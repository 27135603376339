<div class="ffq-lib__driver_assignment-card"
    [ngClass]="{'card-selected check-box-icon': (params.status ==='C'), 'card-disabled' : (params.status ==='D')}"
    *ngFor="let driver of params " >
    {{driver.fullName}}
    <br/>
    <div *ngIf="driver.selector">
        <div class="row">
            <div class="auto__drivers_assignment_dropdown" *ngIf="driver.selector">
                <lib-select (selectedData)="onDriverChange($event)" [params]="driver.checkBoxCtrl"></lib-select>
            </div>
        </div>
    </div>
        <div *ngIf="!driver.selector">
            <lib-check-box class="auto__drivers_assignment__list"
                (selectedData)="onVehicleChange($event,driver.reference)" [params]="driver.checkBoxCtrl">
            </lib-check-box>
        </div>
    </div>