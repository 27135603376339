import { Injectable } from '@angular/core';
import { ModuleProgressStatus } from '@ffq-app-shared/enums/flow.identifier.enum';
import { AppStoreService } from '@ffq-app-store/app.store.service';
import { AutoNavigationLinks, HomeNavigationLinks, BusinessNavigationLinks } from '@ffq-app-shared/model/navigation-links.model';

@Injectable({
  providedIn: 'root'
})
export class NavigationHelperService {

  public autoNavInitialData = {
    yourInfo: ModuleProgressStatus.NOT_STARTED,
    vehicles: ModuleProgressStatus.NOT_STARTED,
    drivers: ModuleProgressStatus.NOT_STARTED,
    discounts: ModuleProgressStatus.NOT_STARTED,
    quote: ModuleProgressStatus.NOT_STARTED,
  };
  public businessNavInitialData = {
    businessInfo: ModuleProgressStatus.NOT_STARTED,
    yourInfo: ModuleProgressStatus.NOT_STARTED,
    confirmation: ModuleProgressStatus.NOT_STARTED,
  };
  public homeNavInitialData = {
    yourInfo: ModuleProgressStatus.NOT_STARTED,
    confirmation: ModuleProgressStatus.NOT_STARTED,
  };

  public autoNavigationData: AutoNavigationLinks = {
    yourInfo: {
      stepNo: 1, title: 'Your Info', routerUrl: 'auto/yourinfo',
      status: ModuleProgressStatus.NOT_STARTED, gtmTag: 'FFQ_Auto_sidepanel_YourInfostepper_button'
    },
    vehicles: {
      stepNo: 2, title: 'Vehicles', routerUrl: 'auto/vehicles',
      status: ModuleProgressStatus.NOT_STARTED, gtmTag: 'FFQ_Auto_sidepanel_Vehiclestepper_button'
    },
    drivers: {
      stepNo: 3, title: 'Drivers', routerUrl: 'auto/drivers',
      status: ModuleProgressStatus.NOT_STARTED, gtmTag: 'FFQ_Auto_sidepanel_Driverstepper_button'
    },
    discounts: {
      stepNo: 4, title: 'Discounts', routerUrl: 'auto/discounts',
      status: ModuleProgressStatus.NOT_STARTED, gtmTag: 'FFQ_Auto_sidepanel_Discountstepper_button'
    },
    quote: {
      stepNo: 5, title: 'Quote', routerUrl: 'auto/quote',
      status: ModuleProgressStatus.NOT_STARTED, gtmTag: 'FFQ_Auto_sidepanel_Quotestepper_button'
    },
    additionalInfo: {
      stepNo: 6, title: 'Additional Info', routerUrl: 'auto/additionalInfo',
      status: ModuleProgressStatus.NOT_STARTED, gtmTag: 'FFQ_Auto_sidepanel_AdditionalInfostepper_button'
    }
  };

  public homeNavigationData: HomeNavigationLinks = {
    yourInfo: { stepNo: 1, title: 'Your Info', routerUrl: 'home/yourinfo', status: ModuleProgressStatus.NOT_STARTED },
    confirmation: { stepNo: 2, title: 'Confirmation', routerUrl: 'home/confirmation', status: ModuleProgressStatus.NOT_STARTED },
  };

  public businessNavigationData: BusinessNavigationLinks = {
    businessInfo: { stepNo: 1, title: 'Business info', routerUrl: 'business/businessinfo', status: ModuleProgressStatus.NOT_STARTED },
    yourInfo: { stepNo: 2, title: 'Your info', routerUrl: 'business/yourinfo', status: ModuleProgressStatus.NOT_STARTED },
    confirmation: { stepNo: 3, title: 'Confirmation', routerUrl: 'business/confirmation', status: ModuleProgressStatus.NOT_STARTED },
  };

  constructor(private appStoreService: AppStoreService) { }

  populateInitialNavigationData(lob: string) {
    this.appStoreService.updateNavData(lob, this.getInitialNavState(lob));
  }

  getInitialNavState(lob: string) {
    if (lob === 'H' || lob === 'R' || lob === 'C') {
      return this.homeNavInitialData;
    } else if (lob === 'B') {
      return this.businessNavInitialData;
    } else if (lob === 'A') {
      return this.autoNavInitialData;
    }
  }

  getClientNavigationLinks(lob: string, navStatus) {
    const navigationLinks = [];
    if (lob === 'H' || lob === 'home') {
      /*this.homeNavigationData.yourInfo.status = navStatus.home.yourInfo;
      this.homeNavigationData.confirmation.status = navStatus.home.confirmation;
      navigationLinks.push(this.homeNavigationData.yourInfo);
      navigationLinks.push(this.homeNavigationData.confirmation);*/
    } else if (lob === 'B' || lob === 'business') {
      this.businessNavigationData.businessInfo.status = navStatus.business.businessInfo;
      this.businessNavigationData.yourInfo.status = navStatus.business.yourInfo;
      this.businessNavigationData.confirmation.status = navStatus.business.confirmation;
      navigationLinks.push(this.businessNavigationData.businessInfo);
      navigationLinks.push(this.businessNavigationData.yourInfo);
      navigationLinks.push(this.businessNavigationData.confirmation);
    } else if (lob === 'A' || lob === 'auto') {
      this.autoNavigationData.yourInfo.status = navStatus.auto.yourInfo;
      this.autoNavigationData.vehicles.status = navStatus.auto.vehicles;
      this.autoNavigationData.drivers.status = navStatus.auto.drivers;
      this.autoNavigationData.discounts.status = navStatus.auto.discounts;
      this.autoNavigationData.quote.status = navStatus.auto.quote;
      navigationLinks.push(this.autoNavigationData.yourInfo);
      navigationLinks.push(this.autoNavigationData.vehicles);
      navigationLinks.push(this.autoNavigationData.drivers);
      navigationLinks.push(this.autoNavigationData.discounts);
      navigationLinks.push(this.autoNavigationData.quote);
      if (navStatus.auto.additionalInfo && navStatus.auto.additionalInfo === 'Not Started') {
        this.autoNavigationData.additionalInfo = {
          stepNo: 6, title: 'Additional Info', routerUrl: 'auto/additionalInfo',
          status: ModuleProgressStatus.NOT_STARTED, gtmTag: 'FFQ_Auto_sidepanel_AdditionalInfostepper_button'
        };
        this.autoNavigationData.additionalInfo.status = navStatus.auto.additionalInfo;
        navigationLinks.push(this.autoNavigationData.additionalInfo);
      }
    }
    return navigationLinks;
  }

  getNavTitle(lob: string) {
    let activePageTitle = null;
    if (lob === 'H' || lob === 'home') {
      activePageTitle = 'Home Quote';
    } else if (lob === 'B' || lob === 'business') {
      activePageTitle = 'Business Quote Request';
    } else if (lob === 'A' || lob === 'auto') {
      activePageTitle = 'Auto Quote';
    } else if (lob === 'C' || lob === 'condo') {
      activePageTitle = 'Condo Quote';
    } else if (lob === 'R' || lob === 'renter') {
      activePageTitle = 'Renters Quote';
    }
    return activePageTitle;
  }
}
