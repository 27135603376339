import { Validators, AbstractControl } from '@angular/forms';
export class RetriveComponentValidation {
  landingZipCode = {
    validators: [
      Validators.required,
      // Validators.maxLength(5),
      Validators.pattern('^[0-9]{5}$'),
      // Validators.minLength(5)
    ],
    messages: {
      required: 'Please enter zip code.',
      // minlength: 'Zip Code is not a valid entry',
      pattern: 'Zip Code is not a valid entry.',
      // maxlength: 'Zip Code is not a valid entry'
    }
  };
  lastName = {
    validators: [
      Validators.required,
      ValidateName,
    ],
    messages: {
      required: 'Please enter Last Name.',
      pattern: 'Last Name is not a valid entry.',
    }
  };
  quoteNumber = {
    validators: [
      Validators.required,
    ],
    messages: {
      required: 'Please enter Quote Number Or Email Address.',
      pattern: 'Quote Number is not a valid entry.'
    }
  };
  emailAddress = {
    validators: [
      Validators.required,
      Validators.pattern(''),
    ],
    messages: {
      required: 'Please enter Quote Number Or Email Address.',
      pattern: 'Email is not a valid entry.'
    }
  };
  dateOfBirth = {
    validators: [
      Validators.required
    ],
    messages: {
      required: 'Please enter Date of Birth.'
    }
  };
}

function ValidateName(control: AbstractControl) {
  const regularExpression = /^[a-zA-Z][a-zA-Z,-. ']+$/;
  if (control.value && !regularExpression.test(control.value)) {
    return { ValidateName: true };
  }
  return null;
}
