import { Component, OnInit , Input } from '@angular/core';
import { FormInputParam } from '../form-input-params';
import { FormControl, AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'lib-common-autocomplete',
  templateUrl: './common-autocomplete.component.html',
  styleUrls: ['./common-autocomplete.component.scss']
})
export class CommonAutocompleteComponent implements OnInit {

  @Input() params: FormInputParam;
  Object = Object;
  myControl: AbstractControl ;
  filteredOptions: Observable<string[]>;
  proxyValue: any;
  defaultVal;
  constructor() { }

  ngOnInit() {

    const formControlName = this.params.formControllName ;
    this.myControl = this.params.formGroup.controls[formControlName];
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => value ? (typeof value === 'string' ? value : value.label) : ''),
        map(label => label ? this._filter(label) : this.params.values.slice())
      );
  }

  private _filter(label: string): string[] {

    const suggOptions = this.params.values;
    const defaultLabel = this.params.defaultValue ? this.params.defaultValue : this.params.otherOption;
    this.defaultVal = suggOptions.find(e => e.label === defaultLabel);
    const filterValue = label.toLowerCase();
    // tslint:disable-next-line: max-line-length
    const filtered = suggOptions.filter(option => option.label.replace('/\s/g', '').toLowerCase().indexOf(filterValue) === 0 || option.label.replace('/\s/g', '').toLowerCase().match(filterValue) ||
    option.label.toLowerCase() === 'other' || option.label.toLowerCase() === 'others');
    if ((filtered.length === 1 && (filtered[0].label === 'Other' || filtered[0].label === 'OTHERS'))) {
      if ('other'.indexOf(filterValue) === 0 || 'others'.indexOf(filterValue) === 0) {
        return filtered.length > 0 ?  filtered : [this.defaultVal] ;
      } else {
        const filtered1 = suggOptions.filter(option => option.value.toLowerCase().indexOf(filterValue) === 0);
        if (filtered1.length === 0) {
          return filtered.length > 0 ?  filtered : [this.defaultVal] ;
        } else {
        return this.params.values;
        }
      }
    } else {
    return filtered.length > 0 ?  filtered : [this.defaultVal] ;
    }
  }


  displayFn = (value) => {
    if (value) {
      const selection = this.params.values.find(e => e.value === value);
      return selection ? selection.label : '' ;
    }
}

  onSelectionChanged(event$) {
    this.proxyValue = event$.option.value.value;
  }

}
