/*
 * @(#) shared.service.constants.js  Jul 19, 2016
 *
 * Copyright 2016 Farmers FFQ
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of
 * Farmers Insurance Group. ("Confidential Information").  You shall not
 * disclose such Confidential Information and shall use it only in
 * accordance with the terms of the license agreement you entered into
 * with Farmers Insurance Group.
 */

import { YearAccident, PayPolicy, PolicyPerksContent, PolicyPerksContentMap } from '@ffq-app-shared/model/common.data.model';

export class DataConstants {

    public static get MODAL_DATA(): any {
        return {
            privacy: {
                modalName: 'privacy',
                modalHeader: 'Privacy Policy',
                modalBody: ''
            },
            timedOutMsg: {
                modalName: 'Timed Out',
                modalHeader: 'Your session is about to expire',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p class="timeout-message">Click "Continue" to keep working. Click "End Session" to save your quote and be directed to the Farmers.com homepage.</p>'
            },
            saveNFinish: {
                modalName: 'mailingAddress',
                modalHeader: 'Your quote has been saved!',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p id="FFQAuto_Modal_mailingAddressContent">For your records, Please note down your quote number below. You will need it to retrieve your quote again.</p><span class="popupQuotenumber"> <i class="fa fa-pencil-square-o"></i>52621895</span><div>Alternatievely, to retrieve your quote using email address, please enter your email id <input type="text" class="quoteNumberText" name="quotenumber"/></div>'
            },
            // Description taken from the Home application.
            changeZip: {
                modalName: 'changeAddressConfirmation',
                modalHeader: 'Are you sure?',
                modalBody: 'Changing your address will require you to re-enter the information you have already provided.',
                acceptDialogAction: 'Change Zip Code',
                cancelDialogAction: 'Cancel Zip Change'
            },
            googlePrefillChangeZipAllowedForAuto: {
                modalName: 'changeAddressConfirmation',
                modalHeader: 'Are you sure?',
                modalBody:  `Selected address is in a different zip '[ZipCode]'. Would you like to proceed?\ `,
                acceptDialogAction: 'Change Zip Code',
                cancelDialogAction: 'Cancel Zip Change',
                color: 'required'
            },
            changeZipForAuto: {
                modalName: 'changeAddressConfirmation',
                modalHeader: 'State/ZIP Mismatch',
                modalBody: ' [ZipCode]\ <br/> Entered ZIP must match state of [State]\.'
            },
            googlePrefillChangeZipForAuto: {
                modalName: 'changeAddressConfirmation',
                modalHeader: 'State/ZIP Mismatch',
                modalBody: ' [Address]\ <br/> Selected address must match state of [State]\.'
            },
            closeVehicleDialog: {
                modalName: 'closeVehicleDialog',
                modalHeader: '',
                modalBody: 'You have made changes to this page. Do you want to discard your changes?',
                cancelButtonText: 'No, continue',
                confirmButtonText: 'Yes, navigate away',
                acceptDialogAction: 'Navigate Away'
            },
            VEHICLES_CANT_FIND_CAR: {
                modalname: 'CantFindCar',
                modalHeader: 'Can\'t find your car?',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p id="FFQAuto_Modal_cantFindCarContent">{{bodyContent}}</p>'
            },
            VEHICLES_CANT_FIND_CAR_EE: {
                modalname: 'CantFindCar',
                modalHeader: 'Can\'t find your car?',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p id="FFQAuto_Modal_cantFindCarContent1">{{bodyContent}}</p>'
            },
            findMyVIN: {
                modalname: 'FindMyVIN',
                modalHeader: 'Vehicle Identification Number (VIN)',
                modalBody: `
                <mat-dialog-content>
                    <div class="auto-module__vin-info-line"></div>
                    <div class="auto-module__vin-info-image">
                        <img src="../../../assets/images/car-image-1.png" />
                        <img src="../../../assets/images/car-image-2.png" />
                    </div>
                    <div class="auto-module__vin-info-content">
                    {{content}}
                    </div>
                    <div class="auto-module__vin-info-content">
                        <p class="auto-module__vin-info-content-heading">{{VINLocations}}</p>
                        <p>{{header1}}</p>
                        <p>{{header2}}</p>
                        <p>{{header3}}</p>
                        <p>{{header4}}</p>
                        <p class="auto-module__vin-info-content-note">{{note}}</p>
                    </div>
                    <div class="auto-module__vin-info-line"></div>
                    </mat-dialog-content>`
            },
            usedfor: {
                modalname: 'Usedfor',
                modalHeader: 'Primary use of vehicle',
                // tslint:disable-next-line:max-line-length
                modalBody: 'Select the option for which you drive the most often, it helps to know if this car will be used for commuting to/from school/work, for business use, or used for pleasure.'
            },
            rideShare: {
                modalname: 'rideShare',
                modalHeader: 'Ride sharing',
                modalBody: 'Ride-sharing companies, such as Uber or Lyft, use personal vehicles to transport passengers for a payment.'
            },
            VEHICLES_OWNERSHP: {
                modalname: 'finance',
                modalHeader: 'Vehicle Ownership',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p id="FFQAuto_Modal_financeContent">{{bodyContent}}</p> '
            },
            garaging: {
                modalHeader: 'Garage Address',
                // tslint:disable-next-line:max-line-length
                modalBody: ' To provide an accurate quote, it helps to know where your car is parked overnight. Typically, this is the same as your home address or mailing address. However, if you park your car elsewhere, please provide that address.'
            },
            passiveRestraint: {
                modalHeader: 'Passive Restraint',
                // tslint:disable-next-line:max-line-length
                modalBody: ' Factory-installed safety features like automatic seatbelts and air bags can qualify you for an additional discount.'
            },
            antiLockBrakes: {
                modalHeader: 'Anti-lock brakes',
                // tslint:disable-next-line:max-line-length
                modalBody: ' Vehicles with factory-installed, four wheel anti-lock braking systems are eligible for a rate discount.'
            },
            theftRecovery: {
                modalHeader: 'Theft recovery system',
                // tslint:disable-next-line:max-line-length
                modalBody: ' A theft recovery system, such as Lo-Jack® or On-Star®, helps law enforcement track stolen vehicles. Vehicles equipped with these systems and an active service subscription may qualify for a rate discount.'
            },
            alternativeFuelForCA: {
                modalHeader: 'Alternative fuels',
                // tslint:disable-next-line:max-line-length
                modalBody: ' Eco-friendly vehicles that run on electric, electric/gasoline hybrid, ethanol, methanol, compressed natural gas, propane or hydrogen qualify for a rate discount.'
            },
            alternativeFuelForGWI: {
                modalHeader: 'Alternative fuels',
                // tslint:disable-next-line:max-line-length
                modalBody: 'Eco-friendly vehicles that run on electric, electric/gasoline hybrid, ethanol, methanol, compressed natural gas or propane can qualify for a reduced rate.'
            },
            alternativeFuelForNonGWI: {
                modalHeader: 'Alternative fuels',
                // tslint:disable-next-line:max-line-length
                modalBody: 'Eco-friendly vehicles that run on electric, electric / gasoline hybrid, ethanol, methanol, compressed natural gas, or propane qualify for a rate discount.'
            },
            passiveRestrCdDrv: {
                modalHeader: 'Air bags or automatic seatbelts',
                // tslint:disable-next-line:max-line-length
                modalBody: ' Factory-installed safety features like automatic seatbelts and air bags can qualify you for an additional discount.'
            },
            escdValdtnCdDrv: {
                modalHeader: 'Electronic stability control',
                // tslint:disable-next-line:max-line-length
                modalBody: ' Safety technology designed to automatically stabilize your vehicle when it skids or loses traction.'
            },
            daytimeRunningLamps: {
                modalHeader: 'Daytime running lamps',
                // tslint:disable-next-line:max-line-length
                modalBody: ' Discounts may apply if your car has daytime running lamps.'
            },
            vinEtching: {
                modalHeader: 'VIN etching',
                // tslint:disable-next-line:max-line-length
                modalBody: ' Vehicles with the VIN number etched into the windshield, door or rear window glass are eligible for a discount.'
            },
            antiTheftDevice: {
                modalHeader: 'Anti-theft device',
                // tslint:disable-next-line:max-line-length
                modalBody: ' Approved anti-theft devices may qualify you for discounts. Examples of these devices include manual or automatic alarm systems, active or passive devices that disable your vehicle, and/or your VIN number etched into your window glass.'
            },
            vehicleRemove: {
                // modalHeader: '',
                // tslint:disable-next-line:max-line-length
                modalBody: ' Are you sure you want to remove this vehicle from the quote?',
                confirmButtonText: 'CONFIRM',
                confirmGTM: 'FFQ_Auto_Vehicle_remVehConfirm_button',
                cancelGTM: 'FFQ_Auto_Vehicle_remVehCancel_button',
                acceptDialogAction: 'RemoveVehicle',
                cancelDialogAction: 'CancelRemoveVehicle'
            },
            addToQuoteDriver: {
                modalHeader: 'AddToQuoteDriver Maximum',
                // tslint:disable-next-line:max-line-length
                modalBody: ' You can only add a maximum of five drivers',
                buttonName: 'CONTINUE'
            },
            driverRemove: {
                modalBody: ' Are you sure you want to remove this driver from the quote?',
                confirmButtonText: 'REMOVE',
                acceptDialogAction: 'RemoveDriver',
                confirmGTM: 'FFQ_Auto_Driver_RemDriverRemove_button',
                cancelGTM: 'FFQ_Auto_Driver_RemDriverCancel_button'
            },
            spouseRemove: {
                modalHeader: 'Remove spouse?',
                // tslint:disable-next-line:max-line-length
                modalBody: 'Are you sure you want to remove this spouse? Removing this spouse will require updating [drivers name] marital status.',
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Remove',
                cancelDialogAction: 'Cancel',
                acceptDialogAction: 'RemoveSpouse',
                confirmGTM: 'FFQ_Auto_Driver_RemSpouseRem_button',
                cancelGTM: 'FFQ_Auto_Driver_AddSpouseCancel_button'
            },
            confirmSpouseRemove: {
                modalHeader: 'Remove spouse?',
                // tslint:disable-next-line:max-line-length
                modalBody: 'Are you sure you want to remove [your] spouse? \n\n Removing [your] spouse will also change [your] marital status to Not Married.',
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Remove',
                cancelDialogAction: 'Cancel',
                acceptDialogAction: 'RemoveSpouse',
                confirmGTM: 'FFQ_Auto_Driver_RemSpouseRem_button',
                cancelGTM: 'FFQ_Auto_Driver_AddSpouseCancel_button'
            },
            selectedSpouseRemove: {
                // tslint:disable-next-line: max-line-length
                modalBody: 'Removing the spouse of a married driver from the quote will change his/her marital status.Please confirm that you want to remove <spouseName> and change the marital status of <correspondingDriverName>.',
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Confirm',
                cancelDialogAction: 'Cancel',
                acceptDialogAction: 'RemoveSpouse',
                confirmGTM: 'FFQ_Auto_Driver_RemSpouseRem_button',
                cancelGTM: 'FFQ_Auto_Driver_AddSpouseCancel_button'
            },
            removePniSpouseAndChange: {
                modalHeader: 'Remove spouse?',
                // tslint:disable-next-line: max-line-length
                modalBody: 'Are you sure you want to remove your spouse? \n\n Removing your spouse will also change your marital status to Not Married.',
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Remove',
                cancelDialogAction: 'Cancel',
                acceptDialogAction: 'RemoveNonPniSpouseAndChange',
                confirmGTM: 'FFQ_Auto_RemSpouseModal_Remove_button',
                cancelGTM: 'FFQ_Auto_RemSpouseModal_Cancle_button'
            },
            removeNonPniSpouseAndChange: {
                modalHeader: 'Remove spouse?',
                // tslint:disable-next-line: max-line-length
                modalBody: 'Are you sure you want to remove [Driver]\'s spouse? \n\n Removing [Driver]\'s spouse will also change [Driver]\'s marital status to Not Married.',
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Remove',
                cancelDialogAction: 'Cancel',
                acceptDialogAction: 'RemoveNonPniSpouseAndChange',
                confirmGTM: 'FFQ_Auto_RemSpouseModal_Remove_button',
                cancelGTM: 'FFQ_Auto_RemSpouseModal_Cancle_button'
            },
            marriedDriverRemove: {
                // tslint:disable-next-line: max-line-length
                modalBody: 'Removing <additionalDriverFullName> will also remove <additionalDriversSpouseFullName> from the quote. Do you want to proceed?',
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Remove Both',
                cancelDialogAction: 'Cancel',
                acceptDialogAction: 'RemoveBoth',
                confirmGTM: 'FFQ_Auto_Driver_RemDriverRemove_button',
                cancelGTM: 'FFQ_Auto_Driver_RemDriverCancel_button'
            },
            cancelAddDriver: {
                modalHeader: 'Cancel Driver',
                modalBody: 'You have made changes to this page. Do you want to discard your changes?',
                confirmButtonText: 'YES, NAVIGATE AWAY',
                cancelButtonText: 'NO, CONTINUE',
                cancelDialogAction: 'continue',
                acceptDialogAction: 'yesNavigate'
            },
            maxVehicleLimitReached: {
                modalName: 'maxVehicleLimitReached',
                modalHeader: '',
                modalBody: 'You can only add a maximum of <maxVehicleCount> vehicles.'
            },
            occupation: {
                modalBody: 'Farmers offers unique discounts to approved occupational groups to bring their employees,' +
                    ' retirees, and members better rates.',
                modalName: 'driverOccupation',
                modalHeader: 'Occupation'
            },
            maritalStatus: {
                modalBody: 'All legally recognized relationships include marriages, civil unions,' +
                    ' and domestic partnerships regardless of gender should select "Married”.',
                modalName: 'maritalStatus',
                modalHeader: '',
                buttonName: 'OK'
            },
            activeMilitaryDuty: {
                modalBody: 'Farmers offers a discount to drivers who are active military personnel based in Louisiana.',
                modalName: 'activeMilitaryDuty',
                modalHeader: 'Active Military Duty'
            },
            changeMaritalStatusToSinglePni: {
                modalHeader: '',
                // tslint:disable-next-line:max-line-length
                modalBody: 'Changing your marital status will remove your spouse information from this application. Please confirm that you want to change your marital status by clicking \'OK\' below.',
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel'
            },
            changeMaritalStatusToSingleAddtnl: {
                modalHeader: '',
                // tslint:disable-next-line:max-line-length
                modalBody: 'Changing marital status of this driver will remove information about his/her spouse from this application. Please confirm that you want to change marital status of this driver by clicking \'OK\' below.',
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel'
            },
            mailingAddress: {
                modalname: 'mailingAddress',
                modalHeader: 'Mailing Address',
                // tslint:disable-next-line:max-line-length
                modalBody: 'The address where we will mail policy documents, insurance cards, and bills to the primary driver on the policy, if different from your Residence Address.'
            },
            fullTimeStudent: {
                // tslint:disable-next-line:max-line-length
                modalBody: 'Student drivers can help save on their auto insurance just by doing well in school. College students with a 3.0 (out of 4.0) grade point average or higher may qualify for discount.',
                modalName: 'fullTimeStudent',
                modalHeader: 'Student GPA'
            },
            finFiling: {
                // tslint:disable-next-line:max-line-length
                modalBody: 'When required by the state, financial filings (also known as SR-22 or FR-44) are filed by insurance companies on behalf of the insured. The filing allows insurance companies to notify the state if you are maintaining coverage and financially responsible for accidents.',
                modalName: 'finFiling',
                modalHeader: 'Financial Responsibility Filing'
            },
            distantStudent: {
                // tslint:disable-next-line:max-line-length
                modalBody: 'Is this driver a student attending school at least 100 miles away from home without custody of a car?',
                modalName: 'distantStudent',
                modalHeader: 'Miles and custody'
            },
            distantStudent_TX: {
                // tslint:disable-next-line:max-line-length
                modalBody: 'Is this driver a student attending school more than 100 miles away from home without custody of a car?',
                modalName: 'distantStudent',
                modalHeader: 'Miles and custody'
            },
            principalPIP: {
                modalBody: 'Personal Injury Protection (PIP) is medical coverage for injuries you (and others) suffer ' +
                    'in an auto accident, regardless of fault.' +
                    ' It also covers reimbursement for certain expenses incurred when you are hurt, such as' +
                    ' lost wages or hiring someone to take care of your home or family.',
                modalName: 'principalPIP',
                modalHeader: 'PIP Insurance Policy'
            },
            safetyCourseCompleted: {
                modalBody: 'Farmers offers a discount to drivers who complete an approved' +
                    ' driving safety or defensive driving course.',
                modalName: 'safetyCourse',
                modalHeader: 'Safety Course'
            },
            safetyCourseDate: {
                modalBody: 'Date you have completed driving safety course or defensive driving course',
                modalName: 'safetyCourseDate',
                modalHeader: 'Date Completed'
            },


            commutQues: {
                modalBody: 'This question applies to any driver on the policy who commutes to another state.',
                modalName: 'commutQues',
                modalHeader: ''
            },
            personalInjury: {
                modalBody: 'Personal Injury refers to bodily injuries incurred by each insured person injured in a motor vehicle accident.',
                modalName: 'personalInjury',
                modalHeader: ''
            },
            paymentStartDate: {
                modalBody: 'What is your auto policy start date, you can get an early shopping discount by ' +
                    'purchasing at least 7 days before your current policy expires.',
                modalHeader: 'Policy Start Date'
            },
            zipCodeNotSupport: {
                modalName: '',
                modalHeader: '',
                // tslint:disable-next-line: max-line-length
                modalBody: '<p > We\'re sorry, we are unable to provide you an online quote at this time. Please call <a class="auto-module__your-info-card-phone desktop" href="javascript:void(0);">(800) 206-9469 </a> <a class="auto-module__your-info-card-phone mobile" href="tel:18002069469">(800) 206-9469 </a> to see if you qualify for a quote.</p>'
            },
            backButtonModal: {
                modalHeader: '',
                modalBody: 'You have made changes to this page. Do you want to discard your changes?',
                confirmButtonText: 'YES, NAVIGATE AWAY',
                cancelButtonText: 'NO, CONTINUE'
            },
            BWKnockout: {
                message: 'We will need a bit more information about your vehicle. Your agent will contact you to complete your quote.'
            },
            BWKnockoutEE: {
            message: 'We will need a bit more information about your vehicle.'
            },
            MIKnockout: {
                message: 'We are sorry. Online auto quote in your area is unavailable. Please accept our apologies for the inconvenience.'
            },
            MDKnockout: {
                message: 'Your Farmers agent will contact you to complete your quote.'
            },
            NYGaragingCreditCheckFailure: {
                // tslint:disable-next-line: max-line-length
                message: 'At this time, we are unable to issue you an online quote since the zip code provided does not match the zip code in your credit report.  For assistance, please contact your assigned Farmers agent.'
            },
            residentialAddress: {
                modalname: 'mailingAddress',
                modalHeader: 'Residential Address',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p id="FFQAuto_Modal_mailingAddressContent">Where is your car typically parked overnight? For most, this is a home address. If you regularly park your car elsewhere, such as a paid parking garage, please provide that address.</p>'
            },
            homeResidentialAddress: {
                modalname: 'homeResidentialAddress',
                modalHeader: 'Residential Address',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p id="FFQHome_Modal_mailingAddressContent">To provide an accurate quote, it helps to know where your car is parked overnight. Typically, this is the same as your home address or mailing address. However, if your car is garaged elsewhere, please provide that address.</p>'
            },
            existingCustomer: {
                modalname: 'existingCustomer',
                modalHeader: 'Our record indicates you have an existing Farmers Auto Policy',
                // tslint:disable-next-line:max-line-length
                modalBody: 'As an existing customer, we appreciate your business. Please contact your agent directly to make any changes or additions to your Auto or Home or Life Accounts. You can proceed by selecting to get a free quote or close this window.'
            },
            locateQuote: {
                modalname: 'locateQuote',
                modalHeader: 'Unable to locate a Quote',
                // tslint:disable-next-line:max-line-length
                modalBody: 'As an existing customer, we appreciate your business. Please contact your agent directly to make any changes or additions to your Auto or Home or Life Accounts. You can proceed by selecting to get a free Life quote or close this window.'
            },
            zipcode: {
                modalname: 'zipcode',
                modalHeader: 'Are you sure?',
                modalBody: 'Changing your zip code will require you to re-enter the information you have already provided.'
            },
            occupationCA: {
                modalname: 'occupationCA',
                modalHeader: 'What is the occupation for <driver name>?',
                // tslint:disable-next-line:max-line-length
                modalBody: 'Farmers offers unique discounts to approved occupational groups to bring their employees, retirees, and members better rates.'
            },
            continuousAutoCoverage: {
                modalname: 'continuousAutoCoverage',
                modalHeader: 'Auto Insurance Coverage',
                modalBody: 'How long have you been insured, without breaks or gaps in your policy coverage?(even for as little as one day)'
            },
            continuousAutoCoverageCIImpl: {
                modalname: 'continuousAutoCoverage',
                modalHeader: 'Auto Insurance Coverage',
                // tslint:disable-next-line:max-line-length
                modalBody: 'Let us know how long you were insured with your prior auto insurance company without any breaks or lapses in coverage'
            },
            studentGPA: {
                modalname: 'StudentGPA',
                modalHeader: 'Student GPA',
                modalBody: ''
            },
            socialSecurityNumber: {
                modalname: 'socialSecurityNumber',
                modalHeader: 'Social Security Number',
                // tslint:disable-next-line:max-line-length
                modalBody: 'Providing your Social Security number improves the accuracy of our rates and service. Please note: this information is protected, and we never share or sell your information.'
            },
            emailAddress: {
                modalname: 'emailAddress',
                modalHeader: 'Email Address',
                // tslint:disable-next-line:max-line-length
                modalBody: 'Your quote will be emailed to you. You can also use your email address to access your quote during the application process.'
            },
            homeEmailAddress: {
                modalname: 'homeEmailAddress',
                modalHeader: 'Email Address',
                // tslint:disable-next-line:max-line-length
                modalBody: 'We\u2019ll email you your quote when it\u2019s complete, or you can use your email address to retrieve your quote during the process. We never share or sell your email address, and it will never be spammed.'
            },
            autoPolicyExists: {
                modalname: 'autoPolicyExists',
                modalHeader: 'Auto Policy',
                modalBody: 'Earn a policy bundle discount if you carry a Farmers Home and Auto policy together.'
            },
            phoneNumber: {
                modalname: 'phoneNumber',
                modalHeader: 'Phone Number',
                // tslint:disable-next-line:max-line-length
                modalBody: 'Providing your phone number makes it easier for us to connect you with your personal agent, file any claim, or resolve an issue.'
            },
            ageLicensedInUS: {
                modalname: 'ageLicensedInUS',
                modalHeader: 'US Domestic License',
                modalBody: 'How old were you when you first received your US Driver\u2019s License?'
            },
            ageLicensedInForeign: {
                modalname: 'ageLicensedInForeign',
                modalHeader: 'Foreign License',
                modalBody: 'How old were you when you first received your Driver\u2019s License in a Foreign Country (including Canada)?'
            },
            ageLicensedInForeignMN: {
                modalname: 'ageLicensedInForeignMN',
                modalHeader: 'Foreign License',
                modalBody: 'How old were you when you first recieved your Driver\u2019s License in a Foreign Country (including Canada)?'
            },
            ageLicensedInUSFL: {
                modalname: 'ageLicensedInUS',
                modalHeader: 'US Domestic License',
                modalBody: 'How old were you when you first received your US Driver\u2019s License?'
            },
            ageLicensedInUSMN: {
                modalname: 'ageLicensedInUS',
                modalHeader: 'US Domestic License',
                modalBody: 'How old were you when you first got your US Driver\u2019s License?'
            },
            accidentsAndViolations: {
                modalname: 'accidentsAndViolations',
                modalHeader: 'Accidents or Violations',
                // tslint:disable-next-line:max-line-length
                modalBody: 'Please include any moving violations (i.e. speeding tickets, red light tickets) and accidents (whether or not they were your fault).'
            },
            commuteToState: {
                modalname: 'commuteToState',
                modalHeader: 'Commuting to State',
                modalBody: 'This question applies to any driver on the policy who commutes to another state.'

            },
            commuteToStateNJNY: {
                modalname: 'commuteToStateNJNY',
                modalHeader: 'Commuting to State',
                modalBody: 'This question applies to any driver on the policy who commutes to another state.'
            },
            commuteToStateNY: {
                modalname: 'commuteToStateNY',
                modalHeader: 'Commuting to State',
                modalBody: 'This question applies to any driver on the policy who commutes to another state.'
            },
            commuteToStateDC: {
                modalname: 'commuteToStateDC',
                modalHeader: 'Commuting to State',
                modalBody: 'This question applies to any driver on the policy who commutes to another state.'
            },
            dateOfBirth: {
                modalname: 'dateOfBirth',
                modalHeader: 'Date of Birth',
                modalBody: 'Providing information about all drivers on your policy will help us give you the most accurate quote.'
            },
            safetyCourse: {
                modalname: 'safetyCourse',
                modalHeader: 'Safety Course',
                modalBody: 'Farmers offers a discount to drivers who complete an approved driving safety or defensive driving course.'
            },
            safetyCourseNotNY: {
                modalname: 'safetyCourseNotNY',
                modalHeader: 'Driver\u2019s age is greater than 55 and the quote state is not NY',
                // tslint:disable-next-line:max-line-length
                modalBody: 'Farmers offers a discount for senior drivers, 55 and older, who successfully complete a state-recognized defensive driving course.'
            },
            safetyCourseNY: {
                modalname: 'safetyCourseNY',
                modalHeader: 'Driver\u2019s age not greater than 55 or state is NY',
                modalBody: 'Farmers offers a discount to drivers who complete an approved driving safety or defensive driving course.'
            },
            currentInsurance: {
                modalname: 'currentInsurance',
                modalHeader: 'Current Insurance',
                // tslint:disable-next-line:max-line-length
                modalBody: 'You have indicated that you are currently insured.  Please enter your current auto insurance company. Select \'Others\' if your company is not listed.'
            },
            policyExpiration: {
                modalname: 'policyExpiration',
                modalHeader: 'Policy Expiration',
                // tslint:disable-next-line:max-line-length
                modalBody: '<h3>When does your current policy expire?</h3>Your policy expiration date is the day when your policy ends.  After that date, your current insurance policy will no longer cover you in the event of an accident.'
            },
            goodStudent: {
                modalname: 'goodStudent',
                modalHeader: 'Good Student',
                // tslint:disable-next-line:max-line-length
                modalBody: 'Farmers offers a discount to full-time high school or college students who maintain at least a "B" average, or 3.0 (out of 4.0) grade point average'
            },
            editIncident: {
                modalname: 'editIncident',
                modalHeader: 'Are you sure you want to edit Incident'
            },
            removeIncident: {
                modalname: 'removeIncident',
                modalHeader: 'Are you sure you want to remove Incident'
            },
            removeDriver: {
                modalname: 'removeDriver',
                modalHeader: ''
            },
            removeAddlDrSp: {
                modalname: 'removeAddlDrSp',
                modalHeader: ''
            },
            VIN: {
                modalname: 'vehicleIdentificationNumber',
                modalHeader: 'Please enter your Vehicle Identification Number (VIN)',
                // tslint:disable-next-line:max-line-length
                modalBody: 'Your Vehicle Identification Number is a unique 17-digit number given to every vehicle in the United States. This number is listed on your registration card, and is also engraved on a narrow plate attached to your dashboard on the driver side.<hr></hr><p class="vin-locations"> VIN Number Locations</p><div class="vin-bottom-list"><p> 1. Dashboard on the driver side</p><p> 2. Driver side door (where the door latches) </p><p> 3. Vehicle registration card or title</p></div></div>'
            },
            AddressForm: {
                modalHeader: 'Garage Address',
                // tslint:disable-next-line:max-line-length
                modalBody: 'It helps to know where your car is parked overnight. Typically, this is the same as your home address or mailing address. However, if you park your car elsewhere, please provide that address.'
            },
            Garaging: {
                modalHeader: 'Garage Address',
                // tslint:disable-next-line:max-line-length
                modalBody: ' To provide an accurate quote, it helps to know where your car is parked overnight. Typically, this is the same as your home address or mailing address. However, if you park your car elsewhere, please provide that address.'
            },
            Usedfor: {
                modalname: 'Usedfor',
                modalHeader: 'Primary use of vehicle',
                // tslint:disable-next-line:max-line-length
                modalBody: 'Select the option for which you drive the most often, it helps to know if this car will be used for commuting to/from school/work, for business use, or used for pleasure.'
            },
            FindMyVIN: {
                modalname: 'FindMyVIN',
                modalHeader: 'Where do I find my VIN?',
                // tslint:disable-next-line:max-line-length
                modalBody: '<b id="FFQAuto_Modal_findMyVinB">VIN Number Locations</b> <p id="FFQAuto_Modal_findMyVinContent1">1.Dashboard on the driver side of vehicle.</p> <p id="FFQAuto_Modal_findMyVinContent2">2. Driver side door where it latches when it\'s closed.</p> <p id="FFQAuto_Modal_findMyVinContent3">3. Your vehicle registration card or tilte.</p>'
            },
            CantFindCar: {
                modalname: 'CantFindCar',
                modalHeader: 'Can\'t find your car?',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p id="FFQAuto_Modal_cantFindCarContent">Enter your VIN number if your car is not in our system. If you want an insurance quote for a specialty car, motorcycle or recreational vehicle, please <a href="https://www.farmersagent.com" target="_blank">contact a Farmers agent</a>. At this time, we do not provide online quotes for these types of vehicles.</p>'
            },
            CantFindCarBW: {
                modalname: 'CantFindCarBW',
                modalHeader: 'Can\'t find your car?',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p id="FFQAuto_Modal_cantFindCarContent">Enter your VIN number if your car is not in our system. If you want an insurance quote for a specialty car, motorcycle or recreational vehicle, please <a href="https://www.farmersagent.com" target="_blank">contact an agent</a>. At this time, we do not provide online quotes for these types of vehicles.</p>'
            },
            financialfiling: {
                modalname: 'financial filing',
                modalHeader: 'Financial Responsibility Filing',
                // tslint:disable-next-line:max-line-length
                modalBody: 'When required by the state, financial filings (also known as SR-22 or FR-44) are filed by insurance companies on behalf of the insured. The filing allows insurance companies to notify the state if you are maintaining coverage and financially responsible for accidents.'
            },
            addDriver: {
                modalname: 'addDriver',
                modalHeader: 'Please add a new driver<p>Date of Birth</p>',
                modalBody: 'Providing information about all drivers on your policy will help us give you the most accurate quote.'
            },
            aboutDriver: {
                modalname: 'aboutDriver',
                modalHeader: 'Driver\'s Age',
                // tslint:disable-next-line:max-line-length
                modalBody: 'This information includes your relationship to the driver, and the driver\'s marital status and Driver License number.'
            },
            additionalDetails: {
                modalname: 'additionalDetails',
                modalHeader: 'Please provide a few additional details:TCPA Content',
                // tslint:disable-next-line:max-line-length
                modalBody: 'Thanks for providing your phone number(s) above! We\'d like to be able to call you to provide helpful information about our products and services. By clicking "Continue" you\'ll consent to receive marketing calls made on behalf of Farmers Insurance SM companies, including the Foremost\xAE, Bristol West\xAE and 21st Century Insurance\xAE companies. These calls may use an automated telephone dialing system, and an artificial or prerecorded voice. Your consent is not a condition of purchase.'
            },
            relationToApplicant: {
                modalname: 'relationToApplicant',
                modalHeader: 'Relationship to applicant',
                modalBody: 'We define "spouse" as a husband, wife, or partner in a civil union.'
            },
            specialAttention: {
                modalname: 'specialAttention',
                modalHeader: 'Your quote requires a special attention',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p class="spclAtten" id="FFQAuto_Modal_specialAttentionContent">We will need a  bit more information about your vehicle.Your Farmers agent will  contact you to complete your quote.You may also contact an agent yourself with the information below.</p>'
            },
            waysToSave: {
                modalname: 'waysToSave',
                modalHeader: 'More ways to save on insurance',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p class="saveWays-desc" id="FFQAuto_Modal_waysToSaveContent">You are now leaving Auto Quote #24886289 but you can get a Farmers Home or Life Insurance policy and save on your Auto Insurance.</p>'
            },
            OtherVehicle: {
                modalname: 'OtherVehicle',
                modalHeader: 'We could not find a model for the vehicle(s) below',
                modalBody: '<p class="vinText">Please enter your VIN so we may provide you with an accurate quote:</p> '
            },
            returnToFarmers: {
                modalname: 'returnToFarmers',
                modalHeader: 'Return to Farmers.com',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p class="return-text" id="FFQAuto_Modal_returnToFarmersContent">You are now leaving Auto Quote <span class="quote-no"> #24886289 </span> but you can get a Farmers Home or Life insurance policy and save on your Auto Insurance</p>'
            },
            navigateAway: {
                modalname: 'navigateAway',
                modalBody: 'You have made changes to this page. Do you want to discard your changes?'
            },
            proceed: {
                modalname: 'proceed',
                modalBody: 'You have made changes to this page. Do you want to discard your changes?'
            },
            commonError: {
                modalname: 'commonError',
                modalHeader: 'Error',
                modalBody: 'Please enter valid data.'
            },
            currAutoInsurance: {
                modalname: 'Current Auto Insurance',
                modalHeader: 'Current Auto Insurance Company',
                // tslint:disable-next-line:max-line-length
                modalBody: 'If you have a current auto insurance policy with a company, please select the name of the company. Select "Other" if the company is not listed.'
            },
            priorAutoInsurance: {
                modalname: 'Prior Auto Insurance',
                modalHeader: 'Who was your prior auto insurance company?',
                // tslint:disable-next-line:max-line-length
                modalBody: 'If you have a prior auto insurance policy with a company, please select the name of the company. Select "Other" if the company is not listed.'

            },
            continuousAutoInsurance: {
                modalname: 'continuous auto insurance',
                modalHeader: 'Auto Insurance Coverage',
                modalBody: 'How long have you been insured, without breaks or gaps in your policy coverage?'
            },
            bodilyInjuryLimits: {
                modalname: 'bodily Injury Limits',
                modalHeader: 'Bodily Injury Limits',
                // tslint:disable-next-line:max-line-length
                modalBody: '<h3>Under your current auto insurance policy, what are the "Bodily Injury Limits"?</h3> This information is listed on your policy&#39;s Declaration Page, typically shown like this: $50,000/$100,000. This represents the highest amount per person/per accident  your insurance company would pay if you are at fault in an accident that causes injury to another person.'
            },
            DriverForeignLicense: {
                modalname: 'Driver\u2019s License',
                modalHeader: 'Driver\u2019s License Age',
                modalBody: 'How old were you when you first received your Driver\u2019s License in a Foreign Country (including Canada)?'
            },
            DriverForeignLicenseMN: {
                modalname: 'Driver\u2019s License',
                modalHeader: 'Driver\u2019s License Age',
                modalBody: 'How old were you when you got your first Driver\u2019s License in a Foreign Country (including Canada)?'
            },
            fewAdditionalDetails: {
                modalname: 'few Additional Details',
                modalHeader: '<p id="FFQAuto_Modal_additionalDetailHeader">Please provide a few additional details:</p>Email Address',
                // tslint:disable-next-line:max-line-length
                modalBody: 'Your quote will be emailed to you. You can also use your email address to access your quote during the application process. Please note: we never share or sell your email address.'
            },
            fewAdditionalDetailsPhone: {
                modalname: 'few Additional Details',
                modalHeader: '<p id="FFQAuto_Modal_additionalDetailsHeader2">Please provide a few additional details:</p>Phone Number',
                // tslint:disable-next-line:max-line-length
                modalBody: 'Providing your phone number makes it easier for us to connect you with your personal agent, file any claim, or resolve an issue.'
            },
            fewAdditionalDetailsSecurityNumber: {
                modalname: 'few Additional Details',
                // tslint:disable-next-line:max-line-length
                modalHeader: '<p id="FFQAuto_Modal_additionalDetailsHeader3">Please provide a few additional details:</p>Social Security Number',
                // tslint:disable-next-line:max-line-length
                modalBody: 'Optional. Providing your Social Security number improves the accuracy of our rates and service. Please note: this information is protected, and we never share or sell your information.'
            },
            driverLicenceInUSA: {
                modalname: 'licence In USA',
                modalHeader: 'Driver\u2019s License Age',
                modalBody: 'How old were you when you first received your US Driver\u2019s License?'
            },
            driverLicenceFirst: {
                modalname: 'Driver\u2019s License Age',
                modalHeader: 'Driver\u2019s License Age',
                modalBody: 'How old were you when you first received your Driver\u2019s License?'
            },
            driverLicenceFirstAdditional: {
                modalname: 'Driver\u2019s License Age',
                modalHeader: 'Driver\u2019s License Age',
                modalBody: 'How old was the driver when they first received their Driver\u2019s License?'
            },
            driverLicenceInUSAFL: {
                modalname: 'licence In USA',
                modalHeader: 'Driver\u2019s License Age',
                modalBody: 'How old were you when you first received your US Driver\u2019s License?'
            },
            driverLicenceInUSAMN: {
                modalname: 'licence In USA',
                modalHeader: 'Driver\u2019s License Age',
                modalBody: 'How old were you when you got your first US Driver\'s License?'
            },
            invalidQuote: {
                modalname: 'mailingAddress',
                modalHeader: 'Your quote has been saved!',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p id="FFQAuto_Modal_mailingAddressContent"> <i class="fa fa-pencil-square-o"></i>52621895</span><div>Alternatievely, to retrieve your quote using email address, please enter your email id <input type="text" class="quoteNumberText" name="quotenumber"/></div>'
            },
            dateOccured: {
                modalname: 'dateOccured',
                modalHeader: 'Date Occured',
                modalBody: 'Use conviction date for violations, and occurrence date for accident.'
            },
            bristolWest: {
                modalname: 'bristolWest',
                modalHeader: 'Continue your quote with Farmers&#174; affiliate Bristol West',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p id="FFQAuto_Modal_bristolWestContent">Based on the information you provided, we can offer you a quote through Bristol West, which is part of Farmers&#174; organization.</p><span class="popUpBristolImage"> <img src="assets/img/bristolwestlogo_reflex.png" class="img-responsive" alt="" /></span><p>By continuing this quote process, you are agreeing to accept a quote provided by one of the member companies of the Bristol West Insurance Group. You also agree that in connection with your quote for insurance, a Bristol West affiliate may review your credit report or obtain or use a credit-based insurance score based on the information contained in that credit report. The Bristol West affiliate may use a third party with the development of your insurance score.</p>'
            },
            navigateWithoutChangesDone: {
                // tslint:disable-next-line:max-line-length
                modalBody: '<p id="FFQAuto_Modal_navPrevContent" class="navigateToPrev">Are you sure you want to navigate to a</p><p class="navigateToPrev"> previous Section ?</p>'
            },
            navigateWithoutSpouse: {
                modalname: 'navigateWithoutSpouse',
                modalBody: '<p class="navigateToPrev" id="FFQAuto_Modal_addingSpouseContent">Please complete adding spouse.</p>'
            },
            PolicyExpire: {
                modalname: 'policyExpire',
                modalHeader: 'Policy Expiration',
                modalBody: '<p id="FFQAuto_Modal_policyExpireContent">When does your current policy expire?</p>'
            },
            studentWithoutCustody: {
                modalname: 'studentWithoutCustody',
                modalHeader: 'Miles and custody',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p id="FFQAuto_Modal_studentContent">Is this driver a student attending school at least 100 miles away from home without custody of a car?</p>'
            },
            studentWithoutCustodyFL: {
                modalname: 'studentWithoutCustody',
                modalHeader: 'Miles and custody',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p id="FFQAuto_Modal_studentContent">Is this driver a student attending school more than 100 miles away from home without custody of a car?</p>'
            },
            'Personal Property': {
                modalname: 'PersonalProperty',
                modalHeader: 'Personal Property',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p id="FFQAuto_Modal_personalPropertyContent">A personal property insurance policy covers items owned or used by the insured anywhere in the world. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Loss of Use': {
                modalname: 'Loss of Use',
                modalHeader: 'Loss of Use',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>With this option, Farmers will provide a flat sum for cabs, public transit or a rental while  your car has a qualified disablement that&#39;s covered by your policy&#39;s comprehensive or collision coverage.</p>'
            },
            'Guest Medical': {
                modalname: 'Guest Medical',
                modalHeader: 'Guest Medical',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Guest medical covers medical and related expenses for persons other than the insured, within the amount allowed for bodily injuries, when the injury takes place on the insured\'s property, regardless of fault. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Towing and Road Service': {
                modalname: 'Towing and Road Service',
                modalHeader: 'Towing and Road Service',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This coverage reimburses the insured for the cost of towing a disabled vehicle and is only available with collision coverage. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Towing & Road Service': {
                modalname: 'Towing and Road Service',
                modalHeader: 'Towing and Road Service',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This coverage reimburses the insured for the cost of towing a disabled vehicle and is only available with collision coverage. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Uninsured Motorist / Under Insured Motorist BI': {
                modalname: 'Uninsured Motorist / Under Insured Motorist BI',
                modalHeader: 'Uninsured Motorist / Under Insured Motorist BI',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This policy option covers injuries to the insured or their passengers if the other party is at fault and has either no or insufficient amount of insurance. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Uninsured Motorist/Underinsured Motorist BI': {
                modalname: 'Uninsured Motorist/Underinsured Motorist Bodily Injury',
                modalHeader: 'Uninsured Motorist/Underinsured Motorist Bodily Injury',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This policy option covers injuries to the insured or their passengers if the other party is at fault and has either no or insufficient amount of insurance. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Coll.plus/Loss of use': {
                modalname: 'Coll.plus/Loss of use',
                modalHeader: 'Coll.plus/Loss of use',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Coll.plus/Loss of use coverage reimburses for expenses incurred from a Collision or Comprehensive loss. If a loss occurs more than 50 miles from home, this coverage also pays for expenses related to returning or retrieving the repaired car. In the case of a car theft, this coverage pays an additional daily amount in excess of the amount provided in a standard policy. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract. </p>'
            },
            'Coll.plus/Loss of use K5': {
                modalname: 'Coll.plus/Loss of use K5',
                modalHeader: 'Coll.plus/Loss of use K5',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Coll.plus/Loss of use K5 coverage reimburses for expenses incurred from a Collision or Comprehensive loss. If a loss occurs more than 50 miles from home, this coverage also pays for expenses related to returning or retrieving the repaired car. In the case of a car theft, this coverage pays an additional daily amount in excess of the amount provided in a standard policy. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract. </p>'
            },
            'Collision Plus/Loss of Use': {
                modalname: 'Collision Plus/Loss of Use',
                modalHeader: 'Collision Plus/Loss of Use',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Collision Plus/Loss of Use coverage reimburses for expenses incurred from a Collision or Comprehensive loss. If a loss occurs more than 50 miles from home, this coverage also pays for expenses related to returning or retrieving the repaired car. In the case of a car theft, this coverage pays an additional daily amount in excess of the amount provided in a standard policy. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract. </p>'
            },
            'Collision Plus/Loss of Use K5': {
                modalname: 'Collision Plus/Loss of Use K5',
                modalHeader: 'Collision Plus/Loss of Use K5',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Collision Plus/Loss of Use coverage reimburses for expenses incurred from a Collision or Comprehensive loss. If a loss occurs more than 50 miles from home, this coverage also pays for expenses related to returning or retrieving the repaired car. In the case of a car theft, this coverage pays an additional daily amount in excess of the amount provided in a standard policy. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Collision Plus/Loss of Use K4': {
                modalname: 'Collision Plus/Loss of Use K4',
                modalHeader: 'Collision Plus/Loss of Use',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Collision Plus/Loss of Use coverage reimburses for expenses incurred from a Collision or Comprehensive loss. If a loss occurs more than 50 miles from home, this coverage also pays for expenses related to returning or retrieving the repaired car. In the case of a car theft, this coverage pays an additional daily amount in excess of the amount provided in a standard policy. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Collision Plus/Loss of Use K3': {
                modalname: 'Collision Plus/Loss of Use K3',
                modalHeader: 'Collision Plus/Loss of Use',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Collision Plus/Loss of Use coverage reimburses for expenses incurred from a Collision or Comprehensive loss. If a loss occurs more than 50 miles from home, this coverage also pays for expenses related to returning or retrieving the repaired car. In the case of a car theft, this coverage pays an additional daily amount in excess of the amount provided in a standard policy. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Collision Plus/Loss of Use K2': {
                modalname: 'Collision Plus/Loss of Use K2',
                modalHeader: 'Collision Plus/Loss of Use',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Collision Plus/Loss of Use coverage reimburses for expenses incurred from a Collision or Comprehensive loss. If a loss occurs more than 50 miles from home, this coverage also pays for expenses related to returning or retrieving the repaired car. In the case of a car theft, this coverage pays an additional daily amount in excess of the amount provided in a standard policy. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Collision Plus/Loss of Use K1': {
                modalname: 'Collision Plus/Loss of Use K1',
                modalHeader: 'Collision Plus/Loss of Use',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Collision Plus/Loss of Use coverage reimburses for expenses incurred from a Collision or Comprehensive loss. If a loss occurs more than 50 miles from home, this coverage also pays for expenses related to returning or retrieving the repaired car. In the case of a car theft, this coverage pays an additional daily amount in excess of the amount provided in a standard policy. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Medical Coverage': {
                modalname: 'Medical Coverage',
                modalHeader: 'Medical Coverage',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Medical coverage pays reasonable expenses incurred by the insured or passengers within three years from the date of accident for necessary medical services and funeral expenses. Medical payment coverage is afforded regardless of fault. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Medical Coverages': {
                modalname: 'Medical Coverages',
                modalHeader: 'Medical Coverages',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Medical coverage pays reasonable expenses incurred by the insured or passengers within three years from the date of accident for necessary medical services and funeral expenses. Medical payment coverage is afforded regardless of fault. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Medical Expense Coverage': {
                modalname: 'Medical Expense Coverage',
                modalHeader: 'Medical Expense Coverage',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Medical expense coverage pays reasonable expenses incurred by the insured or passengers within three years from the date of accident for necessary medical services and funeral expenses. Medical expense coverage is afforded regardless of fault. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            Medical: {
                modalname: 'Medical',
                modalHeader: 'Medical',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Medical coverage pays reasonable expenses incurred by the insured or passengers within three years from the date of accident for necessary medical services and funeral expenses. Medical payment coverage is afforded regardless of fault. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            Collision: {
                modalname: 'Collision',
                modalHeader: 'Collision',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Collision provides coverage for physical damage to the car caused by collision with another vehicle or object. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Uninsured Motorist PD': {
                modalname: 'Uninsured Motorist PD',
                modalHeader: 'Uninsured Motorist PD',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This policy option covers the property of the insured if the party at fault is uninsured or under-insured. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Uninsured Motorist Property Damage': {
                modalname: 'Uninsured Motorist Property Damage',
                modalHeader: 'Uninsured Motorist Property Damage',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This policy option covers the property of the insured if the party at fault is uninsured or under-insured. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Glass Deductible Buyback': {
                modalname: 'Glass Deductible Buyback',
                modalHeader: 'Glass Deductible Buyback',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This coverage allows you to reduce the Comprehensive deductible to $100 when the loss is restricted to glass. (This option is not available if the deductible is already $100). The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Safety Glass - Waiver Of Deductible': {
                modalname: 'Safety Glass - Waiver Of Deductible',
                modalHeader: 'Safety Glass - Waiver Of Deductible',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Glass deductible buyback option allows you to reduce the Comprehensive deductible to $100 in the case of a glass only loss (not available if deductible is already $100). The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Safety Glass - Waiver of DeductibleVA': {
                modalname: 'Safety Glass – Waiver of Deductible',
                modalHeader: 'Safety Glass – Waiver of Deductible',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This coverage/endorsement allows you to cover the glass equipment losses. It may be purchased for any vehicle covered by Comprehensive coverage. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Safety Glass - Waiver of Deductible': {
                modalname: 'Safety Glass - Waiver Of Deductible',
                modalHeader: 'Safety Glass - Waiver Of Deductible',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Glass deductible buyback option allows you to reduce the Comprehensive deductible to $100 in the case of a glass only loss (not available if deductible is already $100). The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Uninsured Motorist': {
                modalname: 'Uninsured Motorist',
                modalHeader: 'Uninsured Motorist',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This coverage pays you the amount you are legally entitled to from damages for bodily injury, up to the limit of liability, when you are hit by an uninsured driver or an unidentified (e.g. hit-and-run) driver. Uninsured Motorist limits cannot be higher than Bodily Injury limits.  The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Michigan Standard Collision': {
                modalname: 'Michigan Standard Collision',
                modalHeader: 'Michigan Standard Collision',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>If you are in an accident, your insurance pays for physical damage to your vehicle no matter who is at fault and you are responsible for the deductible.</p>'
            },
            'Underinsured Motorist': {
                modalname: 'Underinsured Motorist',
                modalHeader: 'Underinsured Motorist',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This policy option covers injuries to the insured or their passengers if the other party is at fault and has an insufficient amount of insurance. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Michigan Broad Form Collision': {
                modalname: 'Michigan Broad Form Collision',
                modalHeader: 'Michigan Broad Form Collision',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>If you are in an accident, your insurance pays for the physical damage to your vehicle. If the accident is not your fault, the insurance will pay your deductible. If the accident is your fault, you are responsible for the deductible.</p>'
            },
            'Separate Structures': {
                modalname: 'Separate Structures',
                modalHeader: 'Separate Structures',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Covers other private structures on the premises, such as garages for up to 10% of the dwelling limit against fire, extended coverage perils, vandalism and malicious mischief, and theft. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Bodily Injury': {
                modalname: 'Bodily Injury',
                modalHeader: 'Bodily Injury',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>If you are legally obligated to pay damages due to bodily injury to others, and the injury occurred due to a covered loss, this coverage will pay damages. If you are sued because of a covered automobile loss, the cost of your legal defense is also covered. The limits chosen are the maximum the company will pay for any single occurrence, per policy (not per vehicle). The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Property Damage': {
                modalname: 'Property Damage',
                modalHeader: 'Property Damage',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>If you are found legally responsible for accidental property damage to someone else\'s property, Property Damage Liability pays for damages caused by you or the driver of your car. This coverage pays for the repairs to the property, up to the limit of coverage chosen by you. If you are sued because of the accident, this coverage also covers the cost of your legal defense. The limits chosen are per policy (not per vehicle). The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Property Damage (Part 4)': {
                modalname: 'Property Damage (Part 4)',
                modalHeader: 'Property Damage',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>If you are found legally responsible for accidental property damage to someone else\'s property, Property Damage Liability pays for damages caused by you or the driver of your auto. This coverage pays for the repairs to the property, up to the limit of coverage chosen by you. If you are sued because of the accident, this coverage also covers the cost of your legal defense. The limits chosen are per policy (not per auto). The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Property Damage Liability': {
                modalname: 'Property Damage Liability',
                modalHeader: 'Property Damage Liability',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>If you are found legally responsible for accidental property damage to someone else\'s property, Property Damage Liability pays for damages caused by you or the driver of your car. This coverage pays for the repairs to the property, up to the limit of coverage chosen by you. If you are sued because of the accident, this coverage also covers the cost of your legal defense. The limits chosen are per policy (not per vehicle). The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Personal Injury Protection Medical': {
                modalname: 'Personal Injury Protection Medical',
                modalHeader: 'Personal Injury Protection Medical',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Personal Injury Protection (PIP) is a mandatory coverage which only applies to the PNI, the PNI\'s spouse and resident relatives of the PNI or PNI\'s spouse.  PIP – Medical pays for allowable expenses for medical care, recovery, rehabilitation, and some funeral expenses up to the selected limit.</p>'
            },
            'Personal Injury Protection Other': {
                modalname: 'Personal Injury Protection Other',
                modalHeader: 'Personal Injury Protection Other',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Personal Injury Protection (PIP) is a mandatory coverage which only applies to the PNI, the PNI\’s spouse and resident relatives of the PNI or PNI\’s spouse.  PIP – Other pays for wage loss, replacement services and survivor\’s benefits up to the limit required by law.</p>'
            },
            'Excess attendant care': {
                modalname: 'Excess attendant care',
                modalHeader: 'Excess attendant care',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Excess Attendant Care is an optional coverage which pays for attendant care above the PIP – Medical coverage limited selected.</p>'
            },
            'Personal Liability': {
                modalname: 'Personal Liability',
                modalHeader: 'Personal Liability',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>If the insured is legally obligated to pay for bodily injury or property damages for an occurrence covered by the policy, personal liability coverage pays damages, up to a specified amount. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            BusinessVehicleError: {
                modalname: '',
                modalHeader: '',
                modalBody: 'Business vehicle use is only allowed on two vehicles per policy.'
            },
            PolicyAlreadyBound: {
                modalname: 'PolicyAlreadyBound',
                modalHeader: 'Policy is already bound',
                modalBody: 'Policy is already bound.'
            },
            'Underinsured Motorist BI_IL': {
                modalname: 'Underinsured Motorist BI',
                modalHeader: 'Underinsured Motorist BI',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This policy option covers injuries to the insured or their passengers if the other party is at fault and has an insufficient amount of insurance. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Uninsured Motorist BI_KY': {
                modalname: 'Uninsured Motorist BI',
                modalHeader: 'Uninsured Motorist Bodily Injury',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This coverage pays you the amount you are legally entitled to from damages for bodily injury, up to the limit of liability, when you are hit by an uninsured driver or an unidentified (e.g. hit-and-run) driver. Uninsured Motorist limits cannot be higher than Bodily Injury limits.  The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Uninsured Motorist BI_ELO_LA': {​​​​​​​​
                modalname: 'Uninsured Motorist BI - Economic Loss Only',
                modalHeader: 'Uninsured Motorist BI - Economic Loss Only',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This coverage pays you the amount you are legally entitled to from damages for bodily injury, up to the limit of liability, when you are hit by an uninsured driver or an unidentified (e.g. hit-and-run) driver. Uninsured Motorist limits cannot be higher than Bodily Injury limits. Economic Loss Only option covers monetary damages like medical costs, funeral expenses and lost wages but does not cover non-monetary damages like pain and suffering and mental anguish. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
                }​​​​​​​​,
            'Uninsured Motorist BI': {
                modalname: 'Uninsured Motorist BI',
                modalHeader: 'Uninsured Motorist BI',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This coverage pays you the amount you are legally entitled to from damages for bodily injury, up to the limit of liability, when you are hit by an uninsured driver or an unidentified (e.g. hit-and-run) driver. Uninsured Motorist limits cannot be higher than Bodily Injury limits.  The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Uninsured Motorist Bodily Injury': {
                modalname: 'Uninsured Motorist Bodily Injury',
                modalHeader: 'Uninsured Motorist Bodily Injury',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This coverage pays you the amount you are legally entitled to from damages for bodily injury, up to the limit of liability, when you are hit by an uninsured driver or an unidentified (e.g. hit-and-run) driver. Uninsured Motorist limits cannot be higher than Bodily Injury limits.  The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            Comprehensive: {
                modalname: 'Comprehensive',
                modalHeader: 'Comprehensive',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Comprehensive covers losses from things other than an accident, like vandalism, riots, floods, hail, fire, animal collisions, and theft.</p>'
            },
            Comprehensive_Part9_MA: {
                modalname: 'Comprehensive (Part 9)',
                modalHeader: 'Comprehensive',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Comprehensive covers losses from things other than an accident, like vandalism, riots, floods, hail, fire, animal collisions, and theft.</p>'
            },
            'Personal Injury Protection': {
                modalname: 'Personal Injury Protection',
                modalHeader: 'Personal Injury Protection',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Personal Injury Protection (PIP) provides no fault benefits for bodily injury to each insured person injured in an accident. Benefits typically include reasonable medical and hospital expenses, loss of income, essential services, accidental death, and funeral services. Coverage varies by state and is subject to conditions and limitations. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Personal Injury ProtectionKY': {
                modalname: 'Personal Injury Protection/No Fault Coverage',
                modalHeader: 'Personal Injury Protection/No Fault Coverage',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Personal Injury Protection, also known as No-Fault Coverage, provides medical coverage for injuries you or your passengers suffer in an auto accident, as well as reimbursement for certain expenses you incur when you are hurt, such as lost wages and hiring someone to take care of your home or family.  This coverage is provided regardless of fault.<br><br>Personal Injury Protection/No-Fault Coverage:<br>Your coverage selection will default to “Accept No Fault PIP.”  If you choose to reject PIP/No Fault coverage, Kentucky law requires you to complete the Kentucky No Fault Rejection form.  To obtain this form, you must call Farmers at 888-767-4030 to reject the coverage, and we will send you a Kentucky No Fault Rejection Form.  You must then submit a completed and signed Kentucky No Fault Rejection Form to the Kentucky Department of Insurance.<br>Your premium may be higher due to your rejection of No Fault coverage, as others will have the same right to sue you for their injuries.If you reject PIP/No Fault coverage, we are required to add Guest PIP/No Fault coverage to provide no fault coverage to passengers in your insured vehicle(s).  Guest PIP/No Fault provides no coverage for you and your household residents.<br><br>For more information about this coverage, please contact our customer care center at 1-888-767-4030.</p>'
            },
            'Personal Injury Protection (Part 2)': {
                modalname: 'Personal Injury Protection (Part 2)',
                modalHeader: 'Personal Injury Protection',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Personal Injury Protection (PIP) provides no fault benefits for bodily injury to each insured person injured in an accident. Benefits include reasonable medical expenses, lost wages, and replacement services. Coverage varies by state and is subject to conditions and limitations. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Personal Injury Protection - Deductible Applies To': {
                modalname: 'Personal Injury Protection - Deductible Applies To',
                modalHeader: 'Personal Injury Protection - Deductible Applies To',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>The “Personal Injury Protection Deductible Applies to” line on your policy indicates who that deductible applies to, either just the named insured or the named insured and dependents as well.</p>'
            },
            'Optional PIP': {
                modalname: 'Optional Personal Injury Protection/No Fault Coverage',
                modalHeader: 'Optional Personal Injury Protection/No Fault Coverage',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Provides additional PIP/No Fault limits for medical coverage for injuries you or your passengers suffer in an auto accident, as well as reimbursement for certain expenses you incur when you are hurt, such as lost wages and hiring someone to take care of your home or family. For more information about this coverage, please contact our customer care center at 1-888-767-4030.</p>'
            },
            'Under Insured Motorist BI': {
                modalname: 'Under Insured Motorist BI',
                modalHeader: 'Under Insured Motorist BI',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>If the other party is at fault and does not have a sufficient amount of insurance to cover damages, this policy option covers injuries to the insured and passengers. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Under Insured Motorist PD': {
                modalname: 'Under Insured Motorist PD',
                modalHeader: 'Under Insured Motorist PD',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>If the other party is at fault and does not have a sufficient amount of insurance, this policy option covers the property of the insured. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            BIPD: {
                modalname: 'BIPD',
                modalHeader: 'BIPD',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>If you are legally obligated to pay damages due to bodily injury to others, and the injury occurred due to a covered loss, this coverage will pay damages. If you are sued because of a covered automobile loss, the cost of your legal defense is also covered. The limits chosen are the maximum the company will pay for any single occurrence, per policy (not per vehicle). The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Uninsured Motorist PD without collision': {
                modalname: 'Uninsured Motorist PD without collision',
                modalHeader: 'Uninsured Motorist PD without collision',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>If the other party is at fault and does not have a sufficient amount of insurance, this policy option covers the property of the insured. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Uninsured Motorist PD w/o Collision': {
                modalname: 'Uninsured Motorist PD w/o Collision',
                modalHeader: 'Uninsured Motorist PD w/o Collision',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>If the other party is at fault and does not have a sufficient amount of insurance, this policy option covers the property of the insured. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Personal Injury Protection Deductible': {
                modalname: 'Personal Injury Protection Deductible',
                modalHeader: 'Personal Injury Protection Deductible',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Personal Injury Protection (PIP) provides no fault benefits for bodily injury to each insured person injured in a motor vehicle accident. Benefits typically include reasonable medical and hospital expenses, loss of income, essential services, accidental death and funeral services. Coverages vary by state and are subject to conditions and limitations. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Bodily Injury Limit': {
                modalname: 'Bodily Injury Limit',
                modalHeader: 'Bodily Injury Limit',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Bodily injury liability coverage will pay if you are legally obligated to pay damages due to bodily injury to others arising from an accident (i.e. a covered loss). It also covers the cost of your legal defense in the event you are sued because of an accident.</p>'
            },
            'Uninsured Motorist/Under Insured Motorist Property Damage': {
                modalname: 'Uninsured Motorist/Under Insured Motorist Property Damage',
                modalHeader: 'Uninsured Motorist/Under Insured Motorist Property Damage',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>An uninsured or underinsured motorist property damage policy can help pay for repairs to your vehicle if it is damaged when it is struck by an uninsured driver, a hit-and-run driver, or an insured driver whose property damage liability limit is not enough to cover the full amount of property damage losses incurred.</p>'
            },
            'Uninsured Motorist / Under Insured Motorist PD': {
                modalname: 'Uninsured Motorist / Under Insured Motorist PD',
                modalHeader: 'Uninsured Motorist / Under Insured Motorist PD',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>An uninsured or underinsured motorist property damage policy can help pay for repairs to your vehicle if it is damaged when it is struck by an uninsured driver, a hit-and-run driver, or an insured driver whose property damage liability limit is not enough to cover the full amount of property damage losses incurred.</p>'
            },
            'Under Insured Motorist': {
                modalname: 'Under Insured Motorist',
                modalHeader: 'Under Insured Motorist',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This policy option covers injuries to the insured or their passengers if the other party is at fault and has an insufficient amount of insurance. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Waiver of Work Loss': {
                modalname: 'Waiver of Work Loss',
                modalHeader: 'Waiver of Work Loss',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Waiver of Work loss coverage, deletes the Income Continuation Benefits coverage under Personal Injury Protection coverage for a reduction in premium with a signed written waiver. Coverages vary by state and are subject to conditions and limitations. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Income Disability': {
                modalname: 'Income Disability',
                modalHeader: 'Income Disability',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>When income is interrupted or terminated because of illness, sickness, or accident, Income Disability coverage pays up to 70% of the insured person\'s  income. Coverages vary by state and are subject to conditions and limitations.The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Basic Reparation Benefits': {
                modalname: 'Basic Reparation Benefits',
                modalHeader: 'Basic Reparation Benefits',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Basic Reparation Benefit provides no-fault benefits for bodily injury to each injured person in a vehicle accident. Benefits typically include reasonable medical and hospital expenses, loss of income, essential services, accidental death and funeral services. Coverages vary by state and are subject to conditions and limitations. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Other than Collision': {
                modalname: 'Other than Collision',
                modalHeader: 'Other than Collision',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Comprehensive coverage covers direct or accidental damage to your vehicle, other than collision. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Lawsuit Option': {
                modalname: 'Lawsuit Option',
                modalHeader: 'Lawsuit Option',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>No Limit coverage gives you the right to sue the person who caused an auto accident for pain and suffering for any injury.</p>'
            },
            'Principal PIP': {
                modalname: 'Principal PIP',
                modalHeader: 'Principal PIP',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Provides medical coverage for injuries you or your passengers suffer in an auto accident, as well as reimbursement for certain expenses you incur when you are hurt, such as lost wages and hiring someone to take care of your home or family.</p>'
            },
            'Principal PIP - Med Exp Only': {
                modalname: 'Principal PIP - Med Exp Only',
                modalHeader: 'Principal PIP - Med Exp Only',
                modalBody: '<p>Provides medical coverage for injuries you or your passengers suffer in an auto accident.</p>'
            },
            'Extended Medical Payments': {
                modalname: 'Extended Medical Payments',
                modalHeader: 'Extended Medical Payments',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Provides medical coverage for injuries you or your passengers suffer in an auto accident as the result of occupying a non-owned auto.</p>'
            },
            'Income Continuation': {
                modalname: 'Income Continuatione',
                modalHeader: 'Income Continuation',
                modalBody: '<p>Coverage for lost wages if you cannot work due to accident-related injuries.</p>'
            },
            'Workers Compensation': {
                modalname: 'Workers Compensation',
                modalHeader: 'Workers Compensation',
                modalBody: '<p>Workers Compensation: Workers Compensation And Employer Liabable Coverage.</p>'
            },
            'Permissive User Limit Of Liability': {
                modalname: 'Permissive User Limit Of Liability',
                modalHeader: 'Permissive User Limit Of Liability',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This coverage allows you to select full or limited liability coverage for permissive users of your insured car(s). Full Permissive User Coverage provides the liability limits you have selected for permissive users of your insured car(s). Limited Permissive User Coverage reduces vehicle liability limits to $15,000 bodily injury liability coverage per person, $30,000 bodily injury liability coverage per accident and $5,000 property damage coverage per accident for permissive users of your insured car(s). A permissive driver is someone who might operate your insured car on an incidental basis, who is not you, a family member or someone listed on your policy to operate your insured car.</p>'
            },
            'Permissive User Limit of Liability': {
                modalname: 'Permissive User Limit of Liability',
                modalHeader: 'Permissive User Limit of Liability',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This coverage allows you to select full or limited liability coverage for permissive users of your insured car(s). Full Permissive User Coverage provides the liability limits you have selected for permissive users of your insured car(s). Limited Permissive User Coverage reduces vehicle liability limits to $15,000 bodily injury liability coverage per person, $30,000 bodily injury liability coverage per accident and $5,000 property damage coverage per accident for permissive users of your insured car(s). A permissive driver is someone who might operate your insured car on an incidental basis, who is not you, a family member or someone listed on your policy to operate your insured car.</p>'
            },
            'Accidental Death Benefit': {
                modalname: 'Accidental Death Benefit',
                modalHeader: 'Accidental Death Benefit',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Accidental Death coverage ensures that your family is paid the selected amount of money if a death occurs as a direct result of an accident.</p>'
            },
            Tort: {
                modalname: 'Tort',
                modalHeader: 'Tort',
                modalBody: '<p>This coverage limits your right to seek financial compensation for injuries caused by other drivers.</p>'
            },
            'UMBI Stacking': {
                modalname: 'UMBI Stacking',
                modalHeader: 'UMBI Stacking',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>The insured may increase the limits for Uninsured Motorist coverage to an amount equal to the sum of all UM limits for each vehicle on the policy in the household insured with Farmers. This option is only available if there is more than one vehicle insured on the policy with Farmers. Rejecting the stacking option will cause the premium to be lower.</p>'
            },
            'Uninsured Motorist Bodily Injury - Stacking': {
                modalname: 'Uninsured Motorist Bodily Injury - Stacking',
                modalHeader: 'Uninsured Motorist Bodily Injury - Stacking',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>The insured has the option of increasing the limits for UM coverage from the selected vehicle limit, to an amount equal the sum of all UM Limits from each vehicle on the policy in the household insured with Farmers. This option is only available if there is more than one vehicle insured on the policy with Farmers. Rejecting the stacking option will cause the premium to be lower.</p>'
            },
            'Income Loss': {
                modalname: 'Income Loss',
                modalHeader: 'Income Loss',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>If you are injured, this coverage pays up to 80% of your lost income, including payments for reasonable expenses incurred to hire someone to do your job (if you are self-employed) or to hire someone to help you work. The coverage only pays if it reduces loss of gross income, subject to the limits available.</p>'
            },
            'UIMBI Stacking': {
                modalname: 'UIMBI Stacking',
                modalHeader: 'UIMBI Stacking',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>The insured may increase the limits for Uninsured Motorist coverage to an amount equal to the sum of all UM limits for each vehicle on the policy in the household insured with Farmers. This option is only available if there is more than one vehicle insured on the policy with Farmers. Rejecting the stacking option will cause the premium to be lower.</p>'
            },
            'Under insured Motorist Bodily Injury - Stacking': {
                modalname: 'Under insured Motorist Bodily Injury - Stacking',
                modalHeader: 'Under insured Motorist Bodily Injury - Stacking',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>The insured may increase the limits for Uninsured Motorist coverage to an amount equal to the sum of all UM limits for each vehicle on the policy in the household insured with Farmers. This option is only available if there is more than one vehicle insured on the policy with Farmers. Rejecting the stacking option will cause the premium to be lower.</p>'
            },
            PayPlan: {
                modalname: 'Pay Plan',
                modalHeader: 'Pay Plan',
                modalBody: '<p>Based on the underwriting results the Pay in Full is only available option.</p>'
            },
            'Uninsured Motorist Bodily Injury - Without Stacking': {
                modalname: 'Uninsured Motorist Bodily Injury - Without Stacking',
                modalHeader: 'Uninsured Motorist Bodily Injury - Without Stacking',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>The insured has the option of increasing the limits for UM coverage from the selected vehicle limit, to an amount equal the sum of all UM Limits from each vehicle on the policy in the household insured with Farmers. This option is only available if there is more than one vehicle insured on the policy with Farmers. Rejecting the stacking option will cause the premium to be lower.</p>'
            },
            'Under Insured Motorist Bodily Injury - Stacking': {
                modalname: 'Under Insured Motorist Bodily Injury - Stacking',
                modalHeader: 'Under Insured Motorist Bodily Injury - Stacking',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>The insured may increase the limits for Uninsured Motorist coverage to an amount equal to the sum of all UM limits for each vehicle on the policy in the household insured with Farmers. This option is only available if there is more than one vehicle insured on the policy with Farmers. Rejecting the stacking option will cause the premium to be lower.</p>'
            },
            'UnderInsured Motorist Bodily Injury - Stacking': {
                modalname: 'UnderInsured Motorist Bodily Injury - Stacking',
                modalHeader: 'UnderInsured Motorist Bodily Injury - Stacking',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>The insured may increase the limits for Uninsured Motorist coverage to an amount equal to the sum of all UM limits for each vehicle on the policy in the household insured with Farmers. This option is only available if there is more than one vehicle insured on the policy with Farmers. Rejecting the stacking option will cause the premium to be lower.</p>'
            },
            'Uninsured Motorist / Under Insured Motorist BI Conversion': {
                modalname: 'Uninsured Motorist / Under Insured Motorist BI Conversion',
                modalHeader: 'Uninsured Motorist / Under Insured Motorist BI Conversion',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This policy option covers injuries to you or your passengers in an accident if the other party is at fault and has an insufficient amount of insurance.</p>'
            },
            'Antitheft device': {
                modalname: 'Antitheft device',
                modalHeader: 'Antitheft device',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Approved anti-theft devices may qualify you for discounts. Examples of these devices include manual or automatic alarm systems, active or passive devices that disable your vehicle, and/or your VIN number etched into your window glass.</p>'
            },
            'Anti-lock Brakes': {
                modalname: 'Anti-lock Brakes',
                modalHeader: 'Anti-lock Brakes',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>If your car is equipped with a factory installed anti-lock braking system on all four wheels, you pay less.</p>'
            },
            'Anti-Lock Brakes': {
                modalname: 'Anti-Lock Brakes',
                modalHeader: 'Anti-Lock Brakes',
                modalBody: '<p>This discount applies when your vehicle is equipped with an anti-lock brake system.</p>'
            },
            'Daytime Running Lights': {
                modalname: 'Daytime Running Lights',
                modalHeader: 'Daytime Running Lights',
                modalBody: '<p>This discount applies when your vehicle is equipped with daytime running lights.</p>'
            },
            'Alternative Fuel': {
                modalname: 'Alternative Fuels',
                modalHeader: 'Alternative Fuels',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Eco-friendly vehicles that run on electric, electric / gasoline hybrid, ethanol, methanol, compressed natural gas, or propane qualify for a rate discount.</p>'
            },
            'Passive Restraint': {
                modalname: 'Passive Restraint',
                modalHeader: 'Air bags or automatic seatbelts',
                modalBody: '<p>If your vehicle has an airbag or another passive restraint system which meets federal safety standards.</p>'
            },
            'Electronic stabilty control': {
                modalname: 'Air bags or automatic seatbelts',
                modalHeader: 'Electronic stabilty control',
                modalBody: '<p>Safety technology designed to automatically stabilize your vehicle when it skids or loses traction.</p>'
            },
            'Daytime Running Lamps': {
                modalname: 'Daytime Running Lamps',
                modalHeader: 'Daytime Running Lamps',
                modalBody: '<p>Discounts may apply if your car has daytime running lamps.</p>'
            },
            'Theft recovery system': {
                modalname: 'Theft recovery system',
                modalHeader: 'Theft recovery system',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>A theft recovery system, such as Lo-Jack? or On-Star?, helps law enforcement track stolen vehicles. Vehicles equipped with these systems and an active service subscription may qualify for a rate discount. </p>'
            },
            'VIN etching': {
                modalname: 'VIN etching',
                modalHeader: 'VIN etching',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This discount is available for vehicles that have their Vehicle Identification Number (VIN) etched in required areas.</p>'
            },
            'VIN Etching': {
                modalname: 'VIN Etching',
                modalHeader: 'VIN Etching',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This discount is available for vehicles that have their Vehicle Identification Number (VIN) etched in required areas.</p>'
            },
            Homeowner: {
                modalname: 'Homeowner',
                modalHeader: 'Home Owner',
                modalBody: '<p>Own a home? Homeowners can receive a discount on their auto policy.</p>'
            },
            'Home Owner': {
                modalname: 'Home Owner',
                modalHeader: 'Home Owner',
                modalBody: '<p>Own a home? Homeowners can receive a discount on their auto policy.</p>'
            },
            'Date Occurred': {
                modalname: 'Date Occurred',
                modalHeader: 'Date Occurred',
                modalBody: '<p>Use conviction date for violations, and occurrence date for accident.</p>'
            },
            'Good Driver': {
                modalname: 'Good Driver',
                modalHeader: 'Good Driver',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>If you are (1) licensed for 3 years without a suspension, lapse, or failed renewal, and (2) have had no more than 1 point on your license in the past 3 years, you may be eligible for a rate discount.</p>'
            },
            'Safe Driver': {
                modalname: 'Safe Driver',
                modalHeader: 'Safe Driver',
                modalBody: '<p>You may qualify for this discount if you have a clean driving record.</p>'
            },
            'Safe Driving': {
                modalname: 'Safe Driving',
                modalHeader: 'Safe Driving',
                modalBody: '<p>You may qualify for this discount if you have a clean driving record.</p>'
            },
            ESC: {
                modalname: 'ESC',
                modalHeader: 'ESC',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This discount is available if you have a vehicle equipped with this feature (ESC). In many new cars, this technology helps drivers maintain control of their vehicle during extreme steering maneuvers by keeping the vehicle headed in the driver\'s intended direction, even when the vehicle nears or exceeds the limits of road traction.</p>'
            },
            'Business/Professional Group': {
                modalname: 'Business/Professional Group',
                modalHeader: 'Business/Professional Group',
                modalBody: '<p>Discount for Doctors, Nurses, Engineers, Educators, Accountants and other professionals.</p>'
            },
            'PIP Limit/Deductible': {
                modalname: 'PIP Limit/Deductible',
                modalHeader: 'PIP Limit/Deductible',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Provides medical coverage for injuries you (and others) suffer in an auto accident as well as reimbursement for certain other expenses you may have because you are hurt, such as lost wages and the need to hire someone to take care of your home or family.</p>'
            },
            'Multiple Car': {
                modalname: 'Multiple Car',
                modalHeader: 'Multiple Car',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>If you own two or more vehicles and insure both of them with Farmers, you may qualify for the Multi-Car discount.</p>'
            },
            'Electronic Funds Transfer': {
                modalname: 'Electronic Funds Transfer',
                modalHeader: 'Electronic Funds Transfer',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Pay your policy premium by Electronic Funds Transfer and get a discount (applies based on the payment plan you have chosen).</p>'
            },
            'Go Paperless': {
                modalname: 'Go Paperless',
                modalHeader: 'Go Paperless',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Get a discount when you agree to receive your policy and billing documents electronically via a valid email address.</p>'
            },
            '3 Years Clean': {
                modalname: '3 Years Clean',
                modalHeader: '3 Years Clean',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>If you are at least 23 years old, you can qualify for a discount when you and all drivers listed on the policy are without at-fault accidents and violations in the past three (3) years.</p>'
            },
            '5 Years Clean': {
                modalname: '5 Years Clean',
                modalHeader: '5 Years Clean',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>If you are at least 25 years old, you can qualify for a discount when you and all drivers listed on the policy are without at-fault accidents and violations in the past five (5) years.</p>'
            },
            'Accident Prevention Course': {
                modalname: 'Accident Prevention Course',
                modalHeader: 'Accident Prevention Course',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This discount applies when you have successfully completed a state approved accident prevention course within the past three (3) years which was not required as the result of any traffic infraction (pursuant to Article 21 of the Vehicle and Traffic Law).</p>'
            },
            'Advance Quote': {
                modalname: 'Advance Quote',
                modalHeader: 'Advance Quote',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>If you (and spouse) are at least 19 years old a discount applies when you get a quote 7 to 59 days before your new policy start date and you an do not have a lapse in coverage with your prior insurance company.</p>'
            },
            'Continuous Insurance': {
                modalname: 'Continuous Insurance',
                modalHeader: 'Continuous Insurance',
                modalBody: '<p>This discount based on the length of continuous coverage with your previous insurance company.</p>'
            },
            'E-mail': {
                modalname: 'E-mail',
                modalHeader: 'E-mail',
                modalBody: '<p>Get a discount when you provide us with your valid email address.</p>'
            },
            'High DownPay': {
                modalname: 'High DownPay',
                modalHeader: 'High DownPay',
                modalBody: '<p>Get a discount when you choose a payment plan with a high down payment amount.</p>'
            },
            'Homeowner/ Mobile Home': {
                modalname: 'Homeowner/ Mobile Home',
                modalHeader: 'Homeowner/ Mobile Home',
                modalBody: '<p>Get a discount if you own the home, mobile home, town home or Condominium in which you reside.</p>'
            },
            'Montana National Guard': {
                modalname: 'Montana National Guard',
                modalHeader: 'Montana National Guard',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>You can get a discount if you are an active member of the Montana National Guard, have voluntarily completed a Montana state approved driving course with the past two (2) years, and have no at-fault accidents and violations within the past three (3) years.</p>'
            },
            'Multi-Policy': {
                modalname: 'Multi-Policy',
                modalHeader: 'Multi-Policy',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Get a discount if you have additional qualifying policies with another Farmers-Affiliated Company (e.g., Farmers, Foremost or Zurich Companies).</p>'
            },
            'Preferred Driver': {
                modalname: 'Preferred Driver',
                modalHeader: 'Preferred Driver',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>If you are at least 25 years old, you can qualify for a discount when you and all drivers listed on the policy are without at-fault accidents and violations in the past five (5) years.</p>'
            },
            'Prior Insurance': {
                modalname: 'Prior Insurance',
                modalHeader: 'Prior Insurance',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Applies when you have at least six (6) months of continuous coverage with the same insurance company without a lapse.</p>'
            },
            'Senior Driver': {
                modalname: 'Senior Driver',
                modalHeader: 'Senior Driver',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Applies to drivers that are 55 years of age or older that have successfully completed an accident prevention course approved by the DMV, and received a certificate of completion. The discount applies to to new policies with inception dates within the 36 month period following course completion; and only to the auto rated on the operator with the course completion certificate; and only once to each such auto regardless of the number of operators with course completion certificates.</p>'
            },
            'Standard Prior Carrier': {
                modalname: 'Standard Prior Carrier',
                modalHeader: 'Standard Prior Carrier',
                modalBody: '<p>Applies if you have a prior insurance policy with a company we consider to be a “standard” carrier.</p>'
            },
            'Window Glass VIN Etching': {
                modalname: 'Window Glass VIN Etching',
                modalHeader: 'Window Glass VIN Etching',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>You can get a discount if you have your windshield, door glass and rear window etched with the VIN of your vehicle.</p>'
            },
            'Uninsured Motorist Limit': {
                modalname: 'Uninsured Motorist Limit',
                modalHeader: 'Uninsured Motorist Limit',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This coverage pays you the amount you are legally entitled to from damages for bodily injury, up to the limit of liability, when you are hit by an uninsured driver or an unidentified (e.g. hit-and-run) driver. Uninsured Motorist limits cannot be higher than Bodily Injury limits.  The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Uninsured/Underinsured Motorist Conversion': {
                modalname: 'Uninsured/Underinsured Motorist Conversion',
                modalHeader: 'Uninsured/Underinsured Motorist Conversion',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This policy option covers injuries to you or your passengers in an accident if the other party is at fault and has an insufficient amount of insurance.</p>'
            },
            'Good Payer': {
                modalname: 'Good Payer',
                modalHeader: 'Good Payer',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Current Farmers Auto insurance customers, who\'ve paid their bills on time for the past year, can save with this discount.</p>'
            },
            'Funeral Expense Benefits': {
                modalname: 'Funeral Expense Benefits',
                modalHeader: 'Funeral Expense Benefits',
                modalBody: '<p>Funeral or burial expenses incurred will be paid if bodily injury causes the death of an insured.</p>'
            },
            'Extra PIP Package': {
                modalname: 'Extra PIP Package',
                modalHeader: 'Extra PIP Package',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>If you have selected Principal PIP coverage, this package provides higher limits on Income Continuation, Essential Services, Death Benefit and Funeral Expense coverages.</p>'
            },
            'Funeral Expenses': {
                modalname: 'Funeral Expenses',
                modalHeader: 'Funeral Expenses',
                modalBody: '<p>Funeral or burial expenses incurred will be paid if bodily injury causes the death of an insured.</p>'
            },
            'Extra PIP Package for Primary Insured, Spouse & Resident Relatives': {
                modalname: 'Extra PIP Package for Primary Insured, Spouse & Resident Relatives',
                modalHeader: 'Extra PIP Package for Primary Insured, Spouse & Resident Relatives',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>If you have selected Principal PIP coverage, this package provides higher limits on Income Continuation, Essential Services, Death Benefit and Funeral Expense coverages.</p>'
            },
            'Essential Services': {
                modalname: 'Essential Services',
                modalHeader: 'Essential Services',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Pays for necessary services that you (an insured) normally do yourself, such as cleaning your house, mowing your lawn, shoveling snow or doing laundry, if you are injured in an auto accident.</p>'
            },
            'PIP Primary Insurer': {
                modalname: 'PIP Primary Insurer',
                modalHeader: 'PIP Primary Insurer',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Provides medical coverage for injuries you (and others) suffer in an auto accident. PIP pays if you or other persons covered under your policy are injured in an auto accident. You can select either the Auto Insurance policy or your Health Insurance policy to be the primary source of coverage. You can receive a reduction in premium by using your own health insurance as a primary source of coverage in the case of injury related to an auto accident. Before selecting this option, you should find out if your health insurance will cover auto accident injuries and how much coverage is provided. MEDICARE and MEDICAID cannot be used for the Health Insurance Primary option (Most consumers do not choose their own health insurer).</p>'
            },
            'Accidental Death Benefits': {
                modalname: 'Accidental Death Benefits',
                modalHeader: 'Accidental Death Benefits',
                modalBody: '<p>A death benefit will be paid if bodily injury from an accident causes the death of an insured.</p>'
            },
            'Health Insurer Primary for PIP': {
                modalname: 'Health Insurer Primary for PIP ',
                modalHeader: 'Health Insurer Primary for PIP ',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Provides medical coverage for injuries you (and others) suffer in an auto accident. PIP pays if you or other persons covered under your policy are injured in an auto accident. You can select either the Auto Insurance policy or your Health Insurance policy to be the primary source of coverage. You can receive a reduction in premium by using your own health insurance as a primary source of coverage in the case of injury related to an auto accident. Before selecting this option, you should find out if your health insurance will cover auto accident injuries and how much coverage is provided. MEDICARE and MEDICAID cannot be used for the Health Insurance Primary option (Most consumers do not choose their own health insurer).</p>'
            },
            'Death Benefit': {
                modalname: 'Death Benefit',
                modalHeader: 'Death Benefit',
                modalBody: '<p>A death benefit will be paid if bodily injury from an accident causes the death of an insured.</p>'
            },
            'Good Student': {
                modalname: 'Good Student',
                modalHeader: 'Good Student',
                modalBody: '<p>Households with students under the age of 25 who maintain a "B" average may qualify for a discount.</p>'
            },
            'New Car': {
                modalname: 'New Car',
                modalHeader: 'New Car',
                modalBody: '<p>Get a credit if your vehicle is the current year or next year\'s model.</p>'
            },
            'Homing Device': {
                modalname: 'Homing Device',
                modalHeader: 'Homing Device',
                modalBody: '<p>Get a discount if your car is equipped with a vehicle recovery system.</p>'
            },
            'Auto/ Home': {
                modalname: 'Auto/ Home',
                modalHeader: 'Auto/ Home',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>You can save money on both auto and homeowners insurance by purchasing both of these policies from Farmers.</p>'
            },
            'Auto & Life': {
                modalname: 'Auto & Life',
                modalHeader: 'Auto & Life',
                modalBody: '<p>Get a reduced rate on your Auto policy if you purchase Farmers Life insurance, too.</p>'
            },
            'Auto & Home': {
                modalname: 'Auto & Home',
                modalHeader: 'Auto & Home',
                modalBody: '<p>Insuring your Car and Home with Farmers may qualify you for reduced rates on both.</p>'
            },
            'Alternate Fuel Vehicle': {
                modalname: 'Alternate Fuel Vehicle',
                modalHeader: 'Alternate Fuel Vehicle',
                modalBody: '<p>Get a discount if you drive a hybrid, electric and other qualifying vehicle.</p>'
            },
            'Auto & Rent': {
                modalname: 'Auto & Rent',
                modalHeader: 'Auto & Rent',
                modalBody: '<p>Purchasing your Car and Renters insurance with Farmers may qualify you for reduced rates on both.</p>'
            },
            'Auto & Business': {
                modalname: 'Auto & Business',
                modalHeader: 'Auto & Business',
                modalBody: '<p>Insure your Car and your Business with Farmers and get a discount on both policies.</p>'
            },
            'Auto & Umbrella': {
                modalname: 'Auto & Umbrella',
                modalHeader: 'Auto & Umbrella',
                modalBody: '<p>Bundle an Umbrella policy with your Car insurance and get a discount.</p>'
            },
            'Mature Driver': {
                modalname: 'Mature Driver',
                modalHeader: 'Mature Driver',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>If you are 55 or older, and successfully complete a Department of Motor Vehicles approved driver safety course, you may be eligible for a discount.</p>'
            },
            'Uninsured Motorist Property Damage with Collision': {
                modalname: 'Uninsured Motorist Property Damage with Collision',
                modalHeader: 'Uninsured Motorist Property Damage with Collision',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This policy option covers the property of the insured if the party at fault is uninsured or under-insured. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            MultiQuote: {
                modalname: 'MultiQuote',
                modalHeader: 'Thank you for retrieving your quote',
                modalBody: '<p>Your quote may have changed since the last time you stopped by.</p>'
            },
            retreiveQuote: {
                modalname: 'retreiveQuote',
                modalHeader: 'Thank you for retrieving your quote',
                modalBody: '<p>Your quote may have changed since the last time you stopped by.</p>'
            },
            assignmentNYFarmers: {
                modalname: 'Vehicle Driver Assignment',
                modalHeader: 'Vehicle Driver Assignment',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p class="vda-modal-content">Every Driver should be assigned to a vehicle and vice versa.<br />Any driver (minimum one) can be assigned to any number of vehicle and vice versa.</p>'
            },
            assignmentNYBW: {
                modalname: 'Vehicle Driver Assignment',
                modalHeader: 'Vehicle Driver Assignment',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p class="vda-modal-content">Multiple drivers can be assigned to one vehicle.<br />Driver cannot be assigned more than one vehicle.<br />Some vehicle can remain unassigned.</p>'
            },
            assignmentCAVAFarmers: {
                modalname: 'Vehicle Driver Assignment',
                modalHeader: 'Vehicle Driver Assignment',
                modalBody: '<p class="vda-modal-content">One vehicle can be assigned to one unique driver.</p>'
            },
            assignmentVABW: {
                modalname: 'Vehicle Driver Assignment',
                modalHeader: 'Vehicle Driver Assignment',
                modalBody: '<p class="vda-modal-content">Driver can be assigned to only one vehicle.</p>'
            },
            'Uninsured Motorist/Under Insured Motorist Bodily Injury': {
                modalname: 'Uninsured Motorist/Under Insured Motorist Bodily Injury',
                modalHeader: 'Uninsured Motorist/Under Insured Motorist Bodily Injury',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This policy option covers injuries to the insured or their passengers if the other party is at fault and has an insufficient amount of insurance. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Uninsured Motorist/Under Insured Motorist Bodily Injury ': {
                modalname: 'Uninsured Motorist/Under Insured Motorist Bodily Injury ',
                modalHeader: 'Uninsured Motorist/Under Insured Motorist Bodily Injury ',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This policy option covers injuries to the insured or their passengers if the other party is at fault and has an insufficient amount of insurance. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Uninsured Motorist Property Damage without Collision': {
                modalname: 'Uninsured Motorist Property Damage without Collision',
                modalHeader: 'Uninsured Motorist Property Damage without Collision',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Uninsured motorist property damage coverage is an optional auto insurance coverage. It is not available if collision coverage is selected. This coverage provides protection for physical damages sustained in an accident caused by a driver who is not insured.</p>'
            },
            'Under Insured Motorist Property Damage': {
                modalname: 'Under Insured Motorist Property Damage',
                modalHeader: 'Under Insured Motorist Property Damage',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This policy option covers the property of the insured if the other party is at fault and doesn\'t have insurance or has an insufficient amount of insurance.</p>'
            },
            'Rental Reimbursement': {
                modalname: 'Rental Reimbursement',
                modalHeader: 'Rental Reimbursement',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This coverage reimburses the insured for the lesser of actual daily rental charges or the purchased limit option for a maximum of 30 days for each qualified disablement on a covered vehicle. A qualified disablement means a loss covered by the Comprehensive or Collision sections of the policy. Rental Reimbursement may be purchased for any vehicle covered by Comprehensive and Collision coverages. The limit options shown in Express are per day amounts.</p>'
            },
            'Supplementary Uninsured/Underinsured Motorist Bodily Injury': {
                modalname: 'Supplementary Uninsured/Underinsured Motorist Bodily Injury',
                modalHeader: 'Supplementary Uninsured/Underinsured Motorist Bodily Injury',
                modalBody: '<p>This coverage expands Uninsured Motorist coverage outside of the state.</p>'
            },
            'PIP Medical 2nd to Other Medical Insurance': {
                modalname: 'PIP Medical 2nd to Other Medical Insurance',
                modalHeader: 'PIP Medical 2nd to Other Medical Insurance',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Select “Yes” if all drivers on the policy are covered by health insurance other than Medicare or Medicaid. If you select “Yes” the medical part of the Personal Injury Protection (PIP) coverage will be secondary to or in excess of your primary health insurance.</p>'
            },
            'PIP Work Loss 2nd to Other Work Loss Coverage': {
                modalname: 'PIP Work Loss 2nd to Other Work Loss Coverage',
                modalHeader: 'PIP Work Loss 2nd to Other Work Loss Coverage',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Select "Yes" if you have disability benefits. If you select "Yes" the work loss part of the Personal Injury Protection (PIP) coverage will be secondary to or in excess of the coverage provided by your employer.</p>'
            },
            'PIP Work Loss Waived': {
                modalname: 'PIP Work Loss Waived',
                modalHeader: 'PIP Work Loss Waived',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Select “Yes” if a driver on the policy is 60 years old or older and does not wish to be eligible for work loss benefits in the event of injury due to an auto accident.</p>'
            },
            'Michigan Broadened Collision': {
                modalname: 'Michigan Broadened Collision',
                modalHeader: 'Michigan Broadened Collision',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>If you are in an accident, your insurance pays for the physical damage to your vehicle. If the accident is not your fault, the insurance will pay your deductible. If the accident is your fault, you are responsible for the deductible.</p>'
            },
            'Michigan Limited Collision': {
                modalname: 'Michigan Limited Collision',
                modalHeader: 'Michigan Limited Collision',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>If you are in an accident and it is not your fault, you are responsible for the deductible and your insurance pays the rest. If the accident is your fault, your insurance pays nothing.</p>'
            },
            'Optional Basic Economic Loss': {
                modalname: 'Optional Basic Economic Loss',
                modalHeader: 'Optional Basic Economic Loss',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This coverage may be applied to basic economic loss; loss of earnings from work; psychiatric, physical or occupational therapy and rehabilitation; or a combination of options loss of earnings from work and psychiatric, physical or occupational therapy and rehabilitation.</p>'
            },
            'Bodily Injury Supplemental Spousal Liability': {
                modalname: 'Bodily Injury Supplemental Spousal Liability',
                modalHeader: 'Bodily Injury Supplemental Spousal Liability',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This covers the liability of the insured because of the death or injury to his or her spouse for the Bodily Injury limits provided under the policy.</p>'
            },
            'Additional Personal Injury Protection': {
                modalname: 'Additional Personal Injury Protection',
                modalHeader: 'Additional Personal Injury Protection',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This coverage expands Personal Injury Protection outside of the state and increases the possible benefits received for Maximum Monthly Work Loss and Other Necessary Expenses.</p>'
            },
            'Accidental Death': {
                modalname: 'Accidental Death',
                modalHeader: 'Accidental Death',
                modalBody: '<p>A death benefit will be paid if bodily injury from an accident causes the death of an insured.</p>'
            },
            'Additional PIP': {
                modalname: 'Additional PIP',
                modalHeader: 'Additional PIP',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This coverage expands Personal Injury Protection outside of the state and increases the possible benefits received for Maximum Monthly Work Loss and Other Necessary Expenses.</p>'
            },
            'Basic and Supplementary Uninsured/Underinsured Motorist': {
                modalname: 'Basic and Supplementary Uninsured/Underinsured Motorist',
                modalHeader: 'Basic and Supplementary Uninsured/Underinsured Motorist',
                modalBody: '<p>This coverage expands Uninsured Motorist coverage outside of the state.</p>'
            },
            'Extra PIP (Income Continuation and Essential Services)': {
                modalname: 'Extra PIP (Income Continuation and Essential Services)',
                modalHeader: 'Extra PIP (Income Continuation and Essential Services)',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>If you have selected Principal PIP coverage, this package provides higher limits on Income Continuation, Essential Services, Death Benefit and Funeral Expense coverages.</p>'
            },
            Funeral: {
                modalname: 'Funeral',
                modalHeader: 'Funeral',
                modalBody: '<p>Funeral or burial expenses incurred will be paid if bodily injury causes the death of an insured.</p>'
            },
            'Limitation on Lawsuit Option': {
                modalname: 'Limitation on Lawsuit Option',
                modalHeader: 'Limitation on Lawsuit Option',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>No Limit coverage provides insured with the right to sue the person who caused an auto accident for pain and suffering for any injury.</p>'
            },
            'Medical Payments': {
                modalname: 'Medical Payments',
                modalHeader: 'Medical Payments',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Medical payments coverage covers reasonable expenses incurred within three years from the date of accident for necessary medical services and funeral expenses if you or your passengers are injured in an accident. Medical payment coverage pays regardless of fault.</p>'
            },
            'Optional Basic Economic Loss (OBEL)': {
                modalname: 'Optional Basic Economic Loss (OBEL)',
                modalHeader: 'Optional Basic Economic Loss (OBEL)',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This coverage may be applied to basic economic loss; loss of earnings from work; psychiatric, physical or occupational therapy and rehabilitation; or a combination of options loss of earnings from work and psychiatric, physical or occupational therapy and rehabilitation.</p>'
            },
            'PIP Coverage': {
                modalname: 'PIP Coverage',
                modalHeader: 'PIP Coverage',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Personal Injury Protection (PIP) provides no fault benefits for bodily injury to each insured person injured in a motor vehicle accident. Benefits typically include reasonable medical and hospital expenses, loss of income, essential services, accidental death and funeral services. In some states, you can elect to stack PIP coverage. When you purchase stacked coverage, your maximum coverage for one accident will be amount of coverage you have purchased multiplied by the number of covered vehicles on the policy.</p>'
            },
            'Property Protection Insurance': {
                modalname: 'Property Protection Insurance',
                modalHeader: 'Property Protection Insurance',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Property Protection Insurance covers expenses related to the damage your vehicle does to other people\'s stationary property such as fences and buildings.</p>'
            },
            Rental: {
                modalname: 'Rental',
                modalHeader: 'Rental',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>For losses covered by Comprehensive or Collision coverage, Rental Reimbursement will repay the daily rental charges up to the purchased limit option for a maximum of 30 days.</p>'
            },
            'Total Disability': {
                modalname: 'Total Disability',
                modalHeader: 'Total Disability',
                modalBody: '<p>This coverage pays for loss in income that results from the bodily injury you sustain in accident.</p>'
            },
            'Transportation Expense': {
                modalname: 'Transportation Expense',
                modalHeader: 'Transportation Expense',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Transportation Expense coverage, that includes expenses for rental reimbursement, is designed to reimburse you for the temporary transportation expense for a qualified disablement, up to the purchased limit for a maximum of 30 days. This coverage is only available when the vehicle has Liability and Physical Damage coverages.</p>'
            },
            'Underinsured Motorist - Bodily Injury': {
                modalname: 'Underinsured Motorist - Bodily Injury',
                modalHeader: 'Underinsured Motorist - Bodily Injury',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This policy option covers injuries to you or your passengers in an accident if the other party is at fault and doesn\'t have insurance or has an insufficient amount of insurance.</p>'
            },
            'Under Insured Motorist Bodily Injury': {
                modalname: 'Under Insured Motorist Bodily Injury',
                modalHeader: 'Under Insured Motorist Bodily Injury',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This policy option covers injuries to you or your passengers in an accident if the other party is at fault and doesn\'t have insurance or has an insufficient amount of insurance.</p>'
            },
            'Underinsured Motorist Bodily Injury': {
                modalname: 'Underinsured Motorist Bodily Injury',
                modalHeader: 'Underinsured Motorist Bodily Injury',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This policy option covers injuries to you or your passengers in an accident if the other party is at fault and doesn\'t have insurance or has an insufficient amount of insurance.</p>'
            },
            'Uninsured Motorist - Bodily Injury': {
                modalname: 'Uninsured Motorist - Bodily Injury',
                modalHeader: 'Uninsured Motorist - Bodily Injury',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This policy option covers injuries to you or your passengers in an accident if the other party is at fault and doesn\'t have insurance or has an insufficient amount of insurance.</p>'
            },
            'Underinsured Motorist PD': {
                modalname: 'Underinsured Motorist PD',
                modalHeader: 'Underinsured Motorist PD',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This policy option covers the property of the insured if the other party is at fault and doesn\'t have insurance or has an insufficient amount of insurance.</p>'
            },
            'Uninsured/Underinsured Motorist': {
                modalname: 'Uninsured/Underinsured Motorist',
                modalHeader: 'Uninsured/Underinsured Motorist',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This policy option covers injuries to you or your passengers in an accident if the other party is at fault and doesn\'t have insurance or has an insufficient amount of insurance</p>'
            },
            'Uninsured/Underinsured Motorist Bodily Injury': {
                modalname: 'Uninsured/Underinsured Motorist Bodily Injury',
                modalHeader: 'Uninsured/Underinsured Motorist Bodily Injury',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This policy option covers injuries to the insured or their passengers if the other party is at fault and has either no or insufficient amount of insurance. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Age 30 Plus': {
                modalname: 'Age 30 Plus',
                modalHeader: 'Age 30 Plus',
                modalBody: '<p>Applies if you are over 30.</p>'
            },
            'Age 50 Plus': {
                modalname: 'Age 50 Plus',
                modalHeader: 'Age 50 Plus',
                modalBody: '<p>Applies if you are over 50.</p>'
            },
            'Age 40 Plus': {
                modalname: 'Age 40 Plus',
                modalHeader: 'Age 40 Plus',
                modalBody: '<p>Applies if you are over 40.</p>'
            },
            'Airbag Discount': {
                modalname: 'Airbag Discount',
                modalHeader: 'Airbag Discount',
                modalBody: '<p>This discount applies when your vehicle is equipped with an airbag safety system.</p>'
            },
            'Paid in Full': {
                modalname: 'Paid in Full',
                modalHeader: 'Paid in Full',
                modalBody: '<p>Get a discount for paying your policy premium in full at time of purchase.</p>'
            },
            'Paid In Full': {
                modalname: 'Paid In Full',
                modalHeader: 'Paid In Full',
                modalBody: '<p>Get a discount for paying your policy premium in full at time of purchase.</p>'
            },
            'Air Bag': {
                modalname: 'Air Bag',
                modalHeader: 'Air Bag Discount',
                modalBody: '<p>This discount applies when your vehicle is equipped with an airbag safety system.</p>'
            },
            'Alternative Fuel Vehicle': {
                modalname: 'Alternative Fuel Vehicle',
                modalHeader: 'Alternative Fuel Vehicle',
                modalBody: '<p>Get a discount if you drive a hybrid, electric and other qualifying vehicle.</p>'
            },
            'Anti-Theft': {
                modalname: 'Anti-Theft',
                modalHeader: 'Anti-Theft',
                modalBody: '<p>Applies when your vehicle is equipped with a qualifying anti-theft device(s).</p>'
            },
            'Anti-Theft Category': {
                modalname: 'Anti-Theft Category',
                modalHeader: 'Anti-Theft Category',
                modalBody: '<p>Save on your Comprehensive Coverage if your car is equipped with an approved anti-theft device.</p>'
            },
            'Anti-Theft Category T1': {
                modalname: 'Anti-Theft Category T1',
                modalHeader: 'Anti-Theft Category T1',
                modalBody: '<p>Approved anti-theft devices.</p>'
            },
            'Anti-Theft Category T2': {
                modalname: 'Anti-Theft Category T2',
                modalHeader: 'Anti-Theft Category T2',
                modalBody: '<p>Save on your Comprehensive Coverage if your car is equipped with an approved anti-theft device.</p>'
            },
            'Anti-Theft Category T3': {
                modalname: 'Anti-Theft Category T3',
                modalHeader: 'Anti-Theft Category T3',
                modalBody: '<p>Save on your Comprehensive Coverage if your car is equipped with an approved anti-theft device.</p>'
            },
            'Anti-Theft Category T4': {
                modalname: 'Anti-Theft Category T4',
                modalHeader: 'Anti-Theft Category T4',
                modalBody: '<p>Save on your Comprehensive Coverage if your car is equipped with an approved anti-theft device.</p>'
            },
            'Anti-Theft Category T5': {
                modalname: 'Anti-Theft Category T5',
                modalHeader: 'Anti-Theft Category T5',
                modalBody: '<p>Save on your Comprehensive Coverage if your car is equipped with an approved anti-theft device.</p>'
            },
            'Anti-theft Device': {
                modalname: 'Anti-theft Device',
                modalHeader: 'Anti-theft Device',
                modalBody: '<p>Save on your Comprehensive Coverage if your car is equipped with an approved anti-theft device.</p>'
            },
            'Anti-Theft Device': {
                modalname: 'Anti-Theft Device',
                modalHeader: 'Anti-Theft Device',
                modalBody: '<p>Save on your Comprehensive Coverage if your car is equipped with an approved anti-theft device.</p>'
            },
            'Auto/Home/Life': {
                modalname: 'Auto/Home/Life',
                modalHeader: 'Auto/Home/Life',
                modalBody: '<p>Save if you have multiple policies.</p>'
            },
            'Auto/Mobilehome': {
                modalname: 'Auto/Mobilehome',
                modalHeader: 'Auto/Mobilehome',
                modalBody: '<p>Save if you add a Mobile Home policy.</p>'
            },
            'Auto/Renter/Life': {
                modalname: 'Auto/Renter/Life',
                modalHeader: 'Auto/Renter/Life',
                modalBody: '<p>Save if you have multiple policies.</p>'
            },
            'Central Fire Alarm': {
                modalname: 'Central Fire Alarm',
                modalHeader: 'Central Fire Alarm',
                modalBody: '<p>Discounts for qualified devices.</p>'
            },
            'Central Burglar/Fire Alarm': {
                modalname: 'Central Burglar/Fire Alarm',
                modalHeader: 'Central Burglar/Fire Alarm',
                modalBody: '<p>Discounts for qualified devices.</p>'
            },
            'Defensive Driver': {
                modalname: 'Defensive Driver',
                modalHeader: 'Defensive Driver',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>If you are 55 or lesser, and successfully complete a Department of Motor Vehicles approved driver safety course, you may be eligible for a discount.</p>'
            },
            'Distant Student': {
                modalname: 'Distant Student',
                modalHeader: 'Distant Student',
                modalBody: '<p>Discounts for your out-of-town student.</p>'
            },
            'Rejection of Wage Loss': {
                modalname: 'Rejection of Wage Loss',
                modalHeader: 'Rejection of Wage Loss',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>If you are at least 65 years old or 60 years old with a pension, you can qualify for a discount if you agree to forgo all wage loss overage.</p>'
            },
            'Advanced Purchase': {
                modalname: 'Advanced Purchase',
                modalHeader: 'Advanced Purchase',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>If you (and spouse) are at least 19 years old a discount applies if you qualify for the Prior Insurance discount and you purchase your policy with us at least two (2) days before your policy’s effective date.</p>'
            },
            'Advance purchase': {
                modalname: 'Advance purchase',
                modalHeader: 'Advance purchase',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>If you (and spouse) are at least 19 years old a discount applies if you qualify for the Prior Insurance discount and you purchase your policy with us at least two (2) days before your policy’s effective date.</p>'
            },
            'Advance Purchase': {
                modalname: 'Advanced Purchase',
                modalHeader: 'Advanced Purchase',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>If you (and spouse) are at least 19 years old a discount applies if you qualify for the Prior Insurance discount and you purchase your policy with us at least two (2) days before your policy’s effective date.</p>'
            },
            'Guest PIP': {
                modalname: 'Guest PIP',
                modalHeader: 'Guest PIP',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Selecting Guest PIP will waive PIP Coverage for the named insured, all listed drivers on the policy and any members of the insured\'s family who are 16 years of age or older and reside in the insured\'s household. In a new customer quote in Express, you must select either Personal Injury Protection (PIP), or Guest PIP</p>'
            },
            'guest PIP': {
                modalname: 'guest PIP',
                modalHeader: 'guest PIP',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Selecting Guest PIP will waive PIP Coverage for the named insured, all listed drivers on the policy and any members of the insured\'s family who are 16 years of age or older and reside in the insured\'s household. In a new customer quote in Express, you must select either Personal Injury Protection (PIP), or Guest PIP</p>'
            },
            'Driver Improvement': {
                modalname: 'Driver Improvement',
                modalHeader: 'Driver Improvement',
                modalBody: '<p>Available for drivers 55 or older who completed a safe driving course approved by the DMV.</p>'
            },
            'Early Shopping': {
                modalname: 'Early Shopping',
                modalHeader: 'Early Shopping',
                modalBody: '<p>Applies when you get a quote seven days before your new policy start date.</p>'
            },
            EarlyShoppingMessage: {
                modalname: 'Early Shopping',
                modalHeader: 'Policy Start Date',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>What is your auto policy start date, you can get an early shopping discount by purchasing at least 7 days before your current policy expires.</p>'
            },
            'HighDown Payment': {
                modalname: 'HighDown Payment',
                modalHeader: 'HighDown Payment',
                modalBody: '<p>Get a discount when you choose a payment plan with a high down payment amount.</p>'
            },
            'Mobile Home': {
                modalname: 'Mobile Home',
                modalHeader: 'Mobile Home',
                modalBody: '<p>Get a discount if you own the home, mobile home, town home or Condominium in which you reside.</p>'
            },
            'Multi-Car Discount Across Company': {
                modalname: 'Multi-Car Discount Across Company',
                modalHeader: 'Multi-Car Discount Across Company',
                modalBody: '<p>Save with two or more cars.</p>'
            },
            'New Customer': {
                modalname: 'New Customer',
                modalHeader: 'New Customer',
                modalBody: '<p>Switch to Farmers and get a discount if you have no lapse in coverage with your prior carrier.</p>'
            },
            'New Home': {
                modalname: 'New Home',
                modalHeader: 'New Home',
                modalBody: '<p>Homes constructed during current year.</p>'
            },
            PIP: {
                modalname: 'PIP',
                modalHeader: 'PIP',
                modalBody: '<p>Save on Personal Injury Protection coverage when there are multiple cars and one driver.</p>'
            },
            'Preferred payment plan': {
                modalname: 'Preferred payment plan',
                modalHeader: 'Preferred payment plan',
                modalBody: '<p>Preferred Payment Plan.</p>'
            },
            'Premier YES': {
                modalname: 'Premier YES',
                modalHeader: 'Premier YES',
                modalBody: '<p>Completed approved Teen Driver Course.</p>'
            },
            'Prior Carrier': {
                modalname: 'Prior Carrier',
                modalHeader: 'Prior Carrier',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Applies when you have at least six (6) months of continuous coverage with the same insurance company without a lapse.</p>'
            },
            'Safety Features': {
                modalname: 'Safety Features',
                modalHeader: 'Safety Features',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Discount for cars equipped with standard safety features like anti-lock brakes, airbags, automatic seatbelts, or Electronic Stability Control.</p>'
            },
            'Senior Defensive Driver': {
                modalname: 'Senior Defensive Driver',
                modalHeader: 'Senior Defensive Driver',
                modalBody: '<p>Available for drivers 55 or older who completed a safe driving course approved by the DMV.</p>'
            },
            'Medical and Hospital Benefits': {
                modalname: 'Medical and Hospital Benefits',
                modalHeader: 'Medical and Hospital Benefits',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Provide benefits for bodily injury to each person injured in a no-fault motor vehicle accident. Benefits typically include reasonable medical and hospital expenses, loss of income, essential services, accidental death, and funeral services.</p>'
            },
            'Shared Family Car': {
                modalname: 'Shared Family Car',
                modalHeader: 'Shared Family Car',
                modalBody: '<p>You may get a discount if you share the family car with a driver in your household under 20 years old.</p>'
            },
            'Teen Driver': {
                modalname: 'Teen Driver',
                modalHeader: 'Teen Driver',
                modalBody: '<p>Save if you meet eligibility requirements.</p>'
            },
            'Share - Auto': {
                modalname: 'Share - Auto',
                modalHeader: 'Share - Auto',
                modalBody: '<p>You may get a discount if you share the family car with a driver in your household under 20 years old.</p>'
            },
            'Farmers Identity Scheild(SM)': {
                modalname: 'Farmers Identity Scheild(SM)',
                modalHeader: 'Farmers Identity Scheild(SM)',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Farmers Identity Shield is an optional bundle that provides coverage for losses due to identity theft as well as proactive identity management services, including:Annual identity report(annual credit & public records),Continuous credit and public records monitoring,24 / 7 access to a professional support for your identity recovery process Up to $30,000 in insurance coverage.For more information,contact your local Farmers Agent. < /p>'
            },
            'Personal Injury Protection – Deductible Applies To': {
                modalname: 'Personal Injury Protection - Deductible Applies ToTeen Driver',
                modalHeader: 'Personal Injury Protection - Deductible Applies To',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>The “Personal Injury Protection Deductible Applies to” line on your policy indicates who that deductible applies to, either just the named insured or the named insured and dependents as well.</p>'
            },
            'Personal Injury Protection – Deductible': {
                modalname: 'Personal Injury Protection - Deductible',
                modalHeader: 'Personal Injury Protection - Deductible',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>The Personal Injury Protection (PIP) deductible is that portion of the PIP loss that is not paid by insurance. It is subtracted from the amount the insurer would be obligated to pay.</p>'
            },
            'Personal Injury Protection – Exclusion of Work Loss': {
                modalname: 'Personal Injury Protection – Exclusion of Work Loss',
                modalHeader: 'Personal Injury Protection – Exclusion of Work Loss',
                modalBody: '<p>Excludes coverage for lost wages caused by a covered accident.</p>'
            },
            Rideshare: {
                modalname: 'Rideshare',
                modalHeader: 'Rideshare',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Farmers offers specific ride-sharing coverage in select states. This policy enables you to select the coverage that fits your needs, including:<li>Comprehensive and collision coverages that help pay for damages to your car</li><li>Uninsured motorist coverage, in case you are hit by a driver who isn’t insured or is underinsured</li><li>Medical payment and personal injury protection (if required).</li></p>'
            },
            'Enhanced Uninsured/Underinsured Motorist Bodily Injury': {
                modalname: 'Enhanced Uninsured/Underinsured Motorist Bodily Injury',
                modalHeader: 'Enhanced Uninsured/Underinsured Motorist Bodily Injury',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This coverage pays you the amount you are legally entitled to recover from damages for bodily injury, up to the limit of liability, when you are hit by an underinsured motorist.  Enhanced Underinsured Motorist limits must equal Bodily Injury limits.  The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Enhanced Uninsured Motorist Property Damage': {
                modalname: 'Enhanced Uninsured Motorist Property Damage',
                modalHeader: 'Enhanced Uninsured Motorist Property Damage',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This coverage pays you the amount you are legally entitled to recover from damages for Property Damage, up to the limit of liability less any applicable deductible, when you are hit by an underinsured motorist.  Enhanced Underinsured Motorist Property Damage limits must equal Property Damage limits.  The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Enhanced Underinsured Motorist': {
                modalname: 'Enhanced Underinsured Motorist',
                modalHeader: 'Enhanced Underinsured Motorist',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This coverage pays you the amount you are legally entitled to recover from damages for bodily injury, up to the limit of liability, when you are hit by an underinsured motorist.  Enhanced Underinsured Motorist limits must equal Bodily Injury limits.  The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            // GW3 changes - US105407 UMBI and UIMBI coverage label and help text updates for IN ,MN, MD , MO, CO and NV states - start
            'Enhanced Underinsured Motorist Bodily Injury': {
                modalname: 'Enhanced Underinsured Motorist Bodily Injury',
                modalHeader: 'Enhanced Underinsured Motorist Bodily Injury',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This coverage pays you the amount you are legally entitled to recover from damages for bodily injury, up to the limit of liability, when you are hit by an underinsured motorist.  Enhanced Underinsured Motorist limits must equal Bodily Injury limits.  The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            // GW3 changes - US105407 UMBI and UIMBI coverage label and help text updates for IN ,MN, MD , MO, CO and NV states - end
            'Enhanced Underinsured Motorist Property Damage': {
                modalname: 'Enhanced Underinsured Motorist Property Damage',
                modalHeader: 'Enhanced Underinsured Motorist Property Damage',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This coverage pays you the amount you are legally entitled to recover from damages for Property Damage, up to the limit of liability less any applicable deductible, when you are hit by an underinsured motorist.  Enhanced Underinsured Motorist Property Damage limits must equal Property Damage limits.  The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Residency Insurance': {
                modalname: 'Residency Insurance',
                modalHeader: 'Residency Insurance',
                // tslint:disable-next-line:max-line-length
                modalBody: 'Either the name insured or spouse must also be the named insured under a homeowner ,condominium owner ,renters or mobile home owners insurance policy covering primary residence.'
            },
            homeReviewConsent: {
                modalname: 'Please review',
                modalHeader: 'Please review',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>You will be receiving a Privacy Notice that gives you the choice of opting out of allowing Farmers to share your consumer report information, such as your credit based insurance score or loss history, among our affiliates.</p><p>In the interim, may we use the consumer report information we currently have on file for you for this specific transaction?</p><p>You will still be able to opt out of any future sharing.</p>'
            },
            'Medical CoverageForVA': {
                modalname: 'Medical Coverage',
                modalHeader: 'Medical Expense',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Medical Expense pays reasonable expenses incurred by the insured or passengers within three years from the date of accident for necessary medical services and funeral expenses. Medical Expense coverage is afforded regardless of fault. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Towing & Road ServiceFSPA': {
                modalname: 'Towing and Road Service',
                modalHeader: 'Towing and Road Service',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Covers reasonable and necessary towing and labor costs incurred because of disablement of an insured car.</p>'
            },
            'Towing & Road ServiceVA': {
                modalname: 'Towing and Road Service',
                modalHeader: 'Towing and Labor Costs',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This coverage reimburses the insured for the cost of towing a disabled vehicle and is only available with collision coverage. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Uninsured Motorist BI_IL': {
                modalname: 'Uninsured Motorist BI',
                modalHeader: 'Uninsured Motorist BI',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This coverage pays you the amount you are legally entitled to from damages for bodily injury, up to the limit of liability, when you are hit by an uninsured driver or an unidentified (e.g. hit-and-run) driver. Uninsured Motorist Bodily Injury limits cannot be higher than Bodily Injury limits. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Uninsured Motorist Property Damage without Collision_LA': {
                modalname: 'Uninsured Motorist Property Damage without Collision',
                modalHeader: 'Uninsured Motorist Property Damage',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This policy option covers the property of the insured if the party at fault is uninsured or under-insured. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },

            PackageDescriptionTexts: {
                basic: 'Provides basic liability coverage for damages that  result from an accident or covered loss.',
                enhanced: 'Provides additional liability coverage for damages if you have an accident or other covered loss.',
                full: 'Provides higher liability coverage for bodily injury or property damage.',
                custom: 'Your custom package with the options you selected.'
            },
            InvalidRetreivalQuote: {
                modalHeader: 'We were unable to locate your quote',
                // tslint:disable-next-line: max-line-length
                modalBody: 'We were unable to locate a quote for the information entered. Please re-enter and try again or select Get a New Quote.'
            },
            'Underinsured Motorist BI': {
                modalname: 'Underinsured Motorist BI',
                modalHeader: 'Underinsured Motorist BI',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This policy option covers injuries to the insured or their passengers if the other party is at fault and has an insufficient amount of insurance. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Underinsured Motorist BI_KY': {
                modalname: 'Underinsured Motorist BI',
                modalHeader: 'Underinsured Motorist Bodily Injury',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This policy option covers injuries to the insured or their passengers if the other party is at fault and has an insufficient amount of insurance. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            errorModal: {
                modalHeader: 'Error',
                // tslint:disable-next-line:max-line-length
                modalBody: 'We\'re sorry. We are unable to complete your request at this time. Please try again.',
                buttonName: 'CONTINUE'
            },
            DuplicateerrorModal: {
                modalHeader: 'Error',
                // tslint:disable-next-line:max-line-length
                modalBody: 'This VIN is already added for a vehicle.',
                buttonName: 'CONTINUE'
            },
            deletePni: {
                modalHeader: 'Error',
                modalBody: 'The primary driver on the quote cannot be removed.',
                buttonName: 'OK'
            },
            deleteOtherDriver: {
                modalHeader: 'Error',
                modalBody: 'This driver is required for your quote and cannot be removed.',
                buttonName: 'OK'
            },
            spouseRequiredInfo: {
                modalHeader: '[Driver]\'s spouse is required.',
                modalBody: 'To remove spouse from the quote, click on \"Edit details\" and modify [Driver]\'s selected marital status.',
                buttonName: 'OK'
            },
            startYearOfBusiness: {
                modalname: 'Start Year of Business',
                modalHeader: '',
                // tslint:disable-next-line: max-line-length
                modalBody: '<p>Use the most recent Of these:<span><br/>&nbsp;&nbsp;&#8226the year your company was registered as a business entity with the state or federal government;</span><span><br/>&nbsp;&#8226the year you filed your DBA or fictitious name;</span><span><br/>&nbsp;&#8226or the year you started operations.</p>',
                buttonName: 'OK'
            },
            currentlyInsuredBusiness: {
                modalname: 'currentlyInsuredBusiness',
                modalHeader: '',
                modalBody: 'Answer “yes” if you currently have a business insurance policy that covers your company’s operations.',
                buttonName: 'OK'
            },
            businessEmployees: {
                modalname: 'businessEmployees',
                modalHeader: '',
                modalBody: 'Includes employees, interns, and family members.',
                buttonName: 'OK'
            },
            businessVehicles: {
                modalname: 'businessVehicles',
                modalHeader: '',
                // tslint:disable-next-line: max-line-length
                modalBody: 'Includes vehicles owned by the business or employee vehicles used for company business. Does not include commuting to and from the business.',
                buttonName: 'OK'
            },
            'Compulsory Bodily Injury (Part 1)': {
                modalname: 'Compulsory Bodily Injury (Part 1)',
                modalHeader: 'Compulsory Bodily Injury',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>If you are legally obligated to pay damages due to bodily injury to others, and the injury occurred due to a covered accident, this coverage will pay damages. If you are sued because of a covered automobile accident, the cost of your legal defense is also covered. The limit is the maximum the company will pay for any single occurrence, per policy (not per auto). <br> Compulsory Bodily Injury is mandatory in the state of MA.  Compulsory Bodily Injury coverage does not pay for injuries to the driver or passengers in your auto.  Compulsory Bodily Injury only covers accidents that occur in MA.  The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Optional Bodily Injury (Part 5)': {
                modalname: 'Optional Bodily Injury (Part 5)',
                modalHeader: 'Optional Bodily Injury',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Additional coverage for bodily injury to others, providing bodily injury coverage for passengers in your auto, and covering you if the accident occurs outside Massachusetts. The limit is the maximum the company will pay for any single occurrence, per policy (not per auto). The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Uninsured Motorist Bodily Injury (Part 3)': {
                modalname: 'Uninsured Motorist Bodily Injury (Part 3)',
                modalHeader: 'Uninsured Motorist Bodily Injury',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This coverage pays you the amount you are legally entitled to from damages for bodily injury, up to the limit of liability, when you are hit by an uninsured driver or an unidentified (e.g. hit-and-run) driver. Uninsured Motorist limits cannot be higher than Bodily Injury limits. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Underinsured Motorist Bodily Injury (Part 12)': {
                modalname: 'Underinsured Motorist Bodily Injury (Part 12)',
                modalHeader: 'Underinsured Motorist Bodily Injury',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This policy option covers injuries to the insured or their passengers if the other party is at fault and has an insufficient amount of insurance. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Medical Payments (Part 6)': {
                modalname: 'Medical Payments (Part 6)',
                modalHeader: 'Medical Payments',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Medical coverage pays reasonable expenses incurred by the insured or passengers within two years from the date of accident for necessary medical services and funeral expenses. Medical payment coverage is afforded regardless of fault. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Collision (Part 7)': {
                modalname: 'Collision (Part 7)',
                modalHeader: 'Collision',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Collision provides coverage for physical damage to the auto caused by collision with another vehicle or object regardless of fault. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Personal Injury Protection - Deductible': {
                modalname: 'Personal Injury Protection - Deductible',
                modalHeader: 'Personal Injury Protection - Deductible',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>The Personal Injury Protection (PIP) deductible is that portion of the PIP loss that is not paid by insurance. It is subtracted from the amount the insurer would be obligated to pay.</p>'
            },
            'Collision Waiver of Deductible': {
                modalname: 'Collision Waiver of Deductible',
                modalHeader: 'Collision Waiver of Deductible',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>With the Limited Waiver of Collision Deductible, the deductible amount for Collision (Part 7) does not apply if: (1) the auto was legally parked; (2) the auto was struck in the rear by another auto moving in the same direction; (3) the operator of the other vehicle was convicted of one of the following violations: Operating Under the Influence, Driving the wrong way on a one way street, operating at an excessive rate of speed, or similar violations; or (4) you are entitled to recover in court against an identified person for some reason other than those listed above. In all cases, the owner of the auto that strikes our vehicle must be an identified person.</p>'
            },
            'Limited Collision (Part 8)': {
                modalname: 'Limited Collision (Part 8)',
                modalHeader: 'Limited Collision',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Limited Collision provides coverage for physical damage to the auto caused by collision with another vehicle or object if you are not at fault.  If you are more than 50% at fault, or the owner of the other auto cannot be identified, you will not receive payment. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Towing and Labor (Part 11)': {
                modalname: 'Towing and Labor (Part 11)',
                modalHeader: 'Towing and Labor',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This coverage reimburses the insured for the cost of towing a disabled vehicle and is only available with collision coverage. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract.</p>'
            },
            'Substitute Transportation (Part 10)': {
                modalname: 'Substitute Transportation (Part 10)',
                modalHeader: 'Substitute Transportation',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This coverage reimburses the insured for the lesser of actual daily rental charges or the purchased limit option for a maximum of 30 days for each qualified disablement on a covered auto. A qualified disablement means a loss covered by the Comprehensive or Collision sections of the policy. The limit options shown are per day amounts.</p>'
            },
            'Uninsured Motorist BI Options': {
                modalname: 'Uninsured Motorist BI Options',
                modalHeader: 'Uninsured Motorist BI options',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>The full option covers monetary damages like medical costs, funeral expenses and lost wages.  It also covers non-monetary damages like pain and suffering and mental anguish.  The economic option only covers monetary damages.</p>'
            },
            'Substitution Transportation (Part 10)': {
                modalname: 'Substitution Transportation (Part 10)',
                modalHeader: 'Substitution Transportation',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>This coverage reimburses the insured for the lesser of actual daily rental charges or the purchased limit option for a maximum of 30 days for each qualified disablement on a covered auto. A qualified disablement means a loss covered by the Comprehensive or Collision sections of the policy. The limit options shown are per day amounts.</p>'
            },
            'Rideshare Coverage': {
                modalname: 'Rideshare Coverage',
                modalHeader: 'Rideshare Coverage',
                // tslint:disable-next-line:max-line-length
                modalBody: '<p>Farmers offers specific ridesharing coverage in select states. This policy enables you to select the coverage that fits your needs, including: <br> •  Comprehensive and collision coverages that help pay for damages to your auto. <br> •  Bodily injury Caused by an Uninsured or Underinsured Auto, in case you are hit by a driver who isn’t insured or is underinsured. <br> •  Medical payments and personal injury protection (if required).</p>'
            },
            // R03 changes - US130849 - Modify driver license country question(for PNI)
            isLicenseCountryForeign: {
                modalname: 'License Country Foreign',
                modalHeader: '',
                // tslint:disable-next-line:max-line-length
                modalBody: ' Answer “yes” if this driver currently holds a valid license issued by a U.S. state, U.S. territory, or Canadian province.'
            },
            optOutMIPIPMModal: {
                modalHeader: 'To opt out of PIP Medical:',
                modalBody: '<p><span>You must be enrolled in Medicare, and;</span></br><span>Your spouse and any resident relatives must ' +
                    'have health care coverage under:</span><ul class="auto-quote-mi-popup-list">' +
                    '<li>A qualified health care plan, or</li><li>' +
                'Medicare Parts A and B, or<li>Coverage under another Auto insurance policy that provides PIP medical coverage</li>' +
                    '<ul></li></ul></p>',
                modalConfirmationText: '<p>Will you be able to provide the proof of required coverage within 7 days of buying your ' +
                    'policy?</p>',
                confirmButtonText: 'YES',
                cancelButtonText: 'NO'
            },
            otherMIPIPMModal: {
                modalHeader: 'To select these coverage limits:',
                modalBody: '<p><span>You must be enrolled in Medicaid, and;</span></br><span>Your spouse and any resident relatives ' +
                'must have health care coverage under<ul class="auto-quote-mi-popup-list"><li>Medicaid, or</li><li>' +
                'A qualified health care plan, or</li><li>Medicare Parts A and B, or<li>Coverage under another Auto insurance ' +
                'policy that provides PIP medical coverage</li><ul></li></ul></p>',
                modalConfirmationText: '<p>Will you be able to provide the proof of required coverage within 7 days of buying your ' +
                    'policy?</p>',
                confirmButtonText: 'YES',
                cancelButtonText: 'NO'
            }
        };
    }

    public static get PO_BOX_PATTERNS(): any {
        return {
            patterns: ['pobox', 'po box', 'p.o box', 'p.o.box', 'po.box', 'p.o. box', 'p o box', 'post office', 'PO BX'],
        };
    }

    public static get REGEX(): any {
        return {
            stringWithoutSpace: '^[a-zA-Z]*$',
            stringWithSpace: '^[a-zA-Z ]*$',
            stringCity: '^[a-zA-Z ]{3,}$',
            stringValidation: /[a-zA-Z]{3,}/,
            nameValidation: /^[a-zA-Z][a-zA-Z- ]+$/,
            suiteValidation: /^[0-9A-Za-z -]+$/,
            businessNameValidation: /^[0-9A-Za-z &'-]+$/,
            nameField: '^[A-Za-z -]+$',
            numbersOnly: '^[0-9]+$',
            apartmentNumber: /^[A-Za-z0-9-#\/ ]+$/,
            quoteNumberExp: /^(\d{8}|\d{10})$/,
            ssn: /^(\s*|(X|\d)(X|\d)(X|\d)-+(X|\d)(X|\d)-+\d\d\d\d)$/,
            // tslint:disable-next-line:max-line-length
            ssnValid: '^(?!\\b(\\d)\\1+-(\\d)\\1+-(\\d)\\1+\\b)(?!123-45-6789|219-09-9999|078-05-1120)(?!666|000|9\\d{2})\\d{3}-(?!00)\\d{2}-(?!0{4})\\d{4}$',
            numberSpecialCharValidation: '^[A-Za-z ]+$',
            // tslint:disable-next-line:max-line-length
            dateValidation: /^((((0[013578])|([13578])|(1[02]))[\/](([1-9])|([0-2][0-9])|(3[01])))|(((0[469])|([469])|(11))[\/](([1-9])|([0-2][0-9])|(30)))|((2|02)[\/](([1-9])|([0-2][0-9]))))[\/]\d{4}$|^\d{4}$/,
            usZipCode: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
            appartmentValidation: /^[A-Za-z0-9]$/,
            milesOneWay: /^[0-9]*$/,
            VINnumber: /^([a-zA-Z0-9_-]){17}$/,
            URLcheck: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.\:]{1}[a-z0-9]+)*(:[0-9]{1,5})?(\/.*)?$/,
            VINAlphaNumeric: /^(?=.*?[a-z])(?=.*?\d)[a-z\d]+$/i,
            VINFormat: '^[^ioqIOQ]*$',
            VINDigit: '^[A-Za-z0-9]{8}([xX]|[0-9]){1}[A-Za-z0-9]+$',
            mobileNumber: /^\(+[^0-1$]\d\d\)+[^0-1$]\d\d\-+\d\d\d\d/,
            mobileNumberForFL: /^\(+[^0-1$]\d\d\)+[^0-1$]\d\d\-+\d\d\d\d/,
            mobileNo: /^([789]\d{9}$)/,
            mobileNowithCode: /^(0[789]\d{9}$)/,
            pincode: /^\d{6}$/,
            yrsOfRes: /^[0-9]*$/,
            engineNo: /^[0-9]{5,30}$/,
            odometerReading: /^[0-9]{2,10}$/,
            loanAmount: /^[1-9][0-9]*$/,
            ratio: /^[0-9:]+$/,
            tractorratio: /^\d{2}\:\d{2}$/,
            cubicCapacity: /^[0-9]{2,5}$/,
            companyName: /^[A-Za-z ]{5,25}$/,
            panNo: /[A-Za-z]{5}\d{4}[A-Za-z]{1}$/,
            vehicleNo: /^[A-Za-z0-9-]*$/,
            drivingLicence: /^[A-Za-z0-9 ]{13,16}$/,
            doorNo: /^[A-Za-z0-9_@.\/#&]{3,}$/,
            accountNo: /^[A-Za-z0-9]{12,16}$/,
            voterID: /^[0-9]{10}$/,
            cardNo: /^\d{15,20}$/,
            landlineNo: /^0[0-9]{10,12}$/,
            landlineCode: /^0[0-9]{1,5}$/,
            landlinePhone: /^[0-9]{1,8}$/,
            noOfFreeVehicle: /^[0-9]+$/,
            passportNo: /^[A-Za-z0-9]{8}$/,
            percentages: /^\d{1,2}\.?\d{1,2}$/,
            floatValid: /^\d{0,2}(\.\d{0,2}){0,1}$/,
            landArea: /^\d{0,5}(\.\d{1,2}){0,1}$/,
            alphaNumeric: /^[A-Za-z0-9 ]+$/,
            otherDocuments: /^[A-Za-z0-9 ]{10,20}$/,
            alphaNumericWithoutSpace: /^[A-Za-z0-9]+$/,
            alphaNumericWithoutSpaceWithSymbol: /^[A-Za-z0-9\/-]+$/,
            alphaNumericWithSpaceWithSpecialcharacter: /^[ A-Za-z0-9_@.\/#&+,:;-]*$/,
            address: /^[A-Za-z0-9-/# ,.]+[A-Za-z0-9]*[A-Za-z-/# ,.]+[A-Za-z0-9-/# ,.]*$/,
            multiWord: /^(?=.*\d)(?=.*[a-z|A-Z])(^\w+(\s+\w+.*))$/,
            addressWithSpecialChracter: /^[A-Za-z0-9\/\-\,\#\. ]+$/,
            instrumentNo: /^\d{6,9}$/,
            number: /^[0-9]+$/,
            registrationNo: /^[a-zA-Z]{2}[A-Za-z0-9\/-]*\d{3}$/,
            registeredOwner: /^[A-Za-z ]{8,15}$/,
            noZero: /^[1-9][0-9]*$/,
            pan: /^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/,
            passport: /^[A-Za-z]{1}\d{7}$/,
            receipt_or_cheque: /^(([BT]{1}[A-Za-z0-9]{16})|([0-9]{6,9})|([A-z0-9]{7,16}))$/,
            floatNumber: /^(?=.*\d)\d*(?:\.\d+)?$/,
            emailValidation: /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i,
            ifsc: /[A-Z|a-z]{4}[0][0-9|A-Z]{6}$/,
            courierName: /^(\d+[A-z]+\w+|[A-z ]+\w+)*$/,
            mobileNoRepeat: /^(\d)(?!\1+$)\d*$/,
            poBoxExp: '\\b[p]*(ost)*\\.*\\s*[o|0]*(ffice)*\\.*\\s*b[o|0]x',
            boxExp: '\\b([p][o|0]b\\.*\\s*[0-9]+|[b][o|0]x\\.*\\s*[0-9]+|[p]o\\.*\\s*[0-9]+)',
            zeroValidation: /[1-9]\d*/,
            apartment: /^[A-Za-z0-9-#\/ ]+$/,
            yearlyMiles: /^[0-9]{1,6}$/,
            OneWayMileDecimal: /^[0-9]{1,6}$/,
            otherOccupation: /^[A-z\s]+$/,
            htmlRegex: /<[^<>]*>/gm,
            spChar: /\"/g,
            spCharSlash: /\'/g,
            newLine: /\s\s+/g,
            agentPhoneNumberFormat: /(\d{3})(\d{3})(\d{4})/
        };
    }

    public static get FLOW(): any {
        return {
            crossSell: 'CS',
            retreiveQuote: 'RQ',
            outBound: 'OB',
            retrieveBuy: 'RB',
            reverseSubmit: 'RS'
        };
    }

    public static get BUTTONTEXT(): any {
        return {
            BuyNow: 'BUY NOW',
            Continue: 'CONTINUE',
            Recalculate: 'RECALCULATE'
        };
    }

    public static get LOB(): any {
        return {
            auto: 'A',
            home: 'H',
            condo: 'C',
            renter: 'R',
            business: 'B',
            life: 'L',
            gdb: 'G',
            sws: 'S',
            stl: 'T'

        };
    }

    public static get CreditDriverType(): any {
        return {
            primary: 'primary',
            secondary: 'Secondary'
        };
    }

    public static get LOB_CS(): any {
        return {
            auto: 'a',
            home: 'h',
            condo: 'c',
            renter: 'r',
            business: 'b',
            life: 'l'
        };
    }
    public static get ExistingCustomerCrossSellOptions(): any {
        return [{
            lob: 'A',
            label: 'Auto Insurance',
            value: 'auto'
        }, {
            lob: 'H',
            label: 'Home Insurance',
            value: 'home',
        }, {
            lob: 'C',
            label: 'Condo Insurance',
            value: 'condo',
        }, {
            lob: 'R',
            label: 'Renters Insurance',
            value: 'renter',
        }, {
            lob: 'L',
            label: 'Life Insurance',
            value: 'life',
        }];
    }
    public static get STATE_CODES(): any {
        return {
            CALIFORNIA: 'CA',
            ARIZONA: 'AZ',
            FLORIDA: 'FL',
            MASSACHUSETTS: 'MA',
            KENTUCKY: 'KY',
            MISSISSIPPI: 'MS',
            LOUISIANA: 'LA',
            NORTH_CAROLINA: 'NC',
            MICHIGAN: 'MI',
            MINNESOTA: 'MN',
            OHIO: 'OH',
            UTAH: 'UT',
            ILLINOIS: 'IL',
            PENNSYLVANIA: 'PA',
            TEXAS: 'TX',
            WASHINGTON: 'WA',
            NEW_MEXICO: 'NM',
            IDAHO: 'ID',
            WISCONSIN: 'WI',
            OREGON: 'OR',
            HAWAII: 'HI',
            ARKANSAS: 'AR',
            NEBRASKA: 'NE',
            NEW_JERSEY: 'NJ',
            TENNESSEE: 'TN',
            GEORGIA: 'GA',
            VIRGINIA: 'VA',
            WYOMING: 'WY',
            NEWYORK: 'NY',
            NORTHDAKOTA: 'ND',
            SOUTHDAKOTA: 'SD',
            COLORADO: 'CO',
            NEVADA: 'NV',
            ALABAMA: 'AL'
        };
    }

    public static get EE_STATES(): any {
        return {
            EEStates: 'MA,KY,NC,LA,MS'
        };
    }

    public static get GTM_CONSTANTS_YMM(): any {
        return {
            FINANCED: 'Financed',
            LEASED: 'Leased',
            OWNED: 'Owned',
            MASSACHUSETTS: 'MA',
            COMMUTINGTOWORK: 'Commuting to/from work or school',
            BUSINESS: 'Business',
            PLEASURE: 'Pleasure',
            FFQ_AUTO_VEHICLE_USEPLEASURE_RADIO: 'FFQ_Auto_Vehicle_usePleasure_radio',
            FFQ_AUTO_VEHICLE_USEBUSINESS_RADIO: 'FFQ_Auto_Vehicle_useBusiness_radio',
            FFQ_AUTO_VEHICLE_USECOMMUTE_RADIO: 'FFQ_Auto_Vehicle_useCommute_radio',
            FFQ_AUTO_VEHICLE_OWNERSHIP_OWNED_RADIO: 'FFQ_Auto_Vehicle_ownership_Owned_radio',
            FFQ_AUTO_VEHICLE_OWNERSHIP_LEASED_RADIO: 'FFQ_Auto_Vehicle_ownership_leased_radio',
            FFQ_AUTO_VEHICLE_OWNERSHIP_FINANCED_RADIO: 'FFQ_Auto_Vehicle_ownership_financed_radio'
        };
    }

    public static get LOBS(): any {
        return {
            H: 'Home',
            R: 'Renters',
            C: 'Condo',
            L: 'Life',
            B: 'Business',
            U: 'Umbrella'
        };
    }
    public static get INFO_ICON_NAME(): any {
        return {
            homeOwnerShip: 'DISCOUNT_HOMEOWNERSHIP',
            bundleAndSave: 'DISCOUNT_BUNDLE_AND_SAVE',
            policyDate: 'DISCOUNT_POLICY_DATE',
            homeOwnerShipNC: 'DISCOUNT_HOMEOWNERSHIP_NC'
        };
    }
    public static get DJM_LOB_NAME(): any {
        return {
            H: 'Auto & Home',
            R: 'Auto & Rent',
            C: 'Auto & Condo',
            L: 'Auto & Life',
            B: 'Auto & Business',
            U: 'Auto & Umbrella',
            M: 'Motorhome, Travel Trailer or 5th Wheel Insurance',
            O: 'Off-Road Vehicle Insurance',
            W: 'Recreational Boat/Watercraft Insurance',
            T: 'Motorcycle Insurance'
        };
    }
    public static get CONTENT(): any {
        return {
            homeOwnerShip: 'Great news! You’re eligible for the Homeowner\'s Discount.',
            homeOwnerShipNC: 'Great news! You may be eligible for the Homeowner\'s Discount.',
            multiPolicyDiscount: 'Nice! You are eligible for multi policy discount when you bundle',
            specialityDiscount: 'Nice! You’re eligible for a discount when you bundle Farmers products.',
            specialityDiscountNC: 'Nice! You may be eligible for a discount when you bundle Farmers products.',
            multiCarDiscount: 'Congratulations! We have a Multi-Vehicle Discount.',
            vehicleFeatureDiscount: 'Awesome! This vehicle may be eligible for our Vehicle Features Discount.',
            occupationDiscount: 'Congratulations! You may qualify for our Affinity Discount.',
            distantStudentDiscount: 'Great news! You may qualify for our Distant student Discount.',
            defensiveDriverDiscount: 'Great news! You may qualify for our Defensive Driver Discount.',
            goodStudentDiscount: 'Great news! You may qualify for our Good Student Discount.',
            safeAndAccidentFree: 'Great news! That qualifies for our Accident Free and Safe Driver Discount.',
            safeDriverDiscount: 'Great news! That qualifies for our Safe Driver Discount.',
            sharedFamilyCarDiscount: 'Great news! You may qualify for our Shared Family Car Discount.',
            paperLessDiscount: 'Thanks! You can also get our Paperless Discount.',
            matureDriverDiscount: 'Great News! You may qualify for mature driver discount',
            accidentFree: 'Great news! That qualifies for our Accident Free Discount.'
        };
    }
    public static get SERVICE_NAME(): any {
        return {
            knockout: 'KnockOut'
        };
    }
    public static get DJM_DISCOUNT_NAME(): any {
        return {
            vehicleFeature: 'Vehicle Feature',
            multipleCar: 'Multiple Car',
            sharedFamilyCarDiscount: 'Shared Family Car Discount',
            goodStudent: 'Good Student',
            defensiveDriver: 'Defensive Driver',
            distantStudent: 'Distant Student Discount',
            noAccident: 'No Accident',
            occupation: 'Business/Professional Group',
            safeDriver: 'Safe Driver Discount',
            accidentFree: 'Five Year Accident Free',
            paperLess: 'Paperless',
            earlyShopping: 'Early Shopping',
            homeOwner: 'Homeowner',
            electronicFundTransfer: 'Electronic Funds Transfer',
            sharedFamilyCar: 'Shared Family Car',
            multipleCarAcrossCompany: 'Multi-Car Discount Across Company',
            paidInFull: 'Paid in Full'
        };
    }
    /** US203892: Custom Dimension for Signal Discounts */
    public static get SIGNAL_DATALAYER_TAGS(): any {
        return {
            signalAppliedPNI: 'PNI',
            signalNotOffered: 'signalnotoffered',
            nosignal: 'signalremovedqf'
        };
    }
    public static get STATE_LIST(): any {
        return {
            AL: 'Alabama',
            AK: 'Alaska',
            AZ: 'Arizona',
            AR: 'Arkansas',
            CA: 'California',
            CO: 'Colorado',
            CT: 'Connecticut',
            DC: 'District Of Columbia',
            DE: 'Delaware',
            FL: 'Florida',
            GA: 'Georgia',
            HI: 'Hawaii',
            ID: 'Idaho',
            IL: 'Illinois',
            IN: 'Indiana',
            IA: 'Iowa',
            KS: 'Kansas',
            KY: 'Kentucky',
            LA: 'Louisiana',
            ME: 'Maine',
            MD: 'Maryland',
            MA: 'Massachusetts',
            MI: 'Michigan',
            MN: 'Minnesota',
            MS: 'Mississippi',
            MO: 'Missouri',
            MT: 'Montana',
            NE: 'Nebraska',
            NV: 'Nevada',
            NH: 'New Hampshire',
            NJ: 'New Jersey',
            NM: 'New Mexico',
            NY: 'New York',
            NC: 'North Carolina',
            ND: 'North Dakota',
            OH: 'Ohio',
            OK: 'Oklahoma',
            OR: 'Oregon',
            PA: 'Pennsylvania',
            RI: 'Rhode Island',
            SC: 'South Carolina',
            SD: 'South Dakota',
            TN: 'Tennessee',
            TX: 'Texas',
            UT: 'Utah',
            VT: 'Vermont',
            VA: 'Virginia',
            WA: 'Washington',
            WV: 'West Virginia',
            WI: 'Wisconsin',
            WY: 'Wyoming',
            Guam: 'Guam',
            'Puerto Rico': 'Puerto Rico',
            'Pacific Military Address': 'Pacific Military Address',
            'European Military Address': 'European Military Address',
            'Americas Military Address': 'Americas Military Address',
            'Newcar Group2-GA': 'Newcar Group2-GA',
        };
    }

    public static get MAX_ALLOWED_DRIVER(): any {
        return {
            maxAllowedDriver: 5
        };
    }
    public static get CHILD_NON_BINARY() {
        return {
            label: 'Child (Non-Binary)',
            value: 'C'
        };
    }

    public static get CHILD_INTERSEX() {
        return {
            label: 'Child (Intersex)',
            value: 'N'
        };
    }

    // US333148: prior insurnace update
    public static get COVERAGE_LAPSE_CONSTANTS() {
        return {
            lessThanSixMonths: 1,
            sixToElevenMonths: 6,
            sixMonths: 'Have you been continuously insured for the past 6 months with any auto insurance company(s)?',
            twelveMonths: 'Have you been continuously insured for the past 12 months with any auto insurance company(s)?'
        };
    }

    public static get BWDiscalimerContent(): any {
        return {
            closebuttonGTM: 'FFQ_Auto_BWmodal_close_button',
            buttonText: 'Continue',
            logoSrc: 'bristolwestlogo_reflex.png'
        };
    }

    public static get DisclaimerTexts(): any {
        return {
            // tslint:disable-next-line:max-line-length
            fccDisclaimer: 'We sometimes reach out to consumers by call and/or text to provide helpful information about our products or services. By clicking "AGREE & NEXT," you agree that marketing calls and/or texts can be made to the phone number(s) provided on behalf of Farmers®, Foremost®, Bristol West®, Farmers Life® and 21st Century® insurance entities or their representatives using an automated telephone dialing system and/or an artificial or pre-recorded voice. Your consent is not a condition of purchase.',
            learnMore: 'Learn more',
            showLess: 'Show less'
        };
    }

    public static get PolicyPerksDisclaimerTexts(): any {
        return {
            // tslint:disable-next-line:max-line-length
            NCDisclaimer1: 'OPTIONAL COVERAGE AVAILABLE AT ADDITIONAL COST. NEW CAR REPLACEMENT IS AVAILABLE WITH FARMERS® BRANDED SMART PLAN AUTOMOBILE POLICIES. NOT AVAILABLE WITH ALL FARMERS BRANDED POLICIES. Subject to terms and conditions. Covers the cost to replace an insured’s vehicle with a new vehicle of the same make, model and equipment when the insured’s vehicle is declared a total loss within one year from the date of purchase or the odometer reading is less than 15,000 miles, whichever comes first. Products not available in every state.',
            // tslint:disable-next-line:max-line-length
            NCDisclaimer2: 'OPTIONAL COVERAGE AVAILABLE AT ADDITIONAL COST. EXTENDED TRANSPORTATION EXPENSES IS AVAILABLE WITH FARMERS® BRANDED SMART PLAN AUTOMOBILE POLICIES. NOT AVAILABLE WITH ALL FARMERS BRANDED POLICIES. Subject to terms, conditions, and eligibility requirements. This coverage reimburses the insured for the lesser of actual daily transportation expenses or the purchased limit option for a maximum of 30 days for each qualified disablement on a covered auto. A qualified disablement means a loss covered by the Comprehensive sections of the policy. The limit options shown are based on the daily limit and total limits. Products not available in every state.',
            // tslint:disable-next-line: max-line-length
            disclaimer1: 'OPTIONAL COVERAGE AVAILABLE AT ADDITIONAL COST. NEW CAR REPLACEMENT IS AVAILABLE WITH FARMERS® BRANDED SMART PLAN AUTOMOBILE POLICIES. Subject to terms and conditions. Covers the cost to replace an insured’s vehicle with a new one of the same make and model when the insured\'s vehicle is a covered total loss within the first two model years of the vehicle and if the vehicle has less than 24,000 miles.',
            // tslint:disable-next-line: max-line-length
            disclaimer2: 'OPTIONAL COVERAGE AVAILABLE AT ADDITIONAL COST. ACCIDENT FORGIVENESS IS AVAILABLE WITH FARMERS® BRANDED SMART PLAN AUTOMOBILE POLICIES.  Accident Forgiveness applies for 1 accident every 3 years. Subject to specific terms, conditions, and eligibility requirements.'
        };
    }

    public static get QuoteDisclaimerTexts(): any {
        return {
            // tslint:disable-next-line:max-line-length
            caQuoteDisclaimer: 'CALIFORNIA ONLY: An insurer which refuses to provide coverage to an applicant who is a "Good Driver" must provide the applicant with a written statement of the reasons it denied coverage. In general, under California law a Good Driver is a person who has not had more than one violation point or more than one at-fault accident resulting in only property damage in the last three years.',
            // tslint:disable-next-line:max-line-length
            mnQuoteDisclaimer: 'Your policy premium can be affected by chargeable accidents and chargeable traffic violations such as moving violations. Insurers are required to file a surcharge disclosure plan explaining conditions under which a surcharge may be applied to a policy. Minnesota law permits an insurer to notify applicants that its surcharge disclosure plan is available for review on its website. A copy of the Farmers written Surcharge Disclosure Statement, including examples of how premium can be affected, is available on Farmers.com. The surcharge Disclosure Statement will be provided in writing upon request.',
            // tslint:disable-next-line:max-line-length
           kyQuoteDisclaimer: 'Based on paying premium automatically from a bank account. Service fees and fees permitted by state law are not shown. Amounts and service fees differ based on pay plan and automatic payment selection. Additional taxes and fees may apply. ',
           // tslint:disable-next-line:max-line-length
           kyQuoteDisclaimerPartTwo: 'This is a preliminary rate ("quote") for the insurance you selected. This quote is not a policy of insurance or application, offer to insure or binder. Certain assumptions may have been made and this quote reflects rates in effect the day of the quote and are subject to change. Premium may vary based on additional information, coverages and deductibles that you select, underwriting criteria, and any changes to assumptions. In order to purchase a policy, information about your spouse may be necessary. Farmers reserves the right to accept, reject, or modify this quote after review of an application and other underwriting information. Applications are subject to underwriting approval. Farmers online auto quotes are available for new customers.',
           ncCoverageChangeDisclaimer: 'Based on updated information we received, the quoted coverages have changed.'
        };
    }

    public static get DisclaimerDiscountTexts(): any {
        return {
            // tslint:disable-next-line:max-line-length
            mnDiscountDisclaimer: 'Your policy premium can be affected by chargeable accidents and chargeable traffic violations such as moving violations. Insurers are required to file a surcharge plan explaining conditions under which a surcharge may be applied to a policy.  MN law permits an insurer to notify applicants that its surcharge disclosure plan is available for review on its website. A copy of our written Surcharge Disclosure Statement, including examples of how premium can be affected, is available on our website. The Surcharge Disclosure Statement will be provided in writing upon your request.\n By clicking NEXT :  YOUR QUOTE,  I acknowledge that I have read the Surcharge disclosure provided above.'
        };
    }

    public static get SaveAndFinishContent(): any {
        return {
            maintitle: 'Quote number',
            subContent: 'Please note down your quote number. Your quote number or email address can be used to retrieve your quote.',
            buttonText1: 'CONTINUE QUOTE',
            buttonText2: 'SAVE AND EXIT'
        };
    }

    public static get IdleTimeoutContent(): any {
        return {
            maintitle: 'Your quote has been saved.',
            subContent: 'To retrieve the quote again, use the quote number below:',
            buttonText2: 'OK',
            buttonText1: '',
        };
    }

    public static get SESSION_TIMEOUT(): any {
        return {
            // Session Timeout In Milliseconds (58 minutes)
            sessionTimeout: 58 * 60 * 1000
        };
    }


    public static get SESSION_TIMEOUT_POPUP(): any {
        return {
            // Timeout for popup In Milliseconds (1 minute)
            sessionTimeoutPopup: 1 * 60 * 1000
        };
    }

    public static get QuoteSummary(): any {
        return {
            basicPackage: 'Basic coverage package',
            enhancedPackage: 'Enhanced coverage package',
            fullPackage: 'Full coverage package',
            customPackage: 'Custom coverage package',
            coveragePackage: 'Coverage package'
        };
    }
    public static get YOUR_DISCOUNT_PARAMS(): any {
        return [{
            discountText: 'Air Bag',
            iconPath: '../../../assets/images/discounts/icon-airbag-or-passive-restraint.png'
        },
        {
            discountText: 'Passive Restraint',
            iconPath: '../../../assets/images/discounts/icon-airbag-or-passive-restraint.png'
        },
        {
            discountText: 'Motorhome, Travel Trailer or 5th Wheel Insurance',
            iconPath: '../../../assets/images/discounts/icons-motorHome-discount.png'
        },
        {
            discountText: 'Off-Road Vehicle Insurance',
            iconPath: '../../../assets/images/discounts/icons-offRoad-discount.png'
        },
        {
            discountText: 'Recreational Boat/Watercraft Insurance',
            iconPath: '../../../assets/images/discounts/icons-recreational-discount.png'
        },
        {
            discountText: 'Motorcycle Insurance',
            iconPath: '../../../assets/images/discounts/icons-motorCycle-discount.png'
        },
        {
            discountText: 'Go Paperless',
            iconPath: '../../../assets/images/discounts/icon-paperless.png'
        },
        {
            discountText: 'Paperless',
            iconPath: '../../../assets/images/discounts/icon-paperless.png'
        },
        {
            discountText: 'ePolicy',
            iconPath: '../../../assets/images/discounts/icon-paperless.png'
        },
        {
            discountText: 'Alternative Fuels',
            iconPath: '../../../assets/images/discounts/icon-paperless.png'
        },
        {
            discountText: 'Alternative Fuel Vehicle',
            iconPath: '../../../assets/images/discounts/icon-paperless.png'
        },
        {
            discountText: 'Anti-lock Brakes',
            iconPath: '../../../assets/images/discounts/icon-anti-lock-brakes.png'
        },
        {
            discountText: 'Early Shopping',
            iconPath: '../../../assets/images/discounts/icon-early-shopping.png'
        },
        {
            discountText: 'ESC',
            iconPath: '../../../assets/images/discounts/icon-youthful-driver-discount-2.png'
        },
        {
            discountText: 'Good Student',
            iconPath: '../../../assets/images/discounts/icon-farmers-fit.png'
        },
        {
            discountText: 'Business/Professional Group',
            iconPath: '../../../assets/images/discounts/icon-business-insurance-or-property.png'
        },
        {
            discountText: 'Safe Driver',
            iconPath: '../../../assets/images/discounts/icon-auto.png'
        },
        {
            discountText: 'Safe Driving',
            iconPath: '../../../assets/images/discounts/icon-auto.png'
        },
        {
            discountText: 'Theft Recovery',
            iconPath: '../../../assets/images/discounts/icon-theft.png'
        },
        {
            discountText: 'Auto & Business',
            iconPath: '../../../assets/images/discounts/icon-auto-business.png'
        },
        {
            discountText: 'Auto & Umbrella',
            iconPath: '../../../assets/images/discounts/icon-auto-umbrella.png'
        },
        {
            discountText: 'Share-Auto',
            iconPath: '../../../assets/images/discounts/icon-community.png'
        },
        {
            discountText: 'Shared Family Car Discount',
            iconPath: '../../../assets/images/discounts/icon-community.png'
        },
        {
            discountText: 'Shared Family Car',
            iconPath: '../../../assets/images/discounts/icon-community.png'
        },
        {
            discountText: 'Auto & Home',
            iconPath: '../../../assets/images/discounts/icon-auto-home.png'
        },
        {
            discountText: 'Auto/Mobilehome',
            iconPath: '../../../assets/images/discounts/icon-auto-home.png'
        },
        {
            discountText: 'Auto & Rent',
            iconPath: '../../../assets/images/discounts/icon-auto-renters.png'
        },
        {
            discountText: 'Mature Driver',
            iconPath: '../../../assets/images/discounts/icon-print-id-card.png'
        },
        {
            discountText: '3 Years Claim',
            iconPath: '../../../assets/images/discounts/icon-multi-policy-or-work-history.png'
        },
        {
            discountText: '3 Years Clean',
            iconPath: '../../../assets/images/discounts/icon-multi-policy-or-work-history.png'
        },
        {
            discountText: '5 Years Clean',
            iconPath: '../../../assets/images/discounts/icon-multi-policy-or-work-history.png'
        },
        {
            discountText: 'Claim Free',
            iconPath: '../../../assets/images/discounts/icon-multi-policy-or-work-history.png'
        },
        {
            discountText: 'E-mail',
            iconPath: '../../../assets/images/discounts/icon-mail.png'
        },
        {
            discountText: 'Distant Student',
            iconPath: '../../../assets/images/discounts/icon-farmers-fit_2.png'
        },
        {
            discountText: 'Good Payer',
            iconPath: '../../../assets/images/discounts/icon-ffr-3-or-optimism.png'
        },
        {
            discountText: 'Defensive Driver',
            iconPath: '../../../assets/images/discounts/icon-loss-control.png'
        },
        {
            discountText: 'Senior Defensive Driver',
            iconPath: '../../../assets/images/discounts/icon-loss-control.png'
        },
        {
            discountText: 'Paid in Full',
            iconPath: '../../../assets/images/discounts/icon-electronic-funds-transfer.png'
        },
        {
            discountText: 'New Customer',
            iconPath: '../../../assets/images/discounts/icon-agent.png'
        },
        {
            discountText: 'Auto & Life',
            iconPath: '../../../assets/images/discounts/icon-auto-life.png'
        },
        {
            discountText: 'Safety Features',
            iconPath: '../../../assets/images/discounts/icon-appreciation.png'
        },
        {
            discountText: 'Multiple Car',
            iconPath: '../../../assets/images/discounts/icon-fleet.png'
        },
        {
            discountText: 'Anti-Theft Device',
            iconPath: '../../../assets/images/discounts/icon-distraction-free-driving.png'
        },
        {
            discountText: 'Anti-Theft Category T5',
            iconPath: '../../../assets/images/discounts/icon-distraction-free-driving.png'
        },
        {
            discountText: 'Anti-Theft Category T4',
            iconPath: '../../../assets/images/discounts/icon-distraction-free-driving.png'
        },
        {
            discountText: 'Anti-Theft Category T3',
            iconPath: '../../../assets/images/discounts/icon-distraction-free-driving.png'
        },
        {
            discountText: 'Anti-Theft Category T2',
            iconPath: '../../../assets/images/discounts/icon-distraction-free-driving.png'
        },
        {
            discountText: 'Anti-Theft Category T1',
            iconPath: '../../../assets/images/discounts/icon-distraction-free-driving.png'
        },
        {
            discountText: 'Anti-Theft',
            iconPath: '../../../assets/images/discounts/icon-distraction-free-driving.png'
        },
        {
            discountText: 'Anti-Theft Category',
            iconPath: '../../../assets/images/discounts/icon-distraction-free-driving.png'
        },
        {
            discountText: 'VIN Etching',
            iconPath: '../../../assets/images/discounts/icon-vin.png'
        },
        {
            discountText: 'VIN Etching Discount',
            iconPath: '../../../assets/images/discounts/icon-vin.png'
        },
        {
            discountText: 'Window Glass VIN Etching',
            iconPath: '../../../assets/images/discounts/icon-vin.png'
        },
        {
            discountText: 'Drivers Ed',
            iconPath: '../../../assets/images/discounts/icon-ffr-1.png'
        },
        {
            discountText: 'Daytime Running Lamps',
            iconPath: '../../../assets/images/discounts/icon-tips.png'
        },
        {
            discountText: 'Daytime Running Lights',
            iconPath: '../../../assets/images/discounts/icon-tips.png'
        },
        {
            discountText: 'Transfer Discount',
            iconPath: '../../../assets/images/discounts/icon-get-a-quote.png'
        },
        {
            discountText: 'Driver Improvement',
            iconPath: '../../../assets/images/icon-youthful-driver-discount-1.png'
        },
        {
            discountText: 'Default',
            iconPath: '../../../assets/images/discounts/icon-wealth.png'
        },
        {
            discountText: 'Signal',
            iconPath: '../../../assets/images/discounts/icon-youthful-driver-discount-1.png'
        },
        {
            discountText: 'Active Military',
            iconPath: '../../../assets/images/discounts/activeMilitary.png'
        },
        {
            discountText: 'Active Military Personnel',
            iconPath: '../../../assets/images/discounts/activeMilitary.png'
        },
        {
            discountText: 'Welcome',
            iconPath: '../../../assets/images/discounts/icon-auto-welcome.png'
        },
        // US230784: farmers renter to homeowner discount
        {
            discountText: 'Farmers Renter to Homeowner',
            iconPath: '../../../assets/images/discounts/icon-farmers-renter-to-homeowner.png'
        },
        // US230780: Auto Pay discount
        {
            discountText: 'Auto Pay',
            iconPath: '../../../assets/images/discounts/icon-auto-pay.png'
        }];
    }

    public static get COVERAGECODES() {
        return {
            propertyDamage: ['13024'],
            bodilyInjury: ['13023'],
            UMUIM: ['90510'],
            UM: ['90511'],
            UIM: ['90512'],
            towingandRoadService: ['40001', '40010'],
            collision: ['22000', '22012', '22013', '22031'],
            glassDeductibleBuyback: ['21017', '21007', '21018'],
            comprehensive: ['21000', '21031'],
            uninsuredMotorist: ['13100', '11100'],
            underinsuredMotorist: ['13101'],
            uninsuredMotoristWithoutCollision: ['12104'],
            collisionPlusLossofUse: ['22007', '22008', '22009', '22010', '22011'],
            uninsuredMotoristPDWithoutCollision: ['12101', '12104'],
            uninsuredMotoristUnderInsuredMotoristBodilyInjurylimit: ['13106'],
            uninsuredMotoristUnderInsuredMotoristBIEcolLosslimit: ['13139'],
            uninsuredMotoristBIEconomicLoss: ['13139'],
            underInsuredMotoristBI: ['13104'],
            uninsuredMotoristBodilyInjury: ['11100'],
            medical: ['30000', '30005'],
            personalInjuryProtection: ['35000', '35021', '35090'],
            uninsuredMotoristPropertyDamageWithoutCollision: ['12101', '12104'],
            uninsuredMotoristPropertyDamageWithCollision: ['12102'],
            uninsuredMotoristPropertyDamage: ['13105', '12100'],
            uninsuredMotoristPropertyDamageMD: ['12100'],
            uninsuredMotoristUnderinsuredMotoristPropertyDamage: ['13107'],
            uninsuredMotoristUnderinsuredMotoristBodilyInjury: ['13106'],
            principalPIPMedExpOnly: ['35051'],
            principalPIP: ['35050'],
            incomeContinuationCoverage: ['35070'],
            essentialServices: ['35071'],
            extraPIP: ['35069'],
            funeralExpenses: ['35072'],
            pIP: ['35000'],
            uninsuredMotoristBodilyInjuryStacking: ['11101'],
            underInsuredMotoristBodilyInjuryStacking: ['13103'],
            supplementaryUninsuredUnderinsuredMotorists: ['13116'],
            uninsuredUnderinsuredMotoristBodilyInjuryConversion: ['13132'],
            accidentalDeathBenefits: ['34008', '34010', '35063'],
            PIPWWKLYINDEM2NDTOOTHWAGECVG: ['35004', 'PIPWL2TOWLC'],
            PIPWMEDSSECONDTOOTHERMEDSCVG: ['35003', 'PIPM2TOMI'],
            WORKLOSSWAIVEDBYPRINCIPALOPER: ['35037', 'PIPWLW'],
            ExtraPIPForPrimaryInsured: ['35056'],
            HealthInsurerPrimaryForPIP: ['35057'],
            PropertyProtectionInsurance: ['PPI'],
            rideShare: ['40021'],
            uninsuredMotoristBodilyInjuryFL: ['11101', '11102'],
            PIPDAT: ['PIPDAT'],
            PIPWAT: ['PIPWAT'],
            EUIM: ['13134'],
            EUIMPD: ['12107'],
            PIPMedical: ['35082'],
            excessAttendantCare: ['35086'],
            rentalReimbursement: ['22004'],
            rentalReimbursementNC: ['22020'],
            underinsuredMotoristPropertyDamage: ['13105'],
            // GW3 changes - start
            waiverOfWorkLoss: ['35008'],
            guestPIP: ['35028'],
            // GW3 changes - end
            uninsuredMotoristBIEconomicLossOnly: ['13139'],
            limitedCollision: ['22032'],
            substituteTransportation: ['22034'],
            optionalBodilyInjuryPart5: ['13045'],
            propertyDamagePart4: ['13044'],
            uninsuredOptionalBodilyPart3: ['13137'],
            acquisitionExpense: ['42006'],
            operationsExpense: ['42007'],
            compulsoryBodilyInjury: ['13043'],
            additionalEquipment: ['22021'],
            collisionPart7: [ '22031'],
            collisionPart7Waiver: [ '22033'],
            pIPPart2: ['PIP'],
            pipMedicalPopupCoverage: ['3', '4', '5'],
            rental: ['22035'],
            vehicleReplacmentCoverage: ['40017'],
            roadsideAssitanceCoverage: ['40027'],
            collisionBw: ['COL', 'COLL'],
            comprehensiveBw: ['COMP', 'OTC'],
            propertyProtectionInsurace: ['35002'],
            MCCAAssessmentRecoupment: ['42004'],
            statutoryAssessmentRecoupment: ['42005'],
            accidentalDeathDisablingInjury: ['34018'],
            uninsuredMotoristBIStacking: ['13129'],
            underinsuredMotoristBIStacking: ['13130'],
            bodilyInjuryBw: ['BI'],
            medicalBw: ['MP'],
            rentalBw: ['TRNEX'],
            towingandRoadServiceBw: ['TL'],
            PIPMedicalExc: ['35084'],
            MCCADeficitRecoupment: ['42009']
        };
    }

    public static get COVERAGEVALUES() {
        return {
            NO_COVERAGE_TEXT: 'No Coverage',
            NO_COVERAGE_VALUE: 'N',
            STACKED_TEXT: 'Stacked',
            STACKED_VALUE: '1',
            NON_STACKED_TEXT: 'Non-Stacked',
            NON_STACKED_VALUE: '2',
            NO_VALUE: '2',
            NO_TEXT: 'No',
            PERMISSIVE_COV_VALUE: 'Full Permissive User Coverage'
        };
    }

    public static get COVERAGELABEL() {
        return {
            collisionPart7Label: 'Collision (Part 7)',
            CollisionWaiverLabel: 'Collision Waiver of Deductible',
            collisionTypeLabel: 'Collision Type',
            UMBDIStackingLabel: 'Uninsured Motorist Bodily Injury - Stacking Option',
            RoadsideAssistanceLabel: 'Roadside Assistance'
        };
    }

    public static get YEAR_ACCIDENT(): YearAccident {
        return {
            fiveYears: '5 years',
            threeYears: '3 years'
        };
    }

    public static get PAY_POLICY(): PayPolicy {
        return {
            payInfull: 'PayInfull',
            sixMonthsTerm: '(6-month term)',
            monthlyAutomatic: 'monthly automatic',
        };
    }

    public static get FP_PAY_POLICY(): PayPolicy {
        return {
            sixMonthsTerm: '6-month term',
        };
    }

    public static get LOBDATAMODEL() {
        return [{
            lobName: 'Home Quote',
            lobCode: 'H',
            highlight: false,
            lobClass: 'home-quote'
        }, {
            lobName: 'Value Term Life Quote',
            lobCode: 'L',
            highlight: false,
            lobClass: 'vt-quote'
        }, {
            lobName: 'Condo Quote',
            lobCode: 'C',
            highlight: false,
            lobClass: 'home-quote'
        }, {
            lobName: 'Renters Quote',
            lobCode: 'R',
            highlight: false,
            lobClass: 'home-quote'
        }, {
            lobName: 'Graded Death Benefit Whole Life Quote',
            lobCode: 'G',
            highlight: false,
            lobClass: 'vt-quote'
        }, {
            lobName: 'Simple Whole Life Quote',
            lobCode: 'S',
            highlight: false,
            lobClass: 'vt-quote'
        }, {
            lobName: 'Simple Term Life Quote',
            lobCode: 'T',
            highlight: false,
            lobClass: 'vt-quote'
        }, {
            lobName: 'Premier Whole Life Quote',
            lobCode: 'P',
            highlight: false,
            lobClass: 'vt-quote'
        }];
    }
/** your info and additional details page disclaimer text */
    public static get YourInfoDisclaimerTexts(): any {
        return {
            // tslint:disable-next-line:max-line-length
            TX: 'We will obtain and use credit information on you or any other member(s) of your household as part of the insurance credit scoring process.',
            // tslint:disable-next-line:max-line-length
            CT: 'In connection with a quote for Auto insurance, we may review your credit report or obtain or use a credit-based insurance score based on the information contained in that credit report. We may use a third party in connection with the development of your insurance score.\n\n If you believe an extraordinary life circumstance has negatively impacted your credit information, you may request , in writing, that we provide reasonable exceptions to our underwriting and/or rating practices for this quote or any policy issued. Please contact your agent for details on qualifying circumstances and how to request an exception.',
            // tslint:disable-next-line:max-line-length
            NJ: 'In connection with a quote for Auto insurance, we may review your credit report or obtain or use a credit-based insurance score based on the information contained in that credit report. We may use a third party in connection with the development of your insurance score.\n\n If you believe an extraordinary life circumstance has negatively impacted your credit information, you may request , in writing, that we provide reasonable exceptions to our underwriting and/or rating practices for this quote or any policy issued. Please contact your agent for details on qualifying circumstances and how to request an exception.',
            // tslint:disable-next-line:max-line-length
            OR: 'In connection with a quote for Auto insurance, we may review your credit report or obtain or use a credit-based insurance score based on the information contained in that credit report. We may use a third party in connection with the development of your insurance score. You may request that we give you a written statement describing our use of credit history or insurance scores.',
            // tslint:disable-next-line:max-line-length
            AZ: 'In connection with a quote for Auto insurance, we may review your credit report or obtain or use a credit-based insurance score based on the information contained in that credit report. We may use a third party in connection with the development of your insurance score.',
            // tslint:disable-next-line:max-line-length
            ID: 'In connection with a quote for Auto insurance, we may review your credit report or obtain or use a credit-based insurance score based on the information contained in that credit report. We may use a third party in connection with the development of your insurance score.',
            // tslint:disable-next-line:max-line-length
            SD: 'In connection with a quote for Auto insurance, we may review your credit report or obtain or use a credit-based insurance score based on the information contained in that credit report. We may use a third party in connection with the development of your insurance score.',
            // tslint:disable-next-line:max-line-length
            WA: 'In connection with a quote for Auto insurance, we may review your credit report or obtain or use a credit-based insurance score based on the information contained in that credit report. We may use a third party in connection with the development of your insurance score.',
            // tslint:disable-next-line:max-line-length
            AL: 'In connection with a quote for Auto insurance, we may review your credit report or obtain or use a credit-based insurance score based on the information contained in that credit report. We may use a third party in connection with the development of your insurance score.',
            // tslint:disable-next-line:max-line-length
            AR: 'In connection with a quote for Auto insurance, we may review your credit report or obtain or use a credit-based insurance score based on the information contained in that credit report. We may use a third party in connection with the development of your insurance score.',
            // tslint:disable-next-line:max-line-length
            FL: 'In connection with a quote for Auto insurance, we may review your credit report or obtain or use a credit-based insurance score based on the information contained in that credit report. We may use a third party in connection with the development of your insurance score.',
            // tslint:disable-next-line:max-line-length
            MO: 'In connection with a quote for Auto insurance, we may review your credit report or obtain or use a credit-based insurance score based on the information contained in that credit report. We may use a third party in connection with the development of your insurance score.',
            // tslint:disable-next-line:max-line-length
            OH: 'In connection with a quote for Auto insurance, we may review your credit report or obtain or use a credit-based insurance score based on the information contained in that credit report. We may use a third party in connection with the development of your insurance score.',
            // tslint:disable-next-line:max-line-length
            UT: 'In connection with a quote for Auto insurance, we may review your credit report or obtain or use a credit-based insurance score based on the information contained in that credit report. We may use a third party in connection with the development of your insurance score.',
            // tslint:disable-next-line:max-line-length
            WI: 'In connection with a quote for Auto insurance, we may review your credit report or obtain or use a credit-based insurance score based on the information contained in that credit report. We may use a third party in connection with the development of your insurance score.',
            // tslint:disable-next-line:max-line-length
            WY: 'In connection with a quote for Auto insurance, we may review your credit report or obtain or use a credit-based insurance score based on the information contained in that credit report. We may use a third party in connection with the development of your insurance score.',
            // tslint:disable-next-line:max-line-length
            GA: 'In connection with a quote for Auto insurance, we may review your credit report or obtain or use a credit-based insurance score based on the information contained in that credit report. We may use a third party in connection with the development of your insurance score.',
            // tslint:disable-next-line:max-line-length
            IA: 'In connection with a quote for Auto insurance, we may review your credit report or obtain or use a credit-based insurance score based on the information contained in that credit report. We may use a third party in connection with the development of your insurance score.',
            // tslint:disable-next-line:max-line-length
            IL: 'In connection with a quote for Auto insurance, we may review your credit report or obtain or use a credit-based insurance score based on the information contained in that credit report. We may use a third party in connection with the development of your insurance score.',
            // tslint:disable-next-line:max-line-length
            IN: 'In connection with a quote for Auto insurance, we may review your credit report or obtain or use a credit-based insurance score based on the information contained in that credit report. We may use a third party in connection with the development of your insurance score.',
            // tslint:disable-next-line:max-line-length
            MT: 'In connection with a quote for Auto insurance, we may review your credit report or obtain or use a credit-based insurance score based on the information contained in that credit report. We may use a third party in connection with the development of your insurance score.',
            // tslint:disable-next-line:max-line-length
            ND: 'In connection with a quote for Auto insurance, we may review your credit report or obtain or use a credit-based insurance score based on the information contained in that credit report. We may use a third party in connection with the development of your insurance score.',
            // tslint:disable-next-line:max-line-length
            NE: 'In connection with a quote for Auto insurance, we may review your credit report or obtain or use a credit-based insurance score based on the information contained in that credit report. We may use a third party in connection with the development of your insurance score.',
            // tslint:disable-next-line:max-line-length
            NV: 'In connection with a quote for Auto insurance, we may review your credit report or obtain or use a credit-based insurance score based on the information contained in that credit report. We may use a third party in connection with the development of your insurance score.',
            // tslint:disable-next-line:max-line-length
            NY: 'In connection with a quote for Auto insurance, we may review your credit report or obtain or use a credit-based insurance score based on the information contained in that credit report. We may use a third party in connection with the development of your insurance score.',
            // tslint:disable-next-line:max-line-length
            OK: 'In connection with a quote for Auto insurance, we may review your credit report or obtain or use a credit-based insurance score based on the information contained in that credit report. We may use a third party in connection with the development of your insurance score.',
            // tslint:disable-next-line:max-line-length
            TN: 'In connection with a quote for Auto insurance, we may review your credit report or obtain or use a credit-based insurance score based on the information contained in that credit report. We may use a third party in connection with the development of your insurance score.',
            // tslint:disable-next-line:max-line-length
            VA: 'In connection with a quote for Auto insurance, we shall review your credit report or obtain or use a credit-based insurance score based on the information contained in that credit report. We may use a third party in connection with the development of your insurance credit score. You may request that your credit information be updated and if you question the accuracy of the credit information, we will, upon your request, reevaluate you based on corrected credit information from a consumer reporting agency.',
            // tslint:disable-next-line:max-line-length
            NM: 'In connection with your application for insurance coverage, we may review and use information contained in your credit report to help determine your premium or your eligibility for coverage.',
            // tslint:disable-next-line:max-line-length
            CO: 'In connection with a quote for Auto insurance, we may review your credit report or obtain or use a credit-based insurance score based on the information contained in that credit report. We may use a third party in connection with the development of your insurance score.',
            // tslint:disable-next-line:max-line-length
            KS: 'In connection with a quote for Auto or Home (includes condo and renters) insurance, we may review your credit report or obtain or use a credit-based insurance score based on the information contained in that credit report. We may use a third party in connection with the development of your insurance score. An internal appeals process exists whereby you may review an adverse action based on credit information. Please contact your agent for details.',
            // tslint:disable-next-line:max-line-length
            MD: 'In connection with a quote for Auto insurance, we may review your credit report or obtain or use a credit-based insurance score based on the information contained in that credit report. We may use a third party in connection with the development of your insurance score. You may request a separate premium quotation that separately identifies the portion of the premium attributable to your credit history.',
            // tslint:disable-next-line:max-line-length
            MN: 'In connection with a quote for Auto insurance, we will review your credit report or obtain or use a credit score, insurance score or other credit information as part of the underwriting process. We may use a third party in connection with the development of your insurance score.',
            // tslint:disable-next-line:max-line-length
            PA: 'In connection with a quote for Auto insurance, we may review your credit report or obtain or use a credit-based insurance score based on the information contained in that credit report for underwriting purposes. We may use a third party in connection with the development of your insurance score.',
            // tslint:disable-next-line: max-line-length
            MI: 'In connection with a quote for Auto insurance, we may review your credit report or obtain or use a credit-based insurance score based on the information contained in that credit report. We may use a third party in connection with the development of your insurance score.\n\n If you believe an extraordinary life circumstance has negatively impacted your credit information, you may request, in writing, that we provide reasonable exceptions to our underwriting and/or rating practices. Please contact your agent for details on qualifying circumstances and how to request an exception.',
           // tslint:disable-next-line: max-line-length
            KY: 'In connection with a quote for Auto or Home (includes Condo and Renters) insurance, we may review your credit report or obtain or use a credit-based insurance score based on the information contained in that credit report as permitted by state law. We may use a third party in connection with the development of your insurance score. We do not review or obtain credit reports for Life quotes. \n By clicking NEXT, I acknowledge that I have read and agree to the Privacy Policy and the use of credit report information for you and your spouse described above.'
        };
    }
    public static get AdditionalDisclaimerTexts(): any {
        return {
            // tslint:disable-next-line: max-line-length
            AL: 'By clicking “NEXT” you agree to receive texts and calls to this number about products, services, marketing, surveys, and claims, including by automatic telephone dialing system or an artificial or prerecorded voice, from Farmers® agents and Farmers, Foremost®, Bristol West®, and 21st Century® Insurance entities and their other representatives. Your consent is not a condition of purchase.',
            // tslint:disable-next-line: max-line-length
            AR: 'By clicking “NEXT” you agree to receive texts and calls to this number about products, services, marketing, surveys, and claims, including by automatic telephone dialing system or an artificial or prerecorded voice, from Farmers® agents and Farmers, Foremost®, Bristol West®, and 21st Century® Insurance entities and their other representatives. Your consent is not a condition of purchase.',
            // tslint:disable-next-line: max-line-length
            AZ: 'By clicking “NEXT” you agree to receive texts and calls to this number about products, services, marketing, surveys, and claims, including by automatic telephone dialing system or an artificial or prerecorded voice, from Farmers® agents and Farmers, Foremost®, Bristol West®, and 21st Century® Insurance entities and their other representatives. Your consent is not a condition of purchase. ',
            // tslint:disable-next-line: max-line-length
            CA: 'By clicking “NEXT” you agree to receive texts and calls to this number about products, services, marketing, surveys, and claims, including by automatic telephone dialing system or an artificial or prerecorded voice, from Farmers® agents and Farmers, Foremost®, Bristol West®, and 21st Century® Insurance entities and their other representatives. Your consent is not a condition of purchase. ',
            // tslint:disable-next-line: max-line-length
            CO: 'By clicking “NEXT” you agree to receive texts and calls to this number about products, services, marketing, surveys, and claims, including by automatic telephone dialing system or an artificial or prerecorded voice, from Farmers® agents and Farmers, Foremost®, Bristol West®, and 21st Century® Insurance entities and their other representatives. Your consent is not a condition of purchase. ',
            // tslint:disable-next-line: max-line-length
            CT: 'By clicking “NEXT” you agree to receive texts and calls to this number about products, services, marketing, surveys, and claims, including by automatic telephone dialing system or an artificial or prerecorded voice, from Farmers® agents and Farmers, Foremost®, Bristol West®, and 21st Century® Insurance entities and their other representatives. Your consent is not a condition of purchase. ',
            // tslint:disable-next-line: max-line-length
            FL: 'By clicking “NEXT” you agree to receive texts and calls to this number about products, services, marketing, surveys, and claims, including by automatic telephone dialing system or an artificial or prerecorded voice, from Farmers® agents and Farmers, Foremost®, Bristol West®, and 21st Century® Insurance entities and their other representatives. Your consent is not a condition of purchase. ',
            // tslint:disable-next-line: max-line-length
            GA: 'By clicking “NEXT” you agree to receive texts and calls to this number about products, services, marketing, surveys, and claims, including by automatic telephone dialing system or an artificial or prerecorded voice, from Farmers® agents and Farmers, Foremost®, Bristol West®, and 21st Century® Insurance entities and their other representatives. Your consent is not a condition of purchase. ',
            // tslint:disable-next-line: max-line-length
            IA: 'By clicking “NEXT” you agree to receive texts and calls to this number about products, services, marketing, surveys, and claims, including by automatic telephone dialing system or an artificial or prerecorded voice, from Farmers® agents and Farmers, Foremost®, Bristol West®, and 21st Century® Insurance entities and their other representatives. Your consent is not a condition of purchase. ',
            // tslint:disable-next-line: max-line-length
            ID: 'By clicking “NEXT” you agree to receive texts and calls to this number about products, services, marketing, surveys, and claims, including by automatic telephone dialing system or an artificial or prerecorded voice, from Farmers® agents and Farmers, Foremost®, Bristol West®, and 21st Century® Insurance entities and their other representatives. Your consent is not a condition of purchase. ',
            // tslint:disable-next-line: max-line-length
            IL: 'By clicking “NEXT” you agree to receive texts and calls to this number about products, services, marketing, surveys, and claims, including by automatic telephone dialing system or an artificial or prerecorded voice, from Farmers® agents and Farmers, Foremost®, Bristol West®, and 21st Century® Insurance entities and their other representatives. Your consent is not a condition of purchase. ',
            // tslint:disable-next-line: max-line-length
            IN: 'By clicking “NEXT” you agree to receive texts and calls to this number about products, services, marketing, surveys, and claims, including by automatic telephone dialing system or an artificial or prerecorded voice, from Farmers® agents and Farmers, Foremost®, Bristol West®, and 21st Century® Insurance entities and their other representatives. Your consent is not a condition of purchase. ',
            // tslint:disable-next-line: max-line-length
            KS: 'By clicking “NEXT” you agree to receive texts and calls to this number about products, services, marketing, surveys, and claims, including by automatic telephone dialing system or an artificial or prerecorded voice, from Farmers® agents and Farmers, Foremost®, Bristol West®, and 21st Century® Insurance entities and their other representatives. Your consent is not a condition of purchase. ',
            // tslint:disable-next-line: max-line-length
            LA: 'By clicking NEXT, you agree to receive texts and calls to this number about products, services, marketing, surveys, and claims, including by automatic telephone dialing system or an artificial or prerecorded voice, from Farmers® agents and from Farmers, Foremost®, Bristol West®, and 21st Century® Insurance entities and their other representatives. Your consent is not a condition of purchase. \n\n Alternatively, you can speak with a Farmers representative to get a quote by calling 1-888-767-4030.',
            // tslint:disable-next-line: max-line-length
            MA: 'By clicking NEXT, you agree to receive texts and calls to this number about products, services, marketing, surveys, and claims, including by automatic telephone dialing system or an artificial or prerecorded voice, from Farmers® agents and from Farmers, Foremost®, Bristol West®, and 21st Century® Insurance entities and their other representatives. Your consent is not a condition of purchase. \n\n Alternatively, you can speak with a Farmers representative to get a quote by calling 1-888-767-4030.',
            // tslint:disable-next-line: max-line-length
            MD: 'By clicking “NEXT” you agree to receive texts and calls to this number about products, services, marketing, surveys, and claims, including by automatic telephone dialing system or an artificial or prerecorded voice, from Farmers® agents and Farmers, Foremost®, Bristol West®, and 21st Century® Insurance entities and their other representatives. Your consent is not a condition of purchase. ',
            // tslint:disable-next-line: max-line-length
            MI: 'By clicking “NEXT” you agree to receive texts and calls to this number about products, services, marketing, surveys, and claims, including by automatic telephone dialing system or an artificial or prerecorded voice, from Farmers® agents and Farmers, Foremost®, Bristol West®, and 21st Century® Insurance entities and their other representatives. Your consent is not a condition of purchase. ',
            // tslint:disable-next-line: max-line-length
            MN: 'By clicking “NEXT” you agree to receive texts and calls to this number about products, services, marketing, surveys, and claims, including by automatic telephone dialing system or an artificial or prerecorded voice, from Farmers® agents and Farmers, Foremost®, Bristol West®, and 21st Century® Insurance entities and their other representatives. Your consent is not a condition of purchase. ',
            // tslint:disable-next-line: max-line-length
            MO: 'By clicking “NEXT” you agree to receive texts and calls to this number about products, services, marketing, surveys, and claims, including by automatic telephone dialing system or an artificial or prerecorded voice, from Farmers® agents and Farmers, Foremost®, Bristol West®, and 21st Century® Insurance entities and their other representatives. Your consent is not a condition of purchase. ',
            // tslint:disable-next-line: max-line-length
            MS: 'By clicking NEXT you agree to receive texts and calls to this number about products, services, marketing, surveys, and claims, including by automatic telephone dialing system or an artificial or prerecorded voice, from Farmers® agents and from Farmers, Foremost®, Bristol West®, and 21st Century® Insurance entities and their other representatives. Your consent is not a condition of purchase. \n\n Alternatively, you can speak with a Farmers representative to get a quote by calling 1-888-767-4030.',
            // tslint:disable-next-line: max-line-length
            MT: 'By clicking “NEXT” you agree to receive texts and calls to this number about products, services, marketing, surveys, and claims, including by automatic telephone dialing system or an artificial or prerecorded voice, from Farmers® agents and Farmers, Foremost®, Bristol West®, and 21st Century® Insurance entities and their other representatives. Your consent is not a condition of purchase. ',
            // tslint:disable-next-line: max-line-length
            ND: 'By clicking “NEXT” you agree to receive texts and calls to this number about products, services, marketing, surveys, and claims, including by automatic telephone dialing system or an artificial or prerecorded voice, from Farmers® agents and Farmers, Foremost®, Bristol West®, and 21st Century® Insurance entities and their other representatives. Your consent is not a condition of purchase. ',
            // tslint:disable-next-line: max-line-length
            NE: 'By clicking “NEXT” you agree to receive texts and calls to this number about products, services, marketing, surveys, and claims, including by automatic telephone dialing system or an artificial or prerecorded voice, from Farmers® agents and Farmers, Foremost®, Bristol West®, and 21st Century® Insurance entities and their other representatives. Your consent is not a condition of purchase. ',
            // tslint:disable-next-line: max-line-length
            NJ: 'By clicking “NEXT” you agree to receive texts and calls to this number about products, services, marketing, surveys, and claims, including by automatic telephone dialing system or an artificial or prerecorded voice, from Farmers® agents and Farmers, Foremost®, Bristol West®, and 21st Century® Insurance entities and their other representatives. Your consent is not a condition of purchase. ',
            // tslint:disable-next-line: max-line-length
            NM: 'By clicking “NEXT” you agree to receive texts and calls to this number about products, services, marketing, surveys, and claims, including by automatic telephone dialing system or an artificial or prerecorded voice, from Farmers® agents and Farmers, Foremost®, Bristol West®, and 21st Century® Insurance entities and their other representatives. Your consent is not a condition of purchase. ',
            // tslint:disable-next-line: max-line-length
            NV: 'By clicking “NEXT” you agree to receive texts and calls to this number about products, services, marketing, surveys, and claims, including by automatic telephone dialing system or an artificial or prerecorded voice, from Farmers® agents and Farmers, Foremost®, Bristol West®, and 21st Century® Insurance entities and their other representatives. Your consent is not a condition of purchase. ',
            // tslint:disable-next-line: max-line-length
            NY: 'By clicking “NEXT” you agree to receive texts and calls to this number about products, services, marketing, surveys, and claims, including by automatic telephone dialing system or an artificial or prerecorded voice, from Farmers® agents and Farmers, Foremost®, Bristol West®, and 21st Century® Insurance entities and their other representatives. Your consent is not a condition of purchase. ',
            // tslint:disable-next-line: max-line-length
            OH: 'By clicking “NEXT” you agree to receive texts and calls to this number about products, services, marketing, surveys, and claims, including by automatic telephone dialing system or an artificial or prerecorded voice, from Farmers® agents and Farmers, Foremost®, Bristol West®, and 21st Century® Insurance entities and their other representatives. Your consent is not a condition of purchase. ',
            // tslint:disable-next-line: max-line-length
            OK: 'By clicking “NEXT” you agree to receive texts and calls to this number about products, services, marketing, surveys, and claims, including by automatic telephone dialing system or an artificial or prerecorded voice, from Farmers® agents and Farmers, Foremost®, Bristol West®, and 21st Century® Insurance entities and their other representatives. Your consent is not a condition of purchase. ',
            // tslint:disable-next-line: max-line-length
            OR: 'By clicking “NEXT” you agree to receive texts and calls to this number about products, services, marketing, surveys, and claims, including by automatic telephone dialing system or an artificial or prerecorded voice, from Farmers® agents and Farmers, Foremost®, Bristol West®, and 21st Century® Insurance entities and their other representatives. Your consent is not a condition of purchase. ',
            // tslint:disable-next-line: max-line-length
            PA: 'By clicking “NEXT” you agree to receive texts and calls to this number about products, services, marketing, surveys, and claims, including by automatic telephone dialing system or an artificial or prerecorded voice, from Farmers® agents and Farmers, Foremost®, Bristol West®, and 21st Century® Insurance entities and their other representatives. Your consent is not a condition of purchase. ',
            // tslint:disable-next-line: max-line-length
            SD: 'By clicking “NEXT” you agree to receive texts and calls to this number about products, services, marketing, surveys, and claims, including by automatic telephone dialing system or an artificial or prerecorded voice, from Farmers® agents and Farmers, Foremost®, Bristol West®, and 21st Century® Insurance entities and their other representatives. Your consent is not a condition of purchase. ',
            // tslint:disable-next-line: max-line-length
            TN: 'By clicking “NEXT” you agree to receive texts and calls to this number about products, services, marketing, surveys, and claims, including by automatic telephone dialing system or an artificial or prerecorded voice, from Farmers® agents and Farmers, Foremost®, Bristol West®, and 21st Century® Insurance entities and their other representatives. Your consent is not a condition of purchase. ',
            // tslint:disable-next-line: max-line-length
            TX: 'By clicking “NEXT” you agree to receive texts and calls to this number about products, services, marketing, surveys, and claims, including by automatic telephone dialing system or an artificial or prerecorded voice, from Farmers® agents and Farmers, Foremost®, Bristol West®, and 21st Century® Insurance entities and their other representatives. Your consent is not a condition of purchase. ',
            // tslint:disable-next-line: max-line-length
            UT: 'By clicking “NEXT” you agree to receive texts and calls to this number about products, services, marketing, surveys, and claims, including by automatic telephone dialing system or an artificial or prerecorded voice, from Farmers® agents and Farmers, Foremost®, Bristol West®, and 21st Century® Insurance entities and their other representatives. Your consent is not a condition of purchase. ',
            // tslint:disable-next-line: max-line-length
            VA: 'By clicking “NEXT” you agree to receive texts and calls to this number about products, services, marketing, surveys, and claims, including by automatic telephone dialing system or an artificial or prerecorded voice, from Farmers® agents and Farmers, Foremost®, Bristol West®, and 21st Century® Insurance entities and their other representatives. Your consent is not a condition of purchase. ',
            // tslint:disable-next-line: max-line-length
            WA: 'By clicking “NEXT” you agree to receive texts and calls to this number about products, services, marketing, surveys, and claims, including by automatic telephone dialing system or an artificial or prerecorded voice, from Farmers® agents and Farmers, Foremost®, Bristol West®, and 21st Century® Insurance entities and their other representatives. Your consent is not a condition of purchase. ',
            // tslint:disable-next-line: max-line-length
            WI: 'By clicking “NEXT” you agree to receive texts and calls to this number about products, services, marketing, surveys, and claims, including by automatic telephone dialing system or an artificial or prerecorded voice, from Farmers® agents and Farmers, Foremost®, Bristol West®, and 21st Century® Insurance entities and their other representatives. Your consent is not a condition of purchase. ',
            // tslint:disable-next-line: max-line-length
            WY: 'By clicking “NEXT” you agree to receive texts and calls to this number about products, services, marketing, surveys, and claims, including by automatic telephone dialing system or an artificial or prerecorded voice, from Farmers® agents and Farmers, Foremost®, Bristol West®, and 21st Century® Insurance entities and their other representatives. Your consent is not a condition of purchase.',
             // tslint:disable-next-line: max-line-length
            KY: 'By clicking NEXT, you agree to receive texts and calls to this number about products, services, marketing, surveys, and claims, including by automatic telephone dialing system or an artificial or prerecorded voice, from Farmers® agents and from Farmers, Foremost®, Bristol West®, and 21st Century® Insurance entities and their other representatives. Your consent is not a condition of purchase. \n\n Alternatively, you can speak with a Farmers representative to get a quote by calling [phoneNumber].',
        };
    }

    /**
     * Gets retrieve confirmation content
     */
    public static get RetrieveConfirmationContent(): any {
        return {
            maintitle: 'Thank you for retrieving your quote',
            subContent: 'Your quote may have changed since the last time you stopped by.',
            buttonText: 'Update Your Quote'
        };
    }

    /* type list used to identify the vehicle type from model
     (for the states where the isFaThreeImpl:false returned by projectCodeService) */
    public static get VehicleTypeValList() {
        return [{
            value: 'CC',
            label: 'CONVERTIBLES'
        }, {
            value: 'CP',
            label: 'COUPE'
        }, {
            value: 'PU',
            label: 'PICKUP'
        }, {
            value: 'SD',
            label: 'SEDAN'
        }, {
            value: 'UT',
            label: 'SUV'
        }, {
            value: 'VN',
            label: 'VAN'
        }, {
            value: 'WG',
            label: 'WAGON'
        }, {
            value: 'CC',
            label: 'CONV'
        }, {
            value: 'WG',
            label: 'WGN'
        }];
    }

    /* type list used to identify the vehicle type from model
     (for the states where the isFaThreeImpl:true returned by projectCodeService) */
    public static get VehicleTypeValListFA3() {
        return [{
            value: 'CC',
            label: 'CONVERTIBLES'
        }, {
            value: 'CP',
            label: 'COUPE'
        }, {
            value: 'PX',
            label: '2WD PICKUP'
        },
        {
            value: 'PX',
            label: 'PICKUP 2WD'
        }, {
            value: 'PY',
            label: '4WD PICKUP'
        }, {
            value: 'PY',
            label: 'PICKUP 4WD'
        }, {
            value: 'SD',
            label: 'SEDAN'
        }, {
            value: 'UX',
            label: '2WD SUV'
        }, {
            value: 'UX',
            label: 'SUV 2WD'
        }, {
            value: 'UY',
            label: '4WD SUV'
        }, {
            value: 'UY',
            label: 'SUV 4WD'
        }, {
            value: 'VN',
            label: 'VAN'
        }, {
            value: 'WG',
            label: 'WAGON'
        }, {
            value: 'CC',
            label: 'CONV'
        }, {
            value: 'WG',
            label: 'WGN'
        }
        ];
    }
    /**
     * Gets the Signal Banner popup content
     */
    public static get SignalBannerPopupContent() {
        return {
            // tslint:disable-next-line: max-line-length
            contentForAllOtherState: '<p>Connect with an agent or a Farmers® representative at <a class="white-space-nowrap" href="tel:1-800-709-4931" data-GTM="FFQ_Auto_Quote_Signal_Banner_Phone_Link"><span>1</span>-<span>8</span><span>0</span><span>0</span>-<span>7</span><span>0</span><span>9</span>-<span>4</span><span>9</span><span>3</span><span>1</span></a> to learn more about how you can save with Signal.</p>',
            // tslint:disable-next-line: max-line-length
            contentForEEStates: '<p>Grab your quote number and connect with a Farmers® representative at <a class="white-space-nowrap" href="tel:1-800-709-4931" data-GTM="FFQ_Auto_ThankYou_Signal_Banner_Phone_Link"><span>1</span>-<span>8</span><span>0</span><span>0</span>-<span>7</span><span>0</span><span>9</span>-<span>4</span><span>9</span><span>3</span><span>1</span></a> to learn more about how you can save with Signal.</p>',
        };
    }

    /**
     * Gets quote error messages
     */
    public static get quoteErrorMessages(): any {
        return {
            VM6001: 'The Property Damage limit selected cannot exceed the Bodily Injury limit.',
            VM6002: 'Towing and Road Service coverage cannot be selected without Collision coverage.',
            VM6003: 'You must have Comprehensive coverage with a deductible of $100 or more to select Glass Deductible Buyback coverage.',
            VM6004: 'You must have Comprehensive coverage with a deductible of $250 or more to select Glass Deductible Buyback coverage.',
            // tslint:disable-next-line: max-line-length
            VM6005: 'Collision coverage cannot be selected without Comprehensive coverage in an online quote. Please call an agent to purchase coverages individually.',
            VM6006: 'The Uninsured Motorist limit selected cannot exceed the Bodily Injury limit.',
            VM6007: 'Uninsured Motorist without Collision coverage cannot be selected without Uninsured Motorist coverage.',
            VM6007A: 'Uninsured Motorist without Collision coverage cannot be selected with Collision coverage.',
            VM6008: 'Collision Plus/Loss of Use coverage cannot be selected without Collision coverage.',
            // GW3 changes - US105408 UMPD without Collision coverage rules for CO state - start
            VM6009: 'Uninsured Motorist Property Damage without Collision coverage cannot be selected with Collision coverage.',
            // tslint:disable-next-line:max-line-length
            VM6010: 'Uninsured Motorist Property Damage without Collision coverage cannot be selected without Uninsured Motorist Bodily Injury  coverage.',
            // GW3 changes - US105408 UMPD without Collision coverage rules for CO state - end
            VM6011: 'Uninsured Motorist PD without Collision coverage cannot be selected without Uninsured Motorist coverage.',
            VM6012: 'Uninsured Motorist PD without Collision coverage cannot be selected with Collision coverage.',
            VM6013: 'Uninsured Motorist/Under Insured Motorist Bodily Injury limit selected cannot exceed the Bodily Injury limit.',
            VM6014: 'Collision and Comprehensive coverages are required.',
            VM6014A: 'Collision and Comprehensive coverages are required.',
            VM6015: 'Under Insured Motorist BI selected cannot exceed the Bodily Injury limit.',
            VM6016: 'Underinsured Motorist limit selected cannot exceed the Bodily Injury limit.',
            VM6017: 'Uninsured Motorist Bodily Injury selected cannot exceed the Bodily Injury limit.',
            VM6018: 'You have selected multiple collision coverages. Please select only one of the available collision coverages.',
            VM6019: 'Medical Coverage and Personal Injury Protection  cannot be selected Simultaneously.',
             // US154387 - UMPD without Collision  and Collision coverage rule for CA
            VM6020: 'Uninsured Motorist Property Damage without Collision coverage cannot be selected with Collision coverage.',
            // US154407 - UMPD with Collision  and Collision coverage rule for CA
            VM6021: 'Uninsured Motorist Property Damage with Collision coverage cannot be selected without Collision coverage.',
            VM6022: 'Uninsured Motorist Property Damage cannot be selected with Collision coverage.',
            VM6023: 'The uninsured Motorist/under insured Motorist Property Damage limit selected cannot exceed the property Damage limit.',
            // tslint:disable-next-line: max-line-length
            VM6024: 'The uninsured Motorist/under insured Motorist Property Damage cannot be selected without uninsured Motorist/under insured Motorist Bodily Injury.',
            VM6025: 'Principal PIP - Med Exp Only cannot be selected without Principal PIP.',
            VM6026: 'Uninsured Motorist Bodily Injury - Stacking selected cannot exceed Bodily Injury limit.',
            VM6027: 'Under Insured Motorist Bodily Injury - Stacking selected cannot exceed Bodily Injury limit.',
            VM6028: 'Select either Uninsured Motorists (UM) or Supplementary Uninsured/Underinsured Motorists (SUM) coverage.',
            // tslint:disable-next-line: max-line-length
            VM6029: 'When Uninsured/Underinsured Motorist Bodily Injury coverage is greater than BI limits, It must be equal to twice the Bodily Injury Limit.',
            // tslint:disable-next-line: max-line-length
            VM6030: 'When Uninsured/Underinsured Motorist Bodily Injury Conversion coverage is greater than BI limits, it must be equal to twice the Bodily Injury Limit.',
            VM6031: 'Uninsured/Underinsured Motorist Bodily Injury is required.',
            // tslint:disable-next-line: max-line-length
            VM6032: 'Uninsured/Underinsured Motorist Bodily Injury cannot be selected with Uninsured/Underinsured Motorist Bodily Injury Conversion.',
            VM6033: 'The Enhanced Underinsured Motorist limit selected should match the Bodily Injury limit.',
            VM6034: 'The Enhanced Underinsured Motorist Property Damage limit selected should match the Property Damage limit.',
            VM6035: 'Please select Comprehensive coverage to add Safety Glass - Waiver of Deductible.',
            BR7017: 'UIM cannot have higher limits than BI.',
            BR7018: 'UM cannot have higher limits than BI.',
            BR7018A: 'UM/UIM cannot have higher limits than BI.',
            BR7035E: 'PIP coverage is mandatory coverage.',
            ABR0000: 'Income Continuation is mandatory when Extra PIP coverage is selected.',
            ABR0001: 'Extra PIP cannot be selected without Principal PIP.',
            ABR0002: 'Extra PIP Package coverage cannot be selected without Principal PIP coverage.',
            // tslint:disable-next-line:max-line-length
            ABR0003: 'Extra PIP Package for Primary Insured, Spouse & Resident Relatives coverage cannot be selected without Extra PIP Package coverage.',
            VM6036: 'Uninsured Motorist Bodily Injury limit should be equal to Underinsured Motorist Bodily Injury limit.',
            // tslint:disable-next-line: max-line-length
            VM6037: 'Uninsured Motorist/Under Insured Motorist Property Damage limit selected cannot exceed the Property Damage limit.',
            VM6038: 'Under Insured Motorist Property Damage limit selected cannot exceed the Property Damage limit.',
            VM6039: 'Uninsured Motorist - Property Damage coverage cannot be selected without Uninsured Motorist - Bodily Injury coverage.',
            // tslint:disable-next-line: max-line-length
            VM6040: 'Uninsured Motorist - Property Damage coverage cannot be selected without Underinsured Motorist - Bodily Injury coverage.',
            VM6041: 'The Uninsured Motorist Property Damage limit selected cannot exceed the Property Damage limit.',
            // tslint:disable-next-line: max-line-length
            VM6042: 'Uninsured Motorist - Property Damage coverage cannot be selected without Uninsured Motorist - Bodily Injury coverage or Underinsured Motorist - Bodily Injury coverage.',
            VM6043: 'Underinsured Motorist Bodily Injury selected cannot exceed the Bodily Injury limit.',
            VM6044: 'Property Damage selected cannot exceed the Bodily Injury limit.',
            // tslint:disable-next-line: max-line-length
            VM6045: 'Underinsured Motorist Property Damage coverage cannot be selected without Underinsured Motorist Bodily Injury coverage.',
            // tslint:disable-next-line: max-line-length
            VM6046: 'Uninsured Motorist - Property Damage coverage cannot be selected without Uninsured Motorist/Underinsured Motorist Bodily Injury coverage.',
            VM6047: 'Comprehensive and Collision coverages are required to select Rental reimbursement coverage.',
            // GW3 - changes - start
            // VM6048: 'Uninsured Motorist/Underinsured Motorist Bodily Injury limit selected cannot exceed the Bodily Injury limit.',
            VM6049A: 'Underinsured Motorist Bodily Injury coverage cannot be selected without Uninsured Motorist Bodily Injury coverage.',
            VM6050A: 'Uninsured Motorist Property Damage coverage cannot be selected without Uninsured Motorist  Bodily Injury coverage.',
            VM6051A: 'Uninsured Motorist Property Damage coverage cannot be selected without Underinsured Motorist Bodily Injury coverage.',
            VM6052A: 'Waiver of Work loss requires Personal Injury Protection Coverage with $0 work loss deductible',
            VM6053A: 'Personal Injury Protection coverage or Guest PIP coverage is required.',
            VM6054A: 'Personal Injury Protection coverage cannot be selected with Guest PIP coverage',
            VM6055A: 'Guest PIP cannot be selected with Rideshare coverage.',
            VM6056A: 'The Uninsured Motorist Bodily Injury limit selected cannot exceed the Bodily Injury limit.',
            VM6047A: 'Underinsured Motorist Bodily Injury selected cannot exceed the Uninsured Motorist Bodily Injury limit.',
            // tslint:disable-next-line:max-line-length
            VM6057: 'Uninsured Motorist PD without Collision coverage cannot be selected without Uninsured Motorist Bodily Injury coverage.',
            VM6058: 'Underinsured Motorist Bodily Injury limit selected cannot exceed the Bodily Injury limit.',
            VM6059: 'The Enhanced Underinsured Motorist Bodily Injury limit selected should match the Bodily Injury limit.',
            // GW3 - changes - end
            VM6048: 'Uninsured Motorist/Underinsured Motorist Bodily Injury limit selected cannot exceed the Bodily Injury limit.',
            VM6049: 'Collision or Comprehensive coverage is required.',
            VM6050: 'Uninsured Motorist Bodily Injury Economic Loss Only limit selected cannot exceed the Bodily Injury limit.',
            VM6051: 'Limited Collision coverage cannot be selected with Collision coverage.',
            VM6052: 'Comprehensive (Part 9) coverage is required to select Substitute Transportation (Part 10) coverage.',
            VM6053: 'Underinsured Motorist - Bodily Injury (Part 12) selected cannot exceed the Optional Bodily Injury (Part 5) limit.',
            VM6054: 'Property Damage (Part 4) selected cannot exceed the Optional Bodily Injury (Part 5) limit',
            VM6055: 'Uninsured Motorist PD without collision coverage cannot be selected without Uninsured Motorist BI coverage.',
            VM6056: 'Uninsured Motorist Bodily Injury (Part 3) selected cannot exceed the Optional Bodily Injury (Part 5) limit.',
            VM6064: 'Comprehensive coverage is required to select Rental reimbursement coverage.',
            VM6065: 'Uninsured Motorist/Underinsured Motorist Property Damage limit selected cannot exceed the Property Damage limit.',
            VM6068: 'You must have Comprehensive coverage with a deductible of more than $100 to select $100 Glass Coverage.',
            // GW-CA changes - start
            // tslint:disable-next-line:max-line-length
            VM6060: 'Uninsured Motorist Property Damage with Collision coverage cannot be selected without Uninsured Motorist Bodily Injury coverage.',
            // tslint:disable-next-line:max-line-length
            VM6061: 'Uninsured Motorist Property Damage without Collision coverage cannot be selected without Uninsured Motorist Bodily Injury coverage.',
            // GW-CA changes - end
            // US248728
            VM6066: 'Uninsured Motorist PD without collision coverage cannot be selected with Collision coverage.',
            // US248713
            VM6067: 'Comprehensive and Collision coverages are required to select Rental coverage.',
            VM6062: 'Add either  UM/UIM - Bodily Injury or UMBI - Economic Loss Only coverage. One of these is required.',
            VM6069: 'Comprehensive and Collision coverages are required to select Vehicle Replacement Plus coverage.',
            VM6070: 'Roadside Assistance coverage options must be the same for all vehicles desiring coverage. Please select ' +
             'either Standard Roadside Assistance or Enhanced Roadside and Ride Assistance option for all covered vehicles.',
            VM6071: 'Uninsured Motorist/Underinsured Motorist Bodily Injury coverage cannot be selected without Uninsured ' +
             'Motorist/Underinsured Motorist Property Damage coverage.',
            VM6072: 'Uninsured Motorist/Underinsured Motorist Property Damage coverage cannot be selected without ' +
             'Uninsured Motorist/Underinsured Motorist Bodily Injury coverage.',
            // US363615: Flex 6.0 (TX) - Auto - Display PIP and Medical coverage
            VM6073: 'Medical Coverage and Personal Injury Protection  cannot be selected simultaneously.',
            VM6074: 'Medical coverage requires Bodily Injury Liability coverage. Please add Bodily Injury or remove Medical.',
            VM6075: 'Not available for vehicles over 20 years old.  Please select No Coverage.',
            VM6076: 'Please select either Principal PIP coverage or Principal PIP - Medical Expense Only coverage.',
            // US482987 - Flex 8.0 (CO) - Auto -  Display UMPD without Collision and UMBI coverages
            VM6077: 'Uninsured Motorist Property Damage without Collision coverage cannot be selected' +
             ' without Uninsured Motorist Bodily Injury coverage.',
            VM6078: 'Underinsured Motorist Bodily Injury selected should be equal to the Uninsured Motorist Bodily Injury limit.',
            VM6079: 'Please select either Uninsured Motorist/Underinsured Motorist Bodily Injury coverage or ' +
             'Uninsured Motorist/Underinsured Motorist Bodily Injury Conversion coverage.',
            // US514167 - Flex 8.0 (CT) - Auto - Display hardstop message when the UM/UIMBI or BI coverages are updated
            VM6080: 'When selecting an Uninsured/Underinsured Motorist Bodily Injury coverage limit that is greater than the BI limits, ' +
            'it must be equal to twice the Bodily Injury limit.',
            // US514195 - Flex 8.0 (CT) - Auto - Display hardstop message when the UM/UIMBI Conversion or BI coverages are updated
            VM6081: 'When selecting an Uninsured/Underinsured Motorist Bodily Injury Conversion coverage limit that is greater than  ' +
            'the BI limits, it must be equal to twice the Bodily Injury limit.',
        };
    }

    /** renters bundle constants */
    public static get RentersErrorMessage() {
        return {
            errorMessageWithAgent: 'We are unable to add Renters to your quote at this time. Please contact your agent for assistance.',
            errorMessageWithOutAgent: 'We are unable to add Renters to your quote at this time. Please contact us at (800)206-9469.'
        };
    }


    /**
     * Gets source phone number mapping
     */
    public static get SourcePhoneNumberMapping() {
        return [{
            number: '1-800-319-8069',
            src: 'PERAAHMEFQ'
        },
        {
            number: '1-800-319-8069',
            src: 'AMPAAHMEFQ'
        },
        {
            number: '1-888-422-3049',
            src: 'PERAAAMEFQ'
        },
        {
            number: '1-800-653-4002',
            src: 'PERAAHQUFQ'
        },
        {
            number: '1-888-422-3049',
            src: 'AMPAAAMEFQ'
        },
        {
            number: '1-800-319-8094',
            src: 'PERAAAQUFQ'
        }
        ];
    }

    public static get BODILYINJURYLABEL() {
        return {
            commonLabel: 'What are the bodily injury limits on your auto insurance policy?',
            insuredAzIl: 'What are the bodily injury limits on your auto insurance policy?',
            insuranceExpiredAzIl: 'What were the bodily injury limits on your auto insurance policy?',
            insuredAzIlADImpl: 'What are the bodily injury limits on your current auto insurance policy?',
            insuranceExpiredAzIlADImpl: 'What were the bodily injury limits on your prior auto insurance policy?'
        };
    }

    /**
     * Gets aria label for ride share
     */
    public static get RIDESHAREARIALABEL() {
        return {
            ariaLabelFlex: 'Is this vehicle used for ridesharing, on-demand food delivery, or both'
        };
    }

    public static get BODILYINJURYVALUE() {
        return {
            YES: 'Yes',
            NP: 'NP',
            NN: 'NN'
        };
    }
    /**
     * Gets next button metlife text
     */
    public static get nextButtonMetlifeText(): any {
        return {
            displayTextMetlife: 'CONTINUE',
            displayTextNormal: 'CONTINUE',
            enableAriaLabel: 'Step 2 Add Vehicle',
            disableAriaLabel: 'Next: Vehicle',
            enableAriaLabelMetlife: 'CONTINUE',
            disableAriaLabelMetlife: 'CONTINUE'
        };
    }

    /**
     * Complete definitions constants
     */
    public static get COMPLETE_DEF_CONST() {
        return {
            dialogCloseButtonGTMTag: 'FFQ_AUTO_AddDriverInfo_complete_Def_close_Button',
            dialogCloseIconGTMTag: 'FFQ_AUTO_AddDriverInfo_complete_Def_close_Icon',
            h1Title: 'CONFLICT_TITLE_H1',
            h2Title: 'CONFLICT_TITLE_H2',
            h4Title: 'CONFLICT_TITLE_H4',
            excludedDriverContent: 'CONFLICT_EXCLUDED_DRIVER',
            oohouseHoldContent: 'CONFLICT_HOUSEHOLD',
            excludedLearnersPermitContent: 'CONFLICT_LEARNERS_PERMIT',
            reasonUnderAgeContent: 'CONFLICT_UNDER_AGE',
            reasonRejectedContent: 'CONFLICT_REJECTED'
        };
    }

    /**
     * Additional Driver Info constants
     */
    public static get ADD_DRIVER_INFO_CONST() {
        return {
            h1Title: 'CONFLICT_TITLE_H3',
            h2Title: 'CONFLICT_TITLE_H2',
            driverUnderAge: 18,
            permittedAgeGroups: [15, 16, 17, 18],
            permittedPnis: ['I', 'O', 'P', 'R'],
            permittedMaritalStatus: ['M'],
            ContinueGTMTag: 'FFQ_AUTO_AddDriverInfo_Continue_Btn',
            showCompleteDefinitionsGTMTag: 'FFQ_AUTO_AddDriverInfo_Info_icon',
            rejectReason_excludedDriver_GtmTag: 'FFQ_AUTO_AddDriverInfo_ExcludedDriver',
            rejectReason_ooHousehold_GtmTag: 'FFQ_AUTO_AddDriverInfo_OOHousehold',
            rejectReason_Accepted_GtmTag: 'FFQ_AUTO_AddDriverInfo_Accepted',
            rejectReason_UnknownPerson_GtmTag: 'FFQ_AUTO_AddDriverInfo_UnknownPerson',
            rejectReason_age_GtmTag: 'FFQ_AUTO_AddDriverInfo_Age',
            maritalstatus_Married_GtmTag: 'FFQ_AUTO_AddDriverInfo_Married',
            maritalstatus_Separated_GtmTag: 'FFQ_AUTO_AddDriverInfo_Separated',
            maritalstatus_Single_GtmTag: 'FFQ_AUTO_AddDriverInfo_Single',
            maritalstatus_Widowed_GtmTag: 'FFQ_AUTO_AddDriverInfo_Widowed',
            pni_Daughter_GtmTag: 'FFQ_AUTO_AddDriverInfo_Daughter',
            pni_Grandchild_GtmTag: 'FFQ_AUTO_AddDriverInfo_Grandchild',
            pni_Inlaw_GtmTag: 'FFQ_AUTO_AddDriverInfo_InLaw',
            pni_Other_GtmTag: 'FFQ_AUTO_AddDriverInfo_Other',
            pni_Parent_GtmTag: 'FFQ_AUTO_AddDriverInfo_Parent',
            pni_Relative_GtmTag: 'FFQ_AUTO_AddDriverInfo_Relative',
            pni_Son_GtmTag: 'FFQ_AUTO_AddDriverInfo_Son',
            pni_Spouse_GtmTag: 'FFQ_AUTO_AddDriverInfo_Spouse',
            pageTitle: 'FFQ- Auto Insurance- Additional Info',
            requiredFields: ['rejectReason_', 'maritalStatus_', 'pni_', 'dateOfBirth_']
        };
    }

    public static get DISCOUNT_JOURNEY_HEADER() {
        return {
            yourInfoDiscountHeader: 'To save you time, we will use your name, address ' +
            'and date of birth to pre-fill some information.',
            addVehicleHeader: 'Add vehicle(s) to your quote by VIN Number or Year, Make, and Model.',
            addVehicleAdpfHeader: 'Confirming we found these vehicles at this address. ' +
            'Add them to your quote to save time, or add another not listed.',
            addDriverAdpfHeader: 'Below are the drivers we found at your address. Who do you want to insure ?',
            additionalInfoHeader: 'This info is needed to send your quote to you. ',
            potentialDiscountText: 'potential savings/discounts. Let\'s find some more.',
            potentialDiscountTextQuote: 'potential savings/discounts - subject to validation and eligibility requirements.',
            appliedDiscountHeader: 'QUOTE_Applied_Discounts_And_Savings',
            appliedDiscountClose: 'Close'
        };
    }

    public static get DRIVER_ASSIGNMENT_ERROR_MSG() {
        return {
            errormessage: 'Each driver should be assigned to at least one vehicle.'
        };
    }

    public static get POLICY_START_DATE_VALIDATION_MESSAGE() {
        return {
            datepickerMinimum: 'Date cannot be less than minDateText from today’s date.'
        };
    }

    public static get SIGNAL_BANNER_POPUP_TEXT() {
        return {
            forNCState: '*Signal Sweepstakes not available in NC, AR and MN.',
            otherStates: '*Signal Sweepstakes not available in AR and MN.',
            headerTextNC: 'Earn a discount with Signal',
            headerTextOthers: 'Earn rewards for safe driving',
            contentTextNC: 'The Signal app allows you to earn a 5% initial discount and up to'
                            + ' 15% each time your policy renews, based on your safe driving.*',
            contentTextOthers: 'The Signal app allows you to earn a 5% discount and up to'
                            + ' 15% each time your policy renews, based on your safe driving.*'
        };
    }

    /**
     * US319654
     * Signal Program Banner constants
     */
    public static get SIGNAL_PROGRAM_BANNER_TEXT() {
        return {
            forFlexExceptAZAndILHeader: 'Looking to save 10% on your auto policy?',
            otherStatesHeader: 'Want to save 5% on your auto policy?',
            forFlexExceptAZAndILParagraph: 'It’s as easy as downloading and using our Signal® app! Earn a'
                            + ' 10% initial discount today and up to 35% each time your policy renews'
                            + ' based on your safe driving.* An additional 5% will be incorporated when you sign up drivers under 25.**',
            otherStatesParagraph: 'It’s as easy as downloading and using our Signal® app! Earn a 5% initial discount '
                            + 'today and up to 15% each time your policy renews, based on your safe driving.* You can '
                            + 'save an additional 10% when you sign up drivers under 25.**'
        };
    }

    /**
     * US319654
     * Signal Program learn more pop-up constants
     */

    public static get SIGNAL_PROGRAM_LEARN_MORE_POPUP_TEXT() {
        return {
            forFlexExceptAZAndILHeader: 'Drive with Signal®',
            otherStatesHeader: 'Save with Signal®',
            forFlexExceptAZAndILStep1: 'Step 1: Choose “Yes, sign up for Signal®”.'
                            + ' We\'ll also need mobile phone numbers for all enrolled drivers to maximize'
                            + ' your potential savings.',
            otherStatesStep1: 'Step 1: Choose “Yes, sign up for Signal®”.'
                            + ' We\'ll also need mobile phone numbers for all enrolled drivers to maximize'
                            + ' your savings.',
            forFlexExceptAZAndILParagraph: 'Gain insights into your driving behavior! Earn a'
                            + ' 10% initial discount today and up to 35% when your policy renews based on'
                            + ' your safe driving.* An additional 5% will be incorporated when you sign up'
                            + ' drivers under 25.**',

            otherStatesParagraph: 'A fast and simple way to save money on your Farmers® auto policy.'
                            + ' Earn a 5% initial discount today and up to 15% when your policy renews,'
                            + ' based on your safe driving.* You can save an additional 10% when you sign'
                            + ' up drivers under 25.**'
        };
    }

    /**
     * US322299: Display Signal information on Thank you page
     */
    public static get SIGNAL_INFORMATION_THANKYOU_PAGE() {
        return {
            otherStatesContent: 'Interested in saving an additional 5% on top of your quoted price?',
            flexStatesContent: 'Interested in saving an additional 10% on top of your quoted price?'
        };
    }

    /**
     * US255602: Segment Field Engagement Descriptions
     */
    public static get SEGMENT_FIELD_ENGAGEMENT_DESCRIPTION() {
        return {
            yourInfo: 'Personal Information',
            drivers: 'Driver Addition',
            scheduleAppt: 'Schedule Agent Appointment'
        };
    }

    /**
     * US279517: Employer drop down error message
     */
    public static get EMPLOYER_DROP_DOWN_ERROR() {
        return {
            errorMessage: 'Sorry, please try another employer or affinity group. ' +
            'Or click "Next" and we\’ll look for other discounts to apply.'
        };
    }

    /**
     * US392263: VD assignment validation error message
     */
    public static get VD_AASSIGNMENT_ERROR() {
        return {
            commuteErrorMessage: 'Each driver can have only one vehicle for work/school commuting purpose.' +
            ' Please select appropriately or modify the usage in vehicle section.',
            commonErrorMessage: 'Each vehicle must have at least one driver',
            commonDriverErrorMessage: 'Each driver should be assigned to at least one vehicle.'

        };
    }

    public static get POLICY_PERKS_CONTENT(): PolicyPerksContentMap {
        return {
            NC: {
                title1: 'New car Replacement',
                // tslint:disable-next-line:max-line-length
                message1: 'New car totaled? With New Car Replacement, we’ll cover the cost to replace it with a new one of the same make and model.',
                assetPath1: '../../../assets/images/discounts/icon-auto.png',
                title2: 'Extended Transportation Expenses',
                // tslint:disable-next-line:max-line-length
                message2: 'Need a rental car?  With Extended Transportation Expenses, a per day amount toward transportation expenses is available when you have a covered loss under your auto insurance policy.',
                assetPath2: '../../../assets/images/discounts/icon_multi-car-policy.png'
            },

            COMMON: {
                title1: 'New car Replacement',
                // tslint:disable-next-line:max-line-length
                message1: 'New car totaled? We’ll cover the cost to replace it with a new one of the same make and model.',
                assetPath1: '../../../assets/images/discounts/icon-auto.png',
                title2: 'Accident Forgiveness',
                // tslint:disable-next-line:max-line-length
                message2: 'We won’t raise your rates because of an accident for every three years you drive without one.',
                assetPath2: '../../../assets/images/discounts/icons-auto-auto-collision.png'
            }
        };
    }

    /**
     * US314819: Added constants for help phone number
     */
    public static get HELP_PHONE_NUMBER() {
        return {
            isEEStateNumber: '1-888-767-4030',
            isEEStateNumberTele: 'tel:1-888-767-4030',
            nonEEStateNumber: '1-800-206-9469',
            nonEEStateNumberTele: 'tel:1-800-206-9469'
        };
    }

    /**
     * US501061 && US501062: CA FFQ Digital Monetization - CA State TFN Replacement
     * for Agent, Quote, Thank you and print quote pages for desktop and mobile windows
     */
    public static get TFN_HELP_NUMBER() {
        return {
            teleDesktop: 'tel:18005087504',
            tele: 'tel:18005087504',
            teleMobile: 'tel:18667388736',
            ariaLabelPhoneNumber: '18005087504',
            desktopNo: '1-800-508-7504',
            mobileNo: '1-866-738-8736',
            teleQuestion: '(800)-508-7504'
        };
    }

    public static get ERROR_CODES() {
        return {
            errorMsgCode: ['EG027', 'EG028', 'EG029', 'EG030'],
            errorMsgValidZip: 'Please enter a valid Zip Code.',
            errorIntermediatePage: ['EG004', 'EG025', 'EG026', 'EG033', 'EG034'],
            errorMsgCodeState: ['EG028', 'EG030'],
            errorQuoteNotFound: 'EL005',
            invalidZipCodeException: 'EG003',
            badActorErrorCode: 'EG032'
        };
    }

    /**
     * US355260: DNQ_BW_External URL
     */
    public static get BW_DNQ_EXTERNAL_URL() {
        return {
            URL: 'https://www.farmers.com/privacy-center/'
        };
    }

    /**
     * US458077: DNQ verbiage changes for BWBL popup
     */
    public static get BRISTOLWEST_REASON_VERBIAGE_MAP() {
        const value = [{ reason: 'PI', verbiageText: 'BW_DNQ_PI_VERBIAGE_TITLE' },
        { reason: 'PRFDL', verbiageText: 'BW_DNQ_PRFDL_VERBIAGE_TITLE' },
        { reason: 'PIFDL', verbiageText: 'BW_DNQ_PIFDL_VERBIAGE_TITLE' },
        { reason: 'OTFDL', verbiageText: 'BW_DNQ_OTFDL_VERBIAGE_TITLE' },
        { reason: 'PIOTFDL', verbiageText: 'BW_DNQ_PIOTFDL_VERBIAGE_TITLE' }
        ];
        return value;
    }

    /**
     * US346971: Display Monetization form in MA brightline quote
     */
    public static get KNOCKOUT_BRIGHTLINE_MA() {
        return {
            msg: 'We will need a bit more information about your vehicle. ' +
                'You may contact a representative yourself at [1-800-FARMERS]. Please reference your quote number: ',
            monetizationMsg1: 'In the meantime, the following companies may be able to provide you with an online quote.',
            monetizationMsg2: 'Please compare 2 or more of the following options'
        };
    }

    public static get ONLY_AGENT_QUOTE_AVAILABLE_MSG() {
        return {
            heading: 'We\'re sorry, but we are unable to provide an online quote at this time.',
            message: 'Please find an experienced Farmers agent in your area for a quote, or',
            additionalMessage: 'Click below for other options'
        };
    }

    /**
     * US346971: AWS source indicator array
     */
    public static get AWS_SOURCE_INDICATOR() {
        return {
            sourceIndicator_AS: 'AS',
            sourceIndicator_AP: 'AP'
        };
    }

    /**
     * US635458: Digital Monetization contstants
     */
    public static get DM_POPUP_TYPE_CONSTANTS() {
        return {
            NOFGIA_BWPOP: 'NOFGIA_BWPOP',
            NOFGIA_CIPOP: 'NOFGIA_CIPOP'
        };
    }

    /**
     * US346971: Agent default phone number
     */
    public static get AGENT_DEFAULT_PHONE() {
        return {
            number: '[1-800-FARMERS]'
        };
    }
    /**
     * get landing page lob svg
     */

    public static get LANDING_PAGE_IMAGE_SVG() {
        return {
            Auto: 'https://www.farmers.com/content/dam/farmers/marketing/digital/aem/icons/navigation/auto.svg',
            Home: 'https://www.farmers.com/content/dam/farmers/marketing/digital/aem/icons/navigation/home.svg',
            Life: 'https://www.farmers.com/content/dam/farmers/marketing/digital/aem/icons/navigation/life.svg',
            Business: 'https://www.farmers.com/content/dam/farmers/marketing/digital/aem/icons/navigation/business-property.svg',
            Renters: 'https://www.farmers.com/content/dam/farmers/marketing/digital/aem/icons/misc/icon-renters.svg',
            Condo: 'https://www.farmers.com/content/dam/farmers/marketing/digital/aem/icons/misc/icon-condo.svg'

        };
    }
    /**
     * get landing page lob titles
     */

    public static get LANDING_PAGE_LOB_TITLES() {
        return {
            Auto: 'Select Auto to get Auto Quote',
            Home: 'Select Home to get Home Quote',
            Life: 'Select Life to get Life Quote',
            Business: 'Select Business to get Business Quote',
            Renters: 'Select Renters to get Renters Quote',
            Condo: 'Select Condo to get Condo Quote'
        };
    }
    /**
     * get landing page lob gtm tags
     */
    public static get LANDING_PAGE_LOB_GTM_TAGS() {
        return {
            Auto: 'FFQ_Auto_Landing_Newquote_Auto_link',
            Home: 'FFQ_Auto_Landing_Newquote_Home_link',
            Life: 'FFQ_Auto_Landing_Newquote_Life_link',
            Business: 'FFQ_Auto_Landing_Newquote_Business_link',
            Renters: 'FFQ_Auto_Landing_Newquote_Renters_link',
            Condo: 'FFQ_Auto_Landing_Newquote_Condo_link'
        };
    }

    /**
     * US379679: get placement id for monetization page
     */
    public static get PLACEMENT_ID() {
        return {
            Iidknockout: 'uxyXWCoF6gj9kUljYrzlvNrViGofvw',
            MAmonetization: 'q92rIciTNSsuRf0VLVQ3elTuUhMIsQ'
        };
    }

    // US376058: Error messages for agent appointment popup
    public static get AGENT_APPOINTMENT_ERROR_MESSAGES() {
        return {
            FirstNameReq: 'Please enter your first name.',
            LastNameReq: 'Please enter your last name.',
            EmailReq: 'Please enter your email address.',
            EmailPattern: 'Email address entered in invalid.',
            PhoneNumPattern: '{} is not a valid entry.',
            DateReq: 'Please select a date for the appointment.',
            DateMin: 'Please select a valid date.',
            DateMax: 'Appointment date has to be within 30 days from next day.',
            TimeReq: 'Please choose a time for the appointment',
            ExisReq: 'Please choose yes or no, if you are an existing Farmers customer',
            ReasonReq: 'Please select a reason for appointment',
            QuoteReq: 'Please enter quote number'
        };
    }

    public static get AGENT_APPOINTMENT_CONST() {
        return {
            Disclaimer1: 'AGENT_APPOINTMENT_DISCLAIMERS',
        };
    }
    /**
     *  US376218: get expiration date for cookie
     */
    public static get COOKIE_EXPIRY_DAYS() {
        return {
            days: 30
        };
    }
    /**
     * returns space
     */
    public static get SPACE() {
        return ' ';
    }
    /**
     * returns empty string
     */
    public static get EMPTY_STRING() {
        return '';
    }

    public static get NC_SUBSCRIPTION_VALIDATION() {
        return {
            subscriptionAgreementErrMsg: ' Please select the checkbox to ' +
            'acknowledge that you have read the Subscription Agreement and you agree to be bound by its terms.',
            NCSubscriptionLabel:  'I have reviewed the Subscription Agreement, ' +
            'and I agree to be bound by its terms. Please select the checkbox to acknowledge that you have read the ' +
            'Subscription Agreement and you agree to be bound by its terms.',
            NCSubscriptionErrorLabel:  'I have reviewed the Subscription Agreement, and I agree to be bound by its terms.'
        };
    }

    public subscriptionAgreementErrMsg: string = ' Please select the checkbox to ' +
    'acknowledge that you have read the Subscription Agreement and you agree to be bound by its terms.';

    public static get DNQ_MESSAGE_CONTENT(): any {
        return {
            // tslint:disable-next-line:max-line-length
            thankYouMsg: 'Thank you for your interest in Farmers® auto insurance. Unfortunately, this quote request does not meet our underwriting requirements for the following reasons:',
            reasonSubText: 'While Farmers is unable to provide you a quote*, we may be able to offer you a quote through our affiliate Bristol West.'
        };
    }
}
