import { Component, OnInit, AfterViewInit } from '@angular/core';
import { NavigationDrawerService } from './navigation-drawer.service';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { AppStoreService } from '@ffq-app-store/app.store.service';
import * as AppStates from '@ffq-app-store/app.state';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ModuleProgressStatus, RateQuoteProgressStatus } from '@ffq-app-shared/enums/flow.identifier.enum';
import { NavigationLinks } from '@ffq-app-shared/model/navigation-links.model';
import { SaveAndFinishLaterObject } from '@ffq-app-auto/drivers';
import { Subscription } from 'rxjs';
import { SaveAndFinishComponent } from '../save-and-finish/save-and-finish.component';
import { ActionDialogComponent } from '@ffq-lib';
import { LibraryDataService } from '@ffq-lib/src/lib/core/services/library-data.service';
import { NavigationHelperService } from '@ffq-app-shared/services/navigation-helper.service';
import { ServiceConstants, DataConstants } from '@ffq-app-shared/constants';
import { take } from 'rxjs/operators';
import { LegacyRedirectionService } from '@ffq-app-core/services/legacy.redirection.service';
import { SpinnerService } from '@ffq-lib/src/lib/spinner/spinner.service';
import { RideShareStateRuleModel, DriverAssignmentStateRuleModel,
  AssignVehicleDriverStateRuleModel } from '@ffq-app-shared/model/state.rule.model';
import { StateRuleModelService } from '@ffq-app-shared/services/state-rule-model.service';
import { NavigationStepsAutoEnum } from '@ffq-app-shared/enums/navigation-steps-auto.enum';
import { AutoStoreService } from '@ffq-app-auto/store/auto.store.service';
import { DriversStoreService } from '@ffq-app-auto/drivers/store/drivers.store.service';
import { ConnectedCarRequest } from '@ffq-app-shared/model/common.data.model';



@Component({
  selector: 'app-navigation-drawer',
  templateUrl: './navigation-drawer.component.html',
  styleUrls: ['./navigation-drawer.component.scss']
})
export class NavigationDrawerComponent implements OnInit, AfterViewInit {

  /**
   * Active page title of navigation drawer component
   */
  public activePageTitle = '';
  /**
   * Show drawer of navigation drawer component
   */
  public showDrawer = false;
  /**
   * Steps completed of navigation drawer component
   */
  public stepsCompleted = 1;
  /**
   * Current step of navigation drawer component
   */
  public currentStep = 1;
  /**
   * Quote number of navigation drawer component
   */
  public quoteNumber = null;
  /**
   * Show as strip of navigation drawer component
   */
  public showAsStrip = false;
  /**
   * Show as panel of navigation drawer component
   */
  public showAsPanel = true;
  /**
   * Show Skeletal Loader panel of navigation drawer component
   */
  public showSkeletalLoaderPanel = false;
  /**
   * Enable quote number of navigation drawer component
   */
  public enableQuoteNumber = false;
  /**
   * Module progress status of navigation drawer component
   */
  public moduleProgressStatus = ModuleProgressStatus;
  /**
   * Save and finish later object of navigation drawer component
   */
  saveAndFinishLaterObject: SaveAndFinishLaterObject;
  //  private sidePanelClass: string = 'sidepanel-container-strip';
  public sidePanelClass = 'sidepanel-container';
  /**
   * Edited  of navigation drawer component
   */
  edited: boolean;
  /**
   * Navigation links of navigation drawer component
   */
  public navigationLinks: Array<any> = [];
  /**
   * Subscriptions  of navigation drawer component
   */
  private subscriptions: Subscription[] = [];
  /**
   * Selected lob of navigation drawer component
   */
  selectedLob: string;
  /**
   * Ccpa url of navigation drawer component
   */
  ccpaUrl: string;
  /**
   * Quoted Lob of navigation drawer component
   */
  quotedLob: any;
  /**
   * crossSellRedirection of navigation drawer component
   */
  crossSellRedirection = false;
  /**
   * rateSummaryRedirection of navigation drawer component
   */
  rateSummaryRedirection = false;
  /**
   * lobData of navigation drawer component
   */
  lobData = DataConstants.LOBDATAMODEL;
  /**
   * displayLOB of navigation drawer component
   */
  displayLOBList = [];
  /**
   * homeBoundIndicator of navigation drawer component
   */
  homeBoundIndicator: any;
  /**
   * lifeBoundIndicator of navigation drawer component
   */
  lifeBoundIndicator: any;
  /**
   * landingStateCode of navigation drawer component
   */
  landingStateCode: any;
  /**
   * notClickableHomeIcon of navigation drawer component
   */
  notClickableHomeIcon: boolean;
  /**
   * landingZipCode of navigation drawer component
   */
  landingZipCode: any;
  /**
   * quoteType of navigation drawer component
   */
  quoteType: any;
  /**
   * existingLobs of navigation drawer component
   */
  existingLobs: any;
  /**
   * navigation Popup variables
   */
  actionDialogComponent = new ActionDialogComponent(this.saveAndFinishLaterDialog, this.libDataService);
  /**
   * Cancel left nav modal action of navigation drawer component
   */
  cancelLeftNavModalAction: any;

  encryptedData: string;

  isHQMSelected = false;
  /**
   * Switchs to strip
   */
  /**
   * Hide side panel by deault
   */
  showSidePanel = false;
  vehicleEdited = false;
  driverEdited = false;
  isVehicleOrDriverEdited = false;
  additionalPageStatus = null;
  isSignalBannerDiscountImpl = false;
  isConnectedCarImpl = false;
  isConnectedCarEligible = false;
  isFXImpl = false;
  isGWImpl = false;
  isBWQuote = false;
  /** US499784 : Digital Monetization Full Package Full Premium changes */
  isFullPackageFullPremiumImpl = false;
  secondRateCallStatus = false;
  connectedCarRequestObject = {} as ConnectedCarRequest;
  /**
   * Rideshare state rule model of add driver component
   */
  rideshareStateRuleModel: RideShareStateRuleModel;
  /**
   * Driver assignment state rule model of add driver component
   */
  driverassignmentStateRuleModel: DriverAssignmentStateRuleModel;
  totalVehicleCount = 0;
  totalDriverCount = 0;
  isHomeThankYou = false;
  saveExitMargin = false;
  /**
   * Assign vehicle driver state rule model of navigation drawer component
   */
  assignVehicleDriverStateRuleModel: AssignVehicleDriverStateRuleModel;
  /**
   * No of in exp drv of navigation drawer component
   */
  noOfInExpDrv = 0;
  /**
   * Determines whether exp drv in
   */
  inExpDrv: boolean;

  routerSubscription: Subscription;
  currentUrl: string;
  public switchToStrip() {
    this.showAsStrip = true;
  }

  /**
   * Creates an instance of navigation drawer component.
   * @param navigationDrawerService NavigationDrawerService
   * @param router Router
   * @param appStore Store<AppStates.AppState>
   * @param appStoreService MatIconModule
   * @param matIcons MatIconModule
   * @param saveAndFinishLaterDialog MatDialog
   * @param libDataService NavigationHelperService
   * @param navHelper NavigationHelperService
   * @param route ActivatedRoute
   */
  constructor(
    private navigationDrawerService: NavigationDrawerService,
    private router: Router,
    private appStore: Store<AppStates.AppState>,
    private appStoreService: AppStoreService,
    private driverStoreService: DriversStoreService,
    private matIcons: MatIconModule,
    private saveAndFinishLaterDialog: MatDialog,
    private libDataService: LibraryDataService,
    private navHelper: NavigationHelperService,
    private spinnerService: SpinnerService,
    private route: ActivatedRoute, private stateRuleModel: StateRuleModelService,
    private legacyRedirectionService: LegacyRedirectionService,
    private autoStoreService: AutoStoreService
  ) {
    navigationDrawerService.showHideEvent.subscribe(
      (sidePanelEvent) => {
        if (sidePanelEvent === 'expandSideStrip') {
          this.showAsStrip = false;
          this.showAsPanel = true;
          this.sidePanelClass = 'sidepanel-container';
        } else if (sidePanelEvent === 'collapseSidePanel') {
          this.showAsStrip = true;
          this.showAsPanel = false;
          this.sidePanelClass = 'sidepanel-container-strip ';
        } else if (sidePanelEvent === 'toggleSidePanelStrip') {

          if (this.showAsStrip === true) {
            this.showAsStrip = false;
            this.showAsPanel = true;
            this.sidePanelClass = 'sidepanel-container ';
          } else {
            this.showAsStrip = true;
            this.showAsPanel = false;
            this.sidePanelClass = 'sidepanel-container-strip ';
          }
        } else if (sidePanelEvent === 'showQuoteNumber') {
          this.enableQuoteNumber = true;
        } else if (sidePanelEvent === 'showSidePanel') {
          this.showSidePanel = true;
        } else if (sidePanelEvent === 'showSkeletalLoaderPanel') {
          this.showSkeletalLoaderPanel = true;
        } else if (sidePanelEvent === 'hideSkeletalLoaderPanel') {
          this.showSkeletalLoaderPanel = false;
        }
      }
    );

    navigationDrawerService.stepStatusChangeEvent.subscribe(
      (newStepNumber) => {
        this.stepsCompleted = newStepNumber;
        this.currentStep = newStepNumber;
        if (this.selectedLob === 'auto' || this.selectedLob === 'A') {
          if (this.currentStep > 1 && this.currentStep < 5) {
            this.saveExitMargin = true;
          } else {
            this.saveExitMargin = false;
          }
        }
      }
    );
  }
  ngAfterViewInit() {
    if (document.getElementById('DigiCertClickID_Z9bZfwjLSeal')) {
      if (document.getElementById('DigiCertClickID_Z9bZfwjLSeal').
        getElementsByTagName('img')[0]) {
        document.getElementById('DigiCertClickID_Z9bZfwjLSeal').
          getElementsByTagName('img')[0].alt = 'Digicert Secure Trusted Click to Verify opens in a new window';
      }
    }
  }

  ngOnInit() {
    this.appStoreService.appData$.subscribe(data => {
      if (data) {
        this.quoteNumber = data.quoteNumber;
        this.homeBoundIndicator = data.homeBoundIndicator;
        this.lifeBoundIndicator = data.lifeBoundIndicator;
        // this.quotedLob = 'HGST'; // data.quotedLobs;
        this.quotedLob = data.quotedLobs;
        this.existingLobs = data.customerData && data.customerData.existingLobs ? data.customerData.existingLobs : '';
        this.landingStateCode = data.landingStateCode;
        this.landingZipCode = data.landingZipCode;
        this.quoteType = data.quoteType;
        this.appStoreService.getRedirectionFlow$.subscribe(redirectionFlow => {
          if (redirectionFlow && this.quotedLob) {
            this.splitQuotedLOB();
            if (this.quotedLob !== 'A' && this.quotedLob !== '') {
              this.crossSellRedirection = true;
            }
            if ((this.quotedLob.indexOf('H') !== -1 || this.quotedLob.indexOf('R') !== -1 || this.quotedLob.indexOf('C') !== -1)
             && this.landingStateCode && this.landingStateCode === 'FL') {
              this.notClickableHomeIcon = true;
              if (this.displayLOBList.length === 1 && this.notClickableHomeIcon && this.crossSellRedirection) {
                this.crossSellRedirection = false;
              }
            }
            if (this.displayLOBList && this.displayLOBList.length === 0) {
              this.crossSellRedirection = false;
            }
          }
        });
      }
    });
    /**
     * Enable rateSummaryRedirection of navigation drawer component if params.Flow = 'PI'
     */
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
       this.currentUrl = event.url;
       if ( this.currentUrl.indexOf('auto/ratesummary') > -1) {
        this.rateSummaryRedirection = true;
      } else {
        this.rateSummaryRedirection = false;
      }
      }

    });
    // this.splitQuotedLOB();

    // this.switchToStrip();
    this.route.queryParams.subscribe(params => {
      if (params.LOB) {
        this.selectedLob = params.LOB;
        // US275214: Fix for active page title getting as empty
        this.activePageTitle = this.navHelper.getNavTitle(this.selectedLob);
      }
    });
    this.appStoreService.getNavigationData$.subscribe(data => {
      if (data) {
        if (data.home) {
          if (data.home.yourInfo === 'Completed' && !data.auto.yourInfo) {
             this.isHomeThankYou = true;
          }
          if (data.auto.yourInfo) {
            this.isHomeThankYou = false;
          }
        } else {
          this.isHomeThankYou = false;
        }
        this.appStoreService.getLob$.subscribe((lobInStore: any) => {
          this.selectedLob = lobInStore;
          if (this.selectedLob) {
            this.activePageTitle = this.navHelper.getNavTitle(this.selectedLob);
            this.navigationLinks = this.navHelper.getClientNavigationLinks(this.selectedLob, data);
          }
        });
      }
    });

    this.ccpaUrl = ServiceConstants.CCPA_URL;

    /** Subscription declared to update the total vehicle count */
    this.appStoreService.getTotalVehicleCount$.subscribe(totalVehicleCount => {
      this.totalVehicleCount = totalVehicleCount;
    });
    /** Subscription declared to update the total driver count */
    this.appStoreService.getTotalDriverCount$.subscribe(totalDriverCount => {
      this.totalDriverCount = totalDriverCount;
    });

    /** Subscription for projectstatus code */
    this.subscriptions.push(this.appStoreService.getProjectCodeStatus$.subscribe(projectCodeStatus => {
      if (projectCodeStatus) {
        this.isFXImpl = projectCodeStatus[`isFlexImpl`];
        this.isConnectedCarImpl = projectCodeStatus[`isConnectedCarImpl`];
        this.isGWImpl = projectCodeStatus[`isGWImpl`];
        this.isFullPackageFullPremiumImpl = projectCodeStatus[`isFullPackageFullPremiumImpl`];
      }
    }));
    /** subscription for Connected Car Eligible Status */
    this.subscriptions.push(this.appStoreService.getConnectedCarStatus$.subscribe((connectedCarEligibleStatus: boolean) => {
      this.isConnectedCarEligible = connectedCarEligibleStatus;
    }));
    this.subscriptions.push(this.appStoreService.getVehicleDriverModuleEditedStatus$.subscribe((data: boolean) => {
      this.isVehicleOrDriverEdited = data;
    }));
    // US153518: EE MVP (NC) - Auto product Driver assignment screen requirements
    this.driverStoreService.getDriverAssignmentData$.subscribe(data => {
      const driverList = JSON.parse(JSON.stringify(data));
      this.noOfInExpDrv = 0;
      this.inExpDrv = false;
      driverList.forEach((driveritem: any, index) => {
        if (driveritem.inExpDrv) {
          this.inExpDrv = true;
          this.noOfInExpDrv = this.noOfInExpDrv + 1;
        }
      });
    });
  }


  navigate(url: any, status: string, previousStepStatus: string, index: any) {
    // clearing the flow when clicked on the navigation.
    this.appStoreService.updateRedirectionFlow({ flowDataForQuote: null });
    this.rideshareStateRuleModel = this.stateRuleModel.getRideshareStateRule(this.landingStateCode);
    this.driverassignmentStateRuleModel = this.stateRuleModel.getDriverAssignmentStateRule(this.landingStateCode);
    // US153518: EE MVP (NC) - Auto product Driver assignment screen requirements
    this.assignVehicleDriverStateRuleModel = this.stateRuleModel.getAssignVehicleDriverStateRule(this.landingStateCode);
    const currentUrl = this.router.url ? this.router.url.replace('/', '') : '';
    // US177580 - Back button disable for PI flow
    this.appStoreService.updateAppControlData({ previousRoute: [currentUrl , currentUrl], displayQuotePremium: false });

    if ((status !== ModuleProgressStatus.NOT_STARTED
      /** DE11188 :
       * When retrieving a business quote and naviagting to confirmation page getting  error 404 in the background
       * and page is clocking forever Navigation to Business confirmation page should not happen directly
       */
      || previousStepStatus === ModuleProgressStatus.COMPLETED) && url.indexOf('business/confirmation') === -1) {
      if (this.checkDriverScreenValidated()) {
        if (index !== this.currentStep || currentUrl !== url[0]) {
          const editedSubcriptio: Subscription = this.appStoreService.getFormEditedStatus$.subscribe(data => {
            this.edited = data;
          });
          editedSubcriptio.unsubscribe();
          const editedVehicle: Subscription = this.appStoreService.getVehicleModuleEditedStatus$.subscribe(data => {
            this.vehicleEdited = data;
          });
          editedVehicle.unsubscribe();
          const editedDriver: Subscription = this.appStoreService.getDriverModuleEditedStatus$.subscribe(data => {
            this.driverEdited = data;
          });
          editedDriver.unsubscribe();

          const lastVisitedPageSub: Subscription = this.appStoreService.getAdditionalPageStatus$.subscribe(data => {
            this.additionalPageStatus = data;
          });
          lastVisitedPageSub.unsubscribe();
          this.isSignalBannerDiscountImpl = this.stateRuleModel.getSignalBannerStateRule(this.landingStateCode).isSignalBannerDiscountImpl;
          /** subscription for brightlined status */
          this.subscriptions.push(this.autoStoreService.getBrightLinedData$.subscribe((data: any) => {
            this.isBWQuote = (data && data.autoCompanyCode && data.autoCompanyCode === 'B');
          }));
          /** for checking whether the user skipped the BWPopUp using browser back button */
          let bwPopUpSkipped = false;
          this.appStoreService.getBwPopUpSkipped$.subscribe(data => { bwPopUpSkipped = data; }).unsubscribe();
          /** For checking second perform rate status, when try to navigate to other pages from quote page */
          this.subscriptions.push(this.appStoreService.getDefaultRateQuoteCalled$.subscribe(rateCallStatus => {
            this.secondRateCallStatus = rateCallStatus === RateQuoteProgressStatus.COMPLETED;
          }));
          if (this.isFullPackageFullPremiumImpl) {
            this.secondRateCallStatus = true;
          }
          /* don't let the user to enter quote page via navigation drawer when the value of bwPopUpSkipped = true */
          if (url[0] !== 'auto/quote' || (url[0] === 'auto/quote' && !bwPopUpSkipped)) {
            /** To block navigation from quote till second rate is competed */
            if (this.currentStep === NavigationStepsAutoEnum.Quote && !this.secondRateCallStatus) {
              return 0;
            }
            if (this.edited) {
              if ((url[0] === 'auto/discounts' || url[0] === 'auto/quote') && this.currentStep !== NavigationStepsAutoEnum.Quote) {
                /** US418753: Connected Car changes for quote Modification */
                this.invokeConnectedCarService();
                console.log('landingstatecode from navigation drawer ' + this.landingStateCode);
                if (this.driverEdited || this.vehicleEdited) {
                  if (this.driverEdited && this.additionalPageStatus !== ModuleProgressStatus.COMPLETED) {
                    this.router.navigate(['/auto/drivers/additionaldetails']);
                  } else if (this.driverassignmentStateRuleModel.IsDriverAssignment &&
                    (this.totalDriverCount > 1 || this.totalVehicleCount > 1) ||
                    (this.assignVehicleDriverStateRuleModel.isAssignVehicleDriver && this.inExpDrv &&
                      !(this.noOfInExpDrv === 1 && this.totalVehicleCount === 1))) {
                    this.router.navigate(['/auto/drivers/driverassignment']);
                 } else if (url[0] === 'auto/discounts' && this.isSignalBannerDiscountImpl && !this.isBWQuote) {
                    this.spinnerService.showLoader();
                    this.checkIfConnectedCarEligible(url);
                 } else {
                    this.spinnerService.showLoader();
                    this.router.navigate(url);
                  }
                } else {
                  this.spinnerService.showLoader();
                  this.router.navigate(url);
                }
              } else {

                this.actionDialogComponent.openDialog({
                  modalHeader: '',
                  modalBody: 'You have made changes to this page. Do you want to discard your changes?',
                  confirmButtonText: 'YES, NAVIGATE AWAY',
                  cancelButtonText: 'NO, CONTINUE'
                });
                this.subscriptions.push(this.libDataService.dialogActionPerformed$.subscribe(
                  action => {
                    this.cancelLeftNavModalAction = action;
                    if (this.cancelLeftNavModalAction === 'Accept') {
                      this.saveAndFinishLaterDialog.closeAll();
                      this.appStoreService.updateFormEdited(false);
                      this.router.navigate(url);
                    } else {
                      this.actionDialogComponent.closeDialog({
                        modalHeader: '',
                        modalBody: 'You have made changes to this page. Do you want to discard your changes?',
                        confirmButtonText: 'YES, NAVIGATE AWAY',
                        cancelButtonText: 'NO, CONTINUE'
                      });
                    }
                  }));
              }

            } else {
              if (url[0] === 'auto/discounts' || url[0] === 'auto/quote') {
                /** US418753: Connected Car changes for quote Modification */
                this.invokeConnectedCarService();
                if (this.driverEdited || this.vehicleEdited) {
                  if (this.driverEdited && this.additionalPageStatus !== ModuleProgressStatus.COMPLETED) {
                    this.router.navigate(['/auto/drivers/additionaldetails']);
                  } else if (this.driverassignmentStateRuleModel.IsDriverAssignment &&
                    (this.totalDriverCount > 1 || this.totalVehicleCount > 1) ||
                    (this.assignVehicleDriverStateRuleModel.isAssignVehicleDriver && this.inExpDrv &&
                      !(this.noOfInExpDrv === 1 && this.totalVehicleCount === 1))) {
                    this.router.navigate(['/auto/drivers/driverassignment']);
                  } else if (url[0] === 'auto/discounts' && this.isSignalBannerDiscountImpl && !this.isBWQuote) {
                    this.spinnerService.showLoader();
                    this.checkIfConnectedCarEligible(url);
                 } else {
                    this.spinnerService.showLoader();
                    this.router.navigate(url);
                  }
                } else if (url[0] === 'auto/discounts' && this.isSignalBannerDiscountImpl && !this.isBWQuote) {
                  this.checkIfConnectedCarEligible(url);
               } else {
                  this.spinnerService.showLoader();
                  this.router.navigate(url);
                }
              } else {
                this.spinnerService.showLoader();
                this.router.navigate(url);
              }
            }
          }
        }
      }

    }
  }
  getStatusClass(stepNo) {

    if (stepNo < this.stepsCompleted) {
      return 'nav-link-item-completed';
    } else if (stepNo === this.stepsCompleted) {
      return 'nav-link-item-active';
    } // else {
    // }
  }

  /**
   * Saves and finish later popup to get emailAddress
   */
  saveAndFinishLater() {
    this.saveAndFinishDialog();
  }

  /**
   * Invoke Connected car service on submit
   */
  invokeConnectedCarService(): void {
    if (this.isVehicleOrDriverEdited && this.isConnectedCarImpl && !this.isBWQuote) {
      const connectedCarRequestObject = {} as ConnectedCarRequest;
      connectedCarRequestObject.quoteNumber = this.quoteNumber;
      connectedCarRequestObject.gWImplemented = this.isGWImpl;
      connectedCarRequestObject.landingStateCode = this.landingStateCode;
      this.appStoreService.vehicleOrDriverChanged(false);
      this.autoStoreService.invokeConnectedCar(connectedCarRequestObject);
    }
  }

  checkIfConnectedCarEligible(url: string[]): void {
    if (this.isConnectedCarImpl && this.isConnectedCarEligible) {
      this.spinnerService.showLoader();
      this.router.navigate(url);
    } else {
      /** DE146373 - Resolved the page keep on loading issue */
      if (this.currentUrl !== '/auto/signal-discounts') {
        this.spinnerService.showLoader();
        this.router.navigate(['/auto/signal-discounts']);
      }
    }
  }

  /**
   * Saves and finish dialog
   */
  saveAndFinishDialog() {
    return this.saveAndFinishLaterDialog.open(SaveAndFinishComponent,
      {
        panelClass: 'save-and-finish-dialog',
        data: { dialogTitle: 'Save and Finish Later' },
        disableClose: true
      });
  }

  populateHqmCrossSellParams() {
    this.legacyRedirectionService.redirectToHQM(this.encryptedData, 'RS');
  }

  checkDriverScreenValidated() {
    if (this.stepsCompleted === 3) {
      let contrlData;
      this.appStoreService.appControlData$.subscribe((data) => contrlData = data).unsubscribe();
      if (contrlData.isDriverScreenValidated) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }

  /**
   * US48479 : Your Info Page - CCPA (Placement of Personal Information Use link)
   */
  openPersonalInformationLink() {
    // Open Link in a new Tab
    window.open(ServiceConstants.CCPA_URL, '_blank');
  }

  /**
   * Function to get the list of Lobs to be displayed in Quote page
   */
  splitQuotedLOB() {
    const list = [];
    if (this.lobData && this.lobData.length > 0 && this.quotedLob !== null) {
      for (const i of this.lobData) {
        const n = this.quotedLob.search(i.lobCode);
        if (n >= 0 && !((this.homeBoundIndicator && 'home-quote' === i.lobClass) ||
          (this.lifeBoundIndicator && 'vt-quote' === i.lobClass)) && !(this.existingLobs !== '' && this.existingLobs.includes(i.lobCode))) {
            if (!this.checkHomeLobPresent(this.existingLobs, i.lobCode)) {
              list.push(i);
            }
        }
      }
    }
    this.displayLOBList = list;
  }

  /**
   * Check if HomeLob Present
   * @param lobsArray lobArray
   * @returns  selected LOB
   */
  checkHomeLobPresent(existingLobs, lob) {
    const homeLOBs = ['H', 'R', 'C'];
    let homeLOB = false;
    if ((existingLobs.indexOf('H') !== -1 || existingLobs.indexOf('C') !== -1 )  && homeLOBs.includes(lob)) {
      homeLOB = true;
    }
    return homeLOB;
  }

  callEncryptCrossSellData() {
    this.appStoreService.getEncryptedCrossSellData(this.quoteNumber);
    this.subscriptions.push(this.appStoreService.getCrossSellEncryptedData$.subscribe((encryptedData: any) => {
      this.encryptedData = encryptedData;
      if (this.isHQMSelected && null != encryptedData) {
        this.populateHqmCrossSellParams();
      }
  }));
  }
  /**
   * Function to redirect to legacy
   */
  navigateToLegacy(lob) {
    if ((lob === 'Home' || lob === 'H') && this.stateRuleModel.getQuoteModuleStateRule(this.landingStateCode).isHQMEnabled) {
      this.callEncryptCrossSellData();
      this.isHQMSelected = true;
    } else {
      const parameterCollection = [];
      const params = {};
      params[`name`] = 'flow';
      params[`value`] = 'CS';
      const params1 = {};
      params1[`name`] = 'crossselllobs';
      params1[`value`] = lob;
      const params2 = {};
      params2[`name`] = 'zip';
      params2[`value`] = this.landingZipCode;
      const params3 = {};
      params3[`name`] = 'quotedlobs';
      params3[`value`] = this.quoteType;
      const params4 = {};
      params4[`name`] = 'quotenumber';
      params4[`value`] = this.quoteNumber;
      parameterCollection.push(params);
      parameterCollection.push(params1);
      parameterCollection.push(params2);
      parameterCollection.push(params3);
      parameterCollection.push(params4);

      this.legacyRedirectionService.redirectToLegacy(parameterCollection);
    }
  }
}
