import { Component, OnInit, Input } from '@angular/core';
import { DataConstants } from '@ffq-app-shared/constants/data.constants';
import { StateRuleModelService } from '@ffq-app-shared/services';
import { AppStoreServices } from '@ffq-app-store';
import { DataService } from './../../../../core';
import { AutoStoreService } from '@ffq-app-auto/store/auto.store.service';
import { Router } from '@angular/router';
import { PrintData } from '@ffq-app-auto/quote/model/quote.model';
import { AutoCommonService } from '@ffq-app-auto/shared/services/auto.common.service';

@Component({
  selector: 'app-quote-disclaimer',
  templateUrl: './quote-disclaimer.component.html',
  styleUrls: ['./quote-disclaimer.component.scss']
})
export class QuoteDisclaimerComponent implements OnInit {
    @Input()
    isRentersImpl;
    @Input()
    landingStateCode;
    isSignalBannerDiscountImpl;
    isQuoteDisclaimerStatusForEE = false;
    quoteDisclaimerText = DataConstants.QuoteDisclaimerTexts;
    @Input()
    isPolicyPerkEligible;
    @Input()
    payPolicy: string;
    /** US332036: Adding disclaimer text for CA non good driver */
    @Input()
    nonGoodDriverImpl: boolean;

  disclaimerCommon: string;
  disclaimerLegal: string;
  disclaimerLegalBW: string;
  disclaimerRenters: string;
  disclaimerCA: string;
  /** US332036: CA non good driver billing restrictions disclaimer text */
  goodDriverDisclaimerCA: string;
  disclaimerSignal: string;
  quoteData: PrintData;
  isBWQuote = false;
  /** US301883: adding policy perk disclaimer from aem */
  disclaimerPolicyPerks: string;
  disclaimer1: string;
  disclaimer2: string;
  isPayInFull = false;
  disableSignalBanner = false;
  isConnectedCarEligible = false;
  isConnectedCarImpl = false;
  /** US499784 : Digital Monetization Full Package Full Premium changes */
  isFullPackageFullPremiumImpl: boolean;
  disclaimerFPCommon: string;

  constructor(
    private stateRuleModel: StateRuleModelService,
    private dataService: DataService,
    private appStoreService: AppStoreServices.AppStoreService,
    private autoStoreService: AutoStoreService,
    private autoCommonService: AutoCommonService,
    private router: Router) { }

  ngOnInit(): void {
    const quoteContent = this.appStoreService.findContent('quote');
    if (quoteContent && quoteContent.summary) {
      this.disclaimerCommon = this.dataService.getStaticString(quoteContent.summary, 'disclaimerText', 'QUOTE_DISCLAIMER_COMMON');
      this.disclaimerFPCommon = this.dataService.getStaticString(quoteContent.summary, 'disclaimerText', 'QUOTE_DISCLAIMER_FP_COMMON');
      this.disclaimerLegal = this.dataService.getStaticString(quoteContent.summary, 'disclaimerText', 'QUOTE_DISCLAIMER_LEGAL');
      this.disclaimerRenters = this.dataService.getStaticString(quoteContent.summary, 'disclaimerText', 'QUOTE_DISCLAIMER_RENTERS');
      this.disclaimerCA = this.dataService.getStaticString(quoteContent.summary, 'disclaimerText', 'QUOTE_DISCLAIMER_CA');
      /** US332036: CA non good driver billing restrictions disclaimer text */
      this.goodDriverDisclaimerCA = this.dataService.getStaticString(quoteContent.summary, 'disclaimerText', 'QUOTE_DISCLAIMER_GOODDRIVER');
      this.disclaimerSignal = this.dataService.getStaticString(quoteContent.summary, 'disclaimerText', 'QUOTE_DISCLAIMER_SIGNAL');
      this.disclaimerLegalBW = this.dataService.getStaticString(quoteContent.summary, 'disclaimerText', 'QUOTE_DISCLAIMER_LEGAL_BW');
      /** US301883: adding policy perk desclaimers from aem */
      this.disclaimerPolicyPerks = this.dataService.getStaticString(quoteContent.summary, 'disclaimerText', 'QUOTE_DISCLAIMER_POLICYPERKS');
      this.disclaimer1 = this.dataService.getStaticString(quoteContent.summary, 'disclaimerText', 'QUOTE_DISCLAIMER_NEW_CAR_REPLACEMENT');
      this.disclaimer2 = this.dataService.getStaticString(quoteContent.summary, 'disclaimerText', 'QUOTE_DISCLAIMER_ACCIDENT_FORGIVENESS');
    }
    /** US254852: Hide disclaimer in both quote page and print page if paypolicy is PayInfull. */
    this.isPayInFull = this.payPolicy === DataConstants.PAY_POLICY.payInfull;
    const stateRuleData = this.stateRuleModel.getQuoteModuleStateRule(this.landingStateCode);
    this.isQuoteDisclaimerStatusForEE = stateRuleData.isEETextDisclaimerTextStates;
    this.isSignalBannerDiscountImpl = this.stateRuleModel.getSignalBannerStateRule(this.landingStateCode).isSignalBannerDiscountImpl;
    this.isConnectedCarImpl = this.stateRuleModel.getProjectCodeStatus('isConnectedCarImpl');
    this.isFullPackageFullPremiumImpl = this.stateRuleModel.getProjectCodeStatus('isFullPackageFullPremiumImpl');
    if (this.router && this.router.url) {
    const currUrlArr = this.router.url.split('?');
    if (currUrlArr[0] === '/print') {
      const quoteNum = currUrlArr[1].split('=')[1];
      this.quoteData = JSON.parse(localStorage.getItem(quoteNum));
    }
  }
    this.autoStoreService.getDataForRateQuote$.subscribe((data: any) => {
    if (data && data.autoCompanyCode != null) {
      this.isBWQuote = data.autoCompanyCode === 'B';
      this.isPayInFull = data.payPolicy === DataConstants.PAY_POLICY.payInfull;
    } else if (this.quoteData) {
        this.isBWQuote = this.quoteData.isBwQuote ;
    }
    });
    /** subscription for Connected Car Eligible Status */
    this.appStoreService.getConnectedCarStatus$.subscribe((connectedCarEligibleStatus: boolean) => {
      this.isConnectedCarEligible = connectedCarEligibleStatus;
    });
    // US171530 - Auto Display Policy perk disclaimer for NC state
    if (this.landingStateCode === DataConstants.STATE_CODES.NORTH_CAROLINA) {
      this.overrideDisclaimers();
    }
    /** DE113531: Should not display signal banner when connected car is eligible */
    this.checkIfSignalBannerRequired();
  }

  /**
   * Sets policy perk disclaimers
   */
  overrideDisclaimers(): void {
    if (this.landingStateCode === DataConstants.STATE_CODES.NORTH_CAROLINA) {
      this.disclaimer1 = DataConstants.PolicyPerksDisclaimerTexts.NCDisclaimer1;
      this.disclaimer2 = DataConstants.PolicyPerksDisclaimerTexts.NCDisclaimer2;
    }
  }

  checkIfSignalBannerRequired(): void {
    this.disableSignalBanner = this.isConnectedCarEligible && this.isConnectedCarImpl;
  }

}
