import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { NavigationDrawerService } from '@ffq-app-shared/components';
import { Subscription } from 'rxjs';
import { AppStoreService } from '@ffq-app-store/app.store.service';
import { AgentData } from '@ffq-app-shared/model/common.data.model';
import { KnockOutService } from '../../services/knock-out.service';
import { PlatformLocation } from '@angular/common';
import { Router } from '@angular/router';
import { ServiceConstants } from '@ffq-app-shared/constants/service.constants';
import { Title } from '@angular/platform-browser';
import { SpinnerService } from '@ffq-lib/src/lib/spinner/spinner.service';
import { AutoStoreService } from '@ffq-app-auto/store/auto.store.service';
import { SegmentService } from '@ffq-app-shared/services/segment.service';
import { take } from 'rxjs/operators';
import { QuoteKnockedoutProps } from '@ffq-app-shared/model/segment.data.model';
import { DataConstants } from '@ffq-app-shared/constants';
import { ScheduleAgentService } from '@ffq-app-shared/services/schedule-agent.service';
import { MatDialog } from '@angular/material/dialog';
import { ScheduleAgentAppointmentComponent
  } from '@ffq-app-shared/components/schedule-agent-appointment/schedule-agent-appointment.component';
import { ControlData } from '@ffq-app-store/app.state';
declare const MediaAlphaExchange__load: (arg0: string) => void;

@Component({
  selector: 'app-knock-out',
  templateUrl: './knock-out.component.html',
  styleUrls: ['./knock-out.component.scss'],
})
export class KnockOutComponent implements OnInit, AfterViewInit {
  private subscriptions: Subscription[] = [];
  quoteNumber: any;
  knockoutMessage: any;
  monetizationMsg1: string;
  monetizationMsg2: string;
  knockoutMonetizationMessage: string[] = [];
  contactAgentMessage: any;
  agentAssigned = false;
  agentName: string;
  agentLocation: string;
  agentPhoneNumber: string;
  agentDetails = {} as AgentData;
  pageTitle = 'Your quote requires special attention';
  googleMaplocation: string;
  isEEImpl: boolean;
  restictAgentDetailsImpl = false;
  isBWDirectSalesImpl: boolean;
  isBrightLined: boolean;
  isBwPopupShown: boolean;
  isFlHouseAccount: boolean;
  isHouseAccount: boolean;
  ariaLabelDesktop: string;
  ariaLabelMobile: string;
  agentPhoneNumberCC: string;
  tele: string;
  teleMobile: string;
  mobileNo: string;
  desktopNo: string;
  sourcePhoneNumber: string;
  isEERideShare: boolean;
  /**
   * rideShareInd to check if the rideshare is selected or not
   */
  rideShareInd: boolean;
  lastPageVisited: any;
  knockoutCode: any;
  detailedKnockoutCode: any;
  detailedKnockoutMsg: any;
  lastStepVisited: any;
  sourceIndicator: string;
  quoteSourceIndicator: string;
  awsFlow = false;
  landingStateCode: string;
  zipCode: string;
  errorCode: string;
  isMonetizationRequired = false;
  autoCompanyCode: string;
  isStartQuoteKnockoutAWSFlow = false;
  scheduleAgentSubsciption: Subscription;
  @ViewChild('scheduleAgent') scheduleAgentElement: ElementRef;

  constructor(
    private navDrawer: NavigationDrawerService,
    private appStoreService: AppStoreService,
    private autoStoreService: AutoStoreService,
    private knockoutService: KnockOutService,
    private titleService: Title,
    private spinnerService: SpinnerService,
    private segmentService: SegmentService,
    private scheduleAgentService: ScheduleAgentService,
    private dialog: MatDialog
) {
  }

  ngOnInit() {
    this.setTitle(this.pageTitle);
    this.navDrawer.hideDrawer();
    this.navDrawer.hideEverything();
    this.subscriptions.push(
      this.appStoreService.appData$.subscribe((data) => {
        this.quoteNumber = data ? data.quoteNumber : null;
      })
    );

    this.knockoutService.knockoutMsg.subscribe((message) => {
      this.knockoutMessage = message;
    });

    this.appStoreService.getLastVisitedStep$.subscribe(data => {
      this.lastStepVisited = data;
    }).unsubscribe();

    this.subscriptions.push(this.appStoreService.getLandingSourceFromSrc$.subscribe(data => {
      this.sourceIndicator = data;
    }));

    this.subscriptions.push(this.appStoreService.sourceIndicatorData$.subscribe(data => {
      this.quoteSourceIndicator = data;
      this.awsFlow = this.quoteSourceIndicator && (this.quoteSourceIndicator === DataConstants.AWS_SOURCE_INDICATOR.sourceIndicator_AS
        || this.quoteSourceIndicator === DataConstants.AWS_SOURCE_INDICATOR.sourceIndicator_AP);
    }));

    /** F42587: Zip/State Quote knockout from Agent website */
    this.subscriptions.push(this.appStoreService.getAppControlData$.subscribe(ctrlData => {
      this.isStartQuoteKnockoutAWSFlow = ctrlData?.isStartQuoteErrorAWSKnockout;
      if (this.isStartQuoteKnockoutAWSFlow) {
        this.knockoutMessage = ctrlData?.knockoutMessage;
        this.knockoutCode = ctrlData?.knockoutCode;
      }
    }));

    this.subscriptions.push(this.appStoreService.appDataZip$.pipe(take(1)).subscribe((data: any) => {
      this.landingStateCode = data[`landingStateCode`];
    }));
    this.subscriptions.push(this.autoStoreService.getAutoErrors$.subscribe((response: any) => {
      /** To make sure the response has error information */
      if (response && Object.keys(response).length !== 0) {
        this.knockoutCode = response.knockOutCode;
        if (response.detailedKnockOutCode) {
          this.detailedKnockoutCode = response.detailedKnockOutCode;
        }
        if (response.detailedKnockoutMsg) {
          this.detailedKnockoutMsg = response.detailedKnockoutMsg;
        }
      }
    }));

    this.subscriptions.push(this.autoStoreService.getBrightLinedData$.subscribe((data: any) => {
      this.autoCompanyCode = data && data.autoCompanyCode ? data.autoCompanyCode : 'F';
    }));

    this.scheduleAgentSubsciption = this.scheduleAgentService.getConfmDialogCloseEvent().subscribe(() => {
      this.scheduleAgentElement.nativeElement.focus();
    });


    // US346971: Display Monetization form in MA brightline quote
    this.appStoreService.appError$.subscribe((errorObj: any) => {
      if (errorObj && Object.keys(errorObj).length !== 0) {
        this.errorCode = errorObj.errorInfo.errorCode;
        this.zipCode = errorObj.errorInfo.zipCode;
        if (errorObj.errorInfo.errorCode === 'EG005') {
          this.isStartQuoteKnockoutAWSFlow = true;
        }
      }
    });

    this.appStoreService.getPhoneNumberFromSrc$.subscribe(
      (phoneNumber: any) => {
        this.sourcePhoneNumber = phoneNumber;
      }
    );

    /** Subscription for projectstatus code */
    this.appStoreService.getProjectCodeStatus$.subscribe(
      (projectCodeStatus: any) => {
        this.isEEImpl = projectCodeStatus[`isEEImpl`];
        /** US471771: Remove agent phone number and references from inconvenience and knockout screen */
        this.restictAgentDetailsImpl = projectCodeStatus[`removeAgentDetailsImpl`];
        this.isBWDirectSalesImpl = projectCodeStatus[`isBWDirectSalesImpl`];
      }
    );
    this.autoStoreService.getDiscountData$
      .subscribe((discountDataResponse: any) => {
        this.rideShareInd =
          discountDataResponse &&
          discountDataResponse.rideShareInd &&
          discountDataResponse.rideShareInd === true;
      })
      .unsubscribe();

    this.autoStoreService.getDiscountData$.subscribe((discountDataResponse: any) => {
      this.rideShareInd = discountDataResponse && discountDataResponse.rideShareInd && discountDataResponse.rideShareInd === true;
    }).unsubscribe();

    this.autoStoreService.getBrightLinedData$.subscribe((data: any) => {
      this.isBrightLined =
        data && data.isBrightlIned ? data.isBrightlIned : this.isBrightLined;
      this.isBwPopupShown =
        data && data.isBwPopupShown ? data.isBwPopupShown : this.isBwPopupShown;
    });

    /** Subcription for auto control data */
    this.autoStoreService.getAutoControlData$
      .subscribe((autoCtrlData) => {
        this.isFlHouseAccount =
          autoCtrlData &&
          autoCtrlData.flHouseAccount &&
          autoCtrlData.flHouseAccount === true;
        this.isHouseAccount =
          autoCtrlData &&
          autoCtrlData.isHouseAccount &&
          autoCtrlData.isHouseAccount === true;
      })
      .unsubscribe();

    this.handleRefresh();
    this.subscriptions.push(
      this.appStoreService.appDataAgent$.subscribe((data: any) => {
        if (data.agent && (data.agent.agent || data.agent.agentInfoBean)) {
          this.agentDetails = data.agent.agent
            ? data.agent.agent
            : data.agent.agentInfoBean;
          if (this.agentDetails.reference === '1') {
            this.agentAssigned = true;
            if (this.agentDetails.firstName || this.agentDetails.lastName) {
              this.agentName =
                this.agentDetails.firstName + ' ' + this.agentDetails.lastName;
            }
            if (
              this.agentDetails.address.city ||
              this.agentDetails.address.state ||
              this.agentDetails.address.zip
            ) {
              this.agentLocation =
                this.agentDetails.address.city +
                ', ' +
                this.agentDetails.address.state +
                ' ' +
                this.agentDetails.address.zip;
            }
            if (
              this.agentDetails.communication.phoneNumber.phoneNumber &&
              this.agentDetails.communication.phoneNumber.phoneNumber !==
              '1-800-FARMERS'
            ) {
              this.agentPhoneNumber =
                this.agentDetails.communication.phoneNumber.phoneNumber.replace(
                  /(\d{3})(\d{3})(\d{4})/,
                  '$1-$2-$3'
                );
              this.agentPhoneNumberCC = '1-'.concat(
                this.agentDetails.communication.phoneNumber.phoneNumber
              );
            }
            // const googleLocquerry = ' 1901 1st St N APT 1206, Jacksonville Beach, FL 32250';
            const googleLocquerry =
              this.agentDetails.address.street + ', ' + this.agentLocation;
            this.googleMaplocation =
              'http://maps.google.com/?q=' + googleLocquerry;
          } else if (this.agentDetails.reference === 'D') {
            this.agentAssigned = false;
          } else {
            this.agentAssigned = false;
          }
        }
      })
    );

    this.determineContactAgentMessage();

    if (this.sourcePhoneNumber) {
      this.tele = this.teleMobile =
        'tel:' + this.sourcePhoneNumber.toString().replace(/-/g, '');
      this.ariaLabelDesktop = this.ariaLabelMobile =
        this.sourcePhoneNumber + ' link';
      this.desktopNo = this.mobileNo = this.sourcePhoneNumber;
    } else {
      if (this.isEEImpl) {
        this.ariaLabelDesktop = this.ariaLabelMobile = '1-888-587-2121 link';
        this.tele = this.teleMobile = 'tel:1-888-587-2121';
        this.desktopNo = this.mobileNo = '1-888-587-2121';
      } else {
        this.ariaLabelDesktop = '1-888-587-2121 link';
        this.ariaLabelMobile = '1-888-587-2121 link';
        this.tele = 'tel:18885872121';
        this.teleMobile = 'tel:18885872121';
        this.desktopNo = '1-888-587-2121';
        this.mobileNo = '1-888-587-2121';
      }
    }
    if (this.isEEImpl && this.rideShareInd === true) {
      this.isEERideShare = true;
    } else {
      this.isEERideShare = false;
    }

    // US346971: Display Monetization form in MA brightline quote in non aws flow
    if (this.landingStateCode === DataConstants.STATE_CODES.MASSACHUSETTS && this.isBrightLined && !this.awsFlow) {
      this.isMonetizationRequired = true;
      this.mediaAlphaPage();
      this.knockoutMonetizationMessage = DataConstants.KNOCKOUT_BRIGHTLINE_MA.msg.split(DataConstants.AGENT_DEFAULT_PHONE.number);
      this.knockoutMessage = this.knockoutMonetizationMessage[0] + this.desktopNo + this.knockoutMonetizationMessage[1] + this.quoteNumber;
      this.monetizationMsg1 = DataConstants.KNOCKOUT_BRIGHTLINE_MA.monetizationMsg1;
      this.monetizationMsg2 = DataConstants.KNOCKOUT_BRIGHTLINE_MA.monetizationMsg2;
    }

    // US346971: segment tracking for knockout
    this.callSegmentEvent();
    /** US471771: Remove agent phone number and references from inconvenience and knockout screen */
    this.checkIfRestrictAgentImpl();
  }

  determineContactAgentMessage() {
    if (this.isEEImpl) {
      this.contactAgentMessage =
        'You may also contact a representative yourself with the information below. Please reference your quote number:';
      if (this.rideShareInd ||
        this.knockoutMessage.includes('and discuss options for purchasing coverage')) {
        this.contactAgentMessage = 'Please reference your quote number:';
      }
    } else if (
      !this.agentAssigned &&
      (this.isBrightLined || this.isBwPopupShown) &&
      this.isBWDirectSalesImpl) {
      if (this.knockoutMessage.toLowerCase().includes('agent')) {
        this.knockoutMessage = this.knockoutMessage.replace('agent', 'representative');
      }
      this.contactAgentMessage =
        'You may also contact a representative yourself with the information below. Please reference your quote number:';
      /** US140374 -AJ gallagher integration - checking whether the quote is assigned for house account */
    } else if (this.isFlHouseAccount || this.isHouseAccount) {
      this.contactAgentMessage =
        'You may also contact Farmers directly. Please reference your quote number:';
    } else if (this.errorCode === 'EG005' || this.isStartQuoteKnockoutAWSFlow) {
      this.knockoutMessage = 'Online quotes are not available at this time. Please contact your agent for a quote.';
      this.appStoreService.appData$.subscribe((data) => {
        this.quoteNumber = '';
      });
    } else {
      this.contactAgentMessage =
        'You may also contact an agent yourself with the information below. Please reference your quote number:';
    }
  }

  private handleRefresh() {
    if (this.knockoutMessage) {
      // setting the knockoutMessage in session storage to handle refreh scenario
      sessionStorage.setItem('KnockoutMessage', this.knockoutMessage);
    } else {
      // getting knockoutMessage from session storage on refresh
      this.knockoutMessage = sessionStorage.getItem('KnockoutMessage')
        ? sessionStorage.getItem('KnockoutMessage')
        : '';
    }
  }

  toLanding() {
    window.location.replace('/');
  }

  findAnAgent() {
    window.location.href = ServiceConstants.FIND_AN_AGENT;
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  /**
   * US346971: Calls segment track for knockout
   */
  callSegmentEvent(): void {
    const knockoutInfo: QuoteKnockedoutProps = {
      knockout_msg: this.knockoutCode === 'KNOCKOUT_AGENT' ? 'BW Knockout' : this.knockoutMessage,
      knockout_code: this.knockoutCode,
      point_of_knockout: this.lastStepVisited,
      company_code: this.autoCompanyCode,
    };

    if (this.knockoutCode === 'EK21' && this.detailedKnockoutCode) {
      knockoutInfo.knockout_code = this.detailedKnockoutCode;
    }

    if (this.knockoutCode === 'EK21' && this.detailedKnockoutMsg) {
      knockoutInfo.knockout_msg = this.detailedKnockoutMsg;
    }
    knockoutInfo.isMonetization = this.isMonetizationRequired ? 'true' : 'false';

    this.segmentService.trackQuoteKnockedout(knockoutInfo);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.spinnerService.hideLoader();
    });
    window.history.pushState({}, '', '/fastquote/auto/knockout');
  }

  /**
   * US346971: Display Monetization form in MA brightline quote
   * media alpha page load
   */
  mediaAlphaPage(): void {
    (window as any).MediaAlphaExchange = {
      type: 'ad_unit',
      placement_id: DataConstants.PLACEMENT_ID.MAmonetization,
      version: '17',
      data: {
        zip: this.zipCode
      }
    };
    MediaAlphaExchange__load('Content');
  }
  /** US471771: Remove agent phone number and references from inconvenience and knockout screen */
  checkIfRestrictAgentImpl(): void {
    this.restictAgentDetailsImpl = this.restictAgentDetailsImpl && this.autoCompanyCode !== 'B';
  }
  openApptModal(): void {
    this.dialog.open(ScheduleAgentAppointmentComponent, {
      panelClass: 'schedule-agent-dialog',
      maxWidth: '700px',
      id: 'appointmentModal',
      autoFocus: false,
      data: this.agentDetails,
      disableClose: true
    });
    this.segmentService.trackCtaClicked('Schedule Agent Appointment button clicked');
  }
}

