<form [formGroup]="saveAndFinishFormGroup">
    <mat-dialog-content [ngClass]="(isEmailViewRequired)? 'auto-module__save-and-finish-dialog':'auto-module__save-and-finish-dialog_email'"> 
        <div>
            <div role="heading" aria-level= "2" class = "auto-module__save-and-finish-header-text" x-ms-format-detection="none">
               <span tabindex="0"> {{saveAndFinishDialogTitle}} 
               {{this.quoteNumber}} </span>
            </div>     
            <div class = "auto-module__save-and-finish-line"></div>
            <div class = "auto-module__save-and-finish-dialog-content">
               {{saveAndFinishDialogContent}}
            </div>
            <div *ngIf="(isEmailViewRequired);">
                <div class = "auto-module__save-and-finish-input-text">
                    <lib-input-text  [params]="saveAndFinishParams.emailAddress"></lib-input-text>
                </div>
            </div>
            <div class = "auto-module__save-and-finish-line"></div>
        </div>
        <div class = "auto-module__save-and-finish-buttons">
            <mat-dialog-actions class = "dialog__info-dialog-button-holder" align="end">
                <button mat-button class="auto-module_dialog__info-dialog-button-close1" (click)= "continueWithQuote()" data-GTM="FFQ_Auto_savefinishmodal_Contquote_button">{{buttonText1}}</button>
            </mat-dialog-actions>
            <mat-dialog-actions class="dialog__info-dialog-button-holder" align="end">
                <button mat-button class="auto-module_dialog__info-dialog-button-close2" (click)= "saveAndExit(false)" data-GTM="FFQ_Auto_savefinishmodal_saveandexit_button">{{buttonText2}}</button>
            </mat-dialog-actions>
        </div>
    </mat-dialog-content>
</form>
