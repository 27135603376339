import { getEnvConfig } from "./env.config";

export const environment = {
  production: true,
  endPoint: window[`endPoint`] ? window[`endPoint`] : 'https://ffq-auto-redesign-api-dev.farmers.com/',
  stage_endPoint: window[`stage_endPoint`] ? window[`stage_endPoint`] : 'https://ffq-auto-redesign-api-dev.farmers.com/',
  one_ui_url: window[`one_ui_url`] ? window[`one_ui_url`] : 'https://farmersffqreg.farmers.com/quote/',
  hqm_end_point: window[`hqm_end_point`] ? window[`hqm_end_point`] : 'https://ffqhomeuiqa.farmers.com/',
  ...getEnvConfig(window.location.hostname),
  port: '9000',
  legacy_endPoint: window[`legacy_endPoint`] ? window[`legacy_endPoint`] : 'https://csstq.farmers.com/AITquote/',
  legacy_homeEndPoint: window[`legacy_homeEndPoint`] ? window[`legacy_homeEndPoint`] : 'https://cssta.farmers.com/okta-login/',
  chatbot_popupTime: window[`chatbot_popupTime`] ? window[`chatbot_popupTime`] : '2000|4000|6000',
  chatbot_url: window[`chatbot_url`] ? window[`chatbot_url`] : 'https://ffqrtqbotui.herokuapp.com/',
  isProduction: 'N',
  dynatrace_script_url: window[`dynatrace_script_url`] ? window[`dynatrace_script_url`] : 'https://js-cdn.dynatrace.com/jstag/184ec339076/bf03584esi/66be9a796e07c791_complete.js',
  // tslint:disable-next-line: max-line-length
  existCustLogin_url: window[`existingCustLogin_url`] ? window[`existingCustLogin_url`] : 'https://www.farmers.com/css/login?redirect=quote&source=ffq',
  // tslint:disable-next-line: max-line-length
  existCustRegister_url: window[`existingCustRegister_url`] ? window[`existingCustRegister_url`] : 'https://www.farmers.com/css/registration?redirect=quote&source=ffq',
  loginRegister_url: window[`loginRegister_url`] ? window[`loginRegister_url`] : 'https://www.farmers.com/css/login',
  farmersDirect_url: window[`farmersDirect_url`] ? window[`farmersDirect_url`] : '',
  farmersHawaii_url: 'https://www.farmershawaii.com/',
  CCPA_URL: window[`CCPA_url`] ? window[`CCPA_url`] : 'https://www.farmers.com/privacy-statement/?_',
  appKey: window[`appKey`] ? window[`appKey`] : 'AD-AAB-AAM-HRG',
  findAnAgent_url: window[`findAnAgent_url`] ? window[`findAnAgent_url`] : 'https://agents.farmers.com/',
  mockPageView: window[`mockPageView`] ? window[`mockPageView`] : 'N',
  isAccessControlEnabled: window[`isAccessControlEnabled`] ? window[`isAccessControlEnabled`] : 'Y',
  autoCompleteKey: window[`autoCompleteKey`] ? window[`autoCompleteKey`] : 'AIzaSyCkB8Dk9hr5UrS2dSYi2fdcrccn3fH7dkY',
  jsonServerMockUrl: 'http://localhost:4200/',
  /**
   * Gets Policy start date for PC states
   */
  // tslint:disable:no-string-literal
  policy_startdate_pc: window['policy_startdate_pc'] ? window['policy_startdate_pc'] : '60',
  /**
   * Gets Policy start date for PLA states
   */
  // tslint:disable:no-string-literal
  policy_startdate_pla: window['policy_startdate_pla'] ? window['policy_startdate_pla'] : '45',

  test_email_list: window['test_email_list'] ? window['test_email_list'] : 'autobindoptout@yopmail.com',
  test_lastName_list: window['test_lastName_list'] ? window['test_lastName_list'] : 'ffqtest',

  digicertScript_Tag: window['digicertScript_Tag'] ? window['digicertScript_Tag'] : 'TUDcrlsd',

  fwsCallOnlyPage: window['fwsCallOnlyPage'] ? window['fwsCallOnlyPage'] : 'https://www.farmers.com/landing/groupselect/call-for-quote/',

  srcMetlifeIndicator: window['srcMetlifeIndicator'] ? window['srcMetlifeIndicator'] : ['AS', 'AP'],

  farmersChoiceUrl: window['farmersChoiceUrl'] ? window['farmersChoiceUrl'] : 'https://farmersinsurancechoice.com/',
  polling: window['polling'] ? window['polling'] : '3|3|2|2|2|2',
  isMigratedLandingPageEnabled: window[`isMigratedLandingPageEnabled`] ? window[`isMigratedLandingPageEnabled`] : 'N',
  fgiaUrl: window[`fgiaUrl`] ? window[`fgiaUrl`] : 'https://quote.farmersgeneralinsuranceagency.com/quote?zipcode=',
  ffqRequestBodyParams: window[`ffqRequestBodyParams`] ? window[`ffqRequestBodyParams`] : true
};

