<div *ngIf="isDJMAutoImpl && !isBWQuote &&
(discountCounter > 0 || headingSubText || additionalInfoHeadingText)" class="auto-module__discount-journey-card">
<div class="auto-module__discount-journey-text" *ngIf="currentStep === 1">
    {{headingSubText1}} {{headingSubText2}}
</div>
   <div [ngClass]="(currentStep === 1)? 'auto-module__discount-journey-block-single':'auto-module__discount-journey-block'"
    *ngIf="!hideDiscountJourneyBlock">
        <img src="../../../../../assets/images/clock.png" class="auto-module__discount-journey-image-clock" alt="">
        <span class="auto-module__discount-journey-sub-text">{{headingSubText}}</span>
    </div>
    <div *ngIf="currentStep === 1 || additionalInfoHeadingText"
    [ngClass]="(currentStep === 1 )? 'auto-module__discount-journey-block-single':'auto-module__discount-journey-block'">
        <img src="../../../../../assets/images/lock.png" class="auto-module__discount-journey-image" alt="">
        <span class="auto-module__discount-journey-sub-text">{{additionalInfoHeadingText}}We will never sell your information.</span>
    </div>
    <div
    [ngClass]="(!hideDiscountJourneyBlock)? 'auto-module__discount-journey-block-single-counter':
    'auto-module__discount-journey-block-counter'"
    *ngIf="currentStep > 1 && !additionalInfoHeadingText && discountCounter > 0" (click)="showPotentialDiscounts()" (keydown.enter)="showPotentialDiscounts()" (keyup.Space)="showPotentialDiscounts()">
        <img src="../../../../../assets/images/piggyBank.png" class="auto-module__discount-journey-piggy" alt="">
        <p class="auto-module__discount-journey-sub-text">
            <strong class="auto-module__discount-journey-counter">{{discountCounter}}</strong>
            <span class="auto-module__discount-journey-discount-text">{{discountCounterText}}</span>
        <span (click)="viewDiscounts()" (keydown.enter)="viewDiscounts()" (keyup.Space)="viewDiscounts()" class="view-discount" *ngIf="currentStep === 5">View Discounts</span></p>
        <a *ngIf="currentStep !== 5" tabindex="0" class="info-icon" role="button"
        [attr.aria-label]="isLargeScreen? 'Potential Savings/discounts info icon opens a modal dialog' : 'Potential Savings/discounts info icon'"></a>
    </div>
    <div class= "auto-module__discount-journey-block-single-counter"
    *ngIf= "additionalInfoHeadingText && discountCounter > 0" (click)="showPotentialDiscounts()" (keydown.enter)="showPotentialDiscounts()" (keyup.Space)="showPotentialDiscounts()">
        <img src="../../../../../assets/images/piggyBank.png" class="auto-module__discount-journey-piggy" alt="">
        <p class="auto-module__discount-journey-sub-text">
            <strong class="auto-module__discount-journey-counter">{{discountCounter}}</strong>
            <span class="auto-module__discount-journey-discount-text">{{discountCounterText}}</span>
        <a tabindex="0" class="info-icon" role="button"
        [attr.aria-label]="isLargeScreen? 'Potential Savings/discounts info icon opens a modal dialog' : 'Potential Savings/discounts info icon'"></a>
    </div>
</div>

