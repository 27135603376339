import { Component, OnInit, Input, ViewChild, TemplateRef, ViewContainerRef, DoCheck } from '@angular/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'lib-inline-spinner',
  templateUrl: './inline-spinner.component.html',
  styleUrls: ['./inline-spinner.component.css']
})
export class InlineSpinnerComponent implements OnInit, DoCheck {
  @Input() color?: ThemePalette;
  @Input() diameter ? = 40;
  @Input() value?: number = 25;



  constructor(private vcRef: ViewContainerRef) { }

  ngOnInit() {

  }

  ngDoCheck() {

  }

}
