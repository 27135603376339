import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { InsuredModalValidation } from '@ffq-app-auto/your-info/model/insured-modal.validation';
import { SegmentService } from '@ffq-app-shared/services/segment.service';

@Component({
  selector: 'app-insured-modal',
  templateUrl: './insured-modal.component.html',
  styleUrls: ['./insured-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InsuredModalComponent implements OnInit {
  insuredModalFormGroup: FormGroup;
  insuredModalParams = new InsuredModalValidation();
  currentlyInsured: any;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private segmentService: SegmentService,
    private dialogRef: MatDialogRef<InsuredModalComponent>
  ) { }

  ngOnInit(): void {
    this.buildForm();
    for (const field of Object.keys(this.insuredModalFormGroup.controls)) {
      this.insuredModalParams[field][`formGroup`] = this.insuredModalFormGroup;
    }
  }

  buildForm() {
    this.insuredModalFormGroup = this.fb.group({
      hasAutoInsurance: ['', this.insuredModalParams.hasAutoInsurance.validators],
    });
  }

  insuredContinue(): void {
    if (!this.insuredModalFormGroup.valid) {
      this.insuredModalFormGroup.markAllAsTouched();
      this.insuredModalFormGroup.setErrors({required: true});
    } else {
    this.currentlyInsured = this.insuredModalFormGroup.get('hasAutoInsurance').value;
    if (this.currentlyInsured === 'Y') {
      this.segmentService.trackCtaClicked('CI PopUp - Yes Clicked');
      this.dialogRef.close('Y');
    } else if (this.currentlyInsured === 'N') {
      this.dialogRef.close('No');
      this.segmentService.trackCtaClicked('CI PopUp - No Clicked');
      }
    }
  }
}
