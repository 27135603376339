import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { NavigationDrawerService } from '@ffq-app-shared/components';
import { Subscription } from 'rxjs';
import { AppStoreService } from '@ffq-app-store/app.store.service';
import { ServiceConstants } from '@ffq-app-shared/constants/service.constants';
import { createUnparsedSourceFile } from 'typescript';
import { Title } from '@angular/platform-browser';
import { SpinnerService } from '@ffq-lib/src/lib/spinner/spinner.service';
import { WindowRef, ICustomWindow } from '@ffq-app-auto/shared/services/window.ref';
import { AutoStoreService } from '@ffq-app-auto/store/auto.store.service';
import { take } from 'rxjs/operators';
import { SegmentService } from '@ffq-app-shared/services/segment.service';
import { QuoteKnockedoutProps } from '@ffq-app-shared/model/segment.data.model';
import { AgentData } from '@ffq-app-shared/model/common.data.model';
import { DataConstants } from '@ffq-app-shared/constants';
declare const MediaAlphaExchange__load: (arg0: string) => void;

@Component({
  selector: 'app-iid-knockout',
  templateUrl: './iid-knockout.component.html',
  styleUrls: ['./iid-knockout.component.scss']
})
export class IidKnockoutComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  quoteNumber: any;
  isEEImpl = false;
  restictAgentDetailsImpl = false;
  ariaLabelDesktop: string;
  ariaLabelMobile: string;
  tele: string;
  teleMobile: string;
  mobileNo: string;
  desktopNo: string;
  sourcePhoneNumber: string;
  isAWSflow = false;
  displayAgentBlock = true;
  isMonetizationEligible = false;
  agentData: AgentData = null;

  /**
   * Error code of iid knockout component
   */
  errorCode: string;
  /**
   * Zip code of intermediate component
   */
  zipCode: string;
  /**
   * Page title requirement for ADA
   */
  pageTitle = 'We aren’t able to complete your Farmers Fast Quote at this time.';
  customWindow: ICustomWindow;
  lastStepVisited: any;
  landingStateCode: any;
  sourceIndicator: any;
  quoteSourceIndicator: string;
  showBadActorErrorMessage: boolean;

  constructor(
    private navDrawer: NavigationDrawerService,
    private appStoreService: AppStoreService,
    private titleService: Title,
    private spinnerService: SpinnerService,
    private windowRef: WindowRef,
    private autoStoreService: AutoStoreService,
    private segmentService: SegmentService) {}

  ngOnInit() {
    this.setTitle(this.pageTitle);
    this.navDrawer.hideDrawer();
    this.navDrawer.hideEverything();
    this.subscriptions.push(this.appStoreService.appData$.subscribe(data => {
      this.quoteNumber = data ? data.quoteNumber : null;
    }));


    /** Subscription for projectstatus code */
    this.appStoreService.getProjectCodeStatus$.subscribe((projectCodeStatus: any) => {
      this.isEEImpl = projectCodeStatus ? projectCodeStatus[`isEEImpl`] : null;
      /** US471771: Remove agent phone number and references from knockout screen */
      this.restictAgentDetailsImpl = projectCodeStatus ? projectCodeStatus[`removeAgentDetailsImpl`] : false;
      this.appStoreService.getPhoneNumberFromSrc$.subscribe((phoneNumber: any) => {
        this.sourcePhoneNumber = phoneNumber;
        this.setPhoneNumber();
      });

    });
    const mcq = window.matchMedia('(orientation: landscape)');
    if (mcq.matches) {
      document.querySelector('.iidknockout-page-container').scrollIntoView();
    }

    this.appStoreService.appError$.subscribe((errorObj: any) => {
      if (errorObj && Object.keys(errorObj).length !== 0) {
        this.errorCode = errorObj.errorInfo.errorCode;
        this.zipCode = errorObj.errorInfo.zipCode;
        /** US406295: Changes to show customized message in IID Knockout Screen */
        if (this.errorCode === DataConstants.ERROR_CODES.badActorErrorCode) {
          this.showBadActorErrorMessage = true;
        }
      }
    });

    /** US346955: Display the monetization form on the IID knockout screen */
    this.subscriptions.push(this.appStoreService.sourceIndicatorData$.subscribe(data => {
      this.quoteSourceIndicator = data;
      this.isAWSflow = this.quoteSourceIndicator && (this.quoteSourceIndicator === DataConstants.AWS_SOURCE_INDICATOR.sourceIndicator_AS
        || this.quoteSourceIndicator === DataConstants.AWS_SOURCE_INDICATOR.sourceIndicator_AP);
    }));

    /** US346955: Display the monetization form on the IID knockout screen */
    this.subscriptions.push(this.appStoreService.appDataAgent$.subscribe((data: any) => {
      this.agentData = data.agent.agent ? data.agent.agent : data.agent.agentInfoBean;
      if (!this.isAWSflow && (!this.agentData || (this.agentData && this.agentData.reference === 'D'))) {
        this.isMonetizationEligible = true;
      }
    }));

    this.displayAgentBlock = !this.isEEImpl && !this.isMonetizationEligible;
    if (this.isMonetizationEligible) {
      this.mediaAlphaPage();
    }

    // US346971: segment tracking for IID knockout
    this.callSegmentTrackForIIDKnockout();
  }

  /**
   * Calls segment track for iidknockout
   */
  callSegmentTrackForIIDKnockout(): void {
    this.appStoreService.getLastVisitedStep$.subscribe(data => {
      this.lastStepVisited = data;
    }).unsubscribe();
    let autoCompanyCode = '';
    this.subscriptions.push(this.autoStoreService.getBrightLinedData$.subscribe((data: any) => {
      autoCompanyCode = data && data.autoCompanyCode ? data.autoCompanyCode : '';
    }));
    this.subscriptions.push(this.appStoreService.appDataZip$.pipe(take(1)).subscribe((data: any) => {
      this.landingStateCode = data[`landingStateCode`];
    }));
    this.subscriptions.push(this.appStoreService.getLandingSourceFromSrc$.subscribe(data => {
      this.sourceIndicator = data;
    }));

    const knockoutInfo: QuoteKnockedoutProps = {
      knockout_msg: 'IID',
      knockout_code: 'IID Knockout',
      point_of_knockout: this.lastStepVisited,
      company_code: autoCompanyCode ? autoCompanyCode : 'F',
      isMonetization: this.isMonetizationEligible ? 'true' : 'false'
    };
    this.segmentService.trackQuoteKnockedout(knockoutInfo);
  }

  findAnAgent(): void {
    window.location.href = ServiceConstants.FIND_AN_AGENT;
  }
  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  public setPhoneNumber(): void {
    if (this.sourcePhoneNumber) {
      this.tele = this.teleMobile = this.isEEImpl ? 'tel:' + this.sourcePhoneNumber : 'tel:' + this.sourcePhoneNumber.replace(/-/g, '');
      this.ariaLabelDesktop = this.ariaLabelMobile = this.sourcePhoneNumber + ' link';
      this.desktopNo = this.mobileNo = this.sourcePhoneNumber;
    }
    if (!this.sourcePhoneNumber) {
      if (this.isEEImpl) {
        this.ariaLabelDesktop = '1-888-587-2121 link';
        this.ariaLabelMobile = '1-888-587-2121 link';
        this.tele = 'tel:1-888-587-2121';
        this.teleMobile = 'tel:1-888-587-2121';
        this.desktopNo = '1-888-587-2121';
        this.mobileNo = '1-888-587-2121';
      } else {
        this.ariaLabelDesktop = '1-888-587-2121 link';
        this.ariaLabelMobile = '1-888-587-2121 link';
        this.tele = 'tel:18885872121';
        this.teleMobile = 'tel:18885872121';
        this.desktopNo = '1-888-587-2121';
        this.mobileNo = '1-888-587-2121';
      }
    }
  }


  /**
   * media alpha page load
   */
  mediaAlphaPage(): void {
    (window as any).MediaAlphaExchange = {
      type: 'ad_unit',
      placement_id: DataConstants.PLACEMENT_ID.Iidknockout,
      version: '17',
      data: {
        zip: this.zipCode
      }
    };
    MediaAlphaExchange__load('Content');
  }


  ngAfterViewInit() {
    setTimeout(() => {
      this.spinnerService.hideLoader();
    });
    window.history.pushState({}, '', '/fastquote/iidquotereject');
  }

  /**
   * on destroy
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
