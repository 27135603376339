import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ChangeDetectorRef, ɵConsole, Pipe, PipeTransform } from '@angular/core';
import { FormInputParam } from '../form-input-params';
import { AbstractControl, FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { FormFieldEmitParams } from '../params/form-field-emit-params';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

// US300698: Added pipe for making dropdown results bold
@Pipe({ name: 'bold' })
export class BoldPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {
  }
  transform(text: string, searchValue: string): SafeHtml {
    if (text.toLocaleUpperCase().includes(searchValue.toLocaleUpperCase())) {
      const indexOfSearch = text.toLocaleUpperCase().indexOf(searchValue.toLocaleUpperCase());
      const pos = indexOfSearch + searchValue.length;
      if (text.substr(pos).length > 2) {
        const firstPart = text.substring(0, pos);
        const boldPart = text.substr(pos);
        return this.sanitizer.bypassSecurityTrustHtml(`${firstPart}<span style="font-weight: bold">${boldPart}</span>`);
      } else {
        const boldPart = text.substring(0, indexOfSearch);
        const secondPart = text.substr(indexOfSearch);
        return this.sanitizer.bypassSecurityTrustHtml(`<span style="font-weight: bold">${boldPart}</span>${secondPart}`);
      }
    } else {
      return text;
    }
  }
}

@Component({
  selector: 'lib-autocomplete-employer-dropdown',
  templateUrl: './autocomplete-employer-dropdown.component.html',
  styleUrls: ['./autocomplete-employer-dropdown.component.scss']
})
export class AutocompleteEmployerDropdownComponent implements OnInit {

  @Input() params: FormInputParam;
  @Input() newValue: any;
  @Input() isButtonClicked: boolean;
  @Output() employerCardValueSelected: EventEmitter<any> = new EventEmitter();
  @Output() employerParam: EventEmitter<any> = new EventEmitter();
  @Output() callingService: EventEmitter<any> = new EventEmitter();
  @Output() searchCompletedValue: EventEmitter<FormFieldEmitParams> = new EventEmitter();
  @Output() searchActivatedValue: EventEmitter<FormFieldEmitParams> = new EventEmitter();
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;
  myControl: AbstractControl;
  Object = Object;
  filteredOptions: string[];
  proxyValue: string;
  inputValue: string;
  isSelected: boolean;

  constructor(
    private changeDetector: ChangeDetectorRef) {
  }

  ngOnInit() {
    const formControlName = this.params.formControllName;
    this.myControl = this.params.formGroup.controls[formControlName];
  }

  showNewValues(dropDownValue: any) {
    this.params.values = dropDownValue;
    this.filteredOptions = dropDownValue;
  }

  displayFn = (value) => {
    if (value) {
      const selection = this.params.values.find(e => e.matchDisplayItem === value);
      this.employerParam.emit(selection);
      return selection ? selection.matchDisplayItem.toLowerCase() : value.toLowerCase();
    }
  }

  onInputChanged(event$: Event): void {
    this.changeDetector.detectChanges();
    const input = event$.target as HTMLInputElement;
    this.inputValue = input.value;
    if (this.inputValue.length === 3) {
      this.searchActivatedValue.emit({ value: this.inputValue, event: 'Search Activated' });
    }
  }

  onSelectionChanged(event$): void {
    this.isSelected = true;
    this.proxyValue = event$.option.value;
    this.searchCompletedValue.emit({ value: this.proxyValue, event: 'Search Completed' });
  }

  onPanelClosed(): void {
    if (!this.isSelected && !this.isButtonClicked) {
      setTimeout(() => this.autocomplete.openPanel(), 0);
    }
    this.isSelected = false;
  }

  // US298981: Added for changes to employer dropdown in card
  closeButton(): void {
    this.myControl.setValue('');
    this.myControl.setErrors(null);
    this.employerCardValueSelected.emit(false);
  }
}
