import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-form-field-loader',
  templateUrl: './form-field-loader.component.html',
  styleUrls: ['./form-field-loader.component.css']
})
export class FormFieldLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
