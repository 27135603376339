<div class="custom-date-picker-header">
  <div class="custom-date-picker-header-label">{{customLabel}}</div>
  <div class="custom-date-picker-header-controls">
    <button mat-icon-button (click)="previousYears()" [attr.aria-label]="customAriaLabel('Previous')"  id="calenderbtn">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <button mat-icon-button (click)="nextYears()" [attr.aria-label]="customAriaLabel('Next')"  id="calenderbtn">
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button> 
  </div>
</div>