import { Validators } from '@angular/forms';

export class SaveAndFinishValidation {
        emailAddress = {
          placeholder: 'Email address (optional)',
          id: 'emailAddress',
          formControllName: 'emailAddress',
          maxLength: '50',
          type: 'text',
          validators: [
            Validators.pattern(/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i),
          ],
          ariaLabel: 'Email address (optional)',
          messages: {
            pattern: 'Email address entered is invalid.'
          }
        };
}
