import { Injectable } from '@angular/core';

/* NgRx */
import { Store } from '@ngrx/store';
import { AppStates } from '@ffq-app-store';
import { AutoState  } from '@ffq-app-auto/store';

import * as fromAppActions from '@ffq-app-store/app.actions';
import * as fromAppSelectors from '@ffq-app-store/app.selectors';

import * as fromAutoActions from '@ffq-app-auto/store/auto.actions';
import * as fromAutoSelectors from '@ffq-app-auto/store/auto.selectors';

import * as fromYourInfoState from './your-info.state';
import * as fromYourInfoSelector from './your-info.selectors';
import * as fromYourInfoActions from './your-info.actions';

import { YourInfo } from '../model/your-info.model';

@Injectable({
  providedIn: 'root'
})
export class YourInfoStoreService {

  yourInfoData$ = this.yourInfoStore.select(fromYourInfoSelector.getData);
  existingCustomerData$ = this.yourInfoStore.select(fromYourInfoSelector.getExistingCustomerData);
  yourInfoError$ = this.yourInfoStore.select(fromYourInfoSelector.getError);
  // yourInfoStatus$ = this.yourInfoStore.select(fromYourInfoSelector.getYourInfoControlData);
  appStatus$ = this.appStore.select(fromAppSelectors.getAppControlData);
  autoStatus$ = this.autoStore.select(fromAutoSelectors.getAutoControlData);
  yourInfoLoadData$ = this.yourInfoStore.select(fromYourInfoSelector.getCustomerData);
  // getSubmitYourInfoStatus$ = this.yourInfoStore.select(fromAppSelectors.getSubmitYourInfoStatus);
  // getAssignAgentStatus$ = this.yourInfoStore.select(fromAutoSelectors.getAssignAgentStatus);
  getSubmitYourInfoStatus$ = this.yourInfoStore.select(fromYourInfoSelector.getSubmitYourInfoStatus);
  getCityValidityStatus$ = this.yourInfoStore.select(fromYourInfoSelector.getIsValidCity);

  constructor(
    private appStore: Store<AppStates.AppState>,
    private autoStore: Store<AutoState.AppState>,
    private yourInfoStore: Store<fromYourInfoState.AppState>
  ) { }

  load() {
    // this.yourInfoStore.dispatch(fromYourInfoActions.Load());
  }

  // saveYourInfo(reqPayload: any) {
  //   this.yourInfoStore.dispatch(fromYourInfoActions.SubmitYourInfo({ reqPayload }));
  // }

  // assignAgent(reqPayload: any) {
  //   this.yourInfoStore.dispatch(fromAppActions.AssignAgent({ reqPayload }));
  // }

  prefillData(reqPayload: any) {
    this.yourInfoStore.dispatch(fromAutoActions.PrefillData({ reqPayload }));
  }

  clearStateControlData() {
    // this.yourInfoStore.dispatch(fromAutoActions.PrefillControlDataClear({ ctrlData: { prefillData: null } }));
    this.appStore.dispatch(fromAppActions.SubmitYourInfoControlDataClear({ ctrlData: { submitYourInfo: null } }));
    // this.appStore.dispatch(fromAppActions.AssignAgentControlDataClear({ ctrlData: { assignAgent: null } }));
  }

  UpdateAllServiceData() {
    this.appStore.dispatch(fromAppActions.ClearYourInfoDataAndControlData());
    this.autoStore.dispatch(fromAutoActions.ClearAllDataFromAuto());
  }
}
