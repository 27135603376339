import { AbstractControl, ValidatorFn } from '@angular/forms';
import { Validators } from '@angular/forms';

export class InsuredModalValidation {

  hasAutoInsurance = {
    values: [
      { label: 'Yes', value: 'Y', gtmTag: 'FFQ_Auto_Yourinfo_haveins_yes_radio'},
      { label: 'No', value: 'N', gtmTag: 'FFQ_Auto_Yourinfo_haveins_no_radio'}
    ],
    id: 'hasAutoInsurance',
    ariaLabel: 'Do you currently have auto insurance',
    formControllName: 'hasAutoInsurance',
    validators: [
      Validators.required,
    ],
   messages: {
    required: 'Please select an option.',
    }
  };
  constructor() {}
}
