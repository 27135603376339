import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor(private datePipe: DatePipe) { }

  /**
   * Gets current date
   * @returns  current date
   */
  getCurrentDate() {
    const date = new Date();
    const currentDate = this.datePipe.transform(date, 'yyyy-MM-dd');
    return currentDate;
  }

  /**
   * Gets age
   * @param dateOfBirth value
   * @returns  age in number
   */
  getAge(dateOfBirth: Date) {
    // return Math.floor((new Date().getTime() - new Date(dateOfBirth).getTime()) / 1000 / 60 / 60 / 24 / 365);
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  /**
   * Transforms date service
   * @param date input
   * @param format the format to which it has to be changed
   */
  transform(date: any, format: string) {
    return this.datePipe.transform(date, format);
  }

  /**
   * Gets date from year, month and date passed
   * @param year year
   * @param month month
   * @param day date
   * @returns  date object
   */
  getDateFromYMD(year, month, day) {
   const date = new Date();
   year ? date.setFullYear(date.getFullYear() - year) :  date.setFullYear(date.getFullYear());
   month ? date.setMonth(date.getMonth() - month) : date.setMonth(date.getMonth());
   day ? date.setDate(day) : date.setDate(date.getDate());
   return this.transform(date, 'yyyy-MM-dd');
  }

  /**
   * Gets date from year, month and date passed
   * @param year year
   * @param month month
   * @param day date
   * @returns  date object
   */
  getDateFromYMDForSchdlAgent(year, month, day): string {
    const date = new Date();
    year ? date.setFullYear(date.getFullYear() - year) :  date.setFullYear(date.getFullYear());
    month ? date.setMonth(date.getMonth() - month) : date.setMonth(date.getMonth());
    day ? date.setDate(day) : date.setDate(date.getDate());
    const dayOfWeek = date.getDay();
    if (dayOfWeek % 6 === 0) {
      // set date to next week day if weekend
      date.setDate(date.getDate() + dayOfWeek % 5 + 1);
    }
    return this.transform(date, 'yyyy-MM-dd');
   }
}
