<mat-form-field [formGroup]="params.formGroup" ngClass="{{enableFocusRing?'enable-focus-ring':' '}} address-autocomplete-field full-width-field form-elements" appearance="fill">
  <mat-label aria-hidden="true" >{{ params.placeholder ? params.placeholder : null }}</mat-label>
  <input  (focus)="focusHandle()" [attr.aria-label]="params.ariaLabel" libAppGooglePlaces libField [validate]="params.directiveValidators" matInput 
    (selectedPlace)="onPlaceSelect($event)"
    (blur)="blurHandle()"
    [formControl]="params.formGroup.controls[params.formControllName]"
    [readonly] = "params.readonly"
    placeholder=""
    maxlength="{{params.maxLength}}"
    attr.data-GTM={{params.gtmTag}}/>
  <mat-error aria-hidden="true">
    <ng-container *ngIf="params.messages">
      <ng-container *ngFor="let key of Object.keys(params.messages)">
        <ng-container *ngIf="((params.formGroup).get(params.formControllName)).hasError(key)">
          {{getErrorMessage(params.messages, key, ((params.formGroup).get(params.formControllName)).value)}}
        </ng-container>
      </ng-container>
    </ng-container>
  </mat-error>
  <mat-hint *ngIf="params.assistiveText" align="start">
    <strong>{{ params.assistiveText }}</strong>
  </mat-hint>
</mat-form-field>
