import { CustomerInfo, ProjectCodeStatus, Home,
  Business, NavigationData, AgentData, RetrieveDataStatus,
  Auto, PremiumAmountObj, UtmParams, CampaignParams } from '@ffq-app-shared/model/common.data.model';
import { RateQuoteProgressStatus, ServiceCallStatus } from '@ffq-app-shared/enums/flow.identifier.enum';

/** 1- Define application state */
export interface AppState {
  error: string;
  data: any;
  controlData: any;
  navigationData: NavigationData;
  retrieveDataStatus: RetrieveDataStatus;
}

/*
export interface Agent {
  agent: AgentDetails;
}

export interface AgentDetails {
  agent: {
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    gender: string;
    address: {
      city: string;
      type: string;
      state: string;
      zip: string;
      apartment: string;
      street: string;
      isStandardized: string;
      zipExtn: string;
      userStrtAdd: string;
      houseNumber: string;
      streetName: string;
      streetPostDirection: string;
      streetPreDirection: string;
      streetType: string
    };
    communication: {
      phoneNumber: {
        phoneNumber: string;
        areaCode: string;
        phoneExtn: string
      }
      emailId: string
    }
    agentId: string;
    agentImageUrl: string;
    reference: string;
    assign: string;
    agentTier: string;
    isBuyEnabled: string;
  };
}
*/

/*
export interface Customer {
  isExistingCust: string;
  existingLobs: string;
  maxAllowedVehicle: number;
  address: {
    city: string;
    type: string;
    state: string;
    zip: string;
    apartment: string
    street: string;
    isStandardized: string;
    zipExtn: string;
    userStrtAdd: string;
    houseNumber: string;
    streetName: string;
    streetPostDirection: string;
    streetPreDirection: string;
    streetType: string
  };
  communication: Communication;
} */


export class ControlData {
  submitYourInfo: null;
  assignAgent: null;
  maxAllowedVehicle: null;
  lastVisitedPage: null;
  currentPageForSB: null;
  quoteSourceIndicator: null;
  signalDiscountSelected: null;
  signalDiscountClicked: null;
  isCoveragesUpdated: boolean;

  retrieveQuote: null;
  isDriverScreenValidated: null;
  defaultRateQuoteCalled: RateQuoteProgressStatus;
  crossSellStatus: false;
  crossSellEncryptData: null;
  redirectionFlow: null;
  flowDataForQuote: string;  /* "flowDataForQuote" is used to store the same value of the property "redirectionFlow".
  Since we need to clear the value after triggering the perform rate. */
  isWithinBusinessHour: string;
  chatStatus: string;
  formEdited: false;
  vehicleChangedAfterQuote: false;
  driverChangedAfterQuote: false;
  vehicleOrDriverChanged: false; /** This flag is to handle Connected car Implementation */
  connectedCarIndicator: false; /* to decide whether to show signal or not */
  packageSelected: string; /* DE9422: defect fix. Used to update the package selected in auto quote page.  */
  totalVehicleCount: number; /** Total number of vehicles added */
  totalDriverCount: number; /** Total number of drivers added */
  bwPopUpSkipped: boolean; /** used to identify whether the BW pop up is skipped */
  additionalPageStatus: string; /** use to track addional page - for SavePNi Service manadating on driver edit */
  isCustomQuoteImplBackDoor: boolean; /** Custom Quote Enable flag from query paramter */
  isRouterRefreshed: boolean;
  isBWCustomQuoteImplBackDoor: boolean; /** BW Custom Quote Enable flag from query paramter */
  requiredAutoRentersLoader: boolean; /** to identify whether the normal or auto-renters loader to be shown in quote page */
  autoRentersPremiumAmt: any; /** stores the auto renters premium amount */
  changeZipCalled: false;
  cityZipData: any;
  townZipData: any;
  restrictedFireProductLobs: string; /** CatMit changes for restricting cross-sell lobs */
  // tslint:disable-next-line: max-line-length
  initAppRenderServices: ServiceCallStatus; /** used to determine whether the project code and static content service can be triggered or not */
  encryptedMDMResponse: string;
  isBWQuote: boolean;
  requiredCvgUpdation: boolean; /* to determine whether the coverages needs to be updated for PI quote */
  previousRoute: Array<string> = ['', '']; /** US177580 - Back button disable for PI flow */
  quotePremiumAmount: PremiumAmountObj; /** US176305 - Display Quote Premium Banner */
  displayQuotePremium: false; /** US176305 - Display Quote Premium Banner */
  lastVisitedStep: null; /** segment tracking changes */
  ppcSrcAvailable: boolean; /* Set PpcSrc value availability status in loading URL - F23960 - for PI */
  partnerADPFFlow: boolean;
  isPrefillCallRequired?: boolean;
  isStartQuoteErrorAWSKnockout: boolean;
  knockoutMessage: string;
  knockoutCode: string;
  boundInd: boolean;

}



/*

export interface BWAgent {
  agentChanged: boolean;
  agentAvailable: boolean;
  agentInfoBean: AgentDetails;
  awsAgentKnockoutInd: boolean;
  knockOutResponseBean: any;
} */


/* 2- Define initail state of the feature */
export const initialState: AppState = {
  data: {
    agentData: {} as AgentData,
    quoteNumber: null,
    effectiveDate: null,
    customerData: null,
    _windowID: null,
    tokenInfo: null,
    redirectionData: null,
    visitorId: null,
    src: null,
    projectCodeStatus: {} as ProjectCodeStatus,
    sourcePhoneNumber: null,
    zipCode: null,
    lob: null,
    homeBoundIndicator: false,
    isBWCompanyCode: false,
    isValidAgent: null,
    landingStateCode: null,
    landingStateDescription: null,
    lifeBoundIndicator: false,
    pniMaritalStatus: null,
    pniOccupation: null,
    quoteType: null,
    quoteWindowId: null,
    quotedLobs: null,
    spouseDriver: null,
    ssnKey: null,
    windowId: null,
    landingZipCode: null,
    formEdited: false,
    businessHourResponse: null,
    utmParameters: {} as UtmParams,
    campaignParameters: {} as CampaignParams,
    isChatActive: null,
    zipCityList: null,
    zipTownList: null,
    customerInfo: {} as CustomerInfo
  },
  controlData: {
    defaultRateQuoteCalled: RateQuoteProgressStatus.NOT_CALLED,
    initAppRenderServices: ServiceCallStatus.INIT
  } as ControlData,
  error: '',
  navigationData: {
    auto: {} as Auto,
    home: {} as Home,
    business: {} as Business
  },
  retrieveDataStatus: {
    isAppStateDataExists: false,
    isAutoDataExists: false,
    isYourInfoDataExists: false,
    isVehiclesDataExists: false,
    isDriversDataExists: false,
    isDiscountsDataExists: false,
    isQuoteDataExists: false
  }


};
