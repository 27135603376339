<div class="existing-record-page-container">

    <div class="existing-record-content">
        <div aria-label="Graphical Farmers Insurance Logo" class="existing-record-logo-holder"></div>
        <div class="existing-policy_2">
            <div class="existing-record-title-holder">
                <span role="heading" aria-level="1"
                    aria-label="Our records indicate you have an existing Farmers Auto Policy">
                    <h3 role="presentation" class="existing-record-title">Our records indicate you have an existing<br
                            role="presentation" class="existing-customer" /> Farmers Auto Policy</h3>
                </span>
            </div>
            <div class="exist-customer existing-record-text"
                aria-label="As an existing customer, we appreciate your business. You may manage your existing policy online.">
                As an existing customer, we appreciate your business. You may manage your existing policy online.
            </div>
            <form method="POST" [formGroup]="landingformGroup" novalidate>
                <button (click)="custLoginOrReg()" id="existing-page-login-button"
                    class="login-or-register-button auto-module__login-reg-primary" role="button"
                    aria-label="Login Or Create Account" data-GTM="FFQ_Auto_Quote_Login_button">Login Or Create
                    Account</button>
            </form>
            <div *ngIf="agentAssigned" class="existing-record-text existing-record-text-small-screen contact-agent-text"
                aria-label="If you prefer to speak to your agent">
                If you prefer to speak to your agent:
            </div>

            <div *ngIf=agentAssigned>
                <div class="existing-policy-agent-detail-name">
                    <ng-container *ngIf="agentDetails?.agentImageUrl ; else default">
                        <div class="existing-policy_agent-detail-icon" tabindex="-1">
                            <img class="existing-policy_agent-image" src="{{agentDetails.agentImageUrl}}" tabindex="0"
                                alt="" onerror="this.src='../../../../../assets/images/agent-default-img.png';" />
                        </div>
                    </ng-container>
                    <ng-template #default>
                        <div class="existing-policy_agent-detail-icon" tabindex="-1">
                            <img class="existing-policy_agent-image"
                                src="../../../../../assets/images/agent-default-img.png" tabindex="0" alt="" />
                        </div>
                    </ng-template>
                    <div *ngIf="agentDetails?.firstName">
                        <div class="existing-policy_agent-detail-name" tabindex="0">{{agentName}}</div>
                        <div class="existing-policy_agent-detail-text" tabindex="0">Your local Farmers Insurance® Agent
                            is
                            available if you have any questions. </div>
                    </div>
                </div>

                <div *ngIf="agentDetails?.communication?.emailId?.emailAddress"
                    class="existing-policy_agent-detail-row ">
                    <a href="mailto:{{agentDetails.communication.emailId.emailAddress}}" class="agent-email-link">
                        <div class="existing-policy_agent-detail-icon" tabindex="-1">
                            <img src="../../../../../assets/images/email.png" tabindex="0"
                                alt="Please select link to send an email to your farmers agent" />
                        </div>
                        <div class="existing-policy_agent-detail-email" tabindex="0">
                            {{agentDetails.communication.emailId.emailAddress}}
                        </div>
                    </a>
                </div>


                <div *ngIf="agentDetails?.communication?.phoneNumber?.phoneNumber && agentPhoneNumber; else noAgentPhoneNumberDesktop"
                    class="existing-policy_agent-detail-row existing-policy_desktop ">
                    <a href="tel:{{agentPhoneNumberCC}}"
                        attr.aria-label="{{'Call to action, contact farmers representative '}} + {{agentPhoneNumber}} "
                        role="link" class="agent_phone">
                        <div class="existing-policy_agent-detail-icon" tabindex="-1">
                            <img src="../../../../../assets/images/local-phone.png" tabindex="0" alt="" />
                        </div>
                        <div class="existing-policy_agent-detail-phone" tabindex="0">{{agentPhoneNumber}}</div>
                    </a>
                </div>

                <ng-template #noAgentPhoneNumberDesktop>
                    <a href={{tele}} role="link"
                        attr.aria-label="{{'Call to action, contact farmers representative ' + desktopNo}}"
                        class="existing-policy_agent-detail-row existing-policy_desktop agent_phone ">
                        <div class="existing-policy_agent-detail-icon" tabindex="-1">
                            <img src="../../../../../assets/images/local-phone.png" tabindex="0" alt="" />
                        </div>
                        <div class="existing-policy_agent-detail-phone" tabindex="0">{{agentPhoneNumber}}</div>
                    </a>
                </ng-template>

                <div *ngIf="agentDetails?.communication?.phoneNumber?.phoneNumber && agentPhoneNumber; else noAgentPhoneNumberMobile"
                    class="existing-policy_agent-detail-row existing-policy_mobile ">
                    <a href="tel:{{agentPhoneNumberCC}}" class="agent_phone">
                        <div class="existing-policy_agent-detail-icon">
                            <img src="../../../../../assets/images/local-phone.png" alt="" />
                        </div>
                        <div class="existing-policy_agent-detail-phone">
                            {{agentPhoneNumber}}
                        </div>
                    </a>
                </div>
                <ng-template #noAgentPhoneNumberMobile>
                    <a href={{teleMobile}} role="link"
                        class="existing-policy_agent-detail-row existing-policy_mobile agent_phone ">
                        <div class="existing-policy_agent-detail-icon">
                            <img src="../../../../../assets/images/local-phone.png" alt="" />
                        </div>
                        <div class="existing-policy_agent-detail-phone">
                            {{agentPhoneNumber}}
                        </div>
                    </a>
                </ng-template>

                <div *ngIf="agentDetails?.address?.city" class="existing-policy_agent-detail-row ">
                    <a [href]=googleMaplocation target="_blank" class="agent-adress-link">
                        <div class="existing-policy_agent-detail-icon">
                            <img src="../../../../../assets/images/room.png" tabindex="0"
                                alt="Please select link to access the address of Your farmer's agent" />
                        </div>
                        <span tabindex="0">
                            <div class="existing-policy_agent-detail-address-1">{{agentDetails.address.street}}
                            </div>
                            <div class="existing-policy_agent-detail-address">{{agentLocation}}
                            </div>
                        </span>
                    </a>
                </div>
            </div>
            <div *ngIf="!agentAssigned" class="existing-policy-finish-buttons">
                <div class="existing-policy_body_noagent"> Need more help?</div>
                <div class="existing-policy_body_call-custome">A Farmers Insurance® representative
                    is available if you have any questions. </div>
                <a href="tel:{{agentPhoneNumber}}"
                    attr.aria-label="{{'Call to action, contact farmers representative '}} + {{agentPhoneNumber}} "
                    role="link" class="agent_phone">
                    <div class="no-agent-phone-icon" tabindex="-1">
                        <img src="../../../../../assets/images/local-phone.png" tabindex="0" alt="" />
                    </div>
                    <div class="existing-policy_no_agent-detail-phone" tabindex="0">{{agentPhoneNumber}}</div>
                </a>
            </div>
        </div>
    </div>
    <div class="thank-you-footer">
        <div class="multi-policy-card">
            <div class="looking-for-ways-to">
                <h1 role="heading" aria-level="2">Do you qualify for a multi-policy discount?</h1>
            </div>
            <div class="thank-you-footer-caption">Find out if adding new coverage will help you save.</div>
            <form *ngIf="landingformGroup!=null" method="POST" (ngSubmit)="startQuote()" [formGroup]="landingformGroup"
                novalidate>
                <div class="form-control-select">
                    <div class="cell-1">
                        <lib-select [params]="landingQuoteParams.lob"></lib-select>
                    </div>
                    <div class="cell-2">
                        <div class="button-holder">
                            <button (focus)="onFocus()" (blur)="onBlur()"
                                [ngClass]="(focusToAutoStartQuoteButton)? 'thank-you-auto-start-quote__isFocus':' '"
                                class="thankyou-page-start-quote-button auto-module__start-saving-primary"
                                aria-label="Start Saving" data-GTM="FFQ_Auto_ThankYou_startsaving_button">Start
                                Saving</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>