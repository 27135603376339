import { Component, OnInit, AfterViewInit } from '@angular/core';
import { NavigationDrawerService } from '../../../../shared/components/navigation-drawer/navigation-drawer.service';
import { SpinnerService } from '@ffq-lib/src/lib/spinner/spinner.service';
import { Router } from '@angular/router';
import { PrintData } from '@ffq-app-auto/quote/model/quote.model';
import { StateRuleModelService } from '@ffq-app-shared/services';
import { DataConstants } from '@ffq-app-shared/constants/data.constants';

import { AgentData } from '@ffq-app-shared/model/common.data.model';
import { Title } from '@angular/platform-browser';
import { AutoStoreService } from '@ffq-app-auto/store/auto.store.service';

@Component({
  selector: 'app-print-quote',
  templateUrl: './print-quote.component.html',
  styleUrls: ['./print-quote.component.scss']
})
export class PrintQuoteComponent implements OnInit, AfterViewInit {
  quoteNum = '';
  quoteAmnt = '';
  isBwQuote = false;
  agentAssigned = false;
  quoteData: PrintData;
  imageSrc = '../../assets/images/';
  agentDetails = {} as AgentData;
  isRentersImpl: any;
  landingStateCode: any;
  payPolicy: string;
  isPolicyPerksEligible = false;
  stateRuleText1 = '';
  nonGoodDriverImpl = false;
  pageTitle = 'Farmers Fast Quote - FFQ-Auto-Print';
  constructor(private navService: NavigationDrawerService,
              private spinnerService: SpinnerService, private router: Router,
              private stateRuleModel: StateRuleModelService,
              private titleService: Title, private autoStoreService: AutoStoreService
    ) { }

  ngOnInit(): void {
    this.setTitle(this.pageTitle);
    this.spinnerService.hideLoader();
    const currUrlArr = this.router.url.split('?');
    const quoteNum = currUrlArr[1].split('=')[1];
    this.quoteData = JSON.parse(localStorage.getItem(quoteNum));
    this.quoteNum = this.quoteData.quoteNumber;
    this.quoteAmnt = this.quoteData.premiumAmnt;
    this.isBwQuote =  this.quoteData.isBwQuote;
    this.isPolicyPerksEligible = this.quoteData.isPolicyPerkEligible;
    const bristolwestPopupData = DataConstants.BWDiscalimerContent;
    this.imageSrc += bristolwestPopupData.logoSrc;
    this.agentDetails = this.quoteData.agent.agent ? this.quoteData.agent.agent : this.quoteData.agent.agentInfoBean;
    if (this.agentDetails.reference === '1') {
      this.agentAssigned = true;
    }

    this.isRentersImpl = this.quoteData.isRentersImpl;
    this.landingStateCode = this.quoteData.landingStateCode;
    /** US332036: Adding Disclaimer for CA Nongood Driver */
    this.payPolicy = this.quoteData.payPolicy;
    this.nonGoodDriverImpl = this.quoteData.isNonGoodDriver;
    if (this.quoteData.isEEImpl) {
      this.stateRuleText1 = 'representatives.';
    } else if (!this.agentAssigned && this.isBwQuote && this.quoteData.isBWDirectSalesImpl) {
      this.stateRuleText1 = 'representatives.';
    } else {
      this.stateRuleText1 = 'agents.';
    }
  }

  ngAfterViewInit() {
    document.getElementById('bodytag').classList.add('overflowAuto');
  }

  callPrint(): void {
    // need LOb, Quote number, windowID
    const reqPayload = {};
    reqPayload[`lob`] = 'A';
    reqPayload[`quoteNumber`] = this.quoteNum;
    reqPayload[`windowId`] = this.quoteData.windowId;
    reqPayload[`pageVisited`] = 'Print';
    this.autoStoreService.dispatchPrint(reqPayload);
    window.print();
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
}
