import { NgModule, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoDialogComponent, InfoDialogContentComponent } from './';
import { MatDialogModule } from '@angular/material/dialog';
import { ActionDialogComponent, ActionDialogContentComponent } from './action-dialog/action-dialog.component';
import { ActionDialogNewComponent } from './action-dialog-new/action-dialog-new.component';

@NgModule({
  declarations: [InfoDialogComponent, ActionDialogComponent, InfoDialogContentComponent, ActionDialogNewComponent],
  imports: [
    CommonModule,
    MatDialogModule
  ],
  providers: [InfoDialogComponent]
})

export class DialogModule {

  constructor() {}


 }
