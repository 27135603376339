import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
// TODO export this component in index.ts and change the path to @ffq-lib
import { ActionDialogNewComponent } from '@ffq-lib/src/lib/dialog/action-dialog-new/action-dialog-new.component';
import { StateRuleModelService } from './state-rule-model.service';
import { AppStoreService } from '@ffq-app-store/app.store.service';
import { DataConstants } from '@ffq-app-shared/constants/data.constants';

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  nums = ['zero', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ',
    'ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen '];
  numsUptoNinety = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

  constructor(public dialog: MatDialog, private stateRuleModel: StateRuleModelService,
              private appStoreService: AppStoreService) { }

  /**
   * Scrolls to first error
   * @param [formId] formId
   */
  scrollToFirstError(formId?: string) {

    if (formId === null || formId === undefined) {
      return;
    }
    // tslint:disable-next-line: max-line-length
    // const invalidFields =  [].slice.call(document.getElementById( 'auto-module__add-vehicle-forms' ).getElementsByClassName( 'mat-form-field-invalid' ))[0];
    setTimeout(() => {
      this.scrollToField(formId);
    }, 200);
  }

  scrollToCustomerError(fieldId: string): void {
    const invalid = document.querySelector('#' + fieldId);
    if (invalid) {
      setTimeout(() => {
        (invalid as HTMLElement).focus();
      }, 200);
    }
  }

  /**
   * Scrolls to field
   * @param formId formId
   */
  scrollToField(formId: string) {
    /** Select and scroll to the second(first is the whole form which is invalid too) error element */
    // tslint:disable-next-line: max-line-length
    let invalidFields;
    let quotePage = false;

    if (formId === 'auto-module__add-vehicle-forms' || formId === 'auto-module__add-driver-forms') {
      invalidFields = document.getElementById(formId).getElementsByClassName('mat-form-field-invalid')[0];
    } else if (formId === 'auto-module__add-driverAssignment-forms') {
      invalidFields = document.getElementById(formId).getElementsByClassName('auto-module__driver_assignment_error_message')[0];
    } else if (formId === 'auto-module__your-info-forms') {
      const error = document.getElementById(formId).getElementsByClassName('mat-form-field-invalid');
      if (error.length > 0) {
        invalidFields = document.getElementById(formId).
          getElementsByClassName('mat-form-field-invalid')[0].getElementsByClassName('mat-form-field-wrapper')[0];
        if (invalidFields === undefined) {
          invalidFields = document.getElementById(formId).
            getElementsByClassName('mat-form-field-invalid')[0];
        }
      } else {
        const flag = document.getElementById(formId).
          getElementsByClassName('error-text')[0];
        if (flag) {
          invalidFields = document.getElementById(formId).getElementsByClassName('auto-module__your-info-card-subscription-agreement')[0];
        }
      }
    } else if (formId === 'auto-module__quote-liability-coverages-forms' || formId === 'auto-module__quote-vehicle-coverage-forms') {
      invalidFields = document.getElementById(formId).getElementsByClassName('coverage-error-label')[0];
      quotePage = true;
    } else if (formId === 'bwRate_adjustable_msg') {
      invalidFields = document.getElementById(formId);
    } else {
      invalidFields = document.getElementById(formId) && document.getElementById(formId).
      getElementsByClassName('mat-form-field-invalid')[0] ? document.getElementById(formId).
      getElementsByClassName('mat-form-field-invalid')[0].getElementsByClassName('mat-form-field-wrapper')[0] : '';
    }

    if (invalidFields) {
      if (invalidFields.getElementsByClassName('mat-select')[0]) {
        setTimeout(() => {
          invalidFields.getElementsByClassName('mat-select')[0].focus();
        }, 200);
      } else if (invalidFields.getElementsByClassName('mat-checkbox')[0]) {
        const invalid = invalidFields.querySelector('.mat-checkbox-inner-container input');
        setTimeout(() => {
          invalid.focus();
          invalidFields.getElementsByClassName('mat-checkbox')[0].classList.add('cdk-keyboard-focused');
          invalidFields.getElementsByClassName('mat-checkbox')[0].classList.remove('cdk-program-focused');
        }, 200);
      } else {
        const invalid = document.querySelector('#' + formId + ' .mat-form-field-invalid input');
        if (invalid) {
          setTimeout(() => {
            (invalid as HTMLElement).focus();
          }, 200);
        }
      }
      if (quotePage) {
        // Function to scroll to first error coverage's sibling
        // This will work only in quote page
        // if sticky header present after navigate , the covergae wont be visible correctly
        // So we are navigating to coverage before the coverage with error
          this.scrollToSiblingDiv(formId);
      } else {
        invalidFields.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }


  scrollToSiblingDiv(formId) {
    const allCoverages =  document.getElementById(formId).getElementsByClassName('ffq-coverage-panel-coverage-holder');
    const mdCoverage =  document.getElementById(formId).getElementsByClassName('md-coverage-header');
    const laCoverage =  document.getElementById(formId).getElementsByClassName('la-coverage-header');
    if (allCoverages && allCoverages.length > 0) {
      let siblingCoverage;
      let siblIndex ;
      const totalCovergaes = allCoverages.length;
      let mdCoverageDrop = false;
      let laCoverageDrop = false;
      // tslint:disable-next-line:prefer-for-of
      for (let index = 0; index < totalCovergaes; index++) {
        if (allCoverages[index].getElementsByClassName('coverage-error-label')[0]) {
          // tslint:disable-next-line:max-line-length
          if (mdCoverage && mdCoverage[0] && (index === totalCovergaes - 1  || index === totalCovergaes - 2) && (window.innerWidth > 1439)) {
            mdCoverageDrop = true;
            break;
          // tslint:disable-next-line: max-line-length
          } else if (laCoverage && laCoverage[0] && (index === totalCovergaes - 1  || index === totalCovergaes - 2) && (window.innerWidth > 1439)) {
            laCoverageDrop = true;
          } else {
            siblIndex =  (window.innerWidth > 1439) &&  ( index % 2 === 1) ? index - 2 : index - 1 ;
            break;
          }
        }
      }
      if (mdCoverageDrop && mdCoverage[0]) {
        mdCoverage[0].scrollIntoView({ behavior: 'smooth', block: 'start' });
      } else if (laCoverageDrop && laCoverage[0]) {
        laCoverage[0].scrollIntoView({ behavior: 'smooth', block: 'start' });
      } else if (siblIndex < 0) {
        siblingCoverage = document.getElementById(formId);
      } else {
        siblingCoverage = allCoverages [siblIndex];
      }

      if (siblingCoverage ) {
        siblingCoverage.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }

  openActionDialog(data) {
    return this.dialog.open(ActionDialogNewComponent, { data });
  }

  /**
   * used to replace the key "webDesc" with "label" in dropdownvalues
   */
  generateDropDownValues(fieldElement) {
    const result = {
      fieldName: fieldElement.fieldName,
      formName: fieldElement.formName,
      status: fieldElement.status ? fieldElement.status : '',
      year: fieldElement.year,
      // tslint:disable-next-line:max-line-length
      dropDownValues: fieldElement.dropDownValues ? fieldElement.dropDownValues.map(element => ({ label: element.webDesc, value: element.value })) : null
    };

    return result;
  }

  /** This method will convert the given number into words upto 999 */
  numToWords(num: number) {

    if (Number(num) === 0) {
      return this.nums[0];
    }
    const numArr = ('000' + num).substr(-3).match(/^(\d{1})(\d{2})$/);
    if (!numArr) { return ''; }
    let numInWords = '';
    numInWords += (Number(numArr[1]) !== 0) ? (this.nums[numArr[1]] || this.numsUptoNinety[numArr[1][0]]
      + ' ' + this.nums[numArr[1][1]]) + 'hundred ' : '';
    numInWords += (Number(numArr[2]) !== 0) ? ((numInWords !== '') ? 'and ' : '') +
      (this.nums[numArr[2]] || this.numsUptoNinety[numArr[2][0]] + ' ' + this.nums[numArr[2][1]]) : '';
    return numInWords;
  }

  /**
   * Combines request and response
   * @param request request for the service
   * @param response response from the service
   * @returns result
   */
  combineRequestAndResponse(request, response) {
    let result = null;
    const reqObjCopy = JSON.parse(JSON.stringify(request));
    result = Object.assign({}, reqObjCopy, response);
    return result;
  }


  // TODO: remove 'helperService.getModuleNameByRoute' if never used
  //        now we make call for chatbot opening explicitly from quote page
  //        so we don't need to track a routing anymore
  /**
   * Returns module name for chat. Case sensetive.
   */
  getModuleNameByRoute() {
    const path = window.location.pathname.split('/');
    for (let i = path.length - 1; i > 0; i--) {
      switch (path[i]) {
        case 'yourinfo':
          return 'yourInfo';
        case 'vehicles':
          return 'VEHICLE';
        case 'drivers':
          return 'DRIVER';
        case 'discounts':
          return 'DISCOUNT';
        case 'knockout':
        case 'quote':
          return 'QUOTE';
        default:
          break;
      }
    }

    return 'yourInfo';
  }

  /**
   * Gets src phone number
   * @param serviceStatus service call Status
   * @returns  source phone number
   */
  getSrcPhoneNumber(serviceStatus) {
    let landingSource = null;
    let sourcePhoneNumber = null;
    this.appStoreService.getLandingSourceFromSrc$.subscribe((landingSrc: any) => {
      landingSource = landingSrc;
    }).unsubscribe();

    if (serviceStatus === 'fail') {
      const srcPhoneNumberElement = DataConstants.SourcePhoneNumberMapping
        .find(element => landingSource.startsWith(element.src));
      if (srcPhoneNumberElement) {
        sourcePhoneNumber = srcPhoneNumberElement.number;
        this.appStoreService.updateAppData({sourcePhoneNumber});
      }
    } else {
      this.appStoreService.getPhoneNumberFromSrc$.subscribe((phoneNumber: any) => {
        sourcePhoneNumber = phoneNumber;
      }).unsubscribe();
    }

    return sourcePhoneNumber;
  }
}

