import { Component, OnInit, AfterViewInit } from '@angular/core';
import { NavigationDrawerService } from '..';
import { AppStoreService } from '@ffq-app-store/app.store.service';
import moment from 'moment';

@Component({
  selector: 'app-footer-links',
  templateUrl: './footer-links.component.html',
  styleUrls: ['./footer-links.component.scss']
})
export class FooterLinksComponent implements OnInit {
  public showAsStrip = false;
  public showAsPanel = true;
  public showDrawer = false;
  landingStateCode: any;
  public currentYear: string;
  showNoticeOfInformation = false;
  lob: any;
  url: any;
  isSmallScreen = false;

  constructor(
    private navigationDrawerService: NavigationDrawerService,
    private appStoreService: AppStoreService) {


    navigationDrawerService.showHideEvent.subscribe(
      (sidePanelEvent) => {
        if (sidePanelEvent === 'expandSideStrip') {
          this.showAsStrip = false;

        } else if (sidePanelEvent === 'collapseSidePanel') {
          this.showAsStrip = true;

        } else if (sidePanelEvent === 'toggleSidePanelStrip') {

          if (this.showAsStrip === true) {
            this.showAsStrip = false;

          } else {
            this.showAsStrip = true;
          }
        }
      }
    );

  }

  ngOnInit() {
    // this.switchToStrip();
    this.createSSLImage();
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (width < 600) {
      this.isSmallScreen = true;
    }
    this.appStoreService.appData$.subscribe((data) => {
      if (data) {
        this.landingStateCode = data.landingStateCode;
        this.lob = data.lob;
        if (this.lob === 'business') {
        this.showNoticeOfInformation = true;
        const stateObj = 'AZ,CA,GA,MN,MT,NJ,OR,VA,NV,CT';
        const stateObj1 = stateObj.split(',');
        this.showNoticeOfInformation = stateObj1.indexOf(this.landingStateCode) > -1;
        this.url = this.urlForBusiness(this.landingStateCode);
        } else if (this.lob === 'auto') {
          const stateObj = 'AZ,VA,GA,NJ,NV,CT,OR,MN,CA,MT,IL';
          const stateObj1 = stateObj.split(',');
          this.showNoticeOfInformation = stateObj1.indexOf(this.landingStateCode) > -1;
          this.url = this.urlForAuto(this.landingStateCode);
        }
      }
    });
    this.currentYear = moment().format('YYYY');
    this.handleYourPrivacyLinkFocus();
  }
  public switchToStrip() {
    this.showAsStrip = true;
  }

  /**
   * US723015 : After closing the CPRA popup
   * focus should goes back to the your-privacy button
   */
  handleYourPrivacyLinkFocus(): void {
    const closeBtn = document.getElementById('close-pc-btn-handler');
    if(closeBtn){
      closeBtn.addEventListener('click', () =>{
        const focusEle = document.getElementById('your-privacy');
        if(focusEle){
          focusEle.focus();
        }
      })
    }
  }

  showLogo() {
        if (this.isSmallScreen) {
          return !this.navigationDrawerService.isSideNavOpened();
        } else if (!this.navigationDrawerService.isSideNavOpened() && !this.showAsStrip) {
          return !this.navigationDrawerService.isSideNavOpened();
        } else {
          return this.showAsStrip;
        }
      }

  createSSLImage() {
    const cid = document.createElement('script');
    cid.async = true;
    cid.src = '//seal.digicert.com/seals/cascade/seal.min.js';
    const s = document.getElementsByTagName('script');
    const ls = s[(s.length - 1)];
    ls.parentNode.insertBefore(cid,
      ls.nextSibling);
    const cleartimer = setInterval(() => {
        if (document.getElementById('DigiCertClickID_Z9bZfwjLSeal')) {
          // tslint:disable-next-line: max-line-length
          document.getElementById('DigiCertClickID_Z9bZfwjLSeal').getElementsByTagName('img')[0].alt = 'Digicert Secure Trusted Click to Verify opens in a new window';
          clearInterval(cleartimer);
          // fix for feature/DE81978
          // Fix for console error(US523074)
          if (document.getElementById('#DigiCertClickID_Z9bZfwjLSeal')) {
            document.getElementById('#DigiCertClickID_Z9bZfwjLSeal').getElementsByTagName('img')[0].setAttribute('style', 'margin: 0px');
          }
        }
      }, 100);

  }

  urlForBusiness(stateCode) {
    const stateObj = 'AZ,GA,NJ,VA,NV,CT';
    return this.findURL(stateCode, stateObj);
  }


  urlForAuto(stateCode) {
    const stateObj = 'AZ,GA,NJ,VA,NV,CT,IL';
    return this.findURL(stateCode, stateObj);
  }

  /**
   * Finds url
   * @param stateCode stateCode
   * @param stateObj stateObj
   * @returns url
   */
  findURL(stateCode, stateObj) {
    switch (stateCode) {
      case 'OR':
        return 'http://www.farmers.com/information-practices/oregon/';
      case 'MN':
        return 'http://www.farmers.com/information-practices/minnesota/';
      case 'CA':
        return 'http://www.farmers.com/information-practices/california/';
      case 'MT':
        return 'http://www.farmers.com/information-practices/montana/';
      default:
        const stateObj1 = stateObj.split(',');
        return stateObj1.indexOf(stateCode) > -1 ? 'https://www.farmers.com/information-practices/general/' : '';
    }
  }
}
