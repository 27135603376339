import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromQuoteSelector from './quote.selectors';
import * as fromQuoteActions from './quote.actions';
import * as fromQuoteState from './quote.state';
import * as fromAutoSelectors from '@ffq-app-auto/store/auto.selectors';
import { AutoState } from '@ffq-app-auto/store';

@Injectable({
  providedIn: 'root'
})
export class QuoteStoreService {

  /**
   * Creates an instance of quote store service.
   * @param quoteStore QuoteStore
   */
  constructor(
    private quoteStore: Store<fromQuoteState.QuoteState>,
    private autoStore: Store<AutoState.AppState>) { }

  /**
   * Getfetch coverage dropdown list$ of quote store service
   */
  getfetchCoverageDropdownList$ = this.quoteStore.select(fromQuoteSelector.getfetchDropdownList);

  /**
   * Redirection flow$ of quote store service
   */
  getFlowControlData$ = this.quoteStore.select(fromQuoteSelector.getFlowControlData);

  /**
   * Get fetch drop down service status of quote store service
   */
  getFetchDropDownServiceStatus$ = this.quoteStore.select(fromQuoteSelector.getFetchDropDownServiceStatus);

  /**
   * Get quote errors$ of quote store service
   */
  getQuoteErrors$ = this.quoteStore.select(fromQuoteSelector.getQuoteErrors);

  /**
   * Get coverage update status$ of quote store service
   */
  getCoverageUpdateStatus$ = this.quoteStore.select(fromQuoteSelector.getCoverageUpdateStatus);

  /**
   * Get uninsuredmotorist value of quote store service
   */
  getUninsuredMotoristValue$ = this.quoteStore.select(fromQuoteSelector.getUninsuredMotoristValue);

  /**
   * Get underinsuredmotorist value of quote store service
   */
  getUnderinsuredMotoristValue$ = this.quoteStore.select(fromQuoteSelector.getUnderinsuredMotoristValue);

  /**
   * Get underinsuredmotorist value of quote store service
   */
  getPIPCoverageValue$ = this.quoteStore.select(fromQuoteSelector.getPIPCoverageValue);

  /**
   * Get PropertyDamageValue of quote store service
   */
  getPropertyDamageValue$ = this.quoteStore.select(fromQuoteSelector.getPropertyDamageValue);

  /**
   * Get BodilyInjuryValue of quote store service
   */
  getBodilyInjurValue$ = this.quoteStore.select(fromQuoteSelector.getBodilyInjury);

  /**
   * Get custom rate coverages$ of quote store service
   */
  getCustomRateCoverages$ = this.quoteStore.select(fromQuoteSelector.getCustomRateCoverages);

  /**
   * Get vehicle custom rate coverages$ of quote store service
   */
  getVehCustomRateCoverages$ = this.quoteStore.select(fromQuoteSelector.getVehCustomRateCoverages);

  /**
   * Get curretn changed vehicle coverages$ of quote store service
   */
  getCurrentChangedVehCvg$ = this.quoteStore.select(fromQuoteSelector.getCurrentChangedVehCvg);

  /**
   * Get cov error status for custom rate$ of quote store service
   */
  getCovErrorStatusForCustomRate$ = this.quoteStore.select(fromQuoteSelector.getCovErrorStatusForCustomRate);

  /**
   * Get cov error status for custom rate$ of quote store service
   */
  getCurrentChangedLiabilityCvg$ = this.quoteStore.select(fromQuoteSelector.getCurrentChangedLiabilityCvg);

  /**
   * Get perform rate status$ of quote store service
   */
  getPerformRateStatus$ = this.autoStore.select(fromAutoSelectors.getPerformRateStatus);

  // Get custom info messages
  getCustomRateInfoMsgs$ = this.quoteStore.select(fromQuoteSelector.getCustomRateInfoMsgs);

  getQuoteButtonText$ = this.quoteStore.select(fromQuoteSelector.getQuoteButtonText);

  // Get covergae for print
  getCoverageForPrint$ = this.quoteStore.select(fromQuoteSelector.getCoverageForPrint);

  /**
   * Get liability custom rate coverages of quote store service
   */
  getLiabilityCustomRateCoverages$ = this.quoteStore.select(fromQuoteSelector.getLiabilityCustomRateCoverages);

  // Update coverage status
  updateQuoteControlData(ctrlData: any) {
    return this.quoteStore.dispatch(fromQuoteActions.UpdateQuoteControlData({ ctrlData }));
  }

  // Update custom coverage for vehicle
  updateVehicleCustomCoverage(reqPayload: any) {
    return this.quoteStore.dispatch(fromQuoteActions.UpdateVehicleCustomCoverage({ reqPayload }));
  }

  /**
   * Fetchs coverage drop down list
   * @param reqPayload reqPayload
   */
  fetchCoverageDropDownList(reqPayload: any) {
    this.quoteStore.dispatch(fromQuoteActions.FetchCoverageDropDownList({ reqPayload }));
  }

  clearFetchDropDownControlData() {
    this.quoteStore.dispatch(fromQuoteActions.FetchCoverageDropDownListControlDataClear({ ctrlData: { fetchDropDownList: '' } }));
  }

  /**
   * Performs quote rate
   * @param reqPayload RequestPayload
   */
  performQuoteRate(reqPayload: any) {
    this.quoteStore.dispatch(fromQuoteActions.PerformRateQuote({ reqPayload }));
  }

  /**
   * Performs save quote
   * @param reqPayload RequestPayload
   */
  saveQuote(reqPayload: any, mode: string) {
    this.quoteStore.dispatch(fromQuoteActions.saveQuote({ reqPayload, mode }));
  }
  // Performs save quote and finish action dispatch
  saveQuoteAndFinish(reqPayload: any, mode: string) {
    this.quoteStore.dispatch(fromQuoteActions.SaveQuoteAndFinish({ reqPayload, mode }));
  }

  updateLiabilityCustomcoverage(reqPayload: any) {
    this.quoteStore.dispatch(fromQuoteActions.UpdateLiabilityCustomcoverage({ reqPayload }));
  }
  doCustomRateCall(reqPayload: any) {
    this.quoteStore.dispatch(fromQuoteActions.CustomRate({ reqPayload }));
  }
}
