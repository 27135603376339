<form [formGroup]="saveAndFinishOnTimeOutFormGroup">
    <mat-dialog-content
        [ngClass]="[((isEmailViewRequired) && (lastVisitedPage !== 'YourInfo')) && !(isTimeOut)? 'auto-module__save-and-finish-on-timeout-dialog' : 'auto-module__save-and-finish-on-timeout-dialog_email',
        (!(isEmailViewRequired)) ? 'auto-module__save-and-finish-on-timeout-dialog_withemail': 'auto-module__save-and-finish-on-timeout-dialog']">
        <div>
            <div class="auto-module__save-and-finish-header-text">
                {{saveAndFinishDialogTitle}}
            </div>
            <div class="auto-module__save-and-finish-on-timeout-dialog-content">
                {{saveAndFinishDialogContent}}
                <p class="auto-module_dialog__info-dialog-text">{{this.quoteNumber}}</p>
            </div>
            <div *ngIf="(isEmailViewRequired) && (lastVisitedPage !== 'YourInfo') && !(isTimeOut);">
                <div class="auto-module__save-and-finish-input-text">
                    <lib-input-text [params]="saveAndFinishOnTimeOutParams.emailAddress"></lib-input-text>
                </div>
            </div>
            <div *ngIf="!(isEmailViewRequired);">
                <div class="auto-module__save-and-finish-line-inline"></div>
                <span class="auto-module__save-and-finish-line-text">OR</span>
                <div class="auto-module__save-and-finish-line-inline"></div>
                <div class="auto-module__save-and-finish-on-timeout-dialog-content">
                    To retrieve the quote again, use the email address you entered:
                    <p class="auto-module_dialog__info-dialog-text">
                        {{appData.customerData?.customer?.communication?.emailAddress}}</p>
                </div>
            </div>
        </div>
        <div class="auto-module__save-and-finish-buttons">
            <mat-dialog-actions class="dialog__info-dialog-button-holder" align="center">
                <button mat-button class="auto-module_dialog__info-dialog-button-close2"
                    (click)="saveAndExit()">{{buttonText2}}</button>
            </mat-dialog-actions>
        </div>

    </mat-dialog-content>
</form>