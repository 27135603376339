<div class="ffq-lib__vehicle-card" role="button" [ngClass]="{'card-disabled' : (params.disable) , 'card-selected': (params.addedToQuote) , 'card-unselected' : (!params.addedToQuote)}">
    <div (keydown.enter)="manageQuote(params.addedToQuote,'addOrdelete', params.id)" (click)="manageQuote(params.addedToQuote,'addOrdelete', params.id )">
        <div tabindex="0" role="button"
            [attr.aria-label]="(params.disable)? params.title +'opens a modal dialog box disabled' : (params.addedToQuote)?  params.title + 'selected click to delete': params.title +'opens a modal dialog box'"
            #prefill attr.data-GTM="{{params.gtmTag}}"
            [ngClass]="(params.addedToQuote)?'card-selected check-box-icon-checked':'card-unselected check-box-icon-unchecked'">
            <h3 aria-hidden="true"
                [ngClass]="(params.addedToQuote)?'ffq-lib__vehicle-card__header_selected':'ffq-lib__vehicle-card__header'"
                role="presentation">{{ params.title }}</h3>
        </div>
        <span attr.aria-label="Vehicle Identification Number : {{params.text }}">
            <h4 aria-hidden="true"
                [ngClass]="params.addedToQuote ? 'ffq-lib__vehicle-card__subheader_checked' : 'ffq-lib__vehicle-card__subheader'"
                *ngIf="params.text" role="presentation">{{ params.text }}</h4>
</span>
    </div>
    <ng-container *ngIf="params.addedToQuote">
        <div class="ffq-lib__vehicle-card__seperator"><hr /></div>
        <div class="ffq-lib__vehicle-card__footer" #edit tabindex="0" role="button"
            attr.aria-label="{{params.title}}{{params.text}} Edit opens in modal dialog box"
            (click)="manageQuote(params.addedToQuote, 'edit', params.id )"
            (keyup.enter)="manageQuote(params.addedToQuote, 'edit', params.id )" 
            attr.data-GTM="{{params.editGtmTag}}">
            <div  *ngIf="params.addedToQuote" class="ffq-lib__vehicle-card__footer_selected row between-xs">
                <div class="col-xs">
                    <a class="edit-icon left-icon"><span class="ffq-lib__vehicle-card__Edit">Edit</span></a>
                </div>
            </div>
        </div>
    </ng-container>
</div>

