import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import {  MatDialogRef } from '@angular/material/dialog';
import {  AppStoreService } from '@ffq-app-store/app.store.service';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SegmentService } from '@ffq-app-shared/services/segment.service';
import { DataService } from '@ffq-app-core/services';
import { DataConstants, ServiceConstants } from '@ffq-app-shared/constants';
import { StateRuleModelService } from '@ffq-app-shared/services/state-rule-model.service';

@Component({
  selector: 'app-fgia-popup',
  templateUrl: './fgia-popup.component.html',
  styleUrls: ['./fgia-popup.component.scss']
})
export class FgiaPopupComponent implements OnInit, OnDestroy {
  dialogTitle: string;
  private subscription: Subscription;
  landingStateCode: any;
  imageSrc = '../../../assets/images/';
  landingZipCode: string;
  bristolwestPopupData: any;
  dialogContent: string;
  lob: string;
  quoteType: string;

  constructor(
    public dialogRef: MatDialogRef<FgiaPopupComponent>,
    private appStoreService: AppStoreService,
    private router: Router,
    private dataService: DataService,
    private stateRuleModel: StateRuleModelService,
    private segmentService: SegmentService) {
  }

  ngOnInit() {
    this.subscription = this.appStoreService.appError$.subscribe((errorObj: any) => {
        if (errorObj && Object.keys(errorObj).length !== 0) {
          this.lob = this.getLob(errorObj.errorInfo.lob);
          this.quoteType =  errorObj.errorInfo.lob;
          this.landingZipCode = errorObj.errorInfo.zipCode;
        }
      });

    this.subscription = this.appStoreService.getStaticContent$.subscribe(() => {
      this.setStaticContent();
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Redirect to Monetization page
   */
  redirectToMonetizationPage(): void {
    this.segmentService.trackCtaClicked('FGIA- No, thanks Clicked');
    this.dialogRef.close();
    this.router.navigate(['/intermediatepage']);
  }

  /**
   * Redirected to farmersgeneralinsuranceagency.com
   */
  redirectToFgiaUrl(): void {
    this.segmentService.trackCtaClicked('FGIA- redirect to FGIA page Clicked');
    let fgiaUrl = ServiceConstants.FGIA_URL + this.landingZipCode + '&insurance_type=' + this.lob;
    window.open(fgiaUrl, '_self');
  }

  /**
   * To set the aem content
   */
  setStaticContent(): void {
    this.appStoreService.appDataZip$.subscribe((data) => {
      this.landingStateCode = data[`landingStateCode`];
    });
    const driverContent = this.appStoreService.findContent('drivers');
    if (driverContent && driverContent.additionalDetails) {
      // this.bristolwestPopupData.title = this.dataService.getStaticString(driverContent.bristolwest, 'headerTitles', 'BW_TITLE');
      const isEEState = this.stateRuleModel.isEEState(this.landingStateCode);
      if (isEEState) {
        this.dialogContent = this.dataService.getStaticString(driverContent.additionalDetails,
          'disclaimerText', 'FGIA_EE_DISCLAIMER');
      } else {
        this.dialogContent = this.dataService.getStaticString(driverContent.additionalDetails,
          'disclaimerText', 'FGIA_DISCLAIMER');
      }
    }
  }

  getLob(quoteType: string) {
    switch (quoteType) {
      case DataConstants.LOB.auto:
        return 'auto';
      case DataConstants.LOB.business:
        return 'business';
      case DataConstants.LOB.home:
        return 'home';
      case DataConstants.LOB.renter:
        return 'renters';
      case DataConstants.LOB.condo:
        // for condo home will be returned in the url
        return 'home';
      case DataConstants.LOB.life:
        return 'life';
    }
  }
}
