import { Component, OnInit, OnDestroy, AfterViewInit, Inject, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';

/* Reactive Forms */
import { FormBuilder, FormGroup, AbstractControl, ValidatorFn } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';

/* rx.js */
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';

/* NgRx */
import { AppStoreService } from '../store/app.store.service';

import {
  LandingComponentValidation,
  RetriveComponentValidation
} from '../landing';

import { DateService } from '@ffq-lib/src/lib/core/services/date.service';
import { DataConstants, NavigationDrawerService, ServiceConstants } from '@ffq-app-shared';

import { DatePipe, TitleCasePipe } from '@angular/common';
import { Validators } from '@angular/forms';

import {
  RetrieveQuoteRequestObject, QuoteWithQuoteNumber, StartQuoteRequest, MultiQuoteResObject,
  RetrieveQuoteResObject
} from './model/landing.model';
import { ModuleLandingService } from './services/module-landing.service';
import { DataLayerService } from '@ffq-app-core/services/data-layer.service';
import { SpinnerService } from '@ffq-lib/src/lib/spinner/spinner.service';
import { NavigationHelperService } from '@ffq-app-shared/services/navigation-helper.service';
import { CookieService } from 'ngx-cookie-service';
import { FlowParamter, ServiceCallStatus, SourceIndicator } from '@ffq-app-shared/enums/flow.identifier.enum';
import { LegacyRedirectionService } from '@ffq-app-core/services/legacy.redirection.service';
import { StateRuleModelService } from '@ffq-app-shared/services';
import { RestErrorHandlerService } from '@ffq-app-core/services/rest.error.service';
import { InfoDialogComponent } from '@ffq-lib/src/lib/dialog/info-dialog/info-dialog.component';
import { DOCUMENT } from '@angular/common';
import { LastVisitedModule, LastVisitedStepForSegment } from '@ffq-app-shared/model/common.data.model';
import { WindowRef, ICustomWindow } from '@ffq-app-auto/shared/services/window.ref';
import { environment } from '@environments/environment';
import { SegmentService } from '@ffq-app-shared/services/segment.service';
import {
  MDMRetrievalParams,
  QuoteFailedProps,
  QuoteInfoParams, QuoteRedirectedProps, RedirectionParams,
  RetrieveQuoteProps, StartQuoteProps
} from '@ffq-app-shared/model/segment.data.model';
import { YourInfoStoreService } from '@ffq-app-auto/your-info/store/your-info.store.service';
import { DateValidatorService } from '@ffq-app-core/services/date-validator.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LandingDialogComponent } from './landing-dialog/landing-dialog.component';
import { url } from 'inspector';
import { type } from 'os';
import { InterstitialPageComponent } from '@ffq-app-shared/components/interstitial-page/interstitial-page.component';
import { LandingPopupComponent } from './landing-popup/landing-popup.component';
@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  providers: [LandingDialogComponent]
})
export class LandingComponent implements OnInit, OnDestroy, AfterViewInit {
  landingformGroup: FormGroup;
  retriveQuoteFormGroup: FormGroup;
  landingValidation = new LandingComponentValidation();
  retrivevalidation = new RetriveComponentValidation();
  retriveQuoteParams: any;
  landingQuoteParams: any;
  moduleName: any;
  show = false;
  showProductErr = false;
  retrieveQuoteRequestObject = {} as RetrieveQuoteRequestObject;
  quoteWithQuoteNumberReqObject = {} as QuoteWithQuoteNumber;
  startQuoteParams: StartQuoteRequest;
  private subscriptions: Subscription[] = [];
  showMockLandingPage = false;
  lob: any;
  flow: any;
  dLRequired: any;
  projectCodeStatusIsCalled = false;
  modalData = DataConstants.MODAL_DATA;
  landingSource: any;
  landingStateCode: any;
  windowDirectUrl: any;
  private paramSubscription: Subscription = new Subscription();
  isLmsRedirectChecked = false;
  isRetrieveQuote = false;
  @ViewChild('retrieveQuoteForm') retrieveQuoteForm: ElementRef;
  autoImage = DataConstants.LANDING_PAGE_IMAGE_SVG.Auto;
  homeImage = DataConstants.LANDING_PAGE_IMAGE_SVG.Home;
  lifeImage = DataConstants.LANDING_PAGE_IMAGE_SVG.Life;
  businessImage = DataConstants.LANDING_PAGE_IMAGE_SVG.Business;
  rentersImage = DataConstants.LANDING_PAGE_IMAGE_SVG.Renters;
  condoImage = DataConstants.LANDING_PAGE_IMAGE_SVG.Condo;
  currentFoucs= 1;



  /**
   * Promoid  of landing component
   */
  promoId: string;
  /**
   * CampaignIdid  of landing component
   */
  campaignId: string;
  customWindow: ICustomWindow;
  pollingStatus = 0;
  pollingStart: boolean;
  ccpaUrl: string;
  /**
   * Determines whether migrated landing enabled is
   */
  isMigratedLandingEnabled = false;
  /**
   * Err from response of landing component
   */
  errFromResponse: any;
  /**
   * Show zip err of landing component
   */
  showZipErr: boolean;
  dialogRef: MatDialogRef<{}>;
  showRetrieve: boolean;
  /**
   * Show multi quote screen
   */
  showMultiQuote: boolean;
  /**
   * Multiquote Response Array
   */
  multiQuoteVO: Array<MultiQuoteResObject> = [];
  legacyRedirectionUrl: string;
  // US358148: add ppc src in startquote req payload for AJG flow
  ppcSource: string;

  quoteRetrieved = false;
  isStartQuoteKnockoutAWSFlow = false;

  isOneUIEnabled = false;
  searchQueryParams: Params;
  /**
   * Creates an instance of landing component.
   * @param router Router
   * @param infoDialogComponent  InfoDialogComponent
   * @param fb FormBuilder
   * @param store Store
   * @param appStoreService AppStoreService
   * @param navDrawer NavigationDrawerService
   * @param datePipe DatePipe
   * @param spinnerService SpinnerService
   * @param dateValidatorService datevalidator
   * @param LandingDialog MatDialog
   */
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private appStoreService: AppStoreService,
    private navDrawer: NavigationDrawerService,
    private datePipe: DatePipe,
    private moduleLandingService: ModuleLandingService,
    private dataLayerService: DataLayerService,
    private navHelper: NavigationHelperService,
    private cookieService: CookieService,
    private legacyRedirectionService: LegacyRedirectionService,
    private stateRulemodel: StateRuleModelService,
    public restErrorHandlerService: RestErrorHandlerService,
    private infoDialogComponent: InfoDialogComponent,
    private spinnerService: SpinnerService,
    private dateService: DateService,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
    private segmentService: SegmentService,
    private yourInfoStoreService: YourInfoStoreService,
    private dateValidatorService: DateValidatorService,
    public LandingDialog: MatDialog,
    private titleCasePipe: TitleCasePipe,
    private windowRef: WindowRef
  ) {
    navDrawer.hideDrawer();
  }

  /**
   * Builds form landing
   * @returns form landing
   */
  buildFormLanding(): any {
    this.landingformGroup = this.fb.group({
      lob: ['', this.landingValidation.lob.validators],
      landingZipCode: ['', this.landingValidation.landingZipCode.validators],
      quoteType: ['']
    });
  }

  /**
   * Displays form
   * @returns form
   */
  displayForm(): any {
    this.landingformGroup.patchValue({});
  }
  /**
   * on init
   */
  ngOnInit() {
    this.createSSLImage();
    this.navDrawer.hideDrawer();
    this.isMigratedLandingEnabled = ServiceConstants.IS_MIGRATED_LANDING_PAGE_ENABLED === 'Y' ? true : false;
    this.navDrawer.hideEverything();
    this.appStoreService.updateAppControlData({ PhoneNumberServiceStatus: null });
    this.populateRequestParamter();
    this.buildFormLanding();
    this.buildFormRetrive();
    this.initFormLanding();
    this.initFormRetrive();
    this.displayForm();
    this.onFormGroupChanges();
    this.ccpaUrl = ServiceConstants.CCPA_URL;
    this.retriveQuoteParams = this.getretriveQuoteFormParameters();
    this.landingQuoteParams = this.getlandingQuoteFormParameters();
    this.windowDirectUrl = this.windowRef.nativeWindow;
    /**
     * Ensure the below funciton is the last call for NgOnit.
     * showMockLandingPage - Will disable the mock page loading whwne redirection from Legacy application
     */
    this.checkUrlParameterAndNavigate();
    this.subscriptions.push(this.appStoreService.getLob$.subscribe((lob: any) => {
      this.lob = lob;
    }));
    this.callAppRenderServices();
    /**
     * US310612 : FL cross sell issue fix
     */
    if (this.flow !== FlowParamter.CROSS_SELL && this.flow !== FlowParamter.RETRIEVE_BUY
      && this.flow !== FlowParamter.RETRIEVE_QUOTE && this.flow !== FlowParamter.RETRIEVE_SUBMIT) {
      this.startQuoteResponseSubscribe();
    }
    this.showOnlyRetrieve(window.location.href);

    this.subscriptions.push(this.appStoreService.getBoundInd$.subscribe(data => {
      if(data === true) {
        this.showRetrieveErrorDialog();
      }
    })),
    /**
     * US348402 : Design Listing page Screen ( MulitQuote Screen)
     */
    this.subscriptions.push(this.appStoreService.getMultiQuoteData$.subscribe((multiQuoteResponse) => {
      if (multiQuoteResponse?.length) {
        this.multiQuoteVO = [...multiQuoteResponse];
        this.showMultiQuote = true;
      }
    }),
      this.appStoreService.getRetrieveQuoteRedirectionData$.subscribe((retrieveQuoteResponse) => {
        if (this.redirectToLegacyHQMorOneUI(retrieveQuoteResponse.data)) {
          const redirectToLegacyHQMorOneUIUrl = this.redirectToLegacyHQMorOneUI(retrieveQuoteResponse.data);
          if (redirectToLegacyHQMorOneUIUrl) {
            window.location.href = redirectToLegacyHQMorOneUIUrl;
          }
        }
      })
    );

    this.handleRetrieveErrorResponse();
    this.handleYourPrivacyLinkFocus();
  }

  /**
   * US723015 : After closing the CPRA popup
   * focus should goes back to the your-privacy button
   */
  
  handleYourPrivacyLinkFocus(): void {
    const closeBtn = this.document.getElementById('close-pc-btn-handler');
    if(closeBtn){
      closeBtn.addEventListener('click', () =>{
        const focusEle = this.document.getElementById('your-privacy');
        if(focusEle){
          focusEle.focus();
        }
      })
    }
  }

  /**
   * US348400: Show only retrieve block when the url has retrive keyword
   */
  showOnlyRetrieve(url: string): void {
    if (url.indexOf('retrieve') > -1) {
      this.showRetrieve = true;
      if (url.indexOf('quoteNumber') > -1) {
        let quoteNumber = '';
        this.route.queryParams.subscribe((params) => {
          quoteNumber = params.quoteNumber;
        });
        this.retriveQuoteFormGroup.get('quoteNumber').setValue(quoteNumber);
      }
    }
  }

  private populateRequestParamter() {
    this.paramSubscription = this.route.queryParams.subscribe(params => {
      if (params.isCustomQuoteImpl || params.isBWCustomQuoteImpl) {
        // Selecting LOB based on Params
        this.appStoreService.backDoorCustomQuoteImplFlag({
          isCustomQuoteImplBackDoor: params.isCustomQuoteImpl ? params.isCustomQuoteImpl === 'true' : undefined,
          isBWCustomQuoteImplBackDoor: params.isBWCustomQuoteImpl ? params.isBWCustomQuoteImpl === 'true' : undefined
        });
        this.paramSubscription.unsubscribe();
      }
    });
  }
  /**
   * after view init
   */
  ngAfterViewInit() {
    /** DE77316 : add focus to element on page load */
    if (this.showRetrieve) {
      if (this.retrieveQuoteForm.nativeElement) {
        this.retrieveQuoteForm.nativeElement[0].focus();
      }
    } else {
      const element = document.getElementById('landing-accessibility-header');
      element?.focus();
    }
    this.spinnerService.hideLoader();
  }
  /**
   * Inits form landing
   */
  private initFormLanding() {
    this.initTextControl('landingZipCode');
    this.initTextControl('lob');
    this.initTextControl('quoteType');
  }

  /**
   * Getlanding quote form parameters
   * @returns  data
   */
  getlandingQuoteFormParameters() {
    return {
      lob: {
        placeholder: 'Quote Type',
        id: 'lob',
        formGroup: this.landingformGroup,
        formControllName: 'lob',
        values: [
          { label: 'Auto Insurance', value: 'auto' },
          { label: 'Home Insurance', value: 'home' },
          { label: 'Business Insurance', value: 'business' },
          { label: 'Renters Insurance', value: 'renter' },
          { label: 'Condo Insurance', value: 'condo' }
        ],
        validators: [Validators.required],
        messages: {
          required: 'Please choose a Quote Type.'
        }
      },
      landingZipCode: {
        placeholder: 'ZIP Code',
        id: 'landingZipCode',
        formGroup: this.landingformGroup,
        formControllName: 'landingZipCode',
        readonly: false,
        autoComplete: 'Off',
        maxLength: 5,
        ariaLabel: 'Zip code',
        directiveValidators: 'zipField',
        ariaHiddenInd: true,
        validators: [Validators.required, Validators.pattern('^[0-9]{5}$')],
        messages: {
          required: 'Please enter ZIP Code.',
          pattern: 'Please enter a 5-digit ZIP Code.'
        }
      }
    };
  }

  isStartQuoteCallRequired() {
    let isStartCallCallRequired = true;
    let data = null;
    this.appStoreService.getAppControlValues$.subscribe((ctrlData: any) => {
      data = ctrlData;
    }).unsubscribe();
    if (data && data[`quoteNumber`]) {
      isStartCallCallRequired = false;
    } else if (data && data[`isRc1Eligible`] && this.pollingStatus) {
      return isStartCallCallRequired;
    } else {
      if (this.getDecodeUrlParamAsString() === sessionStorage.getItem('startQuoteParams')
        && sessionStorage.getItem('StartQuoteResponseData')) {
        /** F24967: Navigation issue fix for landing page Migration  */
        if (this.checkRequestPayloadIfMigrationEnabled()) {
          return true;
        }
        isStartCallCallRequired = false;
        /** US377141: Navigation issue fix for landing page Migration  */
        if (this.checkRequestPayloadIfMigrationEnabled()) {
          return true;
        }
        const startQuoteResponseData = sessionStorage.getItem('StartQuoteResponseData');
        const startQuoteRequestData = sessionStorage.getItem('StartQuoteRequestData');
        const startQuoteResponsectrlData = sessionStorage.getItem('StartQuoteResponsectrlData');

        this.appStoreService.updateStartQuoteDirectly(JSON.parse(startQuoteResponseData),
          JSON.parse(startQuoteRequestData), JSON.parse(startQuoteResponsectrlData));
      }
    }

    return isStartCallCallRequired;
  }

  checkRequestPayloadIfMigrationEnabled(): boolean {
    if (this.isMigratedLandingEnabled && sessionStorage.getItem('StartQuoteRequestData')) {
      const startQuoteRequestData = JSON.parse(sessionStorage.getItem('StartQuoteRequestData'));
      const startQuoteResponseData = JSON.parse(sessionStorage.getItem('StartQuoteResponseData'));
      if (this.startQuoteParams.landingZipCode === startQuoteRequestData.landingZipCode
        && this.startQuoteParams.quoteType === startQuoteRequestData.quoteType
        && !startQuoteResponseData.quoteNumber
        ) {
        return false;
      }
      return true;
    }
    return false;
  }

  startMyQuoteFromLandingPage() {
    /** US348316: retrieve migration navigation issue fix */
    this.isRetrieveQuote = false;
    if (!this.landingformGroup.controls.landingZipCode.value) {
      this.landingformGroup.controls.landingZipCode.setErrors({ required: true });
    }
    if (!this.landingformGroup.valid) {
      if (!this.landingformGroup.value.lob) {
        this.showProductErr = true;
      }
      if (!this.landingformGroup.controls.landingZipCode.valid) {
        this.landingformGroup.controls.landingZipCode.markAsTouched();
      }
      return;
    } else {
      this.showProductErr = false;
    }
    sessionStorage.clear();
    // DE73991: In Landing Page we are Unable to Change the Zip Code(or) LOB
    this.updateLatestZipLobValue();
    this.startMyQuote();
  }

  /**
   * Starts my quote
   */
  startMyQuote() {
    // this.spinnerService.showLoader();
    let udata = this.landingformGroup.value;
    if (this.startQuoteParams) {
      udata = this.startQuoteParams;
      // to pre poulate zip in cae of agent scenario
      if (this.isMigratedLandingEnabled && !this.startQuoteParams.quoteType && this.landingformGroup.value.lob) {
        udata.quoteType = this.getQuoteType(this.landingformGroup.value.lob);
        udata.landingZipCode = this.landingformGroup.value.landingZipCode;
      }
      udata.lob = this.getLob(udata.quoteType);
    } else if (udata.lob) {
      udata.quoteType = this.getQuoteType(udata.lob);
    }
    if (this.isStartQuoteCallRequired()) {
      sessionStorage.clear();
      sessionStorage.setItem('startQuoteParams', this.getDecodeUrlParamAsString());
      // Adding Segment tracking for Start Quote
      this.callSegmentForStartQuote(udata.lob, udata.landingZipCode, udata.quoteType);
      this.appStoreService.startQuote(udata);
      this.callSegmentForRedirectionParams();

    }
  }

  /**
   * Segment tracking call
   */
  callSegmentForStartQuote(lob: string, landingZipCode: string, quoteType: string): void {
    const startQuoteInfo: StartQuoteProps = {
      startquote_lob: lob,
      startquote_landingzipcode: landingZipCode,
      startquote_quotetype: quoteType
    };
    sessionStorage.setItem('startQuoteReqPayload', JSON.stringify(startQuoteInfo));
    this.segmentService.quoteStartInitiated(startQuoteInfo);
  }

  redirectToFirstPage(lob: string) {
    const selectedLOB = this.getLob(lob);
    const routeLob = (selectedLOB === 'condo' || selectedLOB === 'renter') ? 'home' : selectedLOB;
    this.navDrawer.showSidePanel();
    if (lob === 'B') {
      this.router.navigate(['/' + routeLob + this.getModuleRoute(routeLob, 'BusinessInfo')], { queryParams: { LOB: lob } });
    } else {
      // US386017 - Back button redirection issue fix
      // this.appStoreService.updateAppControlData({ previousRoute: ['legacy', 'your-info'] });
      this.router.navigate(['/' + routeLob + this.getModuleRoute(routeLob, 'YourInfo')],
        { queryParams: { LOB: lob } });
    }
  }

  // US322709 : Fixing QuoteType sent as empty to api for Lob Query String
  getQuoteTypeFromQuery(lob: string): string {
    switch (lob) {
      case 'auto':
      case DataConstants.LOB_CS.auto:
        return DataConstants.LOB.auto;
      case 'business':
      case DataConstants.LOB_CS.business:
        return DataConstants.LOB.business;
      case 'home':
      case DataConstants.LOB_CS.home:
        return DataConstants.LOB.home;
      case 'condo':
      case DataConstants.LOB_CS.condo:
        return DataConstants.LOB.condo;
      case 'renter':
      case DataConstants.LOB_CS.renter:
        return DataConstants.LOB.renter;
      case 'life':
      case 'lifec':
      case DataConstants.LOB_CS.life:
        return DataConstants.LOB.life;
    }
  }

  getQuoteType(lob: string) {
    switch (lob) {
      case 'auto':
        return DataConstants.LOB.auto;
      case 'business':
        return DataConstants.LOB.business;
      case 'home':
        return DataConstants.LOB.home;
      case 'condo':
        return DataConstants.LOB.condo;
      case 'renter':
        return DataConstants.LOB.renter;
      case 'life':
      case 'lifec':
        return DataConstants.LOB.life;
    }
  }

  getLob(quoteType: string) {
    switch (quoteType) {
      case DataConstants.LOB.auto:
        return 'auto';
      case DataConstants.LOB.business:
        return 'business';
      case DataConstants.LOB.home:
        return 'home';
      case DataConstants.LOB.renter:
        return 'renter';
      case DataConstants.LOB.condo:
        return 'condo';
      case DataConstants.LOB.life:
        return 'life';
    }
  }

  /**
   * This function needs to be called to calculate the effectiveDate
   * paramter passed to Project Status code
   */
  getEffectiveDate() {
    // TODO - Changes to be done here while retrieve Quote is implemented
    const now = new Date();
    return this.datePipe.transform(now, 'yyyy-MM-dd');
  }

  /**
   * Getretrives quote form parameters
   * @returns data
   */
  getretriveQuoteFormParameters() {
    return {
      lastName: {
        placeholder: 'Last Name',
        id: 'lastName',
        formGroup: this.retriveQuoteFormGroup,
        formControllName: 'lastName',
        readonly: false,
        ariaLabel: 'last Name',
        validators: [
          Validators.required,
        ],
        messages: {
          required: 'Please enter Last Name.',
        }
      },
      dateOfBirth: {
        placeholder: 'Date of Birth(MM/DD/YYYY)',
        id: 'dateOfBirth',
        ariaLabel: 'Date of Birth in form MM/DD/YYYY',
        formGroup: this.retriveQuoteFormGroup,
        formControllName: 'dateOfBirth',
        readonly: false,
        validators: [
          Validators.required
        ],
        minDate: this.dateService.getDateFromYMD(99, null, null),
        maxDate: this.dateService.getDateFromYMD(15, null, null),
        messages: {
          required: 'Please enter Date of Birth.',
          pattern: 'Invalid Date of Birth.',
          ageLimit: 'Age should be between ' + 15 + ' and ' + 99 + ' years.',
          minLength: 'Date of Birth is not a valid entry.'
        }
      },
      emailAddress: {
        placeholder: 'Quote Number Or Email',
        id: 'emailAddress',
        formControllName: 'emailAddress',
        ariaLabel: 'Quote Number Or Email',
        formGroup: this.retriveQuoteFormGroup,
        readonly: false,
        validators: [
          Validators.required,
        ],
        messages: {
          required: 'Please enter Quote Number Or Email Address.',
          pattern: 'Email is not a valid entry.'
        }
      },
      quoteNumber: {
        placeholder: 'Quote Number Or Email',
        id: 'quoteNumber',
        formControllName: 'quoteNumber',
        formGroup: this.retriveQuoteFormGroup,
        ariaLabel: 'Quote Number Or Email',
        readonly: false,
        validators: [
          Validators.required
        ],
        messages: {
          required: 'Please enter Quote Number Or Email Address.',
          pattern: 'Email Address is not a valid entry.',
          maxlength: 'Quote number should be only 10 digits.'
        }
      },
      landingZipCode: {
        placeholder: 'ZIP Code',
        id: 'landingZipCode',
        formControllName: 'landingZipCode',
        formGroup: this.retriveQuoteFormGroup,
        ariaLabel: 'ZIP Code',
        readonly: false,
        validators: [Validators.required, Validators.pattern('^[0-9]{5}$')],
        messages: {
          required: 'Please enter ZIP Code.',
          pattern: 'Please enter a 5-digit ZIP Code.',
          invalid: 'Please enter a valid ZIP Code.'
        }
      }
    };
  }

  /**
   * Builds form retrive
   * @returns form retrive
   */
  buildFormRetrive(): any {
    this.retriveQuoteFormGroup = this.fb.group({
      lastName: ['', this.retrivevalidation.lastName.validators],
      dateOfBirth: ['', this.retrivevalidation.dateOfBirth.validators],
      quoteNumber: ['', this.retrivevalidation.quoteNumber.validators],
      landingZipCode: ['', this.retrivevalidation.landingZipCode.validators]
    });
    // throw new Error('Method not implemented.');
  }
  /**
   * Inits form retrive
   */
  private initFormRetrive() {
    this.initTextControlRetrive('lastName');
    this.initTextControlRetrive('dateOfBirth');
    this.initTextControlRetrive('quoteNumber');
    this.initTextControlRetrive('emailAddress');
    this.initTextControlRetrive('landingZipCode');
  }
  /**
   * US348400: Validating Retrieval Fields
   */
  checkForValidation(): void {
    const controls = this.retriveQuoteFormGroup.controls;
    Object.keys(controls).forEach(control => {
      if (control === 'quoteNumber' && controls[control].value) {
        if (isNaN(controls[control].value)) {
          const validEmailRegex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
          if (!controls[control].value.match(validEmailRegex)) {
            this.retriveQuoteFormGroup.get(control).setErrors({ pattern: true });
          }
        } else if (controls[control].value.length > 10) {
          this.retriveQuoteFormGroup.get(control).setErrors({ maxlength: true });
        }
      } else if (!controls[control].value) {
        this.retriveQuoteFormGroup.get(control).setErrors({ required: true });
      }
    });
  }

  /**
   * Retrives your quote
   */
  retriveYourQuote() {
    /** US348316: retrieve migration navigation issue fix */
    this.isRetrieveQuote = true;
    if (!this.retrieveQuoteRequestObject.isEncrypted) {
      this.retriveQuoteFormGroup.markAllAsTouched();
      this.checkForValidation();
      if (this.retriveQuoteFormGroup.valid) {
        const udata = this.retriveQuoteFormGroup.value;
        this.retrieveQuoteRequestObject = {
          ...{},
          dateOfBirth: this.datePipe.transform(udata.dateOfBirth, 'yyyy-MM-dd'),
          encryptedData: '',
          isEncrypted: false,
          landingZipCode: udata.landingZipCode,
          lastName: this.titleCasePipe.transform(udata.lastName),
          isOneUIRetrieveQuote: false
        };
        if (udata.quoteNumber.indexOf('@') !== -1) {
          this.retrieveQuoteRequestObject = {
            ...this.retrieveQuoteRequestObject,
            emailAddress: udata.quoteNumber.toLowerCase(),
            quoteNumber: ''
          };
        } else {
          this.retrieveQuoteRequestObject = {
            ...this.retrieveQuoteRequestObject,
            emailAddress: '',
            quoteNumber: udata.quoteNumber
          };
        }
      }
    }
    if (this.retrieveQuoteRequestObject.isEncrypted || this.retrieveQuoteRequestObject.landingZipCode && this.retriveQuoteFormGroup.valid) {
      this.appStoreService.retriveQuote(this.retrieveQuoteRequestObject);
      this.subscriptions.push(
        this.appStoreService.getRQControlData$.subscribe((ctrlData: any) => {
          if ('success' === ctrlData.retrieveQuote && 'success' === ctrlData.projectCodeServiceStatus
            && 'success' === ctrlData.staticContentServiceStatus) {
            // change for condo - renters lead generation form
            const selectedLOB = this.getLob(this.getQuoteType(this.lob));
            const routeLob = (selectedLOB === 'condo' || selectedLOB === 'renter') ? 'home' : selectedLOB;
            // To Display Quote number in side panel for Retrieved quotes
            if ((this.lob === 'auto' || routeLob === 'home') && this.flow !== FlowParamter.REDIRECTIONAL_FWS) {
              this.navDrawer.showQuoteNumber();
            }
            this.navDrawer.showSidePanel();

            const flowParm = this.flow ? this.flow : 'RQ';
            if (flowParm === 'RS') {
              this.spinnerService.showLoader();
            }
            this.appStoreService.updateRedirectionFlow({ redirectionFlow: flowParm, flowDataForQuote: flowParm });
            let isBWQuote = false;
            this.appStoreService.getIsBWQuote$.subscribe((isQuoteBrightlined: boolean) => {
              isBWQuote = isQuoteBrightlined;
            });
            // tslint:disable-next-line: max-line-length
            const isSignalBannerDiscountImpl = this.stateRulemodel.getSignalBannerStateRule(ctrlData.landingStateCode).isSignalBannerDiscountImpl;
            /** US480187: Fix for the incomplete quotes if connected car eligible */
            const isConnectedCarEligible = ctrlData.connectedCarIndicator
              && this.stateRulemodel.getProjectCodeStatus('isConnectedCarImpl');
            if (!isConnectedCarEligible && isSignalBannerDiscountImpl
              && (ctrlData.lastVisitedPage === LastVisitedModule.Auto_Discount) && !isBWQuote) {
              this.router.navigate([routeLob + this.getModuleRoute(routeLob, 'Signal')],
                { queryParams: { Flow: flowParm, LOB: this.getQuoteType(this.lob) } });
            } else if (ctrlData.lastVisitedPage === 'RateSummary') {
              this.router.navigate([routeLob + this.getModuleRoute(routeLob, ctrlData.lastVisitedPage)],
                { queryParams: { Flow: 'PI', LOB: this.getQuoteType(this.lob) } });
            } else {
              this.router.navigate([routeLob + this.getModuleRoute(routeLob, ctrlData.lastVisitedPage)],
                { queryParams: { Flow: flowParm, LOB: this.getQuoteType(this.lob) } });
            }
          }
          // Adding Segment tracking for Reteieve Quote
          if (!this.quoteRetrieved) {
            this.callSegmentForRetrieveQuote(this.retrieveQuoteRequestObject.lastName, this.retrieveQuoteRequestObject.quoteNumber,
              this.retrieveQuoteRequestObject.landingZipCode);
            this.quoteRetrieved = true;
          }
        })
      );
    }
  }

  /**
   * Segment tracking call
   */
  callSegmentForRetrieveQuote(lastName: string, quoteNumber: string, landingZipCode: string): void {
    const retrieveQuoteInfo: RetrieveQuoteProps = {
      /** US452706 - Fixing Quote Retrieval Initiated segment issue */
      retrieval_flow: this.showRetrieve ? 'FCOM' : this.flow,
      retrieval_last_name: this.retrieveQuoteRequestObject.lastName,
      retrievequote_quotenumber: this.retrieveQuoteRequestObject.quoteNumber,
      retrieval_zip: this.retrieveQuoteRequestObject.landingZipCode,
      email_deeplink: this.dLRequired && this.dLRequired === 'true' ? 'Yes' : 'No'
    };
    sessionStorage.setItem('RetrieveQuoteReqPayload', JSON.stringify(retrieveQuoteInfo));
    this.segmentService.quoteRetrievalInitiated(retrieveQuoteInfo);
  }

  handleRetrieveErrorResponse() {
    this.subscriptions.push(this.appStoreService.appError$.subscribe((errorObj: any) => {
      if (errorObj && Object.keys(errorObj).length !== 0 && this.isRetrieveQuote) {
        // Adding Segment tracking for Error Scenarios
        this.segmentService.trackErrorOccurred(errorObj);
        // tslint:disable-next-line: max-line-length
        if (errorObj.errorInfo.errorCode === 'EL005') {
          const additionalParameters = { styleClass: '', buttonName: 'OK', buttonGTM: '' };
          additionalParameters.styleClass = 'landing';
          // US348409: Handle error scenario by implementing popup
          this.showErrorDialog();
        } else if (errorObj.errorInfo.errorCode === DataConstants.ERROR_CODES.invalidZipCodeException) {
          // To Handle Invalid Zip Code Exception while Retrieval
          this.retriveQuoteFormGroup.get('landingZipCode').setErrors({ invalid: true });
        } else {
          // tslint:disable-next-line: max-line-length
          this.restErrorHandlerService.checkRestResponse(errorObj.errorInfo.errorCode, this.landingformGroup.value.landingZipCode, this.lob);
        }
      }
    }));
  }

  /**
   *  US348409: Handle error scenario by implementing popup
   */
  showErrorDialog(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
    this.dialogRef = this.LandingDialog.open(LandingDialogComponent, {
      /** DE78801: ADA fixes */
      autoFocus: false,
      ariaLabelledBy: 'landingErrorInfoHeading infoContent'
    });
  }
  showRetrieveErrorDialog(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
    this.dialogRef = this.LandingDialog.open(LandingPopupComponent, {
      autoFocus: false,
      ariaLabelledBy: 'landingErrorInfoHeading infoContent'
    });
    this.dialogRef.afterClosed().subscribe(()=>{
      this.appStoreService.updateAppControlData({ boundInd: null });
    })
  }

  /**
   * Toggles function
   */
  toggleFunction() {
    this.show = !this.show;
    // US388620: Production Validation issues
    this.landingformGroup.get('lob').setValue('');
  }

  togglefocus(){
      const quoteEle = this.document.querySelector('#landing-quote');
      const retrieveEle = this.document.querySelector('#landing-retrieve');
      if(this.currentFoucs == 1){
        (retrieveEle as HTMLElement).focus();
        this.currentFoucs=2
        quoteEle.setAttribute("tabindex", "-1");
        retrieveEle.setAttribute("tabindex", "0");
      }else{
        (quoteEle as HTMLElement).focus(); 
        this.currentFoucs=1;
        retrieveEle.setAttribute("tabindex", "-1");
        quoteEle.setAttribute("tabindex", "0");
      }   
  }
  /*US743618 - focus is on first or activated tab then through arrow key next tab should only receive focus*/
  togglKeyupFunction(e) {
    /*right arrow, left arrow and enter*/
    if(e.key == 'ArrowRight'){
      this.togglefocus();
    }
    else if(e.key == 'ArrowLeft'){
      this.togglefocus();
    }
    else if(e.key == 'Enter'){
      this.toggleFunction();
    }
  }

  /**
   * Gets module route
   * @ param lob
   * @param module Name
   * @returns  path
   */
  getModuleRoute(lob, moduleName) {
    if ('auto' === lob) {
      switch (moduleName) {
        case 'YourInfo':
          return '/yourinfo';
        case 'Vehicle':
          return '/vehicles';
        case 'Driver':
          return '/drivers';
        case 'Discount':
          return '/discounts';
        case 'Signal':
          return '/signal-discounts';
        case 'Quote':
          return '/quote';
        case 'AdditionalInfo':
          return '/additionalInfo';
        case 'Assignment':
          return '/drivers/driverassignment';
        case 'RateSummary':
          return '/ratesummary';
        // Add rest of Auto Modules here
        default:
          return '/yourinfo';
      }
    } else if ('home' === lob) {
      return '/yourinfo';
    } else if ('business' === lob) {
      switch (moduleName) {
        case 'BusinessInfo':
          return '/businessinfo';
        case 'businessInfoBean':
          return '/businessinfo';
        case 'YourInfo':
          return '/yourinfo';
        default:
          return '/yourinfo';
      }
    }
  }

  // #######################################################################################################################

  // Move to library or shared modules?
  private initTextControl(name: string) {
    const control = this.landingformGroup.get(name);
    /* Apply validation rules to text controls */
    if (control) {
      control.valueChanges
        .pipe(debounceTime(300))
        .subscribe(() => this.setValidation(control, name));
    }
  }

  /**
   * Sets validation
   * @param control control
   * @param validationName validationName
   */
  setValidation(control: AbstractControl, validationName: string): void {
    this.landingValidation[validationName].message = '';
    if ((control.touched || control.dirty) && control.errors) {
      this.landingValidation[validationName].message = Object.keys(
        control.errors
      )
        .map(
          key =>
          (this.landingValidation[
            validationName
          ].message += this.landingValidation[validationName].messages[key])
        )
        .join(' ');
    }
  }

  // Move to library or shared modules?
  private initTextControlRetrive(name: string) {
    const control = this.retriveQuoteFormGroup.get(name);
    /* Apply validation rules to text controls */
    if (control) {
      control.valueChanges
        .pipe(debounceTime(300))
        .subscribe(() => this.setValidationRetrive(control, name));
    }
  }

  /**
   * Sets validation retrive
   * @param control control
   * @param validationName validationName
   */
  setValidationRetrive(control: AbstractControl, validationName: string): void {
    this.retrivevalidation[validationName].message = '';
    if ((control.touched || control.dirty) && control.errors) {
      this.retrivevalidation[validationName].message = Object.keys(
        control.errors
      )
        .map(
          key =>
          (this.retrivevalidation[
            validationName
          ].message += this.retrivevalidation[validationName].messages[key])
        )
        .join(' ');
    }
  }

  // To handle AWS/PPC redirection
  /**
   * Checks url parameter and navigate
   */
  checkUrlParameterAndNavigate() {
    const url = this.getDecodeUrl();
    const regExp = DataConstants.REGEX;
    const pattern = regExp.URLcheck;
    this.startQuoteParams = null;
    if (url.match(pattern)) {
      let zipcode = this.moduleLandingService.getQueryString('ZIP', url);
      let quoteType = this.moduleLandingService.getQueryString('LOB', url);
      this.landingSource = this.moduleLandingService.getQueryString('SRC', url);
      const data = this.moduleLandingService.getQueryString('Data', url);
      this.ppcSource = this.moduleLandingService.getQueryString('PpcSrc', url);
      const sourceIndicator = this.moduleLandingService.getQueryString('Source_Indicator', url);
      let agentId = this.moduleLandingService.getQueryString('AOR', url);
      this.flow = this.moduleLandingService.getQueryString('Flow', url);
      this.dLRequired = this.moduleLandingService.getQueryString('dLRequired', url);
      const quoteId = this.moduleLandingService.getQueryString('quoteId', url);
      const gwTurnOnFlag = this.moduleLandingService.getQueryString('GW', url);
      this.promoId = this.moduleLandingService.getQueryString('promoid', url);
      this.campaignId = this.moduleLandingService.getQueryString('cid', url);
      const params = this.moduleLandingService.getQueryString('param', url);
      const cssEcn = this.moduleLandingService.getQueryString('encryptedECN', this.windowDirectUrl.location.href);
      const flexTurnOnFlag = this.moduleLandingService.getQueryString('Flex', url);
      if (this.isMigratedLandingEnabled) {
        const reqBodyParam = ServiceConstants.GET_REQ_BODY_PARAMS || {};
        if (Object.keys(reqBodyParam).length > 0) {
          zipcode = ServiceConstants.GET_REQ_BODY_PARAMS.Zip_Code;
          // US403006: FFQ landing page migration update the LOB for the auto insurance page naviagtions
          quoteType = ServiceConstants.GET_REQ_BODY_PARAMS.Lob ? this.getQuoteType(ServiceConstants.GET_REQ_BODY_PARAMS.Lob.toLowerCase()) :
            DataConstants.LOB.auto;
          // US322709 : Modifying code changes to accept both sourceId and Source_Id from legacy in request params
          if (ServiceConstants.GET_REQ_BODY_PARAMS.SourceID && typeof ServiceConstants.GET_REQ_BODY_PARAMS.SourceID === 'string') {
            const sourceId = ServiceConstants.GET_REQ_BODY_PARAMS.SourceID ? ServiceConstants.GET_REQ_BODY_PARAMS.SourceID : '';
            const sourceIdOther = ServiceConstants.GET_REQ_BODY_PARAMS.Source_ID ? ServiceConstants.GET_REQ_BODY_PARAMS.Source_ID : '';
            this.landingSource = sourceId || sourceIdOther;
          } else if (typeof ServiceConstants.GET_REQ_BODY_PARAMS.SourceID === 'object'
            && Array.isArray(ServiceConstants.GET_REQ_BODY_PARAMS.SourceID) && ServiceConstants.GET_REQ_BODY_PARAMS.SourceID[0]) {
            // DE76737: Unable to go to your info screen from cssts
            const sourceId = ServiceConstants.GET_REQ_BODY_PARAMS.SourceID ? ServiceConstants.GET_REQ_BODY_PARAMS.SourceID[0] : '';
            const sourceIdOther = ServiceConstants.GET_REQ_BODY_PARAMS.Source_ID ? ServiceConstants.GET_REQ_BODY_PARAMS.Source_ID[0] : '';
            this.landingSource = sourceId || sourceIdOther;
          }
          this.promoId = ServiceConstants.GET_REQ_BODY_PARAMS.promoid;
          this.campaignId = ServiceConstants.GET_REQ_BODY_PARAMS.cid;
          // US358148: add ppc src in startquote req payload for AJG flow
          this.ppcSource = ServiceConstants.GET_REQ_BODY_PARAMS.ppcSource ? ServiceConstants.GET_REQ_BODY_PARAMS.ppcSource : '';
        } else {
          if (this.moduleLandingService.getQueryString('Zip_Code', url)) {
            zipcode = this.moduleLandingService.getQueryString('Zip_Code', url);
          }
          if (this.moduleLandingService.getQueryString('Lob', url)) {
            // US322709 : Fixing QuoteType sent as empty to api for Lob Query String
            quoteType = this.getQuoteTypeFromQuery(this.moduleLandingService.getQueryString('Lob', url).toLowerCase());
          }
          if (this.moduleLandingService.getQueryString('Agent_Code', url)) {
            agentId = this.moduleLandingService.getQueryString('Agent_Code', url);
          }
          if (this.moduleLandingService.getQueryString('SourceID', url)) {
            this.landingSource = this.moduleLandingService.getQueryString('SourceID', url);
          }
          // US322709 : Modifying code changes to accept both sourceId and Source_Id from legacy in Query string
          if (this.moduleLandingService.getQueryString('Source_ID', url)) {
            this.landingSource = this.moduleLandingService.getQueryString('Source_ID', url);
          }
        }
        if (!this.landingSource) {
          // US33709 : Fetching the cookie value for source ID id not present in Request Body or Reqest Param
          const sourceIdCookie = this.cookieService.get('SourceId');
          const faSourceIdCookie = this.cookieService.get('FASourceId');
          const seSourceIdCookie = this.cookieService.get('SESourceId');
          if (sourceIdCookie || faSourceIdCookie || seSourceIdCookie) {
            this.landingSource = sourceIdCookie || faSourceIdCookie || seSourceIdCookie;
          }
        }
      }
      if (this.landingSource) {
        this.appStoreService.updateLandingSrc({ landingSrc: this.landingSource });
        if (!this.promoId && !this.campaignId) {
          this.promoId = this.campaignId = this.getPromoIdFromSrc(this.landingSource);
        }
        // US224885: Changes for promoid and cid from call only page
        this.appStoreService.updatePromoIdCid({ promoId: this.promoId, campaignId: this.campaignId });
      }
      this.appStoreService.updatePpcSrcAvailabilityStatus({ ppcSrcAvailable: this.ppcSource !== '' && this.ppcSource !== null });

      // Update the Store with the Redirection Flow
      if (this.flow) {
        this.appStoreService.updateRedirectionFlow({ redirectionFlow: this.flow, flowDataForQuote: this.flow });
      }
      // Datalayer changes starts
      this.dataLayerService.setDataLayerSourceId(this.ppcSource !== '' ? this.ppcSource : this.landingSource);
      const gaCookie = this.cookieService.get('_ga');
      if (gaCookie && gaCookie.length > 4) {
        const visitorId = gaCookie.substr(gaCookie.indexOf('.', 4) + 1);
        this.dataLayerService.setDataLayerVisitorId(visitorId);
      }
      // Datalayer changes ends

      const crosssellsrc = this.moduleLandingService.getQueryString('crosssellsrc', url);
      const crossSellLobs = this.moduleLandingService.getQueryString('crossselllobs', url);
      const lobsQuoted = this.moduleLandingService.getQueryString('lobsquoted', url);
      const propertyType = this.moduleLandingService.getQueryString('residence_type', url);


      if (crosssellsrc) {
        this.redirectToLegacySecondPage(crossSellLobs, zipcode, this.flow, quoteId, lobsQuoted, crosssellsrc, propertyType);
      } else {
        if (this.flow === FlowParamter.CROSS_SELL) {
          if (data) {
            this.quoteWithQuoteNumberReqObject.encryptedData = data;
            this.quoteWithQuoteNumberReqObject.isEncrypted = true;
          }
          sessionStorage.clear();
          this.appStoreService.updateCrossSellStatus({ crossSellStatus: true });
          sessionStorage.clear();
          // Cross Sell flow
          this.startQuotewithQuoteNumber(quoteType);
          // US275715: Update Auto Start Quote for Implementing Polling for Partner Quotes
        } else if (params) {
          this.startQuoteParams = {};
          this.startQuoteParams.params = params.replace(/\ /g, '+');
          this.startQuoteParams.isRc1Eligible = (this.flow === FlowParamter.RETRIEVE_PI_SUMMARY);
          this.startQuoteFlowWithParams();
        } else if (cssEcn && (sourceIndicator === SourceIndicator.MB || sourceIndicator === SourceIndicator.CW)) {
          /** US524087 : CSS cross-sell to FFQ Auto */
          const mdmdata = { encryptedECN: cssEcn, lob: this.getLob(quoteType), source_Indicator: sourceIndicator };
          this.redirectMDM(mdmdata);
        } else if (this.flow === FlowParamter.ONE_UI_QUOTE) {
          /** US482939: Redirection changes if quote is initiated from One UI */
          this.startQuoteParams = {};
          this.startQuoteParams.encryptedData = data ? data.replace(/\ /g, '+') : '';
          this.startQuoteParams.isEncrypted = true;
          this.startQuoteParams.isOneUIRedirectedQuote = true;
          this.startQuoteParams.sourceIndicator = this.ppcSource ? this.ppcSource : sourceIndicator;
          if (this.landingSource) {
            this.startQuoteParams.src = encodeURIComponent(this.landingSource);
          }
          this.startQuoteParams.agentId = agentId ? agentId : null;
          this.startMyQuote();
        } else if (this.flow === FlowParamter.RETRIEVE_PI_SUMMARY) {
          this.retrievePIQuote(data);
        } else if (this.flow) {
          if (data) {
            // Setting up empty values also as in AngularJS
            this.retrieveQuoteRequestObject.encryptedData = data;
            this.retrieveQuoteRequestObject.isEncrypted = true;
            this.retrieveQuoteRequestObject.quoteNumber = '';
            this.retrieveQuoteRequestObject.emailAddress = '';
            this.retrieveQuoteRequestObject.landingZipCode = '';
            this.retrieveQuoteRequestObject.dateOfBirth = this.dateService.getCurrentDate();
            if (this.flow === FlowParamter.RETRIEVE_SUBMIT) {
              this.retrieveQuoteRequestObject.flowParam = this.flow;
            }
          }
          sessionStorage.clear();
          // Retrieve Quote Flow
          sessionStorage.clear();
          this.retriveYourQuote();
        } else if ((zipcode || sourceIndicator) && (quoteType || this.isMigratedLandingEnabled)) {
          // DTM Changes to Set the ZipCode
          this.dataLayerService.setDataLayerZipCode(zipcode);
          this.dataLayerService.setDataLayerLOB(quoteType);

          this.startQuoteParams = {};
          this.startQuoteParams.encryptedData = data ? data.replace(/\ /g, '+') : '';
          this.startQuoteParams.isEncrypted = true;
          this.startQuoteParams.landingZipCode = zipcode;
          this.startQuoteParams.quoteType = quoteType;
          if (this.landingSource) {
            this.startQuoteParams.src = encodeURIComponent(this.landingSource);
          }
          this.startQuoteParams.agentId = agentId ? agentId : null;
          this.startQuoteParams.gwEnabledFromurl = gwTurnOnFlag ? gwTurnOnFlag : null;
          this.startQuoteParams.flexEnabledFromurl = flexTurnOnFlag ? flexTurnOnFlag : null;
          // US358148: add ppc src in startquote req payload for AJG flow
          this.startQuoteParams.sourceIndicator = this.ppcSource ? this.ppcSource : sourceIndicator;
          this.startQuoteParams.cid = this.campaignId;
          this.startQuoteParams.promoid = this.promoId;
          this.appStoreService.updateAppControlData({ quoteSourceIndicator: this.startQuoteParams.sourceIndicator });
          if (this.isMigratedLandingEnabled) {
            if (this.startQuoteParams.landingZipCode && this.startQuoteParams.quoteType) {
              this.startMyQuote();
            } else {
              this.landingformGroup.get('landingZipCode').setValue(zipcode);
              this.showMockLandingPage = true;
            }
          } else {
            this.startMyQuote();
          }
        } else {
          this.redirectToLegacy();
        }
      }

    } else {
      sessionStorage.clear();
      this.spinnerService.hideLoader();
      this.showMockLandingPage = true;
    }
  }

  /**
   * US524087 : CSS cross-sell to FFQ Auto
   */
  redirectMDM(mdmdata: any) {
    this.appStoreService.mdmRetrieve(mdmdata);
    this.appStoreService.getEncryptedMDMResponse$.subscribe(encryptedParam => {
      if (encryptedParam) {
        this.startQuoteParams = {};
        this.startQuoteParams.params = encryptedParam.replace(/\ /g, '+');
        this.startQuoteParams.isRc1Eligible = (this.flow === FlowParamter.RETRIEVE_PI_SUMMARY);
        this.callSegmentForMDMRetriveSuccess(mdmdata.lob, mdmdata.encryptedECN, mdmdata.source_Indicator,
          sessionStorage.getItem('windowID'));
        this.startQuoteFlowWithParams();
      }
    });
  }

  private retrievePIQuote(data: string) {
    const retrievePIQuoteRequestObject = {} as RetrieveQuoteRequestObject;
    if (this.flow !== FlowParamter.RETRIEVE_PI_SUMMARY) {
      retrievePIQuoteRequestObject.quoteNumber = data;
      retrievePIQuoteRequestObject.isEncrypted = false;
      retrievePIQuoteRequestObject.windowId = sessionStorage.getItem('windowID');
      this.flow = FlowParamter.RETRIEVE_PI_SUMMARY;
      this.appStoreService.updateRedirectionFlow({ redirectionFlow: this.flow, flowDataForQuote: this.flow });
    } else {
      this.appStoreService.updateAppControlData({ rateSummaryParam: data });
      // Device testing
      retrievePIQuoteRequestObject.encryptedData = data;
      retrievePIQuoteRequestObject.isEncrypted = true;
      retrievePIQuoteRequestObject.quoteNumber = '';
      retrievePIQuoteRequestObject.emailAddress = '';
      retrievePIQuoteRequestObject.landingZipCode = '';
      retrievePIQuoteRequestObject.dateOfBirth = this.dateService.getCurrentDate();
    }
    sessionStorage.clear();
    this.appStoreService.retrievePIRateSummary(retrievePIQuoteRequestObject);
    this.subscriptions.push(this.appStoreService.getPIControlData$.subscribe((ctrlData: any) => {
      if ('success' === ctrlData.retrieveRateSummary && 'success' === ctrlData.projectCodeServiceStatus
        && 'success' === ctrlData.staticContentServiceStatus) {
        const selectedLOB = this.getLob(this.getQuoteType(this.lob));
        this.subscriptions.push(
          this.appStoreService.getExistingCustomerData$.subscribe((cusata: any) => {
            if ('Y' === cusata.isExistingCust && (cusata.existingLobs && cusata.existingLobs.indexOf('A') !== -1)) {
              this.router.navigate(['/existing']);
            } else {
              this.router.navigate([selectedLOB + this.getModuleRoute(selectedLOB, ctrlData.lastVisitedPage)],
                { queryParams: { Flow: this.flow, LOB: selectedLOB } });
            }
          })
        );
      }
    }));
    this.handleRetrieveErrorResponse();
  }

  redirectToLegacy() {
    if (!ServiceConstants.IS_MOCKPAGE_VIEW_REQUIRED) {
      sessionStorage.clear();
      window.location.href = ServiceConstants.LEGACY_URL + ServiceConstants.LEGACY_URL_COMMON;
    } else {
      this.showMockLandingPage = true;
    }
  }

  /**
   * Get The Phone numbers from AEM
   */
  getAEMPhoneNumber() {
    let isAemTriggred = false;
    this.appStoreService.getLandingSourceFromSrc$.subscribe((landingSrc: any) => {
      this.landingSource = landingSrc;
      if (this.landingSource && !isAemTriggred) {
        if (!this.lob || this.lob === undefined) {
          this.appStoreService.getLob$.subscribe((lob: any) => {
            this.lob = lob;
          }).unsubscribe();
        }
        isAemTriggred = true;
        // tslint:disable-next-line: max-line-length
        this.appStoreService.getSourcePhoneNumber({ lob: this.getQuoteType(this.lob) });
      }
    });
  }

  /**
   * Start Quote With Quote number
   */
  startQuotewithQuoteNumber(quoteType: string) {
    quoteType = (quoteType === null) ? 'A' : quoteType;
    if (!this.quoteWithQuoteNumberReqObject.isEncrypted) {
      this.subscriptions.push(
        this.appStoreService.appData$.subscribe(data => {
          this.quoteWithQuoteNumberReqObject = JSON.parse(JSON.stringify(this.quoteWithQuoteNumberReqObject));
          this.quoteWithQuoteNumberReqObject.agentId = data && data.agentData.agent ? data.agentData.agent.agentId : null;
          this.quoteWithQuoteNumberReqObject.lastName = data ? data.customerData.customer.lastName : null;
          this.quoteWithQuoteNumberReqObject.dateOfBirth = this.datePipe.transform(data.customerData.customer.dateOfBirth, 'MM/dd/yyyy');
          this.quoteWithQuoteNumberReqObject.quoteNumber = data ? data.quoteNumber : null;
          this.quoteWithQuoteNumberReqObject.landingZipCode = data ? data.landingZipCode : null;
          this.quoteWithQuoteNumberReqObject.src = data ? data.src : null;
        }));

      this.quoteWithQuoteNumberReqObject.encryptedData = '';
      this.quoteWithQuoteNumberReqObject.isEncrypted = false;
      this.quoteWithQuoteNumberReqObject.quoteWindowId = '';
    }
    // Setting the Quote Type
    this.quoteWithQuoteNumberReqObject.quoteType = quoteType;
    // Call Start Quote with Quote Number
    if (this.quoteWithQuoteNumberReqObject.isEncrypted || this.quoteWithQuoteNumberReqObject.landingZipCode) {
      this.appStoreService.startQuoteWithQuoteNumber(this.quoteWithQuoteNumberReqObject);
    }
    this.subscriptions.push(this.appStoreService.getAppControlValues$.subscribe((data: any) => {
      /** US484239:Changes for routing to oneUI if isOneUIEnabled true for Crossell Scenario */
      this.isOneUIEnabled = data?.isOneUIEnabled;
      if (this.isOneUIEnabled && !this.isRetrieveQuote) {
        this.legacyRedirectionService.redirectToOneUI(data?.redirectionParams);
        return;
      }
    }));
    this.subscriptions.push(this.appStoreService.getAppControlData$.subscribe((appStatus: any) => {
      let AEMContentServiceCallStatus = null;
      this.appStoreService.getAEMContentServiceStatus$.subscribe(AEMContentStatus => {
        AEMContentServiceCallStatus = AEMContentStatus;
      }).unsubscribe();
      if (appStatus.startQuoteWithQuoteNumber === 'success' && 'success' === appStatus.projectCodeServiceStatus
        && 'success' === AEMContentServiceCallStatus) {
        this.navHelper.populateInitialNavigationData(quoteType);
        this.navDrawer.showHeader();
        this.navDrawer.showQuoteNumber();
        this.redirectToFirstPage(quoteType);
      }
    })
    );
  }

  /**
   * Retrieves error data from store
   */
  retrieveErrorDataFromStore() {
    this.subscriptions.push(this.appStoreService.appError$.subscribe((errorObj: any) => {
      if (errorObj && Object.keys(errorObj).length !== 0) {
        // tslint:disable-next-line: max-line-length
        this.restErrorHandlerService.checkRestResponse(errorObj.errorInfo.errorCode, this.landingformGroup.value.landingZipCode, this.landingformGroup.value.quoteType);
      }
    }));
  }

  handleErrorsStartMyQuote(errorObj) {
    // US349514: Adding Segment tracking for Error Scenarios
    this.segmentService.trackErrorOccurred(errorObj);
    const errCodes = DataConstants.ERROR_CODES.errorMsgCode;
    const errcodeIntermediate = DataConstants.ERROR_CODES.errorIntermediatePage;
    const errCodestate = DataConstants.ERROR_CODES.errorMsgCodeState;
    if (this.flow === FlowParamter.RETRIEVE_PI_SUMMARY) {
      this.legacyRedirectionService.redirectToLegacy([]);
      return;
    }
    const stateCode = errorObj.errorInfo.state;
    if (errorObj.errorInfo.errorCode === 'EG031') {
      // tslint:disable-next-line: max-line-length
      this.callSegementForRedirectedQuotes(errorObj.errorInfo.quoteNumber, ServiceConstants.FARMERS_HAWAII_URL, 'FarmersHawaiiRedirection');
      window.location.replace(ServiceConstants.FARMERS_HAWAII_URL);
    } else if (errorObj.errorInfo.errorCode === 'EG035') {
      this.callSegmentForMDM(errorObj.errorInfo.errorCode, errorObj.errorInfo.lob,
        errorObj.errorInfo.displayContent, errorObj.errorInfo.state,sessionStorage.getItem('windowID'));
      window.location.href = ServiceConstants.AUTO_URL;
    } else if (errorObj.errorInfo.errorCode === 'EG001'
      || errorObj.errorInfo.errorCode === 'EG006') {
      this.router.navigate(['/inconvenience']);
    } else if (errorObj.errorInfo.errorCode === 'EG024') {
      // US228972: Changes for the metlife non legacy zip code redirection
      if (!this.landingSource) {
        this.landingSource = errorObj.errorInfo.src;
        this.promoId = this.campaignId = this.getPromoIdFromSrc(this.landingSource);
      }
      this.callSegementForRedirectedQuotes(errorObj.errorInfo.quoteNumber, ServiceConstants.FWS_CALL_ONLY_PAGE, 'MetLifeRedirection');
      this.legacyRedirectionService.redirectToFWSorFarmersChoice(ServiceConstants.FWS_CALL_ONLY_PAGE, errorObj.errorInfo.quoteNumber
        , this.landingSource, this.promoId, this.campaignId, '', null, null);
    } else if (errorObj.errorInfo.errorCode === 'EG003') {
      // US322709 : Migration of Landing page to FFQ
      this.showMockLandingPage = true;
      this.yourInfoStoreService.UpdateAllServiceData();
      this.showZipErr = true;
      this.errFromResponse = DataConstants.ERROR_CODES.errorMsgValidZip;
      this.landingformGroup.controls.landingZipCode.setValue(errorObj.errorInfo.zipCode);
      this.landingformGroup.controls.landingZipCode.markAsTouched();
    } else if (errCodes.indexOf(errorObj.errorInfo.errorCode) > -1) {
      this.showMockLandingPage = true;
      // Changes for showing error msg on the landing page
      this.yourInfoStoreService.UpdateAllServiceData();
      this.showZipErr = true;
      this.errFromResponse = errorObj.errorInfo.errorMessage;
      if (errCodestate.indexOf(errorObj.errorInfo.errorCode) > -1) {
        this.errFromResponse = this.errFromResponse.replace('{0}', stateCode);
      }
      this.landingformGroup.controls.landingZipCode.setValue(errorObj.errorInfo.zipCode);
      this.landingformGroup.controls.landingZipCode.markAsTouched();
    } else if (errcodeIntermediate.indexOf(errorObj.errorInfo.errorCode) > -1) {
      /** F49739: CA Auto FFQ - Block Online Quote for consumers from organic and direct-to-site traffic */
      // DE75877: In RA11, Home Renters and Condo, Life LOB HI state, page is first redirecting to intermediate page then Hawaii page
      /** US566600: Digital Monitization Routing Changes */
      if (errorObj.errorInfo.errorCode === 'EG033') {
        this.appStoreService.updateAppData({
          landingZipCode: errorObj.errorInfo.zipCode,
          landingStateCode: errorObj.errorInfo.state,
          quoteType: errorObj.errorInfo.lob
        });
        this.trackDirectOnlineQuoteBlocked(errorObj);
        if (errorObj.errorInfo.showPopUpType === 'BLOCKMP') {
          this.router.navigate(['/intermediatepage']);
        } else {
          this.router.navigate(['/interstitial']);
        }
      } else {
        this.router.navigate(['/intermediatepage']);
      }
    } else if (errorObj.errorInfo.errorCode === 'EG015') {
      const quoteInfo: QuoteInfoParams = {
        lob: this.landingformGroup.value.quoteType,
        zip: errorObj.errorInfo.zipCode,
        state: errorObj.errorInfo.state
      };
      /** US521505 - Modified segment event for farmersDirectUrl redirection */
      this.callSegementForRedirectedQuotes(errorObj.errorInfo.quoteNumber, ServiceConstants.FARMERS_DIRECT_URL,
        'FarmersDirectRedirection', quoteInfo);
      window.location.href = ServiceConstants.FARMERS_DIRECT_URL;

    } else if (errorObj.errorInfo.errorCode === 'EG008') {
      if (this.landingformGroup.value.quoteType === 'H') {
        // goToHomeQuoteLabPage
        this.router.navigate(['/intermediatepage']);
      } // DE74764: to avoid redirection to inconvenience page
    } else if (errorObj.errorInfo.errorCode !== DataConstants.ERROR_CODES.errorQuoteNotFound) {
      this.restErrorHandlerService.checkRestResponse(errorObj.errorInfo.errorCode,
        this.landingformGroup.value.landingZipCode, this.landingformGroup.value.quoteType);
    }

  }

  /**
   * Segment tracking for blocked online quotes
   * @param errorObj: Error Object
   */
  trackDirectOnlineQuoteBlocked(errorObj: any): void {
      const failureInfo = {
        error_code: errorObj.errorInfo.errorCode,
        error_msg: errorObj.errorInfo.errorMessage,
        point_of_error: '',
        company_code: 'F',
        source_indicator: this.startQuoteParams?.sourceIndicator,
        zip: errorObj.errorInfo.zip,
        lob: errorObj.errorInfo.lob,
        state: errorObj.errorInfo.state,
        quote_number: 'NA'
      };
      this.segmentService.trackQuoteFailed(failureInfo);
  }

  redirectToLegacySecondPage(crossSellLobs, zipcode, flow, quoteId, lobsQuoted, crosssellsrc, propertyType) {
    const parameterCollection = [];
    const params1 = {};
    params1[`name`] = 'crossselllobs';
    params1[`value`] = crossSellLobs;
    if (params1[`value`] === 'L') {
      params1[`value`] = 'LC';
    }
    const params2 = {};
    params2[`name`] = 'zip';
    params2[`value`] = zipcode;
    const params3 = {};
    params3[`name`] = 'flow';
    params3[`value`] = 'CS';
    const params4 = {};
    params4[`name`] = 'quotenumber';
    params4[`value`] = quoteId;

    const params5 = {};
    if (flow === FlowParamter.CROSS_SELL) {
      params5[`name`] = 'quotedlobs';
      params5[`value`] = lobsQuoted;
    } else {
      params5[`name`] = 'quotedlobs';
      params5[`value`] = 'A';
    }
    const params6 = {};
    params6[`name`] = 'crosssellsrc';
    params6[`value`] = crosssellsrc;

    const params7 = {};
    params7[`name`] = 'propertytype';
    params7[`value`] = propertyType;

    if (params1[`value`] !== undefined) {
      parameterCollection.push(params1);
      parameterCollection.push(params2);
      parameterCollection.push(params3);
      parameterCollection.push(params4);
      parameterCollection.push(params5);
      parameterCollection.push(params6);
      parameterCollection.push(params7);
      this.legacyRedirectionService.redirectToLegacy(parameterCollection);
    }
  }

  /**
   * on destroy
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  getDecodeUrl() {
    return decodeURIComponent(window.location.href.replace(/%20/g, '+'));
  }

  /**
   * for retrieve the entire URL params as a single string
   * @returns  urlparams
   */
  getDecodeUrlParamAsString() {
    return decodeURIComponent(window.location.search.substring(1).replace(/%20/g, '+'));
  }

  /**
   * Calls app render services
   */
  callAppRenderServices() {
    this.subscriptions.push(this.appStoreService.getInitAppRenderServices$.subscribe(data => {
      if (data === ServiceCallStatus.READY) {
        this.appStoreService.updateAppControlData({ initAppRenderServices: ServiceCallStatus.TRIGGERED });
        this.projectCodeStatusIsCalled = true;
        this.spinnerService.hideFarmersLoader();
        this.appStoreService.getProjectStatusCode(this.getEffectiveDate());
        this.appStoreService.getLandingStateCode$.subscribe(stateCode => {
          if (stateCode === 'MA') {
            this.appStoreService.getFetchTownList();
          }
        }).unsubscribe();
        /** checking whether the AEM Content service is needed */
        this.appStoreService.isAEMContentCallNeeded$.subscribe(serviceCallRequired => {
          if (serviceCallRequired) {
            this.appStoreService.getAEMStaticContent();
          }
        }).unsubscribe();
        /** US169265: call aem phone number service in RQ flow also */
        this.getAEMPhoneNumber();
        this.retrieveErrorDataFromStore();
      }
    }));
  }

  checkLmsRedirectRequired(): boolean {
    let isLmsRedirect = false;
    this.appStoreService.getLmsRedirectData$.subscribe(lmsData => {
      if (lmsData && lmsData.isLmsRedirect) {
        isLmsRedirect = lmsData.isLmsRedirect;
        sessionStorage.setItem('windowID', lmsData.windowId);
      }
    }).unsubscribe();
    this.isLmsRedirectChecked = true;
    return isLmsRedirect;
  }

  /**
   * Gets promo id from src
   * @param landingSrcIndicator: landing source indicator
   * @returns promo id from src
   */
  getPromoIdFromSrc(landingSrcIndicator: string): string {
    return landingSrcIndicator.substr(landingSrcIndicator.length - 5);
  }

  /**
   * Segement tracking call
   */
  callSegementForRedirectedQuotes(quoteNumber: string, url: string, type: string, errorInfo?: QuoteInfoParams) {
    const redirectionInfo: QuoteRedirectedProps = {
      redirection_url: url,
      redirection_type: type,
      point_of_redirection: LastVisitedStepForSegment.Auto_YourInfo,
      ...errorInfo
    };
    this.segmentService.trackQuoteRedirected(redirectionInfo);
  }

  /** F39660 : CSS to FFQ Cross sell changes */
  callSegmentForMDM(errorCode: string, lineOfBusiness: string, displayContent: string, stateCode: string, windowId: string) {
    const redirectionInfo: QuoteFailedProps = {
      error_code: errorCode,
      error_msg: displayContent,
      point_of_error: 'MDM Service',
      lob: lineOfBusiness,
      state: stateCode,
      quote_number: windowId
    };
    this.segmentService.callSegmentForMDMErrors(redirectionInfo);
  }

  callSegmentForMDMRetriveSuccess(lob: string, encryptedECN: string, sourceIndicator: string, windowId: string): void {
    const mdmRetriveInfo: MDMRetrievalParams = {
      flow_type: 'CSS',
      encrypted_ecn: encryptedECN,
      lob: lob,
      source_indicator: sourceIndicator,
      quote_number: windowId
    };
    this.segmentService.callSegmentForMDMRetrievalInitiated(mdmRetriveInfo);
  }

  startQuoteFlowWithParams(): void {
    if (!this.startQuoteParams.isRc1Eligible) {
      this.startMyQuote();
    } else {
      const pollingArr = environment.polling.split('|');
      const timeOutVal: number = (parseInt((pollingArr[this.pollingStatus++]), 10)) * 1000;
      if (timeOutVal) {
        const isPolling = this.pollingStatus < pollingArr.length ? true : false;
        const quoteType = 'A';
        this.pollingStart = true;
        setTimeout(() => {
          this.startQuoteParams = { ...this.startQuoteParams, isPolling, quoteType };
          this.spinnerService.showFarmersLoader();
          this.startMyQuote();
          this.pollingStart = false;
        }, timeOutVal);
      }
    }

  }
  startQuoteResponseSubscribe(): void {
    let controlDataSub: Subscription;
    this.subscriptions.push(this.appStoreService.appError$.subscribe((errorObj: any) => {
      if (errorObj && Object.keys(errorObj).length !== 0) {
        this.handleErrorsStartMyQuote(errorObj);
      }
    }));

    controlDataSub = this.appStoreService.getAppControlValues$.subscribe((data: any) => {
      /** F42587: Zip/State Quote knockout from Agent website */
      if (data?.knockoutIndicator) {
        /** DE126587: Fix for not displaying AEM Content inside schedule appointment for zipstate knockout scenario */
        this.appStoreService.getAEMStaticContent();
        controlDataSub?.unsubscribe();
        this.router.navigate(['/auto/knockout']);
      }
      /** US489826:Changes for routing to oneUI if isOneUIEnabled true */
      this.isOneUIEnabled = data?.isOneUIEnabled;
      if (this.isOneUIEnabled && !this.isRetrieveQuote) {
        controlDataSub?.unsubscribe();
        this.legacyRedirectionService.redirectToOneUI(data?.redirectionParams);
        return;
      }
      /**
       * On Success Call of StartQuote Service
       * Project Status Service call needs to be called
       */
      /** US348316: retrieve migration navigation issue fix */
      if (!this.isRetrieveQuote) {
        // Check to redirect to respective url if quote number is not generted
        if (data && this.isMigratedLandingEnabled && data[`redirectionParams`] && !data[`quoteNumber`]) {
          if (data && data[`lob`] === 'home' && data[`isHqmEnabled`] === true) {
            /** US367786 : brower back issue fix -landing page migration */
            sessionStorage.setItem('isLegacyOrHQMQuote', 'true');
            window.location.href = ServiceConstants.HQM_URL + data[`redirectionParams`];
          } else {
            /** US367786 : brower back issue fix -landing page migration */
            sessionStorage.setItem('isLegacyOrHQMQuote', 'true');
            window.location.href = ServiceConstants.LEGACY_URL + ServiceConstants.LEGACY_URL_COMMON + data[`redirectionParams`];
          }
        } else if (data && data[`quoteNumber`]) {
          // Check this is a PI LMS quote with lmsredirect is true.
          // If true need to call retreive summary service
          if (!this.isLmsRedirectChecked && this.checkLmsRedirectRequired()) {
            this.flow = '';
            this.retrievePIQuote(data[`quoteNumber`]);
          } else {
            const quoteType = this.getQuoteType(this.lob);
            let staticContentServiceStatus = null;
            // US322709: Change for landing page migration if src id not present
            if (this.isMigratedLandingEnabled && !this.landingSource && data[`redirectionParams`]) {
              const parms = data[`redirectionParams`].split('&');
              this.setlandingSRCFromParams(parms);
            }
            // tslint:disable-next-line: max-line-length
            this.appStoreService.getAEMContentServiceStatus$.subscribe(AEMContentstatus => { staticContentServiceStatus = AEMContentstatus; }).unsubscribe();
            if ((data && data[`projectCodeStatus`] && Object.keys(data[`projectCodeStatus`]).length !== 0)
              && 'success' === staticContentServiceStatus) {
              // DTM  : Datalayer changes to Set the Quote number
              this.dataLayerService.setDataLayerQuoteNumber(data[`quoteNumber`]);
              this.dataLayerService.setDataLayerStateCode(data[`landingStateCode`]);
              controlDataSub.unsubscribe();
              this.navHelper.populateInitialNavigationData(quoteType);
              this.redirectToFirstPage(quoteType);
            }
          }
        } else if ((data && data[`isRc1Eligible`]) && !this.pollingStart) {
          this.startQuoteParams = {
            ...this.startQuoteParams,
            src: encodeURIComponent(data[`src`]),
            sourceIndicator: data[`sourceIndicator`],
            landingZipCode: data[`landingZipCode`],
            quoteType: data[`quoteType`]
          };
          this.startQuoteFlowWithParams();
        }
      }
    });
  }

  // US30711: Changes to select the lob on click event
  callLObSelect(lob: string): void {
    this.landingformGroup.get('lob').setValue(lob);
    this.showProductErr = false;
    
    /* US743638 - Address the ADA requirements to retain the keyboard focus on the Product
     selection until user moves the focus voluntarily
    const landingElem = this.document.querySelector('#ffq-landing-zip-code input');
    if (landingElem) {
      (landingElem as HTMLElement).focus();
    }*/
  }

  createSSLImage(): void {
    const cid = document.createElement('script');
    cid.async = true;
    cid.src = '//seal.digicert.com/seals/cascade/seal.min.js';
    const s = document.getElementsByTagName('script');
    const ls = s[(s.length - 1)];
    ls.parentNode.insertBefore(cid,
      ls.nextSibling);
    const cleartimer = setInterval(() => {
      if (document.getElementById('DigiCertClickID_Z9bZfwjLSeal')) {
        document.getElementById('DigiCertClickID_Z9bZfwjLSeal')
          .getElementsByTagName('img')[0].alt =
          'Digicert Secure Trusted Click to Verify opens in a new window';
        clearInterval(cleartimer);
      }
    }, 100);

  }

  /**
   * Setlanding srcfrom params
   * @ param parms parameter from requiredparams in startQuote response
   */
  setlandingSRCFromParams(parms: string[]): void {
    for (const val of parms) {
      if (val.startsWith('SRC')) {
        const srcInd = val.split('=');
        this.landingSource = srcInd[1];
      }
    }
    if (this.landingSource) {
      this.appStoreService.updateLandingSrc({ landingSrc: this.landingSource });
      this.appStoreService.updatePromoIdCid({ promoId: this.promoId, campaignId: this.campaignId });
    }
  }

  /**
   * Determines whether form group changes on
   */
  onFormGroupChanges(): void {
    this.landingformGroup.get('landingZipCode').valueChanges.pipe(distinctUntilChanged()).subscribe(newZipCode => {
      if (!this.landingformGroup.controls.landingZipCode.valid && this.showZipErr) {
        this.showZipErr = false;
      }
    });
    this.retriveQuoteFormGroup.get('dateOfBirth').valueChanges.subscribe(val => {
      const result = this.dateValidatorService.validateAge(val, true);
      if (result?.inValidDate || result?.minLength) {
        this.retriveQuoteFormGroup.get('dateOfBirth').setErrors({ pattern: true });
      } else if (result?.ageLimit) {
        this.retriveQuoteFormGroup.get('dateOfBirth').setErrors({ ageLimit: true });
      }
    });
  }

  /**
   * Segment Changes for Redirection Params
   */
  callSegmentForRedirectionParams(): void {
    let redirectionData;
    this.appStoreService.getAppControlValues$.pipe(take(2)).subscribe((data) => {
      redirectionData = data;
      if (redirectionData && redirectionData[`redirectionParams`]) {
        let query = redirectionData[`redirectionParams`];
        let redirectionParamsInfo: RedirectionParams;
        query = query.split('&');
        query.map((key: string) => {
          key = key.replace('?', '');
          redirectionParamsInfo = {
            ...redirectionParamsInfo,
            [`redirectionParams_${key.split('=')[0]?.toLocaleLowerCase()}`]: key.substring(key.indexOf('=') + 1)
          };
        });
        this.segmentService.trackQuoteRouted(redirectionParamsInfo);
      }
    });
  }

  /**
   * US348400: Redirect to Legacy Quotes
   */
  redirectToLegacyHQMorOneUI(res: RetrieveQuoteResObject): string {
    const homeLOBs = ['H', 'R', 'C'];
    if (this.isMigratedLandingEnabled && res && res.rtrvRedirectReqd) {
      // US355524 : Handling redirection  for retrieve Quote to legacy, fws and HQM
      if (res.redirectionParams) {
        if (res.lob === 'H' && res.hqmenabled === true && !res.isOneUIEnabled) {
          /** US367786 : brower back issue fix -landing page migration */
          sessionStorage.setItem('isLegacyOrHQMQuote', 'true');
          this.legacyRedirectionUrl = ServiceConstants.HQM_URL + res.redirectionParams;
        } else if (res.isOneUIEnabled === true && (res.lob === 'A' || homeLOBs.includes(res.lob))) {
          sessionStorage.setItem('isLegacyOrHQMQuote', 'true');
          if (this.flow === FlowParamter.RETRIEVE_QUOTE) {
            this.legacyRedirectionUrl =
              ServiceConstants.ONE_UI_URL +
              res.redirectionParams +
              '&rfls=true';
          } else {
            this.legacyRedirectionUrl =
              ServiceConstants.ONE_UI_URL + res.redirectionParams;
          }
        } else {
          /** US367786 : brower back issue fix -landing page migration */
          sessionStorage.setItem('isLegacyOrHQMQuote', 'true');
          this.legacyRedirectionUrl = ServiceConstants.LEGACY_URL + ServiceConstants.LEGACY_URL_COMMON + res.redirectionParams;
        }
      } else if (res.fwsRedirectionUrl) {
        /** US367786 : brower back issue fix -landing page migration */
        sessionStorage.setItem('isLegacyOrHQMQuote', 'true');
        this.legacyRedirectionUrl = res.fwsRedirectionUrl;
      }
    }
    return this.legacyRedirectionUrl;
  }
  /**
   * DE73991: In Landing Page we are Unable to Change the Zip Code(or) LOB
   */
  updateLatestZipLobValue(): void {
    if (this.landingformGroup.valid && this.startQuoteParams) {
      this.startQuoteParams = {
        ...this.startQuoteParams, landingZipCode: this.landingformGroup.value.landingZipCode, lob: this.landingformGroup.value.lob,
        quoteType: this.getQuoteType(this.landingformGroup.value.lob)
      };
    }
  }
  /**
   * get lob title for lobs
   * @param lob lob
   * @returns lob title
   */

  getLobTitle(lob: string): string {
    switch (lob) {
      case 'Auto': return DataConstants.LANDING_PAGE_LOB_TITLES.Auto;
      case 'Home': return DataConstants.LANDING_PAGE_LOB_TITLES.Home;
      case 'Life': return DataConstants.LANDING_PAGE_LOB_TITLES.Life;
      case 'Business': return DataConstants.LANDING_PAGE_LOB_TITLES.Business;
      case 'Renters': return DataConstants.LANDING_PAGE_LOB_TITLES.Renters;
      case 'Condo': return DataConstants.LANDING_PAGE_LOB_TITLES.Condo;
      default: return '';
    }
  }
  /**
   * get gtm tag for lob options
   * @param lob lob
   * @returns gtm tag
   */

  getLobGtmTag(lob: string): string {
    switch (lob) {
      case 'Auto': return DataConstants.LANDING_PAGE_LOB_GTM_TAGS.Auto;
      case 'Home': return DataConstants.LANDING_PAGE_LOB_GTM_TAGS.Home;
      case 'Life': return DataConstants.LANDING_PAGE_LOB_GTM_TAGS.Life;
      case 'Business': return DataConstants.LANDING_PAGE_LOB_GTM_TAGS.Business;
      case 'Renters': return DataConstants.LANDING_PAGE_LOB_GTM_TAGS.Renters;
      case 'Condo': return DataConstants.LANDING_PAGE_LOB_GTM_TAGS.Condo;
      default: return '';
    }
  }

}
