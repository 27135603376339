import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as fromAutoState from './auto.state';
import * as fromAutoActions from './auto.actions';
import * as fromAppActions from '@ffq-app-store/app.actions';
import { Store } from '@ngrx/store';

import { mergeMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { YourInfoService } from '../your-info/services/your-info.service';
import { FilterStoreStateService, DataService } from '@ffq-app-core';
import { FlowIndentifierEnum, RateQuoteProgressStatus, ModuleProgressStatus } from '@ffq-app-shared/enums/flow.identifier.enum';
import { PrefillData, AutoData, Vehicle, Driver } from '@ffq-app-shared/model/common.data.model';
import { HelperService } from '@ffq-app-shared/services/helper.service';
import { KnockOutService } from '@ffq-app-auto/shared/services/knock-out.service';
import { StateRuleModelService } from '@ffq-app-shared/services/state-rule-model.service';
import { DateService } from '@ffq-lib/src/lib/core/services/date.service';
import { SegmentService } from '@ffq-app-shared/services/segment.service';
import { IdentifyTraits } from '@ffq-app-shared/model/segment.data.model';


@Injectable()
export class AutoEffects {

    /**
     * Creates an instance of auto effects.
     * @param actions$ Actions
     * @param store Store
     * @param yourInfoService YourInfoService
     * @param filterStoreStateService FilterStoreStateService
     * @param dataService DataService
     * @param helperService HelperService
     */
    constructor(
        private actions$: Actions,
        private state: Store<fromAutoState.AutoState>,
        private yourInfoService: YourInfoService,
        private filterStoreStateService: FilterStoreStateService,
        private dataService: DataService,
        private helperService: HelperService,
        private knockOutService: KnockOutService,
        private stateRuleService: StateRuleModelService,
        private dateService: DateService,
        private segmentService: SegmentService,
    ) { }

    /**
     * Prefill data$ of auto effects
     */
    prefillData$ = createEffect(() => this.actions$.pipe(
        ofType(fromAutoActions.PrefillData),
        withLatestFrom(this.state.select((state: any) => state.auto.data)),
        mergeMap((actionPayloadAndStoreData: any) =>
            this.yourInfoService.getPrerefillData$(actionPayloadAndStoreData[0].reqPayload).pipe(
                map(response => fromAutoActions.PrefillDataSuccess(
                    this.filterStoreStateService.filterResponse(
                        {
                            respData: this.modifyPrefillRes(actionPayloadAndStoreData[1], response), ctrlData:
                                { prefillData: 'completed' }, respErr: {},
                        }, FlowIndentifierEnum.PREFILL_DATA)

                )),
                catchError(err => of(fromAutoActions.PrefillDataFail({ respErr: err, ctrlData: { prefillData: 'failed' } })))
            )
        )
    )
    );

    /**
     * Rate quote$ of quote effects
     */
    rateQuote$ = createEffect(() => this.actions$.pipe(
        ofType(fromAutoActions.PerformDefaultRateQuote),
        mergeMap(action =>
            this.dataService.performRateQuote$(action.reqPayload, 'default').pipe(
                mergeMap(response => [
                        fromAutoActions.UpdateAutoControlData({ctrlData: {isBrightLinedFromCreditCheck:
                        action.reqPayload.rateBU && action.reqPayload.rateBU === 'BW' ? true : response.brightlinedIndicator,
                        secondRateCallCompleted: false,
                        isHouseAccount: response.isHouseAccount,
                        flHouseAccount: response.flHouseAccount,
                        eligibleForAutoRentersBundle: response.eligibleForAutoRentersBundle &&
                        // tslint:disable-next-line: max-line-length
                        this.stateRuleService.checkCustomQuoteImplForState(action.reqPayload.rateBU && action.reqPayload.rateBU === 'BW')}}),
                    fromAppActions.UpdateAppControlData({
                        ctrlData:
                            { defaultRateQuoteCalled: RateQuoteProgressStatus.DEFAULT_COMPLETED, flowDataForQuote: null,
                                 bwPopUpSkipped: false, /* setting the bwPopUp skipped to false */
                                 packageSelected: response.knockOutInd === true ? null :
                                 response.persAutoPolicy.vehicle[0].persAutoCoverage.packageSelected}
                    }),
                    fromAutoActions.PerformDefaultRateQuoteSuccess(
                        this.filterStoreStateService.filterResponse(
                            {
                                respData: response,
                                serviceCtrlData: { defaultPerformRate: 'completed' }
                            }, FlowIndentifierEnum.RATE_QUOTE)

                    ),
                    fromAutoActions.UpdatePrequalifiedDiscountList({
                        prequalifiedDiscounts: this.dataService.populateDiscountsFromRateResponse(response)
                    })
                ]),
                /** Updating the "secondRateCallStatus: true" to avoid the unwanted re-rentering in quote page due to subscription
                 *  which makes console errors
                 */
                catchError(err => of(fromAutoActions.PerformRateQuoteFail({ respErr: Object.assign({}, { service: 'PerformRate' }, err),
                 ctrlData: { performRate: 'failed', secondRateCallStatus: true } })))
            )
        )
    )
    );

    /**
     * Vin look up service call
     */
    vinLookUp$ = createEffect(() => this.actions$.pipe(ofType(fromAutoActions.VINLookup),
        mergeMap(action =>
            this.dataService.vinLookUp$(action.reqPayload).pipe(
                map(response =>
                    fromAutoActions.VINLookupSuccess({ respData: response })),
                catchError(err => of(fromAutoActions.ServiceFail({ respError: { service: 'VINLookUp', ...err } })))
            )
        )
    )
    );

    /**
     * Save vehicle service of auto
     */
    saveVehicleDetails$ = createEffect(() => this.actions$.pipe(ofType(fromAutoActions.SaveVehicle),
        mergeMap((action) =>
            this.dataService.saveVehicle$(action.reqPayload).pipe(
                mergeMap(response => [
                    // updating the error count to zero on success of service
                    fromAutoActions.UpdateAutoControlData({ ctrlData: {errorCount: 0} }),
                    // updating the prequalified discounts in store
                    fromAutoActions.UpdatePrequalifiedDiscountList(
                      {
                        prequalifiedDiscounts: this.dataService.populatePrequalifiedDiscounts(response)
                      }),
                    // tslint:disable-next-line:max-line-length
                    fromAutoActions.SaveVehicleSuccess({ reqPayload: action.reqPayload.vehicle, serviceCtrlData: { saveVehicleDetails: 'success' } }),
                    fromAppActions.ClearZipValidationErrorMessage()
                ]),
                catchError(err => [ fromAutoActions.IncrementErrorCount(),
                    fromAutoActions.ServiceFail({ respError: { service: 'AddVehicle', ...err } })])
            )
        )
    )
    );


    /**
     * Delete vehicle service of auto
     */
    deleteVehicleDetails$ = createEffect(() => this.actions$.pipe(ofType(fromAutoActions.DeleteVehicle),
        mergeMap((action) =>
            this.dataService.saveVehicle$(action.reqPayload).pipe(
                mergeMap(response => [
                     // updating the error count to zero on success of service
                     fromAutoActions.UpdateAutoControlData({ ctrlData: {errorCount: 0} }),
                    // updating the prequalified discounts in store
                    fromAutoActions.UpdatePrequalifiedDiscountList(
                        {
                            prequalifiedDiscounts: this.dataService.populatePrequalifiedDiscounts(response)
                        }
                    ),
                    // tslint:disable-next-line:max-line-length
                    fromAutoActions.DeleteVehicleSuccess({ reqPayload: action.reqPayload.vehicle, serviceCtrlData: { deleteVehicleDetails: 'success' } }),
                ]),
                catchError(err => [ fromAutoActions.IncrementErrorCount(),
                    fromAutoActions.ServiceFail({ respError: { service: 'DeleteVehicle', ...err } })])
            )
        )
    )
    );

    saveActionForTenedicaService$ = createEffect(() => this.actions$.pipe(ofType(fromAutoActions.SaveActionFireAndForget),
        mergeMap(action =>
            this.dataService.invokeVehicleSaveActionsService(action.reqPayload).pipe(
                map(response => fromAutoActions.SaveActionFireAndForgetSuccess({ serviceCtrlData: { tenedicaSave: 'success' } }),
                    catchError(err => of(fromAutoActions.SaveActionFireAndForgetFail({ respError: { service: 'saveAction', ...err } })))
                )
            )
        )
    ));

    saveActionForNC$ = createEffect(() => this.actions$.pipe(ofType(fromAutoActions.SaveActionWaitAndFire),
        mergeMap(action =>
            this.dataService.invokeVehicleSaveActionsService(action.reqPayload).pipe(
                map(response => fromAutoActions.SaveActionWaitAndFireSuccess({ serviceCtrlData: { NCSubscription: 'success' } }),
                    catchError(err => of(fromAutoActions.SaveActionWaitAndFireFail({ respError: { service: 'saveActionforNC', ...err } })))
                )
            )
        )
    ));


    /**
     * Edit vehicle service of auto effects
     */
    editVehicleDetails$ = createEffect(() => this.actions$.pipe(ofType(fromAutoActions.EditVehicle),
        mergeMap((action) =>
            this.dataService.saveVehicle$(action.reqPayload).pipe(
                mergeMap(response => [
                    // updating the error count to zero on success of service
                    fromAutoActions.UpdateAutoControlData({ ctrlData: {errorCount: 0} }),
                    // updating the prequalified discounts in store
                    fromAutoActions.UpdatePrequalifiedDiscountList(
                        {
                            prequalifiedDiscounts: this.dataService.populatePrequalifiedDiscounts(response)
                        }
                    ),
                    // tslint:disable-next-line:max-line-length
                    fromAutoActions.EditVehicleSuccess({ reqPayload: action.reqPayload.vehicle, serviceCtrlData: { editVehicleDetails: 'success' } }),
                    fromAppActions.ClearZipValidationErrorMessage()
                ]),
                    catchError(err => [ fromAutoActions.IncrementErrorCount(),
                        fromAutoActions.ServiceFail({ respError: { service: 'EditVehicle', ...err } })])
            )
        )
    )
    );

    /** service calls triggered from driver page  */

    /** Save pnidriver of auto effects */
    savePNIDriver$ = createEffect(() => this.actions$.pipe(ofType(fromAutoActions.SavePNIDriver),
        mergeMap(action =>
            this.dataService.saveDriver$(action.reqPayload).pipe(
                mergeMap(response => [
                    // updating the error count to zero on success of service
                    fromAutoActions.UpdateAutoControlData({ ctrlData: {errorCount: 0} }),
                    // updating the prequalified discounts in store
                    fromAutoActions.UpdatePrequalifiedDiscountList(
                        {
                            prequalifiedDiscounts: this.dataService.populatePrequalifiedDiscounts(response)
                        }
                    ),
                    // tslint:disable-next-line: max-line-length
                    // updating the gender on success of service
                    fromAppActions.UpdatePNIGender({ reqPayload: action.reqPayload.driver.gender}),
                    // tslint:disable-next-line:max-line-length
                    fromAutoActions.SavePNIDriverSuccess({ reqPayload: action.reqPayload, serviceCtrlData: { saveDriverDetails: 'success' } })]),

                catchError(err => of(fromAutoActions.IncrementErrorCount(),
                fromAutoActions.ServiceFailWithCtrlData({ respError: { service: 'SaveDriver', ...err },
                 serviceCtrlData: { saveDriverDetails: 'failure' } })))
            )
        )
    )
    );
    /** Save pnispousedriver$ of auto effects */
    addPNISpouseDriver$ = createEffect(() => this.actions$.pipe(ofType(fromAutoActions.AddPNISpouseDriver),
        mergeMap(action =>
            this.dataService.saveDriver$(action.reqPayload).pipe(
                mergeMap(response => [
                    // updating the error count to zero on success of service
                    fromAutoActions.UpdateAutoControlData({ ctrlData: {errorCount: 0} }),
                    // updating the prequalified discounts in store
                    fromAutoActions.UpdatePrequalifiedDiscountList(
                      { prequalifiedDiscounts: this.dataService.populatePrequalifiedDiscounts(response) }
                    ),
                    // tslint:disable-next-line: max-line-length
                    fromAutoActions.AddPNISpouseSuccess({ reqPayload: action.reqPayload.driver, serviceCtrlData: { saveDriverDetails: 'success' } })]),
                catchError(err => of(fromAutoActions.IncrementErrorCount(),
                fromAutoActions.ServiceFailWithCtrlData({ respError: { service: 'SaveDriver', ...err },
                 serviceCtrlData: { saveDriverDetails: 'failure' } })))
            )
        )
    )
    );
    /** Save additionalDriver$ of auto effects */
    addAdditionalDriver$ = createEffect(() => this.actions$.pipe(ofType(fromAutoActions.AddAdditionalDriver),
        mergeMap((action) =>
            this.dataService.saveDriver$(action.reqPayload).pipe(
                mergeMap(response => [
                    // updating the error count to zero on success of service
                    fromAutoActions.UpdateAutoControlData({ ctrlData: {errorCount: 0} }),
                    // updating the prequalified discounts in store
                    fromAutoActions.UpdatePrequalifiedDiscountList(
                      { prequalifiedDiscounts: this.dataService.populatePrequalifiedDiscounts(response) }
                    ),
                    // tslint:disable-next-line: max-line-length
                    fromAutoActions.AddAdditionalDriverSuccess({ resData: action.reqPayload.driver, serviceCtrlData: { saveDriverDetails: 'success' }})]),
                catchError(err => of(fromAutoActions.IncrementErrorCount(),
                fromAutoActions.ServiceFailWithCtrlData({ respError: { service: 'SaveDriver', ...err },
                 serviceCtrlData: { saveDriverDetails: 'failure' } })))
            )
        )
    )
    );
    /** Edit additionalDriver$ of auto effects */
    editAdditionalDriver$ = createEffect(() => this.actions$.pipe(ofType(fromAutoActions.EditAdditionalDriver),
        mergeMap(action =>
            this.dataService.saveDriver$(action.reqPayload).pipe(
                mergeMap(response => [
                    // updating the error count to zero on success of service
                    fromAutoActions.UpdateAutoControlData({ ctrlData: {errorCount: 0} }),
                    // updating the prequalified discounts in store
                    fromAutoActions.UpdatePrequalifiedDiscountList(
                      { prequalifiedDiscounts: this.dataService.populatePrequalifiedDiscounts(response) }
                    ),
                    // tslint:disable-next-line: max-line-length
                    fromAutoActions.EditAdditionalDriverSuccess({ reqPayload: action.reqPayload.driver, serviceCtrlData: { saveDriverDetails: 'success' } })]),
                    catchError(err => of(fromAutoActions.IncrementErrorCount(),
                    fromAutoActions.ServiceFailWithCtrlData({ respError: { service: 'SaveDriver', ...err },
                     serviceCtrlData: { saveDriverDetails: 'failure' } })))
            )
        )
    )
    );
    /** Add additionalDriverFromPrefill$ of auto effects */
    addAdditionalDriverFromPrefill$ = createEffect(() => this.actions$.pipe(ofType(fromAutoActions.AddAdditionalDriverFromPrefill),
        mergeMap((action) =>
            this.dataService.saveDriver$(action.reqPayload).pipe(
                mergeMap(response => [
                    // updating the error count to zero on success of service
                    fromAutoActions.UpdateAutoControlData({ ctrlData: {errorCount: 0} }),
                    // updating the prequalified discounts in store
                    fromAutoActions.UpdatePrequalifiedDiscountList(
                      { prequalifiedDiscounts: this.dataService.populatePrequalifiedDiscounts(response) }
                    ),
                    // tslint:disable-next-line:max-line-length
                    fromAutoActions.AddAdditionalDriverFromPrefillSuccess({ resData: action.reqPayload.driver, serviceCtrlData: { saveDriverDetails: 'success' } })]),
                // autoActions.RemoveDriverFromPrefillList({ reqPayload: action.reqPayload.driver.prefillRef })
                // ]),
                // tslint:disable-next-line: max-line-length
                catchError(err => of(fromAutoActions.IncrementErrorCount(),
                fromAutoActions.ServiceFailWithCtrlData({ respError: { service: 'SaveDriver', ...err },
                 serviceCtrlData: { saveDriverDetails: 'failure' } })))
            )
        )
    )
    );
    /** Add driver spouse from prefill$ of auto effects */
    addDriverSpouseFromPrefill$ = createEffect(() => this.actions$.pipe(ofType(fromAutoActions.AddDriverSpouseFromPrefill),
        mergeMap((action) =>
            this.dataService.saveDriver$(action.reqPayload).pipe(
                mergeMap(response => [
                    // updating the error count to zero on success of service
                    fromAutoActions.UpdateAutoControlData({ ctrlData: {errorCount: 0} }),
                    // updating the prequalified discounts in store
                    fromAutoActions.UpdatePrequalifiedDiscountList(
                      { prequalifiedDiscounts: this.dataService.populatePrequalifiedDiscounts(response) }
                    ),
                    // tslint:disable-next-line:max-line-length
                    fromAutoActions.AddDriverSpouseFromPrefillSuccess({ reqPayload: action.reqPayload.driver, serviceCtrlData: { saveDriverDetails: 'success' } })]),
                // autoActions.RemoveDriverFromPrefillList({ reqPayload: action.reqPayload.driver.prefillRef })
                // ),
                catchError(err => of(fromAutoActions.IncrementErrorCount(),
                fromAutoActions.ServiceFailWithCtrlData({ respError: { service: 'SaveDriver', ...err },
                 serviceCtrlData: { saveDriverDetails: 'failure' } })))
            )
        )
    )
    );
    /** Delete married additional driver details of drivers effects */
    removeMarriedAdditionalDriver$ = createEffect(() => this.actions$.pipe(ofType(fromAutoActions.RemoveMarriedAdditionalDriver),
        mergeMap((action) =>
            this.dataService.saveDriver$(action.reqPayload).pipe(
                mergeMap(response => [
                    // updating the error count to zero on success of service
                    fromAutoActions.UpdateAutoControlData({ ctrlData: {errorCount: 0} }),
                    // updating the prequalified discounts in store
                    fromAutoActions.UpdatePrequalifiedDiscountList(
                      { prequalifiedDiscounts: response.persAutoInsQuote.persAutoPolicy.discount }
                    ),
                    // tslint:disable-next-line: max-line-length
                    fromAutoActions.RemoveMarriedAdditionalDriverSuccess({ resData: action.reqPayload.driver, serviceCtrlData: { deleteAdditionalDriverDetails: 'success' } }),
                    fromAutoActions.DeleteAdditionalDriver({
                        reqPayload: this.dataService.createDeleteDriverRequestPayload(action.reqPayload.driver.spouseRef, 'delete')
                    })
                ]),
                // catchError(err => of(driverActions.RemoveMarriedAdditionalDriverFail({ respErr: err })))
                // tslint:disable-next-line: max-line-length
                catchError(err => of(fromAutoActions.IncrementErrorCount(),
                fromAutoActions.ServiceFailWithCtrlData({ respError: { service: 'RemoveMarriedAdditionalDriverFail', ...err },
                serviceCtrlData: { deleteAdditionalDriverDetails: 'failure' } })))
            )
        )
    )
    );
    /** Remove married additional driver dummy$ of drivers effects */
    removeMarriedAdditionalDriverDummy$ = createEffect(() => this.actions$.pipe(ofType(fromAutoActions.RemoveMarriedAdditionalDriverDummy),
        mergeMap((action) =>
            this.dataService.saveDriver$(action.reqPayload).pipe(
                mergeMap(response => [
                     // updating the error count to zero on success of service
                     fromAutoActions.UpdateAutoControlData({ ctrlData: {errorCount: 0} }),
                     // updating the prequalified discounts in store
                     fromAutoActions.UpdatePrequalifiedDiscountList(
                        { prequalifiedDiscounts: this.dataService.populatePrequalifiedDiscounts(response) }
                     ),
                    // tslint:disable-next-line: max-line-length
                    fromAutoActions.RemoveMarriedAdditionalDriverSuccess({ resData: action.reqPayload.driver, serviceCtrlData: { deleteAdditionalDriverDetails: 'success' } }),
                    fromAutoActions.RemoveDummySpouse({ spouseRef: action.reqPayload.driver.spouseRef })
                ]),
                // catchError(err => of(driverActions.RemoveMarriedAdditionalDriverFail({ respErr: err })))
                // tslint:disable-next-line: max-line-length
                catchError(err => of(fromAutoActions.IncrementErrorCount(),
                fromAutoActions.ServiceFailWithCtrlData({ respError: { service: 'RemoveMarriedAdditionalDriverFail', ...err },
                serviceCtrlData: { deleteAdditionalDriverDetails: 'failure' } })))
            )
        )
    )
    );
    /** Delete additional driver$ of drivers effects */
    deleteAdditionalDriver$ = createEffect(() => this.actions$.pipe(ofType(fromAutoActions.DeleteAdditionalDriver),
        mergeMap(action =>
            this.dataService.saveDriver$(action.reqPayload).pipe(
                mergeMap(response => [
                    // updating the error count to zero on success of service
                    fromAutoActions.UpdateAutoControlData({ ctrlData: {errorCount: 0} }),
                    // updating the prequalified discounts in store
                    fromAutoActions.UpdatePrequalifiedDiscountList(
                        { prequalifiedDiscounts: this.dataService.populatePrequalifiedDiscounts(response) }
                    ),
                    // tslint:disable-next-line: max-line-length
                    fromAutoActions.DeleteAdditionalDriverSuccess({ reqPayload: action.reqPayload.driver, serviceCtrlData: { deleteAdditionalDriverDetails: 'success' } })]),
                // tslint:disable-next-line: max-line-length
                // catchError(err => of(driverActions.DeleteAdditionalDriverFail({ respErr: err, ctrlData: { deleteAddtionalDriver: 'completed' } })))
                catchError(err => of(fromAutoActions.IncrementErrorCount(),
                fromAutoActions.ServiceFailWithCtrlData({ respError: { service: 'DeleteAddtionalDriverFail', ...err },
                serviceCtrlData: { deleteAdditionalDriverDetails: 'failure' } })))
            )
        )
    )
    );
    /** Edit  drivers effects */
    EditDriver$ = createEffect(() => this.actions$.pipe(ofType(fromAutoActions.EditDriver),
        mergeMap((action) =>
            this.dataService.saveDriver$(action.reqPayload).pipe(
                mergeMap(response => [
                    // updating the error count to zero on success of service
                    fromAutoActions.UpdateAutoControlData({ ctrlData: {errorCount: 0} }),
                    // updating the prequalified discounts in store
                    fromAutoActions.UpdatePrequalifiedDiscountList(
                        { prequalifiedDiscounts: this.dataService.populatePrequalifiedDiscounts(response) }
                    ),
                    fromAutoActions.EditDriverSuccess({ resData: action.reqPayload, serviceCtrlData: { editDriverDetails: 'success' } }), ]
                ),
                // catchError(err => of(driverActions.EditDriverFail({ respErr: err })))
                catchError(err => of(fromAutoActions.IncrementErrorCount(),
                fromAutoActions.ServiceFailWithCtrlData({ respError: { service: 'EditDriverFail', ...err },
                 serviceCtrlData: { editDriverDetails: 'failure' } })))
            )
        )
    )
    );
    /** Edit driver And delete spouse effects */
    EditAndDeleteDriver$ = createEffect(() => this.actions$.pipe(ofType(fromAutoActions.EditAndDeleteDriver),
        mergeMap((action) =>
            this.dataService.saveDriver$(action.reqPayload).pipe(
                mergeMap(response => [
                    // updating the error count to zero on success of service
                    fromAutoActions.UpdateAutoControlData({ ctrlData: {errorCount: 0} }),
                    // updating the prequalified discounts in store
                    fromAutoActions.UpdatePrequalifiedDiscountList(
                        { prequalifiedDiscounts: this.dataService.populatePrequalifiedDiscounts(response) }
                      ),
                      fromAutoActions.EditDriverSuccess({ resData: action.reqPayload, serviceCtrlData: { editDriverDetails: 'success' } }),
                    fromAutoActions.DeleteAdditionalDriver({
                        reqPayload: this.dataService.createDeleteDriverRequestPayload(action.spouseRef, 'delete')
                    })
                ]),
                // catchError(err => of(driverActions.EditDriverFail({ respErr: err })))
                catchError(err => of(fromAutoActions.IncrementErrorCount(),
                fromAutoActions.ServiceFailWithCtrlData({ respError: { service: 'EditDriverFail', ...err },
                serviceCtrlData: { editDriverDetails: 'failure' } })))
            )
        )
    )
    );
    /** Edit driver And delete the incomplete spouse card effects */
    EditAndDeleteDummyDriver$ = createEffect(() => this.actions$.pipe(ofType(fromAutoActions.EditAndDeleteDummyDriver),
        mergeMap((action) =>
            this.dataService.saveDriver$(action.reqPayload).pipe(
                mergeMap(response => [
                    // updating the error count to zero on success of service
                    fromAutoActions.UpdateAutoControlData({ ctrlData: {errorCount: 0} }),
                    // updating the prequalified discounts in store
                    fromAutoActions.UpdatePrequalifiedDiscountList(
                      { prequalifiedDiscounts: this.dataService.populatePrequalifiedDiscounts(response) }
                    ),
                    fromAutoActions.EditDriverSuccess({ resData: action.reqPayload, serviceCtrlData: { editDriverDetails: 'success' } }),
                    fromAutoActions.RemoveDummySpouse({ spouseRef: action.spouseRef })
                ]),
                // catchError(err => of(driverActions.EditDriverFail({ respErr: err })))
                catchError(err => of(fromAutoActions.IncrementErrorCount(),
                fromAutoActions.ServiceFailWithCtrlData({ respError: { service: 'EditDriverFail', ...err },
                serviceCtrlData: { editDriverDetails: 'failure' } })))
            )
        )
    )
    );

    /** Save pnidata$ of auto effects */
    SavePNIAdditionalData$ = createEffect(() => this.actions$.pipe(ofType(fromAutoActions.SavePNIDriverAdditionalData),
        mergeMap((action) =>
            this.dataService.savePniData$(action.reqPayload).pipe(
                mergeMap(data => [
                    // tslint:disable-next-line: max-line-length
                    fromAppActions.UpdateCommunicationDetailsOnSuccess({ respData: this.dataService.populateCommunicationData(data, action.reqPayload) }),
                    fromAutoActions.UpdatePrequalifiedDiscountList(
                      {
                        prequalifiedDiscounts: this.dataService.populatePrequalifiedDiscounts(data)
                      }),
                    fromAutoActions.UpdateAutoState({ reqPayload: { data: null, error: null,
                         controlData: this.dataService.populateControlDataForSavePNI(data, action.reqPayload),
                         serviceControlData: { savePNIStatus: 'success' }}}),
                ]),
                // tslint:disable-next-line: max-line-length
                catchError(err => of(fromAutoActions.ServiceFailWithCtrlData({ respError: this.dataService.populatePNISaveFailureError(err), serviceCtrlData: { savePNIStatus: 'failure' } })))
            )
        )
    )
    );
    /** BW Agent Assignment in auto effect */
    bwAgentAssignment$ = createEffect(() => this.actions$.pipe(ofType(fromAppActions.BWAgentAssignment),
        mergeMap((action) =>
            this.dataService.bwAgentAssignment$().pipe(
                mergeMap(response => [
                    fromAppActions.BWAgentAssignmentSuccess(
                        {
                            respData: this.setBWagentAssignmentDetails(response), ctrlData:
                                { bwAgentAssignment: 'completed' }
                        }),
                    fromAutoActions.UpdateAutoError(
                        this.filterStoreStateService.filterResponse(
                            {
                                respData: response,
                                respError: {}
                            }, FlowIndentifierEnum.BW_AGENT_ASSIGNMENT
                        )
                    )]),
                    catchError(err =>
                        of(fromAppActions.BWAgentAssignmentFail({ respErr: err, ctrlData: { bwAgentAssignment: 'Fail' } })))
                )
        )
    ));

    /** service calls triggered from discount page  */

    /** saveDiscount service of auto effects */
    saveDiscount$ = createEffect(() => this.actions$.pipe(ofType(fromAutoActions.SaveDiscount),
        mergeMap(action =>
            this.dataService.saveDiscount$(action.reqPayload).pipe(
                map(response =>
                    fromAutoActions.SaveDiscountSuccess(this.filterStoreStateService.filterResponse({
                        respData: this.helperService.combineRequestAndResponse(action.reqPayload, response),
                        serviceCtrlData: { saveDiscount: 'success' }
                    }, FlowIndentifierEnum.SAVE_DISCOUNT))),
                catchError(err => of(fromAutoActions.ServiceFailWithCtrlData({
                    respError: { service: 'SaveDiscounts', ...err } ,
                    serviceCtrlData: { saveDiscount: 'completed' }
                })))
            )
        )
    ));

    /* Save signal discount */
    saveSignalDiscount$ = createEffect(() => this.actions$.pipe(ofType(fromAutoActions.SaveSignalDiscount),
        mergeMap(action =>
            this.dataService.saveSignalDiscount$(action.reqPayload).pipe(
                map(response => {
                    return fromAutoActions.SaveSignalDiscountSuccess({ respData: response, ctrlData: { saveSignalDiscount: 'success' } });
                }),
                catchError(err => of(fromAutoActions.SaveSignalDiscountFail({ respErr: err, ctrlData: { saveSignalDiscount: 'failed' } })))
            )
        )
    ));

    /* invoke connected Car API */
    invokeConnectedCar$ = createEffect(() => this.actions$.pipe(ofType(fromAutoActions.InvokeConnectedCar),
        mergeMap(action =>
            this.dataService.connectedCarService$(action.reqPayload).pipe(
                map(response => {
                    /** F37449: Changes to keep the connected car Indicator in APP store */
                    return fromAppActions.UpdateAppControlData({ ctrlData: { connectedCarIndicator: response.connectedCarIndicator } });
                })
            )
        )
    ));

    /** saveDiscount service call with saveMode = 'saveAndFinish' */
    saveDiscountAndFinish$ = createEffect(() => this.actions$.pipe(ofType(fromAutoActions.SaveDiscountAndFinish),
        mergeMap(action =>
            this.dataService.saveDiscount$(action.reqPayload).pipe(
                map(response =>
                    fromAppActions.SaveAndFinishLaterSuccess({ ctrlData: { saveAndFinishLater: 'success' } })),
                catchError(err => of(fromAppActions.SaveAndFinishLaterFail({ respErr: err, ctrlData: { saveAndFinishLater: 'fail' } })))
            )
        )
    ));

    /** saveSignalDiscount service call with saveMode = 'saveAndFinish' */
    saveSignalDiscountAndFinish$ = createEffect(() => this.actions$.pipe(ofType(fromAutoActions.SaveSignalDiscountAndFinish),
        mergeMap(action =>
            this.dataService.saveSignalDiscount$(action.reqPayload).pipe(
                map(response =>
                    fromAppActions.SaveAndFinishLaterSuccess({ ctrlData: { saveAndFinishLater: 'success' } })),
                catchError(err => of(fromAppActions.SaveAndFinishLaterFail({ respErr: err, ctrlData: { saveAndFinishLater: 'fail' } })))
            )
        )
    ));

    /** saveDriverAssignAndFinish service call with saveMode = 'saveAndFinish' */
    saveDriverAssignAndFinish$ = createEffect(() => this.actions$.pipe(ofType(fromAutoActions.SaveDriverAssignAndFinish),
        mergeMap(action =>
            this.dataService.driverVehicleAssignment$(action.reqPayload).pipe(
                map(response =>
                    fromAppActions.SaveAndFinishLaterSuccess({ ctrlData: { saveAndFinishLater: 'success' } })),
                catchError(err => of(fromAppActions.SaveAndFinishLaterFail({ respErr: err, ctrlData: { saveAndFinishLater: 'fail' } })))
            )
        )
    ));

    /* Save KnockOut */
    saveKnockOut$ = createEffect(() => this.actions$.pipe(ofType(fromAutoActions.SaveKnockOut),
        mergeMap(action =>
            this.knockOutService.saveKnockOut$(action.reqPayload).pipe(
                map(response => {
                    return fromAutoActions.SaveKnockOutSuccess({ respData: response, ctrlData: { saveKnockOut: 'success',
                    flHouseAccount: response.flHouseAccount, isHouseAccount: response.isHouseAccount } });
                }),
                catchError(err => of(fromAutoActions.SaveKnockOutFail({ respErr: err, ctrlData: { saveKnockOut: 'failed' } })))
            )
        )
    ));

    /* Save VehicleList */
    SaveVehicleList$ = createEffect(() => this.actions$.pipe(ofType(fromAutoActions.SaveVehicleList),
        mergeMap(action =>
            this.dataService.saveVehicleList$(action.reqPayload).pipe(
                mergeMap(response => [
                    // updating the prequalified discounts in store
                    fromAutoActions.UpdatePrequalifiedDiscountList(
                      {
                        prequalifiedDiscounts: this.dataService.populatePrequalifiedDiscounts(response)
                      }),
                    fromAutoActions.SaveVehicleListSuccess(
                      { reqPayload: action.reqPayload.vehicleRequestList, serviceCtrlData: { saveVehicleList: 'success'  }})]),
                catchError(err => of(fromAutoActions.ServiceFailWithCtrlData({ respError: err,
                     serviceCtrlData: { saveVehicleList: 'failed' } })))
            ))
    ));
    // For driver Vehicle Assignment
    driverVehicleAssignment$ = createEffect(() => this.actions$.pipe(ofType(fromAutoActions.DriverVehicleAssignment),
        mergeMap(action =>
            this.dataService.driverVehicleAssignment$(action.reqPayload).pipe(
                map(response =>
                    fromAutoActions.DriverVehicleAssignmentSuccess(
                        this.filterStoreStateService.filterResponse(
                            {
                                reqPayload: action.reqPayload,
                                respData: response,
                                ctrlData: action.reqPayload.saveMode === 'add' ?
                                  { driverVehicleAssignment: 'success' } : { saveAndFinishLater: 'success' },
                                respError: {}
                            }, FlowIndentifierEnum.DRIVER_ASSIGNMENT
                        )
                    )
                ),
                // tslint:disable-next-line: max-line-length
                catchError(err => of(fromAutoActions.ServiceFailWithCtrlData({ respError: err, serviceCtrlData: { driverVehicleAssignment: 'failure' } })))
            ))));
    // For Vehicle Driver Assignment
    vehicleDriverAssignment$ = createEffect(() => this.actions$.pipe(ofType(fromAutoActions.VehicleDriverAssignment),
    mergeMap(action =>
        this.dataService.driverVehicleAssignment$(action.reqPayload).pipe(
            map(response =>
        fromAutoActions.VehicleDriverAssignmentSuccess({ reqPayload: action.reqPayload,
            ctrlData: action.reqPayload.saveMode === 'add' ? {
                driverVehicleAssignment: 'success' } : { saveAndFinishLater: 'success' } })),
     catchError(err => of(fromAutoActions.ServiceFailWithCtrlData({ respError: err,
        serviceCtrlData: { driverVehicleAssignment: 'failure' } }))),
        ))));
    /**
     * Add ride share action effect for service call
     */
    AddRideShare$ = createEffect(() => this.actions$.pipe(ofType(fromAutoActions.AddRideShare),
        mergeMap(action =>
            this.dataService.addRideShare$(action.reqPayload).pipe(
                map(response =>
                    fromAutoActions.AddRideShareSuccess({ reqPayload: action.reqPayload, ctrlData: { addRideShare: 'success' } })),
                // tslint:disable-next-line:max-line-length
                catchError(err => of(fromAutoActions.ServiceFail({ respError: { service: 'addRideShare', ...err } })))
            )
        )
    ));

    ValidateLicenseNumber$ = createEffect(() => this.actions$.pipe(ofType(fromAutoActions.ValidateLicenseNumber),
        mergeMap(action =>
            this.dataService.validateLicenseNumber$(action.reqPayload).pipe(
                map(response =>
                    fromAutoActions.ValidateLicenseSuccess({ respData: response})),
                catchError(err => of(fromAutoActions.ServiceFailWithCtrlData({ respError:
                    { service: 'validateLicense', ...err }, serviceCtrlData: { validateLicenseNumber: 'failure' } })))
            )
        )
    ));


    saveRideShareAndFinish$ = createEffect(() => this.actions$.pipe(ofType(fromAutoActions.SaveRideShareAndFinish),
        mergeMap(action =>
            this.dataService.addRideShare$(action.reqPayload).pipe(
                map(response =>
                    fromAppActions.SaveAndFinishLaterSuccess({ ctrlData: { saveAndFinishLater: 'success' } }),
                ),
                catchError(err => of(fromAppActions.SaveAndFinishLaterFail({ respErr: err, ctrlData: { saveAndFinishLater: 'fail' } })))
            )
        )
    )
    );

    /**
     *  Add additionalDriverFromPrefill$ of auto effects
     */
    addExcludedDriverFromPrefill$ = createEffect(() => this.actions$.pipe(ofType(fromAutoActions.SaveExcludedDriver),
        mergeMap(action =>
            this.dataService.saveExcludedDriver$(action.reqPayload).pipe(
                map(response =>
                    fromAutoActions.SaveExcludedDriverSuccess({ serviceCtrlData: { saveExcludedDriver: 'success' } })),
                catchError(err => of(fromAutoActions.ServiceFailWithCtrlData({
                    respError: { service: 'SaveExcludedDriver', ...err },
                    serviceCtrlData: { saveExcludedDriver: 'failure' }
                })))
            )
        )
    )
    );

    /** trigger the rentersRate service */
    rentersRate$ = createEffect(() => this.actions$.pipe(ofType(fromAutoActions.RentersRate),
    mergeMap(action =>
        this.dataService.rentersBundleRateQuote$().pipe(
            mergeMap(response => [
                fromAppActions.UpdateAppControlData({
                    ctrlData:
                        { defaultRateQuoteCalled: RateQuoteProgressStatus.RENTERS_RATE_COMPLETED }
                }),
                fromAutoActions.RentersRateSuccess(
                    this.filterStoreStateService.filterResponse(
                        { respData:  response ,
                        error: null,
                      serviceCtrlData: { rentersRate: 'success' } }, FlowIndentifierEnum.RNETERS_RATE))
                ]
            ),
            catchError(err => [
                fromAutoActions.ServiceFailWithCtrlData({ respError: Object.assign({}, {service: 'rentersRate'} , err),
                   serviceCtrlData: { rentersRate: 'fail' } }),
                   // tslint:disable-next-line: max-line-length
                   fromAppActions.UpdateAppControlData({ ctrlData: {  defaultRateQuoteCalled: RateQuoteProgressStatus.RENTERS_RATE_COMPLETED } })]))
        )
    )
);

    /** trigger the print quote service */
    dispatchPrintQuote$ = createEffect(() => this.actions$.pipe(ofType(fromAutoActions.dispatchPrint),
        mergeMap(action =>
            this.dataService.dispatchPrintQuote$(action.reqPayload).pipe(map(response =>
                fromAutoActions.dispatchPrintSuccess(),
            ),
                catchError(err => of(fromAutoActions.dispatchPrintFail()))))));

    /** US240965: Handle ADPF Data & Service when user proceeds from RC1 Summary Page */
    modifyPrefillRes(autoStoreData: AutoData, responseData: PrefillData): PrefillData {
        if (responseData?.externalId) {
            const identifyDetails: IdentifyTraits = {
                ...(responseData?.externalId) && { external_consumer_id: responseData?.externalId },
            };
            this.segmentService.identify(identifyDetails);
        }
        responseData = this.filterPartnerQDataFromPrefill(autoStoreData, responseData);
        responseData.vehicle = this.generateAndModifyReference(autoStoreData.vehicles.length, responseData.vehicle);
        return responseData;
    }

    /** Adding/Modiying reference number for the vehicles and drivers returned by the ADPF service  */
    generateAndModifyReference(initialVal: number, data: Vehicle[] | Driver[]): Vehicle[] | Driver[] {
        if (data) {
            let reference = initialVal + 1;
            data.forEach(element => {
                element.reference = reference;
                reference++;
            });
        }
        return data;
    }

    filterPartnerQDataFromPrefill(autoStoreData: AutoData, response: PrefillData): PrefillData {
        response.vehicle = response.vehicle.filter(adpfVeh => !this.isAdpfVehInQuote(adpfVeh, autoStoreData.vehicles));
        response.driver = response.driver.filter(adpfDvr => !this.isAdpfDrvInQuote(adpfDvr, autoStoreData.drivers));

        return response;
    }

    isAdpfVehInQuote(adpfVeh: Vehicle, quoteVehicles: Vehicle[]): boolean {
        const vehicle = quoteVehicles.find(quoteVeh => this.hasSameVIN(adpfVeh.vin, quoteVeh.vin) || this.hasSameYMM(adpfVeh, quoteVeh));
        return vehicle !== undefined && vehicle !== null;
    }

    hasSameVIN(adpfVehVIN: string, quoteVehVIN: string): boolean {
        if (adpfVehVIN === '' || quoteVehVIN === '') {
            return false;
        }
        return adpfVehVIN === quoteVehVIN;
    }

    hasSameYMM(adpfVeh: Vehicle, quoteVeh: Vehicle): boolean {
        // US275714: Defect fix - ADPF vehicle shown on quote vehicles datat
        const modelList = adpfVeh.model.split('|');

        // US352452 : FFQ Auto Quote Partner Integration flow - Exception due to duplicate VIN
        return adpfVeh.year === quoteVeh.year
            && (modelList.includes(quoteVeh.make) || adpfVeh.make === quoteVeh.make)
            && (modelList.includes(quoteVeh.model) || adpfVeh.model === quoteVeh.model);
    }

    isAdpfDrvInQuote(adpfDvr: Driver, quoteDrivers: Driver[]): boolean {
        const driver = quoteDrivers.find(quoteDvr =>
            adpfDvr.firstName.toLowerCase() === quoteDvr.firstName.toLowerCase()
            && adpfDvr.lastName.toLowerCase() === quoteDvr.lastName.toLowerCase()
            && adpfDvr.pni !== 'H');
        return driver !== undefined && driver !== null;
    }

    isSameDrvAge(adpfDvr: Driver, quoteDvr: Driver): boolean {
        if (adpfDvr.dateOfBirth) {
            return adpfDvr.dateOfBirth === quoteDvr.dateOfBirth;
        } else if (quoteDvr.age) {
            return adpfDvr.age === quoteDvr.age;
        } else {
            const age = this.dateService.getAge(new Date(quoteDvr.dateOfBirth));
            return age === adpfDvr.age;
        }
    }

    /** To overwrite Agent Details with BW Agent */
    setBWagentAssignmentDetails(responseData) {
        if (responseData) {
            if (responseData.agentChanged) {
                if (responseData.agentAvailable) {
                    if (responseData.agentInfoBean) {
                        return responseData;
                    }
                } else {
                    responseData.reference = 'D';
                }
            } else if (!responseData.agentAvailable) {
                responseData.reference = 'D';
            }
        }
        return responseData;
    }
}
