import { Component, OnInit, Input, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { FormInputParam } from '../form-input-params';
import { FormControl, AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map, tap } from 'rxjs/operators';
import { DropDownValues } from '@ffq-app-auto/your-info';

@Component({
  selector: 'lib-typeahead-comp',
  templateUrl: './typeahead-comp.component.html',
  styleUrls: ['./typeahead-comp.component.scss']
})
export class TypeaheadCompComponent implements OnInit {

  @Input() params: FormInputParam;
  @Output() isPanelOpen = new EventEmitter<boolean>();
  Object = Object;
  myControl: AbstractControl;
  filteredOptions: any[];
  proxyValue: any;
  defaultVal;
  constructor(private changeDetector: ChangeDetectorRef) { }

  ngOnInit() {

    const formControlName = this.params.formControllName;
    this.myControl = this.params.formGroup.controls[formControlName];
    this.myControl.valueChanges.subscribe(val => {
      this.filteredOptions = this._filter(val);
    });
  }

  showNewValues(dropDownValue: any): void {
    this.params.values = dropDownValue;
    this.filteredOptions = dropDownValue;
    this.changeDetector.detectChanges();
  }

  private _filter(label: string): DropDownValues[] {
    if (label === '') {
     return this.params.values;
    } else if (label) {
      const suggOptions = this.params.values;
      const filterValue = label.toLowerCase();
      this.defaultVal = suggOptions.find(e => e.label === 'OTHER');
      const filtered = suggOptions.filter(option => option.label.toLowerCase().indexOf(filterValue) === 0);
      if (this.defaultVal && filtered.length === 0) {
        return [this.defaultVal];
      }
      return filtered.length > 0 ? filtered : [];
    }
  }

  displayFn = (value) => {
    if (value) {
      const selection = this.params.values.find(e => e.value === value);
      return selection ? selection.label : '';
    }
  }

  onSelectionChanged(event$): void {
    this.proxyValue = event$.option;
  }
  // US125852 -AutoComplete dropdown scroll issue fix
  handleOpen(): void {
    this.isPanelOpen.emit(true);
  }

  handleClosed(): void {
    this.isPanelOpen.emit(false);
  }
}
