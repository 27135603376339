import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationDrawerService } from '..';
import { AppStoreService } from '@ffq-app-store/app.store.service';
import { AgentData } from '@ffq-app-shared/model/common.data.model';
import { HelperService } from '@ffq-app-shared/services/helper.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ScheduleAgentAppointmentComponent } from '../schedule-agent-appointment/schedule-agent-appointment.component';
import { SegmentService } from '@ffq-app-shared/services/segment.service';
import { ScheduleAgentService } from '@ffq-app-shared/services/schedule-agent.service';
import { Subscription } from 'rxjs';
import { AutoStoreService } from '@ffq-app-auto/store/auto.store.service';
import { DataConstants } from '@ffq-app-shared/constants';


@Component({
  selector: 'app-agent-details',
  templateUrl: './agent-details.component.html',
  styleUrls: ['./agent-details.component.scss']
})
export class AgentDetailsComponent implements OnInit, OnDestroy {

  showAsStrip = false;
  showDrawer = false;
  agentAssigned = false;
  agentName: string;
  agentLocation: string;
  agentPhoneNumber: string;
  agentDetails = {} as AgentData;
  googleMaplocation: string;
  gwEEImpl = false;
  teleDesktop: string;
  teleMobile: string;
  quoteType: string;
  hideDesktopLabel = false;
  ariaLabelPhoneNumber: string;
  ariaLabelStreet: string;
  agentPhoneNumberCC: string;
  mobileNo: string;
  desktopNo: string;
  sourcePhoneNumber: string;
  stateCode: string;
  isQuoteRated: boolean;
  @Input() currentStepNumber: number;
  @ViewChild('scheduleAgent') scheduleAgentElement: ElementRef;
  scheduleAgentSubsciption: Subscription;
  isSchdAgentAppiontmentRequired = false;
  /** US518599 : Externalization control for Monetization projects */
  isTFNProjectImpl: boolean;

  /**
   * Creates an instance of agent details component.
   * @param navigationDrawerService NavigationDrawerService
   * @param appStoreService AppStoreService
   */
  constructor(
    private navigationDrawerService: NavigationDrawerService,
    private appStoreService: AppStoreService,
    private autoStoreService: AutoStoreService,
    private helperService: HelperService,
    private dialog: MatDialog,
    private segmentService: SegmentService,
    private scheduleAgentService: ScheduleAgentService
  ) {

    this.navigationDrawerService.showHideEvent.subscribe(
      (sidePanelEvent) => {
        if (sidePanelEvent === 'expandSideStrip') {
          this.showAsStrip = false;

        } else if (sidePanelEvent === 'collapseSidePanel') {
          this.showAsStrip = true;

        } else if (sidePanelEvent === 'toggleSidePanelStrip') {

          if (this.showAsStrip === true) {
            this.showAsStrip = false;
          } else {
            this.showAsStrip = true;
          }
        }
      }
    );
  }
  /**
   * on destroy
   */
  ngOnDestroy(): void {
    this.scheduleAgentSubsciption.unsubscribe();
  }

  /**
   * Switchs to strip
   */
  public switchToStrip() {
    this.showAsStrip = true;
  }
  ngOnInit() {
    // this.switchToStrip();
    let previousRoute;
    let asignAgentStatus;

    /**
     * US501061 && US501062: CA FFQ Digital Monetization - CA State TFN Replacement
     * for Agent, Quote, Thank you and print quote pages for desktop and mobile windows
     */
    this.appStoreService.appDataZip$.subscribe(appDataZip => {
      this.stateCode = appDataZip.landingStateCode;
    });

    this.autoStoreService.getBaseRateCallStatus$.subscribe(baseRateCallStatus => {
      this.isQuoteRated = baseRateCallStatus;
      if (this.isQuoteRated) {
        this.appStoreService.getPhoneNumberServiceStatus$.subscribe(phServiceStatus => {
             this.updatePhoneNumber(phServiceStatus);
        });
      }
    });

    this.appStoreService.appControlData$.subscribe((data) => {
      previousRoute = data.previousRoute;
      asignAgentStatus = data.assignAgent;
      if (previousRoute !== undefined) {
        if (previousRoute[1] === 'ratesummary') {
          if (asignAgentStatus === 'completed' || asignAgentStatus === 'fail') {
            this.setAgentDetails();
          } else {
            this.agentAssigned = false;
          }
        } else {
          this.setAgentDetails();
        }
      } else {
        this.setAgentDetails();
      }
    });

    this.appStoreService.getPhoneNumberServiceStatus$.subscribe(phServiceStatus => {
      if (phServiceStatus === 'success' || phServiceStatus === 'fail') {
         this.updatePhoneNumber(phServiceStatus);
      }
    });

    this.appStoreService.appData$.subscribe(data => {
      if (data) {
        this.quoteType = data.quoteType;
      }
    });

    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (width < 600) {
      this.hideDesktopLabel = true;
    }
    /** US377239 : schedule agent confirmation ADA changes */
    this.scheduleAgentSubsciption = this.scheduleAgentService.getConfmDialogCloseEvent().subscribe(() => {
      this.scheduleAgentElement.nativeElement.focus();
    });

    this.appStoreService.getProjectCodeStatus$.subscribe((data: any) => {
      this.isSchdAgentAppiontmentRequired = data[`isScheduleAgent`];
    });
  }
  /**
   * Set Agent Details
   */
  setAgentDetails() {
    this.appStoreService.appDataAgent$.subscribe((data: any) => {
      if (data.agent && (data.agent.agent || data.agent.agentInfoBean)) {
        this.agentDetails = data.agent.agent ? data.agent.agent : data.agent.agentInfoBean;
        if (this.agentDetails.reference === '1') {
          this.agentAssigned = true;
          if (this.agentDetails.firstName || this.agentDetails.lastName) {
            this.agentName = this.agentDetails.firstName + ' ' + this.agentDetails.lastName;
          }
          if (this.agentDetails.address.city || this.agentDetails.address.state || this.agentDetails.address.zip) {
            this.agentLocation = this.agentDetails.address.city + ', ' + this.agentDetails.address.state + ' ' +
              this.agentDetails.address.zip;
          }
          if (this.agentDetails.address.street) {
            this.ariaLabelStreet = this.agentDetails.address.street.replace(/[^\w\s]/gi, '');
          }
          if (this.agentDetails.communication.phoneNumber.phoneNumber &&
            this.agentDetails.communication.phoneNumber.phoneNumber !== '1-800-FARMERS') {
            this.agentPhoneNumber = this.agentDetails.communication.phoneNumber.phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/,
              '$1-$2-$3');
            this.agentPhoneNumberCC = '1-'.concat(this.agentDetails.communication.phoneNumber.phoneNumber);
          }
          const googleLocquerry = this.agentDetails.address.street + ', ' + this.agentLocation;
          this.googleMaplocation = 'http://maps.google.com/?q=' + googleLocquerry;

        } else if (this.agentDetails.reference === 'D') {
          this.agentAssigned = false;
        } else {
          this.agentAssigned = false;
        }
      }
    });
  }
  /**
   * Updates phone number
   */
  updatePhoneNumber(serviceCallStatus) {
    this.appStoreService.getProjectCodeStatus$.subscribe((data: any) => {
      this.gwEEImpl = data[`isEEImpl`];
      this.isTFNProjectImpl = data[`isTFNProjectImpl`];
      this.sourcePhoneNumber = this.helperService.getSrcPhoneNumber(serviceCallStatus);
      if (this.sourcePhoneNumber) {
        this.teleDesktop = this.teleMobile = this.gwEEImpl ? 'tel:' + this.sourcePhoneNumber :
          'tel:' + this.sourcePhoneNumber.replace(/-/g, '');
        this.ariaLabelPhoneNumber = this.gwEEImpl ? this.sourcePhoneNumber : this.sourcePhoneNumber.replace(/-/g, '');
        this.desktopNo = this.mobileNo = this.sourcePhoneNumber;
      }

      if (!this.sourcePhoneNumber) {
        if (this.gwEEImpl && !this.isTFNProjectImpl) {
          this.teleDesktop = 'tel:1-888-767-4030';
          this.teleMobile = 'tel:1-888-767-4030';
          this.ariaLabelPhoneNumber = '1-888-767-4030';
          this.desktopNo = '1-888-767-4030';
          this.mobileNo = '1-888-767-4030';
        } else {
          /**
           * US501061 && US501062: CA FFQ Digital Monetization - CA State TFN Replacement
           * for Agent, Quote, Thank you and print quote pages for desktop and mobile windows
           */
          if (this.isTFNProjectImpl && this.isQuoteRated && !this.agentAssigned) {
            this.teleDesktop = DataConstants.TFN_HELP_NUMBER.teleDesktop;
            this.teleMobile = DataConstants.TFN_HELP_NUMBER.teleMobile;
            this.ariaLabelPhoneNumber = DataConstants.TFN_HELP_NUMBER.ariaLabelPhoneNumber;
            this.desktopNo = DataConstants.TFN_HELP_NUMBER.desktopNo;
            this.mobileNo = DataConstants.TFN_HELP_NUMBER.mobileNo;
          } else {
          this.teleDesktop = 'tel:18002069469';
          this.teleMobile = 'tel:18004209008';
          this.ariaLabelPhoneNumber = '18002069469';
          this.desktopNo = '1-800-206-9469';
          this.mobileNo = '1-800-420-9008';
          }
        }
      }
    });
  }

  // US376058: Opening schedule agent appointment modal
  openApptModal(): void {
    this.dialog.open(ScheduleAgentAppointmentComponent, {
      panelClass: 'schedule-agent-dialog',
      maxWidth: '700px',
      id: 'appointmentModal',
      autoFocus: false,
      data: this.agentDetails,
      disableClose: true
    });
    this.segmentService.trackCtaClicked('Schedule Agent Appointment button clicked');
  }
}
