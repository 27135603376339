import { Component, OnInit, Input, EventEmitter, Output, AfterContentChecked, ViewChild, HostListener } from '@angular/core';
import { FormInputParam } from '../form-input-params';
import { MatSelect } from '@angular/material/select';
import { FormFieldEmitParams } from '../params/form-field-emit-params';

@Component({
  selector: 'lib-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit, AfterContentChecked {

  @Input() params: FormInputParam;
  @Input() hasCoverageError: boolean;
  @Output() selectedData: EventEmitter<any> = new EventEmitter();

  add = true;

  Object = Object;

  isExpanded: boolean;

  expansionLabels: any;

  /**
   * US255604 - Segment Tracking Field Engagement
   */
  @Output() selectFieldValue: EventEmitter<FormFieldEmitParams> = new EventEmitter();
  lastSelectValue: string;

  /**
   * Variable declared to handle focus scenario in ADA
   */
  enableFocusRing = false;
  arialabel: string;
  isMultiline = ' ';
  @ViewChild('matSelect')
  matSelect: MatSelect;

  constructor() { }

  ngOnInit() {
   window.addEventListener('scroll', this.scrollEvent, true);
   this.params.ariaLabel = this.params.ariaLabel && this.params.ariaLabel.replace('collapsed', '');
   this.expansionLabels = this.params.ariaLabel;

   if (this.params.pageTitle) { 
      this.arialabel = this.params.ariaLabel;
      if (this.params.pageTitle.length !== 0) {

          this.params.ariaLabel = this.params.pageTitle + ' ' + this.params.ariaLabel;
            }
          }
  }

  focusHandle() {
    this.enableFocusRing = true;
  }
  blurHandle() {
    this.enableFocusRing = false;
  }

  ngAfterContentChecked() {
    if (this.params.id === 'occupation' && this.params.formGroup.value.occupation && (this.add)) {
      const sample = this.params.formGroup.value.occupationDesc;
      this.params.ariaLabel = 'Occupation is' + ' ' + sample + ' ' + 'which is re-selectable';
     } else if (this.params.id === 'maritalStatus' && this.params.formGroup.value.maritalStatus && (this.add)) {
      let sample = this.params.formGroup.value.maritalStatus;
      this.params.values.forEach((maritalstatusvalue: any) => {
         if (maritalstatusvalue.value === sample) {
            sample = maritalstatusvalue.label;
         }
      });
      this.params.ariaLabel = 'Marital status is' + ' ' + sample + ' ' + 'which is re-selectable';
     } else if (this.params.id === 'lapseInCoverage' && this.params.formGroup.value.lapseInCoverage && (this.add)) {
      const sample = this.params.formGroup .value.lapseInCoverage;
      this.params.ariaLabel = 'Length of continuous coverage is' + ' ' + sample + ' ' + 'which is re-selectable';
     } else if (this.params.id === 'bodilyInjuryLimitCd' && this.params.formGroup.value.bodilyInjuryLimitCd && (this.add)) {
      const sample = this.params.formGroup .value.bodilyInjuryLimitCd;
      this.params.ariaLabel = 'Bodily Injury Limits is' + ' ' + sample + ' ' + 'which is re-selectable';
     } else if (this.params.id === 'pni' && this.params.formGroup.value.pni && (this.add)) {
      let sample = this.params.formGroup .value.pni;
      this.params.values.forEach((pnivalue: any) => {
        if (pnivalue.value === sample) {
           sample = pnivalue.label;
        }
     });
      this.params.ariaLabel = 'Relationship to the applicant is' + ' ' + sample + ' ' + 'which is re-selectable';
     } else if (this.params.id === 'incidentDescription' && this.params.formGroup.value.incidentDescription && (this.add)) {
      let sample = this.params.formGroup .value.incidentDescription;
      this.params.values.forEach((incidentvalue: any) => {
        if (incidentvalue.value === sample) {
           sample = incidentvalue.label;
        }
        this.params.ariaLabel = 'Description is' + ' ' + sample + ' ' + 'which is re-selectable';
     });
  } else if (this.params.id === 'townSelect' && this.params.formGroup.value.townSelect && (this.add)) {
    const sample = this.params.formGroup.value.townSelect;
    this.params.ariaLabel = 'Town is' + ' ' + sample + ' ' + 'which is re-selectable';
  } else if (this.params.id === 'citySelect' && this.params.formGroup.value.city && (this.add)) {
    const sample = this.params.formGroup.value.city;
    this.params.ariaLabel = 'City is' + ' ' + sample + ' ' + 'which is re-selectable';
    } else if (this.params.pageTitle === 'Additional Info Details') {
      let sample = this.params.formGroup.value[this.params.id];
      if (sample) {
        this.params.values.forEach((pnivalue: any) => {
          if (pnivalue.value === sample) {
            sample = pnivalue.label;
          }
        });
        this.params.ariaLabel = this.params.contentCheckedLabel + ' ' + sample + ' ';
      }

    }
}

  selectedValue(event: any) {
    this.selectedData.emit(this.params.formGroup.controls[this.params.formControllName].value);
    const target = event.source.selected._element.nativeElement;
    const selectedDropdownData = {
      value: event.value,
      text: target.innerText.trim()
    };

    // US145574: ADA- Auto Quote- Combo box for financed and Leased vehicles are not announced as expected in browsers and devices
    const arr = this.params.ariaLabel ? this.params.ariaLabel.split(' ') : null;
    if (arr && this.params.ariaLabel.includes('selected')  && arr[ arr.length - 1 ] !== 'selected') {
      const trimedValue = this.params.ariaLabel.substr(0, this.params.ariaLabel.indexOf('selected'));
      this.params.ariaLabel = trimedValue + 'selected ' + selectedDropdownData.text;
    } else {
      this.params.ariaLabel = selectedDropdownData.text + ' selected';
    }

    if ((this.params.ariaLabel === 'Occupation combo box please select') ||
    (this.params.ariaLabel === 'Marital Status combo box please select') ||
    (this.params.ariaLabel === 'Length of continuous coverage combo box please select') ||
    (this.params.ariaLabel === 'Bodily Injury Limits combo box please select') ||
    (this.params.ariaLabel === 'Relationship to applicant combo box please select') ||
    (this.params.ariaLabel === 'Description combo box please select')) {
    this.params.ariaLabel = selectedDropdownData.text + ' ' + ' selected';
    this.add = false;
    }

    /**
     * US255604 - Segment Tracking Field Engagement
     */
    if (selectedDropdownData.text && selectedDropdownData.text !== this.lastSelectValue) {
      this.lastSelectValue = selectedDropdownData.text;
      this.selectFieldValue.emit({ value: selectedDropdownData.text, event: 'Value Added' });
    };
  }

  getOption(optionDesc) {
    if ((optionDesc === 'Named Insured and Resident Dependent Relatives' ) ||
    (optionDesc === 'Driving during Revocation or Suspension of License or Registration' ) ||
    (optionDesc === 'Speeding 10 mph or less over the speed limit in a speed zone of 55 - 70 mph')) {
      this.isMultiline = ' multi-line-option ';
    } else {
      this.isMultiline = ' ';
    }
    return this.isMultiline;
  }
// US321653: Select dropdowns are not responsive
  @HostListener("document:click", ["$event"])
  onClick = (event: any): void => {
    const elementId = event?.currentTarget?.activeElement?.id;
    if (elementId && !elementId.includes("mat-select") && this?.matSelect?.panelOpen) {
       this.matSelect.close();
    }
  }
// US321653: Select dropdowns are not responsive
  scrollEvent = (event: any): void => {
    const elementId = event.target?.id || event.target?.nodeName?.toLowerCase();
    if (elementId && !elementId.includes("mat-select") && this?.matSelect?.panelOpen) {
      this.matSelect.close();
    }
  }

}
