<div class="iidknockout-page-container">
    <div class="iidknockout-content">
        <div class="iidknockout-header_ffq-logo" title="Farmers &reg;" aria-label="Graphical Farmers Insurance Logo"></div>
        <div  class="knockoutiid_header-title-iid">
            <div *ngIf="showBadActorErrorMessage">
                <h1>We are unable to complete your request at this time.</h1>
            </div>
            <div *ngIf="!showBadActorErrorMessage">
                <h1>We're sorry. We are unable to complete your request at this time. 
                    <span *ngIf="!isMonetizationEligible">Please try again later.</span>
                </h1>
            </div>
        </div>
        <div *ngIf="isMonetizationEligible && !showBadActorErrorMessage">
            <div class="monetization_body_message" x-ms-format-detection="none">
                In the meantime, the following companies may be able to provide you with an online quote. Please compare 2 or
                more of the following options
            </div>        
            <div class="local-insurance-provier-list clearfix iid-knockout-content-section" id="Content">
            </div>
        </div>
        
        <div class="need_help" ngClass = "{{isMonetizationEligible ? ' monetization_need_help ' : ' need_help ' }}">
            <div *ngIf="displayAgentBlock" class="iidknockout_body_noagent"> Need more help?</div>
            <div *ngIf=!restictAgentDetailsImpl class="iidknockout_body_call-custome">You can call customer service at</div>
            <span *ngIf=!restictAgentDetailsImpl tabindex="0" [attr.aria-label]="'Call to action, contact farmers representative '+ ariaLabelDesktop" class="iidknockout_desktop">
                <div class="iidknockout_body_farmers" x-ms-format-detection = "none" aria-hidden="true">
                    <a href={{tele}} tabindex="0" role="link">{{desktopNo}}</a>
                </div>
            </span>
            <span *ngIf=!restictAgentDetailsImpl [attr.aria-label]="ariaLabelMobile" class="idknockout_mobile">
                <div class="iidknockout_body_farmers" x-ms-format-detection = "none" aria-hidden="true">
                    <a href={{teleMobile}} role="link">{{mobileNo}}</a>
                </div>
            </span>
        </div>

        <div *ngIf="displayAgentBlock" class="agent_button">
            <button (keyup.enter)="findAnAgent()" (click)="findAnAgent()"  role= "presentation" tabindex= "0" aria-label="Let's find an agent button link" mat-button class="ffq-card-button-blue" data-GTM="FFQ_Auto_error_letsfindagent_button">
                <span class="find_agent_text">LET'S FIND AN AGENT</span> 
            </button>
        </div>  
    </div>
</div>