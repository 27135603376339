import {
  Component, OnInit, Input, Output, EventEmitter, ViewChild,
  ElementRef, OnDestroy, ChangeDetectorRef, ɵConsole
} from '@angular/core';
import { FormInputParam } from '../form-input-params';
import { AbstractControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { FormFieldEmitParams } from '../params/form-field-emit-params';


@Component({
  selector: 'lib-autocomplete-typeahead',
  templateUrl: './autocomplete-typeahead.component.html',
  styleUrls: ['./autocomplete-typeahead.component.scss']
})
export class AutocompleteTypeaheadComponent implements OnInit {
  constructor(
    private changeDetector: ChangeDetectorRef) { }
  @Input() params: FormInputParam;
  @Input() inputError = false;
  @Input() newValue: any;
  @Output() employerParam: EventEmitter<any> = new EventEmitter();
  @Output() callingService: EventEmitter<any> = new EventEmitter();

  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;
  myControl: AbstractControl;
  Object = Object;
  filteredOptions: string[];
  proxyValue: any;

  /**
   * US255604 - Segment Tracking Field Engagement
   */
  @Output() searchCompletedValue: EventEmitter<FormFieldEmitParams> = new EventEmitter();
  @Output() searchActivatedValue: EventEmitter<FormFieldEmitParams> = new EventEmitter();
  inputValue: string;

  ngOnInit() {
    const formControlName = this.params.formControllName;
    this.myControl = this.params.formGroup.controls[formControlName];
  }

  showNewValues(dropDownValue: any) {
    this.params.values = dropDownValue;
    this.filteredOptions = dropDownValue;
    this.changeDetector.detectChanges();
  }

  displayFn = (value) => {
    if (value) {
      const selection = this.params.values.find(e => e.matchDisplayItem === value);
      this.employerParam.emit(selection);
      return selection ? selection.matchDisplayItem : value;
    }
  }

  onInputChanged(event$: Event): void {
    /**
     * US255604 - Segment Tracking Field Engagement
     */
    const input = event$.target as HTMLInputElement;
    this.inputValue = input.value;
    if (this.inputValue.length === 3) {
      this.searchActivatedValue.emit({ value: this.inputValue, event: 'Search Activated' });
    }
  }

  onSelectionChanged(event$): void {
    this.proxyValue = event$.option.value;
    /**
     * US255604 - Segment Tracking Field Engagement
     */
    this.searchCompletedValue.emit({ value: this.proxyValue, event: 'Search Completed' });
  }
}
