import { Component, OnInit, OnDestroy, ViewChild, Type } from '@angular/core';
import { ChatbotService } from './chatbot.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AppStoreService } from '@ffq-app-store/app.store.service';
import { Subscription } from 'rxjs';
import { HelperService } from '@ffq-app-shared/services/helper.service';
import { YourInfoStoreService } from '@ffq-app-auto/your-info/store/your-info.store.service';
import { AutoStoreService } from '@ffq-app-auto/store/auto.store.service';
import { ServiceConstants } from '@ffq-app-shared';
import { MatExpansionPanel } from '@angular/material/expansion';



@Component({
  selector: 'app-ffq-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss']
})
export class ChatbotComponent implements OnInit, OnDestroy {
  @ViewChild(MatExpansionPanel) chatPanel: MatExpansionPanel;

  /**
   * Subscriptions  of quote component
   */
  private subscriptions: Subscription[] = [];


  isBWQuote: boolean;
  payPolicy: string;
  agentBuyEnabled: string;
  isAddressStandrdzd: string;
  isbuyEnableInd: string;
  landingStateCode: string;
  landingZipCode: string;
  quoteNumber: any;
  quoteType: any;
  isChatBotImpl: boolean;
  chatHeading = 'Chat with your virtual agent';
  inactivePopUpTime: number;

  chatbotInfo = {
    test_email: false,
    residence: '',
    fullName: '',
    environment_url: '',
    parent_url: '',
    agentNumber: '',
    agentFullName: '',
    buyNow_Continue: false,
    userType: '',
    selectedCrossSellLobs: '',
    message: '',
    url: '',
  };

  cssClass = '';
  isChatbotInitialized = false;
  isChatbotAvailable = false;
  isChatbotConversationStarted = false;
  chatbotPanelOpenState = true;
  quoteWindowId: any;




  constructor(
    private autoStoreService: AutoStoreService,
    private appStoreService: AppStoreService,
    private yourInfoStoreService: YourInfoStoreService,
    private chatbotService: ChatbotService,
    private domSanitizer: DomSanitizer,
    private helperService: HelperService
  ) {

  }



  ngOnInit() {
    this.chatbotService.registerChatbot(this);
  }


  /** add chatbot iframe listener to the main window */
  attachOnmessage() {
    if (window.addEventListener) {
      window.addEventListener('message', this.childtoParent, false);
    } else {
      (window as any).attachEvent('onmessage', this.childtoParent);
    }
  }


  /**
   * Position chatbot container by data from iframe bot
   */
  childtoParent(event) {
    return;
    // const chatDiv = document.getElementById('chat_window_1');
    // chatDiv.style.position = event.data;
   // document.getElementById('contentWrapper').style.overflow = 'hidden';
    // document.getElementById('contentWrapper').style.position = 'relative';
    // document.getElementById('contentWrapper').style.height = '100%';

    // if (event.data === 'absolute') {
    //   setTimeout(() => {
    //     // window.scrollTo(0, document.body.scrollHeight);*
    //     window.scrollTo(0, 280);
    //   }, 100);
    // } else {
    //   document.getElementById('contentWrapper').style.overflow = '';
    //   // document.getElementById('contentWrapper').style.position = '';
    //   document.getElementById('contentWrapper').style.height = 'auto';
    // }
  }


  /**
   * Initialize chat-bot
   */
  initChatBot() {
    this.isChatbotInitialized = true;

    this.appStoreService.getProjectCodeStatus$
    .subscribe((data: any) => {
      // checking if chatbot allowed for this quote at the server side
      this.isChatBotImpl = data[`isChatBotImpl`];
    })
    .unsubscribe();

    /** Subscription for  eligibleForChat from rate quote response */
    let isTheQuoteChatEligible = false;
    const elgChatSub: Subscription = this.autoStoreService.getEligibleForChatBot$.subscribe(
      (data) => {
        isTheQuoteChatEligible = data;
      }
    );
    elgChatSub.unsubscribe();

    /** subscription for brightlined status to check whether it is BWQuote */
    this.autoStoreService.getBrightLinedData$.subscribe((data: any) => {
      this.isBWQuote = (data && data.autoCompanyCode && data.autoCompanyCode === 'B');
    });

    // if chatbot is not supported at the server and the quote is not eligible - cancel initialization
    if (!(this.isChatBotImpl && isTheQuoteChatEligible && !this.isBWQuote)) {
      return false;
    }

    this.attachOnmessage();

    /** subscription for landing state code */
    this.subscriptions.push(
      this.appStoreService.appDataZip$
      .subscribe((data: any) => {
        this.landingStateCode = data[`landingStateCode`];
        this.quoteNumber = data[`quoteNumber`];
        this.quoteType = data[`quoteType`];
        this.landingZipCode = data[`landingZipCode`];
      })
    );

    this.subscriptions.push(
      this.appStoreService.appDataAgent$
        .subscribe((data: any) => {
          this.chatbotInfo.agentNumber = data.agent.agent.communication.phoneNumber.phoneNumber;
          this.chatbotInfo.agentFullName = data.agent.agent.firstName + ' ' + data.agent.agent.lastName;
          this.agentBuyEnabled = data.agent.agent ? data.agent.agent.isBuyEnabled : data.agent.agentInfoBean.isBuyEnabled;
        })
    );

    /** subscription for landing state code */
    this.appStoreService.appData$
      .subscribe((data: any) => {
        this.quoteWindowId = data[`quoteWindowId`];
        const custEmailID =
          data.customerData.customer.communication.emailAddress;
        this.chatbotInfo.test_email =
          (custEmailID === 'autobindoptout@yahoo.com' ||
            custEmailID === 'autobindoptout@gmail.com' ||
            custEmailID === 'autobindoptout@hotmail.com');
      })
      .unsubscribe();

    this.subscriptions.push(
      this.appStoreService.appCustomerData$
        .subscribe((data: any) => {
          this.chatbotInfo.fullName += data.firstName + ' ' + data.lastName;
          this.chatbotInfo.environment_url = location.host;
          this.chatbotInfo.parent_url =
            location.protocol + '//' + location.host + '/fastQuote';
        })
    );


    this.subscriptions.push(
      this.autoStoreService.getDataForRateQuote$.subscribe((data: any) => {
        this.chatbotInfo.residence =
          data.residenceType === '8' ? 'town' : 'home';
      })
    );

    /** subscription for paypolicy */
    this.subscriptions.push(
      this.autoStoreService.getDiscountData$.subscribe((data: any) => {
        this.payPolicy = data.payPolicy;
      })
    );

    this.subscriptions.push(
      this.autoStoreService.getQuoteRateData$.subscribe((data: any) => {
        this.chatbotInfo = {
          ...this.chatbotInfo,
          userType: data.userType,
          selectedCrossSellLobs: data.payPlanChanged,
          message: 'Welcome back',
          url: ServiceConstants.CHATBOT_URL,
        };
      })
    );


    /** check is 'buy now' available. taken from quote */
    if (this.landingStateCode !== 'FL') {

      this.subscriptions.push(
        this.yourInfoStoreService.yourInfoLoadData$.subscribe((data) => {
          this.isAddressStandrdzd = data.customer.address.isStandardized;
        })
      );


      this.chatbotInfo.buyNow_Continue = !(
          this.isAddressStandrdzd === 'Y' &&
          this.isbuyEnableInd === 'Y'
      );
    }

    return true;
  }

  startChatBotOnTimeout() {
    this.startChatBot();
  }

  openChatbotPanelOnTimeout() {
    const popUpTime = ServiceConstants.POPUP_TIME.split('|');
    const continuePopupTime = +popUpTime[0];
    const buyNowPopUpTime = +popUpTime[1];
    this.inactivePopUpTime =
      this.chatbotInfo.buyNow_Continue ? continuePopupTime : buyNowPopUpTime;
    if (this.chatPanel) {
    setTimeout(() => {
      this.chatPanel.open();
    }, this.inactivePopUpTime);
  }
  }

  /**
   * Starting chatbot or open.
   * Be sure all initial data are present.
   * If initialization fails it will never let you start it again.
   *
   */
  startChatBot() {
    // temp for debug
    // this.isChatbotInitialized = false;
    // this.isChatbotConversationStarted = false;


    // init chatbot
    if (!this.isChatbotInitialized) {
      this.isChatbotAvailable = this.initChatBot();
    }

    // never open if initialization is failed
    if (this.isChatbotAvailable) {
      // remove hidden css class
      this.cssClass = '';

      if (!this.isChatbotConversationStarted) {
        this.isChatbotConversationStarted = true;

        // TODO: remove 'helperService.getModuleNameByRoute' if never used
        //        now we make call for chatbot opening explicitly from quote page
        //        so we don't need to track a routing anymore
        // if we are still in the 'quote' module
        // if (this.helperService.getModuleNameByRoute() === 'QUOTE') {
        this.chatbotService.initChatbotServerConversation({
          lob: this.quoteType,
          quoteNumber: this.quoteNumber,
          quoteWindowId: this.quoteWindowId,
        });

        // schedule chatbot panel to open automatically
        this.openChatbotPanelOnTimeout();

        // giv time template to render chat panel and iframe
        setTimeout(() => {
          this.callPost();
        }, 500);

    } else {
        // notify chat frame
        if (document.getElementById('iframe_id')) {
          (document.getElementById(
            'iframe_id'
          ) as any).contentWindow.postMessage(
            this.chatbotInfo,
            '*'
            // ServiceConstants.CHATBOT_URL
          );
        }

        // open panel
        if (this.chatPanel.closed) {
          this.openChatbotPanelOnTimeout();
        }
      }
    }
  }

  onChatOpen() {
    this.chatbotPanelOpenState = true;
    if (this.chatPanel) {
      this.chatPanel.open();
    }
    this.chatHeading = 'Continue with your chat';
  }

  callPost() {
    const iframeUrl = ServiceConstants.CHATBOT_URL + 'post';
    this.domSanitizer.bypassSecurityTrustUrl(iframeUrl);

    // form post starts

    const form = document.createElement('FORM') as HTMLFormElement;
    form.method = 'POST';
    form.style.display = 'none';
    document.body.appendChild(form);
    form.action = iframeUrl;
    form.id = 'iframe_form';
    form.target = 'iframe_a';

    const input1 = document.createElement('INPUT') as HTMLInputElement;
    input1.type = 'hidden';
    input1.name = 'quote_number';
    input1.value = this.quoteNumber;
    form.appendChild(input1);

    const input2 = document.createElement('INPUT') as HTMLInputElement;
    input2.type = 'hidden';
    input2.name = 'name';
    input2.value = this.chatbotInfo.fullName;
    form.appendChild(input2);

    const input3 = document.createElement('INPUT') as HTMLInputElement;
    input3.type = 'hidden';
    input3.name = 'agent_phone';
    input3.value = this.chatbotInfo.agentNumber;
    form.appendChild(input3);

    const input4 = document.createElement('INPUT') as HTMLInputElement;
    input4.type = 'hidden';
    input4.name = 'agent_name';
    input4.value = this.chatbotInfo.agentFullName;
    form.appendChild(input4);

    const input5 = document.createElement('INPUT') as HTMLInputElement;
    input5.type = 'hidden';
    input5.name = 'user_type';
    input5.value = this.chatbotInfo.userType;
    form.appendChild(input5);

    const input6 = document.createElement('INPUT') as HTMLInputElement;
    input6.type = 'hidden';
    input6.name = 'inactive_time';
    input6.value = this.inactivePopUpTime.toString();
    form.appendChild(input6);

    const input7 = document.createElement('INPUT') as HTMLInputElement;
    input7.type = 'hidden';
    input7.name = 'lobs_quoted';
    input7.value = this.quoteType;
    form.appendChild(input7);

    const input8 = document.createElement('INPUT') as HTMLInputElement;
    input8.type = 'hidden';
    input8.name = 'zip_code';
    input8.value = this.landingZipCode;
    form.appendChild(input8);

    const input9 = document.createElement('INPUT') as HTMLInputElement;
    input9.type = 'hidden';
    input9.name = 'environment_url';
    input9.value = this.chatbotInfo.environment_url;
    form.appendChild(input9);

    const input10 = document.createElement('INPUT') as HTMLInputElement;
    input10.type = 'hidden';
    input10.name = 'residence_type';
    input10.value = this.chatbotInfo.residence;
    form.appendChild(input10);

    const input11 = document.createElement('INPUT') as HTMLInputElement;
    input11.type = 'hidden';
    input11.name = 'test_email';
    input11.value = this.chatbotInfo.test_email.toString();
    form.appendChild(input11);

    const input12 = document.createElement('INPUT') as HTMLInputElement;
    input12.type = 'hidden';
    input12.name = 'parent_url';
    input12.value = this.chatbotInfo.parent_url;
    form.appendChild(input12);

    const input13 = document.createElement('INPUT') as HTMLInputElement;
    input13.type = 'hidden';
    input13.name = 'buyOptions';
    input13.value = this.chatbotInfo.buyNow_Continue.toString();
    form.appendChild(input13);

    // live chat changes starts here

    const input14 = document.createElement('INPUT') as HTMLInputElement;
    input14.type = 'hidden';
    input14.name = 'isLiveChat';
    input14.value = 'N';
    form.appendChild(input14);

    const input15 = document.createElement('INPUT') as HTMLInputElement;
    input15.type = 'hidden';
    input15.name = 'appName';
    input15.value = 'chatbot';
    form.appendChild(input15);

    // live chat changes ends here

    console.log('form is', form);
    console.log('url', input9.value);
    form.submit();
    console.log('document', document.getElementById('iframe_id'));
    console.log('document', document.getElementById('chatbotid'));
    console.log('document', document.getElementById('accord_grp'));
    console.log('document', document.getElementById('form.target'));

    // form post ends
  }

  ignoreClick($event: any) {
    $event.stopPropagation();
    $event.preventDefault();
  }

  /**
   * on destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => { subscription.unsubscribe(); });
  }

  hideChatBot() {
    this.cssClass = 'hidden';
  }

}
