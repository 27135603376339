<mat-dialog-content class="auto-module__signal-banner-dialog">
    <div class="auto-module__signal-banner-dialog-container">
        <div class="auto-module__signal-banner-dialog-header" role="heading" aria-level="2">
            <span class="auto-module__signal-banner-dialog-header-text" >Save with Signal®</span>
        </div>
        <div class="auto-module__signal-banner-dialog-content">
            <div class="auto-module__signal-banner-dialog-content-header" >{{headerText}}</div>
            <div class="auto-module__signal-banner-dialog-content-body" >
                <span aria-owns="discountfootnote discountfootnote1 discountfootnote2">
                <p id="discountfootnote">{{contentText}}</p>
                </span>
            </div>

            <div class="auto-module__signal-banner-dialog-content-header">Sign up and start saving</div>
            <div class="auto-module__signal-banner-dialog-content-body" [innerHTML]="popupContent">

            </div>
            <div *ngIf="!signalEligibilityDesc" class="auto-module__signal-banner-dialog-content-disclaimer" >
                
               <label id="discountfootnote1" aria-label="Signal Sweepstakes not available in Arkansas and Minnesota.">
                <p aria-hidden="true">{{signalAvailableDesc}}</p>
            </label>
                <p id="discountfootnote2" class="word-space-0-5px">
                    Percentage discount at policy renewal may vary by state. Visit 
                    <a class="word-break-all" [attr.data-GTM]='signalBannerFarmersLink' href="https://www.farmers.com/signal/" target="_blank" aria-label = "Farmers/signal. Opens in new tab" >farmers.com/signal</a> for details.</p>
               
            </div>
            <div *ngIf="signalEligibilityDesc" class="auto-module__signal-banner-dialog-content-disclaimer" > 
                <label aria-label="Signal Sweepstakes not available in Arkansas and Minnesota.">
                    {{signalAvailableDesc}}
                </label>
                <label  id="discountfootnote1" aria-label="Signal discount is subject to eligibility requirements.">
                    Signal discount is subject to eligibility requirements.
                    Percentage discount at policy renewal may vary by state. Visit 
                    <a class="word-break-all" [attr.data-GTM]='signalBannerFarmersLink' href="https://www.farmers.com/signal/" target="_blank" aria-label = "Farmers/signal. Opens in new tab" >farmers.com/signal</a> for details.
                </label>
            </div>
        </div>
        <div class="auto-module__signal-banner-footer">
            <button mat-button class="auto-module__signal-banner-btn" (click)="closeSignalBannerPopup()"
                data-GTM="FFQ_Auto_Signal_Banner_Close_button">CLOSE</button>
        </div>
    </div>
</mat-dialog-content>