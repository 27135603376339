import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
@Component({
  selector: 'lib-card-assignment',
  templateUrl: './card-assignment.component.html',
  styleUrls: ['./card-assignment.component.scss']
})
export class CardAssignmentComponent implements OnInit {
  @Input() params: any;
  @Output() cardData: EventEmitter<any> = new EventEmitter();




  constructor() { }

  ngOnInit() {
    this.params.forEach((driver) => {
      driver.checkBoxCtrl.values.forEach((value) => {
        value.gtmTag = `FFQ_Auto_VDassign_${this.isBW(driver)}${driver.landingStateCode}veh_${value.label}_${this.isSelector(driver)}`;
      });
    });
  }

  isSelector(driver: any) {
    if (driver.selector) {
      return 'dropdown';
    } else {
      return 'checkbox';
    }
  }

  isBW(data) {
    if (data.isBWQuote) {
      return 'BW';
    } else {
      return '';
    }
  }

  onVehicleChange(selected: any, drvreference: string) {



    const result = {
        driverreference: drvreference,

        vehiclereference: selected
    };

    this.cardData.emit( result );
  }
  onDriverChange(e: any) {
     const result = {
    };
     this.cardData.emit( result );
  }

}
