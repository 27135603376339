import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DataConstants } from '@ffq-app-shared/constants';
import { AppStoreService } from '../../store/app.store.service';
import { RetrieveQuoteRequestObject } from '../model/landing.model';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

export interface Element {
  quoteNumber: string;
  type: string;
  status: string;
}

@Component({
  selector: 'app-multi-retrieve-quote',
  templateUrl: './multi-retrieve-quote.component.html',
  styleUrls: ['./multi-retrieve-quote.component.scss']
})
export class MultiRetrieveQuoteComponent implements OnInit, AfterViewInit {
  @Input() public multiQuoteVO;
  constructor(private appStoreService: AppStoreService, public breakpointObserver: BreakpointObserver) { }
  displayedColumns: string[] = [];
  dataSource: MatTableDataSource<Element>;
  retrieveQuoteRequestObject = {} as RetrieveQuoteRequestObject;
  isSmallBreakpoint: boolean;

  ngOnInit(): void {
    this.displayedColumns = ['quoteNumber', 'type', 'status', 'action'];
    this.loadTable();
    this.appStoreService.appData$.subscribe(data => {
      const customerData = data ? data.customerData : '';
      if (customerData) {
        this.retrieveQuoteRequestObject.lastName = customerData.lastName;
        this.retrieveQuoteRequestObject.dateOfBirth = customerData.dateOfBirth;
      }
      this.retrieveQuoteRequestObject.landingZipCode = data.landingZipCode;
    });
    // US359614: Mobile breakpoint development of MulitQuote Screen
    this.breakpointObserver
      .observe(['(max-width: 599px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isSmallBreakpoint = true;
        }
      });
  }
/**
 * after View Init
 */
  ngAfterViewInit(): void {
    /** DE77316 : add focus to element on page route */
    const element = document.getElementById('ffq-auto-module__multi-quote-text-hidden');
    if (element) {
      element.focus();
    }
  }

  loadTable(): void {
    const ELEMENT_DATA: Element[] = this.multiQuoteVO;
    this.dataSource = new MatTableDataSource<Element>(ELEMENT_DATA);
  }

  getLob(quoteType: string, lobDesc: string): string {
    /** US364506: handling FWS policy types */
    if ( typeof lobDesc !== undefined && lobDesc ) {
      return lobDesc;
    }
    switch (quoteType) {
      case DataConstants.LOB.auto:
        return 'Auto';
      case DataConstants.LOB.business:
        return 'Commercial';
      case DataConstants.LOB.home:
        return 'Home';
      case DataConstants.LOB.renter:
        return 'Rent';
      case DataConstants.LOB.condo:
        return 'Condo';
      /** DE74764: added Life lob mappings */
      case DataConstants.LOB.gdb:
        return 'Graded Whole Life';
      case DataConstants.LOB.sws:
        return 'Simple Whole Life';
      case DataConstants.LOB.stl:
        return 'Simple Term Life';
      case DataConstants.LOB.life:
        return 'Value Term Life';
    }
  }

  /**
   * US355524: Multi Quote UI Redirection for new landing page migration
   */
  callReviewRetrieveQuote(quoteData: object): void {

    this.retrieveQuoteRequestObject.isEncrypted = false;
    this.retrieveQuoteRequestObject.quoteNumber = quoteData[`quoteNumber`];
    this.retrieveQuoteRequestObject.emailAddress = '';
    this.appStoreService.retriveQuote(this.retrieveQuoteRequestObject);
  }
}
