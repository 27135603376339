<mat-dialog-content class="auto-module__bristol-west-dialog">
    <div>
        <div tabindex="0" role="heading" aria-level="2" mat-dialog-title class="bristol-west-header-text">
           Continue your quote request with Farmers General Insurance Agency, Inc
        </div>
    </div>

    <div class="bristol-west-header-text-line"></div>
    <div id="dnqMessage">
        <div class="bristol-west-content-text">
            <p >
                {{dialogContent}}
              <!-- We are unable to offer you an online quote for a Farmers-branded policy at this time. Farmers General Insurance Agency, Inc (FGIA) may be able to offer you a quote through other companies, or you can contact a Farmers agent in your area. -->
            </p>
           
          </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="dialog__info-dialog-button-holder" align="end">
    
<div >
    <button mat-button class="dialog__info-dialog-button-no-thanks"  aria-label="Continue button Link" 
    (click)="redirectToMonetizationPage()" >No, thanks</button>
    <button class="button-width-bw auto-module_continue-to-bw-button-primary" role="button" tabindex="0"
    type="save" mat-button mat-primary align="end" aria-labelledby="Continue to Bristol West" (click)="redirectToFgiaUrl()">
    <span >Continue to FGIA&nbsp;
    </span>
</button>
</div>
</mat-dialog-actions>