<ng-container [formGroup]="params.formGroup">
<div id="opt_{{params.formControllName}}" class="ffq-lib-radio" [ngClass]="{'mat-form-field-invalid':isInputError()}">
    <mat-radio-group [ngClass]="params.classes ? params.classes : null" id={{params.id}} [attr.aria-label]="params.ariaLabel" attr.aria-invalid="{{isError}}" attr.aria-describedby="error_opt_{{params.formControllName}}" [formControl]="params.formGroup.controls[params.formControllName]" (change)="selectedValue($event)"  >
      <div *ngFor="let option of params.values">
        <mat-radio-button [value]="option.value" color="primary"  [attr.data-GTM]="option.gtmTag">
          {{ option.label }}
        </mat-radio-button>
        <h4 class="ffq-lib__driver_age-margin" role="presentation" *ngIf="option.age">{{ option.age }}</h4>
</div>

</mat-radio-group>
<mat-hint *ngIf="params.assistiveText" align="start"><strong>{{params.assistiveText}}</strong> </mat-hint>
 <mat-error *ngIf="isError" id="error_opt_{{params.formControllName}}" >
 {{errorMessage}}
</mat-error>
</div>
</ng-container>
