import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '..';

@Component({
  selector: 'lib-action-dialog-new',
  templateUrl: './action-dialog-new.component.html',
  styleUrls: ['./action-dialog-new.component.scss']
})
export class ActionDialogNewComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ActionDialogNewComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
  }
  dialogActionSelected(action) {
    if (action === 'cancel') {
      this.dialogRef.close({action: 'cancel'});
    } else if (action === 'accept') {
      this.dialogRef.close({action: 'accept'});
    }
  }

}
