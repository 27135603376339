import { createAction, props } from '@ngrx/store';

// tslint:disable-next-line:max-line-length
export const FetchCoverageDropDownList = createAction('[Auto-Quote] Fetch Coverage DropDown List', props<{ reqPayload: any }>());
// tslint:disable-next-line:max-line-length
export const FetchCoverageDropDownListSuccess = createAction('[Auto-Quote] Fetch Coverage DropDown List Success', props<{ respData: any, ctrlData: any }>());
export const FetchCoverageDropDownListFail = createAction('[Auto-Quote] Fetch Coverage DropDown List Fail', props<{ respError: any }>());
export const FetchCoverageDropDownListControlDataClear =
    createAction('[Auto-Quote] Fetch Coverage DropDown List Control data clear', props<{ ctrlData: any }>());

/** ACTION FOR PERFORM RATE SERVICE */
export const PerformRateQuote = createAction('[Auto-Quote] Pefrom Rate Quote', props<{ reqPayload: any }>());

/** ACTION FOR SAVE QUOTE SERVICE */
export const saveQuote = createAction('[Auto-Quote] Save Rate Quote', props<{ reqPayload: any, mode: any }>());
/** ACTION FOR SAVE QUOTE and Finish SERVICE */
export const SaveQuoteAndFinish = createAction('[Auto-Quote] Save Quote And Finish', props<{ reqPayload: any, mode: any }>());

/** ACTION FOR COVERAGE STATUS UPDATE */
export const UpdateQuoteControlData = createAction('[Auto-Quote] Update Coverage Status', props<{ ctrlData: any}>());

/** ACTION FOR UPDATING VEHICLE CUSTOM COVERAGE DATA */
export const UpdateVehicleCustomCoverage = createAction('[Auto-Quote] Update Vehicle Custom Coverage', props<{ reqPayload: any}>());
export const UpdateLiabilityCustomcoverage = createAction('[Auto-Quote] Update Liability Custom coverage', props<{ reqPayload: any }>());

/** ACTION FOR CUSTOM RATE */
export const CustomRate = createAction('[Auto-Quote] Custom Rate ', props<{ reqPayload: any}>());
