import { Component, OnInit, Input,  EventEmitter, Output, ViewChild, AfterViewInit } from '@angular/core';
import { FormInputParam } from '../form-input-params';
import { FormArray, FormControl } from '@angular/forms';
import { InfoDialogComponent } from '../../dialog';


@Component({
  selector: 'lib-check-box',
  templateUrl: './check-box.component.html',
  styleUrls: ['./check-box.component.scss']
})
export class CheckBoxComponent implements OnInit , AfterViewInit {

  @Input() params: FormInputParam;
  @Output() selectedData: EventEmitter<any> = new EventEmitter();
  Object = Object;
  arialabel;
  @ViewChild('search', {static: true}) searchElement: any;
  constructor( private infoDialogComponent: InfoDialogComponent ) { }

  ngOnInit() {

  }

  ngAfterViewInit() {
    setTimeout(() => {
      // tslint:disable-next-line:max-line-length
      if (this.searchElement) {
       if (this.searchElement._elementRef.nativeElement && this.params.pageTitle
        && this.params.pageTitle.length !== 0 && this.params.nolabel  && this.params.drvreference && this.params.drvreference === '1') {

          this.params.values[0].ariaLabel = this.params.pageTitle + this.params.values[0].ariaLabel;
          this.searchElement.focus();

      }
    }
    });

  }

  blurHandle(option: any , params: any) {
    if (params.pageTitle && params.pageTitle.length !== 0 && params.drvreference && params.drvreference === '1') {
      option.ariaLabel = option.ariaLabel.replace(params.pageTitle, '');
      params.pageTitle = '';
    }
  }
  onCheckChange(option, event) {
    this.params.formGroup.get(this.params.formControllName).markAllAsTouched();
    const formArray: FormArray = this.params.formGroup.get(this.params.formControllName) as FormArray;
    if (event.source.checked) {
      formArray.push(new FormControl(event.source.value));
    } else {
      let i = 0;
      formArray.controls.forEach((ctrl: FormControl) => {
        if (ctrl.value === event.source.value) {
          formArray.removeAt(i);
          return;
        }
        i++;
      });
    }
    this.selectedData.emit(this.params.formGroup.controls[this.params.formControllName].value);
  }
  showDialogInfo( info , event ) {
    event.stopPropagation();
    event.preventDefault();
    this.infoDialogComponent.openDialog(
      info.title,
      info.body
    );
  }
}
