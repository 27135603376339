<div #progressSpinnerRef *ngIf="displayProgressSpinner==true" class="loader-spinner-mask-holder">
    <div class="loader-mask">
    </div>
</div>
<div  *ngIf="displayProgressSpinner==true" class="loader-spinner-holder">
    <div class="loader-spinner">
        <mat-spinner></mat-spinner>
    </div>
</div>

<div  *ngIf="displayFarmersLoader"  class="loader-progress-mask-holder">
    <div class="farmers-loader-mask">
    </div>
</div>

<div *ngIf="displayFarmersLoader" class="loader-progress-holder">
    <div class="farmers-loader-progress">
        <div  class="header_ffq-loader-logo" title="Farmers &reg;"></div>
        <mat-progress-bar
          class="example-margin"
          
          [mode]="indeterminateMode">
      </mat-progress-bar>
      <div class="farmers-loader-text">One moment as we gather your information...</div>
    </div>
</div>
