<mat-dialog-content class="auto-module__bristol-west-dialog">
    <div>
        <div *ngIf="!isBWOrganicFlowState" tabindex="0" role="heading" aria-level="2" mat-dialog-title class="bristol-west-header-text">
            {{bristolwestPopupData.maintitle}}
        </div>
        <div *ngIf="isBWOrganicFlowState" tabindex="0" role="heading" aria-level="2" mat-dialog-title class="bristol-west-header-text">
            {{bristolwestPopupData.bwOrganicFlowMainContent}}
        </div>
    </div>

    <div class="bristol-west-header-text-line"></div>
    <div id="dnqMessage">
        <div class="bristol-west-title">
            <p [ngClass]="showDnqMessage ? 'bristol-west-reason-heading' : ''">
                {{bwPopupDescription}}
            </p>
            <ng-container *ngIf="showDnqMessage">
                <ul *ngFor="let reason of dnqReasons" class="bristol-west-reason-description">
                    <li>{{reason.description}}</li>
                </ul>
            </ng-container>
            <p class="bristol-west-reason-subtext" *ngIf="showDnqMessage">{{dnqMessageSectionData.reasonSubText}}</p>
        </div>
        <div class="bristol-west-image">
            <img [src]="imageSrc" class="bristol-west-logo"  alt={{logoText}} />
        </div>
        <div class="bristol-west-content-text">
            <p  *ngIf = "!isBWOrganicFlowState">
                {{bristolwestPopupData.subContent}}
            </p>
            <p *ngIf = "isBWOrganicFlowState">
                {{bristolwestPopupData.bwOrganicFlowSubContent}}
            </p>
            <p *ngIf = "bristolwestPopupData.subContentPilot && !isBWOrganicFlowState">
                {{bristolwestPopupData.subContentPilot}}
            </p>
            <p *ngIf = "isBWOrganicFlowState" >
                {{bristolwestPopupData.bwOrganicFlowSubContentPart1}}
            </p>
            <p *ngIf="isDNQState && !isBWOrganicFlowState">
                {{bristolwestPopupData.fcraContent[0]}}
                <a tabindex="0" class="bristol-west-link-dnq"
                href="tel:1-800-456-6004">1-800-456-6004 </a>
                <a tabindex="0" class="bristol-west-link" aria-label="Consumer Disclosure opens in new window"
                        (click)=goToExternalUrl() (keyup.enter)="goToExternalUrl()">Consumer Disclosure</a>{{bristolwestPopupData.fcraContent[1]}}</p>
            <p><a tabindex="0" *ngIf="isDNQPrivacyPolicyEnabled && !isBWOrganicFlowState" class="bristol-west-link-dnq"
                href="{{this.privacyPolicyLink}}" target="_blank"
                aria-label="Privacy Notice opens in new window">Privacy Notice</a></p>
            <p *ngIf="isBWOrganicFlowState">
                    {{bristolwestPopupData.bwOrganicFlowSubContentPart2[0]}}
                    <a tabindex="0" class="bristol-west-link-dnq"
                    href="tel:1-800-456-6004">1-800-456-6004 </a>
                    <a tabindex="0" class="bristol-west-link" aria-label="Consumer Disclosure opens in new window"
                            (click)=goToExternalUrl() (keyup.enter)="goToExternalUrl()">Consumer Disclosure</a>{{bristolwestPopupData.bwOrganicFlowSubContentPart3}}</p>
            <p *ngIf="isBWOrganicFlowState">{{bristolwestPopupData.bwOrganicFlowSubContentPart4}}</p>
            <p><a tabindex="0" *ngIf="isBWOrganicFlowState" class="bristol-west-link-dnq"
                    href="{{this.privacyPolicyLink}}" target="_blank"
                    aria-label="Privacy Notice opens in new window">Privacy Notice</a></p>
    
            <div *ngIf="isDNQSummaryOfRightsEnabled && !isBWOrganicFlowState">
                <p><a tabindex="0" class="bristol-west-link-dnq" role="button"
                    aria-label="Summary of Rights" [attr.aria-expanded]="!isCollapsed"
                    (click)="isCollapsed=!isCollapsed" (keydown.Space)="isCollapsed=!isCollapsed" (keyup.enter)="isCollapsed=!isCollapsed"
                    class="bristol-west-collapse">Summary of Rights</a></p>
                    <div *ngIf="!isCollapsed" class="bristol-west-content-text bw-dnq">
                        <div *ngIf="isDNQYourRightsEnabled">
                            <div *ngFor="let summaryOfRight of bristolwestPopupData.summaryOfRights; let index=index;">
                                <p *ngIf="index<3">{{summaryOfRight}}</p>
                                <div *ngIf="index>2">{{summaryOfRight}}</div>
                            </div>
                        </div>
                        <div *ngIf="!isDNQYourRightsEnabled && landingStateCode !== 'VA'">
                            <div *ngFor="let summaryOfRight of bristolwestPopupData.summaryOfRights; let index=index;">
                                <p *ngIf="index<=3">{{summaryOfRight}}</p>
                                <div *ngIf="index>3">{{summaryOfRight}}</div>
                            </div>
                        </div>
                        <!-- US465865: Summary of Rights verbiage for VA State -->
                        <div *ngIf="!isDNQYourRightsEnabled && landingStateCode === 'VA'">
                            <div *ngFor="let summaryOfRight of bristolwestPopupData.summaryOfRights; let index=index;">
                                <p *ngIf="index<=4">{{summaryOfRight}}</p>
                                <div *ngIf="index>4">{{summaryOfRight}}</div>
                            </div>
                        </div>
                        <br>
                        <div *ngIf="isDNQYourRightsEnabled">
                            <div *ngFor="let yourRights of bristolwestPopupData.yourRights; let index=index;">
                                <div [innerHTML]="yourRights"></div>
                                <br>
                            </div>
                        </div>
                    </div>
            </div>
    
            <p *ngIf="!isBWOrganicFlowState">You may also
                <span *ngIf ="bristolwestPopupData.subContentPilot; else contactUs">contact an agent</span>
                <ng-template #contactUs>
                <a tabindex="0" *ngIf ="!isEEImpl" class="bristol-west-link" (click)="toKnockOut()">{{bWDirectSaleContact}}</a>
                <span *ngIf ="isEEImpl">contact a representative at <a tabindex="0" class="bristol-west-link" style="text-decoration: none;" href={{teleDesktop}}>{{desktopNo}}</a></span>
                </ng-template> if you have any questions.
            </p>
            <p *ngIf="isBWOrganicFlowState">You may also contact an agent if you have any questions.
            </p>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="dialog__info-dialog-button-holder" align="end">
    <div *ngIf="!isBWOrganicFlowState">
    <button mat-button class="dialog__info-dialog-button-close" (click)="continueWithBristolWest()" aria-label="Continue button Link" [attr.data-GTM]="bristolwestPopupData.closebuttonGTM">{{bristolwestPopupData.buttonText}}</button>
</div>  
    
<div *ngIf="isBWOrganicFlowState">
    <button *ngIf="!isAwsSourceFlow && isFGIAPopupShow" mat-button class="dialog__info-dialog-button-no-thanks" (click)="toMonetizationScreen()" aria-label="Continue button Link" [attr.data-GTM]="bristolwestPopupData.closebuttonGTM">Other Options</button>
    <button *ngIf="!isAwsSourceFlow && !isFGIAPopupShow" mat-button class="dialog__info-dialog-button-no-thanks" (click)="toMonetizationScreen()" aria-label="Continue button Link" [attr.data-GTM]="bristolwestPopupData.closebuttonGTM">No, thanks</button>
    <button class="button-width-bw auto-module_continue-to-bw-button-primary" (click)="continueWithBristolWest()" role="button" tabindex="0"
    type="save" mat-button mat-primary align="end" aria-labelledby="Continue to Bristol West">
    <span >Continue to Bristol
        West&nbsp;
    </span>
</button>
</div>
</mat-dialog-actions>