import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormInputParam } from '../form-input-params';
import { FormFieldEmitParams } from '../params/form-field-emit-params';
@Component({
  selector: 'lib-address-autocomplete',
  templateUrl: './address-autocomplete.component.html',
  styleUrls: ['./address-autocomplete.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class AddressAutocompleteComponent implements OnInit {
  @Output() selectedPlace: EventEmitter<any> = new EventEmitter();
  @Input() params: FormInputParam;
  @Output() fieldValue: EventEmitter<FormFieldEmitParams> = new EventEmitter();
  Object = Object;

  /**
   * Variable declared to handle focus scenario in ADA
   */
  enableFocusRing = false;
  lastBlurInputValue: string;
  placeSelected = false;

  constructor() {
  }

  focusHandle() {
    this.enableFocusRing = true;
    this.placeSelected = false;
  }
  blurHandle() {
    setTimeout(() => { 
      this.enableFocusRing = false;
      /**
       * US255602: Segment Field level tracking
       */
      const inputTextValue = this.params.formGroup.get(this.params.formControllName).value;
      if (inputTextValue && inputTextValue.length > 0 && inputTextValue !== this.lastBlurInputValue && !this.placeSelected) {
        this.lastBlurInputValue = inputTextValue;
        this.fieldValue.emit({ value: inputTextValue, event: 'Value Added' });
      };
    }, 200);
  }

  ngOnInit() {

  }
  onPlaceSelect( address: any ) {
    this.selectedPlace.emit(address);
    this.placeSelected = true;
  }
  getErrorMessage(messages, key, value) {
    return messages[key].replace(/{}/g, value);
  }

}
