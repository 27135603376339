import { Injectable } from '@angular/core';
import { VehicleStateRuleModel, DriverStateRuleModel, YourInfoStateRuleModel, DiscountStateRuleModel,
  DriverAssignmentStateRuleModel, SignalBannerStateRuleModel, RideShareStateRuleModel,
  QuoteStateRuleModel,
  AssignVehicleDriverStateRuleModel} from '../model/state.rule.model';

import { AppStoreService } from '@ffq-app-store/app.store.service';
import { DataConstants } from '@ffq-app-shared';


@Injectable({
  providedIn: 'root'
})
export class StateRuleModelService {

  /**
   * Project code status of state rule model service
   */
  projectCodeStatus: any;

  /**
   * Creates an instance of state rule model service.
   * @param appStore Service
   */
  constructor(
    private appStoreService: AppStoreService) {
    this.appStoreService.getProjectCodeStatus$.subscribe((projectCodeStatus: any) => {
      this.projectCodeStatus = projectCodeStatus;
    });
  }

  getMailingAddressHome(stateName) {
    return this.isStateAvailable('MailingAdressHome', stateName);
  }
  getNoLifeLOBStates(stateName) {
    return this.isStateAvailable('NoLifeLOBStates', stateName);
  }
  /**
   * Gets your info module state rule
   * @param stateName  State name
   * @returns YourInfoStateRuleModel
   */
  getYourInfoModuleStateRule(stateName: string) {
    const yourInfoStateRuleModel = new YourInfoStateRuleModel();
    yourInfoStateRuleModel.isAutoPrefillEnabled = this.isStateAvailable('AddressAutoPrefill', stateName);
    yourInfoStateRuleModel.isGenderX = this.isStateAvailable('GenderXImplement', stateName);
    yourInfoStateRuleModel.minAgeLicence = this.isStateAvailable('MinAgeLicence', stateName);
    yourInfoStateRuleModel.maxAgeLicence = this.isStateAvailable('MaxAgeLicence', stateName);
    yourInfoStateRuleModel.alreadyAutoInsuranceViewRequired = this.isStateAvailable('CurAutoIns', stateName);
    yourInfoStateRuleModel.subscriptionAgreementRequired = this.isStateAvailable('SubsAgreement', stateName);
    yourInfoStateRuleModel.isYDStates = this.isStateAvailable('YDStates', stateName);
    return yourInfoStateRuleModel;
  }

  getBristolWestCoveredState(stateName: string) {
    return this.isStateAvailable('BristolWestCoveredStates', stateName);
  }

  /**
   * Gets vehicle module state rule
   * @param stateName State name
   * @returns  VehicleStateRuleModel
   */
  getVehicleModuleStateRule(stateName: string) {
    const vehicleStateRuleModel = new VehicleStateRuleModel();
    vehicleStateRuleModel.isPurchaseDateViewRequired = this.isStateAvailable('VehiclePurchaseDateImplement', stateName);
    vehicleStateRuleModel.isRideShareViewRequired = this.isStateAvailable('RideShareImplement', stateName);
    vehicleStateRuleModel.isParkOverNightViewRequired = this.isStateAvailable('ParkOverNightImplement', stateName);
    // DE8882 : Need to display Restrict Two Buissiness Vehicle meesage for states other than NY,PA,CT
    vehicleStateRuleModel.isStateRestrictTwoBuissinessVehicle = !this.isStateAvailable('StatesRestrictTwoBuissinessVehicle', stateName);
    vehicleStateRuleModel.isVehicleA = this.isStateAvailable('VehicleA', stateName);
    vehicleStateRuleModel.isVehicleB = this.isStateAvailable('VehicleB', stateName);
    vehicleStateRuleModel.isVehicleC = this.isStateAvailable('VehicleC', stateName);
    vehicleStateRuleModel.isVehicleD = this.isStateAvailable('VehicleD', stateName);
    vehicleStateRuleModel.isVehicleE = this.isStateAvailable('VehicleE', stateName);
    vehicleStateRuleModel.isVehicleF = this.isStateAvailable('VehicleF', stateName);
    vehicleStateRuleModel.isVehicleG = this.isStateAvailable('VehicleG', stateName);
    vehicleStateRuleModel.isGWImpl = this.isStateAvailable('isGWImpl', stateName);
    vehicleStateRuleModel.isCAAutoClassPlanImpl = this.isStateAvailable('isCAAutoClassPlanImpl', stateName);
    vehicleStateRuleModel.isRideShareCvgImpl = this.isStateAvailable('isRideShareCvgImpl', stateName);
    vehicleStateRuleModel.isFaThreeImpl = this.isStateAvailable('FaThreeImpl', stateName);
    return vehicleStateRuleModel;
  }

  /**
   * Gets driver module state rule
   * @param stateName State Name
   */
  getDriverModuleStateRule(stateName: string) {
    const driverStateRuleModel = new DriverStateRuleModel();
    // driverStateRuleModel.isHideSpouseOccupation = this.isStateAvailable('HideSpouseOccupation', stateName);
    driverStateRuleModel.isSkipOccupation = this.isStateAvailable('SkipOccupation', stateName);
    driverStateRuleModel.isAffinityImplemented = this.isStateAvailable('AffinityImplemented', stateName);
    driverStateRuleModel.isPriorReasonQnNotRequired = this.isStateAvailable('ReasonForNoPriorInsurance', stateName);
    driverStateRuleModel.isMilitaryDeploymentQnRequired = this.isStateAvailable('MilitaryDeployment', stateName);
    driverStateRuleModel.minAgeLicence = this.isStateAvailable('MinAgeLicence', stateName);
    driverStateRuleModel.maxAgeLicence = this.isStateAvailable('MaxAgeLicence', stateName);
    driverStateRuleModel.isGenderXImpl = this.isStateAvailable('GenderXImplement', stateName);
    driverStateRuleModel.isMailingAddressRequiredForAuto = this.isStateAvailable('MailingAdress', stateName);
    driverStateRuleModel.isBWImplemented = this.isStateAvailable('BWImplemented', stateName);
    driverStateRuleModel.isAutoPrefillEnabled = this.isStateAvailable('AddressAutoPrefill', stateName);
    driverStateRuleModel.isBWTextDisclaimerTextStates = this.isStateAvailable('BWTextDisclaimerTextStates', stateName);
    driverStateRuleModel.yearAccident = this.isStateAvailable('YearAccident', stateName);
    driverStateRuleModel.hasInjuryQuestion = this.isStateAvailable('HasInjury', stateName);
    driverStateRuleModel.isFinanceFilling = this.isStateAvailable('FinanceFilling', stateName);
    driverStateRuleModel.isPrincipalPIP = this.isStateAvailable('PrincipalPIP', stateName);
    driverStateRuleModel.isDistantStudentDiscountEligibility = this.isStateAvailable('DistantStudentDiscountEligibility', stateName);
    driverStateRuleModel.isCommutersNJNY = this.isStateAvailable('CommutersNJNY', stateName);
    driverStateRuleModel.isCommutersNY = this.isStateAvailable('CommutersNY', stateName);
    driverStateRuleModel.isCommutersDC = this.isStateAvailable('CommutersDC', stateName);
    driverStateRuleModel.isPersonalInjuryLoss = this.isStateAvailable('PersonalInjuryLoss', stateName);
    driverStateRuleModel.YearSafetyCourse = this.isStateAvailable('YearSafetyCourse', stateName);
    driverStateRuleModel.IsAgeSafetyCourseFiftyFive = this.isStateAvailable('AgeSafetyCourseFiftyFive', stateName);
    driverStateRuleModel.IsAgeSafetyCourseSixty = this.isStateAvailable('AgeSafetyCourseSixty', stateName);
    driverStateRuleModel.IsAgeSafetyCourseAlways = this.isStateAvailable('AgeSafetyCourseAlways', stateName);
    driverStateRuleModel.IscivilUnionPartnerState = this.isStateAvailable('CivilUnionPartnerState', stateName);
    driverStateRuleModel.isEEDistantStudentDiscountEligibility = this.isStateAvailable('EEDistantStudentDiscountEligibility', stateName);
    driverStateRuleModel.isActiveMilitaryEnabled = this.isStateAvailable('ActiveMilitaryDuty', stateName);
    driverStateRuleModel.isFDLAllowed = this.isStateAvailable('FDLAllowed', stateName);
    driverStateRuleModel.isContinueousInsuranceImpl = this.isStateAvailable('ContinouseInsImplemented', stateName);
    driverStateRuleModel.isDrivingExp = this.isStateAvailable('DrivingExp', stateName);
    driverStateRuleModel.isGenderNonBinaryEnabled = this.isStateAvailable('GenderNonBinary', stateName);
    driverStateRuleModel.isLicenseInfo = this.isStateAvailable('LicenseInfo', stateName);
    driverStateRuleModel.isAccidentFreeEnabled = this.isStateAvailable('AccidentFree', stateName);
    driverStateRuleModel.isMatureDriverEnabled = this.isStateAvailable('MatureDriver', stateName);
    driverStateRuleModel.isDNQState = this.isStateAvailable('DNQStates', stateName);
    driverStateRuleModel.isDNQPrivacyPolicyEnabled = this.isStateAvailable('DNQPrivacyPolicyStates', stateName);
    driverStateRuleModel.isDNQSummaryOfRightsEnabled = this.isStateAvailable('DNQSummaryOfRightsStates', stateName);
    driverStateRuleModel.isDNQSummaryOfYourRightsText = this.isStateAvailable('DNQSummaryOfYourRightsText', stateName);
    return driverStateRuleModel;
  }

  /**
   * Gets vehicle module state rule
   * @param stateName State name
   * @returns  VehicleStateRuleModel
   */
  getDiscountModuleStateRule(stateName: string) {
    const discountStateRuleModel = new DiscountStateRuleModel();
    discountStateRuleModel.isPolicyPaymentFrequency = this.isStateAvailable('PolicyPaymentFrequency', stateName);
    discountStateRuleModel.isOwnOrRentQuestion = this.isStateAvailable('OwnOrRentQuestion', stateName);
    discountStateRuleModel.isHouseholdQues = this.isStateAvailable('HouseholdQuestion', stateName);
    discountStateRuleModel.isHouseholdHealthInsurance = this.isStateAvailable('HouseholdHealthInsurance', stateName);
    discountStateRuleModel.isDiscountPNIQuestion = this.isStateAvailable('ResidencePolicy', stateName);
    discountStateRuleModel.isDisabilityCoverage = this.isStateAvailable('DisabilityCoverage', stateName);
    discountStateRuleModel.isFSPAstates = this.isStateAvailable('FSPAstates', stateName);
    discountStateRuleModel.isGWImpl = this.isStateAvailable('isGWImpl', stateName);
    discountStateRuleModel.isBWImplemented = this.isStateAvailable('BWImplemented', stateName);
    discountStateRuleModel.isEFTImpl = this.isStateAvailable('isEFTImpl', stateName);
    return discountStateRuleModel;
  }

  /**
   * Gets signal banner module state rule
   * @param stateName State name
   * @returns  SignalBannerStateRuleModel
   */
  getSignalBannerStateRule(stateName: string) {
    const signalBannerStateRuleModel = new SignalBannerStateRuleModel();
    signalBannerStateRuleModel.isSignalBannerImpl = this.isStateAvailable('isSignalBannerImpl', stateName);
    signalBannerStateRuleModel.isSignalBannerDiscountImpl = this.isStateAvailable('isSignalDiscountImpl', stateName);
    signalBannerStateRuleModel.isSignalBannerPopupContentChange = this.isStateAvailable('SignalBannerPopupContentChange', stateName);
    return signalBannerStateRuleModel;
  }
  /**
   * Gets quote module state rule
   * @param stateName State name
   * @returns QuoteStateRuleModel
   */
  getQuoteModuleStateRule(stateName: string) {
    const quoteStateRuleModel = new QuoteStateRuleModel();
    quoteStateRuleModel.isBWImplemented = this.isStateAvailable('BWImplemented', stateName);
    quoteStateRuleModel.isGWImpl = this.isStateAvailable('isGWImpl', stateName);
    quoteStateRuleModel.isDiscountPNIQuestion = this.isStateAvailable('ResidencePolicy', stateName);
    quoteStateRuleModel.isHouseholdQues = this.isStateAvailable('HouseholdQuestion', stateName);
    quoteStateRuleModel.isRideShareCvgImpl = this.isStateAvailable('isRideShareCvgImpl', stateName);
    quoteStateRuleModel.isHQMEnabled = this.isStateAvailable('isHQMEnabled', stateName);
    // US66935 - EE MVP (KY) - Auto product Taxes and Fees requirements for FFQ - Starts
    quoteStateRuleModel.isEETextDisclaimerTextStates  = this.isStateAvailable('EETextDisclaimerTextStates', stateName);
    // US66935 - EE MVP (KY) - Auto product Taxes and Fees requirements for FFQ - Ends
    quoteStateRuleModel.isEEImpl = this.isStateAvailable('isEEImpl', stateName);
    quoteStateRuleModel.isPolicyFeeEnabled = this.isStateAvailable('isPolicyFeeEnabled', stateName);
    quoteStateRuleModel.isPolicyPerksImpl = this.isStateAvailable('isPolicyPerksImpl', stateName);
    return quoteStateRuleModel;
  }

  /**
   * Gets rideshare state rule
   * @param stateName State Name
   */
  getRideshareStateRule(stateName: string) {
    const rideshareStateRuleModel = new RideShareStateRuleModel();
    rideshareStateRuleModel.isRideShareDriverCvgImpl = this.isStateAvailable('isRideShareCvgImpl', stateName);
    return rideshareStateRuleModel;
  }
  /**
   * Gets rideshare state rule
   * @param stateName State Name
   */
  getDriverAssignmentStateRule(stateName: string) {
    const driverassignmentStateRuleModel = new DriverAssignmentStateRuleModel();
    driverassignmentStateRuleModel.IsDriverAssignment = this.isStateAvailable('assignmentFromDriver', stateName);
    return driverassignmentStateRuleModel;
  }

  getGWRollout3StateRule(stateName: string) {
    const yourInfoStateRuleModel = new YourInfoStateRuleModel();
    yourInfoStateRuleModel.isGWRollout3States = this.isStateAvailable('gwRollout3States', stateName);
    return yourInfoStateRuleModel;
  }

  /**
   * US323567: Display the Credit and Privacy disclaimer on the Your info page - Flex 5.1 (ID, WI, OK) States
   */
  getFlexRolloutStateRule(stateName: string) {
    const yourInfoStateRuleModel = new YourInfoStateRuleModel();
    yourInfoStateRuleModel.isFlexRolloutStates = this.isStateAvailable('flexRolloutStates', stateName);
    return yourInfoStateRuleModel;
  }
  /**
   * US174920: (ENABLER) EE MVP (NC) - Auto product Driver assignment screen requirements
   */
  getAssignVehicleDriverStateRule(stateName: string) {
    const assignVehicleDriverStateRuleModel = new AssignVehicleDriverStateRuleModel();
    assignVehicleDriverStateRuleModel.isAssignVehicleDriver = this.isStateAvailable('assignVehicleDriver', stateName);
    return assignVehicleDriverStateRuleModel;
  }
  /**
   * US521507: For "No online quote knockout" segment events
   */
  getNoOnlineQuoteStates(stateName: string): boolean {
    return this.isStateAvailable('NoOnlineQuoteStates', stateName);
  }
  /**
   * US628741: Digital Monetization FGIA pop-up disclaimer change
   */
  isEEState(stateName: string) {
    return this.isStateAvailable('isEEState', stateName);
  }
  /**
   * Gets state rule which are used in your info and more than one module
   * @param condition rule name
   * @param stateName state name
   */
  isStateAvailable(condition: string, stateName: string) {
    let stateObj;
    switch (condition) {
      case 'GenderXImplement':
        return this.getProjectCodeStatus('isGenderXImpl');
      case 'CurAutoIns':
        return this.getProjectCodeStatus('isADPFImpl');
      case 'isGWImpl':
        return this.getProjectCodeStatus('isGWImpl');
      case 'isRideShareCvgImpl':
        return this.getProjectCodeStatus('isRideShareCvgImpl');
      case 'MailingAdressHome':
        // stateObj = 'FL';
        // stateObj = stateObj.split(',');
        return stateName === 'FL';
      // Implemented already
      case 'AddressAutoPrefill':
        // stateObj = 'SD';
        return this.getProjectCodeStatus('isautoAddressCompImpl');
      case 'MinAgeLicence':
        let retValues;
        if (stateName === 'CT' || stateName === 'FL') {
          retValues = 16;
        } else if (stateName === 'NY') {
          retValues = 17;
        } else {
          retValues = 15;
        }
        return retValues;
      case 'MaxAgeLicence':
        return 99;
      case 'ResidencePolicy':
        stateObj = 'MN';
        stateObj = stateObj.split(',');
        const stateCheck = stateObj.indexOf(stateName) > -1;
        return stateCheck;
      case 'HouseholdQuestion':
        stateObj = 'MN,MI,ND';
        stateObj = stateObj.split(',');
        return stateObj.indexOf(stateName) > -1;
      case 'BristolWestCoveredStates':
        stateObj = 'FL,KY,LA,ME,MS,NH,SC,TX';
        stateObj = stateObj.split(',');
        return stateObj.indexOf(stateName) > -1;
      case 'NoLifeLOBStates':
        stateObj = 'FL,NY,CT';
        stateObj = stateObj.split(',');
        return stateObj.indexOf(stateName) > -1;
      case 'BWImplemented':
        return this.getProjectCodeStatus('isBWImplemented');
      case 'ActiveMilitaryDuty':
      case 'isPolicyFeeEnabled':
        stateObj = 'LA';
        stateObj = stateObj.split(',');
        return stateObj.indexOf(stateName) > -1;
      case 'FDLAllowed':
        stateObj = 'MA';
        stateObj = stateObj.split(',');
        return stateObj.indexOf(stateName) > -1;
      case 'isEEState':
        stateObj = DataConstants.EE_STATES.EEStates;
        stateObj = stateObj.split(',');
        return stateObj.indexOf(stateName) > -1;
      case 'ContinouseInsImplemented':
        return this.getProjectCodeStatus('isCIImpl');
      case 'InvalidCityZipStates':
        stateObj = 'MN';
        stateObj = stateObj.split(',');
        return stateObj.indexOf(stateName) > -1 && this.getProjectCodeStatus('isGWImpl');
      case 'DrivingExp':
        stateObj = 'MA,NC';
        stateObj = stateObj.split(',');
        return stateObj.indexOf(stateName) > -1;
      case 'SubsAgreement':
        stateObj = 'NC';
        stateObj = stateObj.split(',');
        return stateObj.indexOf(stateName) > -1;
      case 'DNQStates':
      return this.getProjectCodeStatus('isDNQImpl');
      case 'YDStates':
      return this.getProjectCodeStatus('isYDImpl');
      // US451101: Flex 8.0 (ND, VA, CT ) - Auto - Display the DNQ and FCRA specific verbiage in BW pop up - Retrofit F30892
      case 'DNQPrivacyPolicyStates':
        stateObj = 'AZ,IL,KS,MN,NV,OR,PA,FL,ND,VA,CT';
        stateObj = stateObj.split(',');
        return stateObj.indexOf(stateName) > -1 && this.getProjectCodeStatus('isDNQImpl');
      // US517611: Do not display summary of rights link for ND state
      case 'DNQSummaryOfRightsStates':
        stateObj = 'AZ,IL,KS,MN,NV,OR,VA,CT';
        stateObj = stateObj.split(',');
        return stateObj.indexOf(stateName) > -1 && this.getProjectCodeStatus('isDNQImpl');
      case 'DNQSummaryOfYourRightsText':
        stateObj = 'AZ';
        stateObj = stateObj.split(',');
        return stateObj.indexOf(stateName) > -1 && this.getProjectCodeStatus('isDNQImpl');
      case 'NoOnlineQuoteStates':
        stateObj = 'CA';
        stateObj = stateObj.split(',');
        return stateObj.indexOf(stateName) > -1;
      default:
        return this.isStateAvailableVehicle(condition, stateName);
    }
  }
  /**
   * Gets state rule which are used in vehicle module
   * @param condition rule name
   * @param stateName state name
   */
  private isStateAvailableVehicle(condition: string, stateName: string) {
    let stateObj;
    switch (condition) {
      case 'VehiclePurchaseDateImplement':
        /** Vehicle Purchase date is implemented only in CA */
        /** US239626 - Vehicle Purchase date is implemented for in AZ , IL - flex */
        /** US316134 - Flex 5.1 - Auto - Not to Display the Purchase date question */
        stateObj = 'CA';
        return stateObj.indexOf(stateName) > -1;
      case 'RideShareImplement':
        /** Currently state list is not considered and this switch value is derived from ProjectCode Status isRideShareImpl */
        return this.getProjectCodeStatus('isRideShareImpl');
      case 'ParkOverNightImplement':
        stateObj = 'FL,OH,OK,KY,MS,LA,MA,NC';
        stateObj = stateObj.split(',');
        return stateObj.indexOf(stateName) > -1 || this.getProjectCodeStatus('isFlexImpl');
      case 'VehicleA':
        const vehicleAstateObj = 'CA';
        return vehicleAstateObj.indexOf(stateName) > -1;
      case 'VehicleB':
        stateObj = 'AL,AR,CO,CT,GA,IA,ID,IN,KS,MD,MI,MO,MT,ND,NE,OH,OK,OR,SD,TN,TX,UT,VA,WA,WI,WY,MS,MA,LA,NC';
        return stateObj.indexOf(stateName) > -1;
      case 'VehicleC':
        stateObj = 'FL';
        return stateObj.indexOf(stateName) > -1;
      case 'VehicleD':
        stateObj = 'IL,MN,NJ,NM,KY';
        return stateObj.indexOf(stateName) > -1;
      case 'VehicleE':
        stateObj = 'NV';
        return stateObj.indexOf(stateName) > -1;
      case 'VehicleF':
        stateObj = 'NY';
        return stateObj.indexOf(stateName) > -1;
      case 'VehicleG':
        stateObj = 'PA';
        return stateObj.indexOf(stateName) > -1;
      case 'isCAAutoClassPlanImpl':
        return this.getProjectCodeStatus('isCAAutoClassPlanImpl');
      case 'FaThreeImpl':
        return this.getProjectCodeStatus('isFaThreeImpl');
     case 'StatesRestrictTwoBuissinessVehicle':
        stateObj = 'NY,PA,CT';
        stateObj = stateObj.split(',');
        return stateObj.indexOf(stateName) > -1;
      default:
        return this.isStateAvailableDriver(condition, stateName);
    }
  }

  /**
   * Gets state rule which are used in driver module
   * @param condition rule name
   * @param stateName state name
   */
  private isStateAvailableDriver(condition: string, stateName: string) {
    let stateObj;
    switch (condition) {
      // case 'HideSpouseOccupation':
      //   stateObj = 'TX,PA,MD,NJ,GA,AR,IN,WY';
      //   stateObj = stateObj.split(',');
      //   return stateObj.indexOf(stateName) > -1;
      case 'SkipOccupation':
        stateObj = 'PA,NJ,MD,KY,MS,LA,MA,NC';
        stateObj = stateObj.split(',');
        return stateObj.indexOf(stateName) > -1;
      case 'AffinityImplemented':
        return this.getProjectCodeStatus('isAffinityImpl');
      case 'ReasonForNoPriorInsurance':
        stateObj = 'WA';
        stateObj = stateObj.split(',');
        return stateObj.indexOf(stateName) === -1;
      case 'MilitaryDeployment':
      case 'PersonalInjuryLoss':
          stateObj = 'FL';
          stateObj = stateObj.split(',');
          return stateObj.indexOf(stateName) > -1;
      case 'MailingAdress':
        return this.getProjectCodeStatus('isBWImplemented');
      case 'YearAccident':
        switch (stateName) {
          case 'NY':
            return '40 months';
          case 'CA':
            return '10 years';
          case 'AZ':
          case 'IL':
            return '5 years';
          // US339604: Display the Accidents and Violations question for Flex 6.0 states
          case 'AR':
          case 'GA':
          case 'MI':
          case 'NE':
          case 'NJ':
          case 'TN':
          case 'TX':
            return this.getProjectCodeStatus('isFlexImpl') ? '5 years' : '3 years';
          // US280382: Display Accident and Violation question for flex
          case 'OR':
          case 'ID':
          case 'NM':
          case 'WI':
          // US390853: Display the Accidents and Violations question for flex 7.0 states
          case 'WY':
          case 'AL':
          case 'PA':
          // US478790: Display the Accidents and Violations question for flex 8.0 states
          case 'ND':
          case 'SD':
          case 'VA':
          case 'CT':
          case 'CO':
          case 'NV':
            return this.getProjectCodeStatus('isFlexImpl') ? '5 years' : '3 years';
          default:
            return '3 years';
        }
      case 'HasInjury':
        return stateName === 'CA';
      case 'FinanceFilling':
        stateObj = 'AL,AR,AZ,CO,CT,IA,ID,IL,IN,KS,MI,MO,MT,ND,NE,NV,OH,OK,OR,SD,TN,TX,UT,VA,WA,WI,WY,FL,MS,LA';
        stateObj = stateObj.split(',');
        return stateObj.indexOf(stateName) > -1;
      case 'PrincipalPIP':
        stateObj = 'NJ';
        return stateObj.indexOf(stateName) > -1;
      case 'DistantStudentDiscountEligibility':
        stateObj = 'OK,OH,UT,PA,AZ,WA,IL,IA,TX';
        stateObj = stateObj.split(',');
        return stateObj.indexOf(stateName) > -1 && this.getProjectCodeStatus('isGWImpl');
      // US428307 - PA state commuteToNYNJ should not display
      case 'CommutersNJNY':
        stateObj = 'PA';
        stateObj = stateObj.split(',');
        return stateObj.indexOf(stateName) > -1 && !this.getProjectCodeStatus('isFlexImpl');
      case 'CommutersNY':
        stateObj = 'NJ';
        stateObj = stateObj.split(',');
        return stateObj.indexOf(stateName) > -1;
      case 'CommutersDC':
        stateObj = 'MD';
        stateObj = stateObj.split(',');
        return stateObj.indexOf(stateName) > -1;
      case 'YearSafetyCourse':
        let safetyCourseValue = '';
        // US466540 Changing Age safety question year for age above 55 driver for FLEX
        // US504362 - Flex 8.0 (CT) - Auto - Have a validity of Safety course question to be 2 years for the Mature driver discount
        if (stateName === 'ND' || (stateName === 'CT' && this.getProjectCodeStatus('isFlexImpl')) ) {
          safetyCourseValue = '2';
        } else if (stateName === 'KY') {
          safetyCourseValue = '5';
        } else {
          safetyCourseValue = '3';
        }
        return safetyCourseValue;
      case 'AgeSafetyCourseFiftyFive':
        stateObj = 'CA,ID,MN,NM,UT,AL,WY,PA,WA,MD,MI,CO,OR,TN,VA,OH,AR,IL,AZ,WI,NV,FL,IA,KY,MS';
        stateObj = stateObj.split(',');
        // US503149 - Removing SD from this list for Flex 8.0 changes
        return stateObj.indexOf(stateName) > -1 || (stateName === 'SD' && !this.getProjectCodeStatus('isFlexImpl'));
      case 'AgeSafetyCourseSixty':
        stateObj = 'CT';
        stateObj = stateObj.split(',');
        return stateObj.indexOf(stateName) > -1;
      case 'AgeSafetyCourseAlways':
        stateObj = 'NJ,KS,MT,IN,NY,OK,GA,ND';
        stateObj = stateObj.split(',');
        return stateObj.indexOf(stateName) > -1;
      case 'CivilUnionPartnerState':
        return [
          'IL', 'CO', 'NJ'
        ].indexOf(stateName) >= 0;
      case 'assignmentFromDriver':
        stateObj = 'CA,VA,NY';
        stateObj = stateObj.split(',');
        return stateObj.indexOf(stateName) > -1;
        case 'assignVehicleDriver':
          stateObj = 'NC';
          stateObj = stateObj.split(',');
          return stateObj.indexOf(stateName) > -1;
      case 'gwRollout3States':
        stateObj = 'MD,CO,IN,MN,NV,KS,MO';
        stateObj = stateObj.split(',');
        return stateObj.indexOf(stateName) > -1;
      case 'flexRolloutStates':
        stateObj = 'AZ,IL,OR,ID,NM,WI,OK';
        stateObj = stateObj.split(',');
        return stateObj.indexOf(stateName) > -1;
      case 'GenderNonBinary':
        stateObj = 'OR,AZ,IL,VA';
        stateObj = stateObj.split(',');
        return stateObj.indexOf(stateName) > -1 || this.getProjectCodeStatus('isFlexImpl');
      case 'LicenseInfo':
        return this.getProjectCodeStatus('isDriversLicenseImpl');
      case 'AccidentFree':
        /** US286687: adding wa state to accident 5 year */
       return this.getProjectCodeStatus('isA5Impl');
      case 'MatureDriver':
        stateObj = 'PA,OK,TX,IA,MO,IN,NJ,NE,WI,GA,NY,KY,LA,CA,NC';
        stateObj = stateObj.split(',');
        return stateObj.indexOf(stateName) === -1;
      default:
        return this.isStateAvailableDiscount(condition, stateName);
    }
  }

  /**
   * Gets state rule which are used in discount module
   * @param condition rule name
   * @param stateName state name
   */
  private isStateAvailableDiscount(condition: string, stateName: string) {
    let stateObj;
    switch (condition) {
      case 'PolicyPaymentFrequency':
        stateObj = 'CA';
        stateObj = stateObj.split(',');
        const stateRuleCheck = stateObj.indexOf(stateName) === -1;
        return stateRuleCheck && !this.getProjectCodeStatus('isGWImpl');
      case 'OwnOrRentQuestion':
        /* To be checked later - with BW flag implemented
        if (ffqGlobal.getModuleDetails().isBWQuote) {
          stateObj = 'MN';
          stateObj = stateObj.split(',');
          return stateObj.indexOf(stateName) > -1 ? false : true;
        } else {
          stateObj = 'AL,AR,AZ,CO,CT,GA,ID,IL,IN,KS,MD,MI,MO,NE,NJ,NM,NV,NY,OH,OK,OR,PA,TN,TX,UT,VA,WA,WI,WY,FL,CA,IA,ND,MT,SD,MN';
          stateObj = stateObj.split(',');
          return stateObj.indexOf(stateName) > -1 ? true : false;
        } */
        // tslint:disable-next-line: max-line-length
        stateObj = 'AL,AR,AZ,CO,CT,GA,ID,IL,IN,KS,MD,MI,MO,NE,NJ,NM,NV,NY,OH,OK,OR,PA,TN,TX,UT,VA,WA,WI,WY,FL,CA,IA,ND,MT,SD,MN,KY,MS,LA,NC';
        stateObj = stateObj.split(',');
        return stateObj.indexOf(stateName) > -1;
      case 'HouseholdHealthInsurance':
      case 'DisabilityCoverage':
        stateObj = 'MI';
        stateObj = stateObj.split(',');
        return stateObj.indexOf(stateName) > -1;
      case 'FSPAstates':
        const returnVal = this.getProjectCodeStatus('isFaThreeImpl') || this.getProjectCodeStatus('isFa2point5Impl');
        return returnVal;
      case 'isEFTImpl':
        return this.getProjectCodeStatus('isEFTImpl');
      case 'isSignalDiscountImpl':
        return this.getProjectCodeStatus('isSignalDiscountImpl');
      default:
        return this.isStateAvailableQuote(condition, stateName);
    }
  }

  /**
   * Gets state rule which are used in quote module
   * @param condition rule name
   * @param stateName state name
   */
  private isStateAvailableQuote(condition: string, stateName: string) {
    let stateObj;
    switch (condition) {
      case 'CheckTowingState':
        return ['AR', 'OK', 'KS', 'MO', 'CO', 'IA', 'ND', 'NE', 'NM', 'SD', 'WY', 'AL',
          'GA', 'IL', 'OH', 'TN', 'MI', 'WI', 'CA', 'ID', 'MT', 'NV', 'UT', 'CT', 'IN', 'MD', 'NJ', 'NY', 'MN', 'AZ'
        ].indexOf(stateName) >= 0;
      case 'CheckMedicalCoverageStateIsVA':
        return ['VA'].indexOf(stateName) >= 0;
      case 'CheckUninsuredMotoristBI':
      case 'CheckUnderinsuredMotoristBI':
        return [
          'IL'
        ].indexOf(stateName) >= 0 && this.getProjectCodeStatus('isGWImpl');
      case 'CheckUninsuredMotoristPDLA':
          return ['LA'].indexOf(stateName) >= 0;
      // US67946: EE MVP (KY) - Auto product UMBI coverage requirements for FFQ-starts
      case 'CheckUninsuredMotoristBIKY':
        return ['KY'].indexOf(stateName) >= 0;
      // US67946: EE MVP (KY) - Auto product UMBI coverage requirements for FFQ-Ends
      case 'CheckUnderinsuredMotoristBIKY':
        return ['KY'].indexOf(stateName) >= 0;
      case 'CheckPersonalInjuryProtectionIsKY':
        stateObj = 'KY';
        return stateObj.indexOf(stateName) >= 0 && this.getProjectCodeStatus('isGWImpl');
      case 'UMBIAndBIErrorMsgCheck':
        stateObj = 'OK,OH,UT,AZ,IL,IA,KY';
        stateObj = stateObj.split(',');
        return stateObj.indexOf(stateName) > -1 && this.getProjectCodeStatus('isGWImpl');
      // US421646: Flex 7.0 - Auto - Display UMBI and BI coverages
      // US478471: Flex 8.0 (ND, SD, CO) - Auto - Display UMBI and BI coverages
      // US478475: Flex 8.0 (NV) - Auto - Display UIMBI and BI coverages
      // US478472: Flex 8.0 (VA, CT, NV) - Auto - Display UMBI and BI coverages
      case 'UMBIAndBIErrorMsgCheckFlex':
        stateObj = 'ID,WI,AR,NE,WY,AL,ND,SD,CO,NV,VA,CT';
        stateObj = stateObj.split(',');
        return stateObj.indexOf(stateName) > -1 && this.getProjectCodeStatus('isFlexImpl');
      case 'UIMBIAndBIErrorMsgCheck':
        stateObj = 'KY';
        stateObj = stateObj.split(',');
        return stateObj.indexOf(stateName) > -1 && this.getProjectCodeStatus('isGWImpl');
      case 'isHQMEnabled':
        return this.getProjectCodeStatus('isHQMEnabled');
      // US66935 - EE MVP (KY) - Auto product Taxes and Fees requirements for FFQ - Starts
      case 'EETextDisclaimerTextStates':
      case 'EEDistantStudentDiscountEligibility':
        stateObj = 'KY,MS,LA,MA';
        stateObj = stateObj.split(',');
        return stateObj.indexOf(stateName) > -1;
      // US66935 - EE MVP (KY) - Auto product Taxes and Fees requirements for FFQ - Ends  */

      case 'isSignalBannerImpl':
        return this.getProjectCodeStatus('isSignalBannerImpl');
      case 'SignalBannerPopupContentChange':
        stateObj = 'KY,MS,MA,LA';
        return stateObj.indexOf(stateName) > -1 || this.getProjectCodeStatus('isFlexImpl');
      case 'isEEImpl':
        return this.getProjectCodeStatus('isEEImpl');
      // case 'EETextDisclaimerTextStates':
      //   stateObj = 'KY,MS';
      //   stateObj = stateObj.split(',');
      //   return stateObj.indexOf(stateName) > -1;
      // case 'EEDistantStudentDiscountEligibility':
      //     stateObj = 'KY,MS,MA,LA';
      //     stateObj = stateObj.split(',');
      //     return stateObj.indexOf(stateName) > -1;
      case 'isPolicyPerksImpl':
        return this.getProjectCodeStatus('isPolicyPerksImpl');
      case 'RentalReimbursementErrorMsgCheck':
          stateObj = 'MS,KY,LA,MA';
          stateObj = stateObj.split(',');
          return stateObj.indexOf(stateName) > -1 && this.getProjectCodeStatus('isGWImpl');
      case 'CompCollisionOwnedErrorMsgCheck':
          stateObj = 'MS,KY,LA,MA,NC';
          stateObj = stateObj.split(',');
          return stateObj.indexOf(stateName) > -1 && this.getProjectCodeStatus('isGWImpl');
      case 'CoveragePackageStates':
          stateObj = 'NC';
          stateObj = stateObj.split(',');
          return stateObj.indexOf(stateName) > -1 && this.getProjectCodeStatus('isEEImpl');
      case 'StateIsMA':
          return ['MA'].indexOf(stateName) >= 0;
      case 'CompCollLeasedorFinancedErrMsg':
          stateObj = 'LA,MA,NC';
          stateObj = stateObj.split(',');
          return stateObj.indexOf(stateName) > -1;
      default:
        return this.isStateAvailableDepreciated(condition, stateName);
    }
  }

  /**
   * State rule which are not currently using anywhere , but used in legacy / older versions
   * @param condition rule name
   * @param stateName state name
   */
  private isStateAvailableDepreciated(condition: string, stateName: string) {
    let stateObj;
    switch (condition) {
      // tslint:disable-next-line:jsdoc-format
      /** S12714: 15-211F02  GW - Auto product requirements for FL state - Effective date cannot be future dated more than 60 days*/
      case 'restrictEffectiveDateTo30':
        return this.getProjectCodeStatus('isGWImpl');
      case 'BusinessNoticeOfInformation':
        stateObj = 'AZ,CA,GA,MN,MT,NJ,OR,VA,NV,CT';
        stateObj = stateObj.split(',');
        return stateObj.indexOf(stateName) > -1;
      case 'creditReprtMsg':
        stateObj = 'AZ,AR,CO,CT,IL,IN,IA,FL,KS,MD,MN,MO,MT,NE,NV,NM,ND,OR,PA,TX,VA,WI,WY,OK,OH,TN,NJ,NY,GA,MI,UT,WA';
        stateObj = stateObj.split(',');
        return stateObj.indexOf(stateName) > -1;
      case 'privacyPolicyText':
        stateObj = 'CA';
        return stateObj.indexOf(stateName) === -1;
      case 'NonOfferedStates':
        stateObj = 'AK,DE,DC,LA,ME,MS,NH,NC,RI,SC,VT,WV,MA';
        return stateObj.indexOf(stateName) > -1;
      case 'MartialStatus':
        stateObj = 'NJ,CO,IL';
        stateObj = stateObj.split(',');
        return stateObj.indexOf(stateName) > -1;
      case 'PNIOccupation':
        stateObj = 'NY';
        return stateObj.indexOf(stateName) === -1;
      case 'AnyInjuries':
        stateObj = 'CA';
        stateObj = stateObj.split(',');
        return stateObj.indexOf(stateName) > -1;
      case 'NoticeofInfoPractices':
        stateObj = 'AZ,VA,GA,NJ,NV,CT,OR,MN,CA,MT,IL';
        stateObj = stateObj.split(',');
        return stateObj.indexOf(stateName) > -1;
      case 'safetyCourseList':
        stateObj = 'CA,ID,MN,NM,UT,AL,SD,WY,PA,WA,MD,MI,CO,OR,TN,VA,OH,AR,IL,AZ,WI,NV,CT,NJ,KS,MT,IN,NY,OK,GA,ND,FL,IA,KY,MS';
        stateObj = stateObj.split(',');
        return stateObj.indexOf(stateName) > -1;
      case 'ConvictionDate':
        stateObj = 'CA,MO,VA,NY,NH,OR,CO';
        stateObj = stateObj.split(',');
        return stateObj.indexOf(stateName) > -1;
      case 'FarmersRolledOutStates':
        stateObj = 'AL,AR,AZ,CO,CA,CT,GA,IA,ID,IL,IN,KS,MD,MI,MO,MN,MT,ND,NE,NJ,NM,NV,NY,OH,OK,OR,PA,SD,TN,TX,UT,VA,WA,WI,WY';
        stateObj = stateObj.split(',');
        return (stateObj.indexOf(stateName) > -1);
      case 'BristolWestRolledOutStates':
        stateObj = 'AL,AR,AZ,CO,CT,IA,ID,IL,IN,KS,MD,MI,MO,MN,MT,ND,NE,NJ,NM,NV,NY,OH,OK,OR,PA,SD,TN,TX,UT,VA,WA,WI,WY';
        stateObj = stateObj.split(',');
        return (stateObj.indexOf(stateName) > -1);
      case 'GPAStates':
        stateObj = 'AR,CO,IL,NE,PA,TN,TX,MN,VA,WI,NJ,OH';
        stateObj = stateObj.split(',');
        return stateObj.indexOf(stateName) > -1;
      case 'TurnedOffStates':
        stateObj = '';
        stateObj = stateObj.split(',');
        return stateObj.indexOf(stateName) > -1;
      case 'assignmentFromDiscount':
        stateObj = 'NY';
        stateObj = stateObj.split(',');
        return stateObj.indexOf(stateName) > -1;
      case 'disclaimerState':
        stateObj = 'CA';
        stateObj = stateObj.split(',');
        return stateObj.indexOf(stateName) === -1;
      case 'FDLBrightlineState':
        stateObj = 'MI,MN';
        stateObj = stateObj.split(',');
        return stateObj.indexOf(stateName) > -1;
      case 'GaragingAddress':
        stateObj = 'NY,OH,OK,FL,MA';
        stateObj = stateObj.split(',');
        return stateObj.indexOf(stateName) > -1;
      case 'PolicyPaymentGWFrequency':
        return this.getProjectCodeStatus('isGWImpl');
      case 'ExtraordinaryLifeMsg':
        stateObj = 'CT,KS,MI,NJ,NV';
        stateObj = stateObj.split(',');
        return stateObj.indexOf(stateName) > -1;
      case 'eftEligbleState':
        stateObj = 'CA,KY,MS,LA,MA,NC';
        stateObj = stateObj.split(',');
        return stateObj.indexOf(stateName) > -1;
      default:
        return;
    }
  }

  /**
   * Gets project code status
   * @param property Property to be fetched from ProjectCodeStatus
   * @returns  true/false
   */
  getProjectCodeStatus(property: string) {
    let isEnabledViaProjectCd: boolean;
    isEnabledViaProjectCd = this.projectCodeStatus[property];
    return isEnabledViaProjectCd;
  }
  getCheckTowingState(stateName) {
    return this.isStateAvailable('CheckTowingState', stateName);
  }
  getCheckMedicalCoverageStateIsVA(stateName) {
    return this.isStateAvailable('CheckMedicalCoverageStateIsVA', stateName);
  }
  getCheckUninsuredMotoristBI(stateName) {
    return this.isStateAvailable('CheckUninsuredMotoristBI', stateName);
  }
  getCheckUnderinsuredMotoristBI(stateName) {
    return this.isStateAvailable('CheckUnderinsuredMotoristBI', stateName);
  }
  // US67946: EE MVP (KY) - Auto product UMBI coverage requirements for FFQ-starts
  getCheckUninsuredMotoristBIKY(stateName) {
    return this.isStateAvailable('CheckUninsuredMotoristBIKY', stateName);
  }
  // US67946: EE MVP (KY) - Auto product UMBI coverage requirements for FFQ-Ends
  getCheckUnderinsuredMotoristBIKY(stateName) {
    return this.isStateAvailable('CheckUnderinsuredMotoristBIKY', stateName);
  }
  getCheckPersonalInjuryProtectionIsKY(stateName) {
    return this.isStateAvailable('CheckPersonalInjuryProtectionIsKY', stateName);
  }

  getUMBIAndBIErrorMsgCheck(stateName) {
    return this.isStateAvailable('UMBIAndBIErrorMsgCheck', stateName);
  }

  getUMBIAndBIErrorMsgCheckFlex(stateName) {
    return this.isStateAvailable('UMBIAndBIErrorMsgCheckFlex', stateName);
  }

  getUIMBIAndBIErrorMsgCheck(stateName) {
    return this.isStateAvailable('UIMBIAndBIErrorMsgCheck', stateName);
  }

  getRentalReimbursementErrorMsgCheck(stateName) {
    return this.isStateAvailable('RentalReimbursementErrorMsgCheck', stateName);
  }

  getCompCollisionOwnedErrorMsgCheck(stateName) {
    return this.isStateAvailable('CompCollisionOwnedErrorMsgCheck', stateName);
  }

  getCompCollLeasedorFinancedErrMsgCheck(stateName) {
    return this.isStateAvailable('CompCollLeasedorFinancedErrMsg', stateName);
  }

  getCoveragePackageStates(stateName) {
    return this.isStateAvailable('CoveragePackageStates', stateName);
  }

  getInvalidCityZipStates(stateName) {
    return this.isStateAvailable('InvalidCityZipStates', stateName);
  }

  // US106951 : EE MVP (LA) - Auto product UMPD coverage requirements
  getCheckUninsuredMotoristPDLA(stateName) {
    return this.isStateAvailable('CheckUninsuredMotoristPDLA', stateName);
  }
  getStateIsMA(stateName) {
  return this.isStateAvailable('StateIsMA', stateName);
  }

  /**
   * Checks custom quote impl for state
   * @param isBWQuote isBWQuote
   * @returns boolean
   */
  checkCustomQuoteImplForState(isBWQuote) {
    let isCustomQuoteImpl = false;
    this.appStoreService.getBackDoorCustomFlag$.subscribe((customQuoteBackDoorData: any) => {
      if (isBWQuote) {
        isCustomQuoteImpl = customQuoteBackDoorData.isBWCustomQuoteImplBackDoor !== null ?
          customQuoteBackDoorData.isBWCustomQuoteImplBackDoor : this.getProjectCodeStatus('isBWCustomQuoteImpl') === true;
      } else {
        isCustomQuoteImpl = customQuoteBackDoorData.isCustomQuoteImplBackDoor !== null ?
          customQuoteBackDoorData.isCustomQuoteImplBackDoor : this.getProjectCodeStatus('isCustomQuoteImpl') === true;
      }
    }).unsubscribe();
    return isCustomQuoteImpl;
  }
}
