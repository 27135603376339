<div class="auto-module__policy-perks-container" role="presentation" *ngIf="isPolicyPerkEligible">
        <div class="auto-module__policy-perks-header">
            <div class="auto__policy-perks-title-img">
                <img src="../../../assets/images/discounts/policy-perks-title-img.png" alt="Policy Perks"/>
            </div>
            
        </div>
        <div class="auto-module__policy-perks-sub-header">
            <p>Purchase a policy with Farmers® and you can unlock discounts, save money, and get policy benefits - all with Farmers Policy Perks<sup>SM</sup>.
            <span *ngIf="isPrint" >Contact us to learn more.</span>
                <a *ngIf="!isPrint" [attr.data-GTM]='policyPerkLearnMoreGTM' href="https://www.farmers.com/policy-perks/" target="_blank" aria-label = "Learn more. Opens in new tab">Learn more</a></p>
        </div>
        <div class="auto-module__policy-perks-content">
            <div class="auto-module__policy-perks-content-left">
                <div class="auto-module__policy-perks-img-block">
                    <img [src]= contentMap.assetPath1 alt=""/>
                </div>
                <div class="auto-module__policy-perks-content-block"> 
                    <h4> {{contentMap.title1}} <a id="footerlinkpolicy1" class="footerLink" href="javascript:void(0);" onclick="document.getElementById('policy-perk-disclaimer1').focus()" aria-label="Footer note 1"><span aria-hidden="true" title="Footer note 1" ><sup>1</sup></span></a></h4>
                    <p> {{contentMap.message1}} </p>
                    </div>
            </div>
    
            <div class="auto-module__policy-perks-content-right">
                <div class="auto-module__policy-perks-img-block">
                    <img [src]= contentMap.assetPath2 alt=""/>
                </div>
                <div class="auto-module__policy-perks-content-block">
                    <h4>{{contentMap.title2}} <a id="footerlinkpolicy2" class="footerLink" href="javascript:void(0);" onclick="document.getElementById('policy-perk-disclaimer2').focus()" aria-label="Footer note 2"><span aria-hidden="true" title="Footer note 2"><sup>2</sup></span></a></h4>
                    <p> {{contentMap.message2}} </p>
                </div>
            </div>
        </div>
    </div>

