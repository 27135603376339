import { Component, OnInit, Input, Output, EventEmitter, AfterContentChecked, AfterViewInit, ChangeDetectionStrategy, OnDestroy, Inject, ChangeDetectorRef, HostListener, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatDatepicker, MatDatepickerIntl, MatCalendar } from '@angular/material/datepicker';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { DateService } from '../../core/services/date.service';
import { DatePipe } from '@angular/common';
import { FormInputParam } from '../form-input-params';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CdkRow } from '@angular/cdk/table';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';

const moment = _rollupMoment || _moment;

export const FORMATS = {
  parse: {
    dateInput: 'input',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'lib-date-picker-monthyear-format',
  templateUrl: './date-picker-monthyear-format.component.html',
  styleUrls: ['./date-picker-monthyear-format.component.scss'],
  providers: [

    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },

    { provide: MAT_DATE_FORMATS, useValue: FORMATS },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePickerMonthyearFormatComponent implements OnInit, AfterContentChecked {
  /**
   * Input  of date picker monthyear format component
   */
  @Input() params: FormInputParam;
  /**
   * Object  of date picker monthyear format component
   */
  Object = Object;
  /**
   * Purchase date of date picker monthyear format component
   */
  purchaseDate = true;
  /**
   * Date entered of date picker monthyear format component
   */
  dateEntered: Moment;

  width: number;
  /**
   * Edit value of date picker monthyear format component
   */
  editValue: Moment;
  /**
   * Purchase date entered of date picker monthyear format component
   */
  count = 0;
  /**
   * Arialabel  of date picker component
   */
  arialabel: string;
  /**
   * View child of date picker component
   */
   @ViewChild('dp')
   matDatePicker: MatDatepicker<any>;
  /**
   * Customer Header for picker Component
   */
  customeDatePickerHeader = CustomDatePickerHeaderComponent;
  public mask = {
    guide: true,
    showMask: false,
    keepCharPositions: true,
    // placeholderChar: '\u2000',
    mask: [/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] // MM/yyyy
  };
  /**
   * Creates an instance of date picker monthyear format component.
   * @param datePicker datePicker
   * @param dateService dateService
   * @param datePipe datePipe
   */
  constructor(public datePicker: MatDatepickerIntl, private dateService: DateService, private datePipe: DatePipe) {
  }

  /**
   * on init
   */
  ngOnInit() {
    window.addEventListener('scroll', this.scrollEvent, true);
    if (this.params.placeholder === 'Purchase date' && (this.purchaseDate)) {
      this.datePicker.openCalendarLabel = 'Calendar graphical icon to select purchase date in the format of mmyyyy';
      this.purchaseDate = false;
    }
  }
  get isLargeScreen() {
    this.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (/Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      return false;
    } else {
      return true;
    }

  }
  /**
   * after content checked
   */
  ngAfterContentChecked() {
    // tslint:disable-next-line: max-line-length
    if (this.params.id === 'purchaseDate' && this.params.formGroup.value.purchaseDate && this.params.formGroup.get(this.params.formControllName).valid) {
      const sample = this.params.formGroup.value.purchaseDate;
      const date = sample.split('/');
      this.params.ariaLabel = 'Purchase Date in the format mmyyyy is' + ' ' + `${date[0]}/${date[1]}` + ' ' + 'which is editable';
    }
  }

  /**
   * Validation on when focusOut
   */
  focusOutFunction(event) {
    const value1 = event.target.value.replace(/_/g, ' ').trim();
    const date = event.target.value.split('/');
    if (value1 === '') {
      this.params.formGroup.get(this.params.formControllName).setErrors({ required: true });
    } else if (date[0] && (parseInt(date[0], 10) > 12 || parseInt(date[0], 10) === 0 || parseInt(date[1], 10) === 0)) {
      this.params.formGroup.get(this.params.formControllName).setErrors({ inValidDate: true });
    } else if (value1.length < 7) {
      this.params.formGroup.get(this.params.formControllName).setErrors({ minLength: true });
    }

  }


  inputDate(value) {
    const value2 = value.replace(/[^0-9]/g, '').trim();
    const value1 = value.replace(/_/g, '').trim();
    const date = value.split('/');
    if (value1 && value1.length < 7) {
      this.params.formGroup.get(this.params.formControllName).setErrors({ minLength: true });
      // tslint:disable-next-line: max-line-length
    } else if ((date[0] && date[0].length === 2 && parseInt(date[0], 10) === 0) || (date[1] && date[1].length === 4 && parseInt(date[1], 10) === 0)) {
      this.params.formGroup.get(this.params.formControllName).setErrors({ invalidDate: true });
    } else if (parseInt(date[1], 10) <= 1000) {
      this.params.formGroup.get(this.params.formControllName).setErrors({ matDatepickerMin: true });
    }

    date[0] = date[0] ? date[0].replace(/[^0-9]/g, '').trim() : null;
    if (value2 && value2.length <= 1 && date[0] && date[0].length <= 1 && parseInt(date[0], 10) > 1) {
      date[0] = (this.insertCharecterByIndex(date[0], 0, 0)).substring(0, 2);
      this.params.formGroup.get(this.params.formControllName).setValue(date[0]);
    } else if (value2 && value2.length === 2 && date[0] && date[0].length === 2 && parseInt(date[0], 10) > 12) {
      date[0] = (this.insertCharecterByIndex(date[0], 0, 0) + date[0].substring(0, 1)).substring(0, 2);
      this.params.formGroup.get(this.params.formControllName).setValue(date[0]);
    } else if (value2 && value2.length > 2 && date[0] && date[0].length === 2 && parseInt(date[0], 10) > 12) {
      date[0] = (this.insertCharecterByIndex(date[0], 0, 0) + date[0].substring(0, 1)).substring(0, 2);
      this.params.formGroup.get(this.params.formControllName).setValue(`${date[0]}/${date[1]}`);
    }
  }


  /**
   * Chosens year handler
   * @param normalizedYear normalizedYear
   */
  chosenYearHandler(normalizedYear: Moment) {
    this.dateEntered = moment();
    this.dateEntered.year(normalizedYear.year());
  }

  /**
   * Chosens month handler
   * @param normalizedMonth normalizedMonth
   * @param datepicker datepicker
   */
  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    this.dateEntered.year(normalizedMonth.year());
    this.dateEntered.month(normalizedMonth.month());
    const purchaseDate = `${this.dateEntered.month(normalizedMonth.month()).format('MM')}/${this.dateEntered.year()}`;
    this.params.formGroup.get(this.params.formControllName).setValue(purchaseDate);
    datepicker.close();
  }

  /**
   * Months year date format
   * @param type type
   * @param value value
   */
  monthYearDateFormat(value: string) {
    const value2 = value.replace(/[^0-9]/g, ' ').trim();
    if (value2.length === 7) {
      const date = value.split('/');
      const minDate = new Date(this.datePipe.transform(this.params.minDate, 'MM/dd/yyyy'));
      const maxDate = new Date(this.datePipe.transform(this.params.maxDate));
      const currentDate = new Date();
      // tslint:disable-next-line: max-line-length
      const purchaseDate = parseInt(date[0], 10) <= 12 && parseInt(date[0], 10) !== 0 && parseInt(date[1], 10) !== 0 ? new Date(this.datePipe.transform(date[0] + '/' + currentDate.getDate() + '/' + date[1], 'MM/dd/yyyy')) : null;
      if (purchaseDate !== null) {
        const purchaseDateEntered = `${date[0]}/${date[1]}`;

        if (purchaseDate > maxDate) {
          this.params.formGroup.get(this.params.formControllName).setValue(purchaseDateEntered);
          this.params.formGroup.get(this.params.formControllName).setErrors({ matDatepickerMax: true });
        } else if (purchaseDate < minDate) {
          this.params.formGroup.get(this.params.formControllName).setValue(purchaseDateEntered);
          this.params.formGroup.get(this.params.formControllName).setErrors({ matDatepickerMin: true });
        }
      }
    }
  }

  /**
   * Events handler key up
   */
  eventHandlerKeyUp(event) {
    const date1 = event.clipboardData.getData('text/plain');
    const date = date1.split('/');
    if (date1 && date1.length <= 7 && date[1] && date[1].length === 4) {
      if (date[0] && date[0].length <= 1 && parseInt(date[0], 10) >= 1) {
        date[0] = (this.insertCharecterByIndex(date[0], 0, 0)).substring(0, 2);
      } else if (date[0] && date[0].length === 2 && parseInt(date[0], 10) > 12) {
        date[0] = (this.insertCharecterByIndex(date[0], 0, 0) + date[0].substring(0, 1)).substring(0, 2);
      }
      this.params.formGroup.get(this.params.formControllName).setValue(`${date[0]}/${date[1]}`);
    }

  }

  /**
   * Inserts charecter by index
   * @param input input
   * @param chr input
   * @param position position
   * @returns formatted phone number
   */
  insertCharecterByIndex(input, chr, position) {
    return input.substring(0, position) + chr + input.substring(position);

  }
  // DE70492: Datepickers are not responsive
  @HostListener("document:click", ["$event"])
  onClick = (event: any): void => {
    const elementId = event.currentTarget?.activeElement?.id || event.target?.className;
    if (elementId && !elementId.includes("mat-calendar") && !elementId.includes("calenderbtn") && this.matDatePicker.opened) {
      this.matDatePicker.close();
    }
  }
// DE70492: Datepickers are not responsive
  scrollEvent = (event: any): void => {
    const elementId = event.target?.className || event.currentTarget?.activeElement?.id;
    if (elementId && !elementId.includes("mat-calendar") && !elementId.includes("calenderbtn") && this.matDatePicker.opened) {
      this.matDatePicker.close();
    }
  }
}

@Component({
  selector: 'lib-custom-date-picker-header',
  styleUrls: ['./date-picker-monthyear-custom-header.scss'],
  templateUrl: './date-picker-monthyear-custom-header.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomDatePickerHeaderComponent<D> implements OnDestroy {
  private _destroyed = new Subject<void>();

  constructor(
    private _calendar: MatCalendar<D>, private _dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats, cdr: ChangeDetectorRef) {
    _calendar.stateChanges
        .pipe(takeUntil(this._destroyed))
        .subscribe(() => cdr.markForCheck());
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  get customLabel() {
    if (!this._calendar.startAt) {
      this._calendar.startAt = this._dateAdapter.today();
    }
    if (this._calendar.currentView === 'year') {
      return this._dateAdapter.format(this._calendar.activeDate, 'YYYY');
    } else if (this._calendar.currentView === 'multi-year' && this._calendar.multiYearView) {
      const dateRangeStartDate = this._calendar.multiYearView._years[0][0].displayValue;
      const dateRangeEndDate = this._calendar.multiYearView._years[5][3].displayValue;
      const dateRange = dateRangeStartDate + '-' + dateRangeEndDate;
      return dateRange;
    } else if (this._dateAdapter.getYear(this._calendar.startAt) < (this._dateAdapter.getYear(this._calendar.maxDate) - 23)) {
      const dateRangeStartDate = this._dateAdapter.getYear(this._calendar.maxDate) - 47;
      const dateRangeEndDate = this._dateAdapter.getYear(this._calendar.maxDate) - 24;
      const dateRange = dateRangeStartDate + '-' + dateRangeEndDate;
      return dateRange;
    } else if (this._dateAdapter.getYear(this._calendar.startAt) >= (this._dateAdapter.getYear(this._calendar.maxDate) - 23)) {
      const dateRangeStartDate = this._dateAdapter.getYear(this._calendar.maxDate) - 23;
      const dateRangeEndDate = this._dateAdapter.getYear(this._calendar.maxDate);
      const dateRange = dateRangeStartDate + '-' + dateRangeEndDate;
      return dateRange;
    } else {
      return '         ';
    }
  }

  customAriaLabel(direction: 'Previous' | 'Next') {
    if (this._calendar.currentView === 'multi-year') {
      return (direction + ' 24 Years');
    } else if (this._calendar.currentView === 'year') {
      return (direction + ' Year');
    }
  }

  previousYears() {
    if (this._calendar.currentView === 'multi-year') {
      this._calendar.multiYearView.activeDate = this._dateAdapter.addCalendarYears(this._calendar.activeDate, -24);
    } else {
      this._calendar.activeDate = this._dateAdapter.addCalendarYears(this._calendar.activeDate, -1);
    }
  }

  nextYears() {
    if (this._calendar.currentView === 'multi-year') {
      this._calendar.multiYearView.activeDate = this._dateAdapter.addCalendarYears(this._calendar.activeDate, 24);
    } else {
      this._calendar.activeDate = this._dateAdapter.addCalendarYears(this._calendar.activeDate, 1);
    }
  }
}
