<div role="dialog">
    <h2  role="heading"  aria-level="2" id="landingErrorInfoHeading" class="dialog_info-dialog-title"> We were unable to retrieve your quote</h2>
    <div id="infoContent" class="infoDialogContent" class="dialog_info-dialog-content">
        We can't retrieve your quote because you already purchased your policy.<br>
        If you have questions, please contact your agent or call us at<a href="tel:18005151896" 
        aria-label=  "Contact farmers representative at 1-800-515-1896"> 1-800-515-1896</a><br>
        Thanks for choosing Farmers!
    <div class="dialog_info-dialog-button-holder">
      <button mat-button mat-dialog-close
      class="dialog__info-dialog-button-close close-button" (click)="dialogRef.close()"  (keydown.esc)="dialogRef.close()">CLOSE</button>
    </div>
    </div>
