import { timer, Subject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class QualtricsTimeoutService {
  public timerComplete: Subject<number> = new Subject<number>();
  private timerSubscription: Subscription;
  private timerEnabled = false;
  private counter = 0;
  popUpDisplayTimer: Observable<number>;
  private timeInMilliSec: number;
  private buttonText = '';

  /**
   *
   * param moduleName
   * param buttonText
   */
  startQualtricsTimer(moduleName: string, buttonText?: string) {
    if (this.timerSubscription) {
      this.stopQualtricsTimer();
    }
    this.setInactivityTime(moduleName, buttonText);
    this.startSubscription();
    this.timerEnabled = true;
    this.buttonText = buttonText;
  }
  /**
   *
   * Start timer subscription
   */
  startSubscription() {
    this.popUpDisplayTimer = timer(this.timeInMilliSec);
    this.timerSubscription = this.popUpDisplayTimer.subscribe((res) => {
      this.timerComplete.next(++this.counter);
      this.stopQualtricsTimer();
    });
  }
  /**
   * Stop timer subscription
   */
  stopQualtricsTimer() {
    this.timerEnabled = false;
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }
  /**
   * Reset timer
   * param moduleName
   */
  resetQualtricsTimer(moduleName: string) {
    if (this.timerEnabled) {
      this.startQualtricsTimer(moduleName, this.buttonText);
    }
  }
  /**
   * Set Inactivity time
   * param moduleName
   * param buttonText
   */
  setInactivityTime(moduleName: string, buttonText?: string) {
    switch (moduleName) {
      case 'discounts':
        this.timeInMilliSec = 40000;
        break;
      case 'quote' :
        this.timeInMilliSec = buttonText === 'Buy Now' ? 40000 : 20000;
    }
  }
}
