import { Component, OnInit } from '@angular/core';
import { StateRuleModelService } from '@ffq-app-shared/services';
import { Subscription } from 'rxjs';
import { AppStoreService } from '@ffq-app-store/app.store.service';
import { take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { SignalDiscountPopupComponent } from '../signal-discount-popup/signal-discount-popup.component';
import { QuoteStoreService } from '@ffq-app-auto/quote/store/quote.store.service';
import { AutoStoreService } from '@ffq-app-auto/store/auto.store.service';
import { DataLayerService } from '@ffq-app-core/services/data-layer.service';
import { DataConstants } from '@ffq-app-shared/constants';

@Component({
  selector: 'app-signal-discount-banner',
  templateUrl: './signal-discount-banner.component.html',
  styleUrls: ['./signal-discount-banner.component.scss']
})
export class SignalDiscountBannerComponent implements OnInit {

  private subscriptions: Subscription[] = [];
  isSignalDiscountImpl: boolean;
  landingStateCode: string;
  signalDiscountBannerLearnMore = 'FFQ_Auto_Signal_Quote_Learn_More_Link';
  signalDiscountRerateGTM = 'FFQ_Auto_Signal_rerate_Link';
  signalDiscountRecalculateGTM = 'FFQ_Auto_Signal_recalculate_Link';
  isAutoQuotePage = true;
  isSignalDiscountSelected: boolean;
  isSignalDiscountEligible: boolean;
  isCoverageUpdated: boolean;
  isBWQuote = false;

  constructor(
    private stateRuleModel: StateRuleModelService,
    private appStoreService: AppStoreService,
    private dialog: MatDialog,
    private autoStoreService: AutoStoreService,
    private dataLayerService: DataLayerService
    ) { }

  ngOnInit(): void {
    /** subscription for landing state code */
    this.subscriptions.push(this.appStoreService.appDataZip$.pipe(take(1)).subscribe((data: any) => {
      this.landingStateCode = data[`landingStateCode`];
    }));

    this.isSignalDiscountImpl = this.stateRuleModel.getSignalBannerStateRule(this.landingStateCode).isSignalBannerDiscountImpl;
    this.subscriptions.push(this.appStoreService.getSignalDiscountSelected$.subscribe(data => {
      if (data === 'Y') {
        this.isSignalDiscountSelected = true;
        this.isSignalDiscountEligible = false;
      }
    }));

    /** subscription for brightlined status */
    this.subscriptions.push(this.autoStoreService.getBrightLinedData$.subscribe((data: any) => {
      this.isBWQuote = (data && data.autoCompanyCode && data.autoCompanyCode === 'B');
      if (this.isSignalDiscountImpl && !this.isSignalDiscountSelected && !this.isBWQuote) {
        this.isSignalDiscountEligible = true;
      } else {
        this.isSignalDiscountEligible = false;
      }
    }));

    this.subscriptions.push(this.appStoreService.getIsCoveragesUpdated$.subscribe(cvgUpdated => {
      this.isCoverageUpdated = cvgUpdated;
    }));

  }

  openSignalDiscountBannerPopup() {
    this.dialog.open(SignalDiscountPopupComponent, {
      panelClass: 'signal-discount-banner-dialog',
      data: { dialogTitle: 'Action' },
      autoFocus: false
    });
  }

  callRateQuote() {
    /** US203892: Custom Dimension for Signal Discounts */
    const signalApplied = DataConstants.SIGNAL_DATALAYER_TAGS;
    this.dataLayerService.setDataLayerNoSignal('');
    this.dataLayerService.setDataLayerSignalApplied(signalApplied.signalAppliedPNI);
    this.appStoreService.updateAppControlData({ signalDiscountSelected: 'Y', signalDiscountClicked: 'Y' });
  }

}
