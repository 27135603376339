import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';

import { ErrorService, DataService } from '../../../../core';
import { ServiceConstants, DataConstants } from '../../../../shared';
import { AppStoreService } from '@ffq-app-store/app.store.service';
import { QuoteStoreService } from '../../../quote/store/quote.store.service';
import { DropDownModel } from '../../../quote/model/quote.model';
import { StateRuleModelService } from '@ffq-app-shared/services';
import { AutoStoreService } from '@ffq-app-auto/store/auto.store.service';
import { DateService } from '@ffq-lib/src/lib/core/services/date.service';
import { ChatbotComponent } from './chatbot.component';



@Injectable({
    providedIn: 'root'
  })
export class ChatbotService {
  private bot: ChatbotComponent = null;

  /**
   * Creates an instance of quote service.
   * @param http HttpClient
   * @param errorService ErrorService
   * @param appStoreService AppStoreService
   * @param dataService DataService
   * @param quoteStoreService QuoteStoreService
   */
  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
    private appStoreService: AppStoreService,
    private dataService: DataService,
    private quoteStoreService: QuoteStoreService,
    private stateRuleService: StateRuleModelService,
    private autoStoreService: AutoStoreService,
    private dateService: DateService) { }

  /**
   * Posting request for chatbot start.
   */
  initChatbotServerConversation(requestPayload) {
      return this.http.post(ServiceConstants.BASE_URL + ServiceConstants.CHAT_BOT, requestPayload)
      .pipe(catchError(error => this.errorService.handleError(error)));
  }

  /**
   * Register chatbot component.
   */
  registerChatbot(bot: ChatbotComponent) {
    this.bot = bot;
  }

  /**
   * Starting a new conversation window or open if already exists.
   */
  openConversation() {
    if (this.bot) {
      this.bot.startChatBotOnTimeout();
    }
  }

  /**
   * Hide a conversation window till the next explicit opening.
   */
  hideConversation() {
    if (this.bot) {
      this.bot.hideChatBot();
    }
  }


}
