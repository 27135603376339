<form [formGroup]="insuredModalFormGroup" class="auto-module-insured-modal">
    <mat-dialog-content > 
        <div>
            <div role="heading" aria-level= "2" class = "auto-module__save-and-finish-header-text" x-ms-format-detection="none">
               <span tabindex="0"> Before you get started </span>
            </div>     
            <!-- <div class = "auto-module__save-and-finish-line"></div> -->
            <div class = "auto-module__save-and-finish-dialog-content">
                <div class="">Are you currently covered by an auto insurance policy?
                </div>
                <div class="radio_btn_wrap">
                    <lib-radio-button [params]="insuredModalParams.hasAutoInsurance"></lib-radio-button>
                </div>
                
                <!-- (radioFieldValue)="trackDriverFieldEngagement($event, segmentFieldDescription.drivers, 'Is driver currently insured?')"> -->
            </div>
            <!-- <div class = "auto-module__save-and-finish-line"></div> -->
        </div>
        <div class = "auto-module__insured-modal-continue-buttons">
            <mat-dialog-actions class = "dialog__info-dialog-button-holder" align="end">
                <button mat-button class="auto-module_dialog__info-dialog-button-close2"  
                data-GTM="FFQ_Auto_savefinishmodal_Contquote_button" (click)="insuredContinue()">Continue</button>
            </mat-dialog-actions>
        </div>
    </mat-dialog-content>
</form>
