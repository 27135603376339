import { Injectable } from '@angular/core';
import {
  SessionStorageData, RetrieveDataStatus, Vehicle, Driver,
  AgentInfo, DriverProgress, Auto, AutoState, AutoControlData, LastVisitedModule,
  CustomerData, AppStateFeatureStoreData, Customer, BusinessState, Business, Home
} from '@ffq-app-shared/model/common.data.model';

import { DataConstants } from '@ffq-app-shared';
import { DateService } from '@ffq-lib/src/lib/core/services/date.service';
import { ModuleProgressStatus, FlowParamter } from '@ffq-app-shared/enums/flow.identifier.enum';
import { initialState } from '@ffq-app-store/app.state';
import { FlowIndentifierEnum } from '@ffq-app-shared/enums/flow.identifier.enum';
import { FilterStoreStateService } from '@ffq-app-core/services';
import { AppStoreService } from '@ffq-app-store/app.store.service';


@Injectable({
  providedIn: 'root'
})
export class ModuleLandingService {

  /** Used  to store the partial data informations from legacy quotes */
  partialDatafromLegacy = {
    partialDataInQuote: null,
    isPartialDataExists: false
  };

  /** Used to update the navigation Data and retrieveDataStatus in store */
  navAndRetrieveCtrlData = {
    navigationData: JSON.parse(JSON.stringify(initialState.navigationData)),
    retrieveDataStatus: {} as RetrieveDataStatus
  };


  /**
   * Creates an instance of module landing service.
   * @param dateService DateService
   * @param filterStoreStateService FilterStoreStateService
   */
  constructor(
    private dateService: DateService,
    private appStoreService: AppStoreService,
    private filterStoreStateService: FilterStoreStateService
  ) {
  }


  /**
   * Creates app store data for retrieval
   * @param response RetrieveResponse
   * @returns AppstoreData
   */
  createAppStoreDataForRetrieval(response: any) {
    if (response.lob === 'A' || response.lob === 'H' || response.lob === 'R' || response.lob === 'C') {
      const appstate = {} as AppStateFeatureStoreData;
      const agentInfo = {} as AgentInfo;
      const customerData = {} as CustomerData;
      appstate.data = {};
      appstate.controlData = {};
      if (this.getFlowParam() !== 'PI') {
        agentInfo.agent = response.agent;
        appstate.data.agentData = agentInfo;
      }
      appstate.data.quoteNumber = response.controlData.quoteNumber;
      appstate.data.effectiveDate = response.persInsQuoteDataBean.effectiveDate;
      /**
       * US334572: setting the source indicator from the retrieve response to store
       */
      appstate.controlData.quoteSourceIndicator = response.persInsQuoteDataBean.sourceInd;
      appstate.controlData.isPrefillCallRequired = response.controlData.prefillCallRequired ?
        response.controlData.prefillCallRequired : false;
      appstate.controlData.isYDExist = response.controlData.isYDExist ?
        response.controlData.isYDExist : false;
      /** US435452: Retrieval changes for connected car */
      appstate.controlData.vehicleOrDriverChanged = response.controlData.vehicleOrDriverModified ?
        response.controlData.vehicleOrDriverModified : false;
      appstate.controlData.connectedCarIndicator = response.controlData.quoteConnectedCarIndicator === 'Y';
      // For project code status call
      appstate.data.quoteType = response.lob;
      appstate.data.lob = this.setLobInAppData(response.lob);
      customerData.lob = response.lob;
      customerData.employer = {};
      customerData.employer.employerName = response.controlData.employerName;
      customerData.employer.choiceIndicator = response.controlData.choiceIndicator;
      customerData.employer.gpcCode = response.controlData.gpcCode;
      customerData.employer.fwsZipEligble = response.controlData.fwsZipEligble;
      customerData.employer.fwsToFFQRedirect = response.controlData.fwsToFFQRedirect;
      customerData.currentlyAutoInsured = response.controlData.currentlyAutoInsured;
      customerData.existingLobs = response.controlData.existingLobs;
      if (response.controlData.existingLobs !== null && response.controlData.existingLobs !== undefined &&
        response.controlData.existingLobs !== '') {
          customerData.isExistingCust = 'Y';
        }
      customerData.diffMailAddress = response.controlData.diffMailAddress;
      response.customer.dateOfBirth = this.dateService.transform(response.customer.dateOfBirth, 'yyyy-MM-dd');
      customerData.customer = response.customer;
      this.updateCommunicationDetails(customerData);
      appstate.data.customerData = customerData;
      appstate.data.tokenInfo = response.tokenInfo;
      appstate.data.homeBoundIndicator = response.controlData.homeBoundIndicator;
      appstate.data.landingStateCode = response.persInsQuoteDataBean.landingStateCode;
      // set landing state code description using state code
      appstate.data.landingStateDescription = this.getLandingStateDescription(response.persInsQuoteDataBean.landingStateCode);
      appstate.data.lifeBoundIndicator = response.controlData.lifeBoundIndicator;
      appstate.data.pniMaritalStatus = response.pniMaritalStatus;
      appstate.data.pniOccupation = response.pniOccupation;
      appstate.data.quotedLobs = response.persInsQuoteDataBean.quotedLobs;
      appstate.data.windowId = response.quoteWindowId;
      if (response.persInsQuoteDataBean.quotedLobs && response.persInsQuoteDataBean.quotedLobs !== 'H'
        && response.persInsQuoteDataBean.quotedLobs !== 'A'
        && response.persInsQuoteDataBean.quotedLobs !== 'R'
        && response.persInsQuoteDataBean.quotedLobs !== 'C') {
        appstate.controlData.crossSellStatus = true;
      }
      appstate.data.ssnKey = response.ssnKey;
      if (response.persInsQuoteDataBean.src) {
        appstate.data.src = encodeURIComponent(response.persInsQuoteDataBean.src);
      }
      appstate.data.landingZipCode = response.persInsQuoteDataBean.landingZipCode;
      appstate.controlData.maxAllowedVehicle = parseInt(response.controlData.maxVehicleCount, 10);
      if (response.persAutoInsQuote?.persAutoPolicy && response.persAutoInsQuote.persAutoPolicy.vehicle[0].persAutoCoverage) {
        appstate.controlData.packageSelected =
          response.persAutoInsQuote.persAutoPolicy.vehicle[0].persAutoCoverage.packageSelected;
      }
      appstate.controlData.lastVisitedPage = response.controlData.lastVisitedPage;
      if (response.restrictedFireLobs) {/** CatMit Changes to restrict Fire Lob */
        appstate.controlData.restrictedFireProductLobs = response.restrictedFireLobs;
      }
      if (response.zipTownList) {
        appstate.data.zipTownList = response.zipTownList;
      }
      // DE53665 For MI State, City Field Dropdown is not getting Prefilled
      // and City dropdown is not working in Your info screen when creating quote from media alpha
      if (response.zipCityList) {
        appstate.data.zipCityList = response.zipCityList;
      }
      if (response.subscriptionAgreement) {
        appstate.data.customerData.subscriptionAgreement = response.subscriptionAgreement;
      }
       // tslint:disable-next-line:max-line-length
      if (response.lob === 'A' && response.persAutoInsQuote && response.persAutoInsQuote.driver && response.persAutoInsQuote.driver.length > 0) {
        response.persAutoInsQuote.driver.forEach(obj => {
          if (obj.pni === 'H') {
            appstate.data.customerData.customer.gender = obj.gender;
            appstate.controlData.signalDiscountSelected = obj.signalEnrolled;
          }
        });
      }
      this.appStoreService.getPhoneNumberFromSrc$.subscribe((phoneNumber: any) => {
        appstate.data.sourcePhoneNumber = phoneNumber;
      }).unsubscribe();
      return appstate;
    } else if (response.lob === 'B') {
      const appState = {} as AppStateFeatureStoreData;
      const customerData = {} as CustomerData;
      appState.data = {};
      appState.controlData = {};
      appState.data.customerData = {};
      customerData.lob = response.lob;
      customerData.customer = response.commercialInsQuoteData.customerInformation;
      this.updateCommunicationDetailsForBiz(customerData);
      appState.data.customerData = customerData;
      appState.data.quoteNumber = response.commercialInsQuoteData.quoteNumber;
      appState.data.effectiveDate = response.commercialInsQuoteData.effectiveDate;
      appState.data.quoteType = response.lob;
      appState.data.lob = this.setLobInAppData(response.lob);
      appState.data.tokenInfo = response.tokenInfo;
      appState.data.landingStateCode = response.commercialInsQuoteData.landingStateCode;
      appState.data.lifeBoundIndicator = response.controlData.lifeBoundIndicator;
      appState.data.ssnKey = response.ssnKey;
      appState.data.landingZipCode = response.commercialInsQuoteData.landingZipCode;
      appState.controlData.lastVisitedPage = response.controlData.pgLastVisitedPage;
      this.appStoreService.getPhoneNumberFromSrc$.subscribe((phoneNumber: any) => {
        appState.data.sourcePhoneNumber = phoneNumber;
      }).unsubscribe();
      return appState;
    }
  }

  createAppStoreDataForCrossSell(response: any) {
    const appstate = {} as AppStateFeatureStoreData;
    const agentInfo = {} as AgentInfo;
    const customerData = {} as CustomerData;
    appstate.data = {};
    if (response.isOneUIEnabled) {
      appstate.data.isOneUIEnabled = response.isOneUIEnabled;
      appstate.data.redirectionParams = response.redirectionParams;
      return appstate;
    }
    agentInfo.agent = response.agent;
    appstate.data.agentData = agentInfo;
    appstate.data.quoteNumber = response.quoteNumber;
    appstate.data.quoteType = response.quoteType;
    appstate.data.lob = this.setLobInAppData(response.quoteType);
    response.customer.dateOfBirth = this.dateService.transform(response.customer.dateOfBirth, 'yyyy-MM-dd');
    customerData.customer = response.customer;
    this.updateCommunicationDetails(customerData);
    const spouseDriverData = response.spouseDriver;
    appstate.data.customerData = customerData;
    appstate.data.spouseDriver = spouseDriverData;
    appstate.data.tokenInfo = response.tokenInfo;
    if (response.src) {
      appstate.data.src = encodeURIComponent(response.src);
    }
    appstate.data.landingStateCode = response.landingStateCode;
    appstate.data.landingStateDescription = this.getLandingStateDescription(response.landingStateCode);
    appstate.data.landingZipCode = response.customer.address.zip;
    appstate.data.pniMaritalStatus = response.pniMaritalStatus;
    appstate.data.pniOccupation = response.pniOccupation;
    appstate.data.spouseOccupation = response.spouseOccupation;
    appstate.data.quotedLobs = response.quotedLobs;
    appstate.data.lifeBoundIndicator = response.lifeBoundIndicator;
    appstate.data.homeBoundIndicator = response.homeBoundIndicator;
    if (response.zipTownList) {
      appstate.data.zipTownList = response.zipTownList;
    }
    return appstate;
  }
  // updates navigation data from retreive quote response
  updateNavigationDataFromRetrieve(lob, pgLastVisitedPage, lastVisitedPage) {
    const currLob = this.setLobInAppData(lob);
    if (lob === 'A') {
      const navDataArr = pgLastVisitedPage ? pgLastVisitedPage.split(':') : null;
      const navData = navDataArr ? navDataArr[1] : null;
      const currNavigationCtrlObjAuto: Auto = this.navAndRetrieveCtrlData.navigationData[currLob];
      if (navData) {
        currNavigationCtrlObjAuto.yourInfo = navData[0] === 'C' ? ModuleProgressStatus.COMPLETED : ModuleProgressStatus.NOT_STARTED;
        currNavigationCtrlObjAuto.vehicles = navData[1] === 'C' ? ModuleProgressStatus.COMPLETED : ModuleProgressStatus.NOT_STARTED;
        currNavigationCtrlObjAuto.drivers = navData[2] === 'C' ? ModuleProgressStatus.COMPLETED : ModuleProgressStatus.NOT_STARTED;
        currNavigationCtrlObjAuto.discounts = navData[3] === 'C' ? ModuleProgressStatus.COMPLETED : ModuleProgressStatus.NOT_STARTED;
        currNavigationCtrlObjAuto.quote = navData[4] === 'C' ? ModuleProgressStatus.COMPLETED : ModuleProgressStatus.NOT_STARTED;
      } else {
        // Quote retrieved from legacy application
        this.setNavDataForLegacyQuote(currNavigationCtrlObjAuto, lastVisitedPage);
      }
    } else if (lob === 'B') {
      const currNavigationCtrlObjBiz: Business = this.navAndRetrieveCtrlData.navigationData[currLob];
      // if (pgLastVisitedPage === 'businessInfoBean') {
      currNavigationCtrlObjBiz.businessInfo = ModuleProgressStatus.COMPLETED;
      currNavigationCtrlObjBiz.yourInfo = ModuleProgressStatus.COMPLETED;
      /** DE11188 :
       * When retrieving a business quote and naviagting to confirmation page getting  error 404 in the background
       * and page is clocking forever Navigation to Business confirmation page should not happen directly
       */
      currNavigationCtrlObjBiz.confirmation = ModuleProgressStatus.NOT_STARTED;
      // } else if (lob === 'H') {
      //   const currNavigationCtrlObjHome: Home = this.navAndRetrieveCtrlData.navigationData[currLob];
      //   currNavigationCtrlObjHome.yourInfo = ModuleProgressStatus.COMPLETED;
      // }
    }
  }

  /**
   * Sets nav data for legacy quote
   * @ param currNavigationCtrlObjAuto
   * @ param lastVisitedPage
   */
  setNavDataForLegacyQuote(currNavigationCtrlObjAuto, lastVisitedPage) {
    if (lastVisitedPage) {
      if (lastVisitedPage === LastVisitedModule.Auto_Vehicle) {
        currNavigationCtrlObjAuto.yourInfo = ModuleProgressStatus.COMPLETED;
        currNavigationCtrlObjAuto.vehicles = ModuleProgressStatus.NOT_STARTED;
        currNavigationCtrlObjAuto.drivers = ModuleProgressStatus.NOT_STARTED;
        currNavigationCtrlObjAuto.discounts = ModuleProgressStatus.NOT_STARTED;
        currNavigationCtrlObjAuto.quote = ModuleProgressStatus.NOT_STARTED;
      } else if (lastVisitedPage === LastVisitedModule.Auto_Driver ||
        lastVisitedPage === LastVisitedModule.Auto_VehicleDriverAssignment ||
        lastVisitedPage === LastVisitedModule.Auto_RideShare) {
        currNavigationCtrlObjAuto.yourInfo = ModuleProgressStatus.COMPLETED;
        currNavigationCtrlObjAuto.vehicles = ModuleProgressStatus.COMPLETED;
        currNavigationCtrlObjAuto.drivers = ModuleProgressStatus.NOT_STARTED;
        currNavigationCtrlObjAuto.discounts = ModuleProgressStatus.NOT_STARTED;
        currNavigationCtrlObjAuto.quote = ModuleProgressStatus.NOT_STARTED;
      } else if (lastVisitedPage === LastVisitedModule.Auto_Discount ||
         lastVisitedPage === LastVisitedModule.Auto_Signal) {
        currNavigationCtrlObjAuto.yourInfo = ModuleProgressStatus.COMPLETED;
        currNavigationCtrlObjAuto.vehicles = ModuleProgressStatus.COMPLETED;
        currNavigationCtrlObjAuto.drivers = ModuleProgressStatus.COMPLETED;
        currNavigationCtrlObjAuto.discounts = ModuleProgressStatus.NOT_STARTED;
        currNavigationCtrlObjAuto.quote = ModuleProgressStatus.NOT_STARTED;
      } else if (lastVisitedPage === LastVisitedModule.Auto_Quote) {
        currNavigationCtrlObjAuto.yourInfo = ModuleProgressStatus.COMPLETED;
        currNavigationCtrlObjAuto.vehicles = ModuleProgressStatus.COMPLETED;
        currNavigationCtrlObjAuto.drivers = ModuleProgressStatus.COMPLETED;
        currNavigationCtrlObjAuto.discounts = ModuleProgressStatus.COMPLETED;
        currNavigationCtrlObjAuto.quote = ModuleProgressStatus.COMPLETED;
      } else if (lastVisitedPage === LastVisitedModule.Rate_Summary
        || lastVisitedPage === LastVisitedModule.Auto_YourInfo) {
        /** US453066: Update all module status in store if last visited page is RateSummary or YourInfo  */
        currNavigationCtrlObjAuto.yourInfo = ModuleProgressStatus.NOT_STARTED;
        currNavigationCtrlObjAuto.vehicles = ModuleProgressStatus.NOT_STARTED;
        currNavigationCtrlObjAuto.drivers = ModuleProgressStatus.NOT_STARTED;
        currNavigationCtrlObjAuto.discounts = ModuleProgressStatus.NOT_STARTED;
        currNavigationCtrlObjAuto.quote = ModuleProgressStatus.NOT_STARTED;
      }
    }
  }

  /**
   * Updates communication details on success
   * @param customerData customerData
   * @returns customerData
   */
  updateCommunicationDetails(customerData: any) {
    if (customerData.customer && customerData.customer.communication) {
      if (customerData.customer.communication.phoneNumber && customerData.customer.communication.phoneNumber.areaCode &&
        customerData.customer.communication.phoneNumber.phoneNumber) {
        const phoneNumber = '(' + customerData.customer.communication.phoneNumber.areaCode + ')'
          + (customerData.customer.communication.phoneNumber.phoneNumber).substring(0, 3)
          + '-' + (customerData.customer.communication.phoneNumber.phoneNumber).substring(3, 7);
        customerData.customer.communication.phoneNumber = phoneNumber;
      } else {
        // Setting to null to make sure only null check is done where phone numbers doesnt come in cross sell/retrieve
        customerData.customer.communication.phoneNumber = null;
      }
      if (customerData.customer.communication.emailId) {
        customerData.customer.communication.emailAddress = customerData.customer.communication.emailId.emailAddress;
        delete customerData.customer.communication.emailId;
      }
      if (customerData.customer.diffMailAddress) {
        customerData.customer.communication.diffMailInd = 'Y';
        customerData.customer.communication.diffMailAddress = customerData.customer.diffMailAddress;
      }
    }
    return customerData;
  }
  /**
   * Updates communication details on success for Biz
   * @param customerData customerData
   * @returns customerData
   */
  updateCommunicationDetailsForBiz(customerData: any) {
    if (customerData && customerData.customer.communication) {
      if (customerData.customer.communication.phoneNumber && customerData.customer.communication.phoneNumber.areaCode &&
        customerData.customer.communication.phoneNumber.phoneNumber) {
        const phoneNumber = '(' + customerData.customer.communication.phoneNumber.areaCode + ')'
          + (customerData.customer.communication.phoneNumber.phoneNumber).substring(0, 3)
          + '-' + (customerData.customer.communication.phoneNumber.phoneNumber).substring(3, 7);
        if (customerData.customer.communication.phoneNumber.phoneExtn) {
          customerData.customer.communication.phoneNumberExtn = customerData.customer.communication.phoneNumber.phoneExtn;
        }
        customerData.customer.communication.phoneNumber = phoneNumber;
      } else {
        customerData.customer.communication.phoneNumber = null;
      }
      if (customerData.customer.communication.emailId) {
        customerData.customer.communication.emailAddress = customerData.customer.communication.emailId.emailAddress;
        delete customerData.customer.communication.emailId;
      }
    }
    return customerData;
  }

  /**
   * Sets lob in app data
   * @param lob lob
   */
  setLobInAppData(lob: any) {
    // let lobValue;
    switch (lob) {
      case 'A': {
        // return lobValue = 'auto';
        return 'auto';
      }
      case 'H': {
        return 'home';
      }
      case 'B': {
        return 'business';
      }
      case 'R': {
        return 'renter';
      }
      case 'C': {
        return 'condo';
      }
    }
  }

  /**
   * Gets landing state description
   * @ param stateCode
   * @ returns
   */
  getLandingStateDescription(stateCode: any) {
    const stateDetails = DataConstants.STATE_LIST;
    return stateDetails[stateCode] ? stateDetails[stateCode] : '';
  }

  /**
   * Creates session storage data for retreival
   * @param response RetieveResponse
   * @returns SessionStorageData
   */
  createSessionStorageDataForRetreival(response: any) {
    const data = {} as SessionStorageData;
    if (response.lob === 'A') {
      /**
       *  inorder to redirect the request to error page when pgLastVisitedPage hasn't any value
       */
      // if (!response.controlData.pgLastVisitedPage) {
      //   const errObj = {
      //     status: null,
      //     message: null,
      //     errorInfo: {
      //       errorCode: 'ERR1'
      //     }
      //   };
      //   throw new Error(JSON.stringify(errObj));
      // }
      data.autoFeatureStoreData = this.createAutoStoreDataForRetreival(response);
      data.isPartialDataExists = this.partialDatafromLegacy.isPartialDataExists;
      data.partialDataInQuote = this.partialDatafromLegacy.partialDataInQuote;
    } else if (response.lob === 'B') {
      data.bizFeatureStoreData = this.createAutoStoreDataForRetreival(response);
    }
    // TODO Setting flag - Assuming YourInfoData Exists for All Auto Quotes on Retrieval.
    // Need to Check on implementation of other modules.
    this.navAndRetrieveCtrlData.retrieveDataStatus.isYourInfoDataExists = true;
    // update navigation data from last updated navigation data
    this.updateNavigationDataFromRetrieve(response.lob, response.controlData.pgLastVisitedPage, response.controlData.lastVisitedPage);

    return data;
  }


  /**
   * Creates vehicle store data forretrieval
   * @param response retrieveQuoteResponse
   * @returns  vehiclestate
   */
  createVehicleStoreDataForRetrieval(response: any) {
    const vehicleData = {
      vehicles: []
    };
    let vehicles: Vehicle[] = response.persAutoInsQuote?.persAutoPolicy ? (response.persAutoInsQuote.persAutoPolicy.vehicle) : null;

    // Remove vehicle partial data for quote created in old applciation
    if (response.controlData.pgLastVisitedPage &&
      response.controlData.lastVisitedPage === 'Vehicle' && !response.controlData.pgLastVisitedPage.includes('FFQ-A')) {
      const partialData = response.controlData.pgLastVisitedPage.split('-');
      // Partial data have 0 in pgLastVisitedPage
      if (partialData[1] === '0') {
        this.partialDatafromLegacy.isPartialDataExists = true;
        this.partialDatafromLegacy.partialDataInQuote = vehicles.filter(elementItem => elementItem.reference === partialData[0]);
        vehicles = vehicles.filter(elementItem => elementItem.reference !== partialData[0]);
      }
    }

    if (vehicles && vehicles.length) {
      vehicles.forEach(vehicle => {
        vehicle.vehicleSelected = vehicle.year + ' ' + vehicle.makeDesc + ' ' + vehicle.modelDesc;
      });
      vehicleData.vehicles = vehicles;
      this.navAndRetrieveCtrlData.retrieveDataStatus.isVehiclesDataExists = true;
      // this.navAndRetrieveCtrlData.navigationData[this.setLobInAppData(response.lob)].vehicles = ModuleProgressStatus.COMPLETED;

    }
    return vehicleData;
  }


  /**
   * Creates driver store data forretrieval
   * @ param response
   * @ returns  driverData
   */
  createDriverStoreDataForretrieval(response: any) {
    const driverData = {
      drivers: []
    };

    let drivers: Driver[] = response.persAutoInsQuote?.driver ? (response.persAutoInsQuote.driver) : null;

    // Remove driver partial data for quote created in old applciation
    if (response.controlData.pgLastVisitedPage &&
      response.controlData.lastVisitedPage === 'Driver' && !response.controlData.pgLastVisitedPage.includes('FFQ-A')) {
      const partialData = response.controlData.pgLastVisitedPage.split('-');
      // Partial data have 0 in pgLastVisitedPage
      if (partialData[1] === '0') {
        this.partialDatafromLegacy.isPartialDataExists = true;
        this.partialDatafromLegacy.partialDataInQuote = drivers.filter(elementItem => elementItem.reference === partialData[0]);
        drivers = drivers.filter(elementItem => elementItem.reference !== partialData[0]);
      }
    }

    if (drivers && drivers.length) {
      drivers.forEach(driver => {
        driver.driveProgress = DriverProgress.Completed;
      });
      driverData.drivers = drivers;
      this.navAndRetrieveCtrlData.retrieveDataStatus.isDriversDataExists = true;
      // this.navAndRetrieveCtrlData.navigationData[this.setLobInAppData(response.lob)].drivers = ModuleProgressStatus.COMPLETED;
    }
    // driverData.drivers = drivers;
    return driverData;
  }

  /**
   * Creates auto store data for retreival
   * @param response response
   * @returns  autoData
   */
  createAutoStoreDataForRetreival(response: any) {
    if (response.lob === 'A') {
      const autoState = {
        data: {},
        controlData: {}
      } as AutoState;
      autoState.data.vehicles = this.createVehicleStoreDataForRetrieval(response).vehicles;
      autoState.data.drivers = this.createDriverStoreDataForretrieval(response).drivers;
      autoState.data.discount = this.createDiscountStoreDataForRetreival(response).discount;
      autoState.data.quote = this.createQuoteStoreDataForRetrieval(response).quote;
      // DE59545: Changes for the prequalified discount in case for retrival
      autoState.data.prequalifiedDiscounts = response.persAutoInsQuote?.persAutoPolicy ?
      response.persAutoInsQuote.persAutoPolicy.discount : null;
      autoState.data.prefillData = {
        currentInsuredAnswer: null,
        driver: [],
        prospectQuoteReportId: null,
        quoteNumber: null,
        vehicle: [],
      };
      autoState.data.rentersRate =  {
        rentersMonthlyPremiumAmt: this.resolveAutoRentersQuote(response) ? response.persHomeInsQuote.autoRentersBundlePremiumAmt : null
      };
      // setting control data for auto store
      const autoCtrlData = {} as AutoControlData;
      autoState.data.adpfExcludedDriverData = response.persAutoInsQuote?.adpfExcludedDriver;
      autoCtrlData.autoCompanyCode = response.controlData.autoCompanyCode;
      autoCtrlData.isEaiBrightLineInd = (true === response.persAutoInsQuote?.eaiBrightLineInd);
      autoCtrlData.rc1PremiumAmt = response.persAutoInsQuote?.rc1PremiumAmt;
      this.appStoreService.updateAppControlData({
        isBWQuote: response.persAutoInsQuote?.eaiBrightLineInd === true ?
          response.persAutoInsQuote?.eaiBrightLineInd : false
      });
      autoCtrlData.isBwPopupShown = autoCtrlData.autoCompanyCode === 'B' && autoCtrlData.isEaiBrightLineInd;
      autoCtrlData.eligibleForAutoRentersBundle = this.resolveAutoRentersQuote(response);
      autoCtrlData.isEmailValid = (response.customer &&
        response.customer.communication &&
        response.customer.communication.emailId &&
        response.customer.communication.emailId.emailAddress);
      autoCtrlData.eleLinkFlag = response.controlData.eleLinkFlag ? (response.controlData.eleLinkFlag === 'true') : null;
      autoCtrlData.isELEEnabled = !(response.controlData.eleLinkFlag && response.controlData.eleLinkFlag === 'remove');
      autoState.controlData = autoCtrlData;

      this.navAndRetrieveCtrlData.retrieveDataStatus.isAutoDataExists = true;

      return autoState;
    } else if (response.lob === 'B') {
      const bizState = {
        data: {},
        controlData: {}
      } as BusinessState;

      bizState.data.businessInfo = this.createBusinessInfoStoreDataForRetrieval(response);
      bizState.data.agentData = this.createAgentDataStoreDataForRetrieval(response);

      return bizState;
    }
  }


  /**
   * Creates business Info store data forretrieval
   * @ param response
   * @ returns  driverData
   */
  createBusinessInfoStoreDataForRetrieval(response: any) {
    // let businessInfoData = {} as BusinessInfo;
    response.commercialInsQuoteData.businessInformation = response.commercialInsQuoteData.businessInformation ?
      (response.commercialInsQuoteData.businessInformation) : null;
    return response.commercialInsQuoteData.businessInformation;
  }

  /**
   * Creates Agent data store data forretrieval
   * @ param response
   * @ returns  driverData
   */
  createAgentDataStoreDataForRetrieval(response: any) {
    // let agentData = {} as AgentInfo;
    response.commercialInsQuoteData.agent = response.commercialInsQuoteData.agent ? (response.commercialInsQuoteData.agent) : null;
    return response.commercialInsQuoteData.agent;
  }

  /**
   * Creates quote data for retrieval
   * @param response response
   * @returns quoteData
   */
  createQuoteStoreDataForRetrieval(response: any) {
    const responseCopy =response.persAutoInsQuote? JSON.parse(JSON.stringify(response.persAutoInsQuote)) : null;
    const quoteData = {
      quote: null
    };
    if (responseCopy?.persAutoPolicy && responseCopy.persAutoPolicy.liabilityCoverage) {
      responseCopy.buyEnableInd = response.controlData.isbuyEnableInd;
      responseCopy.eligibleForChat = response.controlData.isQuoteChatEligible;
      responseCopy.eligibleForAutoRentersBundle = response.controlData.isQuoteChatEligible;
      responseCopy.brightlinedIndicator = response.persAutoInsQuote?.eaiBrightLineInd;
      responseCopy.policyFee = response.persAutoInsQuote?.persAutoPolicy.policyFee;
      quoteData.quote = this.filterStoreStateService.filterResponse(
        {
          respData: responseCopy
        }, FlowIndentifierEnum.RATE_QUOTE).respData;
      this.navAndRetrieveCtrlData.retrieveDataStatus.isQuoteDataExists = true;
    }
    return quoteData;
  }

  /**
   * Gets retrieve data status
   * @returns  retrieveDataStatus
   */
  getRetrieveDataStatus() {
    return JSON.parse(JSON.stringify(this.navAndRetrieveCtrlData));
  }

  /**
   * Gets query string
   * @param field field
   * @param url url
   * @returns stringExec
   */
  getQueryString(field, url) {
    const href = url ? url : window.location.href;
    const reg = new RegExp('[?&]' + field + '=([^&#]*)', 'i');
    const stringExec = reg.exec(href);
    return stringExec ? stringExec[1] : null;
  }


  /**
   * Creates discount store data for retreival
   * @param response response
   * @returns  discount
   */
  createDiscountStoreDataForRetreival(response: any) {
    const discountData = {
      policyStartDate: null,
      payPolicy: null,
      residenceType: null,
      primaryResidence: null,
      policyFee: null,
      spouseInsPolicy: null,
      householdCount: null,
      membersofhouseHoldInsuredforMI: null,
      disabilitycoverageforMI: null,
      homeTownLobSelected: false,
      renterCondoLobSelected: false,
      onlyCondoLob: false,
      lifeLobSelected: false,
      umbrellaLobselected: false,
      businessLobselected: false,
      noneOfTheAbove: false,
      motorHomeLobSelected: false,
      offRoadLobSelected: false,
      recreationalLobSelected: false,
      motorCycleLobSelected: false
    };
    // if (response.controlData.isDiscountPageVisited === 'true') {
    const todaysDate = new Date();
    const effectiveDateString = this.dateService.transform(response.persInsQuoteDataBean.startPolicyDate, 'yyyy-MM-dd');
    const effectiveDate = new Date(effectiveDateString);
    if (effectiveDate.getTime() < todaysDate.getTime()) {
      response.persInsQuoteDataBean.startPolicyDate = this.dateService.getCurrentDate();
    }
    discountData.policyStartDate = response.persInsQuoteDataBean.startPolicyDate;
    if (response.persAutoInsQuote?.persAutoPolicy) {
      discountData.payPolicy = response.persAutoInsQuote.persAutoPolicy.payPolicy;
      discountData.residenceType = response.persAutoInsQuote.persAutoPolicy.residenceType;
      discountData.primaryResidence = response.persAutoInsQuote.persAutoPolicy.residenceInsuranceType;

      discountData.spouseInsPolicy = response.persAutoInsQuote.persAutoPolicy.spouseInsPolicy;
      discountData.householdCount = response.persAutoInsQuote.persAutoPolicy.householdCount;
    }
    discountData.membersofhouseHoldInsuredforMI = response.persAutoInsQuote?.membersofhouseHoldInsuredforMI;
    discountData.disabilitycoverageforMI = response.persAutoInsQuote?.disabilitycoverageforMI;
    discountData.homeTownLobSelected = response.controlData.homeQuoteSelected;
    discountData.renterCondoLobSelected = response.controlData.renterQuoteSelected;
    discountData.onlyCondoLob = response.controlData.condoQuoteSelected;
    discountData.lifeLobSelected = response.controlData.lifeQuoteSelected;
    discountData.umbrellaLobselected = response.controlData.umbrellaQuoteSelected;
    discountData.businessLobselected = response.controlData.businessQuoteSelected;
    discountData.noneOfTheAbove = response.controlData.noneOfTheAboveSelected;
    discountData.motorHomeLobSelected = response.controlData.motorHomeQuoteSelected;
    discountData.offRoadLobSelected = response.controlData.offRoadQuoteSelected;
    discountData.recreationalLobSelected = response.controlData.recreationalQuoteSelected;
    /** US261260: Retrieve Flow Changes for the MotorCycle changes */
    discountData.motorCycleLobSelected = response.controlData.motorCycleQuoteSelected;
    this.navAndRetrieveCtrlData.retrieveDataStatus.isDiscountsDataExists = true;
    // }
    const discount = { discount: discountData };
    return discount;
  }

  /* Should show , Renters on auto screen if Project code status is turned on for AR,  AND
    redirectFlow should be RQ , AND
    rentersBundle premiumAmount should be greater than or equal to Zero.
     1. The below function only checking the premiumAmount from retrieveQuote response.
     2. Condition for RQ will check in the function "updateAutoDataFromRQResponse" in auto.component.ts
     3. projectstatus code will check in respective components.
  */
  resolveAutoRentersQuote(response: any) {

    let eligibleForAutoRentersBundle = false;
    if (response && response.persHomeInsQuote &&
      response.persHomeInsQuote.autoRentersBundlePremiumAmt) {
      eligibleForAutoRentersBundle = true;
    }
    return eligibleForAutoRentersBundle;

  }

  /**
   * Gets flow data
   * @returns  flow param
   */
  getFlowParam() {
    let flow = null;
    this.appStoreService.getFlowDataForQuote$.subscribe(data => {
      flow = data;
    }).unsubscribe();
    return flow;
  }
}
