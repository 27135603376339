import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { CardStatus } from '@ffq-app-shared/model/common.data.model';

@Component({
  selector: 'lib-card-driver',
  templateUrl: './card-driver.component.html',
  styleUrls: ['./card-driver.component.scss']
})
export class CardDriverComponent implements OnInit, AfterViewInit {

  @Input() params: any;
  @Output() cardData: EventEmitter<any> = new EventEmitter();
  addOrDeleteLabel: string;
  gtmTag: string;
  showAddSpouseCard: boolean;
  @ViewChild ('addDeleteLink', {static: false}) addDetailElement: ElementRef;
  @ViewChild ('editLink', {static: false}) editDetailElement: ElementRef;
  @ViewChild ('spouseDummyLink', {static: false}) spouseDummyElement: ElementRef;
  @ViewChild ('notMarriedLink', {static: false}) notMarriedElement: ElementRef;
  constructor() { }

  ngOnInit() {
    this.showAddSpouseCard = !this.params.isCSspouse && this.params.dummyCard;
    this.params.status === CardStatus.Completed ?
      this.addOrDeleteLabel = 'Remove from quote' : this.addOrDeleteLabel = this.params.primaryActionLabel;
    this.gtmTag = (this.params.primaryGtmTag !== '' && this.params.status !== 'C') ?
      this.params.primaryGtmTag : 'FFQ_Auto_Driver_selectDriver_checkbox';
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.spouseDummyElement && this.spouseDummyElement.nativeElement && this.params.dummyCard) {
        this.spouseDummyElement.nativeElement.focus();
      } else if (this.notMarriedElement && this.notMarriedElement.nativeElement && !this.params.dummyCard
                                          && this.params.status === CardStatus.Completed) {
        this.notMarriedElement.nativeElement.focus();
      } else if ( this.editDetailElement && this.editDetailElement.nativeElement && this.params.status === CardStatus.Completed) {
        this.editDetailElement.nativeElement.focus();
      } else if ( this.addDetailElement && this.addDetailElement.nativeElement && this.params.status !== CardStatus.Disabled &&
        this.params.pni === 'H') {
        this.addDetailElement.nativeElement.focus();
      }
    });
  }

  manageQuote(action, reference, pni, fullName) {
    const result = {
      action,
      reference,
      pni,
      fullName
    };
    this.cardData.emit(result);
  }

}
