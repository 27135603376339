import { createReducer, on } from '@ngrx/store';
import { Action } from '@ngrx/store';

/** App Actions */
import * as fromAppStates from './app.state';
import * as fromAppActions from './app.actions';
import { createNoSubstitutionTemplateLiteral } from 'typescript';
import { ErrorObj } from '@ffq-app-shared/model/common.data.model';
import { DataConstants } from '@ffq-app-shared/constants/data.constants';

const appReducer = createReducer(fromAppStates.initialState,
  /* START QUOTE */
  on(fromAppActions.StartQuoteSuccess, (state, { respData, reqPayload, ctrlData }) => {
    respData = JSON.parse(JSON.stringify(respData));
    if (state.data.src) {
      respData.src = state.data.src;
    }
    if (respData.sourceIndicator) {
      ctrlData = {
        ...ctrlData, quoteSourceIndicator: respData.sourceIndicator
      };
    }
    if (respData.partnerADPFFlow !== null) {
      ctrlData = {
        ...ctrlData, partnerADPFFlow: respData.partnerADPFFlow
      };
    }
    // when call start quote with 'params' and isRc1Eligible as false, then lob is missing in request body.
    // In this case lob must update in the store
    if (reqPayload.lob === undefined || reqPayload.lob == null) {
      reqPayload = JSON.parse(JSON.stringify(reqPayload));
      let lob = state.data.lob;
      if (lob === undefined || lob == null) {
        switch (respData.quoteType) {
          case DataConstants.LOB.auto:
            lob = 'auto'; break;
          case DataConstants.LOB.business:
            lob = 'business'; break;
          case DataConstants.LOB.home:
            lob = 'home'; break;
          case DataConstants.LOB.renter:
            lob = 'renter'; break;
          case DataConstants.LOB.condo:
            lob = 'condo'; break;
        }
      }
      reqPayload.lob = lob;
    }
    return{
        ...state, data: Object.assign({}, state.data, respData, reqPayload), error: null,
        controlData: Object.assign({}, state.controlData, ctrlData)
    };
  }),

  on(fromAppActions.StartQuoteFail, (state, { respErr }) => ({ ...state, data: null, error: respErr })),

  on(fromAppActions.MDMRetrieveSuccess, (state, { respData, reqPayload, ctrlData }) => {
    respData = JSON.parse(JSON.stringify(respData));
    if (respData.encryptedMDMResponse) {
      ctrlData = {
        ...ctrlData, quoteSourceIndicator: respData.encryptedMDMResponse
      };
    }
    return{
        ...state, data: Object.assign({}, state.data, respData, reqPayload), error: null,
        controlData: Object.assign({}, state.controlData, ctrlData)
    };
  }),

  on(fromAppActions.MDMRetrieveFail, (state, { respErr }) => ({ ...state, data: null, error: respErr })),
  /* SOURCE PHONE NUMBER SERVICE */
  on(fromAppActions.SourcePhoneNumberServiceSuccess, (state, { respData , ctrlData }) => {
    const phoneNumber = state.data.src ? respData.sourcePhoneNumberMapping.find(element => state.data.src.startsWith(element.src)) : null;
    return{
      ...state, data: { ...state.data, sourcePhoneNumber: phoneNumber ? phoneNumber.number : null },
      controlData: Object.assign({}, state.controlData, ctrlData)
    };
  }),

  on(fromAppActions.UpdatePrefillFlag, (state, { ctrlData }) =>
  ({
    ...state, controlData: Object.assign({}, state.controlData, ctrlData)
  })),
  on(fromAppActions.SourcePhoneNumberServiceFail, (state, { ctrlData }) => ({ ...state,
     controlData: Object.assign({}, state.controlData, ctrlData) })),

    on(fromAppActions.UpdateLandingSrc, (state, { reqPayload }) =>
    ({

      ...state, data: {...state.data, src: reqPayload.landingSrc},

    })),

  /* RETRIEVE QUOTE */
  on(fromAppActions.RetrieveQuoteSuccess, (state, { ctrlData }) =>
    ({ ...state, controlData: { ...state.controlData, retrieveQuote: ctrlData.retrieveQuote,
       initAppRenderServices: ctrlData.initAppRenderServices } })),
  on(fromAppActions.RetrieveQuoteFail, (state, { respErr }) => ({ ...state, data: null, error: respErr })),


  /* GET PROJECT CODE STATUS */

  on(fromAppActions.GetProjectCodeStatusSuccess, (state, { respData, ctrlData }) =>
    ({
      ...state, data: { ...state.data, projectCodeStatus: respData },
      controlData: { ...state.controlData, projectCodeServiceStatus: ctrlData.projectCodeServiceStatus }
    })),
  on(fromAppActions.GetProjectCodeStatusFail, (state, { respErr }) => ({ ...state, error: respErr })),

  /* STATIC CONTENT SERVICE */
  on(fromAppActions.StaticContentServiceSuccess, (state, { respData, ctrlData }) =>
  ({
    ...state, data: { ...state.data, staticContents: respData },
    controlData: { ...state.controlData, ...ctrlData }
  })),
  on(fromAppActions.StaticContentServiceFail, (state, { respErr }) => ({ ...state, data: null, error: respErr })),

  /* CHANGE ZIP CODE */
  on(fromAppActions.ChangeZipCode, (state, { reqPayload }) =>
    ({ ...state, data: Object.assign({}, state.data, reqPayload, { validateZip: null }), error: '' })),

  /* VALIDATE ZIP CODE */
  on(fromAppActions.ValidateZipCodeSuccess, (state, { respData }) => {
   const err = isZipFromDifferentState(state, respData.landingStateCode, state.data.projectCodeStatus.isGPRestrictImpl);
   return { ...state, data: { ...state.data, validateZip: respData }, error: err };
  }),
    // ({ ...state, data: { ...state.data, validateZip: respData }, error: '' })),
  on(fromAppActions.ValidateZipCodeFail, (state, { respErr }) => {
    /* US281249 - to set the EG026 Error for different state even
     if any error returned from service when isGPRestrictImpl turned on */
    const stateInResponse = respErr && respErr.errorInfo ? respErr.errorInfo.state : null;
    let err = isZipFromDifferentState(state, stateInResponse, state.data.projectCodeStatus.isGPRestrictImpl);
    err = err && Object.keys(err).length !== 0 ? err : respErr;
    return { ...state, error: { ...state.error, ...err } };
  }),

  /* SUBMIT YOUR INFO */
  on(fromAppActions.SubmitYourInfoSuccess, (state, { respData, ctrlData }) => {
    respData = JSON.parse(JSON.stringify(respData));
    // tslint:disable-next-line:max-line-length
    respData.customer.communication = state.data.lob === 'business' || state.data.lob === 'home' ? respData.customer.communication : state.data.customerData ? JSON.parse(JSON.stringify(state.data.customerData.customer.communication)) : {};
    if (state.data.lob === 'business') {
      return {
        ...state, data: { ...state.data, customerData: { ...state.data.customerData, ...respData }},
        controlData: Object.assign({}, state.controlData, ctrlData)
      };
    } else {
      return {
        ...state, data: { ...state.data, customerData: { ...state.data.customerData, ...respData },
         landingZipCode: respData.customer.address.zip, validateZip: null },
        controlData: Object.assign({}, state.controlData, ctrlData)
      };
    }
  }),
  /** used to update gender of auto insurance */
  on(fromAppActions.UpdatePNIGender, (state, { reqPayload }) =>
  ({
    // ...state, data: { ...state.data, customerData: { ...state.data.customerData,...reqPayload}},
    ...state, data: { ...state.data, customerData:
      { ...state.data.customerData, customer: {...state.data.customerData.customer, gender: reqPayload}}},

  })),
  on(fromAppActions.SubmitYourInfoFail, (state, { respErr, ctrlData }) =>
    ({ ...state, error: respErr, controlData: Object.assign({}, state.controlData, ctrlData) })),

  on(fromAppActions.SubmitYourInfoControlDataClear, (state, { ctrlData }) =>
    ({
      ...state, controlData: Object.assign({}, state.controlData, ctrlData)
    })),

  /* Assign Agent */
  on(fromAppActions.AssignAgentSuccess, (state, { respData, ctrlData }) =>
    ({
      ...state, data: { ...state.data, agentData: respData },
      controlData: Object.assign({}, state.controlData, ctrlData)
    })),
  on(fromAppActions.AssignAgentFail, (state, { respErr, ctrlData }) =>
    ({ ...state, controlData: Object.assign({}, state.controlData, ctrlData) })),

  on(fromAppActions.AssignAgentControlDataClear, (state, { ctrlData }) =>
    ({
      ...state, controlData: Object.assign({}, state.controlData, ctrlData)
    })),
  /** used to update the navigation data of auto insurance */
  on(fromAppActions.UpdateAutoNavStatus, (state, { reqPayload }) =>
    ({
      ...state, navigationData: { ...state.navigationData, auto: Object.assign({}, state.navigationData.auto, reqPayload) }
    })),

  on(fromAppActions.UpdateBusinessNavStatus, (state, { reqPayload }) =>
    ({
      ...state, navigationData: { ...state.navigationData, business: Object.assign({}, state.navigationData.business, reqPayload) }
    })),

  on(fromAppActions.UpdateHomeNavStatus, (state, { reqPayload }) =>
    ({
      ...state, navigationData: { ...state.navigationData, home: Object.assign({}, state.navigationData.home, reqPayload) }
    })),

  /** used to clear the submit yourinfo data and its control data from auto insurance */
  on(fromAppActions.ClearYourInfoDataAndControlData, (state) => {
    /** in order to check whether the quote is a corss-selled one */
     const crosellStatus = state.controlData.crossSellStatus === true;
     /** if the quote is corss-selled , no need to clear customer data */
     if (crosellStatus) {
      return {
        ...state,
        controlData: { ...state.controlData, submitYourInfo: null, assignAgent: null, maxAllowedVehicle: null }
      };

     } else {
      return {
        ...state, data: { ...state.data, customerData: null },
        controlData: { ...state.controlData, submitYourInfo: null, assignAgent: null, maxAllowedVehicle: null }
      };
    }
    }),

  /** used to clear zip validation error message  */
  on(fromAppActions.ClearZipValidationErrorMessage, (state) =>
    ({
      ...state, data: Object.assign({}, state.data, { validateZip: null }), error: ''
    })),
  on(fromAppActions.UpdateCommunicationDetailsOnSuccess, (state, { respData }) =>
    ({
      ...state, data: {
        ...state.data, customerData: {
          ...state.data.customerData, customer: {
            ...state.data.customerData.customer, communication: { ...respData }
          }
        }
      },
    }
    )),
  /*SAVE AND FINISH LATER */
  on(fromAppActions.SaveAndFinishLaterSuccess, (state, { ctrlData }) =>
    ({
      ...state, controlData: Object.assign({}, state.controlData, ctrlData)
    })),
  on(fromAppActions.SaveAndFinishLaterFail, (state, { respErr, ctrlData }) =>
    ({ ...state, error: null, controlData: Object.assign({}, state.controlData, ctrlData) })),

  /* BW Assign Agent */
  on(fromAppActions.BWAgentAssignmentSuccess, (state, { respData, ctrlData }) => {
    /** if "agentChanged:false" and "agentAvailable: true" in response means that there is no change in agent information
     *  so we do not need to edit the agent information in store
     */
    if (respData.agentChanged && !respData.agentAvailable) {
      return {
        ...state, data: { ...state.data, agentData: Object.assign({}, respData) },
        controlData: Object.assign({}, state.controlData, ctrlData)
      };
    } else {
      return {
        ...state, controlData: Object.assign({}, state.controlData, ctrlData)
      };
    }
  }),
  on(fromAppActions.BWAgentAssignmentFail, (state, { respErr, ctrlData }) =>
    ({ ...state, error: respErr, controlData: Object.assign({}, state.controlData, ctrlData) })),

  on(fromAppActions.PopulateSessionStorageOnRetrieval, (state, { sessionStorageData, navAndRetrieveCtrlData }) => {
    sessionStorage.setItem('retrieveQuoteDetails', JSON.stringify(sessionStorageData));
    return { ...state, ...navAndRetrieveCtrlData };
  }),
  /** used to update the retrieve data status of auto insurance */
  on(fromAppActions.UpdateRetrieveDataStatus, (state, { reqPayload }) =>
    ({
      ...state, retrieveDataStatus: { ...state.retrieveDataStatus, ...reqPayload }
    })),

  on(fromAppActions.PopulateAppStoreOnRetrieval, (state, { respData }) =>
    ({
      ...state, data: respData.data,
      controlData: Object.assign({}, state.controlData, respData.controlData),
      error: null
    }
    )),

  /* VALIDATE EMAIL  ADDRESS */
  on(fromAppActions.ValidateEmailSuccess, (state, { respData }) =>
    ({ ...state, data: { ...state.data, validateEmail: respData }, error: '' })),
  on(fromAppActions.ValidateEmailFail, (state, { respErr }) => ({ ...state, error: { ...state.error, ...respErr } })),
  on(fromAppActions.ValidateDriverPage, (state, { ctrlData }) =>
    ({
      ...state, controlData: Object.assign({}, state.controlData, ctrlData)
    })),

  /* CROSSSELL   ENCRYPT DATA */
  on(fromAppActions.GetCrossSellEncryptedDataSuccess, (state, { respData }) =>
    ({ ...state, data: { ...state.data, crossSellEncryptedData: respData }, error: ''})),
  on(fromAppActions.GetCrossSellEncryptedDataFail, (state, { respErr }) => ({ ...state, error: { ...state.error, ...respErr}
  })),
  /* UPDATE FORM EDITED */
  on(fromAppActions.UpdateFormEdited, (state, { reqPayload }) =>
    ({
      ...state, data: { ...state.data }, controlData: { ...state.controlData, formEdited: reqPayload }
    })),
  /* Update last Visied Page */
  on(fromAppActions.UpdateLastVisitedPage, (state, { reqPayload }) =>
    ({
      ...state, controlData: { ...state.controlData, ...reqPayload }
    })),

  /* Sessions keep alive */
  on(fromAppActions.SessionKeepAliveSuccess, (state, { ctrlData }) =>
    ({
      // controlData: { ...state.controlData, sessionKeepAlive: ctrlData.sessionKeepAlive }
      ...state, controlData: Object.assign({}, state.controlData, ctrlData)
    })),
  on(fromAppActions.SessionKeepAliveFail, (state, { respErr }) => ({ ...state, error: null })),

  /** action for updating app control data */
  on(fromAppActions.UpdateAppControlData, (state, { ctrlData }) =>
    ({
      ...state, controlData: { ...state.controlData, ...ctrlData }
    })),
  /** action for updating SegmentEvent */
  on(fromAppActions.UpdateSegmentEvent, (state, { ctrlData }) =>
  ({
    ...state, controlData: { ...state.controlData, ...ctrlData }
  })),
  on(fromAppActions.UpdateNavStatus, (state, { reqPayload }) =>
    ({
      ...state, navigationData: checkStatus(state, reqPayload)
    })),
  on(fromAppActions.StartQuoteWithQuoteNumberFail, (state, { respErr, ctrlData }) =>
    ({
      ...state, controlData: Object.assign({}, state.controlData, ctrlData), error: respErr
    })),
  on(fromAppActions.StartQuoteWithQuoteNumberSuccess, (state, { respData, ctrlData }) => {
    // tslint:disable-next-line:max-line-length
    return {
      ...state, data: respData.data,
      controlData: Object.assign({}, state.controlData, ctrlData)
    };
  }),
  on(fromAppActions.UpdateCrossSellStatus, (state, { ctrlData }) =>
    ({
      ...state, controlData: Object.assign({}, state.controlData, ctrlData)
    })),
  on(fromAppActions.UpdateRedirectionFlow, (state, { ctrlData }) =>
    ({
      ...state, controlData: Object.assign({}, state.controlData, ctrlData)
    })),
  on(fromAppActions.AgentBusinessHourSuccess, (state, { respData, ctrlData }) =>
    ({
      ...state, data: { ...state.data, businessHourResponse: respData },
      controlData: Object.assign({}, state.controlData, ctrlData)
    })),
  on(fromAppActions.AgentBusinessHourFail, (state, { respErr, ctrlData }) =>
    ({
      ...state, controlData: Object.assign({}, state.controlData, ctrlData), error: respErr
    })),
  on(fromAppActions.CheckChatStatusSuccess, (state, { respData, ctrlData }) =>
    ({
      ...state, data: { ...state.data, isChatActive: respData },
      controlData: Object.assign({}, state.controlData, ctrlData)
    })),
  on(fromAppActions.CheckChatStatusFail, (state, { respErr, ctrlData }) =>
    ({
      ...state, controlData: Object.assign({}, state.controlData, ctrlData), error: respErr
    })),
  /* UPDATE driver module chnaged */
  on(fromAppActions.DriverChangedAfterQuote, (state, { reqPayload }) =>
    ({
      ...state, data: { ...state.data }, controlData: { ...state.controlData, driverChangedAfterQuote: reqPayload }
    })),
  /* UPDATE vehicle module chnaged */
  on(fromAppActions.VehicleChangedAfterQuote, (state, { reqPayload }) =>
    ({
      ...state, data: { ...state.data }, controlData: { ...state.controlData, vehicleChangedAfterQuote: reqPayload }
    })),
  /* UPDATE vehicle pr driver module chnaged */
  on(fromAppActions.VehicleOrDriverChanged, (state, { reqPayload }) =>
  ({
    ...state, data: { ...state.data }, controlData: { ...state.controlData, vehicleOrDriverChanged: reqPayload }
  })),
  /* Update when Policy start date changes affecting the Drivingxp */
  on(fromAppActions.PolicyStartDateChanged, (state, { reqPayload }) =>
    ({
      ...state, data: { ...state.data }, controlData: { ...state.controlData, policyStartDateChanged: reqPayload }
    })),
  on(fromAppActions.UpdateVehicleCount, (state, { ctrlData }) =>
    ({
      ...state, controlData: Object.assign({}, state.controlData, ctrlData)
    })),
  on(fromAppActions.UpdateDriverCount, (state, { ctrlData }) =>
    ({
      ...state, controlData: Object.assign({}, state.controlData, ctrlData)
    })),
  on(fromAppActions.AdditionalDetailsPageStatus, (state, { ctrlData }) =>
    ({
      ...state, controlData: Object.assign({}, state.controlData, ctrlData)
    })),
  on(fromAppActions.BackDoorCustomCodeImplementFlag, (state, { ctrlData }) =>
    ({
      ...state, controlData: Object.assign({}, state.controlData, ctrlData)
    })),
    on(fromAppActions.UpdateRefreshFlag, (state, { ctrlData }) =>
    ({
      ...state, controlData: Object.assign({}, state.controlData, ctrlData)
    })),
    on(fromAppActions.UpdateAppRootStoreDirectly, (state,  { reqPayload }) =>
  ({
    ...state, ...reqPayload,
  })),
   /** action for updating app data */
   on(fromAppActions.UpdateAppData, (state, { reqPayload }) =>
   ({
     ...state, data: { ...state.data, ...reqPayload }
   })),
   on(fromAppActions.FetchTownListSuccess, (state, { respData, ctrlData }) =>
    ({
      ...state, data: {...state.data, zipTownList: respData.dropDownVO}, controlData: Object.assign({}, state.controlData, ctrlData)
    })),
  on(fromAppActions.FetchTownListFail, (state, { respErr, ctrlData }) =>
    ({ ...state, error: respErr, controlData: Object.assign({}, state.controlData, ctrlData) })),
  on(fromAppActions.RetrievePIRateSummarySuccess, (state, { ctrlData }) => {
    sessionStorage.setItem('windowID', state.data.windowId);
    return { ...state, controlData: { ...state.controlData, retrieveRateSummary: ctrlData.retrieveRateSummary,
         initAppRenderServices: ctrlData.initAppRenderServices }};
    }),
  on(fromAppActions.RetrievePIRateSummaryFail, (state, { respErr,  ctrlData }) =>
  ({ ...state, data: null, error: {errorInfo : {errorCode : 'EG014'}}, controlData: Object.assign({}, state.controlData, ctrlData) })),
   /* Update last Visied Step for segment tracking */
  on(fromAppActions.UpdateLastVisitedStep, (state, { reqPayload }) =>
   ({
     ...state, controlData: { ...state.controlData, ...reqPayload }
   })),
  on(fromAppActions.UpdatePpcSrcAvailabilityStatus, (state, { ctrlData }) =>
    ({
      ...state, controlData: Object.assign({}, state.controlData, ctrlData)
    })),
    // to update promo id and campaign id
    on(fromAppActions.UpdatePromoIdCid, (state, { reqPayload }) =>
    ({
      ...state, data: {...state.data, promoId: reqPayload.promoId, campaignId: reqPayload.campaignId},
    })),
  // update utm parameters from landing/redirection from partner
  on(fromAppActions.UpdateUtmParameters, (state, {reqPayload}) =>
    ({
      ...state, data: {...state.data, ...reqPayload }
    })),
  // update campaign parameters from landing/redirection from partner
  on(fromAppActions.UpdateCampaignParameters, (state, {reqPayload}) =>
    ({
      ...state, data: {...state.data, ...reqPayload }
    })),
  // update customer last name for test user check
  on(fromAppActions.UpdateCustomerLastName, (state, {reqPayload}) =>
    ({
      ...state, data: { ...state.data, customerInfo: { ...state.data.customerInfo, ...reqPayload } }
    })),
  // update customer email for test user check
  on(fromAppActions.UpdateCustomerEmail, (state, {reqPayload}) =>
    ({
      ...state, data: { ...state.data, customerInfo: { ...state.data.customerInfo, ...reqPayload } }
    })),
  /** update customer details in store from schedule appointment */
  on(fromAppActions.UpdateCustomerDetails, (state, {respData}) =>
  ({
    ...state, data: { ...state.data, customerData:
      { ...state.data.customerData, customer: {...respData}}}

  })),
  // multiquote data
  on(fromAppActions.MultiQuoteData, (state, { respData, request }) =>
  ({
    ...state, data: { ...state.data, ...respData, landingZipCode: request.landingZipCode,
      customerData: { lastName: request.lastName, dateOfBirth :  request.dateOfBirth } }
  })),
   on(fromAppActions.RetrieveQuoteRedirectionData, (state, { respData }) =>
   ({
     ...state, data: { ...state.data, ...respData }
   })),
   on(fromAppActions.UpdateBWCompanyCode, (state, {reqPayload}) =>
   ({
     ...state, data: {...state.data, ...reqPayload }
   })),
   on(fromAppActions.SaveActionsSuccess, (state, { controlData }) =>
   ({
    ...state,
    controlData: { ...state.controlData, ...controlData },
  })),
  on(fromAppActions.SaveActionsFail, (state, { controlData }) => ({
    ...state,
    controlData: Object.assign({}, state.controlData, controlData)
  })),
);

export function appReducerFn(state: fromAppStates.AppState | undefined, action: Action) {
  return appReducer(state, action);
}
// to check the lob to set the navigation data accordingly
export function checkStatus(state, reqPayload) {
  if (state.data.lob === 'auto') {
    return { ...state.navigationData, auto: Object.assign({}, state.navigationData.auto, reqPayload) };
  } else if (state.data.lob === 'home' || state.data.lob === 'renter' || state.data.lob === 'condo') {
    return { ...state.navigationData, home: Object.assign({}, state.navigationData.home, reqPayload) };
  }
}
/*Set lob on basis of quoteType*/
export function setLob(quoteType) {
  if (quoteType === 'A') {
    return 'auto';
  } else if (quoteType === 'H') {
    return 'home';
  } else if (quoteType === 'B') {
    return 'business';
  }
}

export function  isZipFromDifferentState(state, newState: string, isGPRestrictImpl: boolean): ErrorObj  {
   // US281249 - isGPRestrictImpl turned on and setting EG026 Error from UI
  let  errorObj = {} as ErrorObj;
  if (isGPRestrictImpl && state.data.quoteType === DataConstants.LOB.auto
    && state.data.landingStateCode !== newState) {
    errorObj = {
      service: 'ValidateZip', errorInfo: {
        errorCode: 'EG026',
        errorMessage: 'Zip is from Another state',
        exceptionName: 'InvalidZipEntryFromDifferentStateException',
        lob: 'A',
        quoteNumber: state.data.quoteNumber
      }
    };
  }
  return errorObj;
}
