import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { SpinnerService } from '@ffq-lib/src/lib/spinner/spinner.service';
import { Router } from '@angular/router';
import { SegmentService } from '@ffq-app-shared/services/segment.service';
import { ErrorOccurredProps } from '@ffq-app-shared/model/segment.data.model';
import { ServiceConstants } from '@ffq-app-shared/constants';
@Injectable({
  providedIn: 'root'
})
export class ErrorService {


  constructor(private spinnerService: SpinnerService,
              private router: Router,
              private segmentService: SegmentService) { }


  handleError(response: HttpErrorResponse) {
    // this.spinnerService.hideLoader();
    if (response.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', response.error.message);
    } else {
      const errorDetails: ErrorOccurredProps = {
        error_location: response.url ? response.url.replace(ServiceConstants.BASE_URL, '') : '',
        error_type: 'API request error',
        error_element: '',
        error_description: response.message ? response.message : '',
        error_context: response.url ? response.url.replace(ServiceConstants.BASE_URL, '') + ' API' : '',
        error_additional_info_1: response.status ? response.status : '',
        error_additional_info_2: response.statusText ? response.statusText : ''
      };
      if (response.error && response.error.errorInfo) {
        if (response.error.errorInfo.errorCode) {
          errorDetails.error_additional_info_1 = response.error.errorInfo.errorCode;
        }
        if (response.error.errorInfo.errorMessage) {
         errorDetails.error_additional_info_2 = response.error.errorInfo.errorMessage;
        }
      }

      this.segmentService.trackErrorOccurred(errorDetails);

      console.error(
        `Backend returned code ${response.status}, ` +
        `body was: ${JSON.stringify(response.error)}`
      );
    }
    // return an observable with a user-facing error message
    if (response.error && response.error.message) {
      const errObj = {
        status: response.error.status,
        message: response.error.message,
        errorInfo: response.error.errorInfo
      };
      return throwError(errObj);
    } else {
      //  return throwError('Something bad happened; please try again later.');

      // console.log('HTTP - response Error : ' + response.status, response);
      const errObj = {
        status: null,
        message: null,
        errorInfo: null
      };
      if (!response.error) {

        errObj.errorInfo = {
          errorCode: 'ERR1'
        };
        errObj.message = 'Service Error';
      } else if (!response.error.errorInfo || (response.error && response.error.status === 500)) {
        errObj.errorInfo = {
          errorCode: 'ERR1'
        };
      }
      return throwError(errObj);
    }
  }
}
