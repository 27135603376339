<mat-form-field [formGroup]="params.formGroup" class="full-width-field form-elements" [ngClass]="{'failure': inputError && myControl.value }" appearance="fill">
  <mat-label *ngIf="params.placeholder">{{ params.placeholder }}</mat-label>
  <input #search1 [attr.aria-label]='params.ariaLabel' type="text" matInput class="auto-complete-lib"
    (input)="onInputChanged($event)" [formControl]="myControl" [matAutocomplete]="auto"
    formControlName="{{params.formControllName}}" />
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onSelectionChanged($event)">
    <div>
      <ng-container *ngIf="filteredOptions">
        <mat-option *ngFor="let option of filteredOptions " [value]="option.matchDisplayItem"
          [attr.data-GTM]="option.gtmTag">
          {{option.matchDisplayItem}}
        </mat-option>
      </ng-container>
    </div>
  </mat-autocomplete>

  <mat-hint *ngIf="params.assistiveText" align="start"><strong>{{params.assistiveText}}</strong> </mat-hint>

  <lib-form-field-loader *ngIf="params.showLoader"></lib-form-field-loader>

  <mat-error>
    <ng-container *ngIf="params.messages">
      <ng-container *ngFor="let key of Object.keys(params.messages)">
        <ng-container *ngIf="((params.formGroup).get(params.formControllName)).hasError(key)">
          {{ params.messages[key] }}</ng-container>
      </ng-container>
    </ng-container>
  </mat-error>
</mat-form-field>