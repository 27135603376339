import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class LibraryDataService {
  private dialogAction = new Subject<string>();
  dialogActionPerformed$ = this.dialogAction.asObservable();

  constructor() {

  }

  performAction(action: string) {
    this.dialogAction.next(action);
  }

}
