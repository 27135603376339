<div id="quote_unavailable-page-container">
    <div id="quote_unavailable-content">
        <div id="quote_unavailable-header_ffq-logo" title="Farmers &reg;"></div>
        <div id="quote_unavailable_header-title">
            <h1>Sorry, we don't offer insurance in your area.</h1>
        </div>
            <div id="quote_unavailable_body_message">
                Click at least 2 to 3 companies below to find the best rate!
            </div>
            <div class="local-insurance-provier-list clearfix" id="Content">
            </div>
            <div id="need_help">
                <div id="quote_unavailable_body_noagent"> Need more help?</div>
                <div id="quote_unavailable_body_call-custome">You can call customer service at</div>
                <div id="quote_unavailable_body_farmers" x-ms-format-detection = "none" class="quote_unavailable_desktop">
                    <a href={{teleDesktop}} tabindex="0" role="link">{{desktopNo}}</a>
                </div>
                <div id="quote_unavailable_body_farmers" x-ms-format-detection = "none" class="quote_unavailable_mobile">
                    <a href={{teleMobile}} role="link">{{mobileNo}}</a>
                </div>
            </div>
    </div>
</div>