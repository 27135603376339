<div id="no-business-quote-page-container">
    <div id="no-business-quote-content">
        <div id="no-business-quote-header_ffq-logo" title="Farmers &reg;" aria-label="Graphical Farmers Insurance Logo"></div>
        <div id="no-business-quote_header-title">
            <h1>We’re sorry</h1>
        </div>
        <div id="no-business-quote_body_message">Online Business Quote in your area is unavailable. Please accept our apologies for the inconvenience.</div>
        <div id="need_help">
            <div id="no-business-quote_body_noagent"> Need more help?</div>
            <div id="no-business-quote_body_call-custome">You can call customer service at</div>
            <span tabindex="0" [attr.aria-label]="desktopAriaLlabel" class="no-business-quote_desktop">
                <div id="no-business-quote_body_farmers" x-ms-format-detection = "none">
                    <a href={{teleDesktop}} tabindex="0" role="link">{{desktopNo}}</a>
                </div>
            </span>
            <span [attr.aria-label]="mobileAriaLlabel" class="no-business-quote_mobile">
                <div id="no-business-quote_body_farmers" x-ms-format-detection = "none">
                    <a href={{teleMobile}} role="link">{{mobileNo}}</a>
                </div>
            </span>
        </div>

        <div id="agent_button">
            <button (keyup.enter)="findAnAgent()" (click)="findAnAgent()" role= "presentation" tabindex= "0" aria-label="Let's find an agent button link" mat-button class="ffq-card-button-blue">
                <span class="find_agent_text">LET'S FIND AN AGENT</span> 
            </button>
        </div>  
    </div>
</div>