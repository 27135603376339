import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormInputParam } from '../form-input-params';
import { FormFieldEmitParams } from '../params/form-field-emit-params';

@Component({
  selector: 'lib-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss']
})
export class RadioButtonComponent implements OnInit {

  /**
   * Output  of radio button component
   */
  @Output() selectedData: EventEmitter<any> = new EventEmitter();

  @Input() params: FormInputParam;
  Object = Object;

  /**
   * US255604 - Segment Tracking Field Engagement
   */
  @Output() radioFieldValue: EventEmitter<FormFieldEmitParams> = new EventEmitter();
  lastRadioValue: string;

  /**
   * Error message of radio button component
   */
  errorMessage: string;


  /**
   * Determines whether error is
   */
  isError: boolean;

  constructor() { }


  /**
   * on init
   */
  ngOnInit() {
    this.isInputError();
  }

  /**
   * Determines whether form error is
   * @returns  boolean
   */
  isInputError() {
    this.isError = false;
    this.errorMessage = null;
    if ((this.params.formGroup).get(this.params.formControllName).touched
      || ((this.params.formGroup).get(this.params.formControllName).dirty)) {
      if (this.params.messages) {
        for (const key of Object.keys(this.params.messages)) {
          if (((this.params.formGroup).get(this.params.formControllName).hasError(key))) {
            this.errorMessage = this.params.messages[key];
            if ( this.params.focus) {
              setTimeout(() => {
                this.isError = true;
                this.params.focus = false;
             }, 500);
            } else {
              this.isError = true;
            }
          }
        }
      }
    }
    return this.isError;
  }

  /**
   * Selected value
   */
  selectedValue(event$: any) {
    this.selectedData.emit(this.params.formGroup.controls[this.params.formControllName].value);
    /**
     * US255604 - Segment Tracking Field Engagement
     */
    let target;
    if (event$.source && event$.source._elementRef && event$.source._elementRef.nativeElement) {
      target = event$.source._elementRef.nativeElement;
      const selectedRadioButton = {
        value: event$.value,
        text: target.innerText.trim()
      };
      if (selectedRadioButton.text && selectedRadioButton.text !== this.lastRadioValue) {
        this.lastRadioValue = selectedRadioButton.text;
        this.radioFieldValue.emit({ value: selectedRadioButton.text, event: 'Value Added' });
      }
    }
  }
}
