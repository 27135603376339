import { Injectable } from '@angular/core';
export interface ICustomWindow extends Window {
    analytics: any;
}
function getWindow(): any {
    return window;
}
@Injectable({
  providedIn: 'root'
})
export class WindowRef {
    get nativeWindow(): ICustomWindow {
        return getWindow();
    }
}
