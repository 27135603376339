import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[libField]'
})
export class ValidatorsDirective implements OnInit {

  @Input() validate: string;

  private element: HTMLInputElement;
  constructor(private el: ElementRef) {
  }

  /**
   * Inserts charecter by index
   * @ param input
   * @ param chr
   * @ param position
   * @ returns
   */
  insertCharecterByIndex(input, chr, position) {
    return input.substring(0, position) + chr + input.substring(position);
  }

  /**
   * on init
   */
  ngOnInit() {
    this.element = this.el.nativeElement;
    if (this.validate) {
      if ((this.validate === 'zipField') || (this.validate === 'ageFirstLicensed')) {
        this.element.onkeypress = (e) => {
          // tslint:disable-next-line: deprecation
          if (e.ctrlKey || e.keyCode === 9) {
            return;
          }
          // tslint:disable-next-line: deprecation
          const keyCode = e.which ? e.which : e.keyCode;
          const ret = keyCode >= 48 && keyCode <= 57 || [8, 13].indexOf(keyCode) !== -1;
          return ret;
        };
      }
      if (this.validate === 'stringHyphenField') {
        this.element.onkeypress = (event) => {
          // tslint:disable-next-line: deprecation
          return (event.charCode > 64 &&
            // tslint:disable-next-line: deprecation
            event.charCode < 91) || event.charCode === 32 || event.charCode === 45 || (event.charCode > 96 && event.charCode < 123);
        };
      }
      if (this.validate === 'stringSpaceField') {
        this.element.onkeypress = (event) => {
          // tslint:disable-next-line: deprecation
          return (event.charCode > 64 &&
            // tslint:disable-next-line: deprecation
            event.charCode < 91) || event.charCode === 32 || (event.charCode > 96 && event.charCode < 123);
        };
      }
      if (this.validate === 'vinField') {
        this.element.onkeypress = (evt) => {
          if (this.element.value.length >= 17) { return false; }
        };
      }
      /* if (this.validate === 'ageFirstLicensed') {
         this.element.onkeypress = (e) => {
           // tslint:disable-next-line: deprecation
           if (e.ctrlKey || e.keyCode === 9) {
             return;
           }
           // tslint:disable-next-line: deprecation
           const keyCode = e.which ? e.which : e.keyCode;
           const ret = keyCode >= 48 && keyCode <= 57 || [8, 13].indexOf(keyCode) !== -1;
           return ret ? true : false;
         };
       }*/
      /* DE6659 : Issue in the maximum range of
    value(digits) allowed for the field "Miles
     you drive one-way" in Add Vehicle screen of an Auto quote */
      if (this.validate === 'oneWayMiles') {
        this.element.onkeypress = (evt) => {
          if (this.element.value.length > 2) { return false; }
        };
      }
      if (this.validate === 'annualMiles') {
        this.element.onkeypress = (evt) => {
          if (this.element.value.length > 5) { return false; }
        };
      }

      if (this.validate === 'phoneNumber') {
        this.element.onkeypress = (evn) => {
          // tslint:disable-next-line: max-line-length
          const val = this.el.nativeElement.value;
          // tslint:disable-next-line: deprecation
          const isTypeNumber = (evn.charCode >= 48 && evn.charCode <= 57);
          let numVal = val.replace(/\D/g, '');
          if (numVal.length > 10 || !isTypeNumber) {
            return false;
          }
          if (numVal.length >= 0) {
            numVal = this.insertCharecterByIndex(numVal, '(', 0);
          }
          if (numVal.length > 3) {
            numVal = this.insertCharecterByIndex(numVal, ')', 4);
          }
          if (numVal.length > 7) {
            numVal = this.insertCharecterByIndex(numVal, '-', 8);
          }
          this.el.nativeElement.value = numVal;
        };
        this.el.nativeElement.onkeyup = (evn) => {
          const val = this.el.nativeElement.value;
          let numVal = val.replace(/\D/g, '');
          if (numVal.length > 0) {
            numVal = this.insertCharecterByIndex(numVal, '(', 0);
          }
          if (numVal.length > 4) {
            numVal = this.insertCharecterByIndex(numVal, ')', 4);
          }
          if (numVal.length > 8) {
            numVal = this.insertCharecterByIndex(numVal, '-', 8);
          }
          this.el.nativeElement.value = numVal;
        };
      }
    }
  }
}

