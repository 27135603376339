import { NgModule } from '@angular/core';
import { FfqLibraryComponent } from './ffq-lib.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfoDialogComponent, InfoDialogContentComponent, ActionDialogComponent, ActionDialogContentComponent } from './dialog';
import { DialogModule } from './dialog/dialog.module';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatRippleModule} from '@angular/material/core';
import { MatNativeDateModule } from '@angular/material/core';
import {MatRadioModule} from '@angular/material/radio';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDatepickerModule, MatDatepickerIntl} from '@angular/material/datepicker';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatIconModule} from '@angular/material/icon';
import {
  InputTextComponent,
  SelectComponent,
  RadioButtonComponent,
  ButtonComponent,
  CheckBoxComponent,
  DatePickerComponent,
  AddressAutocompleteComponent
} from './form-elements';
import { GooglePlacesDirective } from './form-elements/address-autocomplete/google-places.directive';
import { CardComponent } from './card/card.component';
import { CardDriverComponent } from './card-driver/card-driver.component';
import { ValidatorsDirective } from './directives/validators.directive';
import { FormFieldLoaderComponent } from './form-elements/form-field-loader/form-field-loader.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AutoCompleteComponent } from './form-elements/auto-complete/auto-complete.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { ActionDialogNewComponent } from './dialog/action-dialog-new/action-dialog-new.component';
import { AccordionComponent } from './accordion/accordion.component';
import { CoveragePanelComponent } from './coverage-panel/coverage-panel.component';
import { CardPackageComponent } from './card-package/card-package.component';
import { CardRideshareAssignmentComponent } from './card-rideshare-assignment/card-rideshare-assignment.component';
import { CardAssignmentComponent } from './card-assignment/card-assignment.component';
import { DatePickerMonthyearFormatComponent,
  CustomDatePickerHeaderComponent } from './form-elements/date-picker-monthyear-format/date-picker-monthyear-format.component';
import { TextMaskModule } from 'angular2-text-mask';
import { CommonAutocompleteComponent } from './form-elements/common-autocomplete/common-autocomplete.component';
import { InlineSpinnerComponent } from './inline-spinner/inline-spinner.component';
import { AutocompleteTypeaheadComponent } from './form-elements/autocomplete-typeahead/autocomplete-typeahead.component';
import { TypeaheadCompComponent } from './form-elements/typeahead-comp/typeahead-comp.component';
import {MatSelectModule} from '@angular/material/select';
import { AutocompleteEmployerDropdownComponent, BoldPipe,
} from './form-elements/autocomplete-employer-dropdown/autocomplete-employer-dropdown.component';
@NgModule({
  declarations: [
    FfqLibraryComponent,
    ActionDialogContentComponent,
    InputTextComponent,
    SelectComponent,
    CheckBoxComponent,
    DatePickerComponent,
    RadioButtonComponent,
    ButtonComponent,
    GooglePlacesDirective,
    AddressAutocompleteComponent,
    ValidatorsDirective,
    CardComponent,
    AutocompleteTypeaheadComponent,
    AutocompleteEmployerDropdownComponent,
    FormFieldLoaderComponent,
    CardDriverComponent,
    AutoCompleteComponent,
    TypeaheadCompComponent,
    SpinnerComponent,
    AccordionComponent,
    CoveragePanelComponent,
    CardPackageComponent,
    CardRideshareAssignmentComponent,
    CardAssignmentComponent,
    DatePickerMonthyearFormatComponent,
    CustomDatePickerHeaderComponent,
    CommonAutocompleteComponent,
    InlineSpinnerComponent,
    BoldPipe
  ],
  imports: [
    MatDialogModule,
    MatCheckboxModule,
    MatButtonModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatProgressBarModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    CommonModule,
    MatInputModule,
    ReactiveFormsModule,
    MatRippleModule,
    MatRadioModule,
    DialogModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    TextMaskModule,
    MatIconModule
  ],
  entryComponents: [ActionDialogNewComponent, InfoDialogContentComponent, ActionDialogContentComponent,
    InfoDialogComponent, ActionDialogComponent],
  exports: [
    FfqLibraryComponent,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressBarModule,
    MatRippleModule,
    InputTextComponent,
    SelectComponent,
    CheckBoxComponent,
    DatePickerComponent,
    RadioButtonComponent,
    ButtonComponent,
    AddressAutocompleteComponent,
    AutoCompleteComponent,
    TypeaheadCompComponent,
    AutocompleteTypeaheadComponent,
    AutocompleteEmployerDropdownComponent,
    CardComponent,
    DialogModule,
    CardDriverComponent,
    MatProgressSpinnerModule,
    MatExpansionModule,
    SpinnerComponent,
    AccordionComponent,
    CoveragePanelComponent,
    CardPackageComponent,
    CardRideshareAssignmentComponent,
    CardAssignmentComponent,
    DatePickerMonthyearFormatComponent,
    CustomDatePickerHeaderComponent,
    CommonAutocompleteComponent,
    InlineSpinnerComponent,
    MatIconModule
  ],
  providers: [
    ActionDialogComponent,
    ActionDialogNewComponent,
    InfoDialogComponent,
    MatDatepickerIntl ]
})
export class FfqLibraryModule { }
