import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

/* rx.js */
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

/* App */
import { ErrorService } from '@ffq-app-core';
import { environment } from '@environments/environment';
import { ServiceConstants } from '@ffq-app-shared';

@Injectable({
  providedIn: 'root'
})
export class YourInfoService {
  constructor(private http: HttpClient, private errorService: ErrorService) { }

  // submitYourInfo$(data: any): Observable<any> {
  //   return this.http.post(environment.endPoint + ServiceConstants.YOUR_INFO_SUBMIT, data).pipe(
  //     map((resp: any) => resp),
  //     catchError(error => this.errorService.handleError(error))
  //   );
  // }

  // assignAgent$(data: any): Observable<any> {
  //   return this.http.post(environment.endPoint + ServiceConstants.YOUR_INFO_ASSIGN_AGENT, data).pipe(
  //     map((resp: any) => resp),
  //     catchError(error => this.errorService.handleError(error))
  //   );
  // }

  getPrerefillData$(data: any): Observable<any> {
    return this.http.post(ServiceConstants.BASE_URL + ServiceConstants.ADPF_PREFILL, data).pipe(
      map((resp: any) => resp),
      catchError(error => this.errorService.handleError(error))
    );
  }
}
