import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Router } from '@angular/router';

/* Components */
import { MatIconModule } from '@angular/material/icon';
import { FfqLibraryModule } from '@ffq-lib';
import { StoreModule } from '@ngrx/store';
import { KnockOutComponent } from './components/knock-out/knock-out.component';
import { AutoSharedRoutingModule } from './shared-routing.module';
import { AutoEffects } from '../store/auto.effects';
import { AutoReducer } from '@ffq-app-auto/store';
import { EffectsModule } from '@ngrx/effects';
import { SharedModule } from '@ffq-app-shared/shared.module';
import { DiscountsVinDialogComponent } from './components/discounts-vin-dialog/discounts-vin-dialog.component';
import { SignalBannerComponent } from './components/signal-banner/signal-banner.component';
import { SignalBannerPopupComponent } from './components/signal-banner-popup/signal-banner-popup.component';
import { ChatbotComponent } from './components/chatbot/chatbot.component';
import { ChatbotService } from './components/chatbot/chatbot.service';
import { SignalDiscountBannerComponent } from './components/signal-discount-banner/signal-discount-banner.component';
import { SignalDiscountPopupComponent } from './components/signal-discount-popup/signal-discount-popup.component';
import { DiscountJourneyComponent } from './components/discount-journey/discount-journey.component';

/* Services */
// import { ModalService } from './services';

/* Modules */

@NgModule({
  // tslint:disable-next-line:max-line-length
  declarations: [KnockOutComponent, DiscountsVinDialogComponent, SignalBannerComponent, SignalBannerPopupComponent,
    ChatbotComponent,
    SignalDiscountBannerComponent,
    SignalDiscountPopupComponent,
    DiscountJourneyComponent
  ],
  imports: [
    /* NgRx */
    /* NgRx Imports for Feature Stores */
    StoreModule.forFeature('auto', AutoReducer.autoReducerFn),
    EffectsModule.forFeature([AutoEffects]),
    CommonModule,
    SharedModule,
    AutoSharedRoutingModule
  ],
  exports: [SignalBannerComponent, ChatbotComponent, SignalDiscountBannerComponent,  DiscountJourneyComponent],
  entryComponents: [KnockOutComponent, DiscountsVinDialogComponent, SignalBannerComponent,
    SignalBannerPopupComponent, SignalDiscountBannerComponent, SignalDiscountPopupComponent,  DiscountJourneyComponent],
})
export class AutoSharedModule { }
