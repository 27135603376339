import { Injectable } from '@angular/core';
import { DateService } from '@ffq-lib/src/lib/core/services/date.service';
import { AppStoreService } from '@ffq-app-store/app.store.service';
import { StateRuleModelService } from '@ffq-app-shared/services/state-rule-model.service';
import moment from 'moment';


@Injectable({
  providedIn: 'root'
})
export class DateValidatorService {
  isLandingPage: boolean;
  constructor(private dateService: DateService,
              private stateRuleModelService: StateRuleModelService,
              private appStoreService: AppStoreService) { }

  /**
   * Validates age of driver
   * @param value date entered
   * @returns error message
   */
  validateAge(value, isLandingPage?: boolean) {
    value = value ? value.replace(/_/g, '').trim() : null;
    if (value) {
      const valuesData = value.split('/');
      const today = new Date();
      if (value.length === 10 && (this.hasInvalidData(valuesData) || moment(value, 'MM/DD/YYYY').isAfter(today))) {
        return { inValidDate: true };

      } else if (!this.isValidDate(value) || value.length !== 10) {
        return { minLength: true };
      } else {
        if ( valuesData[2] < '1000') {
          return { ageLimit: true };
        }
        const age = this.dateService.getAge(value);
        let landingStateCode = null;
        this.appStoreService.getLandingStateCode$.subscribe(stateCode => {
          landingStateCode = stateCode;
        }).unsubscribe();
        const minAge = landingStateCode ? this.stateRuleModelService.isStateAvailable('MinAgeLicence', landingStateCode) :
          isLandingPage ? 15 : 0;
        const maxAge = landingStateCode ? this.stateRuleModelService.isStateAvailable('MaxAgeLicence', landingStateCode) : 99;
        if (!(age >= minAge && age < maxAge)) {
         return { ageLimit: true };
        }
      }

      return null;
    }
  }

  /**
   * Validates date using min max values
   * @param value entered date
   * @returns error message
   */
  validateDateUsingMinMaxValues(value, minDateString, maxDateString) {
    value = value ? value.replace(/_/g, '').trim() : null;
    if (value) {
      const valuesData = value.split('/');
      const minDate = moment(new Date(minDateString), 'MM/DD/YYYY').startOf('day');
      const maxDate = moment(new Date(maxDateString), 'MM/DD/YYYY').startOf('day');
      if (this.hasInvalidData(valuesData)) {
        return { inValidDate: true };

      } else if (!this.isValidDate(value) || value.length !== 10) {
        return { minLength: true };
      } else {
        if (moment(value, 'MM/DD/YYYY').startOf('day').isBefore(minDate)) {
          return { matDatepickerMin: true };
        } else if (moment(value, 'MM/DD/YYYY').isAfter(maxDate)) {
          return { matDatepickerMax: true };
        }
      }
    }
    return null;
  }

  /**
   * Valids date
   * @param dateObject date entered by customer
   * @returns boolean
   */
  isValidDate(dateObject) {
    if (moment(dateObject, 'MM/DD/YYYY').isValid() && dateObject.length === 10) {
      return true;
    }
    return false;
  }

  /**
   * Determines whether the date contains invalid data
   * @param valuesData date value as array (month, date, year)
   * @returns boolean
   */
  hasInvalidData(valuesData: string[]) {
    if (parseInt(valuesData[0], 10) === 0 || parseInt(valuesData[0], 10) > 12 || parseInt(valuesData[1], 10) === 0 ||
      parseInt(valuesData[1], 10) > 31 || parseInt(valuesData[2], 10) === 0) {
      return true;
    }
    return false;
  }


  isDatesEqual(dateString1, dateString2) {
    dateString1 = this.dateService.transform(new Date(dateString1), 'MM/dd/yyyy');
    return dateString1 === dateString2;
  }
}
