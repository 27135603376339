<div id="inconvenience-page-container">
    <div id="inconvenience-content">
        <div id="inconvenience-header_ffq-logo" title="Farmers &reg;" aria-label="Graphical Farmers Insurance Logo"></div>
        <div  id="inconvenience_header-title">
            <h1>We aren't able to complete your online quote at this time.</h1>
        </div>
        <div *ngIf="isCustomMessageAvailable else noIsCustomMessageAvailable">
            <div *ngIf="quoteNumber else noQuoteNumber">
                <div id="inconvenience_body_message" x-ms-format-detection="none">{{displayMessageOne}} 
                    <span class="quote_number">#{{quoteNumber}}. </span>
                </div>
                <div id="inconvenience_body_message" x-ms-format-detection="none">{{displayMessageTwo}}</div>
            </div>
            <ng-template #noQuoteNumber>
                <div id="inconvenience_body_message_no_quote_number">We apologize for the inconvenience — and are working to fix the problem. 
                        Please try again later.</div>
            </ng-template>
        </div>
        <ng-template #noIsCustomMessageAvailable>
            <div *ngIf="quoteNumber else noQuoteNumber">
                <div id="inconvenience_body_message" x-ms-format-detection="none">We apologize for the inconvenience. Don’t worry, we have saved your quote 
                    <span class="quote_number">#{{quoteNumber}}. </span>
                    Please try again by retrieving your saved quote on <a class="farmers" href= "/">Farmers.com</a>.</div>
            </div>
            <ng-template #noQuoteNumber>
                <div id="inconvenience_body_message_no_quote_number">We apologize for the inconvenience — and are working to fix the problem. 
                        Please try again later.</div>
            </ng-template>
        </ng-template>

        <div id="need_help">
            <div id="inconvenience_body_noagent"> Need more help? </div>
            <div *ngIf=!restictAgentDetailsImpl id="inconvenience_body_call-custome">You can call customer service at</div>
            <span *ngIf=!restictAgentDetailsImpl tabindex="0" [attr.aria-label]="'Call to action, contact farmers representative '+ ariaLabelDesktop" class="inconvenience_desktop">
                <div id="inconvenience_body_farmers" x-ms-format-detection = "none" aria-hidden="true">
                    <a href={{tele}} tabindex="0" role="link">{{desktopNo}}</a>
                </div>
            </span>
            <span *ngIf=!restictAgentDetailsImpl [attr.aria-label]="ariaLabelMobile" class="inconvenience_mobile">
                <div id="inconvenience_body_farmers" x-ms-format-detection = "none" aria-hidden="true">
                    <a href={{teleMobile}} role="link">{{mobileNo}}</a>
                </div>
            </span>
        </div>

        <div *ngIf=!EEImpl id="agent_button">
            <button (keyup.enter)="findAnAgent()" (click)="findAnAgent()"  role= "presentation" tabindex= "0" aria-label="Let's find an agent button link" mat-button class="ffq-card-button-blue" data-GTM="FFQ_Auto_error_letsfindagent_button">
                <span class="find_agent_text">LET'S FIND AN AGENT</span> 
            </button>
        </div>  
    </div>
</div>
