<div class="wrapper">
    <div class="details-wrapper">
        <div class="header">This policy is issued by: Truck Insurance Exchange (An inter-insurance exchange sometimes
            referred to in the policy as the "Company":)
        </div>
        <br />
        <div class="left">Insured</div>
        <hr class="right policyline"><br />
        <div class="right">Policy Number</div>
        <br />
        <br />
        <p class=para-text>{{disclaimer1}}</p>
        <hr class="print-quote-line">
        <div class="stitle">Subscription Agreement</div>
        <br />
        <p class="disclaimer">{{disclaimer2}}</p>
        <br />
        <pre class="endLine endLine-size">Subscribed to this 
            <hr class="underline"> day of 
            <hr class="underline"> , 2 <hr class="underline"> , <hr class="underline"> a.m./p.m. X 
            <input type ="text" class= "signature-box" disabled>
        </pre>
        <div class="right signature">Signature (If applicant is a minor, parent or guardian must also sign)</div>
    </div>
</div>