import { Injectable } from '@angular/core';

import { AppStoreService } from '@ffq-app-store/app.store.service';
import { WindowRef, ICustomWindow } from '@ffq-app-auto/shared/services/window.ref';
import {
  SegmentCampaign, CampaignParams,
  SegmentContext, FacebookContextTraits,
  CustomerInfo
} from '@ffq-app-shared/model/common.data.model';
import {
  CommonProps, IdentifyTraits, QuoteStartedProps,
  CtaClickedProps, QuoteStepReachedProps,
  ErrorOccurredProps, QuoteModalOpenedProps,
  QuoteCompleteProps, CustomQuoteProps,
  QuoteRedirectedProps, QuoteBrightlinedProps,
  QuoteKnockedoutProps, QuoteFailedProps,
  DriverAddedProps, VehicleAddedProps,
  CheckoutStartedProps, ProductViewedProps, QuoteVariableAdjustedProps,
  RateSummaryStartedProps, OnlineBuyStatusDecidedProps, RedirectionParams,
  SessionTimedOutParams,
  QuoteInfoParams,
  AgentAssignmentProps
} from '@ffq-app-shared/model/segment.data.model';
import { ServiceConstants } from '@ffq-app-shared/constants/service.constants';

@Injectable({
  providedIn: 'root'
})
export class SegmentService {
  customWindow: ICustomWindow;
  commonSegmentProps = {} as CommonProps;
  segmentCampaign = {} as SegmentCampaign;
  context = {} as SegmentContext;
  facebookContextTraits = {} as FacebookContextTraits;
  campaignParams = {} as CampaignParams;
  customerInfo = {} as CustomerInfo;

  lastStepVisited: string;
  landingSrc: string;
  crossSellLobs: string;

  constructor(
    private windowRef: WindowRef,
    private appStoreService: AppStoreService
  ) {
    this.customWindow = this.windowRef.nativeWindow;
    /** subscription for add data zip */
    this.appStoreService.appDataZip$.subscribe(appDataZip => {
      this.commonSegmentProps.zip = appDataZip ? appDataZip.landingZipCode : '';
      this.commonSegmentProps.state = appDataZip ? appDataZip.landingStateCode : '';
      this.commonSegmentProps.quote_number = appDataZip ? appDataZip.quoteNumber : '';
      this.commonSegmentProps.lob = appDataZip ? appDataZip.quoteType : '';
    });
    /** subscription for landing source id */
    this.appStoreService.getLandingSourceFromSrc$.subscribe(src => {
      this.landingSrc = src;
      this.commonSegmentProps.source_id = src ? src : 'FC000000001';
      if (this.landingSrc && this.landingSrc.includes('PERAAAME')) {
        this.commonSegmentProps.lead_id = this.landingSrc.split('_')[1];
      }
    });
    /** subscription for partnerADPFFlow
     * US322358 : FFQ change to track ADPF VS NON ADPF
     */
    this.appStoreService.getPartnerADPFFlow$.subscribe(partnerADPFFlow => {
      if (partnerADPFFlow !== null) {
        this.commonSegmentProps.partner_adpf_flow = partnerADPFFlow;
      }
    });
    /** subscription for company code */
    this.appStoreService.getBWCompanyCode$.subscribe(isBWCompanyCode => {
      this.commonSegmentProps.company_code = isBWCompanyCode ? 'B' : 'F';
    });
    /** subscription for source indicator */
    this.appStoreService.sourceIndicatorData$.subscribe(sourceIndicator => {
      if (sourceIndicator) {
        this.commonSegmentProps.source_indicator = sourceIndicator;
      } else {
        if (this.landingSrc) {
          if (this.landingSrc.substring(0, 3) === 'AMP') {
            this.commonSegmentProps.source_indicator = 'AP';
          } else {
            this.commonSegmentProps.source_indicator = this.landingSrc.substring(0, 2);
          }
        } else {
          this.commonSegmentProps.source_indicator = this.commonSegmentProps.source_id.substring(0, 2);
        }
      }
    });
    /** subscription for flow variable */
    this.appStoreService.getRedirectionFlow$.subscribe(flow => {
      this.commonSegmentProps.flow_param = flow ? flow : 'FC';
    });
    /** subscription for cross sell lobs */
    this.appStoreService.getQuotedLob$.subscribe(lobs => {
      if (lobs !== null) {
        this.crossSellLobs = lobs;
      }
    });
    /** subscription for customer email and last name for test user check */
    this.appStoreService.getTestUserCheckProperties$.subscribe(customerInfo => {
      this.customerInfo = customerInfo;
    });
    /** subscription for last step visited */
    this.appStoreService.getLastVisitedStep$.subscribe(data => {
      this.lastStepVisited = data;
    });
    /** subscription for utm parameters */
    this.appStoreService.getUtmParameters$.subscribe(utmParams => {
      const params = [['source', 'utm_source'], ['content', 'utm_content'],
        ['name', 'utm_campaign'], ['medium', 'utm_medium'], ['term', 'utm_term']];
      params.forEach((param) => {
        if (utmParams[param[1]]) {
          this.segmentCampaign[param[0]] = utmParams[param[1]];
        }
      });
      this.context.campaign = this.segmentCampaign;
    });

    /** subscription for campaign parameters */
    this.appStoreService.getCampaignParameters$.subscribe(campaignParams => {
      const params = ['cid', 'clickkey', 'fbclid', 'gclid', 'gclsrc', 'mcid', 'promoid'];
      params.forEach((param) => {
        if (campaignParams[param]) {
          this.campaignParams[param] = campaignParams[param];
        }
      });
    });

    /** subscription for customer details for facebook traits */
    this.appStoreService.appData$.subscribe(appData => {
      if (appData && appData.customerData && appData.customerData.customer) {
        if (appData.customerData.customer.communication) {
          if (appData.customerData.customer.communication.emailAddress) {
            this.facebookContextTraits.email = appData.customerData.customer.communication.emailAddress;
          }
          if (appData.customerData.customer.communication.phoneNumber) {
            this.facebookContextTraits.phone = appData.customerData.customer.communication.phoneNumber;
          }
        }
        if (appData.customerData.customer.firstName) {
          this.facebookContextTraits.firstName = appData.customerData.customer.firstName;
        }
        if (appData.customerData.customer.lastName) {
          this.facebookContextTraits.lastName = appData.customerData.customer.lastName;
        }
      }
    });

    this.context.os = { name: getOs() };
  }

  identify(customerDetails: any): void {
    const isTestUser = (
      (this.customerInfo.lastName
        && ServiceConstants.TEST_LASTNAME_LIST.split(',').includes(this.customerInfo.lastName.toLowerCase())) ||
      (this.customerInfo.email
        && ServiceConstants.TEST_EMAIL_LIST.split(',').includes(this.customerInfo.email.toLowerCase()))
      ) ? 'Yes' : 'No';
    const traits: IdentifyTraits = {
      ...customerDetails,
      is_test_user: isTestUser
    };
    this.customWindow.analytics.identify(traits, this.context);
  }

  trackCtaClicked(action: string, quoteInfo?: QuoteInfoParams): void {
    const properties: CtaClickedProps = {
      ...this.commonSegmentProps,
      ...quoteInfo,
      action,
      app_name: 'FFQ'
    };
    // in case of digital monitization , quote number will not be generated, so updating as NA
    properties.quote_number = properties.quote_number? properties.quote_number: 'NA';
    this.customWindow.analytics.track('Cta Clicked', properties, this.context);
  }

 trackErrorOccurred(errorDetails: object): void {
    const properties: ErrorOccurredProps = {
      ...this.commonSegmentProps,
      ...errorDetails
    };
    this.customWindow.analytics.track('Error Occurred', properties, this.context);
  }

  /** F39660 : CSS to FFQ Cross sell changes */
  callSegmentForMDMRetrievalInitiated(requesteObj: object): void {
    const properties = {
      ...this.commonSegmentProps,
      ...requesteObj
    };
    this.customWindow.analytics.track('MDM Retrieval Initiated', properties, this.context);
  }

  callSegmentForMDMErrors(failureDetails: object): void {
    const properties: QuoteFailedProps = {
      ...this.commonSegmentProps,
      ...failureDetails
    };
    this.customWindow.analytics.track('MDM ECN Failed', properties, this.context);
  }

  trackHelpModalOpened(modalDetails: object): void {
    const properties: QuoteModalOpenedProps = {
      ...this.commonSegmentProps,
      ...modalDetails,
    };
    this.customWindow.analytics.track('Help Modal Opened', properties, this.context);
  }

  trackQuoteStarted(): void {
    const properties: QuoteStartedProps = {
      ...this.commonSegmentProps,
      ...this.campaignParams,
      isAWSflow: (this.landingSrc && (this.landingSrc.startsWith('AP') || this.landingSrc.startsWith('AS'))) ? true : false,
      app_name: 'FFQ',
      existing_lobs: this.crossSellLobs
    };
    this.customWindow.analytics.track('Quote Started', properties, this.context);

    this.customWindow.analytics.identify({
      ...(this.commonSegmentProps.source_id) && { source_id: this.commonSegmentProps.source_id },
      ...(this.commonSegmentProps.lead_id) && { lead_id: this.commonSegmentProps.lead_id },
      ...(this.commonSegmentProps.quote_number) && { quote_number: this.commonSegmentProps.quote_number },
      ...(this.campaignParams.gclid) && { gclid: this.campaignParams.gclid }
    });
  }

  trackQuoteStepReached(step: string, flowType?: string): void {
    const properties: QuoteStepReachedProps = {
      ...this.commonSegmentProps,
      flow_type: flowType,
      step,
    };
    this.customWindow.analytics.track('Quote Step Reached', properties, this.context);
  }

  trackAssignedAgent(step: string, agentData: any): void {
    const properties: AgentAssignmentProps = {
      ...this.commonSegmentProps,
      step,
      agent_id: agentData?.agentId,
      agency_type: agentData?.agencyType,
      initial_agent: agentData?.initialAgent,
      assignment_type: agentData?.assignmentType,
      assigned_type: agentData?.assignedType
    };
    this.customWindow.analytics.track('Agent Assigned', properties, this.context);
  }

  trackQuoteComplete(quoteDetails: object): void {
    const properties: QuoteCompleteProps = {
      ...this.commonSegmentProps,
      ...this.campaignParams,
      ...quoteDetails
    };
    this.context.traits = this.facebookContextTraits;
    this.customWindow.analytics.track('Quote Complete', properties, this.context);
  }

  trackQuoteRedirected(redirectionDetails: object): void {
    const properties: QuoteRedirectedProps = {
      ...this.commonSegmentProps,
      ...redirectionDetails
    };
    this.customWindow.analytics.track('Quote Redirected', properties, this.context);
  }

  trackQuoteBrightlined(brightlineDetails: object): void {
    const properties: QuoteBrightlinedProps = {
      ...this.commonSegmentProps,
      ...brightlineDetails
    };
    properties.quote_number = properties.quote_number? properties.quote_number: 'NA';
    this.customWindow.analytics.track('Quote Brightlined', properties, this.context);
  }

  trackCIPopup(): void {
    const properties: QuoteBrightlinedProps = {
      ...this.commonSegmentProps
    };
    this.customWindow.analytics?.track('CI Popup shown', properties, this.context);
  }

  trackFGIAPopup(): void {
    const properties: QuoteBrightlinedProps = {
      ...this.commonSegmentProps
    };
    this.customWindow.analytics.track('FGIA Popup shown', properties, this.context);
  }

  trackQuoteKnockedout(knockoutDetails: object): void {
    const properties: QuoteKnockedoutProps = {
      ...this.commonSegmentProps,
      ...knockoutDetails
    };

    if (this.commonSegmentProps.company_code === 'B') {
      /** US527678 : FFQ Auto - KO due to Assigned agent during Brightline */
      if (properties.knockout_code !== 'KNOCKOUT_AGENT' && properties.knockout_code !== 'EK32') {
        properties.knockout_code = 'KNOCKOUT_AGENT_WITH_ERRORS';
      }
    }
    this.customWindow.analytics.track('Quote Knockedout', properties, this.context);
  }

  trackQuoteFailed(failureDetails: object): void {
    const properties: QuoteFailedProps = {
      ...this.commonSegmentProps,
      ...failureDetails
    };
    this.customWindow.analytics.track('Quote Failed', properties, this.context);
  }

  trackQuoteFailedOnlineBlocked(failureDetails: object): void {
    const properties: QuoteFailedProps = {
      ...failureDetails
    };
    this.customWindow.analytics.track('Quote Failed', properties, this.context);
  }

  trackQuoteVariableAdjusted(adjustedDetails: object): void {
    const properties: QuoteVariableAdjustedProps = {
      ...this.commonSegmentProps,
      ...adjustedDetails,
    };
    this.customWindow.analytics.track('Quote Variable Adjusted', properties, this.context);
  }

  trackCustomQuote(quoteDetails: object): void {
    const properties: CustomQuoteProps = {
      ...this.commonSegmentProps,
      ...quoteDetails
    };
    this.customWindow.analytics.track('Custom Quote', properties, this.context);
  }

  trackRateSummaryStarted(rateDetails: object): void {
    const properties: RateSummaryStartedProps = {
      ...this.commonSegmentProps,
      ...rateDetails
    };
    this.customWindow.analytics.track('Rate Summary Started', properties, this.context);
  }

  trackProductViewed(productViewedDetails: ProductViewedProps): void {
    const properties: ProductViewedProps = {
      ...this.commonSegmentProps,
      ...productViewedDetails
    };
    this.context.traits = this.facebookContextTraits;
    this.customWindow.analytics.track('Product Viewed', properties, this.context);
  }

  trackCheckoutStarted(productDetails: object): void {
    const properties: CheckoutStartedProps = {
      ...this.commonSegmentProps,
      ...productDetails
    };
    this.context.traits = this.facebookContextTraits;
    this.customWindow.analytics.track('Checkout Started', properties, this.context);
  }

  trackQuoteModalOpened(modalDetails: QuoteModalOpenedProps): void {
    const properties: QuoteModalOpenedProps = {
      ...this.commonSegmentProps,
      ...modalDetails
    };
    this.customWindow.analytics.track('Quote Modal Opened', properties, this.context);
  }

  trackDriverAdded(driverDetails: DriverAddedProps): void {
    const properties: DriverAddedProps = {
      ...this.commonSegmentProps,
      ...driverDetails
    };
    this.customWindow.analytics.track('Driver Added', properties, this.context);
  }

  quoteVariableAdjusted(adjustedDetails: object): void {
    const properties: QuoteVariableAdjustedProps = {
      ...this.commonSegmentProps,
      ...adjustedDetails
    };
    this.customWindow.analytics.track('Quote Variable Adjusted', properties, this.context);
  }

  vehicleAdded(vehicleData: object): void {
    const properties: VehicleAddedProps = {
      ...this.commonSegmentProps,
      ...vehicleData
    };
    this.customWindow.analytics.track('Vehicle Added', properties, this.context);
  }

  buyStatusDecided(requesteData: object): void {
    const properties: OnlineBuyStatusDecidedProps = {
      ...this.commonSegmentProps,
      ...requesteData
    };
    this.customWindow.analytics.track('Online Buy Status Decided', properties, this.context);
  }
  // US349514: Segment Changes for Get a Quote and Retrieve Quote
  quoteStartInitiated(requesteObj: object): void {
    const properties = {
      ...this.commonSegmentProps,
      ...requesteObj
    };
    this.customWindow.analytics.track('Quote Start Initiated', properties, this.context);
  }
  // US349514: Segment Changes for Get a Quote and Retrieve Quote
  quoteRetrievalInitiated(requesteObj: object): void {
    const properties = {
      ...this.commonSegmentProps,
      ...requesteObj
    };
    this.customWindow.analytics.track('Quote Retrieval Initiated', properties, this.context);
  }

  trackQuoteRouted(redirectionParams?: RedirectionParams): void {
    const properties = {
      ...this.commonSegmentProps,
      ...this.campaignParams,
      ...redirectionParams
    };
    this.customWindow.analytics.track('Quote Routed', properties, this.context);
  }

  trackSessionTimedOut(): void {
    const properties: SessionTimedOutParams = {
      ...this.commonSegmentProps,
      step: this.lastStepVisited
    };
    this.customWindow.analytics.track('Session Timed Out', properties, this.context);
  }
}

function getOs(): string {
  const userAgent = navigator.userAgent;
  const isMobile = ('ontouchstart' in document.documentElement && /mobi/i.test(navigator.userAgent));

  const browserName = ((agent) => {
    switch (true) {
      case agent.indexOf('edge') > -1: return 'Edge';
      case agent.indexOf('edg/') > -1: return 'Edge (Chromium Based)';
      case agent.indexOf('opr') > -1: return 'Opera';
      case agent.indexOf('chrome') > -1: return 'Chrome';
      case agent.indexOf('trident') > -1: return 'Internet Explorer';
      case agent.indexOf('firefox') > -1: return 'Firefox';
      case agent.indexOf('safari') > -1: return 'Safari';
      default: return 'Other';
    }
  })(userAgent.toLowerCase());

  let osName = '';
  if (isMobile) {
    if (/android/i.test(userAgent)) {
      if (browserName === 'Chrome') {
        osName = 'Android Mobile';
      } else {
        osName = 'Android ' + browserName;
      }
    }
    if (/iPad|iPhone|iPod/i.test(userAgent)) {
      if (browserName === 'Safari') {
        osName = 'iOS Mobile';
      } else {
        osName = 'iOS ' + browserName;
      }
    }
  } else {
    osName = browserName;
  }
  return osName;
}
