<mat-form-field [formGroup]="params.formGroup" class="full-width-field form-elements" appearance="fill">
    <mat-label *ngIf="params.placeholder">{{ params.placeholder }}</mat-label>
    <input [attr.aria-label]='params.ariaLabel' type="text" matInput class="auto-complete-lib" [formControl]="myControl" [matAutocomplete]="auto" formControlName="{{params.formControllName}}" />
    <mat-autocomplete  #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onSelectionChanged($event)">
      <mat-option *ngFor="let option of filteredOptions |  async" [value]="option.value" [attr.data-GTM]="option.gtmTag">
        {{option.label}}
      </mat-option>
    </mat-autocomplete>
    
    <mat-hint *ngIf="params.assistiveText" align="start"><strong>{{params.assistiveText}}</strong> </mat-hint>
    <mat-error>
      <ng-container *ngIf="params.messages">
        <ng-container *ngFor="let key of Object.keys(params.messages)">
          <ng-container *ngIf="((params.formGroup).get(params.formControllName)).hasError(key)">
            {{ params.messages[key] }}</ng-container>
        </ng-container>
      </ng-container>
    </mat-error>
    <lib-form-field-loader *ngIf="params.showLoader"></lib-form-field-loader>
  </mat-form-field>