<mat-form-field [formGroup]="params.formGroup"
  class="date-picker-field full-width-field form-elements " appearance="fill">
  <mat-label aria-hidden="true">{{ (params.placeholder) ? params.placeholder : null }}</mat-label>
  <input [attr.aria-label]="params.ariaLabel" (paste)="eventHandlerKeyUp($event)" (input)="inputDate($event.target.value)" (blur)="monthYearDateFormat($event.target.value)" matInput [readonly]="params.readonly" placeholder="" [textMask]="mask"
  (focusout)="focusOutFunction($event)" [formControl]="params.formGroup.controls[params.formControllName]" minlength=7 attr.data-GTM="{{params.gtmTag}}" [type]="params.type === 'tel'? params.type:''" />
    <input   hidden [matDatepicker]="dp" [min]="params.minDate" [max]="params.maxDate" [value]="dateEntered"/>
  <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
  <mat-datepicker #dp startView="multi-year" (yearSelected)="chosenYearHandler($event)"
    (monthSelected)="chosenMonthHandler($event, dp)" panelClass="year-month-picker" [startAt]="params.startDate" [touchUi]="!isLargeScreen" [calendarHeaderComponent]="customeDatePickerHeader">
  </mat-datepicker>
  <mat-hint>{{params.assistiveText}}</mat-hint>
  <mat-error aria-hidden="true">
    <ng-container *ngIf="params.messages">
      <ng-container *ngFor="let key of Object.keys(params.messages)">
        <ng-container *ngIf="((params.formGroup).get(params.formControllName)).hasError(key)">
          {{ params.messages[key] }}</ng-container>
      </ng-container>
    </ng-container>
  </mat-error>
</mat-form-field>