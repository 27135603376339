import { Injectable } from '@angular/core';
import { DataLayerRequestForContentChange } from '@ffq-app-shared/model/common.data.model';

@Injectable({
  providedIn: 'root'
})
export class DataLayerService {


  /**
   * Data layer request of data layer service
   */
  public dataLayerRequest = {} as DataLayerRequestForContentChange;

  /**
   * Creates an instance of data layer service.
   */
  constructor() {
    this.dataLayerRequest.QuoteNumber = '';
    this.dataLayerRequest.QuoteType = '';
    this.dataLayerRequest.StateCode = '';
    this.dataLayerRequest.ZipCode = '';
    this.dataLayerRequest.LOB = '';
    this.dataLayerRequest.LivingStatus = '';
    this.dataLayerRequest.BundleInsurance = '';
    this.dataLayerRequest.SourceId = '';
    this.dataLayerRequest.VisitorId = '';
    this.dataLayerRequest.Industry = '';
    this.dataLayerRequest.Event = '';
    this.dataLayerRequest.Page = '';
    this.dataLayerRequest.CurrentTerm = '';
    this.dataLayerRequest.QuoteBtn = '';

    /** US203892: Custom Dimension for Signal Discounts */
    this.dataLayerRequest.signalApplied = '';
    this.dataLayerRequest.nosignal = '';

    this.dataLayerRequest.event = 'setDataLayer';
    this.pushToDataLayer();
  }

  /**
   * Sets data layer quote number
   * @param data data
   */
  setDataLayerQuoteNumber(data: any) {
    this.dataLayerRequest.QuoteNumber = data;
    this.pushToDataLayer();
    if (window[`FS`]) {
      window[`FS`].setUserVars({QuoteNumber : data});
    }
  }

  /**
   * Sets data layer state code
   * @param data data
   */
  setDataLayerStateCode(data: any) {
    this.dataLayerRequest.StateCode = data;
    this.pushToDataLayer();
  }
  /**
   * Sets data layer zip code
   * @param data data
   */
  setDataLayerZipCode(data: any) {
    this.dataLayerRequest.ZipCode = data;
    this.pushToDataLayer();
  }
  /**
   * Sets data layer lob
   * @param data data
   */
  setDataLayerLOB(data: any) {
    this.dataLayerRequest.LOB = data;
    this.pushToDataLayer();
  }
  /**
   * Sets data layer living status
   * @param data data
   */
  setDataLayerLivingStatus(data: any) {
    this.dataLayerRequest.LivingStatus = data;
    this.pushToDataLayer();
  }
  /**
   * Sets data layer bundle insurance
   * @param data data
   */
  setDataLayerBundleInsurance(data: any) {
    this.dataLayerRequest.BundleInsurance = data;
    this.pushToDataLayer();
  }
  /**
   * Sets data layer source id
   * @param data data
   */
  setDataLayerSourceId(data: any) {
    this.dataLayerRequest.SourceId = data;
    this.pushToDataLayer();
  }
  /**
   * Sets data layer visitor id
   * @param data data
   */
  setDataLayerVisitorId(data: any) {
    this.dataLayerRequest.VisitorId = data;
    this.pushToDataLayer();
  }
  /**
   * Sets data layer industry
   * @param data data
   */
  setDataLayerIndustry(data: any) {
    this.dataLayerRequest.Industry = data;
    this.pushToDataLayer();
  }
  /**
   * Sets data layer event
   * @param data data
   */
  setDataLayerEvent(data: any) {
    this.dataLayerRequest.Event = data;
    this.pushToDataLayer();
  }
  /**
   * Sets data layer page
   * @param data data
   */
  setDataLayerPage(data: any) {
    this.dataLayerRequest.Page = data;
    this.pushToDataLayer();
  }
  /**
   * Sets data layer current term
   * @param data data
   */
  setDataLayerCurrentTerm(data: any) {
    this.dataLayerRequest.CurrentTerm = data;
    this.pushToDataLayer();
  }

  /**
   * Sets data layer Quote Type
   * @param data data
   */
  setDataLayerQuoteType(data: any) {
    this.dataLayerRequest.QuoteType = data;
    this.pushToDataLayer();
  }

  /**
   * Sets data layer Quote button Type
   * @param data data
   */
  setDataLayerQuoteButtonType(data: any) {
    this.dataLayerRequest.QuoteBtn = data;
    this.pushToDataLayer();
  }

  /**
   * Sets data layer signal applied
   * @param data data
   */
  setDataLayerSignalApplied(data: string): void {
    this.dataLayerRequest.signalApplied = data;
    this.pushToDataLayer();
  }

  /**
   * Sets data layer nosignal
   * @param data data
   */
  setDataLayerNoSignal(data: string): void {
    this.dataLayerRequest.nosignal = data;
    this.pushToDataLayer();
  }

  /**
   * Pushs to data layer
   */
  pushToDataLayer() {
    if (window[`dataLayer`]) {
      window[`dataLayer`].push(
        this.dataLayerRequest
      );

    }
  }
}
