import * as fromAppState from './app.state';

/* Selectors */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ServiceCallStatus, FlowParamter } from '@ffq-app-shared/enums/flow.identifier.enum';
import { DataConstants } from '@ffq-app-shared/constants/data.constants';

export const getAppSelector = createFeatureSelector<fromAppState.AppState>(
  'appState'
);

export const getAppData = createSelector(
  getAppSelector,
  state => state.data,
);

export const getAppControlData = createSelector(
  getAppSelector,
  state => state && state.controlData ? state.controlData : null,
);

export const getAppDataAgent = createSelector(
  getAppSelector,
  state => {
    return {
      agent: state.data ? state.data.agentData : null
    };
  },
);

export const getMDKnockOutIndicator = createSelector(
  getAppSelector,
  state => {
    return state && state.data && state.data.mdKnockoutInd ? state.data.mdKnockoutInd : null;
  },
);


// export const getAppVinLookUp = createSelector(
//   getAppSelector,
//   state => {
//     return {
//       landingStateCode: state.data.landingStateCode,
//       landingZipCode: state.data.landingZipCode,
//       quoteNumber: state.data.quoteNumber,
//       _windowID: state.data._windowID
//     };
//   },
// );

export const getAppControlValues = createSelector(
  getAppSelector,
  state => {
    if (state && state.data) {
    return {
      // tslint:disable-next-line:no-unused-expression
      landingStateCode: state.data.landingStateCode,
      landingZipCode: state.data.landingZipCode,
      quoteNumber: state.data.quoteNumber,
      quoteType: state.data.quoteType,
      lob: state.data.lob,
      projectCodeStatus: state.data.projectCodeStatus,
      isRc1Eligible: state.data.isRc1Eligible,
      src:  state.data.src,
      sourceIndicator:  state.data.sourceIndicator,
      redirectionParams: state.data.redirectionParams,
      isHqmEnabled: state.data.isHqmEnabled,
      knockoutIndicator: state.data.knockoutIndicator,
      isOneUIEnabled: state.data.isOneUIEnabled
    };
    } else {
    return '';
    }
  },
);


export const getAppTokenInfo = createSelector(
  getAppSelector,
  state => {
    return {
      tokenInfo: state.data ? state.data.tokenInfo : null,
      _windowID: state.data ? state.data._windowID : null,
    };
  },
);

export const getAppError = createSelector(
  getAppSelector,
  state => state.error,
);

/**
 * returns the customer data from store
 */
export const getCustomerData = createSelector(
  getAppSelector,
  state => {
    return state.data.customerData ? state.data.customerData : null;
  }
);

/**
 * returns the spouse customer data from store
 */
export const getSpouseCustomerData = createSelector(
  getAppSelector,
  state => {
    return state.data.spouseDriver ? state.data.spouseDriver : null;
  }
);

/**
 * returns the customer zip city data from store
 */
export const getCustomerCityZipData = createSelector(
  getAppSelector,
  state => {
    return state.data.zipCityList ? state.data.zipCityList : null;
  }
);
/**
 * returns the customer zip town data from store
 */
export const getCustomerTownZipData = createSelector(
  getAppSelector,
  state => {
    return state.data.zipTownList ? state.data.zipTownList : null;
  }
);

// For Population of Your Info module data.
export const getAppCustomerData = createSelector(
  getCustomerData,
  (customerData) => {
    const custData = customerData;
    return {
      firstName: (custData && custData.customer) ? custData.customer.firstName : '',
      lastName: (custData && custData.customer) ? custData.customer.lastName : '',
      dateOfBirth: (custData && custData.customer) ? custData.customer?.dateOfBirth : '',
      gender: (custData && custData.customer) ? custData.customer?.gender : '',
      residentialAddress: (custData && custData.customer) ? custData.customer?.address?.street : '',
      apartment: (custData && custData.customer) ? custData.customer?.address?.apartment : '',
      city: (custData && custData.customer) ? custData.customer?.address?.city : '',
      state: (custData && custData.customer) ? custData.customer?.address?.state : '',
      town: (custData && custData.customer) ? custData.customer?.address?.town : '',
      zip: (custData && custData.customer) ? custData.customer?.address?.zip : '',
      // TODO - Current Insured Checkbox to be done - Once State data defined properly this can be done.
    };
  },
);

// US376312: Setting communication data
export const getCommunicationData = createSelector(
  getCustomerData,
  (customerData) => {
    const custData = customerData;
    return {
      communication: (custData && custData.customer) ? custData.customer.communication : '',
    };
  },
);

/**
 * Selector for ProjectCodeStatus
 */
export const getProjectCodeStatus = createSelector(
  getAppSelector,
  state => {
    if (state && state.data) {
    return state.data.projectCodeStatus;
    }
  },
);

/** Selector to get the status of the Submit YourInfo */
export const getSubmitYourInfoStatusHome = createSelector(
  getAppControlData,
  (controlData) => {
    const serviceStatus = {
      submitYourInfo: controlData && controlData.submitYourInfo ? controlData.submitYourInfo : null,
      assignAgent: controlData && controlData.assignAgent ? controlData.assignAgent : null,
    };
    return serviceStatus;
  },
);

/** Selector for Redirection Flow */
export const getRedirectionFlow = createSelector(
  getAppSelector,
  state => state && state.controlData && state.controlData.redirectionFlow ?
    state.controlData.redirectionFlow : null

);

/** Selector for Redirection Flow */
export const getFwsToFFQRedirect = createSelector(
  getCustomerData,
  customerData => customerData && customerData.employer && customerData.employer.fwsToFFQRedirect ?
  customerData.employer.fwsToFFQRedirect : null

);

/** Selector for encrypted data foe rate summary pi flow */
export const getEncryptedData = createSelector(
  getAppSelector,
  state => state && state.controlData && state.controlData.rateSummaryParam ?
    state.controlData.rateSummaryParam : null

);

/** Selector for Redirection Flow */
export const getIsBWQuote = createSelector(
  getAppSelector,
  state => state && state.controlData && state.controlData.isBWQuote ?
    state.controlData.isBWQuote : null

);

/** Selector for Redirection Flow */
export const getCrossSellStatus = createSelector(
  getAppSelector,
  state => state && state.controlData && state.controlData.crossSellStatus ?
    state.controlData.crossSellStatus : null
);

/** Selector for CrossSellEncryptData Flow */
export const getCrossSellEncryptData = createSelector(
  getAppSelector,
  state => {
    return state.data.crossSellEncryptedData ? state.data.crossSellEncryptedData.encryptedData : null;
  },
);

/** Selector for Custom Quote Back door flag */
export const getBackDoorCustomFlag = createSelector(
  getAppSelector,
  state => {
    return {
      isCustomQuoteImplBackDoor: state && state.controlData && state.controlData.isCustomQuoteImplBackDoor !== undefined ?
      state.controlData.isCustomQuoteImplBackDoor : null,
      isBWCustomQuoteImplBackDoor: state && state.controlData && state.controlData.isBWCustomQuoteImplBackDoor !== undefined ?
      state.controlData.isBWCustomQuoteImplBackDoor : null
    };
  });

/** Selector for Custom Quote Back door flag */
export const getRefreshFlag = createSelector(
  getAppSelector,
  state => state && state.controlData && state.controlData.isRouterRefreshed ?
    state.controlData.isRouterRefreshed : false
);

export const getRefreshFlagBusiness = createSelector(
  getAppSelector,
  state => state && state.controlData && state.controlData.isRefreshForBusiness ?
    state.controlData.isRefreshForBusiness : null
);


/** Selector for Additional Page */
export const getAdditionalPageStatus = createSelector(
  getAppSelector,
  state => state && state.controlData && state.controlData.additionalPageStatus ?
    state.controlData.additionalPageStatus : null
);


/** selector for Cross Sell Status & Redirection Flow paramter */
export const getIsHomeInfoDisabled = createSelector(
  getRedirectionFlow,
  getCrossSellStatus,
  ( redirectionFlow, crossSellStatus) => {
    return {
      crossSellStatus,
      redirectionFlow,
    };
  },
);


/** Selector to get the landing state code */
export const getLandingStateCode = createSelector(
  getAppSelector,
  state => {
    return state  && state.data ? state.data.landingStateCode : null;
  }
);

/** Selector to get the landing zip code */
export const getLandingZipCode = createSelector(
  getAppSelector,
  state => {
    return (state  && state.data) ?  state.data.landingZipCode : '';
  }
);

export const getPhoneNumberFromSrc = createSelector(
  getAppSelector,
  state => {
    if (state && state.data) {
      return state.data.sourcePhoneNumber ? state.data.sourcePhoneNumber : null;
    }
  }
);
export const getLandingSourceFromSrc = createSelector(
  getAppSelector,
  state => {
    if (state && state.data) {
      return state.data.src ? state.data.src : null;
    }
  }
);
/** Selector to get the validate zip service response */
export const getValidateZip = createSelector(
  getAppSelector,
  state => {
    return state.data.validateZip ? state.data.validateZip : null;
  });

/** Selector to lob of app state */
export const getLob = createSelector(
  getAppSelector,
  state => state && state.data && state.data.lob ? state.data.lob : null
);

/** Selector to quotedLob of app state */
export const getQuotedLob = createSelector(
  getAppSelector,
  state => state && state.data && state.data.quotedLobs ? state.data.quotedLobs : null,
);

/** Selector to quote number of app state */
export const getQuoteNumber = createSelector(
  getAppSelector,
  state => (state && state.data) ? state.data.quoteNumber : ''
);

/* Selector toget Quote Type */
export const getQuoteType = createSelector(
  getAppSelector,
  state => (state && state.data) ? state.data.quoteType : ''
);

/** selector for landing state code, zip code and quoteNumber */
export const getAppDataZip = createSelector(
  getLandingStateCode,
  getLandingZipCode,
  getQuoteNumber,
  getQuoteType,
  (landingStateCode, landingZipCode, quoteNumber, quoteType) => {
    return {
      landingStateCode,
      landingZipCode,
      quoteNumber,
      quoteType
    };
  },
);

/** Selector to get the navigation Data  */
export const getNavigationData = createSelector(
  getAppSelector,
  state => {
    return (state && state.navigationData) ? state.navigationData : null;
  }
);

/** Selctor to get the navigation data of current module */
export const getNavDataOfCurrentModule = createSelector(
  getNavigationData,
  getLob,
  (navigationData, lob, param) => {
    return navigationData[lob] ? navigationData[lob][param.moduleName] : null;
  }
);

/** Selector to get the landing state description */
export const getLandingStateDescription = createSelector(
  getAppSelector,
  state => {
    return state.data.landingStateDescription;
  }
);

/**
 * returns the SaveAndFinishLater data from store
 */
export const getSaveAndFinishLaterData = createSelector(
  getAppSelector,
  getAppControlData,
  state => {
    return state.controlData.saveAndFinishLater ? state.controlData.saveAndFinishLater : null;
  }
);

/** Selector to get the retrieve data status  */
export const getRetrieveDataStatus = createSelector(
  getAppSelector,
  state => state.retrieveDataStatus,
);
/** Selector to get the validate email service response */
export const getValidateEmail = createSelector(
  getAppSelector,
  state => {
    return state.data.validateEmail ? state.data.validateEmail : null;
  });
/** Selector to get the form edited status */
export const getFormEditedStatus = createSelector(
  getAppSelector,
  state => {
    return state && state.controlData && state.controlData.formEdited ? state.controlData.formEdited : false;
  });

/** Selector for existing lob */
export const getExistingLobs = createSelector(
  getAppSelector,
  state => {
    return state && state.data && state.data.customerData && state.data.customerData.existingLobs ?
      state.data.customerData.existingLobs : null;
  });

/** Selector for existing customers */
export const getExistingCustomer = createSelector(
  getAppSelector,
  state => {
    return state && state.data && state.data.customerData && state.data.customerData.isExistingCust ?
      state.data.customerData.isExistingCust : null;
  });

/** selector for existing customer check */
export const getExistingCustomerData = createSelector(
  getExistingLobs,
  getExistingCustomer,
  (existingLobDate, custData) => {
     return  {
      isExistingCust: custData,
      existingLobs: existingLobDate
     };
  }
);


/** selector for defaultRateQuoteCalled */
export const getDefaultRateQuoteCalled = createSelector(
  getAppSelector,
  state => {
    return state && state.controlData && state.controlData.defaultRateQuoteCalled ?
      state.controlData.defaultRateQuoteCalled : fromAppState.initialState.controlData.defaultRateQuoteCalled;
  }
);


/** Selector to quote number of app state */
export const getPniOccupation = createSelector(
  getAppSelector,
  state => state.data.pniOccupation
);
/** Selector to quote number of app state */
export const getPniMaritalStatus = createSelector(
  getAppSelector,
  state => state.data.pniMaritalStatus
);

// For Getting PNI Information in Cross Sell
export const getPniInformation = createSelector(
  getPniOccupation,
  getPniMaritalStatus,
  (pniOccupation, pniMaritalStatus) => {
    return {
      pniOccupation,
      pniMaritalStatus
    };
  },
);

// For Getting Spouse Occupation in Cross Sell
export const getSpouseOccupation = createSelector(
  getAppSelector,
  state => {
    return state.data.spouseOccupation;
  }
);

/** Selector to get the retrieve data status  */
export const getFlowDataForQuote = createSelector(
  getAppSelector,
  state => state && state.controlData &&  state.controlData.flowDataForQuote ?
  state.controlData.flowDataForQuote : null
);

/** Selector to get the business hour  */
export const isBusinessHour = createSelector(
  getAppSelector,
  state => state && state.data &&  state.data.businessHourResponse ?
  state.data.businessHourResponse : null
);

/** Selector to get the chat status  */
export const chatStatus = createSelector(
  getAppSelector,
  state => state && state.data &&  state.data.isChatActive ?
  state.data.isChatActive : null
);

/** Selector to get the chat status  */
export const getLastVisitedPage = createSelector(
  getAppSelector,
  state => state && state.controlData &&  state.controlData.lastVisitedPage ?
  state.controlData.lastVisitedPage : null
);

/** Selector to get the chat status  */
export const getCurrentPageForSB = createSelector(
  getAppSelector,
  state => state && state.controlData &&  state.controlData.currentPageForSB ?
  state.controlData.currentPageForSB : null
);

export const getSignalDiscountSelected = createSelector(
  getAppSelector,
  state => state && state.controlData &&  state.controlData.signalDiscountSelected ?
  state.controlData.signalDiscountSelected : null
);

export const getSignalDiscountClicked = createSelector(
  getAppSelector,
  state => state && state.controlData &&  state.controlData.signalDiscountClicked ?
  state.controlData.signalDiscountClicked : null
);

export const getIsCoveragesUpdated = createSelector(
  getAppSelector,
  state => state && state.controlData &&  state.controlData.isCoveragesUpdated ?
  state.controlData.isCoveragesUpdated : null
);


export const getChangeZipStatus = createSelector(
  getAppSelector,
  state => state && state.controlData &&  state.controlData.changeZipCalled ?
  state.controlData.changeZipCalled : null
);

export const getUpdatedCityZipData = createSelector(
  getAppSelector,
  state => state && state.controlData &&  state.controlData.cityZipData ?
  state.controlData.cityZipData : null
);

export const getUpdatedZipTownList = createSelector(
  getAppSelector,
  state => state && state.controlData &&  state.controlData.zipTownList ?
  state.controlData.zipTownList : null
);

export const getSourceIndicator = createSelector(
  getAppSelector,
  state => state && state.controlData &&  state.controlData.quoteSourceIndicator ?
  state.controlData.quoteSourceIndicator : null
);


/** Selector to get the chat status  */
export const getIsWithinBusinessHourStatus = createSelector(
  getAppSelector,
  state => state && state.controlData &&  state.controlData.agentBusinessHour ?
  state.controlData.agentBusinessHour : null
);

/** selector for landing state code, zip code and quoteNumber */
export const getLiveChatStatus = createSelector(
  getLastVisitedPage,
  getIsWithinBusinessHourStatus,
  getRedirectionFlow,
  getProjectCodeStatus,
  (lastVisitedPage, isWithinBusinessHourStatus, redirectionFlow, projectCodeStatus) => {
    return {
      lastVisitedPage,
      isWithinBusinessHourStatus,
      redirectionFlow,
      projectCodeStatus,
    };
  },
);
/** Selector to get the vehicle module edited status */
export const getVehicleModuleEditedStatus = createSelector(
  getAppSelector,
  state => {
    return state && state.controlData && state.controlData.vehicleChangedAfterQuote ? state.controlData.vehicleChangedAfterQuote : null;
  });
  /** Selector to get the driver module edited status */
export const getDriverModuleEditedStatus = createSelector(
  getAppSelector,
  state => {
    return state && state.controlData && state.controlData.driverChangedAfterQuote ? state.controlData.driverChangedAfterQuote : null;
  });
/** Selector to get the vehicle or Driver module edited status */
export const getVehicleorDriverModuleEditedStatus = createSelector(
  getAppSelector,
  state => {
    return state && state.controlData && state.controlData.vehicleOrDriverChanged;
  });
// Update when Policy start date changes affecting the Drivingxp
export const getPolicyStartDateChangedStatus = createSelector(
    getAppSelector,
    state => {
      return state && state.controlData && state.controlData.policyStartDateChanged ? state.controlData.policyStartDateChanged : null;
    });
/**
 * selector for package selected coverage
 */
export const getQuotePackageSelected = createSelector(
  getAppSelector,
  state => {
    return state && state.controlData && state.controlData.packageSelected ? state.controlData.packageSelected : null;
  }
);

/**
 * Selector for Retrieve quote service status
 */
export const getRetrieveQuoteStatus = createSelector(
  getAppSelector,
  state => {
    return state && state.controlData && state.controlData.retrieveQuote ? state.controlData.retrieveQuote : null;
  },
);

/**
 * Selector for Retrieve rate summary service status
 */
export const getRetrieveSummaryStatus = createSelector(
  getAppSelector,
  state => {
    return state && state.controlData && state.controlData.retrieveRateSummary ? state.controlData.retrieveRateSummary : null;
  },
);

/**
 * Selector for ProjectCode service status
 */
export const getProjectCodeServiceStatus = createSelector(
  getAppSelector,
  state => {
    return state && state.controlData && state.controlData.projectCodeServiceStatus ? state.controlData.projectCodeServiceStatus : null;
  },
);

/**
 * Function to select total vehicle count
 */
export const getTotalVehicleCount = createSelector(
  getAppSelector,
  state => {
    return state && state.controlData && state.controlData.totalVehicleCount;
  },
);

/**
 * Function to select total drivewr count
 */
export const getTotalDriverCount = createSelector(
  getAppSelector,
  state => {
    return state && state.controlData && state.controlData.totalDriverCount;
  },
);

/**
 * Function to get MDM Response
 */
export const getEncryptedMDMResponse = createSelector(
  getAppSelector,
  state => {
    return state && state.controlData && state.controlData.encryptedMDMResponse;
  },
);

// Selector for connected car status
export const getConnectedCarStatus = createSelector(
  getAppControlData,
  controlData => {
    return controlData.connectedCarIndicator ? true : false;
  }
);

/** selector to determine whether AEM static Content service call is needed */
export const isAEMContentCallNeeded = createSelector(
  getQuoteType,
  (quoteType) => {
    // const serviceCallRequired = ();
    return quoteType && quoteType === DataConstants.LOB.auto;
  });

/** Selector to get initAppRenderServices */
export const getStaticContentServiceStatus = createSelector(
  getAppSelector,
  isAEMContentCallNeeded,
  (state, serviceCallRequired) => {
    return !serviceCallRequired ? 'success' : (state && state.controlData ? state.controlData.staticContentServiceStatus : null);
  });

/**
 * Selector for identifying the completion of retrieve quote and project code service & the last visted page
 */
export const getRQControlData = createSelector(
  getRetrieveQuoteStatus,
  getProjectCodeServiceStatus,
  getLastVisitedPage,
  getStaticContentServiceStatus,
  getConnectedCarStatus,
  (retrieveQuote, projectCodeServiceStatus, lastVisitedPage, staticContentServiceStatus, connectedCarIndicator) => {
    return { retrieveQuote, projectCodeServiceStatus, lastVisitedPage, staticContentServiceStatus, connectedCarIndicator };
  },
);

/**
 * Selector for identifying the completion of retrieve rate summary and project code service & the last visted page
 */
export const getPIControlData = createSelector(
  getRetrieveSummaryStatus,
  getProjectCodeServiceStatus,
  getLastVisitedPage,
  getStaticContentServiceStatus,
  (retrieveRateSummary, projectCodeServiceStatus, lastVisitedPage, staticContentServiceStatus) => {
    return { retrieveRateSummary, projectCodeServiceStatus, lastVisitedPage, staticContentServiceStatus };
  },
);

/** selector for getting the value of bwPopUpSkipped */
export const getBwPopUpSkipped = createSelector(
  getAppSelector,
  state => {
    return state && state.controlData && state.controlData.bwPopUpSkipped;
  });

/** selector for getting the value of requiredAutoRentersLoader */
export const getRequiredAutoRentersLoader = createSelector(
  getAppSelector,
  state => {
    return state && state.controlData && state.controlData.requiredAutoRentersLoader;
  });

/** Selector to get initAppRenderServices */
export const getInitAppRenderServices = createSelector(
  getAppSelector,
  state => {
    return state.controlData.initAppRenderServices ? state.controlData.initAppRenderServices : ServiceCallStatus.INIT;
  });

export const getStaticContent = createSelector(
    getAppSelector,
    state => {
      return state && state.data && state.data.staticContents;
    },
  );
/** selector for getting Phone Number Service call status */
export const getPhoneNumberServiceStatus = createSelector(
  getAppSelector,
  state => {
    return state && state.controlData && state.controlData.PhoneNumberServiceStatus ?
     state.controlData.PhoneNumberServiceStatus : (
      (state.data && !state.data.src && state.data.quoteNumber) ? 'success' : null);
  });

/** Selector to get cvgUpdationRequired */
export const getCvgUpdationRequired = createSelector(
  getAppSelector,
  state => {
    return state && state.controlData && state.controlData.requiredCvgUpdation;
  });

export const getLmsRedirectData = createSelector(
  getAppSelector,
  state => {
    if (state && state.data) {
      return {
        isLmsRedirect: state.data.isRedirectionRequired,
        windowId: state.data.windowId
      };
    } else {
      return null;
    }
  });

/** Selector to get the last visited step for segment tracking  */
export const getLastVisitedStep = createSelector(
  getAppSelector,
  state => state && state.controlData &&  state.controlData.lastVisitedStep ?
  state.controlData.lastVisitedStep : null
);

/** Selector to get the segment event traking  */
export const getSegmentEvent = createSelector(
  getAppSelector,
  state => state && state.controlData &&  state.controlData.calledSegmentEvent ?
  state.controlData.calledSegmentEvent : null
);

/** Selector to get QuoteStartedEventStatus */
export const getQuoteStartedEventStatus = createSelector(
  getAppSelector,
  state => {
    return state && state.controlData && state.controlData.quoteStartedEventTriggered;
});
  /** Selector for PpcSrc value availability status */
export const getPpcSrcAvailabilityStatus = createSelector(
  getAppSelector,
  state => state && state.controlData && state.controlData.ppcSrcAvailable ?
    state.controlData.ppcSrcAvailable : null
);

/** subscription for partnerADPFFlow US322358 : FFQ change to track ADPF VS NON ADPF */
export const getPartnerADPFFlow = createSelector(
  getAppSelector,
  state => state && state.controlData && state.controlData.partnerADPFFlow ?
    state.controlData.partnerADPFFlow : false
);
// selector for getting  promo id and campaign id
export const getPromoIdAndCid = createSelector(
  getAppSelector,
  state => {
    if (state && state.data && state.data.promoId) {
      return {
        promoId: state.data.promoId,
        campaignId: state.data.campaignId
      };
    }
    }
);

export const getUtmParameters = createSelector(
  getAppSelector,
  state => state && state.data && state.data.utmParameters ? state.data.utmParameters : {}
);

export const getCampaignParameters = createSelector(
  getAppSelector,
  state => state && state.data && state.data.campaignParameters ? state.data.campaignParameters : {}
);
// selector to get multiquote data
export const getMultiQuoteData = createSelector(
  getAppSelector,
  state => {
    return state?.data?.multiQuoteVO?.length ? state.data.multiQuoteVO : null;
  });
// selector to get retrieve quote response data
export const getRetrieveQuoteRedirectionData = createSelector(
  getAppSelector,
  state => {
    return state;
  });

export const getBWCompanyCode = createSelector(
  getAppSelector,
  state => state && state.data && state.data.isBWCompanyCode ? state.data.isBWCompanyCode : false
);

export const getLastName = createSelector(
  getAppSelector,
  state => {
    return (state && state.data && state.data.customerInfo
      && state.data.customerInfo.lastName) ? state.data.customerInfo.lastName : null;
  }
);

export const getEmail = createSelector(
  getAppSelector,
  state => {
    return (state && state.data && state.data.customerInfo
      && state.data.customerInfo.email) ? state.data.customerInfo.email : null;
  }
);

export const getTestUserCheckProperties = createSelector(
  getLastName,
  getEmail,
  (lastName, email) => {
    return {
      lastName,
      email
    };
  },
);

/** to get prefillCallRequired flag from retrievequote response */
export const getRQPrefillRequiredStatus = createSelector(getAppSelector, appData => {
  return appData.controlData && appData.controlData.isPrefillCallRequired ?  appData.controlData.isPrefillCallRequired : false;
});

/** to get isYDExist flag from retrievequote response */
export const getYDExist = createSelector(getAppSelector, appData => {
  return appData.controlData && appData.controlData.isYDExist ?  appData.controlData.isYDExist : false;
});

/**
 * Selector for identifying the completion of retrieve quote and project code service
 */
export const getRQPrefillStatus = createSelector(
  getRetrieveQuoteStatus,
  getProjectCodeServiceStatus,
  getRQPrefillRequiredStatus,
  getYDExist,
  (retrieveQuote, projectCodeServiceStatus, prefillCallRequiredStatus, isYDExist) => {
    return { retrieveQuote, projectCodeServiceStatus, prefillCallRequiredStatus, isYDExist };
  },
);

/**
 * Selector for identifying the completion of retrieve quote and project code service
 */
export const getRQPrefillStatusForPI = createSelector(
  getRetrieveSummaryStatus,
  getProjectCodeServiceStatus,
  getRQPrefillRequiredStatus, getLastVisitedPage,
  (rateSummaryStatus, projectCodeServiceStatus, prefillCallRequiredStatus, lastVisitedPage) => {
  return { rateSummaryStatus, projectCodeServiceStatus, prefillCallRequiredStatus, lastVisitedPage };
},
);



export const getBoundInd = createSelector(getAppSelector, appData => {
  return appData?.controlData?.boundInd;
});