import { Component, OnInit, Input } from '@angular/core';
import { PrintData } from '@ffq-app-auto/quote/model/quote.model';
import { AppStoreServices } from '@ffq-app-store';
import { DataConstants } from '@ffq-app-shared';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-print-quote-details',
  templateUrl: './print-quote-details.component.html',
  styleUrls: ['./print-quote-details.component.scss']
})
export class PrintQuoteDetailsComponent implements OnInit {
  @Input() printData: PrintData;
  mockCoverageData = {
    quoteNumber: '1197930555',
    lob: 'Home',
    lobHeading: 'Home Insurance',
    firstName: 'sreee',
    lastName: 'pv',
    annualPremium: '2546.15',
    monthlyPremium: '212.17',
    agent: {
      agentId: null, firstName: null, lastName: null, address: null, communication:
      {
        emailId: [],
        phoneNumber: [{ areaCode: null, phoneNumber: '1-800-FARMERS', phoneExt: null }]
      },
      assign: null, buyEnabled: null, reference: null, agentTier: null, agentImageUrl: null,
      agentState: null, producerCode: null, primaryId: null, relProducerCode: null
    },
    sections: [{
      sectionHeading: 'Discounts Applied',
      sectionDatas: ['Homeowner', 'Safe driver', 'Auto & Life', 'Paid in full', 'Share-auto', 'Shared family car discount'],
    },
    {
      sectionHeading: 'Drivers Included',
      sectionDatas: ['Sylvia Terry', 'Julia Becker'],
    },
    {
      sectionHeading: 'Vehicles Included',
      sectionDatas: [' 2019 ACURA ILX 4D', '2017 FORD FIESTA ST 5D'],
    },
      {
        sectionHeading: 'Liability Coverage for All Vehicles',
        leftHeading: 'Coverage',
        middleHeading: 'Limits',
        rightHeading: 'Costs',
        sectionDatas: [{ coverage: 'Bodily Injury', limit: '$300000' , costs: '$83.17' },
        { coverage: 'Property Damage Liability', limit: '$300000/$150000' , costs: '$83.17' },
        { coverage: 'Permissive User Limit of Liability', limit: 'Full' , costs: '$83.17' }],
        sectionDataFooter: { text: 'Total Costs', costs: '$83.17' }
      },
      {
        sectionHeading: 'Vehicle Coverage for 2019 ACURA ILX 4D',
        leftHeading: 'Coverage',
        middleHeading: 'Deductible / Limits',
        rightHeading: 'Costs',
        sectionDatas: [{coverage: 'Comprehensive', limit: '$300000' , costs: '$85.16' },
        { coverage: 'Uninsured Motorist Property Damage without Collision', limit: 'No Coverage' , costs: '$83.17' },
        { coverage: 'Uninsured Motorist Property Damage with Collision', limit: '$300000/$150000' , costs: '$83.17' }],
        sectionDataFooter: { text: 'Total Costs for 2019 ACURA ILX 4D', costs: '$83.17' }
      },
     ]
  };
  liabilityCoverage = {};
  VehicleCoverage = {};
  vehicleList = [];
  driverList = [];
  discountApplied = [];
  /** US499784 : Digital Monetization Full Package Full Premium changes */
  isFullPackageFullPremiumImpl: boolean;
  private subscriptions: Subscription[] = [];
  /**
   * Flag to check discounts Availability
   */
  discountsAvailable: boolean;
  isPayInFull = false;
  paymentMode: string;
  isTotalDiscountSavingsApplied: boolean;
  constructor(private appStoreService: AppStoreServices.AppStoreService) { }

  ngOnInit(): void {
    this.discountApplied = this.printData.sections[0].sectionDatas;
    this.driverList = this.printData.sections[1].sectionDatas;
    this.vehicleList = this.printData.sections[2].sectionDatas;
    this.liabilityCoverage = this.creatCoverageLiabParams(this.printData.sections[3]);
    const vehCovArr = this.printData.sections.splice(4, this.printData.sections.length - 1 );
    this.VehicleCoverage = this.creatCoverageVehParams(vehCovArr);
    this.subscriptions.push(this.appStoreService.getProjectCodeStatus$.subscribe((data: any) => {
      this.isFullPackageFullPremiumImpl = data[`isFullPackageFullPremiumImpl`];
    }));
    /** US254852: Setting PaymentMode based on PayPolicy in Store */
    this.setPaymentMode();
    this.checkDiscountSavings();
  }

  // US259236: Display the total discount Saving on the Print page
  // DE146530: Do not display total discount saving verbiage when total discount saving is not present
  checkDiscountSavings(): void {
    this.isTotalDiscountSavingsApplied = (this.printData.totalDiscount &&
       this.printData.totalDiscount !== null && this.printData.isFXImpl) ? true : false;
  }

  setPaymentMode(): void {
    this.isPayInFull = this.printData.payPolicy === DataConstants.PAY_POLICY.payInfull;
    this.paymentMode = this.isPayInFull ?
      (this.isFullPackageFullPremiumImpl ? DataConstants.FP_PAY_POLICY.sixMonthsTerm : DataConstants.PAY_POLICY.sixMonthsTerm)
      : DataConstants.PAY_POLICY.monthlyAutomatic;
  }

  creatCoverageLiabParams(coverageObj) {
    const  coverageSectionObj = {};
    coverageSectionObj[`sectionHeading`] = coverageObj.sectionHeading;
    coverageSectionObj[`leftHeading`] =  'Coverage';
    coverageSectionObj[`middleHeading`] =  'Limits';
    coverageSectionObj[`rightHeading`] =  'Costs';
    coverageSectionObj[`sectionDatas`] =  coverageObj.sectionDatas.coverages;
    coverageSectionObj[`sectionDataFooter`] =  {text: 'Total Costs', costs: coverageObj.sectionDatas.total};
    this.checkDiscounts();
    return coverageSectionObj;
  }
  // US206225: Auto Product requirement for discounts display on print page for NC
  checkDiscounts() {
    this.appStoreService.getLandingStateCode$.subscribe(stateCode => {
      if (stateCode === 'NC' && !this.printData.sections[0].sectionDatas.length) {
          this.discountsAvailable = true;
       } else {
        this.discountsAvailable = false;
       }
     }).unsubscribe();
  }

  creatCoverageVehParams(vehCovArr) {
    const vehObjArr = [];
    vehCovArr.forEach(coverageObj => {
    const  coverageSectionObj = {};
    coverageSectionObj[`sectionHeading`] = coverageObj.sectionHeading;
    coverageSectionObj[`leftHeading`] =  'Coverage';
    coverageSectionObj[`middleHeading`] =  'Deductible / Limits';
    coverageSectionObj[`rightHeading`] =  'Costs';
    coverageSectionObj[`sectionDatas`] =  coverageObj.sectionDatas.coverages;
    // tslint:disable-next-line:max-line-length
    coverageSectionObj[`sectionDataFooter`] =  {text: 'Total Costs for ' + coverageObj.sectionDatas.title , costs: coverageObj.sectionDatas.total , physicalDamageIndicator: coverageObj.sectionDatas.physicalDamageIndicator};
    vehObjArr.push(coverageSectionObj);
  });
    return vehObjArr;
  }

}
