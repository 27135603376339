import { Component, OnInit, Input } from '@angular/core';
import { NavigationDrawerService } from '@ffq-app-shared/components/navigation-drawer/navigation-drawer.service';
import { AppStoreService } from '@ffq-app-store/app.store.service';
import { AgentData } from '@ffq-app-shared/model/common.data.model';
import { Router } from '@angular/router';
import { PrintData } from '@ffq-app-auto/quote/model/quote.model';
import { AutoStoreService } from '@ffq-app-auto/store/auto.store.service';
import { DataConstants } from '@ffq-app-shared/constants';

@Component({
  selector: 'app-print-quote-agent-info',
  templateUrl: './print-quote-agent-info.component.html',
  styleUrls: ['./print-quote-agent-info.component.scss']
})
export class PrintQuoteAgentInfoComponent implements OnInit {

  showAsStrip = false;
  showDrawer = false;
  agentAssigned = false;
  agentName: string;
  agentLocation: string;
  agentPhoneNumber: string;
  agentDetails = {} as AgentData;
  googleMaplocation: string;
  gwEEImpl = false;
  teleDesktop: string;
  teleMobile: string;
  quoteType: string;
  hideDesktopLabel = false;
  ariaLabelPhoneNumber: string;
  ariaLabelStreet: string;
  agentPhoneNumberCC: string;
  sourcePhoneNumber: string;
  desktopNo: string;
  mobileNo: string;
  landingStateCode: any;
  // quoteData: PrintData;
  @Input() printData: PrintData;
  isBwQuote: boolean;
  /**
   * Creates an instance of agent details component.
   * @param navigationDrawerService NavigationDrawerService
   * @param appStoreService AppStoreService
   */
  constructor(
    private router: Router, private appStoreService: AppStoreService, private autoStoreService: AutoStoreService,
  ) {}

  ngOnInit() {
    /**
     * US501061 && US501062: CA FFQ Digital Monetization - CA State TFN Replacement
     * for Agent, Quote, Thank you and print quote pages for desktop and mobile windows
     */
    this.appStoreService.appDataZip$.subscribe(appDataZip => {
      this.landingStateCode = appDataZip.landingStateCode;
    });

    this.isBwQuote = this.printData.isBwQuote;
    if (this.printData.agent && (this.printData.agent.agent || this.printData.agent.agentInfoBean)) {
      this.agentDetails = this.printData.agent.agent ? this.printData.agent.agent : this.printData.agent.agentInfoBean;
      if (this.agentDetails.reference === '1') {
        this.agentAssigned = true;
        if (this.agentDetails.firstName || this.agentDetails.lastName) {
          this.agentName = this.agentDetails.firstName + ' ' + this.agentDetails.lastName;
        }
        if (this.agentDetails.address.city || this.agentDetails.address.state || this.agentDetails.address.zip) {
          this.agentLocation = this.agentDetails.address.city + ', ' + this.agentDetails.address.state + ' ' +
            this.agentDetails.address.zip;
        }
        if (this.agentDetails.address.street) {
          this.ariaLabelStreet = this.agentDetails.address.street.replace(/[^\w\s]/gi, '');
        }
        if (this.agentDetails.communication.phoneNumber.phoneNumber &&
          this.agentDetails.communication.phoneNumber.phoneNumber !== '1-800-FARMERS') {
          this.agentPhoneNumber = this.agentDetails.communication.phoneNumber.phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/,
            '$1-$2-$3');
          this.agentPhoneNumberCC = '1-'.concat(this.agentDetails.communication.phoneNumber.phoneNumber);
        }
        const googleLocquerry = this.agentDetails.address.street + ', ' + this.agentLocation;
        this.googleMaplocation = 'http://maps.google.com/?q=' + googleLocquerry;

      } else if (this.agentDetails.reference === 'D') {
        this.agentAssigned = false;
      } else {
        this.agentAssigned = false;
      }
    }
    this.gwEEImpl = this.printData.isEEImpl;
    this.appStoreService.getPhoneNumberFromSrc$.subscribe((phoneNumber: any) => {
      this.sourcePhoneNumber = phoneNumber;
      if (this.sourcePhoneNumber) {
        this.teleDesktop = this.teleMobile = 'tel:' + this.sourcePhoneNumber;
        this.ariaLabelPhoneNumber = this.sourcePhoneNumber;
        this.desktopNo = this.mobileNo = this.sourcePhoneNumber;
      }
    });
    if (!this.sourcePhoneNumber) {
      if (this.gwEEImpl && !this.printData.isTFNProjectImpl) {
        this.teleDesktop = 'tel:1-888-767-4030';
        this.teleMobile = 'tel:1-888-767-4030';
        this.ariaLabelPhoneNumber = '1-888-767-4030';
        this.desktopNo = '1-888-767-4030';
        this.mobileNo = '1-888-767-4030';
      } else {
        /**
         * US501061 && US501062: CA FFQ Digital Monetization - CA State TFN Replacement
         * for Agent, Quote, Thank you and print quote pages for desktop and mobile windows
         */
        if (this.printData.isTFNProjectImpl && !this.agentAssigned) {
          this.teleDesktop = DataConstants.TFN_HELP_NUMBER.teleDesktop;
          this.teleMobile = DataConstants.TFN_HELP_NUMBER.teleMobile;
          this.ariaLabelPhoneNumber = DataConstants.TFN_HELP_NUMBER.ariaLabelPhoneNumber;
          this.desktopNo = DataConstants.TFN_HELP_NUMBER.desktopNo;
          this.mobileNo = DataConstants.TFN_HELP_NUMBER.mobileNo;
        } else {
          this.teleDesktop = 'tel:18002069469';
          this.teleMobile = 'tel:18004209008';
          this.ariaLabelPhoneNumber = '18002069469';
          this.desktopNo = '1-800-206-9469';
          this.mobileNo = '1-800-420-9008';
        }
      }
    }
    this.quoteType = this.printData.quoteType;
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (width < 600) {
      this.hideDesktopLabel = true;
    }

  }

}
