import { Injectable } from '@angular/core';
import { AutoStoreService } from '@ffq-app-auto/store/auto.store.service';
import { FlowParamter, ModuleProgressStatus, ServiceCallStatus } from '@ffq-app-shared/enums/flow.identifier.enum';
import moment from 'moment';
import { RateMode, Driver, LastVisitedModule } from '@ffq-app-shared/model/common.data.model';
import { AppStoreService } from '@ffq-app-store/app.store.service';
import { PremiumAmountObj } from '@ffq-app-shared/model/common.data.model';
import { StateRuleModelService } from '@ffq-app-shared/services';
import { DateService } from '@ffq-lib/src/lib/core/services/date.service';
import { DataConstants } from '@ffq-app-shared/constants/data.constants';

@Injectable({
  providedIn: 'root'
})
export class AutoCommonService {
  /**
   * Birth date of auto common service
   */
  birthDate: Date;
  /**
   * Determines whether quote complete is
   */
  isQuoteComplete: boolean;
  /**
   * Driving exp years of auto common service
   */
  drivingExpYears: number;

  /**
   * Creates an instance of auto common service.
   * @param autoStoreService AutoStoreService
   */
   constructor(
    private appStoreService: AppStoreService,
    private autoStoreService: AutoStoreService,
    private stateRuleModel: StateRuleModelService,
    private dateService: DateService) { }

  /**
   * Calculates driving exp
   */
  calculateDrivingExp(dateOfBirth: any , ageFirstLicensed: any , policyStartDate: any) {
    this.birthDate = new Date(dateOfBirth);
    const dateFirstLicensed = new Date(this.birthDate.setFullYear(this.birthDate.getFullYear() + parseInt(ageFirstLicensed, 10)));
    this.autoStoreService.getPNIDisableParameters$.pipe().subscribe(data => {
      if (data.quoteNavigationStatus === ModuleProgressStatus.COMPLETED) {
        this.isQuoteComplete = true;
      }
    }).unsubscribe();
    const today = new Date();
    const firstDate = moment(dateFirstLicensed);
    let secondDate: moment.Moment;
    if (this.isQuoteComplete || policyStartDate) {
      secondDate = moment(new Date(policyStartDate));
    } else {
      secondDate = moment(today);
    }
    const duration = moment.duration(secondDate.diff(firstDate));
    this.drivingExpYears = Math.floor(duration.asYears());
    return this.drivingExpYears;
  }

  /**
   * Calculates PNI and PNI's spouse age
   */
  getPniAndSpouseAge(driversData: Driver[]): number[] {
    let pniAge = 0;
    let spouseAge = 0;
    driversData.forEach((driverItem => {
      if (driverItem.pni === 'H') {
         pniAge = this.dateService.getAge(new Date(driverItem.dateOfBirth));
      }
      if (driverItem.pni === 'X') {
         spouseAge = this.dateService.getAge(new Date(driverItem.dateOfBirth));
      }
    }));
    return [pniAge, spouseAge];
  }

  // US239349: Auto, Stop displaying Distant student discount question on Additional Driver screen after PNI or Spouse age is changed
  checkDistantStudent(driverData: Driver[]): boolean {
    let pniAge: number;
    let spouseAge: number;
    let distantStudentflag = false;
    let age: number;
    driverData.forEach((driverItem => {
      age = this.dateService.getAge(new Date(driverItem.dateOfBirth));
      if (driverItem.pni === 'H') {
        pniAge = age;
      }
      if (driverItem.pni === 'X') {
        spouseAge = age;
      }
      if ((pniAge >= 30 || spouseAge >= 30) && driverItem.pni !== 'X' && driverItem.pni !== 'H' && driverItem.maritalStatus !== 'M' &&
      age < 25 && (driverItem.distantStudent === '' || driverItem.distantStudent === null) &&
      driverItem.driveProgress === 'C' && !distantStudentflag)  {
        distantStudentflag = true;
      }
    }));
    return distantStudentflag;
  }

  /**
   * Checks distant student condition
   */
  distantStudentCondition(driverItem: Driver): boolean {
      const age = this.dateService.getAge(new Date(driverItem.dateOfBirth));
      if ((driverItem.pni !== 'H' && driverItem.pni !== 'X') &&
        driverItem.maritalStatus !== 'M' && age < 25 && (driverItem.distantStudent === 'true'
          || driverItem.distantStudent === 'false') && driverItem.driveProgress === 'C') {
        return true;
      }
      return false;
  }

  isDriverExperienced(drivingExpYrs: number) {
    return (drivingExpYrs < 3);
  }

  /**
   * Calculates number of days for Early Shopping
   */
  getDaysForEarlyShopping(policyStartDate: any) {
    let today: moment.Moment;
    today = moment(new Date());
    const quoteEffectiveDate = moment(new Date(policyStartDate));
    const duration = moment.duration(quoteEffectiveDate.diff(today));
    const earlyShoppingDays = Math.floor(duration.asDays() + 1);
    return earlyShoppingDays;
  }

  /**
   * Determines whether the quote is piquote or not
   * @returns  boolean
   */
  isPIQuote() {
    let result = false;
    this.autoStoreService.getRc1PremiumAmt$.subscribe(premiumAmt => {
      result = (premiumAmt && premiumAmt !== null && premiumAmt !== '');
    }).unsubscribe();
    return result;
  }

  /**
   * Determines whether bwquote is
   * @returns boolean
   */
  isBWQuote() {
    let result = false;
    this.autoStoreService.getBrightLinedData$.subscribe(data => {
      result = data && data.autoCompanyCode && data.autoCompanyCode === 'B';
    }).unsubscribe();
    return result;
  }

  /**
   * Gets rate mode
   * @param packageType selected package
   * @returns rateMode
   */
  getRateMode(packageType) {
    let rateMode = RateMode.Rate;
    if (this.isBWQuote() || !(this.isPIQuote() && packageType === 'default')) {
      return rateMode;
    }
    this.appStoreService.getCvgUpdationRequired$.subscribe(requiredUpdation => {
      rateMode = requiredUpdation ? RateMode.PI_Rate : RateMode.PI_Retrieve;
    }).unsubscribe();
    return rateMode;
  }

  /**
   * Setting Quote Rate Premium Amount
   */
  setQuoteRatePremiumAmount(rc1PremiumAmount, landingStateCode, isEFTImpl) {
    let payPolicy;
    let custPackgAmnt;
    const packageObj: PremiumAmountObj = {};

    /** subscription for paypolicy */
    this.autoStoreService.getDiscountData$.subscribe((data: any) => {
        payPolicy = data.payPolicy;
    }).unsubscribe();
    this.autoStoreService.getQuoteRateData$.subscribe((data: any) => {
      rc1PremiumAmount = this.calculatePremiumWithMemberFee(rc1PremiumAmount, data[`policyFee`], landingStateCode);
      packageObj.isPayInFull = false;
      if (payPolicy === 'PayInfull') {
        // US258736 - updating the verbiage for 1pay payment mode
        packageObj.packgDuration = DataConstants.PAY_POLICY.sixMonthsTerm;
        // US258736 - to hide the asterisk for pay in full payment mode
        packageObj.isPayInFull = true;
        custPackgAmnt = parseFloat(rc1PremiumAmount);
      } else if (payPolicy === 'MonthlyAutomatic') {
        if (isEFTImpl) {
          packageObj.packgDuration = 'monthly automatic';
        } else {
          packageObj.packgDuration = 'monthly';
        }
        custPackgAmnt = parseFloat(rc1PremiumAmount) / 6;
      } else if (payPolicy === 'MonthlyBill') {
        packageObj.packgDuration = 'monthly';
        custPackgAmnt = parseFloat(rc1PremiumAmount) / 6;
      }
      packageObj.packgAmntValue = custPackgAmnt.toFixed(2).split('.')[0];
      packageObj.packgAmntFraction = '.' + custPackgAmnt.toFixed(2).split('.')[1];
    });


    return packageObj;
  }

  /**
   * calculating Premium With Member Fee
   */
  calculatePremiumWithMemberFee(premiumAmount, policyFee, landingStateCode) {
    if (this.stateRuleModel.getQuoteModuleStateRule(landingStateCode).isPolicyFeeEnabled
      && policyFee) {
      return parseFloat(premiumAmount) + parseFloat(policyFee);
    } else {
      return premiumAmount;
    }
  }
  /**
   * Sort the Display of Drivers and Maintains the Order as in AngularJS Application
   */
  sortDriverList(driverDataList: Driver[]): Driver[] {
    const finalSortedDriverDataList = [];
    // Sort in ascenading order
    driverDataList.sort((driver1, driver2) => (driver1.reference > driver2.reference) ? 1 : -1);
    // initialize the already added drivers who have spouse
    const marriedAlreadyAddedDrivers = [];
    // Iterate all drivers
    for (const driver of driverDataList) {
      // See if the refrence of the driver is already added in the this.finalSortedDriverDataList
      if (marriedAlreadyAddedDrivers.indexOf(driver.reference) === -1) {
        // Add the Driver
        finalSortedDriverDataList.push(driver);
        // If the driver is married?
        if (driver.spouseRef) {
          // add to this list marriedAlreadyAddedDrivers to ensure it is not added again
          marriedAlreadyAddedDrivers.push(driver.spouseRef);
          // find the spouse and add into the list so that it is displayed near the spouse
          const dvrData = driverDataList.find(dver => dver.reference === driver.spouseRef);
          const isDriverExist = finalSortedDriverDataList.find(dver => dver.reference === driver.spouseRef);
          if (dvrData && (isDriverExist === null || isDriverExist === undefined)) {
            finalSortedDriverDataList.push(dvrData);
          }
        }
      }
    }
    return finalSortedDriverDataList;
  }

  /**
   * Calculates years of difference for Safe Driver
   */
  getYearsDiff(incidentDate: Date): number {
    let today: moment.Moment;
    today = moment(new Date());
    const incidentDateHappened = moment(new Date(incidentDate));
    const duration = moment.duration(today.diff(incidentDateHappened));
    const yearsDiff = Math.floor(duration.asYears());
    return yearsDiff;
  }

  /**
   * Saves iidknock out
   * @param koMessage KOMessage
   */
  saveIIDKnockOut(koMessage: string): void {
    let quoteNumber: string = null;
    /* No need to trigger the  */
    if (koMessage) {
      this.appStoreService.quoteNumber$.subscribe((quoteNum: string) => {
        quoteNumber = quoteNum;
      }).unsubscribe();
      const requestData = {
        quoteNumber,
        knockOutReason: koMessage,
        lastVisitedPage: LastVisitedModule.Auto_IIDKnockOut,
      };
      this.autoStoreService.saveKnockOut(requestData);
    }
  }

  /**
   * Determines whether agent assign service completed is
   * @returns true if agent assign service completed
   */
  isAgentAssignServiceCompleted(): boolean {

    let assignAgentSkipInd = false;
    let isAgentAssignCompleted = false;

    this.autoStoreService.getPNIDisableParameters$.subscribe(data => {
      // if it is cross sell & Quote is completed.
      if (data && (data.flowParam === FlowParamter.CROSS_SELL
        || data.quoteNavigationStatus === ModuleProgressStatus.COMPLETED)) {
        // Setting a paramter to skip Assign Agent call on Submit yourinfo
        assignAgentSkipInd = true;
      }
    }).unsubscribe();

    this.appStoreService.appControlData$.subscribe(ctrlData => {
      isAgentAssignCompleted = ctrlData &&
        ((ctrlData.assignAgent && ctrlData.assignAgent === ServiceCallStatus.COMPLETED) ||
         ctrlData.assignAgent === ServiceCallStatus.FAIL ||
          assignAgentSkipInd);
    }).unsubscribe();

    return isAgentAssignCompleted;

  }
}
