import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lib-card-package',
  templateUrl: './card-package.component.html',
  styleUrls: ['./card-package.component.scss']
})
export class CardPackageComponent implements OnInit {

  @Input() params: any;
 // @Output() cardData: EventEmitter<any> = new EventEmitter();
  constructor() { }
  ngOnInit() {
  }

}
