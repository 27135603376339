<div *ngIf="showMockLandingPage">
    <div class="ffq-container">
    <div class = "landing-logo-placeholder">
    <div class="landing-logo" >
        <img src="assets/images/header-m1.png" width="100" height="53" alt="Farmers Insurance Logo">
   </div>
   </div>
<div *ngIf="!showMultiQuote">
   <div class = "landing-box">
    <div class = "landing-header" *ngIf="!showRetrieve">
        <h1 title="Farmers FastQuote®">Farmers FastQuote<sup>®</sup> </h1>
        <div class ="landing-phone-number">
            <img src="assets/images/phone-icon.png" width="18" height="18" alt=""/>
            <span class="landing-phone-text">Call <a href="tel:1-800-515-1896"
                aria-label=  "Contact farmers representative at 1-800-515-1896" data-GTM="FFQ_Auto_Landing_Callforaquote_link"> 1-800-515-1896</a> for a quote</span>
        </div>
    </div>
    <!--show below block on showRetrieve condition-->
    <div *ngIf="showRetrieve" [ngClass]="showRetrieve ? 'landing-show-retrieve-header' : 'landing-retrieve-header' ">
        <h1 title="Retrieve Saved Quote®">Retrieve Saved Quote<sup>®</sup> </h1>
    </div>
    <!--hidden element introduced for accessibility readon-->
    <p *ngIf="!showRetrieve"  id="landing-accessibility-header" class="visibility-hidden"
     tabindex="0" role="dialog">Get a New quote or Retrieve a Saved Quote by choosing from the tab list below</p>
    <div class="ffq-box" *ngIf="!showRetrieve">
        <div class = "landing-heading-block" role="tablist">
        <div class="landing-get-quote-header" role="tab" id ="landing-quote" tabindex="0" aria-controls="ffq-tab-panel-1" [attr.aria-selected]="!show" [ngClass]="!show ? 'selected' : 'not-selected'" (click)="toggleFunction()" (keyup)="togglKeyupFunction($event)" data-GTM="FFQ_Auto_Landing_Newquote_toggle">
            <span>Get a New Quote</span></div>
        <div class="landing-retrieve-header" role="tab" id="landing-retrieve" aria-controls="ffq-tab-panel-2"  tabindex="-1" [attr.aria-selected]="show"  [ngClass]="show ? 'selected' : 'not-selected'" (click)="toggleFunction()" (keyup)="togglKeyupFunction($event)"  data-GTM="FFQ_Auto_Landing_Retrievequote_toggle"><span>Retrieve a Saved Quote</span></div>
    </div>

    <div *ngIf="!show" class="start-quote-box" id="ffq-tab-panel-1" role="tabpanel">
        <p *ngIf="!show" class = "landing-header-container" id="ffq-landing-get-a-quote-header">
            Select a product to get a quote:
        </p>
        <p  class="ffq-landing-showproduct-err"  *ngIf="!show && showProductErr" role="alert">
            Please select a product.</p>
            <form  [formGroup]="landingformGroup" novalidate >
                <mat-selection-list aria-labelledby="ffq-landing-get-a-quote-header" tabindex="0"
                class="landing-lob-list" [multiple]="false"
                (selectionChange)="callLObSelect($event.option.value)">
                    <mat-list-option value="auto" [title]="getLobTitle('Auto')"
                    [attr.data-GTM]="getLobGtmTag('Auto')">
                            <img class ="landing-lob-image" alt="Auto Icon" [src]="autoImage">
                            <span class = "landing-lob-text" aria-hidden="true">Auto</span>
                    </mat-list-option>
                    <mat-list-option  value="home" [title]="getLobTitle('Home')"
                    [attr.data-GTM]="getLobGtmTag('Home')">
                            <img class ="landing-lob-image" alt="Home Icon" [src]="homeImage"/>
                            <span class = "landing-lob-text" aria-hidden="true">Home</span>
                    </mat-list-option>
                    <mat-list-option  value="life" [title]="getLobTitle('Life')"
                    [attr.data-GTM]="getLobGtmTag('Life')">
                            <img class ="landing-lob-image" alt="Life Icon" [src]="lifeImage"/>
                            <span class = "landing-lob-text" aria-hidden="true">Life</span>
                    </mat-list-option>
                    <mat-list-option  value="business" [title]="getLobTitle('Business')"
                    [attr.data-GTM]="getLobGtmTag('Business')">
                            <img class ="landing-lob-image" alt="Business Icon" [src]="businessImage"/>
                            <span class = "landing-lob-text" aria-hidden="true">Business</span>
                    </mat-list-option>
                    <mat-list-option  value="renter" [title]="getLobTitle('Renters')"
                    [attr.data-GTM]="getLobGtmTag('Renters')">
                            <img class ="landing-lob-image" alt="Renters Icon" [src]="rentersImage"/>
                            <span class = "landing-lob-text" aria-hidden="true">Renters</span>
                    </mat-list-option>
                    <mat-list-option value='condo' [title]="getLobTitle('Condo')"
                    [attr.data-GTM]="getLobGtmTag('Condo')">
                            <img class ="landing-lob-image" alt="Condo Icon" [src]="condoImage"/>
                            <span class = "landing-lob-text" aria-hidden="true">Condo</span>
                    </mat-list-option>
                </mat-selection-list>
                <div class = "start-quote-container">
                    <div id="ffq-landing-zip-code" class="landing-zip-code">
                        <lib-input-text [params]="landingQuoteParams.landingZipCode" data-GTM="FFQ_Auto_Landing_Newquote_Zipcode_field"></lib-input-text>
                    </div>
                    <div class="landing-button-start">
                        <button   type="submit" (click)="startMyQuoteFromLandingPage()" [attr.aria-disabled] ="!this.landingformGroup.valid" title = 'Start My Quote' data-GTM="FFQ_Auto_Landing_Newquote_Startmyquote_button" >START MY QUOTE</button>
                    </div>
                </div>

                <div class="ffq-landing-show-zip-err" role="alert" *ngIf="showZipErr"  [innerHtml]="errFromResponse"></div>
            </form>
    </div>
    <div *ngIf="show"  class="retrieve-quote-box" id="ffq-tab-panel-2" role="tabpanel">
        <div class="ffq-retrive-box ">
            <form  [formGroup]="retriveQuoteFormGroup" novalidate>
                <div class="form-control">
                    <lib-input-text [params]="retriveQuoteParams.lastName" data-GTM="FFQ_Auto_Landing_Retrievequote_Lastname_field"></lib-input-text>
                </div>
                <div class="form-control">
                    <lib-date-picker [params]="retriveQuoteParams.dateOfBirth" data-GTM="FFQ_Auto_Landing_Retrievequote_Dateofbirth_field"></lib-date-picker>
                </div>
                <div class="form-control">
                    <lib-input-text [params]="retriveQuoteParams.quoteNumber" data-GTM="FFQ_Auto_Landing_Retrievequote_Quotenumber_field"></lib-input-text>
                </div>

                <div class="form-control">
                    <lib-input-text [params]="retriveQuoteParams.landingZipCode" data-GTM="FFQ_Auto_Landing_Retrievequote_Zipcode_field"></lib-input-text>
                </div>
                <div class="landing-button-retrieve">
                    <button   type="submit" (click)="retriveYourQuote()" [attr.aria-disabled]= "!this.retriveQuoteFormGroup.valid"  title = 'Retrieve Your Quote' data-GTM="FFQ_Auto_Landing_Retrievequote_button">RETRIEVE YOUR QUOTE</button>
                </div>
            </form>
        </div>
    </div>
    <div class="landing-ccpa-url">
        <a class="privacy-link" href="{{this.ccpaUrl}}" target="_blank" data-GTM="FFQ_Auto_Landing_Retrievequote_Personalinfo_link">Personal information use</a>
    </div>
</div>

 <!--show below block on showRetrieve condition-->
  <div class="ffq-retrive-box" *ngIf="showRetrieve">
            <form  [formGroup]="retriveQuoteFormGroup" novalidate #retrieveQuoteForm>
                <div class="form-control">
                    <lib-input-text [params]="retriveQuoteParams.lastName" data-GTM="FFQ_Auto_Landing_Retrievequote_Lastname_field"></lib-input-text>
                </div>
                <div class="form-control">
                    <lib-date-picker [params]="retriveQuoteParams.dateOfBirth" data-GTM="FFQ_Auto_Landing_Retrievequote_Dateofbirth_field"></lib-date-picker>
                </div>
                <div class="form-control">
                    <lib-input-text [params]="retriveQuoteParams.quoteNumber" data-GTM="FFQ_Auto_Landing_Retrievequote_Quotenumber_field"></lib-input-text>
                </div>

                <div class="form-control">
                    <lib-input-text [params]="retriveQuoteParams.landingZipCode" data-GTM="FFQ_Auto_Landing_Retrievequote_Zipcode_field"></lib-input-text>
                </div>

                <div class="landing-button-retrieve">
                    <button   type="submit" (click)="retriveYourQuote()" title = 'Retrieve Your Quote' data-GTM="FFQ_Auto_Landing_Retrievequote_button">RETRIEVE YOUR QUOTE</button>
                </div>

            </form>
            <div class="landing-ccpa-url">
                <a class="privacy-link" href="{{this.ccpaUrl}}" target="_blank" title="Personal Information Use" data-GTM="FFQ_Auto_Landing_Retrievequote_Personalinfo_link">Personal information use</a>
            </div>
        </div>

</div>
<div class="ffq-landing-disclaimer">
    <p><span>A preliminary rate ("quote") is for insurance coverage you select. Certain assumptions may have been made that will influence the quote you receive. A quote is not an offer for insurance, an offer of an insurance contract, nor is it an insurance contract, binder or an application to purchase insurance. The coverage descriptions provided are general descriptions of available coverage and are not a statement of contract. Rates quoted reflect the rates in effect as of the date of this quote and are subject to revision. Your actual premium may vary based upon additional information you provide or we obtain, the coverages and deductibles that you select, any additional underwriting criteria, and removal of any assumptions. Farmers reserves the right to accept, reject, or modify this quote after review of the application and other underwriting information. All applications are subject to underwriting approval. Farmers online quotes are for new customers only. Existing customers are requested to contact their local agent to make changes to their policy. Quoted rates do not include fees permitted by state law.</span>
    </p>
</div>
<div class="footer-links-row footer-links-cyber-trust" role="link">
    <div class="footer-links-icon" id= "DigiCertClickID_Z9bZfwjL" data-language="en" data-GTM="FFQ_Auto_sidepanel_digicertlogo_link" title="Click to Verify - This site chose digicert SSL for secure e-commerce and confidential communications.">
        </div>
</div>
<div class="ffq-lading-footer-block">
            <div class="ffq-landing-footer-headers">
                <ul>
                    <li>
                        <a class="ffq-landing-footer-links privacy-policy" href="https://www.farmers.com/privacy-statement/#donotsell"
                            target="_blank" aria-label="Do not sell or share my personal information opens in new window" role="link"
                            data-GTM="FFQ_Auto_sidepanel_privacypolicy_link">Do not sell or share my personal
                            information |</a>
                    </li>
                    <li>
                        <a class="ffq-landing-footer-links" href="https://www.farmers.com/disclaimer/terms-of-use/" target="_blank"
                            aria-label="Terms of Use opens in new window" role="link" data-GTM="FFQ_Auto_sidepanel_termsofuse_link">
                            Terms of use |</a>
                    </li>
                    <li>
                        <a class="ffq-landing-footer-links" href="http://www.farmers.com/contact-us/" target="_blank"
                            aria-label="Contact us opens in new window" role="link" data-GTM="FFQ_Auto_sidepanel_contactus_link">
                            Contact us |</a>
                    </li>
                    <li>
                        <a class="ffq-landing-footer-links" id="your-privacy" href="#" role="button"
                            data-GTM="FFQ_Footer_YourPrivacyChoices" onclick="javascript:OneTrust.ToggleInfoDisplay();return false;">
                            Your privacy choices
                            <img src="https://www.farmers.com/content/dam/farmers/marketing/digital/aem/icons/misc/privacyoptions.png"
                                class="footer-privacy" alt="">
                        </a>
                    </li>
                </ul>
            </div>
</div>
</div>
<div *ngIf="showMultiQuote">
    <app-multi-retrieve-quote [multiQuoteVO]="multiQuoteVO"></app-multi-retrieve-quote>
</div>
</div>
</div>