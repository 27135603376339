import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
/** Shared Properties */
import { StateRuleModelService } from '@ffq-app-shared/services';
import { DataConstants } from '../../../../shared/constants/data.constants';
import { AppStoreServices } from '@ffq-app-store';
import { take } from 'rxjs/operators';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-signal-banner-popup',
  templateUrl: './signal-banner-popup.component.html',
  styleUrls: ['./signal-banner-popup.component.scss']
})
export class SignalBannerPopupComponent implements OnInit {
  /**
   * Subscriptions  of add driver component
   */
  private subscriptions: Subscription[] = [];
  /**
   * Landing state code of add driver component
   */
  landingStateCode: string;
  /**
   * Popup content of Signal banner
   */
  popupContent: SafeHtml;
  /**
   * Signal banner phone number link GTM
   */
  signalBannerPhoneLink: string;
  /**
   * Signal banner signal link GTM
   */
  signalBannerFarmersLink: string;
  /**
   * US171739: To display Signal modal in quote page.
   * Signal available description text
   */
  signalAvailableDesc: string;
  /**
   * US171739: To display Signal modal in quote page.
   * Signal eligibility description boolean
   */
  signalEligibilityDesc = false;
  /**
   * US284892: To display Signal modal in quote page.
   */
  headerText: string;
  /**
   * US284892: To display Signal modal in quote page.
   */
  contentText: string;

  /**
   * Create an instance of signal banner popup component
   * @param dialogRef dialog
   * @param stateRuleModel model
   * @param appStoreService service
   * @param domSanitizer dom sanitizer
   */
  constructor(
    public dialogRef: MatDialogRef<SignalBannerPopupComponent>,
    private stateRuleModel: StateRuleModelService,
    private appStoreService: AppStoreServices.AppStoreService,
    private domSanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    /** subscription for landing state code */
    this.subscriptions.push(this.appStoreService.appDataZip$.pipe(take(1)).subscribe((data: any) => {
      this.landingStateCode = data[`landingStateCode`];
    }));
    this.signalBannerFarmersLink = 'FFQ_Auto_Quote_Signal_Banner_Farmers_Link';
    this.signalBannerPhoneLink = 'FFQ_Auto_Quote_Signal_Banner_Phone_Link';

    this.loadContent();
  }
  /**
   * Load content to Popup
   */
  loadContent() {
    if (!this.stateRuleModel.getSignalBannerStateRule(this.landingStateCode).isSignalBannerPopupContentChange &&
        this.landingStateCode !== DataConstants.STATE_CODES.NORTH_CAROLINA) {
      this.popupContent = this.domSanitizer.bypassSecurityTrustHtml(DataConstants.SignalBannerPopupContent.contentForAllOtherState);
    } else  {
      this.popupContent = this.domSanitizer.bypassSecurityTrustHtml(DataConstants.SignalBannerPopupContent.contentForEEStates);
    }
    // US171739: To display Signal modal in quote page.
    if (this.landingStateCode === DataConstants.STATE_CODES.NORTH_CAROLINA) {
      this.signalEligibilityDesc = true;
      this.signalAvailableDesc = DataConstants.SIGNAL_BANNER_POPUP_TEXT.forNCState;
      this.headerText = DataConstants.SIGNAL_BANNER_POPUP_TEXT.headerTextNC;
      this.contentText = DataConstants.SIGNAL_BANNER_POPUP_TEXT.contentTextNC;
    } else {
      this.signalAvailableDesc = DataConstants.SIGNAL_BANNER_POPUP_TEXT.otherStates;
      this.headerText = DataConstants.SIGNAL_BANNER_POPUP_TEXT.headerTextOthers;
      this.contentText = DataConstants.SIGNAL_BANNER_POPUP_TEXT.contentTextOthers;
    }
  }
  /**
   * Close Popup
   */
  closeSignalBannerPopup() {
    this.dialogRef.close();
  }

}
