import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class ScheduleAgentService {
    schdlAgentConfmPopup: Subject<void> = new Subject<void>();
    /**
     * emit an event on close of schedule agent confirmation dialog
     */
    onConfmDialogClose(): void {
        this.schdlAgentConfmPopup.next();
    }
    /**
     * return subject as observable
     * @returns Observable<void>
     */
    getConfmDialogCloseEvent(): Observable<void> {
        return this.schdlAgentConfmPopup.asObservable();
    }
}
