import { Component, OnInit } from '@angular/core';
import { NavigationDrawerService } from '@ffq-app-shared/components';
import { AppStoreService } from '@ffq-app-store/app.store.service';
import { ServiceConstants } from '@ffq-app-shared/constants/service.constants';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-business-quote-page',
  templateUrl: './business-quote-page.component.html',
  styleUrls: ['./business-quote-page.component.scss']
})
export class BusinessQuoteComponent implements OnInit {
  quoteNumber: any;
  pageTitle = 'We’re sorry';
  desktopNo: string;
  mobileNo: string;
  teleDesktop: string;
  teleMobile: string;
  desktopAriaLlabel: string;
  mobileAriaLlabel: string;
  sourcePhoneNumber: string;

  constructor(private navDrawer: NavigationDrawerService,
              private appStoreService: AppStoreService,
              private titleService: Title
  ) { }

  ngOnInit() {
    this.setTitle(this.pageTitle);
    this.navDrawer.hideDrawer();
    this.navDrawer.hideEverything();
    this.appStoreService.getPhoneNumberFromSrc$.subscribe((phoneNumber: any) => {
      this.sourcePhoneNumber = phoneNumber;
      this.setAgentPhoneNumber();
    });
  }

  findAnAgent() {
    window.location.href = ServiceConstants.FIND_AN_AGENT;
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  /**
   * Sets agent phone number
   */
  setAgentPhoneNumber() {
    if (this.sourcePhoneNumber) {
      this.teleDesktop = this.teleMobile = 'tel:' + this.sourcePhoneNumber.replace(/-/g, '');
      this.desktopNo = this.mobileNo = this.sourcePhoneNumber;
      this.desktopAriaLlabel = this.mobileAriaLlabel = this.sourcePhoneNumber + ' link';
    } else {
      this.teleDesktop = 'tel:18002069469';
      this.teleMobile = 'tel:18004209008';
      this.desktopNo = '1-800-206-9469';
      this.mobileNo = '1-800-420-9008';
      this.desktopAriaLlabel = '1-800-206-9469 link';
      this.mobileAriaLlabel = '1-800-420-9008 link';
    }
  }
}


