import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DataConstants } from '@ffq-app-shared/constants/data.constants';
import { AppStoreService } from '@ffq-app-store/app.store.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';


@Component({
  selector: 'app-signal-discount-popup',
  templateUrl: './signal-discount-popup.component.html',
  styleUrls: ['./signal-discount-popup.component.scss']
})
export class SignalDiscountPopupComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
 /**
  * Landing state code of add vehicle component
  */
  landingStateCode: string;
  /**
   * Flag to check flex implemented states
   */
   isFXImpl: boolean;
   signalPopUpHeader: string;
   signalPopUParagraph: string;
   signalPopUPStep1: string;

  constructor(
    public dialogRef: MatDialogRef<SignalDiscountPopupComponent>,
    private appStoreService: AppStoreService
  ) { }

  ngOnInit(): void {
     /** subscription for landing state code */
     this.subscriptions.push(this.appStoreService.getLandingStateCode$.pipe(take(1)).subscribe((data: any) => {
      this.landingStateCode = data;
    }));
     /** subscription to get isEnhancedCoverageApplicable from Project code status */
     this.subscriptions.push(this.appStoreService.getProjectCodeStatus$.subscribe((data: any) => {
      this.isFXImpl = data[`isFlexImpl`];
    }));
     this.loadContent();
  }

  /**
   * method to load the signal banner pop-up contents according to respective states
   */
  loadContent(): void {
    if (this.isFXImpl && this.landingStateCode !== DataConstants.STATE_CODES.ARIZONA
      && this.landingStateCode !== DataConstants.STATE_CODES.ILLINOIS) {
        this.signalPopUpHeader = DataConstants.SIGNAL_PROGRAM_LEARN_MORE_POPUP_TEXT.forFlexExceptAZAndILHeader;
        this.signalPopUParagraph = DataConstants.SIGNAL_PROGRAM_LEARN_MORE_POPUP_TEXT.forFlexExceptAZAndILParagraph;
        this.signalPopUPStep1 = DataConstants.SIGNAL_PROGRAM_LEARN_MORE_POPUP_TEXT.forFlexExceptAZAndILStep1;
      } else {
        this.signalPopUpHeader = DataConstants.SIGNAL_PROGRAM_LEARN_MORE_POPUP_TEXT.otherStatesHeader;
        this.signalPopUParagraph = DataConstants.SIGNAL_PROGRAM_LEARN_MORE_POPUP_TEXT.otherStatesParagraph;
        this.signalPopUPStep1 = DataConstants.SIGNAL_PROGRAM_LEARN_MORE_POPUP_TEXT.otherStatesStep1;
      }
  }

   /** * on destroy  */
   ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
  /**
   * Close Popup
   */
  closeModalPopup() {
    this.dialogRef.close();
  }
}
