import { Injectable, Renderer2, Inject, RendererFactory2, SecurityContext } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ServiceConstants } from '@ffq-app-shared/constants/service.constants';
import { DomSanitizer } from '@angular/platform-browser';
import { AppStoreService } from '@ffq-app-store/app.store.service';

@Injectable({
  providedIn: 'root'
})
export class LegacyRedirectionService {
  private renderer: Renderer2;
  constructor(
      rendererFactory: RendererFactory2,
      @Inject(DOCUMENT) private document,
      private domSanitizer: DomSanitizer,
      private appStoreService: AppStoreService
    ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  redirectToLegacy(fields) {
    const url = ServiceConstants.LEGACY_URL + ServiceConstants.LEGACY_URL_COMMON;
    const form = this.renderer.createElement('FORM');
    form.method = 'POST';
    form.style.display = 'none';
    document.body.appendChild(form);
    form.action = url;
    fields.forEach((fieldElement: { name: any; value: any; }) => {
      const input = this.renderer.createElement('INPUT');
      input.type = 'hidden';
      input.name = fieldElement.name;
      input.value = fieldElement.value;
      form.appendChild(input);
    });
    form.submit();
  }

  redirectToHQM(data, flow) {
    let sourceId;
    let sourceIndicator;

    this.appStoreService.getLandingSourceFromSrc$.subscribe(src => {
      sourceId = src ? src : 'FC000000001';
    }).unsubscribe();

    this.appStoreService.sourceIndicatorData$.subscribe(srcIndicator => {
      if (srcIndicator) {
        sourceIndicator = srcIndicator;
      } else {
        if (sourceId) {
          if (sourceId.substring(0, 3) === 'AMP') {
            sourceIndicator = 'AP';
          }
        }
      }
      if (!sourceIndicator) {
        sourceIndicator = sourceId.substring(0, 2);
      }
    }).unsubscribe();

    window.location.href = ServiceConstants.HQM_URL + '?Data=' + data + '&Flow=' + flow
      + '&SourceId=' + sourceId + '&SourceIndicator=' + sourceIndicator;
  }

  /**
   * Redirects to fwsor farmers choice
   * @param url : url for the farmers choice
   * @param gpcCode : gpc Code
   * @param landingSrcIndicator: landing Source Indicator
   * @param promoId: promo Id
   * @param campaignId: camapign Id
   */
  redirectToFWSorFarmersChoice(url: string, quoteNumber: string, landingSrcIndicator: string, promoId: string,
                               campaignId: string, experience: string, display: string, ffqLink: string): void {
      let redirectionUrl = quoteNumber === '' ? url.trim() + '?cid=' +
      campaignId + '&promoid=' + promoId + '&SourceID=' + landingSrcIndicator
      : url.trim() + '?QuoteNumber=' + quoteNumber + '&cid=' + campaignId +
      '&promoid=' + promoId + '&SourceID=' + landingSrcIndicator + '&experience='
      + experience;
      // US375781: Changes for sending query parameter to F.com
      if (display != null && ffqLink != null) {
        ffqLink = ffqLink.replace('?', '&');
        redirectionUrl = redirectionUrl  + '&display=' + display + '&link1=' + ffqLink;
      }
      // US222863: Changes for the adding session storage is redirected to static pages
      sessionStorage.setItem('MetLifeRedirection', redirectionUrl);
      this.doRedirectToFWSOrFarmersChoice(redirectionUrl);
  }

  redirectToFarmersGroup(url: string, quoteNumber: string, landingSrcIndicator: string, promoId: string,
                         campaignId: string, experience: string, zipcode?: string, gpcCode?: string): void {
let redirectionUrl = quoteNumber === '' ? url.trim() + gpcCode + '?cid=' +
campaignId + '&promoid=' + promoId + '&SourceID=' + landingSrcIndicator
: url.trim() + gpcCode + '?QuoteNumber=' + quoteNumber + '&cid=' + campaignId +
'&promoid=' + promoId + '&SourceID=' + landingSrcIndicator + '&experience='
+ experience;
if (zipcode) {
redirectionUrl = redirectionUrl  + '&zipcode=' + zipcode;
}
// US222863: Changes for the adding session storage is redirected to static pages
sessionStorage.setItem('MetLifeRedirection', redirectionUrl);
this.doRedirectToFWSOrFarmersChoice(redirectionUrl);
}

   doRedirectToFWSOrFarmersChoice(redirectionUrl: string): void {
    window.location.href = this.domSanitizer.sanitize(SecurityContext.URL, redirectionUrl);
  }

  /**
   * Redirects to farmers choice with gpc
   * @param url : url for the farmers choice
   * @param gpcCode : gpc Code
   * @param landingSrcIndicator: landing Source Indicator
   * @param promoId: promo Id
   */
  redirectToFarmersChoiceWithGPC(url: string, gpcCode: string, landingSrcIndicator: string, promoId: string,
                                 campaignId: string, experience: string): void {
    const redirectionUrl = url.trim() + gpcCode + '?cid=' + campaignId + '&promoid=' + promoId +
      '&SourceID=' + landingSrcIndicator + '&experience=' + experience;
    // US222863: Changes for the adding session storage is redirected to static pages
    sessionStorage.setItem('MetLifeRedirection', redirectionUrl);
    window.location.href = this.domSanitizer.sanitize(SecurityContext.URL, redirectionUrl);
  }

  redirectToOneUI(url: string): void {
    const redirectionUrl = url.trim() ;
    /** US489826:Changes for routing to oneUI */
    this.doRedirectToOneUIUrl(redirectionUrl);
  }

  doRedirectToOneUIUrl(redirectionUrl: string): void {
    window.location.href = ServiceConstants.ONE_UI_URL + redirectionUrl;
  }
}


