import { Component, OnInit } from '@angular/core';
import { MatProgressBar } from '@angular/material/progress-bar';

@Component({
  selector: 'app-auto-renters-progress',
  templateUrl: './auto-renters-progress.component.html',
  styleUrls: ['./auto-renters-progress.component.scss']
})
export class AutoRentersProgressComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
