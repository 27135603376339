import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

createScriptElement(environment.dynatrace_script_url);

function createScriptElement(src: any) {
  const scriptElement = document.createElement('script');
  scriptElement.src = src;
  document.head.appendChild(scriptElement);
}
