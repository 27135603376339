<label tabindex="0">
<div  class="ffq-lib__package-card" 
[ngClass]="params.active ===true ?'card-selected check-box-icon': 'card-unselected check-box-icon-unchecked'"
[attr.data-GTM]='params.gtmTag'>
<label role="heading" aria-level="2" [attr.aria-label]="params.active ===true ? 'Selected ' + params.packageName : 'Clickable ' + params.packageName "></label>
<h1  role="presentation" id="auto-quote-pckName"><span id="auto-quote_pckAmtSpan">
    <strong id="auto-quote_pckDlr">$</strong> 
        <strong id="auto-quote_pckAmt">{{ params.amountNumeric }}</strong>
        <strong id="auto-quote_pckAmtFraction">{{params.amountFraction}}</strong>
        <strong id="auto-quote_pckDura">/{{params.paymentChosen}}</strong></span></h1>
    <hr  role="presentation"/>

    <div aria-hidden="true" id="auto-quote_package-title">{{ params.packageName }}</div>

    <div  id="auto-quote_package-description"><p>{{ params.descriptionData }}</p></div>

</div>
</label>