import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, AfterViewInit, ElementRef, ViewChild} from '@angular/core';

@Component({
  selector: 'lib-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit, AfterViewInit {

  @Input() params: any;
  @Output() cardData: EventEmitter<any> = new EventEmitter();

  @ViewChild('edit', {static: true}) editButton: ElementRef;
  @ViewChild('prefill', {static: true}) prefillfocus: ElementRef;

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (this.editButton && this.editButton.nativeElement) {
    this.editButton.nativeElement.focus();
    } else if (this.params.id === 1) {
       this.prefillfocus.nativeElement.focus();
    }
}


  manageQuote(addedToQuote, action, id) {
    if (action !== 'edit') {
      action = addedToQuote ? 'delete' : 'add';
    }
    const result = {
      action,
      id,
    };
    this.cardData.emit( result );
  }

}
