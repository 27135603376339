import { Component, OnInit, Inject, Output, EventEmitter, HostListener, AfterViewInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Console } from 'console';
import { LibraryDataService } from '../../core/services/library-data.service';
import { DialogData } from '../info-dialog/info-dialog.component';



@Component({
  selector: 'lib-action-dialog',
  templateUrl: './action-dialog.component.html',
  // TODO Currently copied the styles of info dialogue to action dialogue.
  // Got to move this to a common/shared styles
  styleUrls: ['./action-dialog.component.scss'],
  providers: [MatDialog]
})
export class ActionDialogComponent implements OnInit {
  public dialogTitle: string;
  public dialogContent: string;
  public cancelButtonText = 'Cancel';
  public confirmButtonText = 'Change';
  public dialogRef: MatDialogRef<any>;
  public acceptDialogAction = 'Accept';
  public cancelDialogAction = 'Cancel';
  public cancelGTM: string;
  public confirmGTM: string;
  public color: string;


  constructor(public dialog: MatDialog, public libDataService: LibraryDataService) {
  }
  openDialog(modelData: any) {
    this.dialogTitle = modelData.modalHeader;
    this.dialogContent = modelData.modalBody;
    this.cancelButtonText = modelData.cancelButtonText || 'Cancel';
    this.confirmButtonText = modelData.confirmButtonText || 'Change';
    this.acceptDialogAction = modelData.acceptDialogAction || 'Accept';
    this.cancelDialogAction = modelData.cancelDialogAction || 'Cancel';
    this.cancelGTM = modelData.cancelGTM;
    this.confirmGTM = modelData.confirmGTM;
    this.color = modelData.color;

    // tslint:disable-next-line: no-use-before-declare
    this.dialogRef = this.dialog.open(ActionDialogContentComponent, {
      panelClass: ['ffq-action-dialog', 'popup-inprogress'],
      data: {
        dialogTitle: this.dialogTitle, dialogContent: this.dialogContent,
        cancelButtonText: this.cancelButtonText, confirmButtonText: this.confirmButtonText,
        acceptDialogAction: this.acceptDialogAction, cancelDialogAction: this.cancelDialogAction,
        cancelGTM: modelData.cancelGTM, confirmGTM: modelData.confirmGTM, color: this.color
      },
      disableClose: true
    });
    // dialogRef.afterClosed().subscribe(result => {
    //   this.dialogContent = result;
    // });
  }



  closeDialog(modelData: any): void {
    // const dialogRef = this.dialog.closeAll();
    if (this.dialogRef) {
      this.dialogRef.close(modelData);

    }

  }
  ngOnInit() {
  }
  dialogActionSelected(action: any): void {
    this.libDataService.performAction(action);
    // this.dialogRef.close();
  }

}


@Component({
  selector: 'lib-action-dialog',
  templateUrl: 'action-dialog.component.html',
  styleUrls: ['./action-dialog.component.scss'],

})
export class ActionDialogContentComponent implements OnInit, AfterViewInit {
  public dialogTitle: string;

  public dialogContent: string;
  public cancelButtonText = 'Cancel';
  public confirmButtonText = 'Change';
  public acceptDialogAction = 'Accept';
  public cancelDialogAction = 'Cancel';
  public cancelGTM: string;
  public confirmGTM: string;
  public color: string;
  constructor(
    public dialogRef: MatDialogRef<ActionDialogContentComponent>,
    public libDataService: LibraryDataService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.dialogTitle = data.dialogTitle;
    this.dialogContent = data.dialogContent;
    this.cancelButtonText = data.cancelButtonText;
    this.confirmButtonText = data.confirmButtonText;
    this.acceptDialogAction = data.acceptDialogAction;
    this.cancelDialogAction = data.cancelDialogAction;
    this.cancelGTM = data.cancelGTM;
    this.confirmGTM = data.confirmGTM;
    this.color = data.color;

  }
  // US286686 - blank pop up issue fix
  ngOnInit() {
  }

  ngAfterViewInit() {
    const popUpContainer = document.getElementsByClassName('popup-inprogress');
    if (popUpContainer && popUpContainer[0] !== undefined ) {
      popUpContainer[0].classList.remove('popup-inprogress');
    }
  }

  /**
   * Dialogs action selected
   * @param action action
   */
  dialogActionSelected(action: any): void {
    this.libDataService.performAction(action);
    // this.dialogRef.close();
  }

  /**
   * Determines whether no click on
   */
  onNoClick(): void {
    this.dialogRef.close();
  }
  @HostListener('window:keyup.esc') onkeyup() {
    this.dialogRef.close();
  }
}
