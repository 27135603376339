import * as fromAuto from './auto.state';
import {
  getQuotePackageSelected, getRedirectionFlow, getNavigationData, getCrossSellStatus
} from '@ffq-app-store/app.selectors';
/* Selectors */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FlowParamter } from '@ffq-app-shared/enums/flow.identifier.enum';

export const getAutoSelector = createFeatureSelector<fromAuto.AutoState>(
  'auto'
);

export const getAutoControlData = createSelector(
  getAutoSelector,
  state => state ? state.controlData : null
);

export const getPrefillServiceStatus = createSelector(
  getAutoControlData,
  controlData => {
    return controlData.prefillData ? controlData.prefillData : null;
  },
);

/** selector for Bristol west control data and brightline indicator */
export const getBrightLinedData = createSelector(
  getAutoControlData,
  state => {
    if (state) {
      const isEaiBrightLineInd =  state.isEaiBrightLineInd ? state.isEaiBrightLineInd : false;
      const isBrightLinedFromCreditCheck = state.isBrightLinedFromCreditCheck ? state.isBrightLinedFromCreditCheck : false;
      const brightlineCtrlData = {
        isBwPopupShown:  state.isBwPopupShown,
        isBrightlIned: (isEaiBrightLineInd || isBrightLinedFromCreditCheck),
        autoCompanyCode:  state.autoCompanyCode ? state.autoCompanyCode : null
      };
      return brightlineCtrlData;
    }
  }
);

// parmaters to decide to disbale PNI
export const getPNIDisableParameters = createSelector(
  getRedirectionFlow,
  getNavigationData,
  getCrossSellStatus,
  (redirectionFlow, navigationData, crossSellStatus) => {
    return {
      flowParam: redirectionFlow === FlowParamter.CROSS_SELL || crossSellStatus ? FlowParamter.CROSS_SELL : null,
      quoteNavigationStatus: (navigationData && navigationData.auto) ? navigationData.auto.quote : null,
    };
  },
);


export const getDiscountSave = createSelector(
  getAutoSelector,
  state => {
    return state && state.controlData && state.controlData.isDiscountSave ? state.controlData.isDiscountSave : null;
  },
);

export const getSignalDiscountSave = createSelector(
  getAutoSelector,
  state => {
    return state && state.controlData && state.controlData.isSignalDiscountSave ? state.controlData.isSignalDiscountSave : null;
  },
);

export const getQuoteSave = createSelector(
  getAutoSelector,
  state => {
    return state && state.controlData && state.controlData.isQuoteSave ? state.controlData.isQuoteSave : null;
  },
);

export const getDriverAssignSave = createSelector(
  getAutoSelector,
  state => {
    return state && state.controlData && state.controlData.isDriverAssignment ? state.controlData.isDriverAssignment : null;
  },
);

export const getRideShareSave = createSelector(
  getAutoSelector,
  state => {
    return state && state.controlData && state.controlData.isRideShare ? state.controlData.isRideShare : null;
  },
);

/** selector for getting policy start date  */
export const getPolicyStartDate = createSelector(
  getAutoSelector,
  state => {
    return state && state.data && state.data.discount && state.data.discount.policyStartDate ? state.data.discount.policyStartDate : null;
  }
);

/** selector for getting duration value  */
export const getPayPolicy = createSelector(
  getAutoSelector,
  state => {
    return state && state.data && state.data.discount && state.data.discount.payPolicy ? state.data.discount.payPolicy : null;
  }
);

/** selector for getting residence type */
export const getResidenceType = createSelector(
  getAutoSelector,
  state => {
    return state && state.data && state.data.discount && state.data.discount.residenceType ? state.data.discount.residenceType : null;
  }
);

export const getHomeOwnerDiscount = createSelector(
  getAutoSelector,
  state => {
    return state && state.data && state.data.quote && state.data.quote.isHomeOwnerDiscount ? state.data.quote.isHomeOwnerDiscount : false;
  }
);


/** selector for datas required in quote page */
export const getDataForRateQuote = createSelector(
  getPolicyStartDate,
  getResidenceType,
  getBrightLinedData,
  getPayPolicy,
  getHomeOwnerDiscount,
  (policyStartDate, residenceType, brightlineCtrlData, payPolicy, isHomeOwner) => {
    const data = {
      policyStartDate,
      residenceType: residenceType ? residenceType : '',
      isBrightlIned: brightlineCtrlData && brightlineCtrlData.isBrightlIned ? brightlineCtrlData.isBrightlIned : false,
      isBwPopupShown: brightlineCtrlData && brightlineCtrlData.isBwPopupShown ? brightlineCtrlData.isBwPopupShown : false,
      autoCompanyCode: brightlineCtrlData && brightlineCtrlData.autoCompanyCode ? brightlineCtrlData.autoCompanyCode : null,
      payPolicy: payPolicy ? payPolicy : '',
      isHomeOwner: isHomeOwner ? isHomeOwner : false,
    };
    return data;
  }
);




/** selector for eligibleForChatBot */
export const getEligibleForChatBot = createSelector(
  getAutoSelector,
  state => {
    return state && state.data && state.data.quote && state.data.quote.eligibleForChat ?
      state.data.quote.eligibleForChat : false;
  }
);

/**
 * selector for discounts
 */
export const getQuoteDiscount = createSelector(
  getAutoSelector,
  state => {
    return state && state.data && state.data.quote && state.data.quote.discount ? state.data.quote.discount : null;
  }
);

export const getQuoteData = createSelector(
  getAutoSelector,
  state => {
    return state && state.data && state.data.quote ? state.data.quote : null;
  },
);

export const getKnockOutResponse = createSelector(
  getAutoSelector,
  state => {
    return state && state.data && state.data.knockout ? state.data.knockout : null;
  },
);


export const getSaveActionResponse = createSelector(
  getAutoSelector,
  state => {
    return state && state.controlData && state.controlData.NCSubscription ? state.controlData.NCSubscription : null;
  },
);

/**
 * selector for liability coverage
 */
export const getQuoteLiabilityCov = createSelector(
  getAutoSelector, getQuotePackageSelected,
  (state, packageSelected) => {
    const coverages = {};
    let coverPack = {};
    if (state && state.data && state.data.quote && state.data.quote.liabilityCoverage) {
      switch (packageSelected) {
        case 'B': coverPack = state.data.quote.liabilityCoverage.basicCoverages; break;
        case 'E': coverPack = state.data.quote.liabilityCoverage.enhancedCoverages; break;
        case 'F': coverPack = state.data.quote.liabilityCoverage.fullCoverages; break;
        case 'C': coverPack = state.data.quote.liabilityCoverage.customCoverages; break;
        default: coverPack = null; break;
      }
      coverages[`packageSelected`] = packageSelected;
      coverages[`coverages`] = coverPack;
    }
    return coverages;
  }
);

/**
 * selector for vehicle coverage
 */
export const getVehicleCoverageData = createSelector(
  getAutoSelector, getQuotePackageSelected,
  (state, packageSelected) => {
    const vehicleArrayObject = [];
    const vehicleObject = state && state.data && state.data.quote && state.data.quote.vehicle ? state.data.quote.vehicle : null;
    if (vehicleObject) {
      vehicleObject.forEach(vehicle => {
        const data = {surchargeBI: '', surchargePD: ''};
        data[`label`] = vehicle.year + ' ' + vehicle.makeDesc + ' ' + vehicle.modelDesc;
        let coverPack = {};
        switch (packageSelected) {
          case 'B': coverPack = vehicle.persAutoCoverage.basicCoverages; break;
          case 'E': coverPack = vehicle.persAutoCoverage.enhancedCoverages; break;
          case 'F': coverPack = vehicle.persAutoCoverage.fullCoverages; break;
          case 'C': coverPack = vehicle.persAutoCoverage.customCoverages; break;
          default: coverPack = null; break;
        }
        data[`coverages`] = coverPack;
        data[`packageSelected`] = packageSelected;
        data[`vehicleRef`] = vehicle.reference;
        data[`vehicleVin`] = vehicle.maskedVin;
        if (vehicle.physicalDamageIndicator) {
          data[`physicalDamageIndicator`] = vehicle.physicalDamageIndicator;
        }
        data.surchargeBI = vehicle.surchargeBI;
        data.surchargePD = vehicle.surchargePD;
        vehicleArrayObject.push(data);
      });
    }
    return vehicleArrayObject;
  }
);
// export const getAssignAgentStatus = createSelector(
//   getAppSelector,
//   state => {
//     return state.controlData.assignAgent;
//   },
// );


/*
export const assignAgentControlData = (state: fromAppState.AppState) => state.controlData.assignAgent;
export const preFillServiceControlData = (state: fromAuto.AutoState) => state.controlData.prefillData;
export const getAgentAndPrefillServiceControlData = createSelector(
  assignAgentControlData,
  preFillServiceControlData,
  // tslint:disable-next-line:no-shadowed-variable
  (assignAgentControlData, preFillServiceControlData) => {
    const isAssignAgentServiceCompleted = assignAgentControlData ? true : false;
    const isPrefillServiceCompleted = preFillServiceControlData ? true : false;
    if (isAssignAgentServiceCompleted && isPrefillServiceCompleted) {
      return true;
    }
  }
);*/
/** selector for getQuoteRateData */
export const getQuoteRateData = createSelector(
  getAutoSelector,
  state => {
    return state && state.data && state.data.quote && state.data.quote ?
      state.data.quote : false;
  }
);

/** selector for getCustomRateInfoMsgs from quote store */
export const getCustomRateInfoMsgs = createSelector(
  getAutoSelector,
  state => state && state.data && state.data.quote.infoMessages && state.data.quote.infoMessages ?  state.data.quote.infoMessages : null
);

/**
 *  Selectors for Vehicles list
 */

/** list of all vehicles added to quote */
export const getVehicles = createSelector(
  getAutoSelector,
  state => {
    return state && state.data && state.data.vehicles ? state.data.vehicles : [];
  },
);

/** To fetch the short pull data for the Driver summary page to display the Prefill Drivers List */
export const getPrefillVehicleData = createSelector(
  getAutoSelector,
  state => {
    return (state.data.prefillData.vehicle) ? state.data.prefillData.vehicle : [];
  }
);

/** to retrieve vinlook up response */
export const getAppVinLookUpVehicle = createSelector(
  getAutoSelector,
  state => {
    return state && state.data && state.data.vinLookupVehicle ? state.data.vinLookupVehicle : null;
  },
);

/** to retrieve validate license response */
export const getValidateLicense = createSelector(
  getAutoSelector,
  state => {
    return state && state.data && state.data.validateLicense ? state.data.validateLicense : false;
  },
);

/** to retrieve discount data */
export const getDiscountData = createSelector(
  getAutoSelector,
  state => {
    return state && state.data && state.data.discount ? state.data.discount : null;
  },
);

/** to retrieve discount data */
export const getRc1PremiumAmt = createSelector(
  getAutoControlData,
  state => {
    return state  && state.rc1PremiumAmt ? state.rc1PremiumAmt : null;
  },
);

/** selector for error */
export const getAutoErrors = createSelector(
  getAutoSelector,
  state => {
    return state ? state.error : null;
  },
);

/**
 *  Selectors for DRIVER page
 */
// To fetch the short pull data for the Driver summary page to display the Prefill Drivers List
export const getPrefillDriversData = createSelector(
  getAutoSelector,
  state => {
    if (state) {
    const driversArray = state.data.prefillData.driver;
    const basicData = driversArray.filter(value => {
      if (value && value.pni !== 'H') {
        return {
          firstName: value.firstName,
          lastName: value.lastName,
          dateOfBirth: value.dateOfBirth,
          pni: value.pni,
          reference: value.reference
        };
      }
    });
    return basicData;
  }
}
);

// To fetch the Prefill driver data selected on the load of add Modal window , if  NOT driver selected from the Already selected Drivers.
export const getSelectedPrefillDriver = createSelector(
  getAutoSelector,
  (state) => {
    const driversArray = state.data.prefillData.driver;
    const driverSelectedIndex = state.controlData.selectedPrefDrv;
    return driversArray.filter((driver) => {
      // return parseInt(driver.reference, 10) === driverSelectedIndex;
      return driver.reference === driverSelectedIndex;
    })[0];
  }
);

// To fetch the  data for the Driver summary page to display the  Drivers List
// export const getDriverData - Define this.
export const getDriverData = createSelector(
  getAutoSelector,
  state => {
    const driversArray = state && state.data && state.data.drivers ? state.data.drivers : [];
    const basicData = driversArray.filter(value => {
      return {
        firstName: value.firstName,
        lastName: value.lastName,
        dateOfBirth: value.dateOfBirth,
        pni: value.pni,
        reference: value.reference,
        gender: value.gender,
      };
    });
    return basicData;
  }
);

// To fetch the  driver data selected on the load of add Modal window , if the driver selected from Already Added drivers
// export const getSelectedDriverData
export const getSelectedDriverData = createSelector(
  getAutoSelector,
  (state) => {
    const driversArray = state.data.drivers;
    const driverSelectedIndex = state.controlData.selectedDrv;
    return driversArray.filter((driver) => {
      // return parseInt(driver.reference, 10) === driverSelectedIndex;
      return driver.reference === driverSelectedIndex;
    })[0];

  }
);

// To fetch the  driver details for a reference number
export const getDriverDataWithRef = createSelector(
  getAutoSelector,
  (state, param) => {
    if (state && state.data && state.data.drivers) {
      const driversArray = state.data.drivers;
      return driversArray.filter((driver) => driver.reference === param.drivRef)[0];
    }
  }
);

// To fetch pni from Prefill data
export const getPrefillPniDriverData = createSelector(
  getAutoSelector,
  state => {
    return state.data.prefillData.driver.filter((driver) => {
      return driver.pni === 'H';
    })[0];
  }
);

// To fetch currinsured answer from driver store
export const getDriverCurrInsuredAnswer = createSelector(
  getAutoSelector,
  state => {
    return state.controlData.currentInsuredAnswer;
  }
);

// To fetch currentInsuredAnswer from Prefill data
export const getPrefillCurrInsuredAnswer = createSelector(
  getAutoSelector,
  state => {
    return state.data.prefillData.currentInsuredAnswer;
  }
);

// To fetch ydexist from Prefill data
export const getPrefillYdExists = createSelector(
  getAutoSelector,
  state => {
    return state?.data?.prefillData?.ydexist;
  }
);

// To fetch premium amount from auto
export const getPaymentDetails = createSelector(
  getAutoSelector,
  state => {
      const paymentDetails = {
        premiumCalculated: state.controlData.premiumCalculated,
          paymentMode: state.controlData.paymentMode,
        };
      return paymentDetails;
   // return state.controlData.premiumCalculated;
      }
);

export const getServiceControlData = createSelector(
  getAutoSelector,
  state => {
    return state && state.serviceControlData ? state.serviceControlData : null;
  }
);

// Get vechicle rideshare and vechile ownership to be passed as paramters in
// fetchdropdown coverage service call in quote page
export const getVehicleRideShare = createSelector(
  getVehicles,
  (vehicles) => {
    if (vehicles.length > 0) {
      let leasedVehFound = false;
      let rideShareVehFound = false;
      let isVechicleOn = 'O';
      vehicles.forEach(vehicle => {

        if (!rideShareVehFound && vehicle.rideShareVeh && vehicle.rideShareVeh === 'Y') {
          rideShareVehFound = true;
        }
        if (vehicle.vehicleIsOn === 'LR') {
          isVechicleOn = 'L';
          leasedVehFound = true;

        } else if (vehicle.vehicleIsOn === 'LN' && !leasedVehFound) {
          isVechicleOn = 'F';
        }
      });
      return { isVechicleOn, rideShareVehFound };
    }
    return null;
  }
);

/** selector for get SaveVehcleList service status */
export const getSaveVehcleListServiceStatus = createSelector(
getAutoSelector,
  state => {
    return state && state.serviceControlData && state.serviceControlData.saveVehicleList ? state.serviceControlData.saveVehicleList : null;
  },
);

export const getDriverList = createSelector(
  getAutoSelector,
  state => {
    return state && state.data && state.data.drivers ? state.data.drivers : [];
  },
);
// Get rideshare info about vehicle
const getVehRideShareData = createSelector(
  getVehicles,
  (vehicles) => {
    const vehiRideShareData = vehicles.filter(value => {
      return {
        reference: value.reference,
        rideShareVeh: value.rideShareVeh,
      };
    });
    return vehiRideShareData;
  });

// Get rideshare info about driver
const getDrivRideShareData = createSelector(
  getAutoSelector,
  (state) => {
    const driverArray = state && state.data && state.data.drivers ? state.data.drivers : [];
    const drvRideShareData = driverArray.filter(value => {
      return {
        reference: value.reference,
        rideShareDrv: value.rideShareDrv,
      };
    });
    return drvRideShareData;
  });

export const getRideShareData = createSelector(
  getVehRideShareData,
  getDrivRideShareData,
  (vehRideShare, drivRideShare) => {
    let isRideShare = 'N';
    const vehSelected = [];
    const drivSelected = [];
    vehRideShare.forEach(vehicle => {
      if (vehicle.rideShareVeh === 'Y') {
        isRideShare = 'Y';
        vehSelected.push(vehicle.reference);
      }
    });

    drivRideShare.forEach(driver => {
      if (driver.rideShareDrv === 'Y') {
        isRideShare = 'Y';
        drivSelected.push(driver.reference);
      }
    });

    return { isRideShare, vehSelected, drivSelected };
  }
);

/** selector for checking whether vehicle list has any other model vehicle */
export const hasOtherModelVehicles = createSelector(
  getVehicles,
  (vehicles) => {
  const otherMOdelVehicleList = vehicles.filter(vehElement => vehElement.modelDesc === 'OTHER');
  const check = otherMOdelVehicleList.length > 0;
  return check;
  });

/* selector for getting the eleLinkFlag value from auto control data */
export const getEleLinkFlag = createSelector(
  getAutoSelector,
  state => {
    return state && state.controlData && state.controlData.eleLinkFlag ? state.controlData.eleLinkFlag : null;
  });

/* selector for getting the eleLinkFlag value from auto control data */
export const isELEEnabled = createSelector(
  getAutoSelector,
  state => {
    return state && state.controlData && state.controlData.isELEEnabled !== false;
  });

/** selector for getting  isCoverageUpdated from auto control data */
export const getSecondRateCallStatus = createSelector(
  getAutoSelector,
  state => state && state.controlData && state.controlData.secondRateCallCompleted ? state.controlData.secondRateCallCompleted : false
);

/**
 * US501061 && US501062: CA FFQ Digital Monetization - CA State TFN Replacement
 * for Agent, Quote, Thank you and print quote pages for desktop and mobile windows
 */
export const getBaseRateCallStatus = createSelector(
  getAutoSelector,
  state => state && state.controlData && state.controlData.baseRateCallCompleted ? state.controlData.baseRateCallCompleted : false
);

export const getEAAgentStatus = createSelector(
  getAutoSelector,
  state => state?.controlData?.getEAAgentStatus || false
);

/** selector for getting the value of eligible for auto-renters bundle */
export const getEligibleForAutoRentersBundle = createSelector(
  getAutoSelector,
  state => {
    return state && state.controlData && state.controlData.eligibleForAutoRentersBundle;
  });

/** selector for getting the renters monthly premium amount */
export const getRentersMonthlyPremiumAmt = createSelector(
  getAutoSelector,
  state => {
    return (state && state.data && state.data.rentersRate &&  state.data.rentersRate.rentersMonthlyPremiumAmt) ?
     state.data.rentersRate.rentersMonthlyPremiumAmt : 0;
  });

/** selector for getting status of rentersRate service */
export const getRentersRateServiceStatus = createSelector(
  getAutoSelector,
  state => {
    return state && state.serviceControlData && state.serviceControlData.rentersRate ?
    state.serviceControlData.rentersRate : null;
  });

export const getPerformRateStatus = createSelector(
  getAutoSelector,
  state =>  state && state.controlData && state.controlData.secondPerformRate ? state.controlData.secondPerformRate : null
);
/** selector for getting the value of errorCount */
export const getErrorCount = createSelector(
  getAutoSelector,
  state => {
    return state && state.controlData && state.controlData.errorCount ? state.controlData.errorCount : 0;
  },
);

// To fetch pni from Prefill data
export const getPniDriverData = createSelector(
  getAutoSelector,
  state => {
    return state.data.drivers.filter((driver) => {
      return driver.pni === 'H';
    })[0];
  }
);
/**
 *  Selectors for DRIVER page
 */
// To fetch all data for the AdditionalInfo page to display the Prefill Drivers List
export const getPrefillDrivers = createSelector(
  getAutoSelector,
  state => {
    if (state) {
      const driversArray = state.data.prefillData.driver;
      const basicData = driversArray.filter(value => {
        if (value) {
          return {
            firstName: value.firstName,
            lastName: value.lastName,
            dateOfBirth: value.dateOfBirth,
            pni: value.pni,
            reference: value.reference,
            policyStartDate: value.policyStartDate
          };
        }
      });
      return basicData;
    }
  }
);

/** to retrieve excluded driver response */
export const getExcludedDriverSaveStatus = createSelector(
  getAutoSelector,
  state => {
    return state.serviceControlData && state.serviceControlData.saveExcludedDriver ? state.serviceControlData.saveExcludedDriver : null;
  },
);

/** to retrieve AdpfExcludeDriverData from retrieve quote */
export const getAdpfExcludeDriverData = createSelector(getAutoSelector, state => {
  return state.data && state.data.adpfExcludedDriverData && state.data.adpfExcludedDriverData.length ?
    state.data.adpfExcludedDriverData : [];
});
/** to get Prequalified discounts response */
export const getPrequalifiedDiscounts = createSelector(
  getAutoSelector,
  state => {
    return state.data && state.data.prequalifiedDiscounts ? state.data.prequalifiedDiscounts : null;
  },
);

// To fetch saved carrier info from store
export const getSavedCarrierInfo = createSelector(
  getAutoSelector,
  state => {
    return state.controlData && state.controlData.carrierInfoSaved;
  }
);

/**
 * To get dnq messages
 */
export const getDNQMessages = createSelector(
  getAutoSelector,
  state => {
    return state && state.data && state.data.quote && state.data.quote.dnqMessages ? state.data.quote.dnqMessages : [];
  },
);