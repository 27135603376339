<mat-accordion>
  <ng-container *ngIf="!showNCPhyDamageIndDialogflag">
  <mat-expansion-panel class="expansion" expanded="true" [attr.data-GTM]="gtmTag" #covPanel>
    <mat-expansion-panel-header ngClass="{{params.coverageLabel == 'Liability coverage'?' mat-expansion-panel-header-liability ':' mat-expansion-panel-header '}}">
      <mat-panel-title>

        <div class="ffq-coverage-panel-holder">

          <div class="ffq-coverage-panel-coverage-heading" role="heading" aria-level="3">{{params.coverageLabel}}</div>
          <div class="ffq-coverage-amnt-wrapper">
          <span ngClass="ffq-coverage-amnt-header {{strikeOut?' auto-quote-amount-strike ': ' '}}">
            <label attr.aria-label="{{params.coverageAmount  | currency:'USD':true:'1.2'}} coverage amount">
            <div aria-hidden="true" class="ffq-coverage-panel-coverage-amount"><span *ngIf="params.isCustomQuoteImpl == false">(</span>
              {{params.coverageAmount  | currency:'USD':true:'1.2'}}<span *ngIf="params.isCustomQuoteImpl == false">)</span></div>
          </label>
          </span>
          <span *ngIf="params.isCustomQuoteImpl == true" class="ffq-coverage-amnt-header cov-subtoatl-txt">(Subtotal)</span>
        </div>
        </div>
      </mat-panel-title>

    </mat-expansion-panel-header>


    <div class="coverage-panel-content" >
    <ng-container *ngFor="let coverageItem of params.coverageItems">
      <div *ngIf="!coverageItem.cvgToDisplay" class="ffq-coverage-panel-coverage-holder">
        <div class="coverage-name-amnt-wrapper">
        <div class="ffq-coverage-panel-coverage-item-label {{coverageItem.hasError?' coverage-error-label ':'  '}}">{{coverageItem.itemLabel}}</div>
         <div *ngIf="params.isCustomQuoteImpl == true" class="subtotatl-new-wrapper">
            <ng-container *ngIf="coverageItem.covgAmountStrikeOut===true else elseReloadIcon">
              <span ngClass="ffq-coverage-amnt-header {{strikeOut?' auto-quote-amount-strike ': ' '}}">
                <span attr.aria-hidden="coverageItem.covgAmountStrikeOut" attr.aria-label="${{coverageItem.subTotalAmount}}" class="ffq-coverage-panel-coverage-subtotal-amount"
                *ngIf="!coverageItem.helpAmountToHide && !coverageItem.showStringvalue">{{coverageItem.subTotalAmount  | currency:'USD':true:'1.2' }}</span>
                <span attr.aria-hidden="coverageItem.covgAmountStrikeOut" attr.aria-label="${{coverageItem.subTotalAmount}}" class="ffq-coverage-panel-coverage-subtotal-amount"
                *ngIf="coverageItem.showStringvalue">{{coverageItem.subTotalAmount}}</span>
              </span>
            </ng-container>
            <ng-template #elseReloadIcon>
              <span attr.aria-hidden="coverageItem.covgAmountStrikeOut" attr.aria-label="${{coverageItem.subTotalAmount}}" class="ffq-coverage-panel-coverage-subtotal-amount"
                *ngIf="!coverageItem.helpAmountToHide && !coverageItem.showStringvalue">{{coverageItem.subTotalAmount  | currency:'USD':true:'1.2' }}</span>
              <span attr.aria-hidden="coverageItem.covgAmountStrikeOut" attr.aria-label="${{coverageItem.subTotalAmount}}" class="ffq-coverage-panel-coverage-subtotal-amount"
                *ngIf="coverageItem.showStringvalue">{{coverageItem.subTotalAmount}}</span>
            </ng-template>
          </div></div>
           <!--GW3 changes - US154473 GW Rollout 3 AUTO : MN state PIP requirements for Waiver of Work Loss coverage - start-->
          <!--Coverage Item sublabel-->
          <div *ngIf="coverageItem.itemSubLabel" class="coverage-sub-label">{{coverageItem.itemSubLabel}}</div>
           <!--GW3 changes - US154473 GW Rollout 3 AUTO : MN state PIP requirements for Waiver of Work Loss coverage - end-->
        <div  *ngIf="params.isCustomQuoteImpl == false" class="info-icon-wrapper">
          <!--US182860 - ADA changes for BW FL coverage start-->
            <a role="button"  tabindex="0" [attr.aria-label] ="coverageItem.itemLabel +' info icon opens a popup/ modal'" *ngIf="!coverageItem.helpToHide" class="info-icon"  (keydown.enter)='showDialogInfo(coverageItem.infoIconText)' (click)='showDialogInfo(coverageItem.infoIconText)'
              ></a>
           <!--US182860 - ADA changes for BW FL coverage end-->
        </div>
        <div>
          <div class="ffq-coverage-panel-input-holder">
            <div *ngIf="params.isCustomQuoteImpl == true && (coverageItem.permissiveCoverge === undefined || coverageItem.permissiveCoverge === false); else dropDownStaticView" class="ffq-coverage-items-dropdown" attr.role="{{coverageItem.hasError?'alert' : ''}}" attr.aria-describedby="error_{{coverageItem.cvgCode.replace(' ', '_')}}">
              <lib-select [hasCoverageError]="coverageItem.hasError" [params]="coverageItem.coverageItemsDropdown" ></lib-select>
            </div>


            <div  *ngIf="params.isCustomQuoteImpl == true" class="info-icon-wrapper-custom">
              <!--US182860 - ADA changes for BW FL coverage start-->
                <a role="button" tabindex="0" [attr.aria-label] ="coverageItem.itemLabel +' info icon opens a popup/ modal'" *ngIf="!coverageItem.helpToHide" class="info-icon"  (keydown.enter)='showDialogInfo(coverageItem.infoIconText)' (click)='showDialogInfo(coverageItem.infoIconText)'
                  ></a>
              <!--US182860 - ADA changes for BW FL coverage end-->
            </div>
            
            <ng-template #dropDownStaticView>
                <div *ngIf="params.isCustomQuoteImpl === false && (coverageItem.permissiveCoverge === undefined || coverageItem.permissiveCoverge === false);" class="ffq-coverage-items-dropdown"> {{getValueForDisplayCode(coverageItem)}}</div>
                <div *ngIf="coverageItem.permissiveCoverge === true;" class="ffq-coverage-items-dropdown">{{coverageItem.permissiveCovValue}}</div>
            </ng-template>

          </div>
          <ng-container *ngIf="params.isCustomQuoteImpl == true">
          <br role="presentation" />
          <br role="presentation"/>
        </ng-container>

          <div class="ffq-coverage-panel-coverage-subtotal-holder">
            <div *ngIf="params.isCustomQuoteImpl == false">
            <label [attr.aria-label]="coverageItem.covgAmountStrikeOut==true ? 'Subtotal please click  on Recalculate button at the top of the page' : coverageItem.itemLabel +'coverage Subtotal:'">
            <div aria-hidden="true" *ngIf="!coverageItem.helpAmountToHide" class="ffq-coverage-panel-coverage-subtotal-label">Subtotal:
            </div>
          </label>
            <ng-container *ngIf="coverageItem.covgAmountStrikeOut===true else elseReloadIcon">
              <span ngClass="ffq-coverage-amnt-header {{strikeOut?' auto-quote-amount-strike ': ' '}}">
                <span attr.aria-hidden="coverageItem.covgAmountStrikeOut" attr.aria-label="${{coverageItem.subTotalAmount}}" class="ffq-coverage-panel-coverage-subtotal-amount"
                *ngIf="!coverageItem.helpAmountToHide && !coverageItem.showStringvalue">{{coverageItem.subTotalAmount  | currency:'USD':true:'1.2' }}</span>
                <span attr.aria-hidden="coverageItem.covgAmountStrikeOut" attr.aria-label="${{coverageItem.subTotalAmount}}" class="ffq-coverage-panel-coverage-subtotal-amount"
                *ngIf="coverageItem.showStringvalue">{{coverageItem.subTotalAmount}}</span>
              </span>
            </ng-container>

            <ng-template #elseReloadIcon>
              <span attr.aria-hidden="coverageItem.covgAmountStrikeOut" attr.aria-label="${{coverageItem.subTotalAmount}}" class="ffq-coverage-panel-coverage-subtotal-amount"
                *ngIf="!coverageItem.helpAmountToHide && !coverageItem.showStringvalue">{{coverageItem.subTotalAmount  | currency:'USD':true:'1.2' }}</span>

              <span attr.aria-hidden="coverageItem.covgAmountStrikeOut" attr.aria-label="${{coverageItem.subTotalAmount}}" class="ffq-coverage-panel-coverage-subtotal-amount"
                *ngIf="coverageItem.showStringvalue">{{coverageItem.subTotalAmount}}</span>

            </ng-template>
          </div>
            <mat-error>
              <ng-container *ngIf="coverageItem.hasError">
                <div class="ffq-coverage-error-msg" id="error_{{coverageItem.cvgCode.replace(' ', '_')}}">
                {{coverageItem.errorMessage}}
                </div>
              </ng-container>
          </mat-error>
          </div>
        </div>
      </div>
      <div *ngIf="coverageItem.cvgToDisplay && coverageItem.cvgCode == umbiStacking && params.stateCode == statePA" class = "ffq-coverage-panel-coverage-holder"></div>
  </ng-container>


<ng-container *ngIf="params.stateCode=='MD'" >
  <div class="md-coverage-header-wrapper">
  <span class="md-coverage-header">Uninsured vs Underinsured</span>
  <p class="md-coverage-desc">Coverage for damage due to at-fault drivers without insurance or without sufficient insurance.</p>
  <div *ngIf="params.isCustomQuoteImpl == true" class="md-coverage-select">
    <lib-select [params]="params.mdCoverages"></lib-select>
  </div>
 </div>
    <ng-container *ngFor="let coverageItem of params.insuredUninsuredCoverages">
      <div *ngIf="!coverageItem.cvgToDisplay && mdCoverageControl.value == coverageItem.mdCoverageValue" class="ffq-coverage-panel-coverage-holder">
        <div class="coverage-name-amnt-wrapper"> <div class="ffq-coverage-panel-coverage-item-label {{coverageItem.hasError?' coverage-error-label ':'  '}}">{{coverageItem.itemLabel}}</div>
          <div *ngIf="params.isCustomQuoteImpl == true" class="subtotatl-new-wrapper">
            <ng-container *ngIf="coverageItem.covgAmountStrikeOut===true else elseReloadIcon">
              <span ngClass="ffq-coverage-amnt-header {{strikeOut?' auto-quote-amount-strike ': ' '}}">
                <span attr.aria-hidden="coverageItem.covgAmountStrikeOut" attr.aria-label="${{coverageItem.subTotalAmount}}" class="ffq-coverage-panel-coverage-subtotal-amount"
                *ngIf="!coverageItem.helpAmountToHide && !coverageItem.showStringvalue">{{coverageItem.subTotalAmount  | currency:'USD':true:'1.2' }}</span>
                <span attr.aria-hidden="coverageItem.covgAmountStrikeOut" attr.aria-label="${{coverageItem.subTotalAmount}}" class="ffq-coverage-panel-coverage-subtotal-amount"
                *ngIf="coverageItem.showStringvalue">{{coverageItem.subTotalAmount}}</span>
              </span>
            </ng-container>
            <ng-template #elseReloadIcon>
              <span attr.aria-hidden="coverageItem.covgAmountStrikeOut" attr.aria-label="${{coverageItem.subTotalAmount}}" class="ffq-coverage-panel-coverage-subtotal-amount"
                *ngIf="!coverageItem.helpAmountToHide && !coverageItem.showStringvalue">{{coverageItem.subTotalAmount  | currency:'USD':true:'1.2' }}</span>
              <span attr.aria-hidden="coverageItem.covgAmountStrikeOut" attr.aria-label="${{coverageItem.subTotalAmount}}" class="ffq-coverage-panel-coverage-subtotal-amount"
                *ngIf="coverageItem.showStringvalue">{{coverageItem.subTotalAmount}}</span>
            </ng-template>
          </div></div>
          <div *ngIf="params.isCustomQuoteImpl == false" class="info-icon-wrapper">
            <a *ngIf="!coverageItem.helpToHide" class="info-icon" aria-hidden="true" (keydown.enter)='showDialogInfo(coverageItem.infoIconText)' (click)='showDialogInfo(coverageItem.infoIconText)'
              title="{{(coverageItem.infoIconText.modalHeader ? coverageItem.infoIconText.modalHeader : coverageItem.infoIconText)}}" role="button"  attr.aria-label ="{{coverageItem.itemLabel}} info icon opens a popup/ modal" tabindex="0"></a>
            </div>

        <div class="ffq-coverage-panel-input-holder">

          <div *ngIf="params.isCustomQuoteImpl == true else dropDownStaticViewMD" class="ffq-coverage-items-dropdown" attr.role="{{coverageItem.hasError?'alert' : ''}}" attr.aria-describedby="error_{{coverageItem.cvgCode.replace(' ', '_')}}">
              <lib-select [hasCoverageError]="coverageItem.hasError" [params]="coverageItem.coverageItemsDropdown"></lib-select>
            </div>

              <div  *ngIf="params.isCustomQuoteImpl == true" class="info-icon-wrapper-custom">
                  <a aria-hidden="true" *ngIf="!coverageItem.helpToHide" class="info-icon"  (keydown.enter)='showDialogInfo(coverageItem.infoIconText)' (click)='showDialogInfo(coverageItem.infoIconText)'
                    title="{{(coverageItem.infoIconText.modalHeader ? coverageItem.infoIconText.modalHeader : coverageItem.infoIconText)}}" role="button"  attr.aria-label ="{{coverageItem.itemLabel}} info icon opens a popup/ modal" tabindex="0"></a>
              </div>
            <ng-template #dropDownStaticViewMD>
                <div class="ffq-coverage-items-dropdown"> {{getValueForDisplayCode(coverageItem)}}</div>
            </ng-template>

        </div>

        <ng-container *ngIf="params.isCustomQuoteImpl == true">
          <br role="presentation" />
          <br role="presentation"/>
        </ng-container>

            <div class="ffq-coverage-panel-coverage-subtotal-holder">
              <div *ngIf="params.isCustomQuoteImpl == false">
              <label [attr.aria-label]="coverageItem.covgAmountStrikeOut==true ? 'Subtotal please click  on Recalculate button at the top of the page' : coverageItem.itemLabel +'coverage Subtotal:'">
              <div *ngIf="!coverageItem.helpAmountToHide" class="ffq-coverage-panel-coverage-subtotal-label">Subtotal:
              </div>
              </label>
              <ng-container *ngIf="coverageItem.covgAmountStrikeOut===true else elseReloadIcon">
                <span ngClass="ffq-coverage-amnt-header {{strikeOut?' auto-quote-amount-strike ': ' '}}">
                  <span attr.aria-hidden="coverageItem.covgAmountStrikeOut" attr.aria-label="${{coverageItem.subTotalAmount}}" class="ffq-coverage-panel-coverage-subtotal-amount"
                  *ngIf="!coverageItem.helpAmountToHide && !coverageItem.showStringvalue">{{coverageItem.subTotalAmount  | currency:'USD':true:'1.2' }}</span>
                  <span attr.aria-hidden="coverageItem.covgAmountStrikeOut" attr.aria-label="${{coverageItem.subTotalAmount}}" class="ffq-coverage-panel-coverage-subtotal-amount"
                  *ngIf="coverageItem.showStringvalue">{{coverageItem.subTotalAmount}}</span>
                </span>
              </ng-container>

              <ng-template #elseReloadIcon>
                <span attr.aria-hidden="coverageItem.covgAmountStrikeOut" attr.aria-label="${{coverageItem.subTotalAmount}}" class="ffq-coverage-panel-coverage-subtotal-amount"
                  *ngIf="!coverageItem.helpAmountToHide && !coverageItem.showStringvalue">{{coverageItem.subTotalAmount  | currency:'USD':true:'1.2' }}</span>

                <span attr.aria-hidden="coverageItem.covgAmountStrikeOut" attr.aria-label="${{coverageItem.subTotalAmount}}" class="ffq-coverage-panel-coverage-subtotal-amount"
                  *ngIf="coverageItem.showStringvalue">{{coverageItem.subTotalAmount}}</span>


              </ng-template>
            </div>
              <mat-error >
                <ng-container *ngIf="coverageItem.hasError">
                  <div class="ffq-coverage-error-msg" id="error_{{coverageItem.cvgCode.replace(' ', '_')}}">
                  {{coverageItem.errorMessage}}
                  </div>
                </ng-container>
            </mat-error>
            </div>
        </div>
    </ng-container>
  </ng-container>
  </div>
  </mat-expansion-panel>
  </ng-container>
<ng-container *ngIf="showNCPhyDamageIndDialogflag">
  <mat-expansion-panel class="expansion" expanded="true" [attr.data-GTM]="gtmTag" #covPanel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="ffq-coverage-panel-holder">
          <div class="ffq-coverage-panel-coverage-heading phy-damage-head" role="heading" aria-level="3">
            {{params.coverageLabel}}
          </div>
          <div class="amber_alert flex">
            <img src="../../../../../assets/images/icon-warning.png" class="amber_alert_icon" alt="" />
            <span *ngIf="vehicleSelectedByVin" class="phy-damage-error">This vehicle is not eligible for physical damage coverage.</span>
            <span *ngIf="!vehicleSelectedByVin" class="phy-damage-error">Add your vehicle’s VIN to see if this vehicle is eligible for physical damage coverage.</span>
          </div>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="coverage-panel-content">
      <ng-container *ngFor="let coverageItem of params.coverageItems">
        <div *ngIf="!coverageItem.cvgToDisplay" class="ffq-coverage-panel-coverage-holder">
          <div class="coverage-name-amnt-wrapper">
            <div class="ffq-coverage-panel-coverage-item-label {{coverageItem.hasError?' coverage-error-label ':'  '}}">
              {{coverageItem.itemLabel}}</div>
          </div>
          <div class="ffq-coverage-panel-input-holder">
            <ng-template [ngIf]="showNCPhyDamageIndDialogflag">
              <div class="ffq-coverage-items-dropdown"> {{getValueForDisplayCode(coverageItem)}}</div>
            </ng-template>
          </div>
          <ng-container>
            <br role="presentation" />
            <br role="presentation" />
          </ng-container>
        </div>
      </ng-container>
    </div>
  </mat-expansion-panel>
</ng-container>
</mat-accordion>
