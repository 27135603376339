<div class="auto__signal__discount-banner" *ngIf="isSignalDiscountEligible">
    <h2 role="presentation">Want to save an additional 5% today?</h2>
    <div>
        <div class="auto__signal__discount_banner-img">
            <img src="../../../../assets/images/signal-discount-banner-img.PNG" alt="">
        </div>
        <div class="auto__signal__discount_banner-para">
            <p>It’s as easy as signing up for Signal® by Farmers®<a class="info-icon" tabindex="0" aria-label="Save with Signal® info icon opens a popup/ modal" (click)="openSignalDiscountBannerPopup()" (keydown.enter)="openSignalDiscountBannerPopup()" role="button"></a></p>
        </div>
    </div>
    <div class="auto__signal__discount_banner-signup" *ngIf="!isCoverageUpdated">
        <a [attr.data-GTM]="signalDiscountRerateGTM" tabindex="0" [attr.role] = "isAutoQuotePage ? 'button' : 'link'" (keydown.enter)="callRateQuote()" (click)="callRateQuote()" >Sign up and update price**</a>
    </div>
    <div class="auto__signal__discount_banner-signup-recalculate" *ngIf="isCoverageUpdated">
        <a [attr.data-GTM]="signalDiscountRecalculateGTM" tabindex="0" [attr.role] = "isAutoQuotePage ? 'button' : 'link'">Recalculate above before signing up**</a>
    </div>
    
</div>