import { createAction, props } from '@ngrx/store';
import { create } from 'domain';
import { AutoState } from './auto.state';
import { ConnectedCarRequest, PrequalifiedDiscounts } from '@ffq-app-shared/model/common.data.model';

// used to edit any of the
export const UpdateAutoState = createAction('[Auto-State] Update auto information', props<{ reqPayload: AutoState }>());


// TODO Control Data required ?
export const PrefillData = createAction('[Auto-Prefill] Get Prefill Data', props<{ reqPayload: any }>());
export const PrefillDataSuccess = createAction('[Auto-Prefill] Get Prefill Data Success'
    , props<{ respData: any, ctrlData: any, respErr: any }>());
export const PrefillDataFail = createAction('[Auto-Prefill] Get Prefill Data Fail', props<{ respErr: any, ctrlData: any }>());
export const PrefillControlDataClear = createAction('[Auto-Prefill] Clear Unwanted Control Data', props<{ ctrlData: any }>());


export const SelectPrefillDriver = createAction('[Auto - Driver] Select Prefill Driver ', props<{ ctrlData: any }>());
export const SelectPNIDriver = createAction('[Auto - Driver] Select PNI Driver ', props<{ ctrlData: any }>());
export const SelectPNISpouse = createAction('[Auto - Driver] Select PNI Driver ', props<{ ctrlData: any }>());

/** action used to delete the vehicle which is added to quote */
export const DeleteVehicleFromPrefillData = createAction('[Auto-Prefill] Delete Vehicle From Prefill Data');
/** used to update the adpf vehicle reference number */
export const UpdateAdpfVehicleReference = createAction('[Auto-Prefill] Update Adpf Vehicle Reference', props<{ reqPayload: any }>());
export const ClearSelectedPrefillDriver = createAction('[Auto - Driver] Clear Selected Prefill Driver ', props<{ ctrlData: any }>());
export const ResetVinResponse = createAction('[Auto - Driver] Clear Previous VIN Response ');

/** action used to remove driver from prefill which is added to quote */
export const RemoveDriverFromPrefillList = createAction('[Auto-Driver] Remove Driver From PrefillList', props<{ reqPayload: any }>());
/** action to remove all data added to auto quote when customer changes the state */
export const ClearAllDataFromAuto = createAction('[Auto-Prefill] Clear All Data from Auto Store');
// tslint:disable-next-line: max-line-length
export const UpdateAutoControlData = createAction('[Auto-ControlData] Update Auto Control Data', props<{ ctrlData: any }>());
export const UpdateAutoServiceControlData
    = createAction('[Auto-ControlData] Update Auto Service Control Data', props<{ serviceCtrlData: any }>());

// tslint:disable-next-line:max-line-length
export const BWActionPopupShown = createAction('[Auto-Driver] Bristol west pop up shown success');
export const UpdateAutoCompanyCode = createAction('[Auto-Driver] Update Auto Company Code', props<{ reqPayload: any }>());

// export const IsDiscountSave = createAction('[Auto-Driver] Check Discounts is saved', props<{ reqPayload: any }>());
// export const IsQuoteSave = createAction('[Auto-Driver] Check Quote is saved', props<{ reqPayload: any }>());
// export const IsDriverAssignment = createAction('[Auto-Driver] Check Quote is saved', props<{ reqPayload: any }>());
export const IsModuleSaveAndFinish = createAction('[Auto] Check Save and finish called', props<{ reqPayload: any }>());


export const UpdateClearStoreData = createAction('[Auto-Driver] Update Clear Store Data', props<{ ctrlData: any }>());

/** ACTION FOR PERFORM RATE SERVICE */
export const PerformDefaultRateQuote = createAction('[Auto-Quote] Perform Default Rate Quote', props<{ reqPayload: any }>());
/** ACTION FOR PERFORM DEFAULT RATE SERVICE SUCCESS */
export const PerformDefaultRateQuoteSuccess = createAction('[Auto-Quote] Perform Default Rate Quote Success',
    props<{ respData: any, respError: any, serviceCtrlData: any }>());
/** ACTION FOR PERFORM RATE SERVICE SUCCESS */
export const PerformRateQuoteSuccess = createAction('[Auto-Quote] Perform Rate Quote Success',
    props<{ respData: any, ctrlData: any, respError: any }>());
/** ACTION FOR PERFORM RATE SERVICE FAIL */
export const PerformRateQuoteFail = createAction('[Auto-Quote] Perform Rate Quote Fail', props<{ respErr: any, ctrlData: any }>());

/**
 *  ACTIONS FOR MANAGING VEHICLE INFORMATIONS
 */

/** Action to trigger vinlookup service */
export const VINLookup = createAction('[Auto-Vehicles] VIN Lookup', props<{ reqPayload: any }>());
/** Action for updating vinlookup response to store when the service executed successfully */
export const VINLookupSuccess = createAction('[Auto-Vehicles] VIN Lookup Success', props<{ respData: any }>());

/** Action to trigger save vehicle service with saveMode ='add' */
export const SaveVehicle = createAction('[Auto-Vehicles] Save', props<{ reqPayload: any }>());
/** Action for updating saveVehicle ( with saveMode ='add') service response to store when the service executed successfully */
export const SaveVehicleSuccess = createAction('[Auto-Vehicles] Save Success', props<{ reqPayload: any, serviceCtrlData: any }>());

/** Action to trigger save vehicle service with saveMode ='delete' */
export const DeleteVehicle = createAction('[Auto-Vehicles] Delete Vehicle', props<{ reqPayload: any }>());
/** Action for updating saveVehicle (with saveMode ='delete' ) service response to store when the service executed successfully */
// tslint:disable-next-line:max-line-length
export const DeleteVehicleSuccess = createAction('[Auto-Vehicles] Delete Vehicle Success', props<{ reqPayload: any, serviceCtrlData: any }>());

/** Action to trigger save vehicle service with saveMode ='edit' */
export const EditVehicle = createAction('[Auto-Vehicles] Edit Vehicle', props<{ reqPayload: any }>());
/** Action for updating saveVehicle ( with saveMode ='edit')service response to store when the service executed successfully */
export const EditVehicleSuccess = createAction('[Auto-Vehicles] Edit Vehicle Success', props<{ reqPayload: any, serviceCtrlData: any }>());

/** Action used to update service call failed  */
export const ServiceFail = createAction('[Auto-Service] Service Request Failed', props<{ respError: any }>());
/** Action used to update service call failed  */
// tslint:disable-next-line:max-line-length
export const ServiceFailWithCtrlData = createAction('[Auto-Service] Service Request Failed (with control data)', props<{ respError: any, serviceCtrlData: any }>());



/** Save Action from Review button */
export const SaveActionFireAndForget = createAction('[Auto-Vehicles] Tenedica Save', props<{ reqPayload: any }>());
/** Action for updating saveVehicle ( with saveMode ='add') service response to store when the service executed successfully */
export const SaveActionFireAndForgetSuccess = createAction('[Auto-Vehicles] Tenedica Success',
props<{ serviceCtrlData: any }>());

export const SaveActionFireAndForgetFail = createAction('[Auto-Service] Tenedica Failed', props<{ respError: any }>());

/** Action to trigger save vehicle service with saveMode ='delete' */
export const SaveActionWaitAndFire = createAction('[Auto-Vehicles] NC Subcription save', props<{ reqPayload: any }>());
/** Action for updating saveVehicle (with saveMode ='delete' ) service response to store when the service executed successfully */
// tslint:disable-next-line:max-line-length
export const SaveActionWaitAndFireSuccess = createAction('[Auto-Vehicles]  NC Subcription Success', props<{serviceCtrlData: any }>());
export const SaveActionWaitAndFireFail = createAction('[Auto-Vehicles]  NC Subcription fail', props<{ respError: any  }>());
/**
 *  ACTIONS TRIGGERED FROM DRIVER PAGE
 */
/** Action to trigger Select Driver */
export const SelectDriver = createAction('[Auto - Driver] Select Driver ', props<{ ctrlData: any }>());
/** Action to trigger Clear Selected Driver */
export const ClearSelectedDriver = createAction('[Auto - Driver] Clear Selected Driver ', props<{ ctrlData: any }>());
// tslint:disable-next-line: max-line-length
// Call the InitiatePNI Driver as soon as the Driver loading , if Driver Data is empty in the Driver Store . This action should save the customer data to DriversList in Driver store as 0th driver.
export const InitiatePNIDriver = createAction('[Auto-Driver] Initiate PNI Driver', props<{ reqPayload: any }>());
/** Action to Initiate PNI Spouse */
export const InitiatePNISpouse = createAction('[Auto-Driver] Initiate PNI Spouse', props<{ reqPayload: any }>());
/** Action to Initiate Additional Spouse */
export const InitiateDriverSpouse = createAction('[Auto-Driver] Initiate Additional Driver Spouse', props<{ reqPayload: any }>());
/** Action to trigger Save PNI Driver */
export const SavePNIDriver = createAction('[Auto-Driver] Save PNI Driver', props<{ reqPayload: any }>());
/** Action triggered when Save PNI Driver service returns success */
// tslint:disable-next-line: max-line-length
export const SavePNIDriverSuccess = createAction('[Auto-Driver] Save PNI Driver Success', props<{ reqPayload: any, serviceCtrlData: any }>());
// tslint:disable-next-line:max-line-length
// Upon clicking Save of Add driver Modal of the PNI  Spouse driver - PNI spouse  driver action should trigger , Pay load should be the Form data collected/ Prefilled in the Add driver Modal window.
export const AddPNISpouseDriver = createAction('[Auto-Driver] Add PNI Spouse Driver', props<{ reqPayload: any }>());
/** Action triggered when Save PNI Spouse Driver save returns success */
// tslint:disable-next-line: max-line-length
export const AddPNISpouseSuccess = createAction('[Auto-Driver] Add PNI Spouse Driver Success', props<{ reqPayload: any, serviceCtrlData: any }>());
// tslint:disable-next-line:max-line-length
// Call the AddPNI Spouse Save of Add driver Modal of the Spouse driver - AddPNI driver action should trigger , Pay load should be the Form data collected/ Prefilled in the Add driver Modal window.
export const AddAdditionalDriver = createAction('[Auto-Driver] Add Additional Driver', props<{ reqPayload: any }>());
// tslint:disable-next-line:max-line-length
export const AddAdditionalDriverSuccess = createAction('[Auto-Driver] Add Additional Driver Success', props<{ resData: any, serviceCtrlData: any }>());
// tslint:disable-next-line:max-line-length
// Call the Edit driver details - Edit driver action should trigger , Pay load should be the Form data collected/ Prefilled in the Add driver Modal window.
export const EditAdditionalDriver = createAction('[Auto-Driver] Edit Additional Driver', props<{ reqPayload: any }>());
// tslint:disable-next-line:max-line-length
export const EditAdditionalDriverSuccess = createAction('[Auto-Driver] Edit Additional Driver Success', props<{ reqPayload: any, serviceCtrlData: any }>());
// On click of 'Add to Quote' add this driver form prefill data
export const AddAdditionalDriverFromPrefill = createAction('[Auto-Driver] Driver Add to quote', props<{ reqPayload: any }>());
// tslint:disable-next-line:max-line-length
export const AddAdditionalDriverFromPrefillSuccess = createAction('[Auto-Driver] Driver Add to quote Success', props<{ resData: any, serviceCtrlData: any }>());
// On click of 'Add to Quote' add this driver spouse form prefill data
export const AddDriverSpouseFromPrefill = createAction('[Auto-Driver] Add driver spouse from Prefill list', props<{ reqPayload: any }>());
// tslint:disable-next-line: max-line-length
export const AddDriverSpouseFromPrefillSuccess = createAction('[Auto-Driver] Add driver spouse from Prefill list Success', props<{ reqPayload: any, serviceCtrlData: any }>());
// Removal of AD & ADSpouse
export const RemoveMarriedAdditionalDriver = createAction('[Auto-Driver] Remove Married Additional Driver', props<{ reqPayload: any }>());
// tslint:disable-next-line:max-line-length
export const RemoveMarriedAdditionalDriverSuccess = createAction('[Auto-Driver] Remove Married Additional Driver Success', props<{ resData: any, serviceCtrlData: any }>());
// DELETE Spouse Actions : For Spouse and Additional Driver Spouses
export const DeleteAdditionalDriver = createAction('[Auto - Driver] Delete AdditionalDriver', props<{ reqPayload: any }>());
// tslint:disable-next-line:max-line-length
export const DeleteAdditionalDriverSuccess = createAction('[Auto - Driver] Delete AdditionalDriver Success', props<{ reqPayload: any, serviceCtrlData: any }>());
// tslint:disable-next-line:max-line-length
export const RemoveMarriedAdditionalDriverDummy = createAction('[Auto-Driver] Remove Married Additional Dummy Driver', props<{ reqPayload: any }>());
// Remove Dummy Spouse Card
export const RemoveDummySpouse = createAction('[Auto - Driver] Remove dummy spouse', props<{ spouseRef: any }>());
// DELETE Spouse Actions : For Spouse and Additional Driver Spouses
// export const DeleteSpouse = createAction('[Auto - Driver] Delete spouse', props<{ reqPayload: any }>());
// tslint:disable-next-line: max-line-length
// export const DeleteSpouseSuccess = createAction('[Auto - Driver] Delete spouse Success', props<{ reqPayload: any, serviceCtrlData: any }>());
/** Action to trigger Edit Driver */
export const EditDriver = createAction('[Auto - Driver] Edit Driver', props<{ reqPayload: any }>());
/** Action to trigger on Edit Driver Success */
export const EditDriverSuccess = createAction('[Auto - Driver] Edit Driver success', props<{ resData: any, serviceCtrlData: any }>());
/** Action to trigger on Edit Delete Driver Success */
export const EditAndDeleteDriver = createAction('[Auto - Driver] Edit and delete Driver', props<{ reqPayload: any, spouseRef: any }>());
/** Action to trigger on Edit Delete Driver Success */
// tslint:disable-next-line:max-line-length
export const EditAndDeleteDriverSuccess = createAction('[Auto - Driver] Edit and delete Driver success', props<{ resData: any, serviceCtrlData: any }>());
/** Action to trigger on Edit Delete Driver Success */
// tslint:disable-next-line: max-line-length
export const EditAndDeleteDummyDriver = createAction('[Auto - Driver] Edit driver and delete dummy spouse', props<{ reqPayload: any, spouseRef: any }>());
// tslint:disable-next-line:max-line-length
export const EditAndDeleteDummyDriverSuccess = createAction('[Auto - Driver] Edit driver and delete dummy spouse success', props<{ resData: any, serviceCtrlData: any }>());
export const SavePNIDriverAdditionalData = createAction('[Auto-Driver] Save PNI Driver`s Additional Data', props<{ reqPayload: any }>());
/** Action to trigger on Add rideshare */
export const AddRideShare = createAction('[Auto-Driver] Add Ride Share', props<{ reqPayload: any }>());
export const ValidateLicenseNumber = createAction('[Auto-Driver] Validate Driver License', props<{ reqPayload: any }>());
export const AddRideShareSuccess = createAction('[Auto-Driver] Add Ride Share Success', props<{ reqPayload: any, ctrlData: any }>());
export const ValidateLicenseSuccess = createAction('[Auto-Driver] Validate License Success', props<{ respData: any }>());
export const ClearValidateLicense = createAction('[Auto - Driver] Clear Validate License ', props<{ respData: any }>());

/**
 *  ACTIONS TRIGGERED FROM DISCOUNT PAGE
 */

/** Action to trigger saveDiscount service */
export const SaveDiscount = createAction('[Auto-Discount] Save Discount', props<{ reqPayload: any }>());
/** Action for updating saveDiscount when the service executed successfully */
// tslint:disable-next-line: max-line-length
export const SaveDiscountSuccess = createAction('[Auto-Discount] Save Discount Success', props<{ respErr: any, respData: any, serviceCtrlData: any }>());
/** Action to trigger saveDiscount with saveMode = 'saveAndFinish' when user clicks on 'Save quote & finish later' link on page */
export const SaveDiscountAndFinish = createAction('[Auto-Discount] Save Discount And Finish', props<{ reqPayload: any }>());
/** Action to trigger saveDiscount with saveMode = 'saveAndFinish' when user clicks on 'Save quote & finish later' link on page */
export const SaveSignalDiscountAndFinish = createAction('[Auto-Discount] Save Signal Discount And Finish', props<{ reqPayload: any }>());
/** Action to trigger driverAssignment with saveMode = 'saveAndFinish' when user clicks on 'Save quote & finish later' link on page */
export const SaveDriverAssignAndFinish = createAction('[Auto-Discount] Save Driver Assignment And Finish', props<{ reqPayload: any }>());
/** Action to trigger rideshare with saveMode = 'saveAndFinish' when user clicks on 'Save quote & finish later' link on ride share page */
export const SaveRideShareAndFinish = createAction('[Auto-Discount] Save Ride share And Finish', props<{ reqPayload: any }>());

export const UpdateDriverListSuccess = createAction('[Auto-Discount] Save DriverList for Vehicle Driver Assignment',
props<{ reqPayload: any }>());

/** Action to delete discount and quote data before triggering the saveDiscount service */
export const ResetDiscountAndQuoteState = createAction('[Auto-Discount] Delete Discount And Quote Data from Quote');

/** Action for updating quote data */
// tslint:disable-next-line:max-line-length
export const UpdateAutoStoreFromRQ = createAction('[Auto-Store] Update Auto Store Data From Retrieve Quote Response', props<{ reqPayload: any }>());

// tslint:disable-next-line:max-line-length
export const SaveQuoteSuccess = createAction('[Auto-Quote] Save Rate Quote Success', props<{ respData: any, ctrlData: any }>());
export const SaveQuoteFail = createAction('[Auto-Quote] Save Rate Quote Fail', props<{ respErr: any, ctrlData: any }>());

export const SaveKnockOut = createAction('[Auto-Quote] Save KnockOut', props<{ reqPayload: any }>());
export const SaveKnockOutFail = createAction('[Auto-Quote] Save KnockOut Fail', props<{ respErr: any, ctrlData: any }>());
export const SaveKnockOutSuccess = createAction('[Auto-Quote] Save KnockOut Success', props<{ respData: any, ctrlData: any }>());

export const SaveSignalDiscount = createAction('[Auto-Quote] Save Signal Discount', props<{ reqPayload: any }>());
// tslint:disable-next-line:max-line-length
export const SaveSignalDiscountSuccess = createAction('[Auto-Quote] Save Signal Discount Success', props<{ respData: any, ctrlData: any }>());
// tslint:disable-next-line:max-line-length
export const SaveSignalDiscountFail = createAction('[Auto-Quote] Save Signal Discount Fail', props<{ respErr: any, ctrlData: any }>());

export const SaveVehicleList = createAction('[FFQ-App] Save Vehicle List', props<{ reqPayload: any }>());
// tslint:disable-next-line:max-line-length
export const SaveVehicleListSuccess = createAction('[Auto-Quote] Save Vehicle List Success', props<{ reqPayload: any, serviceCtrlData: any }>());
export const DriverVehicleAssignment = createAction('[Auto - Driver] Driver Vehicle Assignment', props<{ reqPayload: any }>());
export const DriverVehicleAssignmentSuccess = createAction('[Auto-Driver] Driver Vehicle Assignment Success',
    props<{ reqPayload: any, ctrlData: any, respError: any }>());
export const VehicleDriverAssignment = createAction('[Auto - Driver] Vehicle Driver Assignment', props<{ reqPayload: any }>());
/** ACTION FOR VEHICLE DRIVER ASSIGNMENT SUCCESS */
export const VehicleDriverAssignmentSuccess = createAction('[Auto-Driver] Vehicle Driver Assignment Success',
    props<{ reqPayload: any, ctrlData: any }>());
export const UpdateAutoError = createAction('[Auto-Error] Update Error Object', props<{ respError: any }>());
/** ACTION FOR CUSTOM RATE SUCCESS */
export const CustomRateSuccess = createAction('[Auto-Quote] Custom Rate Success', props<{
    respData: any, ctrlData: any,
    serviceCtrlData: any, respError: any
}>());
/** ACTION TO TRIGGER RENTERS RATE SERVICE */
export const RentersRate = createAction('[Auto-Quote] Renters Rate Service');
/** ACTION FOR STORING RENTERS RATE RESPONSE */
export const RentersRateSuccess = createAction('[Auto-Quote] Renters Rate Success', props<{
    respData: any, error: any,
    serviceCtrlData: any
}>());

export const UpdateAutoStoreDirectly = createAction('[Auto ] Refresh Auto Store Update', props<{ reqPayload: any }>());
/** ACTION TO UPDATE THE RENTERS RESPONSE IN STORE */
export const UpdateRentersRateData = createAction('[Auto-Quote] Updated the Renters Rate Data', props<{ reqPayload: any }>());
/** ACTION TO INCREMENT THE ERROR COUNT BY ONE WHEN ANY ERROR IS OCCURED DURING SERVICE CALL */
export const IncrementErrorCount = createAction('[App-Auto] Increment the error count by 1');
/** ACTION TO UPDATE THE DISTANT STUDENT WHEN THE PNI OR PNI'S SPOUSE AGE IS CHANGED */
export const updateDistantStudent = createAction('[FFQ-App] Change distant Student', props<{reqPayload: any}>());
/** ACTION TO call print service */
export const dispatchPrint = createAction('[Auto-Quote] dispatch print', props<{ reqPayload: any }>());
/** ACTION TO update print service success */
export const dispatchPrintSuccess = createAction('[Auto-Quote] dispatch print Success');
/** ACTION TO updated print service fail */
export const dispatchPrintFail = createAction('[Auto-Quote] dispatch print Fail');

/** Action to trigger saveExcludedDriver service */
export const SaveExcludedDriver = createAction('[Auto-AdditionalDriverInfo] Save Excluded Driver', props<{ reqPayload: any }>());
/** Action for updating serviceCtrldata when the service executed successfully */
export const SaveExcludedDriverSuccess =
  createAction('[Auto-AdditionalDriverInfo] Save Excluded Driver Success', props<{ serviceCtrlData: any }>());

// Action to save Prequalified Discount details
export const UpdatePrequalifiedDiscountList = createAction('[Auto-PrequalifiedDiscount] Update Auto Prequalified Discount',
  props<{ prequalifiedDiscounts: PrequalifiedDiscounts[] }>());

export const UpdateResidenceType = createAction('[Auto-Quote] Update Residence Type',
  props<{ respData: any}>());

/** Action to trigger connectedCar service */
export const InvokeConnectedCar = createAction('[Auto-Driver] Initiate Connected Car', props<{ reqPayload: ConnectedCarRequest }>());
