
  <!--  <div id="header_logo-placeholder">
    <span aria-label="Farmers Insurance  Logo" data-GTM="FFQ_Auto_header_Farmerslogo_link" role="main"> 
        <div aria-hidden ="true" id="header_ffq-logo" title="Farmers &reg;"></div>
        </span>
      </div>-->
<div class="row">
<div class=" col-12 card is-loading">
  <div class="skeletal_loader_spacing"></div>
    <div class="skeletal_loader_text_type1"></div>
    <div class="skeletal_loader_text_type2"></div>
    <div class="skeletal_loader_spacing"></div>
    <div class="skeletal_loader_text_type1"></div>
    <div class="skeletal_loader_text_type2"></div>
 
    <div class="skeletal_loader_paragraph"></div>
    <div class="skeletal_loader_spacing"></div>
    <div class="skeletal_loader_text_type1"></div>

    <div class="skeletal_loader_list">
    <div class="skeletal_loader_bullet_point"></div>
    <div class="skeletal_loader_text_type2"></div>
  </div>
  <div class="skeletal_loader_list">
    <div class="skeletal_loader_bullet_point"></div>
    <div class="skeletal_loader_text_type2"></div>
  </div>
  <div class="skeletal_loader_list">
    <div class="skeletal_loader_bullet_point"></div>
    <div class="skeletal_loader_text_type2"></div>
  </div>
  <div class="skeletal_loader_list">
    <div class="skeletal_loader_bullet_point"></div>
    <div class="skeletal_loader_text_type2"></div>
  </div>

  </div>
</div>