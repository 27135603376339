import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, AfterContentChecked , DoCheck } from '@angular/core';
import { FormInputParam } from '../form-input-params';
import { AbstractControl } from '@angular/forms';
import { FormFieldEmitParams } from '../params/form-field-emit-params';

@Component({
  selector: 'lib-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class InputTextComponent implements OnInit, AfterContentChecked , DoCheck {
    @Input() params: FormInputParam;
    Object = Object;
    /**
     * Variable declared to handle focus scenario in ADA
     */
    enableFocusRing = false;
    edittext = 'edit';
    arialabel: string;

    /**
     * US255602: Segment Field level tracking
     */
    @Output() inputFieldValue: EventEmitter<FormFieldEmitParams> = new EventEmitter();
    lastBlurInputValue: string;

    constructor() {
    }
    ngOnInit() {
      if (this.params.pageTitle) {
        this.arialabel = this.params.ariaLabel;
        if (this.params.pageTitle.length !== 0) {

            this.params.ariaLabel = this.params.pageTitle + ' ' + this.params.ariaLabel;
              }
            }
    }

    ngDoCheck() {

    }
    getErrorMessage(messages, key, value) {
      return messages[key].replace(/{}/g, value);
    }
    eventHandlerKeyUp() {
      if (this.params.id === 'phoneNumber') {
      const content =  this.params.formGroup.get('phoneNumber').value;
      let numVal = content.replace(/\D/g, '');
      if (numVal.length > 0) {
        numVal = this.insertCharecterByIndex(numVal, '(', 0);
      }
      if (numVal.length > 4) {
        numVal = this.insertCharecterByIndex(numVal, ')', 4);
      }
      if (numVal.length > 8) {
        numVal = this.insertCharecterByIndex(numVal, '-', 8);
      }
      if (numVal.length > 13) {
        numVal = numVal.substring(0, 13);
      }
      this.params.formGroup.get(this.params.formControllName).setValue(numVal);
    }
  }
    /**
     * Determines whether paste on
     * @ param event
     */
    onPaste(event: any) {
      if (this.params.id === 'phoneNumber') {
        const content = event.clipboardData.getData('text/plain');
        let numVal = content.replace(/\D/g, '');
        const isnum = /^\d+$/.test(numVal);
        if (!isnum) {
          event.preventDefault();
        }
        if (numVal.length > 0) {
          numVal = this.insertCharecterByIndex(numVal, '(', 0);
        }
        if (numVal.length > 4) {
          numVal = this.insertCharecterByIndex(numVal, ')', 4);
        }
        if (numVal.length > 8) {
          numVal = this.insertCharecterByIndex(numVal, '-', 8);
        }
        if (numVal.length > 13) {
          numVal = numVal.substring(0, 13);
        }
        this.params.formGroup.get(this.params.formControllName).setValue(numVal);
        event.preventDefault();
      }
    }
    /**
     * Inserts charecter by index
     * @param input input
     * @param chr input
     * @param position position
     * @returns formatted phone number
     */
    insertCharecterByIndex(input, chr, position) {
      return input.substring(0, position) + chr + input.substring(position);

  }

  blurHandle() {
    this.enableFocusRing = false;
    this.edittext = '';
    if (this.params && this.params.pageTitle) {
    this.params.pageTitle = '';
    }
    /**
     * US255602: Segment Field level tracking
     */
    const inputTextValue = this.params.formGroup.get(this.params.formControllName).value;
    if (inputTextValue && inputTextValue.length > 0 && inputTextValue !== this.lastBlurInputValue) {
      this.lastBlurInputValue = inputTextValue;
      this.inputFieldValue.emit({ value: inputTextValue, event: 'Value Added' });
    }
    // removes unwanted empty space from both end
    if ( this.params.id === 'firstName' || this.params.id === 'lastName') {
      if (inputTextValue && inputTextValue.length > 0 ) {
        this.params.formGroup.get(this.params.formControllName).setValue(inputTextValue.trim());
      }
    }
  }

  scrollIntoViewIfNeeded() {

    this.enableFocusRing = true;
    const element = document.getElementById(document.activeElement.id);
    if (element) {
      // bottom posn of window = top posn of the window + window height
      const windowBottomPosition = document.documentElement.scrollTop + window.innerHeight;
      // getting the element's top position
      const elementTopPosition = this.getElementWindowTop(element);
      // element's bottom position
      const elementBottomPosition = elementTopPosition + element.clientHeight;
      if (!(elementBottomPosition > document.documentElement.scrollTop
        && elementTopPosition < windowBottomPosition)) {
          if (document.activeElement.tagName === 'INPUT' && !this.isLargeScreen() &&
          element.parentElement && element.parentElement.parentElement) {//US322709 : Migration of Landing page to FFQ
            window.setTimeout(() => {
              const parentElement = element.parentElement.parentElement;
              parentElement.scrollIntoView();
            }, 0);
          }
      }
    }

  }

  /**
   * Gets element window top
   */
  getElementWindowTop(elem) {
    return elem && typeof elem.getBoundingClientRect === 'function' ?
      elem.getBoundingClientRect().top - document.body.getBoundingClientRect().top : 0;
  }

  isLargeScreen() {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (width > 960) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Gets address form control
   */
  get addressFormControl(): AbstractControl {
    return this.params.formGroup.get('residentialAddress');
  }

  ngAfterContentChecked() {
    if (this.params.id === 'oneWayMiles' && this.params.formGroup.value.oneWayMiles &&
    this.params.formGroup.controls.oneWayMiles.status === 'VALID') {
         const sample = this.params.formGroup.value.oneWayMiles;
         this.params.ariaLabel = 'Miles you drive one way is' + ' ' + sample + ' ' + ', which is editable';
        } else if (this.params.id === 'vin' && this.params.formGroup.value.vin) {
          const sample = this.params.formGroup.value.vin;
          this.params.ariaLabel = 'Vehicle Identification Number is' + ' ' + sample + ' ' + 'which you can ' + this.edittext;
         } else if (this.params.id === 'annualMiles' && this.params.formGroup.value.annualMiles) {
          const sample = this.params.formGroup.value.annualMiles;
          this.params.ariaLabel = 'Miles you drive per year calculated as' + ' ' + sample + ' ' + 'which is editable';
         } else if (this.params.id === 'occuOtherDesc' && this.params.formGroup.value.occuOtherDesc) {
          const sample = this.params.formGroup.value.occuOtherDesc;
          this.params.ariaLabel = 'Occupation is ' + ' ' + sample + ' ' + ' , which is re-editable';
         } else if (this.params.id === 'ageFirstLicensed' && this.params.formGroup.value.ageFirstLicensed) {
          const sample = this.params.formGroup.value.ageFirstLicensed;
          this.params.ariaLabel = 'Age is ' + ' ' + sample + ' ' + ' , which is re-editable';
         } else if (this.params.pageTitle !== undefined && this.params.pageTitle.length === 0) {
          this.params.ariaLabel = this.arialabel;
         }

     }
}
