<div id="quote_unavailable-page-container">
    <div id="quote_unavailable-content" *ngIf="!isOnlyAgentQuoteAvailable">
        <div id="quote_unavailable-header_ffq-logo" title="Farmers &reg;"></div>
        <div id="quote_unavailable_header-title">
            <h1>Sorry, we don't offer insurance in your area.</h1>
        </div>
        <div id="quote_unavailable_body_message">
            Click at least 2 to 3 companies below to find the best rate!
        </div>

        <div class="local-insurance-provier-list clearfix" id="Content">
        </div>
        <div id="need_help">
            <div id="quote_unavailable_body_noagent"> Need more help?</div>
            <div id="quote_unavailable_body_call-custome">You can call customer service at</div>
                <div class="agent-detail-row desktop">
                    <div id="quote_unavailable_body_farmers" x-ms-format-detection = "none">
                        <a href={{teleDesktop}} tabindex="0" role="link">{{desktopNo}}</a>
                    </div>
                </div>
                <div class="agent-detail-row mobile">
                    <div>
                        <a href={{teleMobile}} id="quote_unavailable_body_farmers-mobile" x-ms-format-detection = "none">{{mobileNo}}</a>
                    </div>
                </div>
        </div>
    </div>

    <div id="quote_unavailable-content" *ngIf="isOnlyAgentQuoteAvailable">
        <div id="quote_unavailable-header_ffq-logo" title="Farmers &reg;"></div>
        <div class="quote_blocked_header-title">
            <h2>{{monetizationHeading}}</h2>
        </div>
        <div id="quote_unavailable_body_message">{{monetizationMessage}}</div>
        <div id="quote_unavailable_body_message">{{monetizationMessageAdditional}}</div>

        <div class="local-insurance-provier-list clearfix" id="Content">
        </div>
        <div id="need_help">
            <div id="quote_unavailable_body_noagent"> Need more help?</div>
            <div class="agent_button">
                <button (keyup.enter)="findAnAgent()" (click)="findAnAgent()" role= "presentation" tabindex= "0" aria-label="Let's find an agent button link" mat-button class="ffq-card-button-blue">
                    <span class="find_agent_text">LET'S FIND AN AGENT</span> 
                </button>
            </div>
        </div>
    </div>
</div>