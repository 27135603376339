import { Injectable } from '@angular/core';
import { LegacyRedirectionService } from '@ffq-app-core/services/legacy.redirection.service';
import { Router } from '@angular/router';
import { DataConstants } from '@ffq-app-shared/constants/data.constants';
import { InfoDialogComponent } from '@ffq-lib/src/lib/dialog/info-dialog/info-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class RestErrorHandlerService {
  constructor(
    private legacyRedirectionService: LegacyRedirectionService,
    private router: Router,
    private infoDialogComponent: InfoDialogComponent
  ) { }
  modalData = DataConstants.MODAL_DATA;
  checkRestResponse(errorCode, changedZipCode, lob) {
    switch (errorCode) {
      case 'EG014':
        const data = [{ name: 'crossselllobs', value: lob }, { name: 'zip', value: changedZipCode }];
        this.legacyRedirectionService.redirectToLegacy(data);
        break;
      case 'EG001':
        this.router.navigate(['/inconvenience']);
        break;
      case 'EG002':
        this.router.navigate(['/inconvenience']);
        break;
      case 'EG003':
        // popupService.fnOpenDailogPopUp(moduleCntrlName, 'md', 'Please enter a valid Zip Code');
        break;
      case 'EG005':
        this.router.navigate(['/maintenance']);
        break;
      case 'EG1000':
        this.router.navigate(['/inconvenience']);
        break;
      case 'E1234':
        this.router.navigate(['/inconvenience']);
        break;
      case 'ERR3':
        this.router.navigate(['/inconvenience']);
        break;
      case 'ERR4':
        this.router.navigate(['/inconvenience']);
        break;
      case 'EG006':
        this.router.navigate(['/inconvenience']);
        break;
      case 'EG010':
        // popupService.fnOpenDailogPopUp(moduleCntrlName, 'md', 'Lookup action for VIN fails');
        break;
      case 'EV002':
        this.router.navigate(['/inconvenience']);
        break;
      case 'ERR1':
        // eventCapturedBus.fireEvent(serviceConstants.SERVICE_EVENTS.SAVEANDFINISH_ERROR_EVENT);
        // $state.transitionTo('inconveniencePage');
        this.router.navigate(['/inconvenience']);
        break;
      case 'ERR7':
        // popupService.fnOpenDailogPopUp(moduleCntrlName, 'md', 'Agent is not assigned');
        break;
      case 'ERR5':
        this.router.navigate(['/inconvenience']);
        break;
      case 'ERR11':
        this.router.navigate(['/inconvenience']);
        break;
      case 'ED002':
        this.router.navigate(['/inconvenience']);
        break;
      case 'EG009':
        // popupService.fnOpenDailogPopUp(moduleCntrlName, 'md', 'Invalid Vin');
        break;
      case 'EG011':
        // popupService.fnPolicyAlreadyBound('controller.policyAlreadyBound', 'md', constants.modalData['PolicyAlreadyBound']);
        break;
      case 'EQ001':
        this.router.navigate(['/inconvenience']);
        break;
      case 'EQ002':
        this.router.navigate(['/inconvenience']);
        break;
      case 'EG013':
        // fnOpenZipCodeErrorPopup
        const additionalParameters1 = { styleClass: '', buttonName: '', buttonGTM: '' };
        this.infoDialogComponent.openDialog(this.modalData.zipCodeNotSupport.modalName,
          this.modalData.zipCodeNotSupport.modalBody, additionalParameters1)
          .afterClosed().subscribe(() => {
            window.location.replace('/');
          });
        break;
      case 'EG008':
      // goToHomeQuoteLabPage'
       this.router.navigate(['/intermediatepage']);
       break;
      case 'EG004':
        this.router.navigate(['/intermediatepage']);
        break;
      case 'EG032':
        this.router.navigate(['/iidquotereject']);
        break;
      default:
        this.router.navigate(['/inconvenience']);
    }

  }
}
