import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { StateRuleModelService } from '@ffq-app-shared/services';
import { SegmentService } from '@ffq-app-shared/services/segment.service';
import { AppStoreService } from '@ffq-app-store/app.store.service';
import { SpinnerService } from '@ffq-lib/src/lib/spinner/spinner.service';
import { NavigationDrawerService } from '../navigation-drawer/navigation-drawer.service';
import { Router, Event, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { BristolWestPopupComponent } from '../bristol-west-popup/bristol-west-popup.component';
import { AutoStoreService } from '@ffq-app-auto/store/auto.store.service';
import { MatDialog } from '@angular/material/dialog';
import { DataConstants } from '@ffq-app-shared/constants';
import { InsuredModalComponent } from '@ffq-app-auto/your-info/components/insured-modal/insured-modal.component';
import { FgiaPopupComponent } from '../fgia-popup/fgia-popup.component';
import { StartQuoteRequest } from '@ffq-app-landing/index';
import { DatePipe } from '@angular/common';
import { ServiceCallStatus } from '@ffq-app-shared/enums/flow.identifier.enum';

@Component({
  selector: 'app-interstitial-page',
  templateUrl: './interstitial-page.component.html',
  styleUrls: ['./interstitial-page.component.scss']
})
export class InterstitialPageComponent implements OnInit, AfterViewInit, OnDestroy {

/**
 * Subscriptions  of business info component
 */
private subscriptions: Subscription[] = [];

/**
 * source indicator from autostore
 */
quoteSourceIndicator: string;

  showPopUpType: string;
  lob: string;
  landingZipCode: string;
  quoteType: string;
  params: StartQuoteRequest;
  sourceIndicator: string;
  src: string;

  isAwsSourceFlow = false;
  /* Bright line implemented for Organic Flow */
  isBrghtLineOrganicFlowImpl = true; // should be false by default
  isBwPopupShown = false;
  isBrightLineShowCurrentInsuredImpl = true; // should be false by default
  insuredModalOpened = false;
  /**
   * Landing state code of about yourself component
   */
  landingStateCode = '';


  constructor(private navDrawer: NavigationDrawerService,
              private router: Router,
              private appStoreService: AppStoreService,
              private spinnerService: SpinnerService,
              private segmentService: SegmentService,
              private datePipe: DatePipe,
              private stateRuleModel: StateRuleModelService,
              private autoStoreService: AutoStoreService,
              private dialog: MatDialog,
              private fqiaDialog: MatDialog,
              private currentlyInsuredDialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.navDrawer.showSidePanel();
    this.navDrawer.showSkeletalLoaderSidePanel();
    this.navDrawer.hideSidePanelHeader();
    this.navDrawer.hideHeaderPanelIcons();
   //  this.appStoreService.getAEMStaticContent();
    // this.navDrawer.hideDrawer();
    // this.navDrawer.hideEverything();
    // this.navDrawer.hideHeader();
    this.subscriptions.push(this.appStoreService.sourceIndicatorData$.subscribe(data => {
      this.quoteSourceIndicator = data;
    }));
    this.subscriptions.push(this.autoStoreService.getBrightLinedData$.subscribe((data: any) => {
      this.isBwPopupShown = data && data.isBwPopupShown ? data.isBwPopupShown : this.isBwPopupShown;
    }));
    this.subscriptions.push(this.appStoreService.appDataZip$.subscribe(appDataZip => {
      this.landingStateCode = appDataZip.landingStateCode;
    }));
    this.isAwsSourceFlow = this.quoteSourceIndicator && (this.quoteSourceIndicator === DataConstants.AWS_SOURCE_INDICATOR.sourceIndicator_AS
      || this.quoteSourceIndicator === DataConstants.AWS_SOURCE_INDICATOR.sourceIndicator_AP);

    this.appStoreService.getAEMStaticContent();
    let staticContentServiceStatus = null;

    this.appStoreService.getAEMContentServiceStatus$.subscribe(AEMContentstatus => {
       staticContentServiceStatus = AEMContentstatus;

       if ('success' === staticContentServiceStatus) {
         // No need to show the Bristol west popup in the aws flow
         this.showPopUp();
       }
      });
  }

  getLob(quoteType: string) {
    switch (quoteType) {
      case DataConstants.LOB.auto:
        return 'auto';
      case DataConstants.LOB.business:
        return 'business';
      case DataConstants.LOB.home:
        return 'home';
      case DataConstants.LOB.renter:
        return 'renter';
      case DataConstants.LOB.condo:
        return 'condo';
      case DataConstants.LOB.life:
        return 'life';
    }
  }


  /** F51790 : Digital Monetization - FFQ Quotes in Red States (Auto & Dwellings)
    * When showPopUpType is 'FGIA'. Show FGIA Popup.
    *      On Yes go to FGIA. On No go to Monetization Page.
    * When showPopUpType is 'CIPOP'. Show CI Popup.
    *      On Yes of Currently Insured, go to FGIA Popup.
    *              On Yes on FGIA Popup - Go to FGIA page.
    *               On No on FGIA Popup - go to Monetization Page.
    *
    *      On No of Currently Insured, go to BWPopup.
    *              On Yes on BWPopup - Continue Quoting with BW.
    *             On No on BWPopup - go to FGIA Popup.
    *             On Yes on FGIA Popup - Go to FGIA.
    *             On No on FGIA Popup - go to Monetization Page.
    *
    *  When showPopUpType is 'BWPOP'. Show BW Popup.
    *      On Yes on BWPopup - Continue Quoting with BW.
    *      On No on BWPopup - go to FGIA Popup.
    *            On Yes on FGIA Popup - Go to FGIA.
    *           On No on FGIA Popup - go to Monetization Page.
  */
  public showPopUp(): void {
    this.subscriptions.push(this.appStoreService.appError$.subscribe((errorObj: any) => {
      if (errorObj && Object.keys(errorObj).length !== 0) {
        this.showPopUpType = errorObj.errorInfo.showPopUpType;
        this.lob = this.getLob(errorObj.errorInfo.lob);
        this.quoteType =  errorObj.errorInfo.lob;
        this.landingZipCode = errorObj.errorInfo.zipCode;
        this.src = errorObj.errorInfo.src;
        this.sourceIndicator = errorObj.errorInfo.sourceIndicator;
      }
    }));

    if (this.showPopUpType === 'FGIA') {
      this.openFgiaDialog();
    } else if (this.showPopUpType === 'CIPOP' || this.showPopUpType === DataConstants.DM_POPUP_TYPE_CONSTANTS.NOFGIA_CIPOP) {

      this.subscriptions.push(this.currentlyInsuredDialog.open(InsuredModalComponent,
        { panelClass: 'insured-modal-dialog', disableClose: true }).afterClosed().subscribe(currentlyInsuredResponse => {
          this.insuredModalOpened = true;
          if (currentlyInsuredResponse && currentlyInsuredResponse === 'No') {
            this.openBristolWestDialog();
          } else if (currentlyInsuredResponse && currentlyInsuredResponse === 'Y') {
            if (this.showPopUpType === DataConstants.DM_POPUP_TYPE_CONSTANTS.NOFGIA_CIPOP) {
              this.router.navigate(['/intermediatepage']);
            } else {
              this.openFgiaDialog();
            }
          }
        }));
    } else if (this.showPopUpType === 'BWPOP' || this.showPopUpType === DataConstants.DM_POPUP_TYPE_CONSTANTS.NOFGIA_BWPOP) {
      this.openBristolWestDialog();
    }
  }

  public showOrganicBWFlowPopup(): void {
    if (this.isBrghtLineOrganicFlowImpl && !this.isBwPopupShown) {
      if (!this.isAwsSourceFlow) {
        if (this.isBrightLineShowCurrentInsuredImpl) {
          if (!this.insuredModalOpened) {
            /** Subscription for currentlyInsuredDialog */
            this.subscriptions.push(this.currentlyInsuredDialog.open(InsuredModalComponent,
              { panelClass: 'insured-modal-dialog', disableClose: true }).afterClosed().subscribe(currentlyInsuredResponse => {
                this.insuredModalOpened = true;
                if (!currentlyInsuredResponse && !this.isBwPopupShown) {
                  this.showOrganicBWFlowPopup();
                } else if (currentlyInsuredResponse && currentlyInsuredResponse === 'No') {
                  this.openBristolWestDialog();
                } else if (currentlyInsuredResponse && currentlyInsuredResponse === 'Y') {
                  // this.router.navigate(['/intermediatepage']);
                  this.openFgiaDialog();
                }
              }));
            this.insuredModalOpened = true;
          }
        } else {
          this.openBristolWestDialog();
        }
      } else if (this.isAwsSourceFlow && this.landingStateCode === DataConstants.STATE_CODES.FLORIDA) {
        this.openBristolWestDialog();
      }
    }
  }

  openBristolWestDialog(): void {
    this.dialog.open(BristolWestPopupComponent, {
      panelClass: 'bristol-west-popup',
      data: {},
      disableClose: true
    }).afterClosed().subscribe(result => {
      if (result && result === 'Yes') {
        this.params = {};
        this.params.lob = this.lob;
        this.params.quoteType = this.quoteType;
        this.params.landingZipCode = this.landingZipCode;
        this.params.blockQuoteSkip = true;
        this.params.src = this.src;
        this.params.sourceIndicator = this.sourceIndicator;
        // this.callSegmentForStartQuote(udata.lob, udata.landingZipCode, udata.quoteType);
        this.appStoreService.startQuote(this.params);
        //  this.appStoreService.updateAppControlData({ initAppRenderServices: ServiceCallStatus.TRIGGERED });
        this.spinnerService.hideFarmersLoader();
        this.subscriptions.push(this.appStoreService.getInitAppRenderServices$.subscribe(data => {
          if (data === ServiceCallStatus.READY) {
            this.appStoreService.getProjectStatusCode(this.getEffectiveDate());
            this.appStoreService.getSourcePhoneNumber({ lob: this.quoteType });
          }
        }));
        this.autoStoreService.updateAutoControlData({ isBwPopupShown: true, autoCompanyCode: 'B', isEaiBrightLineInd: true });
        this.subscriptions.push(this.appStoreService.getAppControlValues$.subscribe((data: any) => {
          if (data && data[`projectCodeStatus`] && Object.keys(data[`projectCodeStatus`]).length !== 0) {
            this.redirectToFirstPage(data[`quoteType`]);
          }

        }));

      } else if (result && result === 'others') {
        // this.router.navigate(['/intermediatepage']);
        this.openFgiaDialog();
      }});
  }

  getModuleRoute(lob, moduleName) {
    if ('auto' === lob) {
      switch (moduleName) {
        case 'YourInfo':
          return '/yourinfo';
        case 'Vehicle':
          return '/vehicles';
        case 'Driver':
          return '/drivers';
        case 'Discount':
          return '/discounts';
        case 'Signal':
          return '/signal-discounts';
        case 'Quote':
          return '/quote';
        case 'AdditionalInfo':
          return '/additionalInfo';
        case 'Assignment':
          return '/drivers/driverassignment';
        case 'RateSummary':
          return '/ratesummary';
        // Add rest of Auto Modules here
        default:
          return '/yourinfo';
      }
    } else if ('home' === lob) {
      return '/yourinfo';
    } else if ('business' === lob) {
      switch (moduleName) {
        case 'BusinessInfo':
          return '/businessinfo';
        case 'businessInfoBean':
          return '/businessinfo';
        case 'YourInfo':
          return '/yourinfo';
        default:
          return '/yourinfo';
      }
    }
  }

  redirectToFirstPage(lob: string) {
    const selectedLOB = this.getLob(lob);
    const routeLob = (selectedLOB === 'condo' || selectedLOB === 'renter') ? 'home' : selectedLOB;
    this.navDrawer.showSidePanel();
    if (lob === 'B') {
      this.router.navigate(['/' + routeLob + this.getModuleRoute(routeLob, 'BusinessInfo')], { queryParams: { LOB: lob } });
    } else {
      // US386017 - Back button redirection issue fix
      // this.appStoreService.updateAppControlData({ previousRoute: ['legacy', 'your-info'] });
      this.router.navigate(['/' + routeLob + this.getModuleRoute(routeLob, 'YourInfo')],
        { queryParams: { LOB: lob } });
    }
  }

  /**
   * This function needs to be called to calculate the effectiveDate
   * paramter passed to Project Status code
   */
    getEffectiveDate() {
      // TODO - Changes to be done here while retrieve Quote is implemented
      const now = new Date();
      return this.datePipe.transform(now, 'yyyy-MM-dd');
    }

  openFgiaDialog(): void {
    this.fqiaDialog.open(FgiaPopupComponent, {
      panelClass: 'bristol-west-popup',
      data: {},
      disableClose: true
    });
    // .afterClosed().subscribe(result => {
    //   if (result && result === 'Yes') {
    //     this.autoStoreService.updateAutoControlData({ isBwPopupShown: true, autoCompanyCode: 'B', isEaiBrightLineInd: true });
    //     this.router.navigate(['/auto/yourinfo']);

    //   }
    // });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.spinnerService.hideLoader();
    });
  }

  ngOnDestroy() {
    this.navDrawer.hideSkeletalLoaderSidePanel();
    this.navDrawer.showSidePanelHeader();
    this.navDrawer.showHeaderPanelIcons();
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
