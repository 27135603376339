import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, from } from 'rxjs';
import { AppStoreService } from '@ffq-app-store/app.store.service';
import { AutoStoreService } from '@ffq-app-auto/store/auto.store.service';
import { take } from 'rxjs/operators';
import { StateRuleModelService } from '@ffq-app-shared/services';
import { ActionDialogComponent } from '@ffq-lib';
import { LibraryDataService } from '@ffq-lib/src/lib/core/services/library-data.service';
import { SignalBannerPopupComponent } from './../signal-banner-popup/signal-banner-popup.component';
import { DataConstants } from '@ffq-app-shared';

@Component({
  selector: 'app-signal-banner',
  templateUrl: './signal-banner.component.html',
  styleUrls: ['./signal-banner.component.scss']
})
export class SignalBannerComponent implements OnInit {
  /**
   * Action dialog component of about yourself component
   */
  actionDialogComponent: ActionDialogComponent = new ActionDialogComponent(this.dialog, this.libDataService);

  /**
   * Subscriptions  of quote component
   */
  private subscriptions: Subscription[] = [];
  /**
   * Landing state code of Signal Banner component
   */
  landingStateCode: string;
  /**
   * Signal banner implemented of Signal Banner component
   */
  isSignalBannerImpl: boolean;
  /**
   * Determines whether isBWQuote is implemented
   */
  isBWQuote = false;

  lastVisitedPage: string;

  currentPage: string;

  isAutoQuotePage: boolean;

  signalBannerLearnMore: string;

  isSignalDiscountImpl: boolean;

  isSignalDiscountSelected: boolean;

  /** flag to check flex implentation */
  isFXImpl: boolean;
  signalContent: string;
  /**
   * Create an instance of signal banner component
   * @param appStoreService service
   * @param stateRuleModel model
   * @param dialog dialog
   * @param libDataService service
   * @param autoStoreService service
   */
  constructor(
    private appStoreService: AppStoreService,
    private stateRuleModel: StateRuleModelService,
    private dialog: MatDialog,
    private libDataService: LibraryDataService,
    private autoStoreService: AutoStoreService
  ) { }

  ngOnInit() {
    /** subscription for landing state code */
    this.subscriptions.push(this.appStoreService.appDataZip$.pipe(take(1)).subscribe((data: any) => {
      this.landingStateCode = data[`landingStateCode`];
    }));

    /** subscription for getting project codes */
    this.subscriptions.push(this.appStoreService.getProjectCodeStatus$.pipe(take(1)).subscribe((data: any) => {
      this.isFXImpl = data[`isFlexImpl`];
    }));

    /** subscription for brightlined status */
    this.subscriptions.push(this.autoStoreService.getBrightLinedData$.subscribe((data: any) => {
      // tslint:disable-next-line:max-line-length
      // this.isBWQuote = data && data.autoCompanyCode && data.autoCompanyCode === 'B' ? true : false; // sonar code smell fix - rewritten as below
      this.isBWQuote = (data && data.autoCompanyCode && data.autoCompanyCode === 'B');
      // Show signal bar for farmers quote if 'isSignalBannerImpl' is true.
      // This value updated whenver quote changes from farmers to bristol west and vice versa.
      this.isSignalBannerImpl = this.stateRuleModel.getSignalBannerStateRule(this.landingStateCode).isSignalBannerImpl && !this.isBWQuote;
    }));

    this.appStoreService.getLastVisitedPage$.subscribe(data => {
      this.lastVisitedPage = data;
    }).unsubscribe();

    this.appStoreService.getCurrentPageForSB$.subscribe(data => {
      this.currentPage = data;
    }).unsubscribe();

    if (this.currentPage === 'Quote') {
      this.isAutoQuotePage = true;
      this.signalBannerLearnMore = 'FFQ_Auto_Quote_Signal_Banner_Learnmore_Link';
    } else {
      this.isAutoQuotePage = false;
      this.signalBannerLearnMore = 'FFQ_Auto_ThankYou_Signal_Banner_Learnmore_Link';
    }
    this.subscriptions.push(this.appStoreService.getSignalDiscountSelected$.subscribe(data => {
      if (data === 'Y') {
        this.isSignalDiscountSelected = true;
      }
    }));
    this.isSignalDiscountImpl = this.stateRuleModel.getSignalBannerStateRule(this.landingStateCode).isSignalBannerDiscountImpl;
    // tslint:disable-next-line:max-line-length
    if (!this.isBWQuote && !this.isSignalBannerImpl && this.currentPage !== 'Quote' && (this.isSignalDiscountImpl && !this.isSignalDiscountSelected)) {
      this.isSignalBannerImpl = true;
    }

    // US322299: Display the signal program information on Thank you page
    this.setSignalContent();
  }

  setSignalContent(): void {
    if (this.isFXImpl && this.landingStateCode !== DataConstants.STATE_CODES.ARIZONA
      && this.landingStateCode !== DataConstants.STATE_CODES.ILLINOIS) {
      this.signalContent = DataConstants.SIGNAL_INFORMATION_THANKYOU_PAGE.flexStatesContent;
    } else {
      this.signalContent = DataConstants.SIGNAL_INFORMATION_THANKYOU_PAGE.otherStatesContent;
    }
  }

  openSignalBannerPopup() {
    if (this.isAutoQuotePage) {
      this.dialog.open(SignalBannerPopupComponent, {
        panelClass: 'signal-banner-dialog',
        data: { dialogTitle: 'Action' }
      });
    } else {
      window.open('https://www.farmers.com/signal/', '_blank');
    }
  }

}
