<div class="quote-renters-progress-popup">
    <div class="quote-renters-progress-heading">
    <h3 role="heading" aria-level="2">Auto + Renter Insurance</h3>
    </div>

    <div  class="quote-renters-progress-text" tabindex="0">
        Calculating savings...
    </div>

    <div class="quote-renters-progress-loader">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</div>