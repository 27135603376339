<div role="heading" aria-level="3" class="coverage-sec-header">{{coverageParams.sectionHeading}}</div>

<div *ngIf="physicalDamageIndicatorFlag" aria-level="3" class="physical-damage-message">        
    This vehicle is not eligible for physical damage coverage.
</div>
<br *ngIf="physicalDamageIndicatorFlag"/>

<div class="coverage-sec-wrapper">    

<!--coverage data for breakpoint above small as per Zeplin - start-->
<div role="table">
<div class="layout-above-xsmall-bp">
    
        <div role="rowgroup">
    <div class="row" role="row">
        <div role="columnheader" class="item-col-header column left">{{coverageParams.leftHeading}}</div>
        <div role="columnheader" class="item-col-header column middle">{{coverageParams.middleHeading}}</div>
        <div role="columnheader" class="item-col-header column right">{{coverageParams.rightHeading}}</div>
    </div>
        </div>
         <div role="rowgroup">
    <div class="coverage-data-wrapper">
        <div class="row item-row" role="row" *ngFor="let rowData of coverageParams.sectionDatas">
            <div role="cell" class="item-col column left"><span>{{rowData.coverage}}</span></div>
            <div role="cell" class="item-col column middle">
                <span>{{rowData.limit}}</span>
                <span *ngIf="rowData.limit2" class="item-second-limit">{{rowData.limit2}}</span></div>
            <div role="cell" class="item-col column right"><span>{{rowData.costs}}</span></div>
        </div>
        <div class="row item-row">
            <div class="item-col-footer column footer-left-col">{{coverageParams.sectionDataFooter.text}}</div>
            <div class="item-col column footer-right-col">{{coverageParams.sectionDataFooter.costs}}</div>
        </div>
    </div>
    </div>

    
</div>
</div>
<!--coverage data for breakpoint above small as per Zeplin - end-->

<!--coverage data for breakpoint small as per Zeplin - start-->
<div class="layout-xsmal-bp">
    <div class="coverage-data-wrapper">
        <div class="row item-row-xs" *ngFor="let rowData of coverageParams.sectionDatas">
            <div class="column-xs left-xs"><span class="item-col-header-xs">{{coverageParams.leftHeading}}</span><span class="item-col-xs">{{rowData.coverage}}</span></div>
            <div class="column-xs middle-xs"><span class="item-col-header-xs">{{coverageParams.middleHeading}}</span>
                <span class="item-col-xs">{{rowData.limit}}</span>
                <span *ngIf="rowData.limit2" class="item-second-limit-xs">{{rowData.limit2}}</span>
            </div>
            <div class="column-xs right-xs"><span class="item-col-header-xs">{{coverageParams.rightHeading}}</span><span class="item-col-xs">{{rowData.costs}}</span></div>
        </div>
        <div class="row item-row-xs">
            <div class="column-xs left-xs"><span class="item-col-header-xs">{{coverageParams.sectionDataFooter.text}}</span><span class="item-col-xs">{{coverageParams.sectionDataFooter.costs}}</span></div>
        </div>
    </div>
     
</div>
<!--coverage data for breakpoint small as per Zeplin - end-->
</div>
