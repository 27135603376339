<div class="details-wrapper print-row">
<!--Quote premium section-->

<div class="sec-wrapper"><div role="heading" aria-level="2" class="policy-title">Your 6-Month Auto Policy Premium</div>
      <p class="premium-amnt">${{printData.premiumAmnt}}/{{paymentMode}}<a class="premium-amnt-sub" aria-roledescription="Foot-Notes" aria-label="Future payments will be automatically withdrawn from your bank/credit account and  Rate Subjects to Information Verification" href="#">*</a></p>
      <p class="policy-fee" *ngIf="printData.policyFee">(Includes ${{printData.policyFee}} membership fee)</p>
       <div class="remarks" aria-hidden="true"><p *ngIf= "!isPayInFull">*Future payments will be automatically withdrawn from your bank/credit account</p>
        <p *ngIf= "isFullPackageFullPremiumImpl">*Installment plan options may be available</p>
        <p>*Rate Subjects to Information Verification</p></div> </div>
<hr class="print-quote-line" aria-hidden="true">
<!--Discounts applied-->
<div *ngIf="!discountsAvailable" class="sec-wrapper">
    <div role="heading" aria-level="3" class="section-title">Discounts Applied</div>
    <div class="sec-data-wrapper">
        <ng-container *ngFor="let data of discountApplied">
            <div class="sec-item">{{data}}</div>
        </ng-container>
    </div>
    <div *ngIf="isTotalDiscountSavingsApplied" class="auto_total_discount">
        <img class="auto_quote-total-discount-icon" src="../../../../assets/images/discounts/icon-totalSavings-discount.png" alt="" />
        <div class="auto_quote-total-discount-text">Your policy premium includes a <strong>Total Discount Savings</strong> of <strong>${{printData.totalDiscount}}</strong> </div>
    </div>
</div>
<hr *ngIf="!discountsAvailable" class="print-quote-line" aria-hidden="true">
<!--Drivers Included-->
<div class="sec-wrapper">
    <div role="heading" aria-level="3" class="section-title">Drivers Included</div>
    <div class="sec-data-wrapper">
        <ng-container *ngFor="let data of driverList">
            <div class="sec-item">{{data}}</div>
        </ng-container>
    </div>
</div>

<hr class="print-quote-line" aria-hidden="true">
<!--Vehicles Included-->
<div class="sec-wrapper">
<div class="section-title">Vehicles Included</div>
<div class="sec-data-wrapper">
    <ng-container *ngFor="let data of vehicleList">
        <div class="sec-item1">{{data}}</div>
    </ng-container>
</div>
</div>
<hr class="print-quote-line" aria-hidden="true">
<!--Liability Coverage-->
<div class="sec-wrapper">
<app-print-quote-coverage-section [coverageParams]="liabilityCoverage"></app-print-quote-coverage-section>
</div>
<hr class="print-quote-line" aria-hidden="true">
<!--vehicle coverage-->
<div class="sec-wrapper">
<ng-container *ngFor="let vehCoverage of VehicleCoverage">
<app-print-quote-coverage-section [coverageParams]="vehCoverage"></app-print-quote-coverage-section>
<hr class="print-quote-line" aria-hidden="true">
</ng-container>
</div>
</div>