import { Directive, ElementRef, EventEmitter, OnInit, Output } from '@angular/core';
declare var google: any;

@Directive({
  selector: '[libAppGooglePlaces]'
})
export class GooglePlacesDirective implements OnInit {
  @Output() selectedPlace: EventEmitter<any> = new EventEmitter();

  private element: HTMLInputElement;
  constructor(el: ElementRef) {
    this.element = el.nativeElement;
  }
  ngOnInit() {
    const southWest = new google.maps.LatLng(43.969515, -99.901813);
    const northEast = new google.maps.LatLng(43.969515, -99.901813);
    const autoCompleteBound = new google.maps.LatLngBounds(southWest, northEast);
    const options = {
      bounds: autoCompleteBound,
      types: ['address'],
      componentRestrictions: { country: 'US' }
    };


    const autocomplete = new google.maps.places.Autocomplete(this.element, options);
    google.maps.event.addListener(autocomplete, 'place_changed', () => {

      const curAddressDetails = {
        street_number: '',
        route: '',
        locality: '',
        administrative_area_level_1: '',
        state_description: '',
        country: '',
        postal_code: '',
        home_address: ''
      };
      const componentForm = {
        street_number: 'short_name',
        route: 'long_name',
        locality: 'long_name',
        administrative_area_level_1: 'short_name',
        country: 'long_name',
        postal_code: 'short_name'
      };
      const place = autocomplete.getPlace();
      if (place) {
      place.address_components.forEach((value) => {
        const addressType = value.types[0];
        if (componentForm[addressType]) {
          curAddressDetails[addressType] = value[componentForm[addressType]];
          if (addressType === 'administrative_area_level_1') {
                curAddressDetails.state_description = value.long_name;
            }
          }
        });

      if (curAddressDetails.route) {
          if (curAddressDetails.street_number) {
            curAddressDetails.home_address = curAddressDetails.street_number + ' ' + curAddressDetails.route;
          } else {
            curAddressDetails.home_address = curAddressDetails.route;
          }
      }

      const result = {
              zip: curAddressDetails.postal_code,
              country: curAddressDetails.country,
              state: curAddressDetails.administrative_area_level_1,
              city: curAddressDetails.locality,
              new_address: curAddressDetails.home_address
            };
      this.selectedPlace.emit(result);
    }
     });

  }
}
