import { Component, OnInit, AfterViewInit } from '@angular/core';
import { NavigationDrawerService } from '@ffq-app-shared/components';
import { ServiceConstants } from '@ffq-app-shared/constants/service.constants';
import { Router, Event, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppStoreService } from '@ffq-app-store/app.store.service';
import { SpinnerService } from '@ffq-lib/src/lib/spinner/spinner.service';
declare const MediaAlphaExchange__load: any;

@Component({
  selector: 'app-intermediate-bw-page',
  templateUrl: './intermediate-bw-page.component.html',
  styleUrls: ['./intermediate-bw-page.component.scss']
})
export class IntermediateBWComponent implements OnInit, AfterViewInit {
  moduleName: any;
  landingZipCode: any;
  desktopNo: string;
  mobileNo: string;
  teleDesktop: string;
  teleMobile: string;
  sourcePhoneNumber: string;
  /**
   * Subscriptions  of business info component
   */
  private subscriptions: Subscription[] = [];

  constructor(private navDrawer: NavigationDrawerService,
              private router: Router,
              private appStoreService: AppStoreService,
              private spinnerService: SpinnerService
  ) { }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.spinnerService.hideLoader();
    });
    // throw new Error('Method not implemented.');
  }

  ngOnInit() {
    this.navDrawer.hideDrawer();
    this.navDrawer.hideEverything();
    /** Subscription for landing state code */
    this.subscriptions.push(this.appStoreService.appDataZip$.subscribe(appDataZip => {
      this.landingZipCode = appDataZip.landingZipCode;
    }));
    this.mediaAlphaPage();
    this.appStoreService.getPhoneNumberFromSrc$.subscribe((phoneNumber: any) => {
      this.sourcePhoneNumber = phoneNumber;
      this.setAgentPhoneNumber();
    });
  }

  findAnAgent() {
    window.location.href = ServiceConstants.FIND_AN_AGENT;
  }

  /**
   * Sets agent phone number
   */
  setAgentPhoneNumber() {
    if (this.sourcePhoneNumber) {
      this.teleDesktop = this.teleMobile = 'tel:' + this.sourcePhoneNumber.replace(/-/g, '');
      this.desktopNo = this.mobileNo =  this.sourcePhoneNumber;
    } else {
      this.teleDesktop = 'tel:18002069469';
      this.teleMobile = 'tel:18004209008';
      this.desktopNo = '1-800-206-9469';
      this.mobileNo = '1-800-420-9008';
    }
  }
  /**
   * media alpha page load
   */
  mediaAlphaPage() {
    (window as any).MediaAlphaExchange = {
      type: 'ad_unit',
      placement_id: 'UvcvA8abPJnyhj4QQXLrfEbdK7kpeg',
      version: '17',
      ua_class: 'auto',
      data: {
        zip: this.landingZipCode
      }
    };
    MediaAlphaExchange__load('Content');
  }
}
