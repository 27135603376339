<mat-form-field [formGroup]="params.formGroup" ngClass=" {{enableFocusRing?'enable-focus-ring':' '}} date-picker-field full-width-field form-elements " appearance="fill">
  <mat-label aria-hidden="true">{{ (params.placeholder) ? params.placeholder : null }}</mat-label>
  <input (focus)="focusHandle()" (paste)="eventHandlerKeyUp($event)" (input)="focusout($event.target.value)" [attr.aria-label]="params.ariaLabel" (blur)="validateDateFormat('change',$event.target.value)" [textMask]="mask" matInput [readonly]="params.readonly" 
  placeholder=""   [formControl]="params.formGroup.controls[params.formControllName]" minlength=10 attr.data-GTM="{{params.gtmTag}}" [type]="params.type === 'tel'? params.type:''"/>
  <input   hidden [matDatepicker]="picker" [matDatepickerFilter]="weekendFilter" [min]="params.minDate" [max]="params.maxDate" [value]="dateEntered" (dateChange)="valueChange($event)"/>
  <mat-datepicker-toggle id ="date_picker_toggle" matSuffix [for]="picker" [disabled]="params.isDisabled" ></mat-datepicker-toggle>
  <mat-datepicker #picker (opened)="streamOpened()" [startView]="startWithYear? startWithYear: 'month'" (monthSelected)="streamOpened()" panelClass="datePicker" [dateClass]="dateClass" (keydown.enter)="picker.open()" (closed) = "checkIfDateSelected()" [startAt]="params.startDate" [touchUi]="!isLargeScreen"></mat-datepicker>
  <mat-hint>{{params.assistiveText}}</mat-hint>
  <mat-error #matError aria-hidden ="true">
    <ng-container *ngIf="params.messages">
      <ng-container *ngFor="let key of Object.keys(params.messages)">
        <ng-container  *ngIf="((params.formGroup).get(params.formControllName)).hasError(key)">
          {{ params.messages[key] }}</ng-container>
      </ng-container>
    </ng-container>
  </mat-error>
</mat-form-field>