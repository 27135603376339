<div  class="auto__module__schedule-agent-confirmation-dialog">
    <h2 id="schedule-agent-confirmation-title" class="auto__module__schedule-agent-confirmation-dialog-heading">Appointment request submitted</h2>
    <mat-dialog-content id="schedule-agent-confirmation-description">
        <div id="schedule-agent-confirm-sub-title" class="auto__module__schedule-agent-confirmation-dialog-sub-heading">
        Thank you for submitting your request! 
        </div>
        <div id="schedule-agent-confirm-agent-details" class="auto__module__schedule-agent-confirmation-dialog-agent-details">
            <div class="auto__module__schedule-agent-confirmation-dialog-agent-name">{{agentConfirmdata.agentName}}</div>
            <div class="auto__module__schedule-agent-confirmation-dialog-schedule-date">{{agentConfirmdata.scheduleDate}}</div>
            <div *ngFor = "let scheduleTime of agentConfirmdata.scheduleTime" class="auto__module__schedule-agent-confirmation-dialog-schedule-time">
                <div>{{scheduleTime}}</div>
            </div>
        </div>
    </mat-dialog-content>
    <div class="auto__module__schedule-agent-confirmation-dialog-button">
        <button class="auto__module__schedule-agent-confirmation-dialog-button-done" (click)="onButtonClick()" data-GTM="FFQ_Auto_Sched_Agent_Confirm_Done_button">Done</button>
    </div>
</div>
