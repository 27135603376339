import { Component, OnInit } from '@angular/core';
import { DataConstants } from '@ffq-app-shared/constants/data.constants';
import { DriverStateRuleModel } from '@ffq-app-shared/model/state.rule.model';
import { StateRuleModelService } from '@ffq-app-shared/services/state-rule-model.service';
import { Subscription } from 'rxjs';
import {  MatDialogRef } from '@angular/material/dialog';
import {  AppStoreService } from '@ffq-app-store/app.store.service';
import { take } from 'rxjs/operators';
import { KnockOutService } from '@ffq-app-auto/shared/services/knock-out.service';
import { Router } from '@angular/router';
import { AutoStoreService } from '@ffq-app-auto/store/auto.store.service';
import { DataLayerService } from '@ffq-app-core/services/data-layer.service';
import { DataService } from '@ffq-app-core/services/data.service';
import { AgentData, SaveActionsRequest, DNQMessage } from '@ffq-app-shared/model/common.data.model';
import { SegmentService } from '@ffq-app-shared/services/segment.service';
import { QuoteBrightlinedProps } from '@ffq-app-shared/model/segment.data.model';

@Component({
  selector: 'app-bristol-west-popup',
  templateUrl: './bristol-west-popup.component.html',
  styleUrls: ['./bristol-west-popup.component.scss']
})
export class BristolWestPopupComponent implements OnInit {
  dialogTitle: string;
 bristolwestPopupData: any; 
  private subscription: Subscription;
  driverStateRuleModel: DriverStateRuleModel;
  landingStateCode: any;
  isContinueEnable: boolean;
  emailAddress: any;
  applicantName: string;
  quoteNumber: any;
  imageSrc = '../../../assets/images/';
  isEEImpl: boolean;
  isBWDirectSalesImpl: boolean;
  isBrightlineDNQImpl: boolean;
  /**
   * Variable to handle ADA changes for BW and FM Logo
   */
  logoText: string;
  teleDesktop: string;
  desktopNo: string;
  sourcePhoneNumber: string;
  landingZipCode: string;
  agentAssigned = false;
  agentDetails = {} as AgentData;
  bWDirectSaleContact: string;
  lastStepVisited: any;
  sourceIndicator: string;
  isDNQState = false;
  isBWOrganicFlowState = false;
  /* Bright line implemented for Organic Flow */
  isBrghtLineOrganicFlowImpl = false;
  isAwsSourceFlow = false;
  isAwsSourceFlowExempted = false;
  quoteSourceIndicator: string;
  isDNQPrivacyPolicyEnabled = false;
  isDNQSummaryOfRightsEnabled = false;
  isDNQYourRightsEnabled = false;
  privacyPolicyLink = DataConstants.BW_DNQ_EXTERNAL_URL.URL;
  isCollapsed = true;
  bwReasonMappings = DataConstants.BRISTOLWEST_REASON_VERBIAGE_MAP;
  bwBrightLineReason: string;
  dnqReasons: DNQMessage[] = [];
  dnqMessageSectionData: any;
  showDnqMessage = false;
  bwPopupDescription = '';
  isBWRedState = false;
  isFGIAPopupShow = true;

  constructor(
    public dialogRef: MatDialogRef<BristolWestPopupComponent>,
    private stateRuleModel: StateRuleModelService,
    private appStoreService: AppStoreService,
    private knockoutService: KnockOutService,
    private router: Router,
    private autoStoreService: AutoStoreService,
    private dataLayerService: DataLayerService,
    private dataService: DataService,
    private segmentService: SegmentService) {
  }

  ngOnInit() {
    this.subscription = this.appStoreService.appDataZip$.pipe(take(1)).subscribe((data: any) => {
      this.landingStateCode = data[`landingStateCode`];
      this.landingZipCode = data[`landingZipCode`];
    });
    this.appStoreService.getPhoneNumberFromSrc$.subscribe((phoneNumber: any) => {
      this.sourcePhoneNumber = phoneNumber;
    });
    this.appStoreService.appError$.subscribe((errorObj: any) => {
      if (errorObj && Object.keys(errorObj).length !== 0 && errorObj.errorInfo.errorCode === 'EG033') {
        this.isBWRedState = true;
        if (errorObj.errorInfo.showPopUpType === DataConstants.DM_POPUP_TYPE_CONSTANTS.NOFGIA_BWPOP
          || errorObj.errorInfo.showPopUpType === DataConstants.DM_POPUP_TYPE_CONSTANTS.NOFGIA_CIPOP) {
            this.isFGIAPopupShow = false;
        }
      }
    }).unsubscribe();
    if (!this.isBWRedState) {
    this.subscription = this.appStoreService.getProjectCodeStatus$.pipe(take(1)).subscribe((data: any) => {
      this.isEEImpl = data[`isEEImpl`];
      this.isBrghtLineOrganicFlowImpl = data[`isBrghtLineOrganicFlowImpl`];
      this.isBWDirectSalesImpl = data[`isBWDirectSalesImpl`];
      this.isBrightlineDNQImpl = data[`isBrightlineDNQImpl`];
      if (this.isEEImpl && !this.sourcePhoneNumber) {
        this.teleDesktop = 'tel:1-888-767-4030';
        this.desktopNo = '1-888-767-4030';
    } else {
      this.teleDesktop = 'tel:' + this.sourcePhoneNumber;
      this.desktopNo = this.sourcePhoneNumber;
    }
    });
    this.subscription = this.appStoreService.appData$.subscribe((data) => {
      if (data.customerData) {
        this.emailAddress = data.customerData.customer.communication.emailAddress;
        this.quoteNumber = data.quoteNumber;
      }
    });

    // US355260: AUTO DNQ - Update the applicant name in DNQ VERBIAGE
    this.subscription = this.appStoreService.appCustomerData$.subscribe((data) => {
      this.applicantName = data.firstName + ' ' + data.lastName;
    });

    this.appStoreService.getLastVisitedStep$.subscribe(data => {
      this.lastStepVisited = data;
    }).unsubscribe();

    this.subscription = this.appStoreService.appDataAgent$.subscribe((data: any) => {
      if (data.agent && (data.agent.agent || data.agent.agentInfoBean)) {
        this.agentDetails = data.agent.agent ? data.agent.agent : data.agent.agentInfoBean;
        if (this.agentDetails.reference === '1') {
          this.agentAssigned = true;
        } else if (this.agentDetails.reference === 'D') {
          this.agentAssigned = false;
        } else {
          this.agentAssigned = false;
        }
      }
    });

    this.appStoreService.sourceIndicatorData$.subscribe(data => {
      this.quoteSourceIndicator = data;
    }).unsubscribe();
  }
    this.isAwsSourceFlow = this.quoteSourceIndicator && (this.quoteSourceIndicator === DataConstants.AWS_SOURCE_INDICATOR.sourceIndicator_AS
      || this.quoteSourceIndicator === DataConstants.AWS_SOURCE_INDICATOR.sourceIndicator_AP);
    this.isAwsSourceFlowExempted = this.isAwsSourceFlow && this.landingStateCode !== DataConstants.STATE_CODES.FLORIDA;
    this.isBWOrganicFlowState = (this.isBrghtLineOrganicFlowImpl && !this.isAwsSourceFlowExempted) || this.isBWRedState;
    this.bristolwestPopupData = DataConstants.BWDiscalimerContent;
    this.imageSrc += this.bristolwestPopupData.logoSrc;

  if (!this.isBWRedState) {
    this.driverStateRuleModel = this.stateRuleModel.getDriverModuleStateRule(this.landingStateCode);

    /** Subcription for auto control data */
    this.subscription = this.autoStoreService.getAutoControlData$.subscribe((autoCtrlData) => {
      this.isDNQState = autoCtrlData.isDNQEligible && this.driverStateRuleModel.isDNQState;
      this.isDNQPrivacyPolicyEnabled = this.isDNQState && this.driverStateRuleModel.isDNQPrivacyPolicyEnabled;
      this.isDNQSummaryOfRightsEnabled = this.isDNQState && this.driverStateRuleModel.isDNQSummaryOfRightsEnabled;
      this.isDNQYourRightsEnabled = this.isDNQState && this.driverStateRuleModel.isDNQSummaryOfYourRightsText;
      this.bwBrightLineReason = autoCtrlData.bwBLReason;
    });
  }
    this.subscription = this.appStoreService.getStaticContent$.subscribe(() => {
      this.setStaticContent();
    });

    this.subscription.unsubscribe();
    // updated  the "bwPopUpSkipped: true" for identifying whether the user is skipped the bwPopUP
    this.appStoreService.updateAppControlData({ bwPopUpSkipped: true });

    this.logoText = 'Graphical Bristol West Insurance Group Logo';


    if (!this.agentAssigned && this.isBWDirectSalesImpl) {
      this.bWDirectSaleContact = 'contact a representative';
    } else {
      this.bWDirectSaleContact = 'contact an agent';
    }

    this.appStoreService.getLandingSourceFromSrc$.subscribe(data => {
      this.sourceIndicator = data;
    }).unsubscribe();
    // call segment tracking when BW pop up appears
    this.callSegmentTrackingForBWPopUp();
    this.autoStoreService.getDNQMessages$.subscribe((dnqData) => {
      this.dnqReasons = [...dnqData];
    });
    this.showDnqMessage = this.isBrightlineDNQImpl && this.dnqReasons.length > 0;
    this.dnqMessageSectionData = DataConstants.DNQ_MESSAGE_CONTENT;
    this.setBWPopupDescription();
  }
  /**
   * after view init
   */
  ngAfterViewInit(): void {
    // call Save Action Service
    this.callSaveActionService();
  }
  /**
   * segment tracking for BW pop up
   */
  private callSegmentTrackingForBWPopUp(): void {
    const brightlineInfo: QuoteBrightlinedProps = {
      point_of_brightline: this.lastStepVisited,
      accepted_bw: 'No',
    };
    this.segmentService.trackQuoteBrightlined(brightlineInfo);
  }
  /**
   * Call SaveActions Service
   */
  callSaveActionService(): void {
    if (this.showDnqMessage) {
      const mockRequest: SaveActionsRequest = {
        quoteNumber: this.quoteNumber,
        paymentMode: '',
        executeActions: ['DNQ'],
        saveMode: 'add',
        dnqMessage: this.generateDnqMessage()
      };
      this.appStoreService.saveActions(mockRequest);
    }
  }

  generateDnqMessage(): string | undefined {
    let completeDnqMessage = document.getElementById('dnqMessage')?.innerText;
    completeDnqMessage = completeDnqMessage?.replace(/\n/g, '');
    return completeDnqMessage;
  }

  continueWithBristolWest() {
    // updated "secondRateCallCompleted: true" for avoiding the unwanted rentering of quote page data
    this.autoStoreService.updateAutoControlData({ isBwPopupShown: true, secondRateCallCompleted: true });
    // updated for identying that the user is responded to the pop up
    this.appStoreService.updateAppControlData({ bwPopUpSkipped: false });
    this.dataLayerService.setDataLayerQuoteType('BW');
    this.appStoreService.updateBWCompanyCode({ isBWCompanyCode: true });
    // call segment tracking to update accepted BW switch
    this.segmentQuoteBrightlined('Yes');
    this.dialogRef.close('Yes');
  }

  /**
   * Segments quote brightlined
   * @param bwAccepted is BW accepted
   */
  segmentQuoteBrightlined(bwAccepted: string): void {
    const brightlineInfo: QuoteBrightlinedProps = {
      point_of_brightline: this.lastStepVisited,
      accepted_bw: bwAccepted,
      agency_type: this.agentDetails?.agencyType,
      agent_id: this.agentDetails.agentId
    };
    this.segmentService.trackQuoteBrightlined(brightlineInfo);
  }

  toKnockOut() {
    // call segment tracking to update denied BW switch
    this.segmentQuoteBrightlined('No');
    // updated "secondRateCallCompleted: true" for avoiding the unwanted rentering of quote page data
    this.autoStoreService.updateAutoControlData({ isBwPopupShown: true, secondRateCallCompleted: true });
    // updated for identying that the user is responded to the pop up
    this.appStoreService.updateAppControlData({ bwPopUpSkipped: false });
    this.dialogRef.close();
    const requestData = {
      quoteNumber: this.quoteNumber,
      knockOutReason: 'deniedBWSwitch',
      lastVisitedPage: 'Driver',
      pgLastVisitedPage: '1-1',
      emailAddress: this.emailAddress
    };
    this.autoStoreService.saveKnockOut(requestData);
    this.autoStoreService.getKnockOutResponse$.subscribe(response => {
      if (response !== null && response.knockOutMsg) {
        this.knockoutService.setKnockOutMessage(response.knockOutMsg);
      }
    });
  }

  toMonetizationScreen() {
    // call segment tracking to update denied BW switch
    this.segmentQuoteBrightlined('No');
    this.trackDirectOnlineQuoteForMonetization();
    // updated "secondRateCallCompleted: true" for avoiding the unwanted rentering of quote page data
    this.autoStoreService.updateAutoControlData({ isBwPopupShown: true, secondRateCallCompleted: true });
    // updated for identying that the user is responded to the pop up
    this.appStoreService.updateAppControlData({ bwPopUpSkipped: false });
    if (!this.isBWRedState || (this.isBWRedState && !this.isFGIAPopupShow)) {
      this.dialogRef.close();
      this.router.navigate(['/intermediatepage']);
    } else {
      this.dialogRef.close("others");
    }

  }

  /**
   * Segment tracking for online quotes redirected to Monetization Page
   * @param errorObj: Error Object
   */
  trackDirectOnlineQuoteForMonetization(): void {
    this.segmentService.trackCtaClicked('BW Offer Rejected');
  }
  /**
   *  US458077 - DNQ verbiage changes for BWBL popup
   */
  getVerbiageText(reasonStr: string): string {
    const bwReasonIdx = this.bwReasonMappings.findIndex(bwReason => bwReason.reason === reasonStr);
    return (bwReasonIdx < 0) ? 'BW_DNQ_VERBIAGE_TITLE' : this.bwReasonMappings[bwReasonIdx].verbiageText;
  }

  /**
   * To set the aem content
   */
  setStaticContent(): void {
    const driverContent = this.appStoreService.findContent('drivers');
    if (driverContent && driverContent.bristolwest) {
      const bwVerbiageText = this.getVerbiageText(this.bwBrightLineReason);
      this.bristolwestPopupData.maintitle = this.dataService.getStaticString(driverContent.bristolwest,
        'headerTitles', 'BW_MAIN_TITLE');
      this.bristolwestPopupData.title = this.dataService.getStaticString(driverContent.bristolwest, 'headerTitles', 'BW_TITLE');
      this.bristolwestPopupData.subContent = this.dataService.getStaticString(driverContent.bristolwest,
        'disclaimerText', 'BW_SUBCONTENT_DISCLAIMER');
      this.bristolwestPopupData.subContentPilot = this.dataService.getStaticString(driverContent.bristolwest,
        'disclaimerText', 'BW_SUBCONTENT_PILOT_DISCLAIMER');
      /** US355260: AUTO DNQ - CHANGES FOR BRISTOL WEST POP UP WINDOW  */
      /** US458077 - DNQ verbiage changes for BWBL popup */
      this.bristolwestPopupData.dnqContent = this.isDNQState ? this.dataService.getStaticString(driverContent.bristolwest,
        'disclaimerText', bwVerbiageText).replace('<applicant name>', this.applicantName) : null;
      this.bristolwestPopupData.fcraContent = this.isDNQState ? this.dataService.getStaticString(driverContent.bristolwest,
        'disclaimerText', 'BW_FCRA_VERBIAGE_DISCLAIMER').split('[1-800-456-6004] [www.consumerdisclosure.com]') : null;
      this.bristolwestPopupData.yourRights = this.dataService.getStaticString(driverContent.bristolwest,
        'disclaimerText', 'BW_SUMMARY_OF_RIGHTS_DISCLAIMER_1');
      this.bristolwestPopupData.yourRights += this.dataService.getStaticString(driverContent.bristolwest,
        'disclaimerText', 'BW_SUMMARY_OF_RIGHTS_DISCLAIMER_2');
      this.bristolwestPopupData.yourRights = this.isDNQYourRightsEnabled ? this.bristolwestPopupData.yourRights.split('\n\n') : null;
      this.bristolwestPopupData.summaryOfRights = this.isDNQSummaryOfRightsEnabled ?
        this.dataService.getStaticString(driverContent.bristolwest,
          'disclaimerText', 'BW_SUMMARY_OF_RIGHTS').split('\n\n') : null;
      this.bristolwestPopupData.bwMessage = this.dataService.getStaticString(driverContent.bristolwest,
            'disclaimerText', 'BW_ORGANICFLOW_MESSAGE');
      this.bristolwestPopupData.bwOrganicFlowMainContent = this.dataService.getStaticString(driverContent.bristolwest,
              'headerTitles', 'BW_ORGANICFLOW_MAIN_TITLE');
      this.bristolwestPopupData.bwOrganicFlowSubContent = this.dataService.getStaticString(driverContent.bristolwest,
              'disclaimerText', 'BW_ORGANICFLOW_SUBCONTENT');
      this.bristolwestPopupData.bwOrganicFlowSubContentPart1 = this.dataService.getStaticString(driverContent.bristolwest,
              'disclaimerText', 'BW_ORGANICFLOW_DISCLAIMER_SUBCONTENT_PART1');
      this.bristolwestPopupData.bwOrganicFlowSubContentPart2 = this.isBWOrganicFlowState ?
        this.dataService.getStaticString(driverContent.bristolwest,
          'disclaimerText', 'BW_ORGANICFLOW_DISCLAIMER_SUBCONTENT_PART2').split('[1-800-456-6004] [www.consumerdisclosure.com]') : null;
      if (this.bristolwestPopupData.bwOrganicFlowSubContentPart2) {
        const splitText = '\n\n';
        const disclaimerArray = this.bristolwestPopupData.bwOrganicFlowSubContentPart2[1].split(splitText);
        this.bristolwestPopupData.bwOrganicFlowSubContentPart3 = disclaimerArray[0];
        this.bristolwestPopupData.bwOrganicFlowSubContentPart4 = disclaimerArray[1];
      }
    }
  }

  /**
   * To redirect exteranl URL
   * US355260: AUTO DNQ - CHANGES FOR BRISTOL WEST POP UP WINDOW
   */
  goToExternalUrl(): void {
    window.open('https://www.consumerdisclosure.com', '_blank');
  }

  /**
   * Sets bwpopup description
   */
  setBWPopupDescription(): void {
    if (!this.showDnqMessage) {
      if (this.isDNQState && !this.isBWOrganicFlowState) {
        this.bwPopupDescription = this.bristolwestPopupData.dnqContent;
      } else if (!this.isDNQState && !this.isBWOrganicFlowState) {
        this.bwPopupDescription = this.bristolwestPopupData.title;
      } else if (this.isBWOrganicFlowState) {
        this.bwPopupDescription = this.bristolwestPopupData.bwMessage;
      }
    } else {
      this.bwPopupDescription = this.dnqMessageSectionData.thankYouMsg;
    }
  }

}
