import { Action, createAction, props } from '@ngrx/store';

// Listing down all the possible actions trigger,During Population write Success and Fail Actions for each

// TODO PNI

// On click of 'Add to Quote' for PNI
export const AddDetailsPNIDriver = createAction('[Auto-Driver] Add details to PNI Driver', props<{ reqPayload: any }>());

export const updatePNIDriver = createAction('[Auto-Driver] Update PNI Driver', props<{ reqPayload: any }>());

// On click of 'Add to Quote' for PNI
export const AddDetailsSpouseDriver = createAction('[Auto-Driver] Add details to PNI Spouse', props<{ reqPayload: any }>());

// On click of 'Add to Quote' for PNI
export const AddDetailsAdditionalDriver = createAction('[Auto-Driver] Add details to PNI Spouse', props<{ reqPayload: any }>());

export const FetchDropDownList = createAction('[Auto - Driver] Fetch Dropdown List');
export const FetchDropDownListSuccess = createAction('[Auto-Driver] Fetch DropDown List Success', props<{ resData: any, ctrlData: any }>());
export const FetchDropDownListFail = createAction('[Auto-Driver] Fetch DropDown List Fail', props<{ respError: any }>());
export const RemoveDriver = createAction('[Auto-Driver] Remove Driver', props<{ reqPayload: any }>());

export const CancelAddDriver = createAction('[Auto - Driver] Cancel Add Driver ', props<{ ctrlData: any }>());
export const UpdateControlData = createAction('[Auto - Driver] Update ControlData ', props<{ ctrlData: any }>());

/** US49164 : Fetch Dropdowns for Accidents & Violations- Actions START */
export const FetchDropDownIncidentCategoryUCCList = createAction('[Auto - Driver] Fetch DropDown Incident Category UCC List');
export const FetchDropDownIncidentCategoryUCCListSuccess = createAction('[Auto-Driver] Fetch DropDown Incident Category UCC List Success',
    props<{ resData: any, ctrlData: any }>());
export const FetchDropDownIncidentCategoryUCCListFail = createAction('[Auto-Driver] Fetch DropDown Incident Category UCC List Fail',
    props<{ respError: any }>());
export const FetchDropDownIncidentCategoryACCList = createAction('[Auto - Driver] Fetch Dropdown Incident Category ACC List');
export const FetchDropDownIncidentCategoryACCListSuccess = createAction('[Auto-Driver] Fetch DropDown Incident Category ACC List Success',
    props<{ resData: any, ctrlData: any }>());
export const FetchDropDownIncidentCategoryACCListFail = createAction('[Auto-Driver] Fetch DropDown Incident Category ACC List Fail',
    props<{ respError: any }>());
/** US49164 : Fetch Dropdowns for Accidents & Violations- Actions END */

export const ResetDriverState = createAction('[Auto-Driver] Delete All Drivers Added to Quote');
