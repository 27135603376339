<mat-dialog-content class="auto-module__signal-discount-banner-dialog">
    <div class="auto-module__signal-discount-banner-dialog-container">
        <div class="auto-module__signal-banner-dialog-header">
            <h2>{{signalPopUpHeader}}</h2>
        </div>
            <div class="auto-module__signal-banner-dialog-content-body" >
                <span>
                    <p>{{signalPopUParagraph}}</p>
                </span>
                <br aria-hidden="true"><br aria-hidden="true">
                <span>
                    <p>
                        How it works:
                    </p>
                </span>
                <br aria-hidden="true"><br aria-hidden="true">
                <span>
                    <p>{{signalPopUPStep1}}</p>
                </span>
                <br aria-hidden="true"><br aria-hidden="true">
                <span>
                    <p>
                        Step 2: After you complete your purchase, make sure to download the app from the link we text to you.
                    </p>
                </span>
                <br aria-hidden="true"><br aria-hidden="true">
                <span>
                    <p>
                        Step 3: Start driving and take 10 trips with the app within 30 days.
                    </p>
                </span>
            </div>
        <div class="auto-module__signal-discount-banner-footer">
            <button mat-button class="auto-module__signal-discount-banner-btn" (click)="closeModalPopup()" data-GTM="FFQ_Auto_Signal_Discount_Popup_Close_button">CLOSE</button>
        </div>
    </div>

</mat-dialog-content>
