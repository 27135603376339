import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SpinnerService {
  private subject = new Subject<any>();
  private farmersLoaderSubject = new Subject<any>();
  private existingSpinnerCount = 0;
  /**
   * Shows loader
   */
  showLoader() {
    this.existingSpinnerCount++;
    this.setSpinStatus(true);
  }

  // US275714: UI - Update Auto  - Implement Loader Screen for Partner Quotes
  /**
   * Shows loader with farmers logo
   */
  showFarmersLoader(): void {
    this.setFarmersLoaderStatus(true);
  }

  /**
   * Hides Farmers loader
   */
  hideFarmersLoader(): void {
    setTimeout(() => {
      this.setFarmersLoaderStatus(false);
    }, 1000);
  }

  /**
   * Hides loader
   */
  hideLoader() {

    if (this.existingSpinnerCount > 0) {
      this.existingSpinnerCount--;
    }

    if (this.existingSpinnerCount === 0) {

      setTimeout(() => {
        this.setSpinStatus(false);
      }, 1000);

    }

  }

  /**
   * Sets spin status
   * @param spin state
   */
  setSpinStatus(spinState: boolean) {
    this.subject.next({ spinStatus: spinState });
  }

  // US275714: UI - Update Auto  - Implement Loader Screen for Partner Quotes
  /**
   * Sets farmers loader status
   * @param spin state
   */
  setFarmersLoaderStatus(spinState: boolean): void {
    this.farmersLoaderSubject.next({ farmersLoaderStatus: spinState });
  }

  /**
   * Gets spin status
   * @returns spin status
   */
  getSpinStatus(): Observable<any> {
    return this.subject.asObservable();
  }

  /**
   * Gets farmers loader subject
   * @returns farmers loader subject as observable
   */
  getFarmersLoaderStatus(): Observable<any> {
    return this.farmersLoaderSubject.asObservable();
  }

  /**
   * Sets existing spinner count
   * @param count number
   */
  setExistingSpinnerCount(count: number) {
    this.existingSpinnerCount = count;
  }

}
