// tslint:disable-next-line:no-empty-interface
import * as fromRoot from '../../store/app.state';
import { PrefillData, Quote, PrequalifiedDiscounts, VinVehicle, AutoState, AutoControlData } from '@ffq-app-shared/model/common.data.model';
import { RateQuoteProgressStatus } from '@ffq-app-shared/enums/flow.identifier.enum';

/* 1- Extend AppState interface and add new feature state to it */
export interface AppState extends fromRoot.AppState {
  auto: AutoState;
}

export { AutoState } from '@ffq-app-shared/model/common.data.model';

/* 3- Define initail state of the feature */
export const initialState: AutoState = {
  data: {
    prefillData: {
      currentInsuredAnswer: null,
      driver: [],
      prospectQuoteReportId: null,
      quoteNumber: null,
      vehicle: [],
    } as PrefillData,
    quote: {} as Quote,
    vehicles: [],
    drivers: [],
    prequalifiedDiscounts: [] as PrequalifiedDiscounts[],
    driverVehicleAssignment: [],
    vinLookupVehicle: {} as VinVehicle,
    discount: null,
    knockout: {}
  },
  error: null,
  controlData: { } as AutoControlData,
  serviceControlData: null
};

