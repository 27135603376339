<div id="chat_window_1" class='chat-window' *ngIf="isChatbotAvailable" [ngClass]="cssClass">
    <div class="chatButton" *ngIf='!chatPanel.expanded' (click)="onChatOpen()">
        <div class="chatButtonContent">
            <img class="chatButtonIcon" src="assets/images/icon-chat.png" alt="Chat icon">
            <div class="chatButtonDiv"> <p class="chatText">CHAT</p> </div>
        </div>
    </div>
    <mat-expansion-panel
        #chatPanel
        class="chatBox-padding chat-align" 
        [ngClass]="{'chatBox-padding chat-align' : true, 'hidden': !chatPanel.expanded}"
        (opened)="onChatOpen()"
        (closed)="chatbotPanelOpenState = false" 
        hideToggle="true">
        <mat-expansion-panel-header class="chat-window-header-panel">
            <span *ngIf="chatbotPanelOpenState" class="spanalign " ng-enter="ignoreClick($event);" ng-click="ignoreClick($event);">
                <img class="minimizeIconChatbot fa pull-right"  src="assets/images/minimize-icon.png" 
                     alt="Minimize button" id="minLogo">
                <img class="img-circle imageSize fa-right inactiveLink"  src="assets/images/thumbnail.png" alt="" id="imageLogo">
            </span>
            <span tabindex="0"
                *ngIf="!chatbotPanelOpenState" 
                class="spanalign ">
                <span class="chat-size fa-left chattext-color">{{chatHeading}}</span>
                <img class="fa-2x fa fa-right" src="assets/images/conversation-icon.png" alt="Conversation icon" id="minLogo">
            </span>
        </mat-expansion-panel-header>
      
        <iframe title="iframe" id="iframe_id" class="iframe-body" src="" name="iframe_a"></iframe>
      
      </mat-expansion-panel>    
</div>