import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ScheduleAgentConfirmationData } from '@ffq-app-shared/model/common.data.model';
import { ScheduleAgentService } from '@ffq-app-shared/services/schedule-agent.service';
import { SegmentService } from '@ffq-app-shared/services/segment.service';

@Component({
  selector: 'app-schedule-agent-confirmation',
  templateUrl: './schedule-agent-confirmation.component.html',
  styleUrls: ['./schedule-agent-confirmation.component.scss']
})
export class ScheduleAgentConfirmationComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public agentConfirmdata: ScheduleAgentConfirmationData,
    private matDialogRef: MatDialogRef<ScheduleAgentConfirmationComponent>,
    private scheduleAgentService: ScheduleAgentService) {
  }
  /**
   * on click of done
   */
  onButtonClick(): void {
    this.scheduleAgentService.onConfmDialogClose();
    this.matDialogRef.close();
  }
}
