import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { DataConstants } from '@ffq-app-shared/constants/data.constants';
import { SaveAndFinishValidation } from '@ffq-app-shared/model/save-and-finish.validation';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AppStoreService } from '@ffq-app-store/app.store.service';
import { take } from 'rxjs/operators';
import { SaveAndFinishLaterObject } from '@ffq-app-auto/drivers';
import { NavigationDrawerService } from '../navigation-drawer/navigation-drawer.service';
import { AutoStoreService } from '@ffq-app-auto/store/auto.store.service';
import { RestErrorHandlerService } from '@ffq-app-core/services/rest.error.service';

import { DialogData } from '@ffq-lib/src/lib/dialog';


@Component({
  selector: 'app-save-and-finish',
  templateUrl: './save-and-finish.component.html',
  styleUrls: ['./save-and-finish.component.scss']
})
export class SaveAndFinishComponent implements OnInit {

  /**
   * Save and finish form group of save and finish component
   */
  saveAndFinishFormGroup: FormGroup;
  /**
   * Save and finish dialog title of save and finish component
   */
  saveAndFinishDialogTitle: string;
  /**
   * Save and finish dialog content of save and finish component
   */
  saveAndFinishDialogContent: string;
  /**
   * Save and finish popup data of save and finish component
   */
  saveAndFinishPopupData: any;
  /**
   * Save and finish params of save and finish component
   */
  saveAndFinishParams = new SaveAndFinishValidation();
  /**
   * Subscriptions  of save and finish component
   */
  private subscriptions: Subscription[] = [];
  /**
   * Quote number of save and finish component
   */
  public quoteNumber: any;
  /**
   * App data of save and finish component
   */
  private appData: any;
  /**
   * Show email div of save and finish component
   */
  showEmailDiv: boolean;
  /**
   * Save and finish later object of save and finish component
   */
  saveAndFinishLaterObject = {} as SaveAndFinishLaterObject;
  /**
   * Email entered of save and finish component
   */
  emailEntered: any;
  /**
   * Determines whether email view required is
   */
  isEmailViewRequired = true;
  /**
   * Save and finish later of save and finish component
   */
  saveAndFinishLater: any;
  /**
   * Button text1 of save and finish component
   */
  buttonText1: any;
  /**
   * Button text2 of save and finish component
   */
  buttonText2: any;

  errorDetails: any;
  windowUrl = window.location.href;

  /**
   * Creates an instance of save and finish component.
   * @param fb FormBuilder
   * @param dialogRef MatDialog
   * @param appStoreService AppStoreService
   * @param navigationDrawerService NavigationDrawerService
   */
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialog,
    private appStoreService: AppStoreService,
    private autoStoreService: AutoStoreService,
    private navigationDrawerService: NavigationDrawerService,
    private restErrorHandlerService: RestErrorHandlerService,
  ) {
    this.subscriptions.push(this.appStoreService.appData$.subscribe((data) => {
      this.appData = data;
    }));
  }

  /**
   * on init
   */
  ngOnInit() {
    this.buildForm();
    this.quoteNumber = this.appData.quoteNumber;
    if (this.appData.customerData.customer) {
      if (!this.appData.customerData.customer.communication.emailAddress) {
        this.isEmailViewRequired = true;
      } else {
        this.isEmailViewRequired = false;
      }
    }
    this.saveAndFinishPopupData = DataConstants.SaveAndFinishContent;
    this.saveAndFinishDialogTitle = this.saveAndFinishPopupData.maintitle;
    this.saveAndFinishDialogContent = this.saveAndFinishPopupData.subContent;
    this.buttonText1 = this.saveAndFinishPopupData.buttonText1;
    this.buttonText2 = this.saveAndFinishPopupData.buttonText2;
    /** Subscription for getting validateZip response */
    this.subscriptions.push(this.appStoreService.getValidateEmail$.subscribe(validateEmailResponse => {
      if (validateEmailResponse) {
        this.provideEmailCodeValidationMessage(validateEmailResponse);
      }
    }));
  }
  /**
   * Builds form
   * @returns form
   */
  buildForm(): any {
    this.saveAndFinishFormGroup = this.fb.group({
      emailAddress: ['', this.saveAndFinishParams.emailAddress.validators]
    });

    for (const field of Object.keys(this.saveAndFinishFormGroup.controls)) {
      this.saveAndFinishParams[field][`formGroup`] = this.saveAndFinishFormGroup;
    }
  }
  /**
   * Continues with quote to close the dialog
   */
  continueWithQuote() {
    this.dialogRef.closeAll();
  }

  /**
   * Saves and exit
   * @param isValidEmail to check email validity
   */
  saveAndExit(isValidEmail) {
    if (this.windowUrl.indexOf('/additionalInfo') > -1) {
      this.handleSaveQuoteForAdditionalInfo();
    }
    this.emailEntered = this.saveAndFinishFormGroup.get('emailAddress').value;
    if (!isValidEmail && this.emailEntered) {
      // Calling the external service
      this.appStoreService.validateEmail({ address: this.emailEntered });
    } else {
      let lastVisitedPage = null;
      const vsitedPageSub = this.appStoreService.appControlData$.subscribe((data) => {
        lastVisitedPage = data.lastVisitedPage;
      });
      vsitedPageSub.unsubscribe();
      switch (lastVisitedPage) {
        case 'Discount' : this.autoStoreService.isModuleSaveAndFinish({ isDiscountSave: 'Yes' }); break;
        case 'Quote' :  this.autoStoreService.isModuleSaveAndFinish({ isQuoteSave: 'Yes' }); break;
        case 'Assignment' : this.autoStoreService.isModuleSaveAndFinish({ isDriverAssignment: 'Yes' }); break;
        case 'RideShare' :  this.autoStoreService.isModuleSaveAndFinish({ isRideShare: 'Yes' }); break;
        case 'Signal' : this.autoStoreService.isModuleSaveAndFinish({ isSignalDiscountSave: 'Yes' }); break;
        default: this.saveAndFinishLaterObject = this.navigationDrawerService.createRequestPayload(this.emailEntered);
                 this.appStoreService.saveAndFinishLater(this.saveAndFinishLaterObject); break;
      }
      // if (lastVisitedPage === 'Discount') {
      //   this.isDiscountSaveJson = { isDiscountSave: 'Yes' };
      //   this.autoStoreService.isModuleSaveAndFinish(this.isDiscountSaveJson);
      // } else if (lastVisitedPage === 'Quote') {
      //   this.autoStoreService.isModuleSaveAndFinish({ isQuoteSave: 'Yes' });
      // } else if (lastVisitedPage === 'Assignment') {
      //   this.autoStoreService.isModuleSaveAndFinish({ isDriverAssignment: 'Yes' });
      // } else {
      //   this.saveAndFinishLaterObject = this.navigationDrawerService.createRequestPayload(this.emailEntered);
      //   this.appStoreService.saveAndFinishLater(this.saveAndFinishLaterObject);
      // }
      /* Subscription for Save And Finish */
      this.subscriptions.push(this.appStoreService.saveAndFinishLaterCtrlData$.subscribe(saveAndFinishLater => {
        this.saveAndFinishLater = saveAndFinishLater;
        if (this.saveAndFinishLater === 'success') {
          window.location.replace('/');
        } else {
           this.handleSaveFinishError();
           this.dialogRef.closeAll();
        }
      }));
    }
  }
  @HostListener('window:keyup.esc')onkeyup() {
    this.dialogRef.closeAll();
 }
  /**
   * Provides email code validation message
   * @param validateEmailResponse validateemailResponse
   */
  provideEmailCodeValidationMessage(validateEmailResponse) {
    if (validateEmailResponse.status === 'valid' || validateEmailResponse.status === 'accept_all') {
      this.saveAndExit(true);
    } else {
      this.saveAndFinishParams.emailAddress.messages.pattern = 'Email address entered is invalid';
      this.saveAndFinishFormGroup.get('emailAddress').setErrors({ pattern: true });
    }
  }

  handleSaveFinishError() {
    /** Subscription for fetching error information */
    this.appStoreService.appError$.subscribe((response: any) => {
    /** To make sure the response has error information */
    if (response && Object.keys(response).length !== 0) {
      this.errorDetails = response;
      const errorCode = this.errorDetails.errorInfo.errorCode;
      this.restErrorHandlerService.checkRestResponse(errorCode, ' ', 'A');
    }
  });
  }

  // US399428: DCM- Save and Exit functionality on the conflict resolution page
  handleSaveQuoteForAdditionalInfo(): void {
    window.location.replace('/');
  }
}
