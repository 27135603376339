<mat-form-field [formGroup]="params.formGroup" ngClass=" {{enableFocusRing?'enable-focus-ring':' '}} input-text-field full-width-field form-elements" appearance="fill" color="primary">
  <mat-label aria-hidden="true">{{ (params.placeholder) ? params.placeholder : null }}</mat-label>
  <input (keyup)="eventHandlerKeyUp()" autocomplete="{{params?.autoComplete}}" id="removeLastpass" (change)="eventHandlerKeyUp()" (paste)="onPaste($event)"  (focus)="scrollIntoViewIfNeeded()"(blur)="blurHandle()" [attr.aria-label]="params.ariaLabel" libField [validate]="params.directiveValidators" matInput [formControl]="params.formGroup.controls[params.formControllName]" type="{{params.type}}" maxlength="{{params.maxLength}}" [attr.data-GTM]="params.gtmTag"/>
  <mat-error [attr.aria-hidden]="params.ariaHiddenInd == true ? false : true">
    <ng-container *ngIf="params.messages">
      <ng-container *ngFor="let key of Object.keys(params.messages)">
        <ng-container *ngIf="((params.formGroup).get(params.formControllName)).hasError(key)">
          {{getErrorMessage(params.messages,
            key, ((params.formGroup).get(params.formControllName)).value)}}
        </ng-container>
      </ng-container>
    </ng-container>
  </mat-error>
  <mat-hint *ngIf="params.assistiveText" align="start">
    <strong>{{params.assistiveText}}</strong>
  </mat-hint>
</mat-form-field>