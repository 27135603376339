import * as fromAuto from './auto.state';
import { createReducer } from '@ngrx/store';
import { on } from '@ngrx/store';
import { Action } from '@ngrx/store';

/** Auto Actions */
import * as fromAutoActions from './auto.actions';
import { stat } from 'fs';
import moment from 'moment';
import { NullTemplateVisitor } from '@angular/compiler';
import { Discount, RateResponse, Driver } from '@ffq-app-shared/model/common.data.model';
import { DataConstants } from '@ffq-app-shared';



const autoReducer = createReducer(fromAuto.initialState,
  on(fromAutoActions.UpdateAutoState, (state, { reqPayload }) =>
    ({
      ...state,
      data: reqPayload.data ? { ...state.data, ...reqPayload.data } : state.data,
      controlData: reqPayload.controlData ? { ...state.controlData, ...reqPayload.controlData } : state.controlData,
      error: reqPayload.error ? { ...state.error, ...reqPayload.error } : state.error,
      serviceControlData: reqPayload.serviceControlData ?
        { ...state.serviceControlData, ...reqPayload.serviceControlData } : state.serviceControlData
    })),
  on(fromAutoActions.PrefillDataSuccess, (state, { respData, ctrlData, respErr }) =>
    ({
      ...state, error: Object.assign({}, state.error, respErr),
      data: Object.assign({}, state.data, respData),
      controlData: Object.assign({}, state.controlData, ctrlData)
    })),
  on(fromAutoActions.PrefillDataFail, (state, { respErr, ctrlData }) =>
    ({
      ...state, error: respErr,
      controlData: Object.assign({}, state.controlData, ctrlData)
    })),
  on(fromAutoActions.PrefillControlDataClear, (state, { ctrlData }) =>
    ({
      ...state, controlData: Object.assign({}, state.controlData, ctrlData)
    })),
  on(fromAutoActions.SelectPrefillDriver, (state, { ctrlData }) =>
    ({
      ...state,
      controlData: Object.assign({}, state.controlData, ctrlData)
    })),
  on(fromAutoActions.ClearSelectedPrefillDriver, (state, { ctrlData }) =>
    ({
      ...state,
      controlData: Object.assign({}, state.controlData, ctrlData)
    })),
  /**
   * delete the vehicle object using the adpfVehicleReference from the store,
   * 'adpfVehicleReference' is updated from add vehicle page
   */
  on(fromAutoActions.DeleteVehicleFromPrefillData, (state) =>
    ({
      ...state,
      data: {
        ...state.data, prefillData: {
          ...state.data.prefillData, vehicle: state.data.prefillData.vehicle.filter(vehicleObject =>
            vehicleObject.reference !== state.data.adpfVehicleReference)
        }
      }
    })),

  /** set the adpf vehicle reference number which is used to delete when adding it to quote */
  on(fromAutoActions.UpdateAdpfVehicleReference, (state, { reqPayload }) =>
    ({
      ...state,
      data: { ...state.data, adpfVehicleReference: reqPayload }
    })),
  on(fromAutoActions.RemoveDriverFromPrefillList, (state, { reqPayload }) =>
    ({
      ...state,
      data: {
        ...state.data, prefillData: {
          ...state.data.prefillData, driver: state.data.prefillData.driver.filter(driverObject =>
            driverObject.reference !== reqPayload)
        }
      }
    })),

  /** clear all data added to auto quote */
  on(fromAutoActions.ClearAllDataFromAuto, (state) =>
    ({
      ...state,
      ...fromAuto.initialState
    })),

  /** set bw pop up shown flag , on BW POP UP display */
  on(fromAutoActions.BWActionPopupShown, (state) =>
    (
      {
        ...state, controlData: { ...state.controlData, isBwPopupShown: true }
      })),

  /** set bw pop up shown flag , on BW POP UP display */
  on(fromAutoActions.UpdateAutoCompanyCode, (state, { reqPayload }) =>
    (
      {
        ...state, controlData: { ...state.controlData, autoCompanyCode: reqPayload }
      })),
  on(fromAutoActions.UpdateAutoServiceControlData, (state, { serviceCtrlData }) =>
    (
      {
        ...state,
        serviceControlData: { ...state.serviceControlData, ...serviceCtrlData }
      })),
  /** update auto control data */
  on(fromAutoActions.UpdateAutoControlData, (state, { ctrlData }) => ({
    ...state,
    controlData: { ...state.controlData, ...ctrlData }
  }),

  ),
  /** update prequalified discount data */
  on(fromAutoActions.UpdatePrequalifiedDiscountList, (state, { prequalifiedDiscounts }) =>
   ({ ...state, data: { ...state.data, prequalifiedDiscounts }}),
  ),

  on(fromAutoActions.UpdateResidenceType, (state, { respData }) =>
   ({ ...state, data: {
    ...state.data,
    discount: {...state.data.discount, residenceType: respData}
  }}),
  ),

  // on(fromAutoActions.IsDiscountSave, (state, { reqPayload }) =>
  //   ({
  //     ...state,
  //     controlData: { ...state.controlData, ...reqPayload }
  //   })),

  // on(fromAutoActions.IsQuoteSave, (state, { reqPayload }) =>
  //   ({
  //     ...state,
  //     controlData: { ...state.controlData, ...reqPayload }
  //   })),
  //   on(fromAutoActions.IsQuoteSave, (state, { reqPayload }) =>
  //   ({
  //     ...state,
  //     controlData: { ...state.controlData, ...reqPayload }
  //   })),
  //   on(fromAutoActions.IsDriverAssignment, (state, { reqPayload }) =>
  //   ({
  //     ...state,
  //     controlData: { ...state.controlData, ...reqPayload }
  //   })),
  on(fromAutoActions.IsModuleSaveAndFinish, (state, { reqPayload }) =>
    ({
      ...state,
      controlData: { ...state.controlData, ...reqPayload }
    })),

  /** updating quote data to store in retrieve quote flow */
  on(fromAutoActions.UpdateAutoStoreFromRQ, (state, { reqPayload }) =>
    ({
      ...state,
      ...reqPayload
    })),

  /** updating default performRate response to store */
  on(fromAutoActions.PerformDefaultRateQuoteSuccess, (state, { respData, respError, serviceCtrlData }) => {
    /** US254852: Changing payPolicy based on rate response */
    const updatedDiscountData = UpdateDiscountDataUsingResponse(state.data.discount, respData);
    return {
      ...state, data: {
        ...state.data,
        quote: { ...state.data.quote, ...respData },
        discount: { ...updatedDiscountData },
        rentersRate: state.controlData.eligibleForAutoRentersBundle === true ? state.data.rentersRate : null
      }, serviceControlData: { ...state.serviceControlData, ...serviceCtrlData },
      error: respError
    };
  }),
  /** updating performRate service error to store */
  on(fromAutoActions.PerformRateQuoteFail, (state, { respErr, ctrlData }) =>
    ({ ...state, error: respErr, controlData: { ...state.controlData, ...ctrlData } })),
  /** updating performRate service to store */
  on(fromAutoActions.PerformRateQuoteSuccess, (state, { respData, ctrlData, respError }) => {
    const updatedQuoteData = UpdateQuoteDataUsingResponse(state.data.quote, respData);
    return {
      ...state, data: {
        ...state.data,
        quote: { ...updatedQuoteData }
      }, controlData: { ...state.controlData, ...ctrlData },
      error: respError
    };
  }),

  /** STROING THE RESPONSE OF VIN LOOKUP */
  on(fromAutoActions.VINLookupSuccess, (state, { respData }) =>
    ({ ...state, data: { ...state.data, vinLookupVehicle: respData }, error: '' })),

  /** STORING THE RESPONSE OF VALIDATE LICENSE */
  on(fromAutoActions.ValidateLicenseSuccess, (state, { respData }) =>
    ({ ...state, data: { ...state.data, validateLicense: respData.resp }, error: '' })),

  /** CLEARING THE RESPONSE OF VALIDATE LICENSE */
  on(fromAutoActions.ClearValidateLicense, (state, { respData }) =>
    ({ ...state, data: { ...state.data, validateLicense: respData.resp }, error: '' })),

  /** ADDING THE VEHICLE SAVED TO QUOTE */
  on(fromAutoActions.SaveVehicleSuccess, (state, { reqPayload, serviceCtrlData }) =>
    ({
      ...state, data: {
        ...state.data, vehicles: [...state.data.vehicles, reqPayload],
        vinLookupVehicle: fromAuto.initialState.data.vinLookupVehicle,
        prefillData: {
          ...state.data.prefillData, vehicle: state.data.prefillData.vehicle.filter(vehicleObject =>
            vehicleObject.reference !== state.data.adpfVehicleReference)
        },
      }, serviceControlData: { ...state.serviceControlData, ...serviceCtrlData },
      error: null
    })),
  /** REMOVING THE DELETED VEHICLE  */
  on(fromAutoActions.DeleteVehicleSuccess, (state, { reqPayload, serviceCtrlData }) =>
    ({
      ...state, data: {
        ...state.data, vehicles:
          [
            ...state.data.vehicles.filter(element =>
              element.reference !== reqPayload.reference)],
      }, serviceControlData: { ...state.serviceControlData, ...serviceCtrlData },
      error: null
    })),
  /** UPDATED THE VEHICLE DATA USING REFERENCE NUMBER  */
  on(fromAutoActions.EditVehicleSuccess, (state, { reqPayload, serviceCtrlData }) => {
    const editedVehicle = state.data.vehicles.find(element => element.reference === reqPayload.reference);
    const updatedDriver = JSON.parse(JSON.stringify(reqPayload));
    updatedDriver.primaryDriver = editedVehicle.primaryDriver ? editedVehicle.primaryDriver : null;
    // updatedDriver.rideShareVeh = editedVehicle.rideShareVeh ? editedVehicle.rideShareVeh : null;
    return {
      ...state, data: {
        ...state.data, vehicles:
          [...state.data.vehicles.filter(element =>
            element.reference !== reqPayload.reference), updatedDriver],
        vinLookupVehicle: fromAuto.initialState.data.vinLookupVehicle,
      }, serviceControlData: { ...state.serviceControlData, ...serviceCtrlData },
      error: null
    };
  }),
  on(fromAutoActions.SelectDriver, (state, { ctrlData }) =>
    ({
      ...state,
      controlData: Object.assign({}, state.controlData, ctrlData)
    })),
  on(fromAutoActions.ClearSelectedDriver, (state, { ctrlData }) =>
    ({
      ...state,
      controlData: Object.assign({}, state.controlData, ctrlData)
    })),
  /** INITIATE PNI DRIVER DATA  */
  on(fromAutoActions.InitiatePNIDriver, (state, { reqPayload }) =>
    (
      {
        ...state, data: {
          ...state.data, drivers: [reqPayload, ...state.data.drivers.filter(element =>
            element.pni !== reqPayload.pni)]
        }, error: null
      })),
  /** INITIATE PNI DRIVER SPOUSE DATA  */
  on(fromAutoActions.InitiateDriverSpouse, (state, { reqPayload }) =>
    (
      {
        ...state,
        data: {
          ...state.data, drivers:
            [...state.data.drivers, reqPayload]
        }, error: null
      })),
  /** ADD ADITIONAL DRIVER DETAILS  */
  on(fromAutoActions.AddAdditionalDriverSuccess, (state, { resData, serviceCtrlData }) =>
    ({
      ...state, data: {
        ...state.data, drivers:
          [...state.data.drivers,
            resData]
      }, serviceControlData: { ...state.serviceControlData, ...serviceCtrlData }, error: null
    })),
  /** SAVE PNI DRIVER DETAILS  */
  on(fromAutoActions.SavePNIDriverSuccess, (state, { reqPayload, serviceCtrlData }) =>
    (
      {
        ...state, data: {
          ...state.data,
          driverReferenceNumber: reqPayload.driver.reference,
          // currentInsuredAnswer: reqPayload.currentInsuredAnswer,
          drivers: [...state.data.drivers.filter(item => item.reference !== reqPayload.driver.reference), reqPayload.driver]
        }, controlData: { ...state.controlData, currentInsuredAnswer: reqPayload.currentInsuredAnswer },
        serviceControlData: { ...state.serviceControlData, ...serviceCtrlData }, error: null
      }

    )),
  /** SAVE PNI SPOUSE DRIVER DETAILS  */
  on(fromAutoActions.AddPNISpouseSuccess, (state, { reqPayload, serviceCtrlData }) =>
    (
      {
        ...state, data: {
          ...state.data,
          driverReferenceNumber: reqPayload.reference,
          drivers: [...state.data.drivers.filter(item => item.reference !== reqPayload.reference), reqPayload]
        }, serviceControlData: { ...state.serviceControlData, ...serviceCtrlData }, error: null
      }
    )),
  /** REMOVE ADDITIONAL DRIVER SPOUSE  */
  on(fromAutoActions.RemoveMarriedAdditionalDriverSuccess, (state, { resData, serviceCtrlData }) =>
    ({
      ...state, data: {
        ...state.data, drivers:
          [
            // tslint:disable-next-line: no-shadowed-variable
            ...state.data.drivers.filter(element =>
              element.reference !== resData.reference)]
      }
      , serviceControlData: { ...state.serviceControlData, ...serviceCtrlData }, error: null
    })),
  /** ADD ADDITIONAL DRIVER FROM PREFILL  */
  on(fromAutoActions.AddAdditionalDriverFromPrefillSuccess, (state, { resData, serviceCtrlData }) =>
    ({
      ...state, data: {
        ...state.data, drivers: [...state.data.drivers, resData],
        prefillData: {
          ...state.data.prefillData, driver: state.data.prefillData.driver.filter(driverObject =>
            driverObject.reference !== resData.prefillRef)
        },
      }, serviceControlData: { ...state.serviceControlData, ...serviceCtrlData }, error: null
    })),
  /** ADD ADDITIONAL DRIVER SPOUSE FROM PREFILL  */
  on(fromAutoActions.AddDriverSpouseFromPrefillSuccess, (state, { reqPayload, serviceCtrlData }) =>
    ({
      ...state, data: {
        ...state.data,
        driverReferenceNumber: reqPayload.reference,
        drivers: [...state.data.drivers.filter(item => item.reference !== reqPayload.reference), reqPayload],
        prefillData: {
          ...state.data.prefillData, driver: state.data.prefillData.driver.filter(driverObject =>
            driverObject.reference !== reqPayload.prefillRef)
        },
      }, serviceControlData: { ...state.serviceControlData, ...serviceCtrlData }, error: null
    })),
  /** EDIT ADDITIONAL DRIVER DETAILS  */
  on(fromAutoActions.EditAdditionalDriverSuccess, (state, { reqPayload, serviceCtrlData }) =>
    (
      {
        ...state, data: {
          ...state.data,
          driverReferenceNumber: reqPayload.reference,
          drivers: [...state.data.drivers.filter(item => item.reference !== reqPayload.reference), reqPayload]
        }, serviceControlData: { ...state.serviceControlData, ...serviceCtrlData }, error: null,
      }

    )),
  /** DELETE ADDITIONAL DRIVER DETAILS  */
  on(fromAutoActions.DeleteAdditionalDriverSuccess, (state, { reqPayload, serviceCtrlData }) =>
    (
      {
        ...state, data: {
          ...state.data,
          driverReferenceNumber: reqPayload.reference,
          drivers: [...state.data.drivers.filter(item => item.reference !== reqPayload.reference)]
        }, serviceControlData: { ...state.serviceControlData, ...serviceCtrlData }, error: null
      }
    )),
  /** EDIT DRIVER DETAILS  */
  on(fromAutoActions.EditDriverSuccess, (state, { resData, serviceCtrlData }) => {
    const editedDriver = state.data.drivers.find(element => element.reference === resData.driver.reference);
    const updatedDriver = JSON.parse(JSON.stringify(resData.driver));
    updatedDriver.rideShareDrv = editedDriver.rideShareDrv ? editedDriver.rideShareDrv : null;
    updatedDriver.mostUsedVehicleRef = editedDriver.mostUsedVehicleRef ? editedDriver.mostUsedVehicleRef : null;
    const currentInsured = editedDriver.pni === 'H' ? resData.currentInsuredAnswer : state.controlData.currentInsuredAnswer;
    return {
      ...state, data: {
        ...state.data,
        driverReferenceNumber: resData.driver.reference,
        // currentInsuredAnswer: resData.currentInsuredAnswer,
        drivers: [...state.data.drivers.filter(driver =>
        driver.reference !== resData.driver.reference), updatedDriver]
      }, controlData: { ...state.controlData, currentInsuredAnswer: currentInsured },
      serviceControlData: { ...state.serviceControlData, ...serviceCtrlData }, error: null
    };
  }),
  /** DELETE DRIVER SPOUSE DUMMY DETAILS  */
  on(fromAutoActions.RemoveDummySpouse, (state, { spouseRef }) =>
    (
      {
        ...state,
        data: {
          ...state.data, drivers:
            [...state.data.drivers.filter(drivr => drivr.reference !== spouseRef)]
        }, error: null
      })),
  /** UPDATING THE SERVICE FAIL */
  on(fromAutoActions.ServiceFail, (state, { respError }) => ({ ...state, error: respError })),
  /** UPDATING SAVE DISCOUNT SERVICE RESPONSE */
  on(fromAutoActions.SaveDiscountSuccess, (state, { respErr, respData, serviceCtrlData }) =>
    (
      {
        ...state, data: { ...state.data, discount: respData },
        controlData: {...state.controlData, flHouseAccount: respData.flHouseAccount, isHouseAccount: respData.isHouseAccount},
        serviceControlData: { ...state.serviceControlData, ...serviceCtrlData },
        error: respErr
      }
    )),

  /** UPDATING SAVE DISCOUNT SERVICE RESPONSE */
  on(fromAutoActions.SaveSignalDiscountSuccess, (state, { respData, ctrlData }) =>
    (
      {
        /** FIX ADDED FOR LOSING PREPOPULATED OPTIONS IN DISCOUNT PAGE WHEN SIGNAL IS SELECTED AS NO */
        ...state, data: { ...state.data, discount: {...state.data.discount, saveSignalDiscount: respData.saveSignalDiscount }},
        serviceControlData: { ...state.controlData, ...ctrlData }
      }
    )),
  /** UPDATING THE SERVICE CONTROL DATA AND ERROR WHEN SERVICE FAILS */
  on(fromAutoActions.ServiceFailWithCtrlData, (state, { respError, serviceCtrlData }) =>
    (
      { ...state, serviceControlData: { ...state.serviceControlData, ...serviceCtrlData }, error: respError })),

  /** DELETE DISCOUNTS DATA FROM QUOTE WHEN CUSTOMER CLICKS ON NEXT BUTTON IN DISCOUNT */
  on(fromAutoActions.ResetDiscountAndQuoteState, (state) => ({
    ...state, data: {
      ...state.data, discount: fromAuto.initialState.data.discount,
      quote: fromAuto.initialState.data.quote
    }, error: null
  })),
  /** updating default saveQuote response to store */
  on(fromAutoActions.SaveQuoteSuccess, (state, { respData, ctrlData }) => {
    const updatedQuoteData = respData.vehicle[0].persAutoCoverage[0].packageSelected;
    const updatedQuoteDataSave = UpdateSaveQuoteDataUsingResponse(state.data.quote.vehicle, updatedQuoteData);
    return {
      ...state, data: {
        ...state.data,
        quote: { ...state.data.quote, vehicle: [...updatedQuoteDataSave] }
      }, controlData: { ...state.controlData, ...ctrlData }
    };


  }),
  /** updating saveQuote service error to store */
  on(fromAutoActions.SaveQuoteFail, (state, { respErr, ctrlData }) =>
    ({ ...state, error: { ...state.error, respErr }, controlData: { ...state.controlData, ...ctrlData } })),
  /** updating knockout response to store */
  on(fromAutoActions.SaveKnockOutSuccess, (state, { respData, ctrlData }) =>
    ({
      ...state, data: {
        ...state.data,
        knockout: { ...state.data.knockout, ...respData }
      }, controlData: { ...state.controlData, ...ctrlData }
    })),
  /** updating knockout response to store */
  on(fromAutoActions.SaveKnockOutFail, (state, { respErr, ctrlData }) =>
    ({ ...state, error: { ...state.error, respErr }, controlData: { ...state.controlData, ...ctrlData } })),


    /** updating save Action NC Subscription  success to store */
    on(fromAutoActions.SaveActionWaitAndFireSuccess, (state, { serviceCtrlData }) =>
    ({
      ...state, data: {}, controlData: { ...state.controlData, ...serviceCtrlData }
    })),

   /** updating save Action NC Subscription  error to store */
  on(fromAutoActions.SaveActionWaitAndFireFail, (state, {respError }) =>
    ({ ...state, error: { ...state.error, respError }})),

  /** used to update the vin after discount saveVehicle List */
  on(fromAutoActions.SaveVehicleListSuccess, (state, { reqPayload, serviceCtrlData }) => {
    const updatedVehcleData = UpdateVehicleList(reqPayload, state.data.vehicles);
    return {
      ...state, data: {
        ...state.data, vehicles: updatedVehcleData,
        vinLookupVehicle: fromAuto.initialState.data.vinLookupVehicle,
      }, serviceControlData: { ...state.serviceControlData, ...serviceCtrlData },
    };
  }),

  /** updating Driver Vehicle Assignment request data and success to store */
  on(fromAutoActions.DriverVehicleAssignmentSuccess, (state, { reqPayload, ctrlData, respError }) => {
    const vehicleDataUpdate = UpdateDriverVehicleAssignment(state.data.vehicles, reqPayload.drivers);
    return ({
      ...state, data: { ...state.data, vehicles: vehicleDataUpdate },
      serviceControlData: { ...state.serviceControlData, ...ctrlData },
      error: respError
    });
  }),

  /** updating Driver Vehicle Assignment request data and success to store */
  on(fromAutoActions.VehicleDriverAssignmentSuccess, (state, { reqPayload, ctrlData }) => {
    const vehicleDriverDataUpdate = UpdateVehicleDriverAssignment(state.data, reqPayload);
    return ({
      ...state, data: { ...state.data, vehicles: vehicleDriverDataUpdate.vehicleArray, drivers: vehicleDriverDataUpdate.drvArray },
      serviceControlData: { ...state.serviceControlData, ...ctrlData }
    });
  }),
  // Update when Policy start date changes affecting the DrivingExp
  on(fromAutoActions.UpdateDriverListSuccess, (state, { reqPayload }) =>
  ({
      ...state, data: { ...state.data, drivers: reqPayload}
    })),

  /** updating rideshare request data and success to store */
  on(fromAutoActions.AddRideShareSuccess, (state, { reqPayload, ctrlData }) => {
    const driverDataUpdate = UpdateDriverRideShare(state.data.drivers, reqPayload.drivers);
    const vehicleDataUpdate = UpdateVehicleRideShare(state.data.vehicles, reqPayload.vehicles);
    return ({
      ...state, data: { ...state.data, drivers: [...driverDataUpdate], vehicles: [...vehicleDataUpdate] },
      serviceControlData: { ...state.serviceControlData, ...ctrlData }
    });
  }),
  on(fromAutoActions.ResetVinResponse, (state) =>
    ({
      ...state, data: {
        ...state.data, vinLookupVehicle: fromAuto.initialState.data.vinLookupVehicle,
      },
    })),
  /** Used to update error object in Auto Store */
  on(fromAutoActions.UpdateAutoError, (state, { respError }) => ({
    ...state,
    error: respError === null ? null : { ...state.error, ...respError }
  })),
   /** updating custom rate response to store */
  on(fromAutoActions.CustomRateSuccess, (state, { respData, ctrlData, serviceCtrlData, respError }) => {
    const updatedQuoteData = UpdateQuoteDataUsingResponse(state.data.quote, respData);
    return {
      ...state, data: {
        ...state.data,
        quote: { ...updatedQuoteData }
      }, controlData: { ...state.controlData, ...ctrlData },
      serviceControlData: { ...state.serviceControlData, ...serviceCtrlData },
      error: respError
    };
  }),
  /** updating renters rate response to store */
  on(fromAutoActions.RentersRateSuccess, (state, { respData, error, serviceCtrlData }) =>
  (
    {
      ...state, data: { ...state.data, rentersRate: respData },
      error: { ...state.error, ...error},
      serviceControlData: { ...state.serviceControlData, ...serviceCtrlData }
    }
  )),
  on(fromAutoActions.UpdateAutoStoreDirectly, (state,  { reqPayload }) =>
  ({
    ...state, ...reqPayload,
  })),
  /** ADD EXCLUDED DRIVER FROM PREFILL  */
  on(fromAutoActions.SaveExcludedDriverSuccess, (state, { serviceCtrlData }) =>
    ({ ...state, data: { ...state.data }, serviceControlData: { ...state.serviceControlData, ...serviceCtrlData }, error: '' })),
  /** updating renters rate information */
  on(fromAutoActions.UpdateRentersRateData, (state, { reqPayload }) =>
  ({
    ...state, data:  { ...state.data, rentersRate: reqPayload },
    error: null
  })),
   /** updating error count in auto store */
   on(fromAutoActions.IncrementErrorCount, (state) =>
   ({
     ...state, controlData:  { ...state.controlData,
       errorCount: state.controlData.errorCount ? state.controlData.errorCount + 1 : 1 }
    })),
    /** updating distant student in auto store */
    on(fromAutoActions.updateDistantStudent, (state, { reqPayload}) => {
      const editedDriver: Driver = state.data.drivers.find(element => element.reference === reqPayload);
      const updateDriver: Driver = {
        ...editedDriver, distantStudent: ''
      };
      return {
        ...state, data: {
          ...state.data,
          drivers: [...state.data.drivers.filter(driver =>
            driver.reference !== reqPayload), updateDriver]
        },
      };
    }),

);

export function autoReducerFn(state: fromAuto.AutoState | undefined, action: Action) {
  return autoReducer(state, action);
}



/**
 * Updates quote data using response
 * @param quoteData quoteData from store
 * @param respData respData
 */
function UpdateQuoteDataUsingResponse(quoteData, respData) {
  const respDataCopy = JSON.parse(JSON.stringify(quoteData));
  respDataCopy.knockOutInd = respData.knockOutInd;
  respDataCopy.knockOutBeanList = respData.knockOutBeanList;
  /* fix for getting error from discount page when navigating back to discount from quote and clicks next button */
  if (!quoteData || Object.keys(quoteData).length === 0) {
    return quoteData;
  }
  /* the coverage objects are not there in respData when the knockOutInd = true */
  if (!respData.knockOutInd) {
    respDataCopy.discount = respData.discount;
    respDataCopy.coverageAdjustedIndicator = respData.coverageAdjustedIndicator;
    respDataCopy.umbrellaPolicyIndicator = respData.umbrellaPolicyIndicator;
    respDataCopy.infoMessages = respData.infoMessages;
    respDataCopy.liabilityCoverage = { ...quoteData.liabilityCoverage, ...respData.liabilityCoverage };
    respDataCopy.vehicle.forEach(vehElement => {
      const vehObjectFromQuote = respData.vehicle.find(vehicleObj => vehicleObj.reference === vehElement.reference);
      vehElement.persAutoCoverage = { ...vehElement.persAutoCoverage, ...vehObjectFromQuote.persAutoCoverage };
      vehElement.surchargeBI = vehObjectFromQuote.surchargeBI ;
      vehElement.surchargePD = vehObjectFromQuote.surchargePD ;
    });
    if (respData.policyFee) {
      respDataCopy.policyFee = respData.policyFee;
    }
  }
  return respDataCopy;
}
/**
 * Updates discount data using response
 * @param discountData discountData from store
 * @param respData respData
 */
function UpdateDiscountDataUsingResponse(discountData: Discount, respData: RateResponse): Discount {
  const respDataCopy = JSON.parse(JSON.stringify(discountData));
  if (respData.payPlanChanged) {
    respDataCopy.payPolicy = DataConstants.PAY_POLICY.payInfull;
  }
  return respDataCopy;
}

/**
 * Updates quote data using response
 * @param quoteData quoteData from store
 * @param respData respData
 */
function UpdateSaveQuoteDataUsingResponse(quoteData, respData) {
  const vehicleArray: any[] = [];
  const clone = JSON.parse(JSON.stringify(quoteData));
  clone.forEach(vehElement => {
    vehElement.persAutoCoverage.packageSelected = respData;
    vehicleArray.push(vehElement);
  });
  return vehicleArray;
}

/**
 * Updates vehicle list
 * @param vehcleList from reqest
 * @param quoteData quoteData from store
 */
function UpdateVehicleList(vehcleList, quoteData) {
  const vehicleArray = JSON.parse(JSON.stringify(quoteData));
  vehcleList.forEach(element => {
    const updateItem = vehicleArray.find(vehicleObj => vehicleObj.reference === element.reference);
    const index = vehicleArray.indexOf(updateItem);
    const updatedDriver = JSON.parse(JSON.stringify(element));
    updatedDriver.primaryDriver = updateItem.primaryDriver ? updateItem.primaryDriver : null;
    updatedDriver.rideShareVeh = updateItem.rideShareVeh ? updateItem.rideShareVeh : null;
    vehicleArray[index] = updatedDriver;
  });
  return vehicleArray;
}
/* updates driver ride share info each driver
*/
function UpdateDriverRideShare(driverData, rideShareData) {
  const driverArray: any[] = [];
  const clone = JSON.parse(JSON.stringify(driverData));
  clone.forEach(drivElement => {
    const rideObj = rideShareData.filter(element => element.reference === drivElement.reference)[0];
    drivElement[`rideShareDrv`] = rideObj ? rideObj.rideShareDrv : '';
    driverArray.push(drivElement);
  });
  return driverArray;
}

/**
 * updates vehicle ride share info each vehicle
 */
function UpdateVehicleRideShare(vehicleData, rideShareData) {

  const vehicleArray: any[] = [];
  const clone = JSON.parse(JSON.stringify(vehicleData));
  clone.forEach(vehElement => {
    const rideObj = rideShareData.filter(element => element.reference === vehElement.reference)[0];
    vehElement[`rideShareVeh`] = rideObj ? rideObj.rideShareVeh : '';
    vehicleArray.push(vehElement);
  });
  return vehicleArray;
}

/**
 * updates vehicle ride share info each vehicle
 */
function UpdateDriverVehicleAssignment(vehicleData, drivers) {

  const vehicleArray = JSON.parse(JSON.stringify(vehicleData));
  vehicleArray.forEach(vehicle => {
    if (vehicle.primaryDriver) {
      vehicle.primaryDriver = null;
    }
  });

  drivers.forEach(driverElement => {
    let primaryVehicle = [];
    if (driverElement.primaryVehicle.toString().includes('|')) {
      primaryVehicle = driverElement.primaryVehicle.split('|');
    } else {
      primaryVehicle.push(driverElement.primaryVehicle);
    }

    primaryVehicle.forEach(veh => {
      const vehObj = vehicleArray.find(vehElement => parseInt(vehElement.reference, 10) === parseInt(veh, 10));
      if (vehObj) {
        if (vehObj[`primaryDriver`] && !vehObj[`primaryDriver`].includes(driverElement.reference)) {
          vehObj[`primaryDriver`] = vehObj[`primaryDriver`] + '|' + driverElement.reference.toString();
        } else {
          vehObj[`primaryDriver`] = driverElement.reference.toString();
        }

      }

    });
  });
  return vehicleArray;
}

function UpdateVehicleDriverAssignment(autoData, reqPayload) {

  const vehicleArray = JSON.parse(JSON.stringify(autoData.vehicles));
  const drvArray = JSON.parse(JSON.stringify(autoData.drivers));
  vehicleArray.forEach(veh => {
    if (veh.primaryDriver) {
      veh.primaryDriver = null;
    }
  });
  drvArray.forEach(driver => {
    if (driver.mostUsedVehicleRef) {
      driver.mostUsedVehicleRef = null;
    }
  });
  const drivers = reqPayload.drivers;
  const vehicles = reqPayload.vehicles;
  drivers.forEach(driverElement => {
    const drvObj = drvArray.find(dvElement => driverElement.reference === dvElement.reference);
    drvObj.mostUsedVehicleRef = driverElement.mostUsedVehicleRef;
  });
  vehicles.forEach(vehicleElement => {
    const vehObj = vehicleArray.find(vhElement => vehicleElement.reference === parseInt(vhElement.reference, 10));
    vehObj.primaryDriver = vehicleElement.primaryDriver;
  });
  return {drvArray, vehicleArray};
}


