/*
 * @(#) shared.service.constants.js  Jul 19, 2016
 *
 * Copyright 2016 Farmers FFQ
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of
 * Farmers Insurance Group. ("Confidential Information").  You shall not
 * disclose such Confidential Information and shall use it only in
 * accordance with the terms of the license agreement you entered into
 * with Farmers Insurance Group.
 */

import { environment } from '@environments/environment';
import { LandingPagePostRequest } from '@ffq-app-landing/model/landing.model';

export class ServiceConstants {
    /**
     * Gets base url
     */
    public static get BASE_URL(): string { return environment.endPoint; }
    /**
     * Gets base url
     */
    public static get BASE_URL_MOCK(): string { return environment.jsonServerMockUrl; }
    /**
     * Gets legacy url
     */
    public static get LEGACY_URL(): string { return environment.legacy_endPoint; }

    public static get HQM_URL(): string { return environment.hqm_end_point; }

    public static get ONE_UI_URL(): string { return environment.one_ui_url; }

    public static get FGIA_URL(): string { return environment.fgiaUrl; }

    public static get IS_MIGRATED_LANDING_PAGE_ENABLED(): string { return environment.isMigratedLandingPageEnabled; }
    public static get GET_REQ_BODY_PARAMS(): LandingPagePostRequest { return window[`ffqRequestBodyParams`]; }
    /**
     * Gets Environment Variable for Policy Effective Date - PC states
     */
    public static get POLICY_STARTDATE_PC(): string { return environment.policy_startdate_pc; }
    /**
     * Gets Environment Variable for Policy Effective Date - PLA states
     */
    public static get POLICY_STARTDATE_PLA(): string { return environment.policy_startdate_pla; }
    /**
     * Gets popup time
     */
    public static get POPUP_TIME(): string { return environment.chatbot_popupTime; }
    /**
     * Gets chatbot url
     */
    public static get CHATBOT_URL(): string { return environment.chatbot_url; }
    /**
     * Gets open live chat url
     */
    public static get OPEN_LIVECHAT(): string { return 'livechat/chat'; }
    /**
     * Gets whether within business hour
     */
    public static get IS_WITHIN_BUSINESS_HOUR(): string { return 'livechat/checkWithinBusinessHr'; }
    /**
     * Gets the chat status
     */
    public static get GET_CHAT_STATUS(): string { return 'livechat/checkChatStatusActive'; }
    /**
     * Gets the chat status
     */
    public static get CHECK_LIVECHAT_ELIGIBLE(): string { return 'livechat/checkLiveChatEligible'; }
    /**
     * Gets legacy home url
     */
    public static get LEGACY_HOME_URL(): string { return environment.legacy_homeEndPoint; }
    /**
     * Gets whether is production
     */
    public static get IS_PRODUCTION(): string { return environment.isProduction; }

    /**
     * CCPA URL
     */
    public static get CCPA_URL(): string { return environment.CCPA_URL; }

    /**
     * existCustLogin_url
     */
    public static get existCustLogin_url(): string { return environment.existCustLogin_url; }

    /**
     * Login/Register URL
     */
    public static get LOGIN_REGISTER_URL(): string { return environment.loginRegister_url; }

    /**
     * existCustRegister_url
     */
    public static get existCustRegister_url(): string { return environment.existCustRegister_url; }
    /**
     * AutoComplete Key
     */
    public static get AUTO_COMPLETE_KEY(): string { return environment.autoCompleteKey; }

    /**
     * Boolean to check credential
     */
    public static get IS_ACCESS_CONTROL_ENABLED(): boolean { return environment.isAccessControlEnabled === 'Y'; }

    /**
     * Gets popup time
     */
    public static get IS_MOCKPAGE_VIEW_REQUIRED(): boolean { return environment.mockPageView === 'Y'; }
    /**
     * FARMERS_DIRECT_URL
     */
    public static get FARMERS_DIRECT_URL(): string { return environment.farmersDirect_url; }
    /**
     * FARMERS_HAWAII_URL
     */
     public static get FARMERS_HAWAII_URL(): string { return environment.farmersHawaii_url; }
    /**
     * FIND_AN_AGENT_URL
     */
    public static get FIND_AN_AGENT(): string { return environment.findAnAgent_url; }
    /**
     * List of test emails from Heroku
     */
    public static get TEST_EMAIL_LIST(): string { return environment.test_email_list; }
    /**
     * List of test last names from Heroku
     */
    public static get TEST_LASTNAME_LIST(): string { return environment.test_lastName_list; }
    /**
     * Gets chat bot
     */
    public static get CHAT_BOT(): string { return 'common/chatbot'; }
    /**
     * Gets start quote
     */
    public static get START_QUOTE(): string { return 'common/startQuote'; }
    /**
     * MDM Retrive
     */
    public static get MDM_RETRIVE(): string { return 'common/mdmRetrieve'; }
    /**
     * Gets source phone number
     */
    public static get SOURCE_PHONE_NUMBER(): string { return 'common/AEMPhoneNumber'; }
    /**
     * Gets change zip
     */
    public static get CHANGE_ZIP(): string { return 'common/validateZip'; }
    /**
     * Gets cross sell encrypted data
     */
    public static get GET_ENCRYPTED_CROSS_SELL_DATA(): string { return 'common/getEncryptedCrosssellData'; }
    /**
     * Gets validate email
     */
    public static get VALIDATE_EMAIL(): string { return 'common/validateEmail'; }
    /**
     * Gets your info submit
     */
    public static get YOUR_INFO_SUBMIT(): string { return 'yourInfo/submitYourInfo'; }
    /**
     * Gets cross sell
     */
    public static get CROSS_SELL(): string { return 'common/startQuoteWithQuoteNumber'; }
    /**
     * Gets save on edit
     */
    public static get SAVE_ON_EDIT(): string { return 'common/saveOnEdit'; }
    /**
     * Gets get project codes
     */
    public static get GET_PROJECT_CODES(): string { return 'common/projectCodeStatus'; }
    /**
     * Gets fetch town list
     */
    public static get FETCH_TOWN_LIST(): string { return '/yourInfo/fetchTownList'; }
    /**
     * Gets lading retrieve quote
     */
    public static get LADING_RETRIEVE_QUOTE(): string { return 'common/retrieveQuote'; }
    /**
     * Gets save quote
     */
    public static get SAVE_QUOTE(): string { return 'rwd/rest/services/rate/saveQuote'; }
    /**
     * Gets send knockout details
     */
    public static get SEND_KNOCKOUT_DETAILS(): string { return 'common/saveKnockOut'; }
    /**
     * Gets legacy url common
     */
    public static get LEGACY_URL_COMMON(): string { return 'ffq/directlandingpage.jsf'; }
    /**
     * Gets auto url
     */
    public static get AUTO_URL(): string { return '/fastquote'; }
    /**
     * Gets session keep alive
     */
    public static get SESSION_KEEP_ALIVE(): string { return 'common/sessionKeepAlive'; }
    /**
     * Gets your info assign agent
     */
    public static get YOUR_INFO_ASSIGN_AGENT(): string { return 'yourInfo/assignAgent'; }
    /**
     * Gets vehicle dropdown
     */
    public static get VEHICLE_DROPDOWN(): string { return 'common/fetchDropDownList'; }
    /**
     * Gets incident category dropdown
     */
    public static get INCIDENT_CATEGORY_DROPDOWN(): string { return 'common/fetchIncidentCategory'; }
    /**
     * Gets fetchspl dropdown vehicles
     */
    public static get FETCHSPL_DROPDOWN_VEHICLES(): string { return 'vehicle/retrieveSpl'; }
    /**
     * Gets vin lookup
     */
    public static get VIN_LOOKUP(): string { return 'vehicle/doVinLookUp'; }
    /**
     * Gets vehicle save
     */
    public static get VEHICLE_SAVE(): string { return 'vehicle/saveVehicle'; }
    /**
     * Gets vehicle list save
     */
    public static get DISCOUNT_SAVE_VEHICLES(): string { return 'vehicle/saveVehicleList'; }
    /**
     * Gets adpf prefill
     */
    public static get ADPF_PREFILL(): string { return 'yourInfo/getPrefillData'; }

    /**
     * Gets fetch bundle question
     */
    public static get FETCH_BUNDLE_QUESTION(): string { return 'common/fetchBundleQuestion'; }

    /**
     * Gets save pni
     */
    public static get SAVE_PNI(): string { return 'driver/savePNIDriver'; }
    /**
     * Gets driver save
     */
    public static get DRIVER_SAVE(): string { return 'driver/saveDriver'; }
    /**
     * Gets Driver - BW agent assignment
     */
    public static get BW_AGENT_ASSIGNMENT(): string { return 'common/bwAgentAssignment'; }

    /**
     * Gets save discount
     */
    public static get SAVE_DISCOUNT(): string { return'discount/saveDiscounts'; }

    /**
     * Gets save discount
     */
    public static get SAVE_SIGNAL_DISCOUNT(): string { return'discount/saveSignalDiscounts'; }

    /**
     * Gets perform rate
     */
    public static get PERFORM_RATE(): string { return'rate/performRate'; }

    /**
     * Gets perform rate for HVQ
     */
    public static get PERFORM_MONO_RATE(): string { return'rate/performMonoRate'; }

    /**
     * Gets perform rate for HVQ
     */
     public static get RATE_DEFAULT(): string { return'default'; }

    /**
     * Gets perform rate for HVQ
     */
    public static get FETCH_COVERAGE_DROPDOWN(): string {return 'rate/fetchCoverageDropdowns'; }

    /**
     * Gets business dropdown
     */
    public static get BUSINESS_INFO_DROPDOWN(): string { return'businessInfo/fetchBusinessDropDown'; }
    /**
     * Gets your info submit
     */
    public static get BUSINESS_YOUR_INFO_SUBMIT(): string { return 'businessInfo/submitBusinessInfo'; }

    /**
     * Gets driver Vehicle Assignment
     */
    public static get DRIVER_VEHICLE_ASSIGNMENT(): string { return 'common/driverVehicleAssignment'; }
    /* Gets ride share
     */
    public static get RIDE_SHARE(): string { return '/common/rideShareAssignment'; }

    /** Gets custom quote */
    public static get CUSTOM_QUOTE(): string { return 'rate/customRate'; }

    /** Gets renters rate quote */
    public static get RENTERS_RATE_QUOTE(): string { return 'rate/rentersRate'; }

    /** Gets static content */
    public static get STATIC_CONTENT(): string { return 'common/AEMStaticContent'; }

    /** Gets print quote */
    public static get DISPATCH_PRINT(): string { return 'common/printQuoteVisited'; }
    /**
     * Gets lading retrieve PI Rate Summary
     */
    public static get RETRIEVE_SUMMARY_QUOTE(): string { return 'common/retrieveSummaryQuote'; }
    /**
     * Gets Farmers.com url
     */
    public static get FARMERS_URL(): string { return 'https://www.farmers.com/'; }

    /**
     * Gets employer dropdown
     */
    public static get EMPLOYER_DROPDOWN(): string { return 'common/getEmployerDropdown'; }

    public static get FWS_CALL_ONLY_PAGE(): string { return environment.fwsCallOnlyPage; }

    public static get FARMERS_CHOICE_PAGE(): string { return environment.farmersChoiceUrl; }
    /**
     * Gets Save Excluded Driver
     */
    public static get SAVE_EXCLUDED_DRIVER(): string { return 'driver/saveExcludedADPFDriver'; }
    /**
     * Gets Connected Car API URL
     */
    public static get CONNECTED_CAR_API_URL(): string { return 'driver/connectedCar'; }

    /**
     * Gets license validation
     */
    public static get VALIDATE_LICENSE(): string { return 'driver/isInvalidLicense'; }

    /**
     * Gets tnedicca service url
     */
    public static get VEHICLE_SAVE_ACTIONS_URL(): string { return 'vehicle/saveActions'; }

    /**
     * Gets saveActions service url
     */
    public static get RATE_SAVE_ACTIONS_URL(): string { return 'rate/saveActions'; }

    /**
     * Gets Credit Report Service URL
     */
      public static get CREDIT_REPORT_URL(): string { return 'common/creditReport'; }
     /**
      * Gets Schedule Agent Email URL
      */
     public static get SCHEDULE_AGENT_EMAIL(): string { return 'common/scheduleAgentEmail'; }

}

/**
 * loader is not required for the services given below
 */
export const NoLoaderServices: string[] = ['vehicle/retrieveSpl', 'livechat/checkWithinBusinessHr', 'livechat/checkChatStatusActive',
'livechat/checkLiveChatEligible', 'rate/rentersRate', 'livechat/chat',
'common/AEMPhoneNumber', 'common/getEmployerDropdown'];

/**
 * Services which are intercept by the auto interceptor
 */
export const ServicesForAutoInterceptor: string[] = ['vehicle/saveVehicle', 'driver/saveDriver', 'driver/savePNIDriver'];

export const ServicesForAutoInterceptorVD: string[] = ['vehicle/saveVehicle', 'driver/saveDriver'];

export const AutoRentersLoaderServices: string[] = [ ServiceConstants.FETCH_COVERAGE_DROPDOWN, ServiceConstants.PERFORM_RATE,
     ServiceConstants.CUSTOM_QUOTE];

export const ServicesForParnterRequestHeaderAddition: string[] = [ServiceConstants.RETRIEVE_SUMMARY_QUOTE, ServiceConstants.START_QUOTE];
