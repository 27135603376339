export enum NavigationStepsAutoEnum {
    YourInfo = 1,
    Vehciles = 2,
    Drivers = 3,
    Discouts = 4,
    Quote = 5,
    AdditionalInfo = 6
}

export enum NavigationStepsHomeEnum {
    YourInfo = 1,
    Confirmation = 2,
}


export enum NavigationStepsBusinessEnum {
    BusinessInfo = 1,
    YourInfo = 2,
    Confirmation = 3,
}
