<div class="app-print"> 
  <div ngClass="{{agentAssigned? '': ' no-agent-assigned '}}" id="print-content">
      <div class="top-header justify-content-between print-row">

          <div *ngIf="isBwQuote" class="logo">
              <img   class="bw-img-logo" [src]="imageSrc" alt="BW Insurance Logo" >
          </div>

          <div  *ngIf="!isBwQuote"  class="logo" >
               <img class="mw-100" src="assets/images/header-m1.png" alt="Farmers Insurance Logo" >
          
          </div>

          <div >
          </div>
          <div>
            <button role="button" (click)="callPrint()" aria-label="Print. Opens modal dialog box" 
            mat-raised-button class="print-button print-btn-hide button-secondary button-width d-flex justify-content-center"
              type="button"   mat-button>
              <div class="print-icon-with-text"></div>
          
          </button>
          </div>
      </div>
      <div class="quote-blue-line"></div>
      <div id="header-block" class="print-row">


        <div  *ngIf="!isBwQuote" id="thank-you-block" class="">
          <p  id="thank-you-text">Thank you {{quoteData.firstName}} for choosing Farmers. Below is a summary of your auto insurance quote. 
            Please refer to your <span class="bold-weight">quote #{{quoteNum}}</span> to purchase your policy online or with one of our {{stateRuleText1}}</p>
        </div>



        <div *ngIf="isBwQuote" id="thank-you-block" class="">
          <div  id="thank-you-text">Thank you {{quoteData.firstName}} for choosing Bristol West. Below is a summary of your auto insurance quote. 
            Please refer to your <span class="bold-weight">quote #{{quoteNum}}</span> to purchase your policy online or with one of our {{stateRuleText1}}</div>
        </div>

        

        <div id="agent-details-block" class="print-agent-info " [ngClass]="agentAssigned ? ' agent-assigned-block ': ' '">
          <app-print-quote-agent-info [printData]="quoteData"></app-print-quote-agent-info>
        </div>
      </div>
     
 
      <hr class="print-quote-line" aria-hidden="true">
      <app-print-quote-details [printData]="quoteData"></app-print-quote-details> 
    
      <div *ngIf="isPolicyPerksEligible" class="print-row">
        <app-policy-perks [isPrint] = "true"></app-policy-perks>
      </div>
      <!--Disclaimer-->
      <div class="print-row">
        <app-quote-disclaimer [landingStateCode]="landingStateCode" [isRentersImpl]="isRentersImpl" [isPolicyPerkEligible]="isPolicyPerksEligible" [payPolicy]= "payPolicy" [nonGoodDriverImpl]="nonGoodDriverImpl"></app-quote-disclaimer>
      </div>

    </div>      
  </div>
  