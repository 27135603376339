import { Component, Input, OnInit } from '@angular/core';

/* bootstrap */
// import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-ffq-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  @Input() title = `Information`;

  constructor(
    //  public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
  }

}
