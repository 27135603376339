import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { KnockOutComponent } from './components/knock-out/knock-out.component';
import { SignalBannerComponent } from './components/signal-banner/signal-banner.component';
import { SignalDiscountBannerComponent } from './components/signal-discount-banner/signal-discount-banner.component';
import { DiscountJourneyComponent } from './components/discount-journey/discount-journey.component';


const routes: Routes = [
  {
    path: '',
    component: KnockOutComponent
  },
  {
    path: '',
    component: SignalBannerComponent
  },
  {
    path: '',
    component: SignalDiscountBannerComponent
  },
  {
    path: '',
    component: DiscountJourneyComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AutoSharedRoutingModule { }
