<div class="disclaimer_txt">
    <ng-container>
        <div *ngIf="!isPayInFull">
          <p id="service-fee-disclaimer1" tabindex="0" *ngIf="isQuoteDisclaimerStatusForEE; else notEE"><span aria-hidden="true">*</span>{{disclaimerCommon}}</p>
          <ng-template #notEE>
            <p id="service-fee-disclaimer1" tabindex="0"><span aria-hidden="true">* </span>{{disclaimerCommon}}</p>
          </ng-template>
        </div>
        <div *ngIf="isFullPackageFullPremiumImpl">
          <p id="service-fee-disclaimer1" tabindex="0"><span aria-hidden="true">* </span>{{disclaimerFPCommon}}</p>
        </div>
        <p *ngIf="landingStateCode=='CA'">{{disclaimerCA}}</p>
        <p *ngIf="nonGoodDriverImpl">{{goodDriverDisclaimerCA}}</p>
        <p *ngIf="isRentersImpl" id="service-fee-disclaimer2" tabindex="0"><span
                aria-hidden="true">** </span>{{disclaimerRenters}}</p>
        <p *ngIf="!isBWQuote" id="FFQAuto_Quote_legalDisclosure3 ">{{disclaimerLegal}}</p>
        <p *ngIf="isBWQuote">{{disclaimerLegalBW}}</p>
    </ng-container>
    <ng-container *ngIf="isSignalBannerDiscountImpl && !isBWQuote && !disableSignalBanner">
        <div [innerHTML]="disclaimerSignal"></div>
    </ng-container>
    <ng-container *ngIf="isPolicyPerkEligible">
        <!--US301883: adding policy perks disclaimer from aem-->
        <p class="policy-perk-disclaimer" tabindex="0" [innerHTML]="disclaimerPolicyPerks"></p>
        <p class="policy-perk-disclaimer" id="policy-perk-disclaimer1" tabindex="0" [innerHTML]="disclaimer1"></p>
        <p class="policy-perk-disclaimer" id="policy-perk-disclaimer2" tabindex="0" [innerHTML]="disclaimer2"></p>          
    </ng-container>       
</div>
