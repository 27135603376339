import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

/* rx.js */
import { Observable, of, Subscription } from 'rxjs';
import { map, catchError, retry } from 'rxjs/operators';
import { take } from 'rxjs/internal/operators/take';

/* App */
import { ErrorService } from './error.service';

import { environment } from '@environments/environment';

import { AppStoreService } from '@ffq-app-store/app.store.service';
import { AutoStoreService } from '@ffq-app-auto/store/auto.store.service';
import { DatePipe } from '@angular/common';

import {
  NavigationData, RateQuoteRequest, SaveVehicleRequest, Vehicle, LastVisitedModule,
  SaveDriverObject, Driver, SavePNIAdditionalData, CommunicationData, AddressData,
  BwAssignAgent, SavedriverVehicleAssignment, ExcludedDriver, SaveExcludedDriversRequest,
  PrequalifiedDiscounts, Response, RateResponse, VehicleSaveActionsRequest, CreditReportRequest,
  ScheduleAgentAppointmentRequest, ConnectedCarRequest, SaveActionsRequest
} from '@ffq-app-shared/model/common.data.model';
import { ModuleProgressStatus } from '@ffq-app-shared/enums/flow.identifier.enum';
import { ServiceConstants, DataConstants } from '@ffq-app-shared/constants';
import { DateService } from '@ffq-lib/src/lib/core/services/date.service';
import { SaveQuoteSuccess } from '@ffq-app-auto/store/auto.actions';


@Injectable({
  providedIn: 'root'
})
export class DataService {

  /**
   * Creates an instance of data service.
   * @param http Http
   * @param errorService ErrorService
   * @param appStoreService AppStoreService
   * @param datePipe DataPipe
   * @param dateService DateService
   */
  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
    private appStoreService: AppStoreService,
    private datePipe: DatePipe,
    private dateService: DateService,
    private autoStoreService: AutoStoreService
  ) { }

  /**
   * Starts quote$
   * @param data Data
   * @returns quote$ Observable
   */
  startQuote$(data: any): Observable<any> {
    return this.http.post(environment.endPoint + ServiceConstants.START_QUOTE, data).pipe(
      map((resp: any) => resp),
      retry(2),
      catchError(error => this.errorService.handleError(error))
    );
  }

  /**
   * MDM Retrieve$
   * @param data Data
   * @returns quote$ Observable
   */
  mdmRetrieve$(data: any): Observable<any> {
    return this.http.post(environment.endPoint + ServiceConstants.MDM_RETRIVE, data).pipe(
      map((resp: any) => resp),
      retry(2),
      catchError(error => this.errorService.handleError(error))
    );
  }


  getSourcePhoneNumber$(data: any): Observable<any> {
    // tslint:disable-next-line: max-line-length
    return this.http.get(ServiceConstants.BASE_URL + ServiceConstants.SOURCE_PHONE_NUMBER + `?lob=${data.lob}`).pipe(
      map((resp: any) => resp),
      retry(2),
      catchError(error => this.errorService.handleError(error))
    );
  }
  /**
   * Starts quote with quote number$
   * @param data data
   * @returns quote with quote number$  Observable
   */
  startQuoteWithQuoteNumber$(data: any): Observable<any> {
    return this.http.post(ServiceConstants.BASE_URL + ServiceConstants.CROSS_SELL, data).pipe(
      map((resp: any) => resp),
      catchError(error => this.errorService.handleError(error))
    );
  }
  /*
    validateZip$(data: any): Observable<any> {
      return this.http.post(ServiceConstants.BASE_URL + ServiceConstants.CHANGE_ZIP, data).pipe(
        map((resp: any) => resp),
        catchError(error => this.errorService.handleError(error))
      );
    }
    */

  /**
   * Validates zip code$
   * @param zipcode ZipCode
   * @returns zip code$ Observable
   */
  validateZipCode$(zipcode: any): Observable<any> {
    return this.http.post(environment.endPoint + ServiceConstants.CHANGE_ZIP, this.createValidateZipPayload(zipcode)).pipe(
      map((resp: any) => resp),
      catchError(error => this.errorService.handleError(error))
    );
  }


  // Service call to get the project status codes
  /**
   * Gets project status codes$
   * @param date Date
   * @returns project status codes$ Observable
   */
  getProjectStatusCodes$(date: any): Observable<any> {
    const apiUrl = environment.endPoint + ServiceConstants.GET_PROJECT_CODES;
    let appData = null;
    const appDataSubscription: Subscription = this.appStoreService.appData$.subscribe(data => appData = data);
    appDataSubscription.unsubscribe();
    /**
     * StartQuote service call is success and Project status Service call is getting called
     */
    const projectStatusRequest: { [key: string]: string } = {
      lob: appData.quoteType,
      landingZipCode: appData.landingZipCode,
      quoteNumber: appData.quoteNumber,
      effectiveDate: date
    };
    if (appData.flexEnabledFromurl === 'Y') {
      projectStatusRequest.flexEnabledFromurl = appData.flexEnabledFromurl;
    }
    return this.http.post(apiUrl, projectStatusRequest).pipe(
      map((resp: any) => resp),
      retry(2),
      catchError(error => this.errorService.handleError(error)));
  }

  getStaticContent$(): Observable<any> {

    let appData = null;
    this.appStoreService.appData$.subscribe(result => appData = result).unsubscribe();
    /** F42587: Setting lob as auto as we need to call this service incase of awsknockout for all lobs */
    this.appStoreService.getAppControlData$.subscribe(ctrlData => {
      if (ctrlData?.isStartQuoteErrorAWSKnockout) {
        appData = Object.assign({}, appData, { lob: 'auto' });
      }
    }).unsubscribe();
    this.appStoreService.appError$.subscribe((errorObj: any) => {
      if (errorObj && Object.keys(errorObj).length !== 0) {
        if (errorObj?.errorInfo?.errorCode === 'EG033') {
          appData = Object.assign({}, appData, { lob: 'auto', landingStateCode: errorObj?.errorInfo?.state });
        }
      }
    }).unsubscribe();

    const apiUrl = environment.endPoint + ServiceConstants.STATIC_CONTENT +
      `?lob=${appData.lob}&state=${appData.landingStateCode}&quoteNumber=${appData?.quoteNumber}`;
    return this.http.get(apiUrl).pipe(
      map((resp: any) => resp),
      retry(2),
      catchError(error => this.errorService.handleError(error))
    );
  }

  /**
   * Sessions keep alive$
   * @param data Data
   * @returns keep alive$  Observable
   */
  sessionKeepAlive$(data: any): Observable<any> {
    return this.http.post(ServiceConstants.BASE_URL + ServiceConstants.SESSION_KEEP_ALIVE, data).pipe(
      map((resp: any) => resp),
      catchError(error => this.errorService.handleError(error))
    );
  }

  /**
   * Saves quote$
   * @param data Data
   * @returns quote$ Observable
   */
  saveQuote$(packageSelected: any, mode: any): Observable<any> {
    return this.http.post(ServiceConstants.BASE_URL + ServiceConstants.SAVE_QUOTE,
      this.getSaveQuoteServiceRequestObject(packageSelected, mode)).pipe(
        map((resp: any) => resp),
        catchError(error => this.errorService.handleError(error))
      );
  }

  /**
   * Saves on edit$
   * @param data Data
   * @returns on edit$ Observable
   */
  saveOnEdit$(data: any): Observable<any> {
    return this.http.post(ServiceConstants.BASE_URL + ServiceConstants.SAVE_ON_EDIT, data).pipe(
      map((resp: any) => resp),
      catchError(error => this.errorService.handleError(error))
    );
  }

  /**
   * Retrieves quote$
   * @param data Data
   * @returns quote$ Observable
   */
  retrieveQuote$(data: any): Observable<any> {
    return this.http.post(ServiceConstants.BASE_URL + ServiceConstants.LADING_RETRIEVE_QUOTE, data).pipe(
      map((resp: any) => resp),
      catchError(error => this.errorService.handleError(error))
    );
  }

  /**
   * Validates email$
   * @param data Data
   * @returns email$ Observable
   */
  validateEmail$(data: any): Observable<any> {
    return this.http.get(ServiceConstants.BASE_URL + ServiceConstants.VALIDATE_EMAIL + `?address=${data.address}`).pipe(
      map((resp: any) => resp),
      catchError(error => this.errorService.handleError(error))
    );
  }

  /**
   * Gets prerefill data$
   * @param data Data
   * @returns prerefill data$ Observable
   */
  getPrerefillData$(data: any): Observable<any> {
    return this.http.post(ServiceConstants.BASE_URL + ServiceConstants.ADPF_PREFILL, data).pipe(
      map((resp: any) => resp),
      catchError(error => this.errorService.handleError(error))
    );
  }


  /**
   * Submits your info$
   * @param data data
   * @returns your info$
   */
  submitYourInfo$(data: any): Observable<any> {
    return this.http.post(environment.endPoint + ServiceConstants.YOUR_INFO_SUBMIT, data).pipe(
      map((resp: any) => {
        // clearing the start quote param to make sure start quote is called again in the same session
        sessionStorage.setItem('startQuoteParams', '');
        return resp;
      }),
      retry(2),
      catchError(error => this.errorService.handleError(error))
    );
  }

  /**
   * Assigns agent$
   * @param data data
   * @returns agent$
   */
  assignAgent$(data: any): Observable<any> {
    return this.http.post(environment.endPoint + ServiceConstants.YOUR_INFO_ASSIGN_AGENT, data).pipe(
      map((resp: any) => resp),
      catchError(error => this.errorService.handleError(error))
    );
  }
  /**
   * Params data service
   * @param zipCode zipcode for validation
   * @returns requestPaylord for the validateZip service
   */
  private createValidateZipPayload(zipCode) {
    let appData = null;
    const appDataSubscription: Subscription = this.appStoreService.appData$.subscribe(data => appData = data);
    appDataSubscription.unsubscribe();
    const changeZipReqPayLoad = {
      quoteNumber: appData.quoteNumber,
      quoteWindowId: appData.quoteWindowId,
      quoteType: appData.quoteType,
      landingZipCode: zipCode,
      isFWSZipEligble: appData.isFWSZipEligble
    };
    return changeZipReqPayLoad;
  }
  /**
   * Params data service
   * @param requestdata  for save rate
   * @returns requestPaylord for the saveRate service
   */
  private getSaveQuoteServiceRequestObject(pckgSelctd, mode) {
    let appData = null;
    const appDataSubscription: Subscription = this.appStoreService.appData$.subscribe(data => appData = data);
    appDataSubscription.unsubscribe();
    let appCtrlData = null;
    this.appStoreService.getAppControlData$.subscribe(ctrlData => appCtrlData = ctrlData).unsubscribe();
    const saveQuoteRequestObj = {
      quoteNumber: appData.quoteNumber,
      quoteWindowId: appData.quoteWindowId,
      landingStateCode: appData.landingStateCode,
      effectiveDate: this.dateService.getCurrentDate(),
      packageSelected: pckgSelctd,
      lastVisitedPage: LastVisitedModule.Auto_Quote,
      pgLastVisitedPage: this.createPgLastVistedPageObject(),
      saveMode: mode,
      emailAddress: '', // appData.customerData && appData.customerData.customer &&
      // appData.customerData.customer.communication ? appData.customerData.customer.communication.emailAddress : '',
      sessionTimeOutFlag: false,
      autoRentersBundlePremiumAmt: appCtrlData.autoRentersPremiumAmt
    };
    return saveQuoteRequestObj;
  }
  /**
   * Creates save vehicle request payload
   * @param vehicleobject vehicleobject
   * @param saveMode saveMode
   * @returns  requestPayload
   */
  createSaveVehicleRequestPayload(vehicleobject: Vehicle, saveMode: string) {
    let appData = null;
    const appDataSubscription: Subscription = this.appStoreService.appData$.subscribe(data => appData = data);
    appDataSubscription.unsubscribe();
    const requestPayload = {} as SaveVehicleRequest;
    requestPayload.quoteNumber = appData.quoteNumber;
    requestPayload.quoteWindowId = appData._windowID;
    requestPayload.emailAddress = appData.emailAddress;
    requestPayload.lastVisitedPage = LastVisitedModule.Auto_Vehicle;
    requestPayload.pgLastVisitedPage = this.createPgLastVistedPageObject();
    // TODO function to be written to handle the value change in saveMode
    requestPayload.saveMode = (saveMode === 'edit') ? 'update' : saveMode;
    requestPayload.vehicle = vehicleobject;
    return requestPayload;

  }

  populatePrequalifiedDiscounts(response: Response): PrequalifiedDiscounts[] {
    return response.persAutoInsQuote && response.persAutoInsQuote.persAutoPolicy &&
      response.persAutoInsQuote.persAutoPolicy.discount ? response.persAutoInsQuote.persAutoPolicy.discount : [];
  }

  populateDiscountsFromRateResponse(response: RateResponse): PrequalifiedDiscounts[] {
    return response.persAutoPolicy &&
      response.persAutoPolicy.discount ? response.persAutoPolicy.discount : [];
  }

  // ** Servcie to Save the vehicle details */
  saveVehicle$(data: SaveVehicleRequest): Observable<any> {
    return this.http.post(ServiceConstants.BASE_URL + ServiceConstants.VEHICLE_SAVE, data).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError(error => this.errorService.handleError(error))
    );
  }

  saveVehicleList$(data: SaveVehicleRequest): Observable<any> {
    return this.http.post(ServiceConstants.BASE_URL + ServiceConstants.DISCOUNT_SAVE_VEHICLES, data).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError(error => this.errorService.handleError(error))
    );
  }

  /**
   * Creates pg last visted page object
   * @returns pg last visted page object
   */
  createPgLastVistedPageObject(): string {
    let navData: NavigationData;
    const navDataSubscription: Subscription = this.appStoreService.getNavigationData$.subscribe(data => navData = data);
    navDataSubscription.unsubscribe();
    let returnValue = 'FFQ-A:';
    returnValue += navData.auto.yourInfo === ModuleProgressStatus.COMPLETED ? 'C' : 'N';
    returnValue += navData.auto.vehicles === ModuleProgressStatus.COMPLETED ? 'C' : 'N';
    returnValue += navData.auto.drivers === ModuleProgressStatus.COMPLETED ? 'C' : 'N';
    returnValue += navData.auto.discounts === ModuleProgressStatus.COMPLETED ? 'C' : 'N';
    returnValue += navData.auto.quote === ModuleProgressStatus.COMPLETED ? 'C' : 'N';
    return returnValue;

  }

  /**
   * Performs rate quote$
   * @param requestPayload requestPayload
   * @returns rate quote$  Response
   */
  performRateQuote$(requestPayload, rateType): Observable<any> {

    let isHvqRate = false;
    let isGWRate = false;
    let isBrightLined = false;

    const appStore: Subscription = this.appStoreService.getProjectCodeStatus$.pipe(take(1)).subscribe((data: any) => {
      isHvqRate = data[`isHvqRate`];
      isGWRate = data[`isGWImpl`];
    });

    this.autoStoreService.getBrightLinedData$.subscribe((data) => {
      if (data) {
        if (data.isBrightlIned && data.autoCompanyCode && data.autoCompanyCode === 'B') {
          isBrightLined = true;
        }
      }
    });

    appStore.unsubscribe();
    this.autoStoreService.getBrightLinedData$.subscribe((data) => {
      if (data) {
        if (data.isBrightlIned && data.autoCompanyCode && data.autoCompanyCode === 'B') {
          isBrightLined = true;
        }
      }
    });

    let uri = ServiceConstants.PERFORM_RATE;
    // US391921 Changes for calling hvq rate
    if (isGWRate && isHvqRate && !isBrightLined && rateType !== ServiceConstants.RATE_DEFAULT) {
      uri = ServiceConstants.PERFORM_MONO_RATE;
    }
    // setting the type to skip the spinner for 2nd rate call.
    return this.http.post(ServiceConstants.BASE_URL + uri,
      Object.assign({}, requestPayload, { rateCallType: rateType })).pipe(
        map((resp: any) => resp),
        catchError(error => this.errorService.handleError(error))
      );
  }

  /**
   * Creates rate quote request payload
   */
  createRateQuoteRequestPayload(dataForRateQuote) {
    const requestPayload = {} as RateQuoteRequest;
    /** Subscription for complete data in App store */
    let appData = null;
    this.appStoreService.appData$.subscribe(data => {
      appData = data;
    }).unsubscribe();

    /** Subscription for getting redirection flow */
    let redirectionFlow = null;
    this.appStoreService.getRedirectionFlow$.subscribe(data => { redirectionFlow = data; }).unsubscribe();

    if (dataForRateQuote.rateMode === 'retrieve') {
      const todaysDate = new Date();
      const effectiveDateString = this.dateService.transform(dataForRateQuote.effectiveDate, 'yyyy-MM-dd');
      const effectiveDate = new Date(effectiveDateString);
      if (effectiveDate.getTime() < todaysDate.getTime()) {
        dataForRateQuote.effectiveDate = this.dateService.getCurrentDate();
      }
    }
    requestPayload.quoteNumber = appData.quoteNumber;
    requestPayload.landingStateCode = appData.landingStateCode;
    requestPayload.effectiveDate = dataForRateQuote.effectiveDate;
    requestPayload.rateMode = dataForRateQuote.rateMode;
    requestPayload.rateBU = dataForRateQuote.quoteType;
    requestPayload.ratePackage = dataForRateQuote.pckgType;
    requestPayload.clearCustomPackage = dataForRateQuote.customDelete;
    requestPayload.lastVisitedPage = LastVisitedModule.Auto_Quote;
    requestPayload.pgLastVisitedPage = this.createPgLastVistedPageObject();
    requestPayload.eleLinkFlag = dataForRateQuote.ELEflag;
    requestPayload.existingLobs = appData.quoteType;
    requestPayload.residenceType = dataForRateQuote.residenceType;
    requestPayload.primaryResidence = dataForRateQuote.primaryResidence;
    requestPayload.chatbotImpl = appData.projectCodeStatus.isChatBotImpl;
    requestPayload.isLiveChatImpl = appData.projectCodeStatus.isLiveChatImpl;
    requestPayload.eligibleForChatBotPreviously = dataForRateQuote.eligibleForChatBotPreviously && appData.projectCodeStatus.isChatBotImpl;
    requestPayload.rentersImpl = appData.projectCodeStatus.isRentersImpl;
    requestPayload.redirectionFlow = redirectionFlow;
    requestPayload.signalDiscountEnrolled = dataForRateQuote.signalDiscountEnrolled;
    requestPayload.eligibleForRateFeatures = dataForRateQuote.eligibleForMoreFeatures;
    return requestPayload;
  }

  /**
   * Opens live chat
   */
  openLiveChat() {

    /** Subscription for complete data in App store */
    let appData = null;
    const appDataSub: Subscription = this.appStoreService.appData$.subscribe(data => {
      appData = data;
    });
    appDataSub.unsubscribe();

    return this.http.post(ServiceConstants.BASE_URL + ServiceConstants.OPEN_LIVECHAT,
      {
        quoteNumber: appData.quoteNumber,
        lob: appData.quoteType
      }).pipe(
        map((resp: any) => resp),
        catchError(error => this.errorService.handleError(error))
      ).subscribe(res => {
        return res;
      });
  }

  /**
   * Live chat eligibility
   */
  checkLiveChatEligible(isWithinBusinessHours, chatActiveStatus) {
    /** Subscription for complete data in App store */
    let appData = null;
    const appDataSub: Subscription = this.appStoreService.appData$.subscribe(data => {
      appData = data;
    });
    appDataSub.unsubscribe();

    return this.http.post(ServiceConstants.BASE_URL + ServiceConstants.CHECK_LIVECHAT_ELIGIBLE,
      {
        quoteNumber: appData.quoteNumber,
        lob: appData.quoteType,
        liveChatImpl: appData.projectCodeStatus.isLiveChatImpl,
        businessHrFlag: isWithinBusinessHours.isWithinBusinesshoursflag,
        activeChatFlag: chatActiveStatus
      }).pipe(
        map((resp: any) => resp),
        catchError(error => this.errorService.handleError(error))
      ).subscribe(res => {
        return res;
      });
  }

  /**
   * Agent business hour service
   */
  agentBusinessHoursService$() {
    /** Subscription for complete data in App store */
    let appData = null;
    const appDataSub: Subscription = this.appStoreService.appData$.subscribe(data => {
      appData = data;
    });
    appDataSub.unsubscribe();
    return this.http.post(ServiceConstants.BASE_URL + ServiceConstants.IS_WITHIN_BUSINESS_HOUR,
      {
        quoteNumber: appData.quoteNumber,
        lob: appData.quoteType
      }).pipe(
        map((resp: any) => resp),
        catchError(error => this.errorService.handleError(error))
      );
  }

  /**
   * Chat Active Status Service
   */
  checkChatStatusService$() {
    /** Subscription for complete data in App store */
    let appData = null;
    const appDataSub: Subscription = this.appStoreService.appData$.subscribe(data => {
      appData = data;
    });
    appDataSub.unsubscribe();
    return this.http.post(ServiceConstants.BASE_URL + ServiceConstants.GET_CHAT_STATUS,
      {
        quoteNumber: appData.quoteNumber,
        lob: appData.quoteType
      }).pipe(
        map((resp: any) => resp),
        catchError(error => this.errorService.handleError(error))
      );
  }

  /**
   * Vins look up$
   * @param data data
   * @returns look up$ resp
   */
  vinLookUp$(data: any): Observable<any> {
    return this.http.post(ServiceConstants.BASE_URL + ServiceConstants.VIN_LOOKUP, data).pipe(
      map((resp: any) => {
        resp.vehicle = Object.assign({}, resp.vehicle, { isValidVin: true });
        return resp;
      }),
      catchError(error => this.errorService.handleError(error))
    );
  }

  /**
   * Creates save driver request payload
   * @param driver - Driver Data
   * @param saveMode - Save mode value
   * @returns Returns Sve Driver Object Data
   */
  // AddDriverComponent.html:11 ERROR TypeError: Cannot assign to read only property 'quoteNumber' of object '[object Object]'
  createSaveDriverRequestPayload(driver: Driver, saveMode: string) {
    const saveDriverObject = {} as SaveDriverObject;

    /** Subscription for complete data in App store */
    let appData = null;
    const appDataSub: Subscription = this.appStoreService.appData$.subscribe(data => {
      appData = data;
    });
    appDataSub.unsubscribe();
    saveDriverObject.quoteNumber = appData.quoteNumber;
    saveDriverObject.quoteWindowId = appData._windowID;
    saveDriverObject.emailAddress = appData.emailAddress;
    saveDriverObject.saveMode = saveMode;
    saveDriverObject.driver = driver;

    saveDriverObject.effectiveDate = this.dateService.getCurrentDate();
    saveDriverObject.landingStateCode = appData.landingStateCode;
    saveDriverObject.lastVisitedPage = LastVisitedModule.Auto_Driver;
    // saveDriverObject.pgLastVisitedPage = 'FFQ-Redesign';
    saveDriverObject.pgLastVisitedPage = this.createPgLastVistedPageObject();
    return saveDriverObject;
  }

  createSavedriverVehicleAssignmentReq(driver: any, vehicle: any, saveMode: string) {
    const savedriverVehicleAsgnObj = {} as SavedriverVehicleAssignment;
    /** Subscription for complete data in App store */
    let appData = null;
    const appDataSub: Subscription = this.appStoreService.appData$.subscribe(data => {
      appData = data;
    });
    appDataSub.unsubscribe();
    savedriverVehicleAsgnObj.quoteNumber = appData.quoteNumber;
    savedriverVehicleAsgnObj.quoteWindowId = appData._windowID;
    savedriverVehicleAsgnObj.emailAddress = appData.emailAddress;
    savedriverVehicleAsgnObj.saveMode = saveMode;
    savedriverVehicleAsgnObj.effectiveDate = this.dateService.getCurrentDate();
    savedriverVehicleAsgnObj.landingStateCode = appData.landingStateCode;
    if (vehicle) {
      savedriverVehicleAsgnObj.vehicles = vehicle;
    }
    savedriverVehicleAsgnObj.drivers = driver;
    savedriverVehicleAsgnObj.lastVisitedPage = LastVisitedModule.Auto_VehicleDriverAssignment;
    savedriverVehicleAsgnObj.pgLastVisitedPage = this.createPgLastVistedPageObject();
    return savedriverVehicleAsgnObj;
  }

  /**
   * Creates delete driver request payload
   */
  createDeleteDriverRequestPayload(driverRef: string, saveMode: string) {
    const deleteDriverObject = {} as SaveDriverObject;

    /** Subscription for complete data in App store */
    let appData = null;
    const appDataSub: Subscription = this.appStoreService.appData$.subscribe(data => {
      appData = data;
    });
    appDataSub.unsubscribe();

    deleteDriverObject.quoteNumber = appData.quoteNumber;
    // deleteDriverObject.quoteWindowId = this.appData._windowID;
    deleteDriverObject.emailAddress = appData.emailAddress;
    deleteDriverObject.lastVisitedPage = LastVisitedModule.Auto_Driver;
    deleteDriverObject.landingStateCode = appData.landingStateCode;
    deleteDriverObject.effectiveDate = this.dateService.getCurrentDate();
    // Save Mode would be delete
    deleteDriverObject.saveMode = saveMode;
    deleteDriverObject.fccDisclaimerContent = 'N';
    deleteDriverObject.fccDisclaimerImpl = 'N';
    deleteDriverObject.currentInsuredAnswer = 'N';
    deleteDriverObject.quoteWindowId = 'null';
    deleteDriverObject.pgLastVisitedPage = this.createPgLastVistedPageObject();
    const driverSub: Subscription = this.autoStoreService.getDriverDataWithRef$(driverRef).subscribe((driverData) => {
      deleteDriverObject.driver = driverData ? JSON.parse(JSON.stringify(driverData)) : null;
    }
    );
    driverSub.unsubscribe();
    // JK Should the clear happen here or later
    // this.driverStoreService.clearDriverDataSelection({ selectedDrv: null });
    return deleteDriverObject;
  }

  /**
   * Creates save pniadditional data service req
   * @param savePniDataReq SavePNIAdditionalData
   * @returns  SavePNIAdditionalData
   */
  createSavePNIAdditionalDataServiceReq(savePniDataReq: SavePNIAdditionalData) {
    this.populatePNIAdditionalData(savePniDataReq);

    /** Subscription for complete data in App store */
    let appData = null;
    const appDataSub: Subscription = this.appStoreService.appData$.subscribe(data => {
      appData = data;
    });
    appDataSub.unsubscribe();

    savePniDataReq.quoteNumber = appData.quoteNumber;
    return savePniDataReq;
  }


  /**
   * Populates pniadditional data
   * @param savePniDataReq  SavePNIAdditionalData
   */
  populatePNIAdditionalData(savePniDataReq) {
    savePniDataReq.lob = 'A';
    savePniDataReq.effectiveDate = this.dateService.getCurrentDate();
    savePniDataReq.driverReference = '1';
    // savePniDataReq.fccDisclaimerContent = DataConstants.DisclaimerTexts.fccDisclaimer;
    savePniDataReq.fccDisclaimerImpl = 'Y';
    savePniDataReq.saveMode = 'add';
  }

  /**
   * Populates control data for save pni
   * @ param respData
   * @ param requestPayload
   * @ returns pniControlData
   */
  populateControlDataForSavePNI(respData: any, requestPayload: any) {
    const pniControlData: any = { isEaiBrightLineInd: false, isEmailValid: false, savePNIStatus: '', isDNQEligible: false, bwBLReason: '' };
    pniControlData.isEaiBrightLineInd = respData.eaiBrightLineInd;
    pniControlData.isEmailValid = respData.validateEmailResp.status;
    pniControlData.mdKnockoutInd = respData.mdKnockoutInd;
    pniControlData.isDNQEligible = respData.isDNQEligible;
    pniControlData.bwBLReason = respData.bwBLReason;
    if (!respData.eaiBrightLineInd) {
      pniControlData.autoCompanyCode = 'F';
      pniControlData.isBwPopupShown = false;
    }
    return pniControlData;
  }

  /**
   * Populates communication data
   * @param respData ResponseData
   * @param requestPayload RequestPayload
   * @returns  CommunicationData
   */
  populateCommunicationData(respData: any, requestPayload: any) {
    const communcationData = {} as CommunicationData;
    const address = {} as AddressData;
    if (respData.diffMailAddress) {
      address.street = respData.diffMailAddress.street;
      address.apartment = respData.diffMailAddress.apartment;
      address.city = respData.diffMailAddress.city;
      address.state = respData.diffMailAddress.state;
      address.zip = respData.diffMailAddress.zip;
      communcationData.diffMailAddress = address;
      communcationData.diffMailInd = 'Y';
    }
    communcationData.phoneNumber = requestPayload.driverPhone;
    communcationData.emailAddress = respData.validateEmailResp.address;
    return communcationData;
  }

  /**
   * Saves driver$
   * @param data save driver api req data
   * @returns driver$ Observable
   */
  saveDriver$(data: any): Observable<any> {
    return this.http.post(ServiceConstants.BASE_URL + ServiceConstants.DRIVER_SAVE, data).pipe(
      map((resp: any) => resp),
      catchError(error => this.errorService.handleError(error))
    );
  }

  /**
   * Saves pni data$
   * @param data content
   * @returns pni data $
   */
  savePniData$(data: any): Observable<any> {
    return this.http.post(ServiceConstants.BASE_URL + ServiceConstants.SAVE_PNI, data).pipe(
      map((resp: any) => resp),
      catchError(error => this.errorService.handleError(error))
    );
  }


  /**
   * Fetchs drop down list$
   * @returns drop down list$
   */
  driverVehicleAssignment$(data: any): Observable<any> {
    return this.http.post(ServiceConstants.BASE_URL + ServiceConstants.DRIVER_VEHICLE_ASSIGNMENT, data).pipe(
      map((resp: any) => resp),
      catchError(error => this.errorService.handleError(error))
    );
  }

  /**
   * Populates pnisave failure error
   * @param err array
   * @returns err array
   */
  populatePNISaveFailureError(err: any) {
    const errorCodeArray = err.errorInfo.errorCode.split(',');
    return errorCodeArray;
  }

  /**
   * bw Agent Assignment$
   * @param data bwAgentAssignment api req
   */
  bwAgentAssignment$() {
    /**
     * BW Assign Agent Request Object
     */
    const bwAgentAssignmentReq = {} as BwAssignAgent;
    const appDataSubscription: Subscription = this.appStoreService.appData$.subscribe(data =>
      bwAgentAssignmentReq.quoteNumber = data.quoteNumber);
    appDataSubscription.unsubscribe();
    return this.http.post(ServiceConstants.BASE_URL + ServiceConstants.BW_AGENT_ASSIGNMENT, bwAgentAssignmentReq).pipe(
      map((resp: any) => resp),
      catchError(error => this.errorService.handleError(error))
    );
  }
  /**
   * Saves driver$
   * @param data save driver api req data
   * @returns driver$ Observable
   */
  saveDiscount$(data: any): Observable<any> {
    return this.http.post(ServiceConstants.BASE_URL + ServiceConstants.SAVE_DISCOUNT, data).pipe(
      map((resp: any) => resp),
      catchError(error => this.errorService.handleError(error))
    );
  }

  /**
   * Invokes Connected Car Service
   * @param data content
   */
  connectedCarService$(data: ConnectedCarRequest): Observable<any> {
    return this.http.post(ServiceConstants.BASE_URL + ServiceConstants.CONNECTED_CAR_API_URL, data).pipe(
      map((resp: any) => resp),
      catchError(error => this.errorService.handleError(error))
    );
  }

  /**
   * Saves signalDiscount$
   * @param data save signal discount api req data
   * @returns resp$ Observable
   */
  saveSignalDiscount$(requestPayload): Observable<any> {
    return this.http.post(ServiceConstants.BASE_URL + ServiceConstants.SAVE_SIGNAL_DISCOUNT, requestPayload).pipe(
      map((resp: any) => resp),
      catchError(error => this.errorService.handleError(error))
    );
  }

  /**
   * Get Cross sell Encrypted data$
   * @param data crosssell encrypted req data
   * @returns encrypted data$ Observable
   */
  getCrossSellEncryptedData$(data: any): Observable<any> {
    return this.http.get(ServiceConstants.BASE_URL + ServiceConstants.GET_ENCRYPTED_CROSS_SELL_DATA + `?quoteNumber=${data}`).pipe(
      map((resp: any) => resp),
      catchError(error => this.errorService.handleError(error))
    );
  }
  /**
   * Customs rate$
   * @param requestPayload RequestPayload
   * @returns rate$ rateResponse
   */
  customRate$(requestPayload): Observable<any> {
    return this.http.post(ServiceConstants.BASE_URL + ServiceConstants.CUSTOM_QUOTE,
      requestPayload).pipe(
        map((resp: any) => resp),
        catchError(error => this.errorService.handleError(error))
      );
  }

  setPackageChoosen(packageName) {
    const quoteSummaryConstants = DataConstants.QuoteSummary;
    let packageChoosen;
    if (packageName === quoteSummaryConstants.basicPackage) {
      packageChoosen = 'B';
    } else if (packageName === quoteSummaryConstants.enhancedPackage) {
      packageChoosen = 'E';
    } else if (packageName === quoteSummaryConstants.fullPackage) {
      packageChoosen = 'F';
    } else if (packageName === quoteSummaryConstants.customPackage) {
      packageChoosen = 'C';
    }
    return packageChoosen;
  }

  /**  addRideShare service call   */
  addRideShare$(req): Observable<any> {
    return this.http.post(ServiceConstants.BASE_URL + ServiceConstants.RIDE_SHARE, req).pipe(
      map((resp: any) => {
        return {
          resp
        };
      }),
      catchError(error => this.errorService.handleError(error))
    );
  }

  /**  validateLicense service call   */
  validateLicenseNumber$(req): Observable<any> {
    return this.http.post(ServiceConstants.BASE_URL + ServiceConstants.VALIDATE_LICENSE, req).pipe(
      map((resp: any) => {
        return {
          resp
        };
      }),
      catchError(error => this.errorService.handleError(error))
    );
  }
  /**
   * Renters bundle rate quote$
   * @returns bundle rate quote$
   */
  rentersBundleRateQuote$(): Observable<any> {
    const requestPayload = this.createRentersRateRequestPayload();
    return this.http.post(ServiceConstants.BASE_URL + ServiceConstants.RENTERS_RATE_QUOTE, requestPayload).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError(error => this.errorService.handleError(error))
    );
  }

  /**
   * Creates renters rate request payload
   * @returns rentersRateQuoteRequestObj
   */
  createRentersRateRequestPayload() {
    /** Subscription for complete data in App store */
    let appData = null;
    this.appStoreService.appData$.subscribe(data => {
      appData = data;
    }).unsubscribe();
    /* Request Object for renters rate service */
    const rentersRateQuoteRequestObj = {
      quoteNumber: appData.quoteNumber,
      quoteWindowId: appData.quoteWindowId,
      landingStateCode: appData.landingStateCode,
      effectiveDate: this.dateService.getCurrentDate(),
      rateMode: 'rate'
    };
    return rentersRateQuoteRequestObj;
  }

  createExcludedSaveDriverRequestPayload(excludeDriverList: ExcludedDriver[], isYDImpl: boolean): SaveExcludedDriversRequest {
    const saveExcludedDriverObj = {} as SaveExcludedDriversRequest;
    /** Subscription for complete data in App store */
    let appData = null;
    const appDataSub: Subscription = this.appStoreService.appData$.subscribe(data => {
      appData = data;
    });
    appDataSub.unsubscribe();

    let ydExists = false;
    this.autoStoreService.getPrefillYdExists$.subscribe(data => {
      ydExists = data;
    }).unsubscribe();    
    
    saveExcludedDriverObj.quoteNumber = appData.quoteNumber;
    saveExcludedDriverObj.quoteWindowId = appData._windowID;
    saveExcludedDriverObj.saveMode = 'add';
    saveExcludedDriverObj.driver = excludeDriverList;
    saveExcludedDriverObj.pgLastVisitedPage = this.createPgLastVistedPageObject();
    saveExcludedDriverObj.lastVisitedPage = LastVisitedModule.Auto_AdditionalInfo;
    saveExcludedDriverObj.isYDExist = isYDImpl && ydExists;
    return saveExcludedDriverObj;
  }

  getStaticString(submodule, headerOrDiscliamer, name) {
    const content = submodule[headerOrDiscliamer].find(item => item.name === name);
    if (headerOrDiscliamer === 'disclaimerText' && content) {
      const disclaimer = content.value.replace(/\\n/g, '\n');
      return disclaimer;
    } else if (headerOrDiscliamer === 'infoContent') {
      return content ? {
        name: content.name ? content.name : null, title: content.title ? content.title :
          null, value: content.value.replace(/\\"/g, '"')
      } : null;
    }
    return content ? content.value : null;
  }

  // service call for quote print
  dispatchPrintQuote$(requestPayload): Observable<any> {
    return this.http.post(ServiceConstants.BASE_URL + ServiceConstants.DISPATCH_PRINT, requestPayload).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError(error => this.errorService.handleError(error))
    );
  }

  /**
   * Gets fetch town list$
   * @param date date
   * @returns fetch town list$
   */
  getFetchTownList$(): Observable<any> {
    const apiUrl = environment.endPoint + ServiceConstants.FETCH_TOWN_LIST;
    let appData = null;
    this.appStoreService.getAppControlValues$.subscribe(data => appData = data).unsubscribe();
    const townListRequest = {
      landingZipCode: appData.landingZipCode,
      landingStateCode: appData.landingStateCode
    };
    return this.http.post(apiUrl, townListRequest).pipe
      (map((resp: any) => resp),
        catchError(error => this.errorService.handleError(error)));
  }

  /**
   * Retrieves PI rate summary$
   * @param data Data
   * @returns quote$ Observable
   */
  retrievePIRateSummary$(data: any): Observable<any> {
    return this.http.post(ServiceConstants.BASE_URL + ServiceConstants.RETRIEVE_SUMMARY_QUOTE, data).pipe(
      map((resp: any) => resp),
      catchError(error => this.errorService.handleError(error))
    );
  }

  /**
   * Generates drop down values
   * @param fieldElement fieldElement
   * @returns result
   */
  private generateDropDownValues(fieldElement) {
    const result = {
      coverageCode: fieldElement.coverageCode,
      dropDownValues: fieldElement.dropDownValues.map(element => ({ label: element.webDesc, value: element.value }))
    };
    return result;
  }

  getEmployerDropdown$(reqPayload): Observable<any> {
    return this.http.post(ServiceConstants.BASE_URL + ServiceConstants.EMPLOYER_DROPDOWN, reqPayload).pipe(
      map((resp: any) => resp),
      catchError(error => this.errorService.handleError(error))
    );
  }

  /**
   * save excluded driver$
   * @returns save excluded driver$
   */
  saveExcludedDriver$(data: SaveExcludedDriversRequest): Observable<any> {
    return this.http.post(ServiceConstants.BASE_URL + ServiceConstants.SAVE_EXCLUDED_DRIVER, data).pipe(
      map((resp: any) => resp),
      catchError(error => this.errorService.handleError(error))
    );
  }

  /**
   * Invokes vehicle save actions service
   * @param requestPayload VehicleSaveActionsRequest
   */
  invokeVehicleSaveActionsService(requestPayload: VehicleSaveActionsRequest): Observable<any> {
    /* US347502: Invoke TNEDICCA Service for Partner Quotes*/
    const reqURL = ServiceConstants.BASE_URL + ServiceConstants.VEHICLE_SAVE_ACTIONS_URL;
    return this.http.post(reqURL, requestPayload);
  }

  /**
   * Invokes save actions service
   * @param requestPayload SaveActionsRequest
   */
    invokeSaveActionsService(requestPayload: SaveActionsRequest): Observable<any> {
      const reqURL = ServiceConstants.BASE_URL + ServiceConstants.RATE_SAVE_ACTIONS_URL;
      return this.http.post(reqURL, requestPayload);
    }


  /**
   * Invokes Credit Report Service
   * @param data content
   */
  creditReportService(data: CreditReportRequest): void {
    this.http.post(ServiceConstants.BASE_URL + ServiceConstants.CREDIT_REPORT_URL, data)
      .subscribe((response: any) => response,
        err => this.errorService.handleError(err));
  }

  /**
   * sendEmailToScheduleAgent
   * @param data Data
   * @returns Observable
   */
  sendEmailToScheduleAgent$(data: ScheduleAgentAppointmentRequest): Observable<any> {
    return this.http.post(ServiceConstants.BASE_URL + ServiceConstants.SCHEDULE_AGENT_EMAIL, data).pipe(
      map((resp: any) => resp),
      catchError(error => this.errorService.handleError(error))
    );
  }
}
