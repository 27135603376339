import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DateValidatorService } from '@ffq-app-core/services/date-validator.service';
import { DataConstants } from '@ffq-app-shared/constants';
import { Customer, FieldEnagement, ScheduleAgentAppointmentRequest,
  ScheduleAgentConfirmationData } from '@ffq-app-shared/model/common.data.model';
import { QuoteVariableAdjustedProps } from '@ffq-app-shared/model/segment.data.model';
import { HelperService } from '@ffq-app-shared/services/helper.service';
import { SegmentService } from '@ffq-app-shared/services/segment.service';
import { AppStoreService } from '@ffq-app-store/app.store.service';
import { DateService } from '@ffq-lib/src/lib/core/services/date.service';
import { Subscription } from 'rxjs';
import { CommunicationData, CustomerData } from './model/customer-data.model';
import { ScheduleAgentValidtion } from './model/schedule-agent-validation';
import { ServiceConstants } from '@ffq-app-shared/constants/service.constants';
import { ScheduleAgentConfirmationComponent } from '../schedule-agent-confirmation/schedule-agent-confirmation.component';
import { DatePipe } from '@angular/common';
import { DataService } from '@ffq-app-core/services/data.service';

@Component({
  selector: 'app-schedule-agent-appointment',
  templateUrl: './schedule-agent-appointment.component.html',
  styleUrls: ['./schedule-agent-appointment.component.scss']
})
export class ScheduleAgentAppointmentComponent implements OnInit, OnDestroy {
  formGroup: FormGroup;
  quoteNumber: string;
  customerData: CustomerData;
  communicationData: CommunicationData;
  private subscriptions: Subscription[] = [];
  disclaimer1: string;
  ccpaUrl = ServiceConstants.CCPA_URL;
  segmentFieldDescription = DataConstants.SEGMENT_FIELD_ENGAGEMENT_DESCRIPTION;
  scheduleApptParams = new ScheduleAgentValidtion(this.dateValidatorService, this.dateService);
  isFormSubmitted: boolean;
  isEmailIdCaptured = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private appStoreService: AppStoreService,
    private helperService: HelperService,
    private segmentService: SegmentService,
    private formBuilder: FormBuilder,
    private dateService: DateService,
    private dateValidatorService: DateValidatorService,
    private dialogRef: MatDialogRef<ScheduleAgentAppointmentComponent>,
    private dialog: MatDialog,
    private dataService: DataService,
    private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.appStoreService.getAppControlValues$.subscribe((controlValues: any) => {
      this.quoteNumber = controlValues.quoteNumber;
    });
    this.appStoreService.appCustomerData$.subscribe((customerData: any) => {
      this.customerData = customerData;
    });
    // US376312: Fetching communication details
    this.appStoreService.getCommunicationData$.subscribe((communicationData: CommunicationData) => {
      this.communicationData = communicationData;
      this.isEmailIdCaptured = this.communicationData.communication.emailAddress ? true : false;
    });
    this.buildForm();
    for (const field of Object.keys(this.formGroup.controls)) {
      this.scheduleApptParams[field][`formGroup`] = this.formGroup;
    }
    this.setDateLimits();
    this.subscriptions.push(this.appStoreService.getValidateEmail$.subscribe(validateEmailResponse => {
      if (validateEmailResponse && this.isFormSubmitted ) {
        this.provideEmailCodeValidationMessage(validateEmailResponse);
      }
    }));
    this.subscriptions.push(this.appStoreService.appError$.subscribe((errorObj: any) => {
      if (errorObj && Object.keys(errorObj).length !== 0) {
          if (errorObj.service === 'ValidateEmail' && this.isFormSubmitted ) {
            this.handleEmailValidationFailure();
          }
      }
    }));
    this.subscriptions.push(this.appStoreService.getStaticContent$.subscribe(() => {
      this.setStaticContent();
    }));
  }

  setStaticContent(): void {
    const agentAppointmentContent = this.appStoreService.findContent('agentAppointment');
    if (agentAppointmentContent && agentAppointmentContent.summary) {
      this.disclaimer1 = this.dataService.getStaticString(agentAppointmentContent.summary,
        'disclaimerText',  DataConstants.AGENT_APPOINTMENT_CONST.Disclaimer1);
    }
  }

  setDateLimits(): void {
    const currentDate = new Date();
    const nextDay = currentDate.getDate() + 1;
    const month = currentDate.getMonth();
    const nextMonth = new Date(new Date().setMonth(month + 1));
    this.scheduleApptParams.date.minDate = this.dateService.getDateFromYMD(null, null, nextDay);
    this.scheduleApptParams.date.maxDate = this.dateService.transform(nextMonth, 'yyyy-MM-dd');
  }

  buildForm(): void {
    this.formGroup = this.formBuilder.group({
      firstName: [this.customerData.firstName ? this.customerData.firstName : '', this.scheduleApptParams.firstName.validators],
      lastName: [this.customerData.lastName ? this.customerData.lastName : ''],
      emailId: [this.communicationData.communication.emailAddress ?
        this.communicationData.communication.emailAddress : '', this.scheduleApptParams.emailId.validators],
      phoneNumber: [this.communicationData.communication.phoneNumber ?
        this.communicationData.communication.phoneNumber : '', this.scheduleApptParams.phoneNumber.validators],
      date: ['', this.scheduleApptParams.date.validators],
      time: new FormArray([], this.scheduleApptParams.time.validators),
      existingFarmCust: ['', this.scheduleApptParams.existingFarmCust.validators],
      reason: ['', this.scheduleApptParams.reason.validators],
      quoteNumber: [this.quoteNumber ? this.quoteNumber : '']
    });
    this.customerData.firstName ? this.formGroup.get('firstName').disable() : this.formGroup.get('firstName').enable();
    this.customerData.lastName ? this.formGroup.get('lastName').disable() : this.formGroup.get('lastName').enable();
    this.quoteNumber ? this.formGroup.get('quoteNumber').disable() : this.formGroup.get('quoteNumber').enable();
    this.communicationData.communication.emailAddress ? this.formGroup.get('emailId').disable() : this.formGroup.get('emailId').enable();
    this.communicationData.communication.phoneNumber ?
      this.formGroup.get('phoneNumber').disable() : this.formGroup.get('phoneNumber').enable();
  }

  trackFieldEngagement(dataFromField: FieldEnagement, description: string, variableName: string): void {
    const variableInfo: QuoteVariableAdjustedProps = {
      action: dataFromField.event,
      category: 'PNI',
      description,
      step: 'Schedule agent appointment',
      variable_name: variableName,
      variable_value: dataFromField.value,
      flow_type: 'NA'
    };
    if (variableName === 'Date of Birth') {
      variableInfo.variable_value = 'xx/xx/xxxx';
    }
    this.segmentService.trackQuoteVariableAdjusted(variableInfo);
  }

  /**
   * Provides email code validation message
   * @param validateEmailResponse validateemailResponse
   */
  provideEmailCodeValidationMessage(validateEmailResponse): void {
    if (validateEmailResponse.status === 'valid' || validateEmailResponse.status === 'accept_all') {
      this.postSubmitActions();
    } else {
      // DE187554 : email focus issue fix on failure
      this.handleEmailValidationFailure();
    }
  }
  /**
   * handle email validation failure
   */
  handleEmailValidationFailure(): void {
    this.isFormSubmitted = false;
    this.scheduleApptParams.emailId.messages.pattern = DataConstants.AGENT_APPOINTMENT_ERROR_MESSAGES.EmailPattern;
    this.formGroup.get('emailId').setErrors({ pattern: true });
    this.helperService.scrollToFirstError('auto-module__schedule-agent-appt-forms');
  }
  /**
   * on form submit
   */
  submit(): void {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) {
      const emailEntered = this.formGroup.controls.emailId.value;
      if (!this.isEmailIdCaptured) {
        this.appStoreService.validateEmail({ address: emailEntered });
        this.isFormSubmitted = true;
      } else {
        this.postSubmitActions();
      }
    } else {
      this.helperService.scrollToFirstError('auto-module__schedule-agent-appt-forms');
    }
  }
  /**
   * on click of submit
   */
  postSubmitActions(): void {
    this.segmentService.trackCtaClicked('Schedule Agent Appointment button submitted');
    this.updateCommsInAppStore();
    /** US476266: Update the customer details in store from appointment popup submit in knockout screen */
    this.updateCustomerDetailsInStoreIfNotExists();
    this.sendEmailToAgent();
    this.openConfirmationModal();
    this.dialogRef.close();
  }
  /**
   * send email to agent
   */
  sendEmailToAgent(): void {
    const scheduleAgentEmailRequest: ScheduleAgentAppointmentRequest = this.constructSendEmailRequest();
    // US373700 - trigger REST API call to send email to agent
    this.appStoreService.sendEmailToScheduleAgent(scheduleAgentEmailRequest);
  }

  /**
   * constructs send email request
   * @returns ScheduleAgentAppointmentRequest
   */
  private constructSendEmailRequest(): ScheduleAgentAppointmentRequest {
    return {
      customerName: [this.formGroup.controls.firstName.value,
      this.formGroup.controls.lastName.value].join(DataConstants.SPACE),
      customerEmail: this.formGroup.controls.emailId.value,
      customerPhoneNumber: this.formGroup.controls.phoneNumber.value,
      isFarmersCustomer: this.formGroup.controls.existingFarmCust.value,
      quoteNumber: this.formGroup.controls.quoteNumber.value,
      scheduleDate: this.formGroup.controls.date.value,
      scheduleTime: this.formGroup.controls.time.value,
      contactReason: this.formGroup.controls.reason.value,
      fccDisclaimerContent: this.disclaimer1
    };
  }

  /**
   * update communication details in app store
   */
  updateCommsInAppStore(): void {
    if (this.customerData?.firstName && (this.formGroup.controls.phoneNumber.enabled
      || this.formGroup.controls.emailId.enabled)) {
      const communicationData = {
        phoneNumber: this.formGroup.controls.phoneNumber.value,
        emailAddress: this.formGroup.controls.emailId.value
      };
      this.appStoreService.updateCommsFromApptPopup(communicationData);
    }
  }

  updateCustomerDetailsInStoreIfNotExists(): void {
    if (this.formGroup.controls.phoneNumber.enabled
      || this.formGroup.controls.emailId.enabled
      || this.formGroup.controls.firstName.enabled
      || this.formGroup.controls.lastName.enabled) {
        const customerData = {} as Customer;
        const communicationData = {
          phoneNumber: this.formGroup.controls.phoneNumber.value,
          emailAddress: this.formGroup.controls.emailId.value
        };
        customerData.firstName = this.formGroup.controls.firstName.value;
        customerData.lastName = this.formGroup.controls.lastName.value;
        customerData.communication = communicationData;
        this.appStoreService.updateCustomerDetailsFromApptPopup(customerData);
    }
  }

  /**
   * close the modal dialog
   */
  close(): void {
    this.dialogRef.close();
  }
  /**
   * open schedule agent confirmation popup
   */
  openConfirmationModal(): void {
    this.dialog.open(ScheduleAgentConfirmationComponent, {
      panelClass: 'schedule-agent-confirmation-dialog',
      id: 'confirmationModal',
      data: this.populateConfirmationModalData(),
      ariaLabelledBy: 'schedule-agent-confirmation-title',
      ariaDescribedBy: 'schedule-agent-confirmation-description',
      autoFocus: true
    });
}
/**
 * Populate schedule agent confirmaton data
 * @returns Schedul Agent Confirmation Input
 */
populateConfirmationModalData(): ScheduleAgentConfirmationData {
    const scheduleConfirmData: ScheduleAgentConfirmationData = {
        agentName : [this.data.firstName, DataConstants.SPACE, this.data.lastName].join(DataConstants.EMPTY_STRING),
        scheduleDate : this.datePipe.transform(this.formGroup.controls.date.value, 'longDate'),
        scheduleTime : this.formGroup.controls.time.value
    };
    return scheduleConfirmData;
}
/**
 * on destroy
 */
ngOnDestroy(): void {
  this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
}

}


