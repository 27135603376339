<mat-form-field [formGroup]="params.formGroup" ngClass="{{hasCoverageError?'has-coverage-error':' '}} {{enableFocusRing?'enable-focus-ring':' '}} full-width-field form-elements select-box" appearance="fill">
  <mat-label aria-hidden="true" *ngIf="params.placeholder">{{ params.placeholder }}</mat-label>
  
  <mat-select #matSelect [aria-label]="params.ariaLabel" [attr.aria-expanded] = "matSelect.panelOpen" disableOptionCentering (focus)="focusHandle()"(blur)="blurHandle()"  [formControl]="params.formGroup.controls[params.formControllName]" (selectionChange) = "selectedValue($event)" attr.data-GTM="{{params.gtmTag}}">
    <mat-option [ngClass]="getOption(option.label)" *ngFor="let option of params.values" [disabled] = 'option.disable' [value]="option.value" [attr.data-GTM]="option.gtmTag">{{ option.label }}</mat-option>
  </mat-select>
  <span id="Vehicle"></span>

    <mat-hint *ngIf="params.assistiveText" align="start"><strong>{{params.assistiveText}}</strong> </mat-hint>
    <mat-error aria-hidden="true">
      <ng-container *ngIf="params.messages">
        <ng-container *ngFor="let key of Object.keys(params.messages)">
          <ng-container *ngIf="((params.formGroup).get(params.formControllName)).hasError(key)">
            {{ params.messages[key] }}</ng-container>
        </ng-container>
      </ng-container>
    </mat-error>
    <lib-form-field-loader *ngIf="params.showLoader"></lib-form-field-loader>
  </mat-form-field>