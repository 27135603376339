import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AutoState } from '../../store';
import * as fromDriversSelectors from './drivers.selectors';
import * as fromAutoActions from './../../store/auto.actions';
import * as DriversState from './drivers.state';
import * as fromDriversActions from './drivers.actions';

@Injectable({
    providedIn: 'root'
})
export class DriversStoreService {

    getFetchDropDownList$ = this.driversStore.select(fromDriversSelectors.getfetchDropdownList);
    getfetchDropdownListStatus$ = this.driversStore.select(fromDriversSelectors.getfetchDropdownListStatus);
    fetchIncidentCategoryList$ = this.driversStore.select(fromDriversSelectors.getfetchIncidentCategoryList);
    // getSubmitPNIDriverData$ = this.driversStore.select(fromDriversSelectors.getSubmitPNIDriverData);
    getSubmitPNIBWData$ = this.driversStore.select(fromDriversSelectors.getSubmitPNIBWData);
    getMDKnockoutStatus$ = this.driversStore.select(fromDriversSelectors.getMDKnockoutStatus);
    getDriverAssignmentData$ = this.driversStore.select(fromDriversSelectors.getDriverAssignmentData);
    getVehicleAssignmentData$ = this.driversStore.select(fromDriversSelectors.getVehicleAssignmentData);
    getSavePNIStatus$ =  this.driversStore.select(fromDriversSelectors.getSavePNIStatus);
    getDriversControlData$ = this.driversStore.select(fromDriversSelectors.getDriversControlData);

    /**
     * Addnl details form data$ of drivers store service
     */
    addnlDetailsFormData$ = this.driversStore.select(fromDriversSelectors.addnlDetailsFormData);
    constructor(
        private autoStore: Store<AutoState.AppState>,
        private driversStore: Store<DriversState.DriversState>
    ) { }

    fetchDropDownList() {
        this.driversStore.dispatch(fromDriversActions.FetchDropDownList());
    }
    /** US49164 : Fetch Dropdowns for Accidents & Violations  - Dispatcher - START */
    fetchIncidentCategoryDropDownList() {
        this.driversStore.dispatch(fromDriversActions.FetchDropDownIncidentCategoryUCCList());
        this.driversStore.dispatch(fromDriversActions.FetchDropDownIncidentCategoryACCList());
    }
    /** US49164 : Fetch Dropdowns for Accidents & Violations  - Dispatcher - END */

    removeDriver(reqPayload: any) {
        this.autoStore.dispatch(fromDriversActions.RemoveDriver({ reqPayload }));
    }

    /**
     * Resets driver state
     */
    resetDriverState() {
        this.driversStore.dispatch(fromDriversActions.ResetDriverState());
    }

    /**
     * Removes additional married driver
     * @ param reqPayload
     */

    cancelAddDriver(ctrlData: any) {
        this.driversStore.dispatch(fromDriversActions.CancelAddDriver({ ctrlData }));
    }

    updateControlData(ctrlData: any) {
        this.driversStore.dispatch(fromDriversActions.UpdateControlData({ ctrlData }));
    }
}

