import { Injectable } from '@angular/core';
import { FlowIndentifierEnum } from '@ffq-app-shared/enums/flow.identifier.enum';
import { Quote, PrequalifiedDiscounts, IIDRiskIndicator } from '@ffq-app-shared/model/common.data.model';



@Injectable({
    providedIn: 'root'
})
export class FilterStoreStateService {


    filterResponse(resp: any, flow: string) {
        delete resp.respData.windowId;
        delete resp.respData.quoteNumber;
        delete resp.respData.quoteWindowId;
        switch (flow) {
            case FlowIndentifierEnum.SUBMIT_YOUR_INFO: {
                resp = Object.assign({}, resp, {
                    ctrlData:
                        { maxAllowedVehicle: resp.respData.maxAllowedVehicle, restrictedFireProductLobs: resp.respData.restrictedFireLobs
                          , ...resp.ctrlData }
                });
                delete resp.respData.maxAllowedVehicle;
                break;
            }
            case FlowIndentifierEnum.PREFILL_DATA: {
                if (resp.respData.iidBehaviorCode === 'SC') {
                resp = Object.assign({}, resp, { respData: { prefillData: resp.respData } },
                        {respErr: {service: 'PrefillData', message: 'KO - IID KNOCKOUT'}},
                    { ctrlData: { ...resp.ctrlData, prefillData: null } });
                } else {
                const isPrefillDataReceived: boolean = !(resp.respData.vehicle.length === 0 && resp.respData.driver.length === 0);
                resp = Object.assign({}, resp, { respData: { prefillData: resp.respData } },
                    { ctrlData: { ...resp.ctrlData, isPrefillDataReceived } });
                }
                break;
            }
            case FlowIndentifierEnum.VALIDATE_ZIP: {
                resp = Object.assign({}, resp, { respData: resp.respData });
                break;
            }
            case FlowIndentifierEnum.RATE_QUOTE: {
                if (!resp.respData.knockOutInd) {
                    const quoteResponse = this.filterQuoteResponse(resp.respData);
                    resp = Object.assign({}, resp, { respData: quoteResponse }, { respError: null });
                } else {
                    resp = Object.assign({}, resp, { respError: this.populateKnockOutErrorForStore(resp.respData, flow) });
                }
                break;
            }
            case FlowIndentifierEnum.BW_AGENT_ASSIGNMENT:
            case FlowIndentifierEnum.DRIVER_ASSIGNMENT: {
                /** US524052 : FFQ Auto - Assigned agent during Brightline */
                if (resp.respData.vehicleAssignmentKnockoutInd ||
                    (resp.respData.knockOutResponseBean && (resp.respData.knockOutResponseBean?.errorCode))) {
                    resp = Object.assign({}, resp, { respError: this.populateKnockOutErrorForStore(resp.respData, flow) });
                }
                delete resp.respData;
                break;
            }
            case FlowIndentifierEnum.SAVE_DISCOUNT: {
                resp.respErr = this.populateKnockOutErrorForStore(resp.respData, flow);
                delete resp.respData.emailAddress;
                delete resp.respData.landingStateCode;
                delete resp.respData.saveMode;
                delete resp.respData.lastVisitedPage;
                if (resp.knockOutResponse && resp.knockOutResponse.errorCode) {
                    delete resp.respData.knockOutResponse;
                    resp.respData.knockOutInd = true;
                }
                break;
            }
            case FlowIndentifierEnum.SAVE_QUOTE: {
                if (!resp.respData.knockOutInd) {
                    const quoteResponse = this.filterSaveQuoteResponse(resp.respData.persInsQuoteData.persAutoInsQuote);
                    resp = Object.assign({}, resp, { respData: quoteResponse });
                }
                break;
            }
            case FlowIndentifierEnum.RNETERS_RATE: {
                if (resp.respData.errorMessage || resp.respData.status === 'failure') {
                    resp.error = { service: 'rentersRate', errorMessage: resp.errorMessage };
                    resp.respData = null;
                    resp.serviceCtrlData = { rentersRate: 'fail' };
                } else {
                    resp.respData = { rentersMonthlyPremiumAmt: resp.respData.rentersMonthlyPremiumAmt};
                }
                break;
            }
            default: {
                break;
            }
        }
        return resp;
    }

    copyCustomerAndAgentData(response) {

        if (response.agent) {
            response.agentData = {};
            response.agentData.agent = response.agent;
        }

        if (response.customer) {
            response.customerData = {};
            response.customerData.customer = response.customer;
            if (response.customer.communication) {
                response.customerData = this.updateCommunicationDetails(response.customerData);
            }
        }
        return response;
    }

    /**
     * Updates communication details on success
     * @param customerData customerData
     * @returns customerData
     */
    updateCommunicationDetails(customerData: any) {
        if (customerData.customer && customerData.customer.communication) {
            if (customerData.customer.communication.phoneNumber && customerData.customer.communication.phoneNumber.areaCode &&
                customerData.customer.communication.phoneNumber.phoneNumber) {
                const phoneNumber = '(' + customerData.customer.communication.phoneNumber.areaCode + ')'
                    + (customerData.customer.communication.phoneNumber.phoneNumber).substring(0, 3)
                    + '-' + (customerData.customer.communication.phoneNumber.phoneNumber).substring(3, 7);
                customerData.customer.communication.phoneNumber = phoneNumber;
            } else {
                // Setting to null to make sure only null check is done where phone numbers doesnt come in cross sell/retrieve
                customerData.customer.communication.phoneNumber = null;
            }
            if (customerData.customer.communication.emailId) {
                customerData.customer.communication.emailAddress = customerData.customer.communication.emailId.emailAddress;
                delete customerData.customer.communication.emailId;
            }
        }
        return customerData;
    }
    /**
     * Filters quote response
     * @param resp resp
     */
    filterQuoteResponse(resp) {
        this.filterVehiclesFromQuote(resp.persAutoPolicy.vehicle);
        const response: Quote = {} as Quote;
        response.vehicle = resp.persAutoPolicy.vehicle;
        response.discount = resp.persAutoPolicy.discount;
        response.liabilityCoverage = resp.persAutoPolicy.liabilityCoverage;
        response.knockOutInd = resp.knockOutInd;
        response.brightlinedIndicator = resp.brightlinedIndicator;
        response.buyEnableInd = resp.buyEnableInd;
        response.knockOutBeanList = resp.knockOutBeanList;
        response.infoMessages = resp.infoMessages;
        response.dnqMessages = resp.dnqMessages;
        response.payPlanChanged = resp.payPlanChanged;
        response.userType = resp.userType;
        response.policyFee = resp.policyFee;
        response.flHouseAccount = resp.flHouseAccount;
        response.isHouseAccount = resp.isHouseAccount;
        response.eligibleForChat = resp.eligibleForChat;
        response.eligibleForAutoRentersBundle = resp.eligibleForAutoRentersBundle;
        response.validCityZipForMN = resp.validCityZipForMN ;
        response.coverageAdjustedIndicator = resp.coverageAdjustedIndicator ;
        response.umbrellaPolicyIndicator = resp.umbrellaPolicyIndicator ;
        response.isHomeOwnerDiscount =  this.checkHomeOwnerShipEligble(resp.persAutoPolicy.discount);
        return response;
    }

    // Us223622: Homeownership discount check
    checkHomeOwnerShipEligble(discount: PrequalifiedDiscounts[]): boolean {
     let isHomeOwner = false;
     if (discount !== null && discount !== undefined) {
        isHomeOwner = discount.find(dis => dis.name === 'Homeowner') ? true : false;
    }
     return isHomeOwner;
    }

    /**
     * Filters vehicles from quote
     * @param vehicles vehicles
     */
    filterVehiclesFromQuote(vehicles) {
        const requiredKeyNames = ['reference', 'year', 'makeDesc', 'modelDesc', 'persAutoCoverage',
        'physicalDamageIndicator', 'surchargeBI', 'surchargePD', 'maskedVin'];
        vehicles.forEach(vehicleObj => {
            Object.keys(vehicleObj).forEach(keyName => {
                if (requiredKeyNames.indexOf(keyName) < 0) {
                    delete vehicleObj[keyName];
                }
            });
        });
    }

    /**
     * Populates knock out error for store
     */
    populateKnockOutErrorForStore(resp, servicename) {
        const result = { knockOutCode: '', knockOutMsg: '', detailedKnockOutCode: '', detailedKnockoutMsg: '', service: '' };
        switch (servicename) {
            case FlowIndentifierEnum.SAVE_DISCOUNT:
                if (resp && resp.knockOutResponse && resp.knockOutResponse.knockOut) {
                    result.knockOutCode = resp.knockOutResponse.errorCode;
                    result.knockOutMsg = resp.knockOutResponse.knockOutMsg;
                    result.service = 'KnockOutFromDiscount';
                }
                break;
            case FlowIndentifierEnum.RATE_QUOTE:
                if (resp.knockOutInd === true) {
                    result.knockOutCode = resp.knockOutBeanList[0].knockOutCode;
                    result.knockOutMsg = resp.knockOutBeanList[0].knockOutDesc;
                    if (resp.knockOutBeanList[0].detailedKnockOutCode) {
                        result.detailedKnockOutCode = resp.knockOutBeanList[0].detailedKnockOutCode;
                    }
                    if (resp.knockOutBeanList[0].detailedKnockOutDesc) {
                        result.detailedKnockoutMsg = resp.knockOutBeanList[0].detailedKnockOutDesc;
                    }
                    result.service = 'KnockOut';
                    delete resp.knockOutBeanList[0];
                }
                break;
            case FlowIndentifierEnum.DRIVER_ASSIGNMENT:
            case FlowIndentifierEnum.BW_AGENT_ASSIGNMENT:
                if (resp && resp.knockOutResponseBean && resp.knockOutResponseBean.errorCode) {
                    result.knockOutCode = resp.knockOutResponseBean.errorCode;
                    result.knockOutMsg = resp.knockOutResponseBean.knockOutMsg;
                    result.service = 'KnockOut';
                    delete result.detailedKnockOutCode;
                    delete result.detailedKnockoutMsg;
                }
                break;
        }
        return result;
    }
    /**
     * Filters save quote response
     * @param resp resp
     */
    filterSaveQuoteResponse(resp) {
        this.filterVehiclesFromQuote(resp.persAutoPolicy[0].vehicle);
        const response: Quote = {} as Quote;
        response.vehicle = resp.persAutoPolicy[0].vehicle;
        response.discount = resp.persAutoPolicy[0].discount;
        response.liabilityCoverage = resp.persAutoPolicy[0].liabilityCoverage;
        response.knockOutInd = resp.knockOutInd;
        response.brightlinedIndicator = resp.brightlinedIndicator;
        response.buyEnableInd = resp.buyEnableInd;
        response.knockOutBeanList = resp.knockOutBeanList;
        response.infoMessages = resp.infoMessages;
        response.payPlanChanged = resp.payPlanChanged;
        response.userType = resp.userType;
        response.flHouseAccount = resp.flHouseAccount;
        response.isHouseAccount = resp.isHouseAccount;
        response.eligibleForChat = resp.eligibleForChat;
        response.eligibleForAutoRentersBundle = resp.eligibleForAutoRentersBundle;
        return response;
    }
}
