import { Injectable } from '@angular/core';
import { Subscription, Observable, timer, Subject } from 'rxjs';
import { DataConstants } from '@ffq-app-shared';
import { SegmentService } from './segment.service';

const sessionTimeout = DataConstants.SESSION_TIMEOUT;
const sessionTimeoutPopup = DataConstants.SESSION_TIMEOUT_POPUP;

@Injectable({
  providedIn: 'root'
})
export class SessionTimeoutService {

  private enableTimer = false;
  private timeoutMilli = sessionTimeout.sessionTimeout;
  private popupTimeoutMilli = sessionTimeoutPopup.sessionTimeoutPopup; // 20 * 1000  ; // 10800; // 1680000 = 28 mins

  private counter = 0;
  private timerSubscription: Subscription;
  private popupTimerSubscription: Subscription;
  private timer: Observable<number>;
  private popupTimer: Observable<number>;
  public timeoutExpired: Subject<number> = new Subject<number>();
  public popupTimeoutExpired: Subject<number> = new Subject<number>();
  constructor(
    private segmentService: SegmentService
  ) { }

  public startTimer() {
    if (this.timerSubscription) {
      this.stopTimer();
    }
    this.setSubscription();
    this.enableTimer = true;
  }


  public startPopupTimer() {

    if (this.popupTimerSubscription) {
      this.stopPopupTimer();
    }
    this.setPopupSubscription();
  }



  public stopTimer() {
    this.enableTimer = false;
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  public stopPopupTimer() {
    if (this.popupTimerSubscription) {
      this.popupTimerSubscription.unsubscribe();
    }
  }

  private setSubscription() {
    this.timer = timer(this.timeoutMilli);
    this.timerSubscription = this.timer.subscribe(n => {
      this.timerComplete(n);
    });
  }


  private setPopupSubscription() {
    this.popupTimer = timer(this.popupTimeoutMilli);
    this.popupTimerSubscription = this.popupTimer.subscribe(n => {
      this.popupTimerComplete(n);
    });
  }

  private timerComplete(n: number) {
    this.timeoutExpired.next(++this.counter);
    this.timerSubscription.unsubscribe();
  }


  private popupTimerComplete(n: number) {
    this.segmentService.trackSessionTimedOut();
    setTimeout(() => {
      this.popupTimeoutExpired.next(++this.counter);
      this.popupTimerSubscription.unsubscribe();
    }, 1000);
  }

  public resetTimer() {

    if (this.enableTimer) {
      this.startTimer();
    }
  }

}
