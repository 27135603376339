import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InfoDialogService {
  private subject = new Subject<any>();

  constructor() { }

  /**
   * Sets dialog status
   * @param dialog state
   */
  setDialogStatus(dialogTitle: string, content: string) {
    this.subject.next({ status: true, title: dialogTitle, description: content});
  }

  /**
   * Clear dialog status
   * @param dialog state
   */
  clearDialogStatus() {
    this.subject.next({status: false});
  }

  /**
   * Gets dialog status
   * @returns dialog status
   */
  getDialogStatus(): Observable<any> {
    return this.subject.asObservable();
  }
}
