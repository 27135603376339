<div id="knock-out-page-container">
    <div class="knockout-content">
        <div class="knock-out-header_ffq-logo" title="Farmers &reg;" aria-label="Graphical Farmers Insurance Logo"></div>
        <div class="knock-out_header-title">
            <h1>Your quote requires special attention</h1>
        </div>
        <div *ngIf="!isMonetizationRequired">
            <div class="knock-out_body_knock_message">{{knockoutMessage}}</div>
            <div class="knock-out_body_agent_message">{{contactAgentMessage}}<span
                class="knock-out_body_quote_number">{{quoteNumber}}</span></div>
        </div>

        <div *ngIf="isMonetizationRequired">
            <div class="knock-out_body_knock_message">
                {{knockoutMonetizationMessage[0]}}<a class="knock-out_phone" href="{{tele}}" tabindex="0"
                    attr.aria-label="{{desktopNo}}" role="link">{{desktopNo}}</a>{{knockoutMonetizationMessage[1]}}<span
                    class="knock-out_body_quote_number">{{quoteNumber}}</span>
            </div>
            <div class="knock-out_body_knock_message">
                <p>{{monetizationMsg1}}</p>
                <p>{{monetizationMsg2}}</p>
            </div>
            <div class="monetization-holder">
                <div class="local-insurance-provier-list clearfix iid-knockout-content-section" id="Content">
                </div>
            </div>
        </div>
        <div [ngClass]="isMonetizationRequired ? 'knock-out_monetization':''" *ngIf=!agentAssigned>
            <div id="knock-out_body_noagent"> Need more help?</div>
            <div *ngIf="!isEERideShare && !restictAgentDetailsImpl" id="knock-out_body_call-custome">You can call customer service
                at</div>
            <div *ngIf=isEERideShare id="knock-out_body_call-custome">You can call a Farmers representative at</div>
            <span class="knock-out_desktop" *ngIf=!restictAgentDetailsImpl>
                <div class="knock-out_body_farmers" x-ms-format-detection = "none">
                    <a href={{tele}} id ="default-phone" tabindex="0" attr.aria-label=
                    "{{'Call to action, contact farmers representative ' + desktopNo}}"  role="link">{{desktopNo}}</a>
                </div>
            </span>
            <span [attr.aria-label]="ariaLabelMobile"  class="knock-out_mobile" *ngIf=!restictAgentDetailsImpl>
                <div class="knock-out_body_farmers" x-ms-format-detection = "none">
                    <a href={{teleMobile}} id ="default-phone" tabindex="0" role="link">{{mobileNo}}</a>
                </div>
            </span>
        </div>
        <div *ngIf=agentAssigned>
            <div class="knock-out_agent-detail-row">
            <ng-container *ngIf="agentDetails?.agentImageUrl ; else default" >
                <div class="knock-out_agent-detail-icon" tabindex="-1">
                    <img class="knock-out_agent-image" src="{{agentDetails.agentImageUrl}}" tabindex="0" alt="" onerror="this.src='../../../../../assets/images/agent-default-img.png';" />
                </div>
            </ng-container>
            <ng-template #default>
                <div class="knock-out_agent-detail-icon" tabindex="-1">
                    <img class="knock-out_agent-image" src="../../../../../assets/images/agent-default-img.png" tabindex="0"
                        alt="" />
                </div>
            </ng-template>
            <div *ngIf="agentDetails?.firstName">
                    <div class="knock-out_agent-detail-name" tabindex="0">{{agentName}}</div>
                    <div class="knock-out_agent-detail-text" tabindex="0">Your local Farmers Insurance® Agent is
                        <br />available if you have any questions. </div>
            </div>
        </div>

            <div *ngIf="agentDetails?.communication?.emailId?.emailAddress" class="knock-out_agent-detail-row">
                <a href="mailto:{{agentDetails.communication.emailId.emailAddress}}" class="agent-email-link"><div class="knock-out_agent-detail-icon" tabindex="-1">
                    <img src="../../../../../assets/images/email.png" tabindex="0"
                        alt="Please select link to send an email to your farmers agent" />
                </div>
                    <div class="knock-out_agent-detail-email" tabindex="0">{{agentDetails.communication.emailId.emailAddress}}
                </div>
            </a>
            </div>


            <div *ngIf="agentDetails?.communication?.phoneNumber?.phoneNumber && agentPhoneNumber; else noAgentPhoneNumberDesktop"
                class="knock-out_agent-detail-row knock-out_desktop">
                <a href="tel:{{agentPhoneNumberCC}}"  attr.aria-label=
                "{{'Call to action, contact farmers representative '}} + {{agentPhoneNumber}} " role="link" class="agent_phone">
                    <div class="knock-out_agent-detail-icon" tabindex="-1">
                        <img src="../../../../../assets/images/local-phone.png" tabindex="0"
                            alt="" />
                    </div>
                    <div class="knock-out_agent-detail-phone" tabindex="0">{{agentPhoneNumber}}</div>
                </a>           
            </div>

            <ng-template #noAgentPhoneNumberDesktop>
                <a href={{tele}} role="link"  attr.aria-label=
                "{{'Call to action, contact farmers representative ' + desktopNo}}" class= "knock-out_agent-detail-row knock-out_desktop agent_phone">
                  <div class="knock-out_agent-detail-icon" tabindex="-1">
                        <img src="../../../../../assets/images/local-phone.png" tabindex="0"
                            alt="" />
                    </div>
                    <div class="knock-out_agent-detail-phone" tabindex="0">{{desktopNo}}</div>
                </a>
            </ng-template>

            <div *ngIf="agentDetails?.communication?.phoneNumber?.phoneNumber && agentPhoneNumber; else noAgentPhoneNumberMobile"
                class="knock-out_agent-detail-row knock-out_mobile">
                <a href="tel:{{agentPhoneNumberCC}}" role="link" class="agent_phone">
                    <div class="knock-out_agent-detail-icon">
                        <img src="../../../../../assets/images/local-phone.png" alt="" />
                    </div>
                    <div class="knock-out_agent-detail-phone">                       
                        {{agentPhoneNumber}}
                    </div>
                </a>
            </div>
            <ng-template #noAgentPhoneNumberMobile>
                <a href={{teleMobile}} role="link" class="knock-out_agent-detail-row knock-out_mobile agent_phone">
                    <div class="knock-out_agent-detail-icon">
                        <img src="../../../../../assets/images/local-phone.png" alt="" />
                    </div>
                    <div class="knock-out_agent-detail-phone">                       
                        {{mobileNo}}
                    </div>
                </a>
            </ng-template>

            <div *ngIf="agentDetails?.address?.city" class="knock-out_agent-detail-row">
                <a [href]=googleMaplocation target="_blank" class="agent-adress-link"> <div class="knock-out_agent-detail-icon">
                    <img src="../../../../../assets/images/room.png" tabindex="0"
                        alt="Please select link to access the address of Your farmer's agent" />
                </div>
                    <span tabindex="0">
                    <div class="knock-out_agent-detail-address-1">{{agentDetails.address.street}}
                    </div>
                    <div class="knock-out_agent-detail-address">{{agentLocation}}
                    </div>
                    </span></a>
            </div>
            <div *ngIf="isStartQuoteKnockoutAWSFlow" class="knock-out_agent-detail-row schedule-appointment-click" data-GTM="FFQ_Auto_Agent_Appt__popup_button" tabindex="0" (keyup.enter) = "openApptModal()" (click)="openApptModal()">
                <div class="knock-out_agent-detail-icon">
                    <img class="schedule-appointment-img" src="assets/images/schedule-agent-icon.png" alt="" tabindex="0" />
                    <div class="knock-out_agent-detail-address schedule-appointment-content" role="link" data-GTM="FFQ_Auto_Agent_Appt__popup_button">Schedule appointment</div>

                </div>

            </div>

        </div>
        <div *ngIf="!agentAssigned && !isEEImpl && !isStartQuoteKnockoutAWSFlow" class="knock-out-finish-buttons">
            <mat-dialog-actions class="knock-out-dialog-button-holder" align="center">
                <button mat-button class="knock-out-button-close" data-GTM="FFQ_Auto_splattention_letsfindagent_button"
                (keyup.enter)="findAnAgent()" (click)="findAnAgent()" role = "presentation" tabindex= "0" aria-label="Let's find an agent button link">LET'S FIND AN AGENT</button>
            </mat-dialog-actions>
        </div>
    </div>
</div>