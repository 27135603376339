import { Component, OnInit } from '@angular/core';
import { AutoStoreService } from '@ffq-app-auto/store/auto.store.service';
import { AppStoreServices } from '@ffq-app-store';
import { DataService } from './../../../../core';
import { Subscription } from 'rxjs/internal/Subscription';
import { ModuleProgressStatus } from '@ffq-app-shared/enums/flow.identifier.enum';
import { PrequalifiedDiscounts } from '@ffq-app-shared/model/common.data.model';
import { NavigationDrawerService } from '@ffq-app-shared/components/navigation-drawer/navigation-drawer.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { InfoDialogComponent, InfoDialogContentComponent } from '@ffq-lib/src/lib/dialog/info-dialog/info-dialog.component';
import { DataConstants } from '@ffq-app-shared/constants/data.constants';
import { take } from 'rxjs/internal/operators/take';
import { StateRuleModelService } from '@ffq-app-shared/services/state-rule-model.service';

@Component({
  selector: 'app-discount-journey',
  templateUrl: './discount-journey.component.html',
  styleUrls: ['./discount-journey.component.scss']
})
export class DiscountJourneyComponent implements OnInit {
  /**
   * Determines whether bwquote is
   */
  isBWQuote = false;
  /**
   * Subscriptions  of discount journey component
   */
  private subscriptions: Subscription[] = [];
  /**
   * Determines whether djmauto impl is
   */
  isDJMAutoImpl = false;
  hideDiscountJourneyBlock = false;
  adpfVehiclesList = [];
  adpfDriversList = [];
  headingSubText: string;
  additionalInfoHeadingText: string;
  discountArr = [];
  discountCounter = 0;
  infoDialogRef: MatDialogRef<InfoDialogContentComponent>;
  infoDialogComponent = new InfoDialogComponent(this.potentialDialog);
  currentStep: number;
  windowUrl = window.location.href;
  popupText: string;
  prequalifiedDiscountsList: PrequalifiedDiscounts[];
  discountCounterText: string;
  landingStateCode: string;
  headingSubText1: string;
  headingSubText2: string;
  isFlexImpl = false;

  /**
   * Creates an instance of discount journey component.
   * @param autoStoreService AutoSToreService
   * @param appStoreService AppStoreService
   * @param dataService DataService
   */
  constructor(private autoStoreService: AutoStoreService,
              private appStoreService: AppStoreServices.AppStoreService,
              private navigationDrawerService: NavigationDrawerService,
              private stateRuleModelService: StateRuleModelService,
              private dataService: DataService,
              public potentialDialog: MatDialog) {
                navigationDrawerService.stepStatusChangeEvent.subscribe(
                  (newStepNumber) => {
                    this.setHeaderSubText(newStepNumber);
                  }
                );
              }

  ngOnInit(): void {

    this.autoStoreService.getBrightLinedData$.subscribe((data: any) => {
      this.isBWQuote = data && data.autoCompanyCode && data.autoCompanyCode === 'B';
    });

    // US2267731: subscription for landing state code
    this.subscriptions.push(this.appStoreService.getLandingStateCode$.pipe(take(1)).subscribe((data: any) => {
      this.landingStateCode = data;
    }));

    /** Subscription for projectstatus code */
    this.subscriptions.push(this.appStoreService.getProjectCodeStatus$.subscribe((projectCodeStatus: any) => {
      this.isDJMAutoImpl = projectCodeStatus[`isDJMAutoImpl`];
      this.isFlexImpl = projectCodeStatus[`isFlexImpl`];
     }));

    this.subscriptions.push(this.autoStoreService.getPrefillVehicleData$.subscribe(vehicleList => {
      this.adpfVehiclesList = vehicleList;
    }));

    /**  Subcription to retrieve the prequalified discounts */
    this.subscriptions.push(this.autoStoreService.getPrequalifiedDiscounts$.subscribe((prequalifiedDiscounts: PrequalifiedDiscounts[]) => {
      // DE56549: Changes for DJM retrival counter issue
      if (prequalifiedDiscounts && prequalifiedDiscounts != null) {
        this.prequalifiedDiscountsList = prequalifiedDiscounts;
        this.updateDiscountArray();
      }
    }));

    /**  Subcription to get the ADPF Drivers list */
    this.subscriptions.push(this.autoStoreService.prefillDriversData$.subscribe(driverData => {
      this.adpfDriversList = driverData;
    }));

    this.discountCounter = this.discountArr.length;
    this.popupListText(this.discountArr);

    /** US459401 - Update headers and sub-headers refering from AEM */
    this.setStaticContent();
  }

  popupListText(discountArr: string[]): void {
    this.popupText = '<ul>';
    discountArr.forEach(val => {
      this.popupText = this.popupText + '<li>' + val + '</li>';
    });
    this.popupText = this.popupText + '</ul>';
  }

  /**
   * Updates discount array
   */
  updateDiscountArray(): void {
    // US267331: Changes for the EFT discount
    const discountArr = [];
    /** US276769: Remove EFT Discount from the list if we get Paid-In-Full in Rate Response */
    const paidInFullDiscountExists = this.prequalifiedDiscountsList.filter(discount =>
      discount.name === DataConstants.DJM_DISCOUNT_NAME.paidInFull).length > 0;
    if (!this.stateRuleModelService.isStateAvailable('eftEligbleState', this.landingStateCode)
        && !paidInFullDiscountExists && !this.isFlexImpl) {
      discountArr.push(DataConstants.DJM_DISCOUNT_NAME.electronicFundTransfer);
    }
    this.prequalifiedDiscountsList.forEach(discount => {
      if (!discountArr.includes(discount.name)) {
        discountArr.push(discount.name);
      }
    });
    this.discountArr = discountArr;
    this.discountCounter = this.discountArr.length;
    this.popupListText(this.discountArr);
  }

  setHeaderSubText(newStepNumber: number): void {
    this.currentStep = newStepNumber;
    const additionalDetailsPage = this.windowUrl.includes('/auto/drivers/additionaldetails');
    if (newStepNumber === 1) {
      this.headingSubText = DataConstants.DISCOUNT_JOURNEY_HEADER.yourInfoDiscountHeader;
    } else if (newStepNumber === 2 && this.adpfVehiclesList.length > 0) {
      this.headingSubText = DataConstants.DISCOUNT_JOURNEY_HEADER.addVehicleAdpfHeader;
      this.discountCounterText = DataConstants.DISCOUNT_JOURNEY_HEADER.potentialDiscountText;
    } else if (newStepNumber === 2 && this.adpfVehiclesList.length === 0) {
      this.headingSubText = DataConstants.DISCOUNT_JOURNEY_HEADER.addVehicleHeader;
      this.discountCounterText = DataConstants.DISCOUNT_JOURNEY_HEADER.potentialDiscountText;
    } else if (newStepNumber === 3 && this.adpfDriversList.length > 0
      && !additionalDetailsPage) {
      this.headingSubText = DataConstants.DISCOUNT_JOURNEY_HEADER.addDriverAdpfHeader;
      this.discountCounterText = DataConstants.DISCOUNT_JOURNEY_HEADER.potentialDiscountText;
    } else if ((newStepNumber === 3 && this.adpfDriversList.length === 0)
      || newStepNumber === 4) {
      this.discountCounterText = DataConstants.DISCOUNT_JOURNEY_HEADER.potentialDiscountText;
    } else if (newStepNumber === 5) {
      this.discountCounterText = DataConstants.DISCOUNT_JOURNEY_HEADER.potentialDiscountTextQuote;
    } else if (additionalDetailsPage) {
      this.discountCounterText = DataConstants.DISCOUNT_JOURNEY_HEADER.potentialDiscountText;
    }
    this.additionalInfoHeadingText = newStepNumber === 3 && additionalDetailsPage
      ? DataConstants.DISCOUNT_JOURNEY_HEADER.additionalInfoHeader : '';
    this.hideDiscountJourneyBlock = (this.currentStep === 3 && this.adpfDriversList.length === 0)
      || this.currentStep === 4 || this.currentStep === 5 || additionalDetailsPage;
   }

  // US246131: Changes for the potential discount popup for discount journey mapping
  showPotentialDiscounts(): MatDialogRef<InfoDialogContentComponent> {
    if (this.currentStep !== 5 && this.popupText) {
    return this.potentialDialog.open(InfoDialogContentComponent,
      {
        panelClass: 'ffq-info-dialog-potential',
        data: {
          dialogTitle: 'Potential Savings/discounts ' + '(' + this.discountCounter + ')',
          dialogContent: this.popupText,
          buttonName: 'Close', buttonGTMTag: ''
        },
        disableClose: true,
        autoFocus: false,
        width: '511px'
      });
    }
  }

  // US246131: FFQ Auto Discount Journey Mapping- Update on the quote page(Farmers only)- Change for scrolling to Applied Discounts
  viewDiscounts(): void {
    setTimeout(() => {
      const element = document.getElementById('auto__quote-your-discounts');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    });
  }

  /**
   * Gets whether is large screen
   */
  get isLargeScreen() {
    if (
      /Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      return false;
    } else {
      return true;
    }
  }
  /**
   * set static content
   */
  setStaticContent(): void {
    const yourInforContent = this.appStoreService.findContent('yourInfo');
    if (yourInforContent?.summary) {
      this.headingSubText1 = this.dataService.getStaticString(yourInforContent.summary, 'headerTitles', 'YOURINFO_HEADINGSUBTEXT1');
      this.headingSubText2 = this.dataService.getStaticString(yourInforContent.summary, 'headerTitles', 'YOURINFO_HEADINGSUBTEXT2');
      }
  }

}
