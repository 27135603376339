export const DateFormats = {
parse: {
  dateInput: 'input',
  },
  display: {
  dateInput: 'MM/DD/YYYY',
  monthYearLabel: 'MMMM YYYY',
  dateA11yLabel: 'MM/DD/YYYY',
  monthYearA11yLabel: 'MMMM YYYY',
  }
};
