<div *ngIf="params.dummyCard" class="ffq-lib__title">{{params.title}}</div>
<div
    [ngClass]="{ 'ffq-lib__driver-card' : (!showAddSpouseCard), 'card-selected': (params.status ==='C'), 'card-disabled' : (params.status ==='D' || params.isMaxAllowed)}">
    <div *ngIf="!showAddSpouseCard" role="button" id="ffq-lib__driver-card_action_label_{{params.status}}" tabindex="0"
        #addDeleteLink class="ffq-lib__driver-card-input-check-box"
        (click)="manageQuote( addOrDeleteLabel , params.reference, params.pni, params.fullName )"
        attr.Data-GTM="{{gtmTag}}"
        [attr.aria-label]="(params.status ==='C')? params.fullName +' selected, click to delete':
         (params.status ==='D' || params.isMaxAllowed)? params.fullName +' disabled box' : params.fullName +' opens a modal dialog box'">
        <div [ngClass]="(params.status ==='C')?
            ((params.pni === 'H' || params.isCSspouse) ? 'ffq-lib__margin-left': 'selected check-box-icon'):
            'card-unselected check-box-icon-unchecked'">
        </div>
        <h3 aria-hidden="true" class="ffq-lib__driver-card_name"
            [ngClass]="(params.status ==='C')? ' ffq-lib__driver-card-selected-font ':' '">{{ params.fullName }}</h3>
    </div>
   
    <h4 *ngIf="!showAddSpouseCard && params.age"
    [ngClass]="((params.pni === 'H' || params.isCSspouse) && params.status ==='C') ? 'ffq-lib__driver-card_age-margin' : 'ffq-lib__driver-card_age'"
    role="presentation" (click)="manageQuote( addOrDeleteLabel , params.reference, params.pni, params.fullName )">{{ params.age }}</h4>
    
    <div class="ffq-lib__driver-card-button-container cursor-pointer" *ngIf="params.status ==='C'" (click)="manageQuote( params.primaryActionLabel , params.reference , params.pni, params.fullName)">
        <hr />
        <div class="ffq-lib__driver-card-button" 
        (keydown.enter)="manageQuote( params.primaryActionLabel , params.reference , params.pni, params.fullName)">
            <a id="ffq-lib__driver-card_action_label_{{params.status}}" #editLink tabindex="0" role="button"
                [attr.aria-label]="params.fullName +' Edit details opens a modal dialog box'"
                class="edit-icon left-icon" attr.Data-GTM="{{params.primaryGtmTag}}">
                <span class="ffq-lib__driver-card__Edit">{{ params.primaryActionLabel }}</span>
            </a>
        </div>
    </div>
</div>

<div *ngIf="showAddSpouseCard" class="ffq-lib__driver-dummy-card ffq-lib__cursor " (click)="manageQuote( addOrDeleteLabel , params.reference, params.pni, params.fullName )"  (keydown.enter)="manageQuote( addOrDeleteLabel , params.reference , params.pni, params.fullName)">
    <a id="ffq-lib__driver-card_action_label_{{params.status}}" #spouseDummyLink tabindex="0" role="button"
        [attr.aria-label]="params.fullName +' opens a modal dialog box'"
        [ngClass]="(params.status ==='D') ? 'ffq-lib__plus_icon-disabled left-add-icon': 'ffq-lib__plus_icon left-add-icon'"
        attr.Data-GTM="{{params.spouseGtmTag}}">
        <span class="ffq-lib__driver-card-plus">{{ params.primaryLabel }}</span>
    </a>
</div>
<div *ngIf="showAddSpouseCard" id="ffq-lib__driver-card_action_label_{{params.status}}" tabindex="0"
    class="ffq-lib__link" role="button" #notMarriedLink [attr.aria-label]="params.isMarriedLabel +' opens a modal dialog box'"
    (click)="manageQuote( params.removeSpouse , params.reference, params.pni, params.fullName )"
    attr.Data-GTM="{{params.gtmTag}}">{{params.isMarriedLabel}}</div>