import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { ServiceConstants, ServicesForAutoInterceptor, ServicesForAutoInterceptorVD } from '@ffq-app-shared/constants';
import { RateQuoteProgressStatus } from '@ffq-app-shared/enums/flow.identifier.enum';
import { AppStoreService } from '@ffq-app-store/app.store.service';


/**
 * Used to update the app control data in order to manage perform rate call in quote page
 */
@Injectable({
    providedIn: 'root'
  })
export class AutoHttpInterceptorService implements HttpInterceptor {

    /**
     * Creates an instance of auto http interceptor service.
     * @param appStoreService AppStoreService
     */
    constructor(private appStoreService: AppStoreService) { }

    /**
     * Intercepts auto http interceptor service
     * @param request HttpRequest
     * @param next HttpHandler
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        /** Setting application/json */
        const urlTemplate = request.url.replace(ServiceConstants.BASE_URL, '');
        const nextHandle = next.handle(request);
        let isConnectedCarImpl: boolean;
        return nextHandle.pipe(
            finalize(() => {
                if (ServicesForAutoInterceptor.includes(urlTemplate)) {
                    this.appStoreService.updateAppControlData({ defaultRateQuoteCalled: RateQuoteProgressStatus.NOT_CALLED,
                        requiredCvgUpdation: this.hasUpdatedVDData(request) });

                }
                if (ServicesForAutoInterceptorVD.includes(urlTemplate)) {
                    this.appStoreService.getProjectCodeStatus$.subscribe(data => {
                        isConnectedCarImpl = data[`isConnectedCarImpl`];
                      }).unsubscribe();
                    if (isConnectedCarImpl) {
                        /** US418751: Invoke Connected Car when Modifying Drivers or Vehicles */
                        this.appStoreService.vehicleOrDriverChanged(true);
                    }
                    if (request.body.saveMode === 'add' || request.body.saveMode === 'delete') {
                        this.appStoreService.updateAppControlData({ PrefilldriverAssignment:  true });
                    }
                }
            }));
    }

    /**
     * Determines whether updated communication data has
     * @param request apirequest
     * @returns boolean
     */
    hasUpdatedVDData(request) {
        const urlTemplate = request.url.replace(ServiceConstants.BASE_URL, '');
        if (ServiceConstants.SAVE_PNI !== urlTemplate) {
            return true;
        }
        let customerData;
        let requiredCvgUpdation = true;
        this.appStoreService.yourInfoLoadData$.subscribe(data => {
            customerData = data;
        }).unsubscribe();
        const communicationData = customerData.customer.communication;
        const isCommunicationData = communicationData && communicationData.emailAddress;
        if (isCommunicationData &&  request.body.driverEmail.toLowerCase() === communicationData.emailAddress.toLowerCase()
            && request.body.driverPhone === communicationData.phoneNumber) {
            this.appStoreService.getCvgUpdationRequired$.subscribe(cvgData => {
                requiredCvgUpdation = cvgData;
            }).unsubscribe();
        }
        return requiredCvgUpdation;
    }

}
