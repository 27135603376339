import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'app-landing-dialog',
  templateUrl: './landing-dialog.component.html',
  styleUrls: ['./landing-dialog.component.scss']
})
export class LandingDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<LandingDialogComponent>) { }

  ngOnInit(): void {
    // Dynamically update the phone number in the popup screen
    this.invoca(window, document, 'solutions.invocacdn.com/js/invoca-latest.min.js', '2013/1325511193');
  }
  /**
   * Invoca Script
   * US348410: Dynamically update the phone number in the popup screen
   */
  invoca(i, n, v, o): void {
    i.InvocaTagId = o;
    const s = n.createElement('script'); s.type = 'text/javascript';
    s.async = true;
    s.src = ('https:' === n.location.protocol ? 'https://' : 'http://') + v;
    const fs = n.getElementsByTagName('script')[0]; fs.parentNode.insertBefore(s, fs);
  }
}
