import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ServiceConstants } from '@ffq-app-shared/constants';
import { map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ErrorService } from '@ffq-app-core/services/error.service';
import { AppStoreService } from '@ffq-app-store/app.store.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class KnockOutService {

  knockoutMsg = new BehaviorSubject<string>('');

  constructor(private http: HttpClient,
              private errorService: ErrorService,
              private router: Router) {
  }

  setKnockOutMessage(knockoutMsg: string) {
    this.knockoutMsg.next(knockoutMsg);
    this.router.navigate(['/auto/knockout']);
  }

  /**
   * save KnockOut$
   * @param data save KnockOut api req data
   * @returns KnockOut$ Observable
   */
  saveKnockOut$(data: any): Observable<any> {
    return this.http.post(ServiceConstants.BASE_URL + ServiceConstants.SEND_KNOCKOUT_DETAILS, data).pipe(
      map((resp: any) => resp),
      catchError(error => this.errorService.handleError(error))
    );
  }
}
