import { Component, OnInit, AfterViewInit } from '@angular/core';
import { NavigationDrawerService } from '@ffq-app-shared/components';
import { Subscription, combineLatest } from 'rxjs';
import { AppStoreService } from '@ffq-app-store/app.store.service';
import { ServiceConstants } from '@ffq-app-shared/constants/service.constants';
import { createUnparsedSourceFile } from 'typescript';
import { Title } from '@angular/platform-browser';
import { SpinnerService } from '@ffq-lib/src/lib/spinner/spinner.service';
import { take } from 'rxjs/operators';
import { AutoStoreService } from '@ffq-app-auto/store/auto.store.service';
import { SegmentService } from '@ffq-app-shared/services/segment.service';
import { SaveAndFinishLaterObject } from '@ffq-app-auto/drivers';

@Component({
  selector: 'app-inconvenience-error',
  templateUrl: './inconvenience.component.html',
  styleUrls: ['./inconvenience.component.scss']
})
export class InconvenienceComponent implements OnInit, AfterViewInit {
  private subscriptions: Subscription[] = [];
  quoteNumber: any;
  EEImpl = false;
  restictAgentDetailsImpl = false;
  autoCompanyCd: string;
  ariaLabelDesktop: string;
  ariaLabelMobile: string;
  tele: string;
  teleMobile: string;
  mobileNo: string;
  desktopNo: string;
  sourcePhoneNumber: string;
  /**
   * Save and finish later object of save and finish component
   */
  saveAndFinishLaterObject = {} as SaveAndFinishLaterObject;
  /**
   * Page title requirement for ADA
   */
 /** US426031: Title change for inconvenience page */
  pageTitle = 'We aren’t able to complete your online quote at this time.';
  errorDetails: any;
  errorCode: any;
  lastPageVisited: any;
  lastStepVisited: any;
  sourceIndicator: string;
  landingStateCode: string;
  displayMessageOne: string;
  displayMessageTwo: string;
  isCustomMessageAvailable = false;

  constructor(private navDrawer: NavigationDrawerService,
              private appStoreService: AppStoreService,
              private titleService: Title,
              private spinnerService: SpinnerService,
              private autoStoreService: AutoStoreService,
              private segmentService: SegmentService) {
  }

  ngOnInit() {
    this.setTitle(this.pageTitle);
    this.navDrawer.hideDrawer();
    this.navDrawer.hideEverything();
    this.subscriptions.push(this.appStoreService.appData$.subscribe(data => {
      this.quoteNumber = data ? data.quoteNumber : null;
    }));

    this.subscriptions.push(this.appStoreService.getLandingSourceFromSrc$.subscribe(data => {
      this.sourceIndicator = data;
    }));

    this.subscriptions.push(
      combineLatest([
        this.appStoreService.appError$,
        this.autoStoreService.getAutoErrors$,
      ]).subscribe(([appError, autoError]) => {
        const errorObject = appError && Object.keys(appError).length !== 0 ? appError : autoError;
        if (errorObject && Object.keys(errorObject).length !== 0) {
          this.errorCode = errorObject.errorInfo.errorCode;
          this.errorDetails = errorObject.errorInfo.errorMessage;
          if (errorObject?.errorInfo?.displayMessageOne) {
            this.isCustomMessageAvailable = true;
            this.displayMessageOne = errorObject.errorInfo.displayMessageOne;
            this.displayMessageTwo = errorObject.errorInfo.displayMessageTwo;
          }
        }
      })
    );


    this.appStoreService.getLastVisitedStep$.subscribe(data => {
      this.lastStepVisited = data;
    }).unsubscribe();
    let autoCompanyCode = '';
    this.subscriptions.push(this.autoStoreService.getBrightLinedData$.subscribe((data: any) => {
      autoCompanyCode = data && data.autoCompanyCode ? data.autoCompanyCode : 'F';
    }));
    this.autoCompanyCd = autoCompanyCode;
    this.subscriptions.push(this.appStoreService.appDataZip$.pipe(take(1)).subscribe((data: any) => {
      this.landingStateCode = data[`landingStateCode`];
    }));

    if (this.quoteNumber) {
      // Save the quote to DB2 when evenr there is an invonvenience
      this.saveAndFinishLaterObject = this.navDrawer.createRequestPayload(null);
      this.appStoreService.saveAndFinishLater(this.saveAndFinishLaterObject);
    }

    // segment tracking for failed quote
    const failureInfo = {
      error_code : this.errorCode,
      error_msg : this.errorDetails,
      point_of_error : this.lastStepVisited,
      company_code : autoCompanyCode
    };
    this.segmentService.trackQuoteFailed(failureInfo);
    /** Subscription for projectstatus code */
    this.appStoreService.getProjectCodeStatus$.subscribe((projectCodeStatus: any) => {
        this.EEImpl = projectCodeStatus ? projectCodeStatus[`isEEImpl`] : null;
        /** US471771: Remove agent phone number and references from inconvenience and knockout screen */
        this.restictAgentDetailsImpl = projectCodeStatus ? projectCodeStatus[`removeAgentDetailsImpl`] : false;
        this.appStoreService.getPhoneNumberFromSrc$.subscribe((phoneNumber: any) => {
          this.sourcePhoneNumber = phoneNumber;
          if (this.sourcePhoneNumber) {
            this.tele = this.teleMobile = this.EEImpl ? 'tel:' + this.sourcePhoneNumber : 'tel:' + this.sourcePhoneNumber.replace(/-/g, '');
            this.ariaLabelDesktop = this.ariaLabelMobile = this.sourcePhoneNumber + ' link';
            this.desktopNo = this.mobileNo = this.sourcePhoneNumber;
          }
        });
        if (!this.sourcePhoneNumber) {
            if (this.EEImpl) {
              this.ariaLabelDesktop = '1-888-587-2121 link';
              this.ariaLabelMobile = '1-888-587-2121 link';
              this.tele = 'tel:1-888-587-2121';
              this.teleMobile = 'tel:1-888-587-2121';
              this.desktopNo = '1-888-587-2121';
              this.mobileNo = '1-888-587-2121';
            } else {
              this.ariaLabelDesktop = '1-888-587-2121 link';
              this.ariaLabelMobile = '1-888-587-2121 link';
              this.tele = 'tel:18885872121';
              this.teleMobile = 'tel:18885872121';
              this.desktopNo = '1-888-587-2121';
              this.mobileNo = '1-888-587-2121';
            }
          }

    });
    const mcq = window.matchMedia('(orientation: landscape)');
    if (mcq.matches) {
      document.querySelector('#inconvenience-page-container').scrollIntoView();
    }
    /** US471771: Remove agent phone number and references from inconvenience and knockout screen */
    this.checkIfRestrictAgentImpl();
  }

  findAnAgent() {
    window.location.href = ServiceConstants.FIND_AN_AGENT;
  }
  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.spinnerService.hideLoader();
      this.spinnerService.hideFarmersLoader();
    });
    window.history.pushState({}, '', '/fastquote/inconvenience');
  }

  /** US471771: Remove agent phone number and references from inconvenience and knockout screen */
  checkIfRestrictAgentImpl(): void {
    this.restictAgentDetailsImpl = this.restictAgentDetailsImpl && this.autoCompanyCd !== 'B';
  }
}
