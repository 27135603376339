import { Component, OnInit, Input } from '@angular/core';
import { StateRuleModelService } from '@ffq-app-shared/services';
import { AppStoreServices } from '@ffq-app-store';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AutoStoreService } from '@ffq-app-auto/store/auto.store.service';
import { DataConstants } from '@ffq-app-shared/constants/data.constants';

@Component({
  selector: 'app-policy-perks',
  templateUrl: './policy-perks.component.html',
  styleUrls: ['./policy-perks.component.scss']
})
export class PolicyPerksComponent implements OnInit {
  /**
   * Subscriptions  of policy perks
   */
  private subscriptions: Subscription[] = [];
  /**
   * Landing state code of quote
   */
  landingStateCode: string;
  /**
   * isBWQuote of policy perks
   */
  isBWQuote = false;
  /**
   * isEEImpl of policy perks
   */
  isEEImpl = false;
  /**
   * isPolicyPerkEligible of policy perks
   */
  isPolicyPerkEligible: boolean;
  /**
   * isPolicyPerkImpl of policy perks
   */
  isPolicyPerkImpl: boolean;
  agentBuyEnabled: string;
  isAddressStandrdzd: string;
  isbuyEnableInd: string;
  quoteButtonText: string;
  contentMap = DataConstants.POLICY_PERKS_CONTENT.COMMON;
  /**
   * policyPerkLearnMoreGTM of policy perks
   */
  policyPerkLearnMoreGTM = 'FFQ_Auto_Quote_learnmore_link';

  @Input() isPrint;

  /**
   * Create an instance of signal banner popup component
   * @param stateRuleModel model
   * @param appStoreService service
   * @param autoStoreService service
   */
  constructor(
    private stateRuleModel: StateRuleModelService,
    private appStoreService: AppStoreServices.AppStoreService,
    private autoStoreService: AutoStoreService
  ) { }

  ngOnInit(): void {
    /** subscription for landing state code */
    this.subscriptions.push(this.appStoreService.appDataZip$.pipe(take(1)).subscribe((data: any) => {
      this.landingStateCode = data[`landingStateCode`];
    }));

    this.isPolicyPerkImpl = this.stateRuleModel.getQuoteModuleStateRule(this.landingStateCode).isPolicyPerksImpl;
    this.subscriptions.push(this.appStoreService.appDataAgent$.subscribe((data: any) => {
      this.agentBuyEnabled = data.agent.agent ? data.agent.agent.isBuyEnabled : data.agent.agentInfoBean.isBuyEnabled;
    }));

    this.subscriptions.push(this.appStoreService.yourInfoLoadData$.subscribe(data => {
      this.isAddressStandrdzd = data.customer.address.isStandardized;
    }));

    this.subscriptions.push(this.autoStoreService.getQuoteRateData$.subscribe((data: any) => {
      this.isbuyEnableInd = data[`buyEnableInd`];
    }));

    /** subscription for brightlined status */
    this.subscriptions.push(this.autoStoreService.getBrightLinedData$.subscribe((data: any) => {
      this.isBWQuote = (data && data.autoCompanyCode && data.autoCompanyCode === 'B');
      if (this.isAddressStandrdzd === 'Y' && this.isbuyEnableInd === 'Y') {
        this.quoteButtonText = 'BUY NOW';
      } else {
        this.quoteButtonText = 'CONTINUE';
      }
      if (this.isPolicyPerkImpl && this.quoteButtonText === 'CONTINUE' && !this.isBWQuote) {
        this.isPolicyPerkEligible = true;
      } else {
        this.isPolicyPerkEligible = false;
      }
    }));
    // US171530 - Display Policy perk banner for NC state
    if (this.landingStateCode === DataConstants.STATE_CODES.NORTH_CAROLINA) {
      this.contentMap = DataConstants.POLICY_PERKS_CONTENT.NC;
    }
  }

}
