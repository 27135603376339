import { Validators } from '@angular/forms';
export class LandingComponentValidation {
  landingZipCode = {
    validators: [
      // Validators.maxLength(5),
      Validators.pattern('^[0-9]{5}$'),
      // Validators.minLength(5)
    ],
    messages: {
      required: 'Please enter zip code.',
      // minlength: 'Zip Code is not a valid entry',
      pattern: 'Please enter a 5-digit ZIP Code.',
      // maxlength: 'Zip Code is not a valid entry'
    }
  };
  lob = {
    validators: [
      Validators.required
    ],
    messages: {
      required: 'Please choose a Quote Type.'
    }
  };
}
