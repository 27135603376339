import { Component, Input, OnInit, ViewChild, TemplateRef, ViewContainerRef, DoCheck, ChangeDetectorRef } from '@angular/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { OverlayRef, OverlayConfig } from '@angular/cdk/overlay';
import { SpinnerService } from './spinner.service';
import { ThemePalette } from '@angular/material/core';

// import { OverlayService } from '../overlay/overlay.service';

@Component({
  selector: 'lib-progress-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css']
})
export class SpinnerComponent  implements OnInit, DoCheck {
  @Input() color?: ThemePalette;
  @Input() diameter ? = 100;
  @Input() mode?: ProgressSpinnerMode;
  @Input() indeterminateMode?: ProgressSpinnerMode = 'indeterminate';
  @Input() strokeWidth?: number;
  @Input() value?: number;
  @Input() backdropEnabled = true;
  @Input() positionGloballyCenter = true;
  @Input() displayProgressSpinner = false;
  @Input() displayFarmersLoader = false;

  @ViewChild('progressSpinnerRef')
  private progressSpinnerRef: TemplateRef<any>;
  constructor(private vcRef: ViewContainerRef,
              private spinnerService: SpinnerService,
              private changeDetectorRef: ChangeDetectorRef
   ) { }

  ngOnInit() {

    this.spinnerService.getSpinStatus().subscribe(spin => {
      this.displayProgressSpinner = spin.spinStatus;
      // US241106: Performance analysis on Google API returned address triggering Change ZIP
      this.changeDetectorRef.detectChanges();
    });

    // US275714: UI - Update Auto  - Implement Loader Screen for Partner Quotes
    this.spinnerService.getFarmersLoaderStatus().subscribe(statusObj => {
      this.displayFarmersLoader = statusObj.farmersLoaderStatus;
      this.changeDetectorRef.detectChanges();
    });
  }
  ngDoCheck() {

  }
}
