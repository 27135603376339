<mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
            Liability Coverage
        </mat-panel-title>
 
      </mat-expansion-panel-header>


      <div>Accordion content here !</div>

    </mat-expansion-panel>
  </mat-accordion>