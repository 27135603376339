<mat-dialog-content class="auto__module__schedule-agent-dialog">
    <div class="modal-header">
            <div class="btn-close" role="button" aria-label="close schedule agent popup" (click)="close()" data-GTM="FFQ_Agent_Appt_Close_button">&times;</div>
        <div class="modal-header-title">
            Schedule an appointment
        </div>
    </div>
    <div class="auto__module__schedule-agent-dialog__content">
        <div class="auto__module__schedule-agent-dialog__agent__info">
            <div class="auto__module__schedule-agent-dialog__agent__name">
                Farmers Insurance - <span>{{data.firstName}} {{data.lastName}}</span></div>
            <div class="auto__module__schedule-agent-dialog__note">
                Submitting this form is not a confirmation of an 
                appointment. Your Farmers agent will need to confirm the time and date of your appointment.
            </div>
            <div class="auto__module__schedule-agent-dialog__required-note">
                *Required field
            </div>
        </div>
    <div class="auto___module-schedule-agent-dialog___cust_info">
        <h2 class="contact-heading">Contact information</h2>
        <form [formGroup]="formGroup" (ngSubmit)="submit()" class="auto___module-schedule-agent-dialog___cust_input" id="auto-module__schedule-agent-appt-forms">
                <lib-input-text
                        (inputFieldValue)="trackFieldEngagement($event, segmentFieldDescription.scheduleAppt, 'First Name')"
                        [params]="scheduleApptParams.firstName"></lib-input-text>
                <lib-input-text
                        (inputFieldValue)="trackFieldEngagement($event, segmentFieldDescription.scheduleAppt, 'Last Name')"
                        [params]="scheduleApptParams.lastName"></lib-input-text>
                <lib-input-text
                    (inputFieldValue)="trackFieldEngagement($event, segmentFieldDescription.scheduleAppt, 'Email ID')"
                    [params]="scheduleApptParams.emailId"></lib-input-text>
                <lib-input-text
                    (inputFieldValue)="trackFieldEngagement($event, segmentFieldDescription.scheduleAppt, 'Phone number')"
                    [params]="scheduleApptParams.phoneNumber"></lib-input-text>
                <div class="auto___module-schedule-agent-dialog-reason-for-contact">
                    <div class="auto___module-schedule-agent-dialog-reason-for-contact-label">Reason for contact*</div>
                    <lib-select
                    (selectFieldValue)="trackFieldEngagement($event, segmentFieldDescription.scheduleAppt, 'Reason for appointment')"
                    [params]="scheduleApptParams.reason"></lib-select>
                </div>
                <div class="auto___module-schedule-agent-dialog-existing-farmers-cust">
                <div class="auto___module-schedule-agent-dialog-existing-farmers-cust-label">Are you an existing Farmers customer?</div>
                <lib-radio-button
                        (inputFieldValue)="trackFieldEngagement($event, segmentFieldDescription.scheduleAppt, 'Is existing Farmers customer')"
                        [params]="scheduleApptParams.existingFarmCust"></lib-radio-button>
                </div>
                <lib-input-text
                (inputFieldValue)="trackFieldEngagement($event, segmentFieldDescription.scheduleAppt, 'Quote number')"
                [params]="scheduleApptParams.quoteNumber"></lib-input-text>
                <div class="auto___module-schedule-agent-dialog-line-break">
                    <div class="auto___module-schedule-agent-dialog-line"></div>
                </div>
                <h2 class="auto___module-schedule-agent-dialog-date-time-heading">Preferred date and time</h2>
                <lib-date-picker
                    (dateFieldValue)="trackFieldEngagement($event, segmentFieldDescription.scheduleAppt, 'Date of Appointment')"
                    [params]="scheduleApptParams.date"></lib-date-picker>
                <div class="auto___module-schedule-agent-dialog-time-slots">
                    <div class="auto___module-schedule-agent-dialog-time-slots-label">Time slot (select all that apply)*</div>
                    <lib-check-box [params]="scheduleApptParams.time"></lib-check-box>
                </div>
                <div class="auto___module-schedule-agent-dialog-line-break">
                    <div class="auto___module-schedule-agent-dialog-line"></div>
                </div>
                <p class="auto___module-schedule-agent-dialog-disclaimer">{{disclaimer1}}</p>
                <a class="auto___module-schedule-agent-dialog-privacy-link" href="{{ccpaUrl}}" target="_blank" aria-label="Personal information use opens in new window"
                 data-GTM="FFQ_Auto_ScheduleAgent_PerInfoUse_link">Personal Use of Information</a>   
                <button type="submit" class="auto___module-schedule-agent-dialog-submit-button" aria-label="Agree & Submit opens a popup/modal" data-GTM="FFQ_Auto_Agent_Appt_Submit_button">Agree & Submit</button>
        </form>
    </div>
</div>
</mat-dialog-content>