import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormInputParam } from '../form-input-params';
import { FormControl, AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { FormFieldEmitParams } from '../params/form-field-emit-params';
@Component({
  selector: 'lib-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss']
})
export class AutoCompleteComponent implements OnInit {
  @Input() params: FormInputParam;
  @Output() isPanelOpen = new EventEmitter<boolean>();
  Object = Object;
  myControl: AbstractControl ;
  filteredOptions: Observable<string[]>;
  proxyValue: any;
  defaultVal;
  displayOptions = false;

  /**
   * US255604 - Segment Tracking Field Engagement
   */
  @Output() searchCompletedValue: EventEmitter<FormFieldEmitParams> = new EventEmitter();
  @Output() searchActivatedValue: EventEmitter<FormFieldEmitParams> = new EventEmitter();
  inputValue: string;

  @ViewChild(MatAutocompleteTrigger, {read: MatAutocompleteTrigger}) auto: MatAutocompleteTrigger;

  @ViewChild('autoCompleteInput', { read: MatAutocompleteTrigger }) autoComplete: MatAutocompleteTrigger;
  
  constructor() { }

  ngOnInit() {
    window.addEventListener('scroll', this.scrollEvent, true);
    const formControlName = this.params.formControllName ;
    this.myControl = this.params.formGroup.controls[formControlName];
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => value ? (typeof value === 'string' ? value : value.label) : ''),
        map(label => label ? this._filter(label) : this.params.values.slice())
      );
      
  }

  private _filter(label: string): string[] {
    if (label && this.params.autocompleteOption) {
      this.displayOptions = true;
    }
    const suggOptions = this.params.values;
    const defaultLabel = this.params.defaultValue ? this.params.defaultValue : this.params.otherOption;
    this.defaultVal = suggOptions.find(e => e.label === defaultLabel);
    const filterValue = label.toLowerCase();
    const filtered = suggOptions.filter(option => option.label.toLowerCase().indexOf(filterValue) === 0 ||
    option.label.toLowerCase() === 'other' || option.label.toLowerCase() === 'others');
    if ((filtered.length === 1 && (filtered[0].label === 'Other' || filtered[0].label === 'OTHERS'))) {
      if ('other'.indexOf(filterValue) === 0 || 'others'.indexOf(filterValue) === 0) {
        return filtered.length > 0 ?  filtered : [this.defaultVal] ;
      } else {
        const filtered1 = suggOptions.filter(option => option.value.toLowerCase().indexOf(filterValue) === 0);
        if (filtered1.length === 0) {
          return filtered.length > 0 ?  filtered : [this.defaultVal] ;
        } else {
        return this.params.values;
        }
      }
    } else {
    return filtered.length > 0 ?  filtered : [this.defaultVal] ;
    }
  }

  displayFn = (value) => {
    if (value) {
      const selection = this.params.values.find(e => e.value === value);
      return selection ? selection.label : '' ;
    }
  }

  isValue(event) {
    if (this.params.autocompleteOption) {
      if (event.target.value) {
        this.displayOptions = true;
        event.stopPropagation();
        this.auto.openPanel();
      } else {
        this.displayOptions = false;
      }
    }
  }

  onInputChanged(event$: Event): void {
    /**
     * US255604 - Segment Tracking Field Engagement
     */
    const input = event$.target as HTMLInputElement;
    this.inputValue = input.value;
    if (this.inputValue.length === 3) {
      this.searchActivatedValue.emit({ value: this.inputValue, event: 'Search Activated' });
    }
  }

  onSelectionChanged(event$): void {
    if (event$.option && event$.option.value && event$.option.value) {
      this.proxyValue = event$.option.value.value;
    }
    /**
     * US255604 - Segment Tracking Field Engagement
     */
    const autoCompleteSelectedText = event$.option._element.nativeElement.innerText.trim();
    this.searchCompletedValue.emit({ value: autoCompleteSelectedText, event: 'Search Completed' });
  }

  // US125852 -AutoComplete dropdown scroll issue fix
  handleOpen() {
    if (this.params.autocompleteOption && (!this.displayOptions || !this.myControl.value)) {
      this.auto.closePanel();
    }
    this.isPanelOpen.emit(true);
  }

  handleClosed() {
    this.isPanelOpen.emit(false);
  }

  // US311472 - Enable scrolling on modals when autocomplete is selected and dropdown open
  scrollEvent = (event: any): void => {
    if (this.autoComplete.panelOpen) {
      this.autoComplete.updatePosition();
    }
  }
}
