import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-print-quote-coverage-section',
  templateUrl: './print-quote-coverage-section.component.html',
  styleUrls: ['./print-quote-coverage-section.component.scss']
})
export class PrintQuoteCoverageSectionComponent implements OnInit {
@Input()
coverageParams;
physicalDamageIndicatorFlag = false;
  constructor() { }

  ngOnInit(): void {
    if (this.coverageParams.sectionHeading.startsWith('Liability')) {
      this.coverageParams.sectionDatas.forEach(coverage => {
        if (coverage.limit) {
          const amntArr = coverage.limit.split('/');
          if (amntArr[0] && amntArr[0].startsWith('$')) {
            coverage.limit = amntArr[0] + ' per person' ;
            coverage.limit2 = amntArr[1] ? amntArr[1] + ' per accident' : null;
          }
        }
      });
    }
    this.physicalDamageIndicatorFlag = this.physicalDamageIndicatorFlagValue();
}
  physicalDamageIndicatorFlagValue(): boolean {
    let phyDamage = false;
    if (this.coverageParams.sectionDataFooter.physicalDamageIndicator !== undefined
      && this.coverageParams.sectionDataFooter.physicalDamageIndicator === 'N') {
      phyDamage = true;
    }
    return phyDamage;
  }
}
