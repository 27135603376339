import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromApp from '../../../store/app.state';
import * as fromAutoSelectors from '../../store/auto.selectors';
import * as fromDrivers from './drivers.state';
const getAppSelector = createFeatureSelector<fromApp.AppState>(
  'appState'
);


const getDriversSelector = createFeatureSelector<fromDrivers.DriversState>(
  'drivers'
);

export const getDriversControlData = createSelector(
  getDriversSelector,
  state => state ? state.controlData : null
);


// To fetch drop down list values in the add driver modal window
export const getfetchDropdownList = createSelector(
  getDriversSelector,
  (state) => {
    return state.data.fetchDropDownList;
  }
);

// To fetch drop down list service status
export const getfetchDropdownListStatus = createSelector(
  getDriversSelector,
  state => state && state.controlData && state.controlData.fetchDropDownList ? state.controlData.fetchDropDownList : ''
);


export const getCustomerCommunicationData = createSelector(
  getAppSelector,
  state => {
    return state.data.customerData.customer.communication;
  }

);

// selector to retrieve savePNI service status
export const getSavePNIStatus = createSelector(
  fromAutoSelectors.getServiceControlData,
  (serviceControlData) => {
    const savePNIStatus = serviceControlData && serviceControlData.savePNIStatus ? serviceControlData.savePNIStatus : null;
    return savePNIStatus;
  }
);

// To fetch PNI communication and brightline status data
export const getSubmitPNIDriverData = createSelector(
  fromAutoSelectors.getAutoControlData,
  getSavePNIStatus,
  (controlData, savePNIStatus) => {
    if (controlData) {
      const savePNiControlData = {
        isEmailvalid: controlData.isEmailValid,
        savePNIStatus,
        isBrightlIned: controlData.isEaiBrightLineInd,
        isBwPopupShown: controlData.isBwPopupShown ? controlData.isBwPopupShown : false,
        autoCompanyCode: controlData.autoCompanyCode,
      };
      return savePNiControlData;
    }
  }
);

export const getMDKnockoutStatus = createSelector(
  fromAutoSelectors.getAutoControlData,
  (controlData) => {
    return controlData && controlData.mdKnockoutInd ? controlData.mdKnockoutInd : null;
  }
);

// Selector for BW Data
export const getSubmitPNIBWData = createSelector(
  fromAutoSelectors.getAutoControlData,
  (controlData) => {
    const brightLineControlData = {
      isBrightlIned: controlData.isEaiBrightLineInd,
      isBwPopupShown: controlData && controlData.isBwPopupShown ? controlData.isBwPopupShown : false,
      autoCompanyCode: controlData.autoCompanyCode,
    };
    return brightLineControlData;
  }
);

// Selector for populating additional details form data
export const addnlDetailsFormData = createSelector(
  getCustomerCommunicationData,
  (communication) => {
    return {
      driverEmail: communication.emailAddress ? communication.emailAddress : '',
      driverPhone: communication.phoneNumber ? communication.phoneNumber : '',
      diffMailInd: communication.diffMailAddress === undefined ? 'N' : 'Y',
      street: communication.diffMailAddress === undefined ? '' : communication.diffMailAddress.street,
      apartment: communication.diffMailAddress === undefined ? '' : communication.diffMailAddress.apartment,
      city: communication.diffMailAddress === undefined ? '' : communication.diffMailAddress.city,
      state: communication.diffMailAddress === undefined ? '' : communication.diffMailAddress.state,
      zip: communication.diffMailAddress === undefined ? '' : communication.diffMailAddress.zip
    };
  }
);


// To fetch incident category values in the add driver modal window
export const getfetchIncidentCategoryList = createSelector(
  getDriversSelector,
  (state) => {
    return state.data.fetchIncidentCategoryList;
  }
);

/** Selector to get the landing state code */
export const getLandingStateCode = createSelector(
  getAppSelector,
  state => {
    return (state && state.data) ? state.data.landingStateCode : '';
  }
);

export const getVehicleList = createSelector(
  fromAutoSelectors.getVehicles,
  (vehicles) => {
    const vehicleList = [];

    vehicles.forEach(vehiclesitem => {

      const vehobj = {
        label: vehiclesitem.vehicleSelected,
        value: vehiclesitem.reference,
        vehicleUsageDesc: vehiclesitem.vehicleUsageDesc,
        isDisabled: false,
        primaryDriver: vehiclesitem.primaryDriver,
      };
      vehicleList.push(vehobj);

    });
    return vehicleList;
  }
);

export const getVehicleAssignmentList = createSelector(
  fromAutoSelectors.getVehicles,
  (vehicles) => {
    const vehicleAssignmentList = [];

    vehicles.forEach(vehiclesitem => {

      const vehobj = {
        reference: vehiclesitem.reference,
        primaryDriver: vehiclesitem.primaryDriver,
      };
      vehicleAssignmentList.push(vehobj);

    });
    return vehicleAssignmentList;
  }
);

export const getDriverAssignmentList = createSelector(
  fromAutoSelectors.getDriverList,
  (drivers) => {
    const driverAssignmentList = [];

    drivers.forEach(driversItem => {

      const drvObj = {
        reference: driversItem.reference,
        freqVeh: driversItem.freqVeh,
      };
      driverAssignmentList.push(drvObj);
    });
    return driverAssignmentList;
  }
);

export const getDriverAssignmentData = createSelector(
  fromAutoSelectors.getDriverList,
  getVehicleList,
  getVehicleAssignmentList,
  getSubmitPNIDriverData,
  getLandingStateCode,
  (drivers, vehicleList, vehicleAssignmentList, brightLineControlData, landingStateCode) => {
    const driverList = [];

    drivers.forEach(driveritem => {

      const vehicleListcopy = JSON.parse(JSON.stringify(vehicleList));
      vehicleListcopy.forEach(vehiclesitem => {
        vehiclesitem.ariaLabel = 'Assign' + vehiclesitem.label + 'to' + driveritem.firstName + ' ' + driveritem.lastName;
      });
      const drvobj = {
        fullName: driveritem.firstName + ' ' + driveritem.lastName,
        age: driveritem.age,
        dateOfBirth: driveritem.dateOfBirth,
        ageFirstLicenced: driveritem.ageFirstLicencedInUS,
        inExpDrv: driveritem.inExpDrv,
        mostUsedVehicleRef: driveritem.mostUsedVehicleRef,
        reference: driveritem.reference,
        isBWQuote: brightLineControlData.isBrightlIned,
        driverLicense: driveritem.spouseLicencedToDrive,
        landingStateCode,
        vehicleList: vehicleListcopy,
        vehicleAssignmentList
      };

      driverList.push(drvobj);


    });

    return driverList;
  }
);

export const getVehicleAssignmentData = createSelector(
  fromAutoSelectors.getDriverList,
  getVehicleList,
  getDriverAssignmentList,
  getSubmitPNIDriverData,
  (drivers, vehicleList, driverAssignmentList) => {
    const vehiclesList = [];

    vehicleList.forEach(vehicleitem => {

      const driverListcopy = JSON.parse(JSON.stringify(drivers));
      driverListcopy.forEach(driveritem => {
        driveritem.ariaLabel = 'Assign ' + vehicleitem.label + ' to ' + driveritem.firstName + ' ' + driveritem.lastName;
      });
      const vehobj = {
        name: vehicleitem.label,
        reference: vehicleitem.value,
        primaryDriver: vehicleitem.primaryDriver,
        driverList: driverListcopy,
        driverAssignmentList
      };
      vehiclesList.push(vehobj);
    });
    return vehiclesList;
  }
);
