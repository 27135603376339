import * as fromYourInfoState from './your-info.state';
import * as fromAppState from '@ffq-app-store/app.state';
import { AutoState } from '@ffq-app-auto/store/';


/* Selectors */
import { createFeatureSelector, createSelector } from '@ngrx/store';


const getYourInfoSelector = createFeatureSelector<fromYourInfoState.YourInfoState>(
  'yourInfo'
);

const getAppSelector = createFeatureSelector<fromAppState.AppState>(
  'appState'
);

const getAutoSelector = createFeatureSelector< AutoState.AutoState >(
  'auto'
);


export const getData = createSelector(
  getYourInfoSelector,
  state => state.data
);


export const getError = createSelector(
  getYourInfoSelector,
  state => state.error
);

/**
 * retrieves the control data from app store
 */
export const getAppControlData = createSelector(
  getAppSelector,
  state => state.controlData
);

export const selectCustomer = (state: fromYourInfoState.YourInfoState) => state.data ? state.data.customer : null;
export const selectAgent = (state: fromAppState.AppState) => state.data ? state.data.agent : null;
export const selectQuoteData = (state: fromAppState.AppState) => state.data ? state.data.quoteData : null;
// export const selectYourInfoControlData = (state: fromYourInfoState.YourInfoState) => state.controlData ? state.controlData : null;
// export const selectAppControlData = (state: fromAppState.AppState) => state.controlData ? state.controlData : null;



/**
 * returns the customer data from store
 */
export const getCustomerData = createSelector(
  getAppSelector,
  state => {
    return state.data.customerData ? state.data.customerData : null;
  }
);

/** checks city validity */
export const getIsValidCity = createSelector(
  getAppSelector,
  state => {
      return state.data.customerData ? state.data.customerData.isValidCity : null;
  },
);
/**
 * returns control data from auto store
 */
export const getAutoControlData = createSelector(
  getAutoSelector,
  state =>  {
   return  state.controlData;
  }
);

/**
 * returns prefill service status from auto control data
 */
export const getPrefillServiceStatus = createSelector(
  getAutoControlData,
  (controlData) => {
    const prefillStatus = {
      prefillData: controlData.prefillData ? controlData.prefillData : null,
      isPrefillDataReceived: controlData.isPrefillDataReceived ? controlData.isPrefillDataReceived : null
    };
    return prefillStatus;
  },
);


/**
 * returns the submit yourinfo and assign agent service status from app control data
 */
export const getAppServiceStatus = createSelector(
  getAppControlData,
  (controlData) => {
    const serviceStatus = {
      submitYourInfo: controlData && controlData.submitYourInfo ? controlData.submitYourInfo : null,
      assignAgent: controlData && controlData.assignAgent ? controlData.assignAgent : null,
      retrieveQuote: controlData && controlData.retrieveQuote ? controlData.retrieveQuote : null,
    };
    return serviceStatus;
  },
);

/**
 * returns whether the customer is existing or not
 */
export const getExistingCustomerData = createSelector(
  getCustomerData,
  (customerData) => {
    const custData = {
      isExistingCust: customerData && customerData.isExistingCust ? customerData.isExistingCust : null,
      existingLobs: customerData && customerData.existingLobs ? customerData.existingLobs : null,
      existingCustAction: customerData && customerData.existingCustAction ? customerData.existingCustAction : null
    };
    return custData;
  }
);

export const getRedirectionStatus = createSelector(
  getCustomerData,
  (customerData) => {
    const redirection = {
      isRedirectionEligible: customerData && customerData.isRedirectionEligible ? customerData.isRedirectionEligible : null,
      redirectionUrl: customerData && customerData.redirectionUrl ? customerData.redirectionUrl : null,
    };
    return redirection;
  }
);

export const getFwsRedirectionParam = createSelector(
  getCustomerData,
  (customerData) => {
    const fwsRedirectionParams = {
      display: customerData && customerData.display ? customerData.display : null,
      ffqredirectionalUrl: customerData && customerData.ffqredirectionUrl ? customerData.ffqredirectionUrl : null,
    };
    return fwsRedirectionParams;
  }
);

/**
 * returns whether the city is valid or not
 */
// export const getCityValidity = createSelector(
//   getIsValidCity,
//   (customerData) => {
//     const validCity = {
//       isValidCity: customerData && customerData.isValidCity ? customerData.isValidCity : null
//     };
//     return validCity;
//   }
// );

/**
 * returns the status of services submitYourInfo, assignAgent & prefill service
 * along with customer data indicating whether its already existing customer
 */
export const getSubmitYourInfoStatus = createSelector(
  getPrefillServiceStatus,
  getAppServiceStatus,
  getExistingCustomerData,
  getRedirectionStatus,
  getAppControlData,
  getFwsRedirectionParam,
  (prefillStatus, serviceStatus, custData, redirection, controlData, fwsRedirectionParams) => {
     return  {
      submitYourInfo: serviceStatus.submitYourInfo,
      customerData: custData,
      redirectionForMetlife: redirection,
      assignAgent: serviceStatus.assignAgent,
      prefillStatus: prefillStatus.prefillData,
      isPrefillDataReceived: prefillStatus.isPrefillDataReceived,
      retrieveQuote: serviceStatus.retrieveQuote,
      controlData: controlData.calledSegmentEvent,
      fwsRedirectionParameters: fwsRedirectionParams
     };
  }
);
