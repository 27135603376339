import { Component, OnInit, Inject, Injectable, HostListener } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import {
  MatDatepicker,
  MatDatepickerModule
} from '@angular/material/datepicker';
import { DriverDataSummary } from '@ffq-app-auto/drivers';
import { InfoDialogService } from './info-dialog.service';
import { MatNativeDateModule } from '@angular/material/core';

export interface DialogData {
  dialogTitle: string;
  dialogContent: string;
  cancelButtonText: string;
  confirmButtonText: string;
  buttonName: string;
  acceptDialogAction: string;
  cancelDialogAction: string;
  driver: DriverDataSummary;
}

@Component({
  selector: 'lib-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss'],
  providers: [
    MatDialog,
    MatDatepicker,
    MatDatepickerModule,
    MatNativeDateModule
  ]
})
export class InfoDialogComponent implements OnInit {
  public dialogTitle: string;

  public dialogContent: string;
  className: string;
  public buttonName: string;
  buttonGTMTag?: string;
  constructor(public dialog: MatDialog) {
    // super();
  }

  onNoClick(): void {
    alert('Close button clicked ');
  }

  openDialog(title: string, content: string, additionalParameters = { styleClass: '', buttonName: '', buttonGTM: '' }) {
    this.dialogTitle = title;
    this.dialogContent = content;
    if (additionalParameters.styleClass) {
      this.className = additionalParameters.styleClass;
    } else {
      this.className = 'ffq-info-dialog';
    }
    if (additionalParameters.buttonName) {
      this.buttonName = additionalParameters.buttonName;
    } else {
      this.buttonName = 'Close';
    }
    if (additionalParameters.buttonGTM) {
      this.buttonGTMTag = additionalParameters.buttonGTM;
    } else {
      this.buttonGTMTag = '';
    }


    //     alert('Show dialog');

    // tslint:disable-next-line: no-use-before-declare
    const dialogRef = this.dialog.open(InfoDialogContentComponent, {
      // width: '250px',
      // position: { left: '20px' },
      panelClass: this.className,
      data: {
        dialogTitle: this.dialogTitle, dialogContent: this.dialogContent,
        buttonName: this.buttonName, buttonGTMTag: this.buttonGTMTag
      },
      autoFocus: false,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      this.dialogContent = result;
    });
    return dialogRef;
  }

  closeDialog(): void {
    //     alert('Show dialog');

    this.dialog.closeAll();
  }
  ngOnInit() {  }
}

@Component({
  selector: 'lib-info-dialog',
  templateUrl: 'info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss'],
  providers: [MatDialog]
})
export class InfoDialogContentComponent implements OnInit {
  public dialogTitle: string;
  public buttonName: string;
  public dialogContent: string;
  public buttonGTMTag?: string;

  constructor(
    public dialogRef: MatDialogRef<InfoDialogContentComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private infoService: InfoDialogService
  ) {
    this.dialogTitle = data.dialogTitle;
    this.dialogContent = data.dialogContent;
    this.buttonName = data.buttonName;
    this.buttonGTMTag = data.buttonGTMTag;
  }

  @HostListener('window:keyup.esc') onkeyup() {
    this.dialogRef.close();
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    // US241955- Help Modal Opened segment event
    this.infoService.setDialogStatus(this.dialogTitle, this.dialogContent);
  }
}
