import { AgentCommunication } from '@ffq-app-shared/components';
import { RateQuoteProgressStatus } from '@ffq-app-shared/enums/flow.identifier.enum';
import { FetchDropdownListResponse } from '@ffq-app-shared/model/fetch-dropdown.model';
import { BusinessInfo } from '../../business/business-info/model/business-info.model';
/**
 * Project code status
 */
export interface ProjectCodeStatus {
    isRideShareImpl: boolean;
    isGaragingZipImpl: boolean;
    isYDDImpl: boolean;
    isBrightLineImpl: boolean;
    isBWImplemented: boolean;
    isAcxiomImpl: boolean;
    isGEPrjImpl: boolean;
    isADPFImpl: boolean;
    isAffinityImpl: boolean;
    isForeignDrvImpl: boolean;
    isFa2point5Impl: boolean;
    isFaThreeImpl: boolean;
    residencetypeRender: boolean;
    isautoAddressCompImpl: boolean;
    isFCCImplemented: boolean;
    isRideShareCvgImpl: boolean;
    isGWImpl: boolean;
    isEFTImpl: boolean;
    isLBImpl: boolean;
    isCAAutoClassPlanImpl: boolean;
    isChatBotImpl: boolean;
    effectiveDate: string;
    isRentersImpl: boolean;
    ttkCrossSellDisabledLobs: string;
    isEnhancedCoverageApplicable: boolean;
    isGenderXImpl: boolean;
    isLiveChatImpl: boolean;
    isParkOverNightImpl: boolean;
    isPrincipalPIP: boolean;
    maximumPolicyStartDate: number;
    minimumPolicyStartDate: number;
}


/**
 * Communication
 */
export interface CommunicationData {
    emailAddress: string;
    phoneNumber: string;
    phoneNumberExtn?: string;
    diffMailInd?: string;
    diffMailAddress?: AddressData;
}


/**
 * BW Assign Agent req
 */
export interface BwAssignAgent {
    quoteNumber?: string;
    quoteWindowId?: string;
}

/**
 * Auto
 */
export interface Auto {
    yourInfo: string;
    vehicles: string;
    drivers: string;
    discounts: string;
    quote: string;
}

/**
 * Home
 */
export interface Home {
    yourInfo: string;
}

/**
 * Business
 */
export interface Business {
    businessInfo: string;
    yourInfo: string;
    confirmation: string;
}
/**
 * Navigation data
 */
export interface NavigationData {
    auto: Auto;
    home: Home;
    business: Business;
}


/**
 * Prefill data
 */
export interface PrefillData {
    currentInsuredAnswer: string;
    driver: any;
    prospectQuoteReportId: any;
    quoteNumber: string;
    vehicle: any;
    iidRiskIndicators?: IIDRiskIndicator[];
    externalId: string;
}

/**
 * Iid risk indicator
 */
export interface IIDRiskIndicator {
    code?: string;
    description?: string;
}


/* Vehicle related Models */


/**
 * Vehicle
 */
export interface Vehicle {
    reference?: number;
    vin?: string;
    year?: string;
    make?: string;
    model?: string;
    makeDesc?: string;
    modelDesc?: string;
    surchargeBI?: string;
    surchargePD?: string;
    vehicleIsOn?: string;
    vehicleType?: string;
    purchaseDate?: string;
    vehicleUsage?: string;
    vehicleUsageDesc?: any;
    alternativeFuel?: string;
    daytimeRunningLamps?: string;
    vinEtching?: string;
    rideShare?: string;
    diffGrgInd?: string;
    grgingAddr?: GrgIngAddr;
    theftRecovery?: string;
    antiTheftDevice?: string;
    antiLockBreaks?: string;
    passiveRestraint?: string;
    oneWayMiles?: string;
    annualMiles?: number;
    noOfDaysUsed?: string;
    absCdDrv?: string;
    absProtected?: string;
    absUpdated?: string;
    altFuelProtected?: true;
    altFuelupdated?: string;
    baseSymbolDrv?: string;
    bipdSymbol?: string;
    carSymbol?: string;
    cashValueAmt?: string;
    minHorsePower?: string;
    custCarSymbol?: string;
    excludedCarInd?: string;
    fiberglassInd?: string;
    highPerfInd?: string;
    highPerfIndDrv?: string;
    isVinEntrInQuote?: string;
    lnLrAddr?: string;
    lnLrCompany?: string;
    lnLrState?: string;
    medPipSymbol?: string;
    odometerDate?: string;
    odometerReadSrc?: string;
    pasiveRstUpdated?: string;
    passiveRestrCdDrv?: string;
    polkAbsCdDrv?: string;
    primaryDriver?: string;
    requiredUpdateVehicle?: string;
    sportsCarInd?: string;
    symDetMethCd?: string;
    truckGUWCdDrv?: string;
    validVehicle?: string;
    vehClassCd?: string;
    vehcielFuelType?: string;
    vehicleSelected?: string;
    escdValdtnCdDrv?: string;
    rideShareVeh?: string;
    physicalDamageIndicator?: string;
    maskVin?: string;
    encryptVin?: string;
}

/**
 * Grg ing addr
 */
export interface GrgIngAddr {
    apartment: string;
    city: string;
    state: string;
    zip: string;
    street: string;
    town: string;
}



/**
 * Driver
 */
export interface Driver {
    mostUsedVehicleRef?: number;
    inExpDrv?: boolean;
    accidentHistory?: string;
    age?: number;
    ageFirstLicencedInForeign?: string;
    ageFirstLicencedInUS?: string;
    ageLicenseCountry?: string;
    bodilyInjuryLimitCd?: string;
    bodilyInjuryLimitName?: string;
    activeMilitaryDuty?: string;
    carrierCode?: string;
    carrierExpDate?: string;
    commuteToDC?: string;
    commuteToNYNJ?: string;
    currentCarrier?: string;
    dateOfBirth?: string;
    distantStudent?: string;
    finFiling?: string;
    firstName?: string;
    fullTimeStudent?: string;
    gender?: string;
    incidents?: Incident[];
    lapseInCovdesc?: string;
    lapseInCoverage?: string;
    lastName?: string;
    licNumber?: string;
    licStateCd?: string;
    maritalStatus?: string;
    maritalStatusDesc?: string;
    militaryDeployment?: string;
    nopriorIns?: string;
    occuOtherDesc?: string;
    occupation?: string;
    occupationDesc?: string;
    personalInjury?: string;
    phoneNumber?: string;
    pni?: string;
    prefilledBySAQ?: string;
    principalPIP?: string;
    priorCarrier?: string;
    reference?: string;
    safetyCourseCompleted?: string;
    safetyCourseDate?: string;
    spouseLicencedToDrive?: string;
    spouseRef?: string;
    ssn?: string;
    ssnUpdated?: boolean;
    address?: string;
    communication?: string;
    creditScore?: string;
    //  This is for Driver Prefill Data only
    // Not required for Network call.
    prefillRef?: string;
    currentlyAutoInsuered?: string;
    driverType?: string;
    emailAddress?: string;
    liyabilityInMonths?: string;
    primaryVehicle?: string;
    rideShareDrv?: string;
    // Not required for Network call.
    driveProgress?: DriverProgress;
    ffqOccupationCode?: string;
    ffqOccupationDescription?: string;
    anySixMonthsContLiabilityInPast?: string;
    licenseNumber?: string;
    licenseIssueState?: string;
    licenseFromAdpf?: boolean;
    adpfLicenseSequenceNumber?: string;
    drivingExpInMonths?: string;
    carrierNameAvailable?: boolean;
    bodilyInjuryAvailable?: boolean;
    carrierExpiryAvailable?: boolean;
    lapseInCvgAvailable?: boolean;
}

/**
 * Incident
 */
export interface Incident {
    bodilyInjury?: string;
    dateOccured?: Date;
    description?: WebDescValue;
    type?: WebDescValue;
}

// TODO move to Shared Modal
export enum CardStatus {
    Disabled = 'D',
    Active = 'A',
    Completed = 'C'
}

/**
 * Driver progress
 */
export enum DriverProgress {
    Initiated = 'I',
    Completed = 'C'
}

/**
 * Rate mode
 */
export enum RateMode {
    Rate = 'rate',
    Retrieve = 'retrieve',
    PI_Rate = 'pi_rate',
    PI_Retrieve = 'pi_retrieve',
}



/**
 * Web desc value
 */
export interface WebDescValue {
    webDesc?: string;
    value?: string;
}


/**
 * Address
 */
/**
 * Address data
 */
export interface AddressData {
    street?: string;
    apartment?: string;
    city?: string;
    state?: string;
    zip?: string;
    town?: string;
}

/**
 * Agent data
 */
export class AgentData {
    agentId: string;
    firstName: string;
    lastName: string;
    communication: AgentCommunication;
    address: AddressData;
    reference: string;
    agentImageUrl: string;
    isRedirectionEligible: boolean;
    redirectionUrl: string;
    display: string;
    ffqredirectionUrl: string;
    agencyType: string;
    initialAgent: string;
    assignmentType: string;
    assignedType: string;
}

/* 2- Define feature state */
export interface BusinessState {
    data: Data;
    error: string;
    controlData: any;
}

export interface Data {
    fetchDropDownList: FetchDropdownListResponse;
    businessInfo: BusinessInfo;
    agentData: {};
}
/**
 * Customer
 */
export interface Customer {
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    gender: string;
    address: AddressData;
    communication: CommunicationData;
}

/**
 * Session storage data
 */
export interface SessionStorageData {
    appStateFeatureStoreData: AppStateFeatureStoreData;
    autoFeatureStoreData: any;
    bizFeatureStoreData: any;
    partialDataInQuote: any;
    isPartialDataExists: boolean;
}


/**
 * Retrieve data status
 */
export interface RetrieveDataStatus {
    isAppStateDataExists: boolean;
    isAutoDataExists: boolean;
    isYourInfoDataExists: boolean;
    isVehiclesDataExists: boolean;
    isDriversDataExists: boolean;
    isDiscountsDataExists: boolean;
    isQuoteDataExists: boolean;
}
export interface AppStateFeatureStoreData {
    data: {
        agentData?: AgentInfo;
        quoteNumber?: any;
        effectiveDate?: any;
        customerData?: CustomerData;
        _windowID?: any;
        tokenInfo?: any;
        redirectionData?: any;
        visitorId?: any;
        projectCodeStatus?: ProjectCodeStatus;
        zipCode?: any;
        lob?: any;
        homeBoundIndicator?: false;
        isValidAgent?: any;
        landingStateCode?: any;
        landingStateDescription?: any;
        lifeBoundIndicator?: false;
        pniMaritalStatus?: any;
        pniOccupation?: any;
        quoteType?: any;
        quoteWindowId?: any;
        quotedLobs?: any;
        spouseDriver?: any;
        ssnKey?: any;
        windowId?: any;
        landingZipCode?: any;
        zipCityList?: any;
        zipTownList?: any;
        sourcePhoneNumber?: any;
        src?: any;
        sourcePhoneNumberArray?: any;
        spouseOccupation?: string;
        isOneUIEnabled?: boolean
        redirectionParams?: string;
    };
    controlData?: any;
}

export interface AppStateFeatureStoreDataForBiz {
    data: {
        businessInformation?: any;
        customerInformation?: any;
        agent?: any;
        src?: any;
        landingZipCode?: any;
        landingStateCode?: any;
        tokenInfo?: any;
        quoteNumber?: any;
        effectiveDate?: any;
        quoteType?: any;
        lob?: any;
        lifeBoundIndicator?: any
        ssnKey?: any;
    };
    controlData?: any;
}

/**
 * Agent info
 */
export interface AgentInfo {
    agent?: AgentData;
}

/**
 * Customer data
 */
export interface CustomerData {
    customer?: any;
    lob?: any;
    currentlyAutoInsured?: any;
    existingLobs?: any;
    diffMailAddress?: any;
    isExistingCust?: any;
    subscriptionAgreement?: string;
    employer?: EmployerData;
}


/**
 * Last visited module
 */
export enum LastVisitedModule {
    Auto_YourInfo = 'YourInfo',
    Auto_Vehicle = 'Vehicle',
    Auto_Driver = 'Driver',
    Auto_Signal = 'Signal',
    Auto_Discount = 'Discount',
    Auto_Quote = 'Quote',
    Auto_VehicleDriverAssignment = 'Assignment',
    Auto_RideShare = 'RideShare',
    Auto_ThankYou = 'ThankYou',
    Rate_Summary = 'RateSummary',
    Auto_AdditionalInfo = 'ADPFExcludedDriver',
    Auto_IIDKnockOut = 'IIDKnockOut'
}

/**
 * Quote
 */
export interface Quote {
    vehicle: any[];
    discount: any[];
    liabilityCoverage: any;
    knockOutInd: boolean;
    brightlinedIndicator: boolean;
    buyEnableInd: any;
    knockOutBeanList: any[];
    infoMessages: any[];
    payPlanChanged: boolean;
    userType: any;
    policyFee: any;
    flHouseAccount: boolean;
    isHouseAccount: boolean;
    eligibleForChat: boolean;
    eligibleForAutoRentersBundle: boolean;
    validCityZipForMN: boolean;
    coverageAdjustedIndicator: string;
    isHomeOwnerDiscount?: boolean;
    umbrellaPolicyIndicator?: string;
    dnqMessages?: DNQMessage[];
}

/**
 * Rate quote request
 */
export interface RateQuoteRequest {
    quoteNumber: string;
    quoteWindowId: string;
    landingStateCode: string;
    effectiveDate: string;
    rateMode: string;
    rateBU: string;
    ratePackage: string;
    clearCustomPackage: boolean;
    lastVisitedPage: string;
    pgLastVisitedPage: string;
    eleLinkFlag: string;
    existingLobs: string;
    redirectionFlow: string;
    residenceType: string;
    primaryResidence: string;
    chatbotImpl: boolean;
    isLiveChatImpl: boolean;
    eligibleForChatBotPreviously: boolean;
    rentersImpl: boolean;
    signalDiscountEnrolled: string;
    eligibleForRateFeatures: boolean;
}

export interface SignalDiscountRequest {
    quoteNumber: string;
    quoteWindowId: string;
    landingStateCode: string;
    lastVisitedPage: string;
    pgLastVisitedPage: string;
    signalDiscountEnrolled: string;
    saveMode?: string;
}

/**
 * Data layer request for content change
 */
export interface DataLayerRequestForContentChange {
    QuoteNumber: any;
    QuoteType: any;
    StateCode?: any;
    ZipCode?: any;
    LOB?: any;
    QuoteAmount?: any;
    LivingStatus?: any;
    BundleInsurance?: any;
    SourceId?: any;
    VisitorId?: any;
    Industry?: any;
    // Do we really need this two ? Event and  event?
    Event?: any;
    Page?: any;
    CurrentTerm?: any;
    event?: any;
    QuoteBtn?: string;
    /** US203892: Custom Dimension for Signal Discounts */
    signalApplied: string;
    nosignal: string;
}

/**
 * Vin look up request
 */
export interface VinLookUpRequest {
    quoteNumber: string;
    quoteWindowId: string;
    vin: string;
    landingStateCode: string;
    effectiveDate: string;
}

/**
 * Vin vehicle
 */
export interface VinVehicle {
    vehicle: VinLookupVehicle;
}

/**
 * Vin lookup Vehicle Response
 */
export interface VinLookupVehicle {
    reference: string;
    year: string;
    make: string;
    model: string;
    makeDesc: string;
    modelDesc: string;
    surchargeBI?: string;
    surchargePD?: string;
    vehicleType: string;
    vin: string;
    vehicleUsage: string;
    vehicleUsageDesc: string;
    vehcielFuelType: string;
    absCdDrv: string;
    polkAbsCdDrv: string;
    passiveRestrCdDrv: string;
    highPerfIndDrv: string;
    escdValdtnCdDrv: string;
    truckGUWCdDrv: string;
    baseSymbolDrv: string;
    primaryDriver: string;
    annualMiles: string;
    noOfDaysUsed: string;
    oneWayMiles: string;
    antiLockBreaks: string;
    absProtected: string;
    passiveRestraint: string;
    theftRecovery: string;
    alternativeFuel: string;
    altFuelProtected: true;
    passiveRstProtected: string;
    carSymbol: string;
    symDetMethCd: string;
    bipdSymbol: string;
    medPipSymbol: string;
    excludedCarInd: string;
    highPerfInd: string;
    sportsCarInd: string;
    fiberglassInd: string;
    cashValueAmt: string;
    minHorsePower: string;
    custCarSymbol: string;
    absUpdated: string;
    pasiveRstUpdated: string;
    altFuelupdated: string;
    isVinEntrInQuote: string;
    vehicleSelected: string;
    requiredUpdateVehicle: string;
    purchaseDate: string;
    vehicleIsOn: string;
    lnLrState: string;
    lnLrCompany: string;
    lnLrAddr: string;
    odometerMileage: string;
    odometerDate: string;
    persAutoCoverage: string;
    persInsAutoCoveragebean: [{}];
    daytimeRunningLamps: string;
    odometerReadSrc: string;
    vehClassCd: string;
    validVehicle: string;
    grgingAddr: string;
    antiTheftDevice: string;
    diffGrgInd: string;
    rideShare: string;
    vinEtching: string;
    homingDevice: string;
    statedValue: string;
    rideShareVeh: string;
    isValidVin?: string;
    maskVin?: string;
    encryptVin?: string;
}

/**
 * Save vehicle request
 */
export interface SaveVehicleRequest {
    quoteNumber: string;
    quoteWindowId: string;
    saveMode: string;
    emailAddress: string;
    vehicle: Vehicle;
    lastVisitedPage: string;
    pgLastVisitedPage: string;
}

/**
 * Save driver object
 */
export interface SaveDriverObject {
    quoteNumber?: string;
    quoteWindowId?: string;
    landingStateCode?: string;
    effectiveDate?: string;
    saveMode?: string;
    emailAddress?: string;
    currentInsuredAnswer?: string;
    fccDisclaimerImpl?: string;
    fccDisclaimerContent?: string;
    driver: Driver;
    lastVisitedPage?: string;
    pgLastVisitedPage?: string;
}

/**
 * Save driver Vehicle Assignment
 */
export interface SavedriverVehicleAssignment {
    quoteNumber?: string;
    quoteWindowId?: string;
    landingStateCode?: string;
    effectiveDate?: string;
    drivers?: any[];
    vehicles?: any[];
    saveMode?: string;
    lastVisitedPage?: string;
    pgLastVisitedPage?: string;
    emailAddress?: string;
}

/**
 * Validate License Number
 */
export interface ValidateLicenseNumber {
    drvLicNum?: string;
    licLocCd?: string;
    driver?: Driver;
    landingStateCode?: string;
    quoteWindowId?: string;
    quoteNumber?: string;
}

/**
 * Save pniadditional data
 */
export interface SavePNIAdditionalData {
    lob?: string;
    effectiveDate?: string;
    quoteNumber?: string;
    driverReference?: string;
    driverEmail?: string;
    driverPhone?: string;
    driverSSN?: string;
    fccDisclaimerContent?: string;
    fccDisclaimerImpl?: string;
    saveMode?: string;
    diffMailInd?: string;
    diffMailAddress?: AddressData;
    anonymousId?: string;
}

/* 2- Define feature state */
export interface AutoState {
    data: AutoData;
    error: any;
    controlData: any;
    serviceControlData: any;
}



/**
 * Control data
 */
export interface AutoControlData {
    prefillData: null;
    isDiscountSave: null;
    isSignalDiscountSave: null;
    isEaiBrightLineInd: boolean;
    isBrightLinedFromCreditCheck: boolean;
    autoCompanyCode: string;
    isEmailValid: boolean;
    currentInsuredAnswer: string;
    isBwPopupShown: boolean;
    encryptedMDMResponse: string;
    /** used to store the value of ele link flag based on user click */
    eleLinkFlag: boolean;
    // used to control the visibility of ELE link on page quote. Since we don't need to show the link once the user clicks
    isELEEnabled: boolean;
    NCSubscription: string;
    /**
     * used to skip the rendering of vehicle coverage data in quote page
     * when there is updation happened in store due to the service call which triggered for fetching all other packages
     */
    secondRateCallCompleted: boolean;
    baseRateCallCompleted: boolean;
    // used to identify whether the quote is eligible for auto-renters bundle or not
    eligibleForAutoRentersBundle: boolean;
    secondPerformRate: string;
    errorCount: number; /* count the errors occured during the service call */
    flHouseAccount: boolean; /* used to store the flHouseAccount value from discount, rate and saveknockout response */
    isHouseAccount: boolean; /* used to store the isHouseAccount value from discount, rate and saveknockout response */
    rc1PremiumAmt: string; /* used to store the premium amount in RC1 flow */
    isPrefillCallRequired: boolean; /* used to store the flag to decide if prefill service is called in RQ flow */
}


/**
 * Data for Auto
 */
export interface AutoData {
    prefillData: any;
    vehicles: Vehicle[];
    vinLookupVehicle: VinVehicle;
    driverVehicleAssignment: any[];
    discount: any;
    quote: Quote;
    drivers: any[];
    knockout: any;
    rentersRate?: any;
    adpfExcludedDriverData?: ExcludedDriver[];
    prequalifiedDiscounts?: PrequalifiedDiscounts[];
    validateLicense?: boolean;
    isYDExist?: boolean;
}

/**
 * Save vehicle list object
 */
export interface SaveVehicleListObject {
    quoteNumber?: string;
    quoteWindowId?: string;
    lastVisitedPage?: string;
    pgLastVisitedPage?: string;
    saveMode?: string;
    emailAddress?: string;
    vehicleRequestList: any[];
}

export interface InfoContentObj {
    name?: string;
    title?: string;
    value?: string;
    coverage?: string;
}

export interface PremiumAmountObj {
    packgDuration?: string;
    packgAmntValue?: string;
    packgAmntFraction?: string;
    isPayInFull?: boolean;
}

export interface EmployerData {
    gpcCode?: string;
    employerName?: string;
    choiceIndicator?: string;
    fwsZipEligble?: boolean;
    fwsToFFQRedirect?: string;
}

export interface EmployerResponseData {
    accountName?: string;
    accountType?: string;
    classicAutoInd?: string;
    expandName?: string;
    groupEligibilityInd?: string;
    groupProducerCode?: string;
    itemType?: string;
    matchDisplayItem?: string;
    matchItem?: string;
    mdAutoPrdInd?: string;
    paypointName?: string;
    r2AutoPrdInd?: string;
    snapQuoteDate?: string;
    snapQuoteInd?: string;
    weightValue?: number;
}

export enum LastVisitedStepForSegment {
    Auto_YourInfo = 'Your Info',
    Auto_Vehicle = 'Vehicles',
    Auto_Driver = 'Drivers',
    Auto_AddnlDetails = 'Driver Contact Info',
    Auto_Signal = 'Signal Discount',
    Auto_Discount = 'Discounts',
    Auto_Quote = 'Quote',
    Auto_VehicleDriverAssignment = 'Vehicle Driver Assignment',
    Auto_ThankYou = 'Thank You',
    Auto_DCM = 'Driver Mismatch'
}
/**
 * Excluded Driver
 */
export interface ExcludedDriver {
    firstName?: string;
    lastName?: string;
    reference?: string;
    rejectReason?: string;
    gender?: string;
    pni?: string;
    maritalStatus?: string;
}

/**
 * Save driver Vehicle Assignment
 */
export interface SaveExcludedDriversRequest {
    quoteNumber?: string;
    quoteWindowId?: string;
    driver?: ExcludedDriver[];
    saveMode?: string;
    lastVisitedPage?: string;
    pgLastVisitedPage?: string;
    isYDExist?: boolean;
}

export interface PrequalifiedDiscounts {
    name?: string;
    type?: string;
    code?: string;
    reference?: string;
}

export interface Response {
    errormessage?: string;
    status?: string;
    persAutoInsQuote?: PersAutoInsQuote;
    quoteNumber?: string;
    windowId?: string;
}

export interface RateResponse {
    buyEnableInd?: string;
    knockOutInd?: boolean;
    status?: string;
    persAutoPolicy?: PersAutoPolicy;
    payPlanChanged?: boolean;
    quoteNumber?: string;
    windowId?: string;
}

export interface SaveActionsRequest {
    quoteNumber?: string;
    paymentMode?: string;
    saveMode?: string;
    executeActions?: string[];
    dnqMessage?: string;
}

export interface DNQMessage {
    code?: string;
    description?: string;
}

export interface PersAutoInsQuote {
    driver?: Driver;
    eaiBrightLineInd?: boolean;
    persAutoPolicy?: PersAutoPolicy;
    membersofhouseHoldInsuredforMI?: string;
}

export interface PersAutoPolicy {
    discount?: PrequalifiedDiscounts[];
    vehicle?: Vehicle;
}

export interface Incidents {
    bodilyInjury?: string;
    incidentDate?: Date;
    descriptionLabel?: WebDescValue;
    typeLabel?: WebDescValue;
    incidentType?: string;
}

export interface YearAccident {
    fiveYears?: string;
    threeYears?: string;
}

export interface PayPolicy {
    payInfull?: string;
    sixMonthsTerm?: string;
    monthlyAutomatic?: string;
}

export interface Discount {
    quoteNumber?: string;
    quoteWindowId?: string;
    landingStateCode?: string;
    effectiveDate?: string;
    payPolicy?: string;
    spouseInsPolicy?: string;
    membersofhouseHoldInsuredforMI?: string;
    disabilitycoverageforMI?: string;
    householdCount?: string;
    residenceType?: string;
    primaryResidence?: string;
    policyStartDate?: string;
    homeTownLobSelected?: boolean;
    renterCondoLobSelected?: boolean;
    onlyCondoLob?: boolean;
    lifeLobSelected?: boolean;
    umbrellaLobselected?: boolean;
    businessLobselected?: boolean;
    noneOfTheAbove?: boolean;
    lastVisitedPage?: string;
    pgLastVisitedPage?: string;
    saveMode?: string;
    emailAddress?: string;
    recreationalLobSelected?: boolean;
    offRoadLobSelected?: boolean;
    motorHomeLobSelected?: boolean;
    motorCycleLobSelected?: boolean;
}

export interface FieldEnagement {
    value?: string;
    event?: string;
}

export interface GoogleAddressAutocompleteData {
    residentialAddress?: string;
    zip?: string;
    state?: string;
    city?: string;
}

export interface ErrorObj {
    service?: string;
    errorInfo?: {
        errorCode?: string;
        errorMessage?: string;
        exceptionName?: string;
        lob?: string;
        quoteNumber?: string;

    };
}
export interface UtmParams {
    utm_source?: string;
    utm_medium?: string;
    utm_campaign?: string;
    utm_content?: string;
    utm_term?: string;
}

export interface CampaignParams {
    cid?: string;
    clickkey?: string;
    fbclid?: string;
    gclid?: string;
    gclsrc?: string;
    mcid?: string;
    promoid?: string;
}

export interface SegmentCampaign {
    source?: string;
    medium?: string;
    name?: string;
    content?: string;
    term?: string;
}

export interface SegmentContext {
    campaign?: object;
    os?: object;
    traits?: object;
}

export interface FacebookContextTraits {
    address?: FacebookContextTraitsAddress;
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
}

export interface FacebookContextTraitsAddress {
    city?: string;
    postalCode?: string;
    state?: string;
}

export interface PolicyPerksContentMap {
    NC: PolicyPerksContent;
    COMMON: PolicyPerksContent;
}

export interface PolicyPerksContent {
    title1: string;
    message1: string;
    assetPath1: string;
    title2: string;
    message2: string;
    assetPath2: string;
}

export interface VehicleSaveActionsRequest {
    quoteNumber: string;
    mode: string;
    subscriptionAgreement: string;
    action: string[];
}

export interface CreditReportRequest {
    quoteNumber: string;
    state: string;
    creditReport: CreditReport;
    isFlexImpl: boolean;
}

export interface ConnectedCarRequest {
    quoteNumber: string;
    gWImplemented: boolean;
    landingStateCode: string;
}

export interface CreditReport {
    requestorLOB: string;
    UWCompanyName: string;
    applicants: Applicant[];
    customAttributes: CustomAttribute[];
}

export interface CustomAttribute {
    key: string;
    value: string;
}

export interface Applicant {
    type: string;
    personalInformation: PersonalInformation;
    fetchSSN: string;
}

export interface PersonalInformation {
    customerReferenceNumber: string;
    socialSecurityNumber: string;
    dateOfBirth: string;
    names: Names[];
    addresses: UserAddress[];
}

export interface Names {
    firstName: string;
    lastName: string;
}

export interface UserAddress {
    city: string;
    state: string;
    zip: string;
    houseNumber: string;
}
export interface CustomerInfo {
    lastName: string;
    email: string;
}

export interface AdpfCallReqObject {
    quoteNumber?: string;
    quotewindowId?: string;
    iidImpl?: boolean;
    adpfRetrieval?: boolean;
  }
/**
 * US377239: schedule agent confirmation changes
 */
export interface ScheduleAgentConfirmationData {
    agentName: string;
    scheduleDate: string;
    scheduleTime: string[];
}
/**
 * US373700 - trigger REST API call to send email to agent
 */
export interface ScheduleAgentAppointmentRequest {
    customerName: string;
    customerEmail: string;
    customerPhoneNumber: string;
    isFarmersCustomer: string;
    quoteNumber: string;
    scheduleDate: string;
    scheduleTime: string[];
    contactReason: string;
    fccDisclaimerContent: string;
}

export interface CarrierOjbect {
    currInsured: string;
    carrierInfo: { carrierName: string;
                    carrierCode: string;
                    bodilyInjury: string;
                    bodilyInjuryLimitName: string;
                    lapseInCvg: string;
                    lapseInCovdesc: string;
                    carrierExpiry: string;
                    militaryDeployment: string;
                    lapseInContinuousCoverage: string;
                    clearEncryptionInd: boolean;
                };
}
