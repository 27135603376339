import { EnvironmentConfig } from './environment.interface';

export function getEnvConfig(pathname: string): EnvironmentConfig {
    const url = decodeURIComponent(pathname);
    let region: string;
    if (url.includes('localhost')) {
        region = 'localhost';
    } else if (url.includes('stage')) {
        region = 'stage';
    } else {
        region = url.split('.')[0].split('/').pop();
    }
    switch (region) {
        case 'stage':
            return {
                endPoint: window[`stage_endPoint`] ? window[`stage_endPoint`] : 'https://esales-stage.farmers.com/auto-api/',
                hqm_end_point: window[`stage_hqm_end_point`] ? window[`stage_hqm_end_point`] : 'https://ehomesales-stage.farmers.com/',
                one_ui_url: window[`stage_one_ui_url`] ? window[`stage_one_ui_url`] : 'https://esales-stage.farmers.com/quote/',
            };
        case 'localhost':
            return {
                endPoint: 'https://ffqautouiuat.farmers.com/auto-api/',
                hqm_end_point: 'https://ffqhomeuiqa.farmers.com/',
                one_ui_url: 'https://ffqautouiqa.farmers.com/quote/',
            };
        default:
            return {
                endPoint: window[`endPoint`] ? window[`endPoint`] : 'https://ffqautouiuat.farmers.com/auto-api/',
                hqm_end_point: window[`hqm_end_point`] ? window[`hqm_end_point`] : 'https://ffqhomeuiqa.farmers.com/',
                one_ui_url: window[`one_ui_url`] ? window[`one_ui_url`] : 'https://ffqautouiqa.farmers.com/quote/',
            };
    }
}
