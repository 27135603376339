import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, OnDestroy, Inject } from '@angular/core';
import { FormGroup, FormBuilder, NgForm } from '@angular/forms';
import { LandingComponentValidation } from '@ffq-app-landing';
import { NavigationDrawerService } from '..';
import { YourInfoStoreService } from '@ffq-app-auto/your-info/store/your-info.store.service';
import { Subscription } from 'rxjs';
import { AppStoreService } from '@ffq-app-store/app.store.service';
import { take } from 'rxjs/operators';
import { DataConstants } from '@ffq-app-shared/constants/data.constants';
import { TitleCasePipe } from '@angular/common';
import { ServiceConstants } from '@ffq-app-shared/constants/service.constants';
import { HttpParams } from '@angular/common/http';
import { StateRuleModelService } from '@ffq-app-shared/services';
import { Router } from '@angular/router';
import { LegacyRedirectionService } from '@ffq-app-core/services/legacy.redirection.service';
import { SpinnerService } from '@ffq-lib/src/lib/spinner/spinner.service';
import { Title } from '@angular/platform-browser';
import { AutoStoreService } from '@ffq-app-auto/store/auto.store.service';
import { SegmentService } from '@ffq-app-shared/services/segment.service';
import { QuoteKnockedoutProps } from '@ffq-app-shared/model/segment.data.model';
import { AgentData } from '@ffq-app-shared/model/common.data.model';

@Component({
  selector: 'app-existing-policy',
  templateUrl: './existing-policy.component.html',
  styleUrls: ['./existing-policy.component.scss']
})
export class ExistingPolicyComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('legacyform', { static: true }) legacyformElement: ElementRef;

  landingQuoteParams: any;
  landingformGroup: FormGroup;
  legacyformGroup: FormGroup;
  landingValidation = new LandingComponentValidation();
  existingLob = 'A';
  crossSellStatus: boolean;
  landingStateCode: string;
  landingZipCode: string;
  quoteNumber: any;
  private subscriptions: Subscription[] = [];
  quoteType: string;
  existingCusReqObject = {} as ExistingCustomer;
  legacyURL: string;
  tempLob = [];
  autoToHomeCrossSell: boolean;
  noLifeLOBStates: boolean;
  projectCodes: any;
  lobList = [];
  isEEImpl: boolean;
  buttonAction: string;
  isNewKOScreenEnabled: any;
  pageTitle = 'Existing Customer page';
  encryptedData: string;
  isHQMSelected = false;
  agentPhoneNumber: string;
  sourcePhoneNumber: string;
  lastPageVisited: any;
  lastStepVisited: any;
  sourceIndicator: string;
  agentName: string;
  agentLocation: string;
  agentDetails = {} as AgentData;
  googleMaplocation: string;
  agentAssigned = false;
  agentPhoneNumberCC: string;
  teleMobile: string;
  mobileNo: string;
  desktopNo: string;
  tele: string;
  focusToAutoStartQuoteButton = false;

  constructor(
    private fb: FormBuilder,
    private navDrawer: NavigationDrawerService,
    private yourInfoStoreService: YourInfoStoreService,
    private appStoreService: AppStoreService,
    private autoStoreService: AutoStoreService,
    private titleCasePipe: TitleCasePipe,
    private stateRuleModel: StateRuleModelService,
    private router: Router,
    private legacyRedirectionService: LegacyRedirectionService,
    private spinnerService: SpinnerService,
    private titleService: Title,
    private segmentService: SegmentService) {
  }

  ngOnInit() {
    this.setTitle(this.pageTitle);
    /** subscription for landing state code */
    this.subscriptions.push(this.appStoreService.appDataZip$.pipe(take(1)).subscribe((data: any) => {
      this.landingStateCode = data[`landingStateCode`];
      this.quoteNumber = data[`quoteNumber`];
    }));

    /** Subscription for projectstatus code */
    this.subscriptions.push(this.appStoreService.getProjectCodeStatus$.subscribe((data: any) => {
      this.projectCodes = data;
      // US82453: EE MVP (KY) - Auto product for FFQ to block cross-sell Life and Business with existing policy - Starts
      this.isEEImpl = data[`isEEImpl`];
      // US82453: EE MVP (KY) - Auto product for FFQ to block cross-sell Life and Business with existing policy - Starts
      this.isNewKOScreenEnabled = data[`isNewKOScreenView`];
    }));

    /** Subscription for landing zip code */
    this.subscriptions.push(this.appStoreService.getLandingZipCode$.subscribe(zipCode => {
      this.landingZipCode = zipCode;
    }));

    // US285181 Removed unused code
    this.appStoreService.getLastVisitedStep$.subscribe(data => {
      this.lastStepVisited = data;
    }).unsubscribe();
    let autoCompanyCode = '';
    this.subscriptions.push(this.autoStoreService.getBrightLinedData$.subscribe((data: any) => {
      autoCompanyCode = data && data.autoCompanyCode ? data.autoCompanyCode : 'F';
    }));
    this.subscriptions.push(this.appStoreService.getLandingSourceFromSrc$.subscribe(data => {
      this.sourceIndicator = data;
    }));
    const knockoutInfo: QuoteKnockedoutProps = {
      knockout_code: 'EXISTCUST',
      knockout_msg: 'Existing customer',
      existing_lobs: this.existingLob,
      point_of_knockout: this.lastStepVisited,
      company_code: autoCompanyCode,
    };
    this.segmentService.trackQuoteKnockedout(knockoutInfo);

    /**  Subscription for crossell encrypt data */
    if (this.stateRuleModel.getQuoteModuleStateRule(this.landingStateCode).isHQMEnabled) {
      this.subscriptions.push(this.appStoreService.getCrossSellEncryptedData$.subscribe((encryptedData: any) => {
        this.encryptedData = encryptedData;
        if (this.isHQMSelected && null != encryptedData) {
          this.populateHqmCrossSellParams();
        }
      }));
    }

    this.navDrawer.hideDrawer();
    this.navDrawer.hideEverything();
    this.noLifeLOBStates = this.stateRuleModel.getNoLifeLOBStates(this.landingStateCode);
    this.listUnQuotedLOB(this.existingLob);

    this.buildFormLanding();
    this.landingQuoteParams = this.getlandingQuoteFormParameters();
    const params = new HttpParams()
      .set('windowID', localStorage.getItem('windowID'));
    this.legacyURL = ServiceConstants.LEGACY_URL + ServiceConstants.LEGACY_URL_COMMON + '?' + params;

    // US314819 Created separate method for setting phone number
    this.setAgentPhoneNumber();

    // US285181 Added code for fetching agent details
    this.fetchAgentDetails();
  }

  setAgentPhoneNumber(): void {
    this.appStoreService.getPhoneNumberFromSrc$.subscribe((phoneNumber: string) => {
      this.sourcePhoneNumber = phoneNumber;
    });
    this.agentPhoneNumber = this.sourcePhoneNumber ? this.sourcePhoneNumber :
      this.isEEImpl ? DataConstants.HELP_PHONE_NUMBER.isEEStateNumber : DataConstants.HELP_PHONE_NUMBER.nonEEStateNumber;
  }

  fetchAgentDetails(): void {
    this.subscriptions.push(
      this.appStoreService.appDataAgent$.subscribe((data: any) => {
        if (data.agent && (data.agent.agent || data.agent.agentInfoBean)) {
          this.agentDetails = data.agent.agent
            ? data.agent.agent
            : data.agent.agentInfoBean;
          if (this.agentDetails.reference === '1') {
            this.agentAssigned = true;
            if (this.agentDetails.firstName || this.agentDetails.lastName) {
              this.agentName =
                this.agentDetails.firstName + ' ' + this.agentDetails.lastName;
            }
            if (
              this.agentDetails.address.city ||
              this.agentDetails.address.state ||
              this.agentDetails.address.zip
            ) {
              this.agentLocation =
                this.agentDetails.address.city +
                ', ' +
                this.agentDetails.address.state +
                ' ' +
                this.agentDetails.address.zip;
            }
            if (
              this.agentDetails.communication.phoneNumber.phoneNumber &&
              this.agentDetails.communication.phoneNumber.phoneNumber !==
              '1-800-FARMERS'
            ) {
              this.agentPhoneNumber =
                this.agentDetails.communication.phoneNumber.phoneNumber.replace(
                  DataConstants.REGEX.agentPhoneNumberFormat,
                  '$1-$2-$3'
                );
              this.agentPhoneNumberCC = '1-'.concat(
                this.agentDetails.communication.phoneNumber.phoneNumber
              );
            }
            const googleLocquerry =
              this.agentDetails.address.street + ', ' + this.agentLocation;
            this.googleMaplocation =
              'http://maps.google.com/?q=' + googleLocquerry;
          } else if (this.agentDetails.reference === 'D') {
            this.agentAssigned = false;
          } else {
            this.agentAssigned = false;
          }
        }
      })
    );
  }


  filterLob(array, callback) {
    const result = [];
    for (let i = 0, l = array.length; i < l; i++) {
      if (this.existingLob.indexOf(array[i].lob) === -1) {
        result.push(array[i]);
      }
    }
    return result;
  }

  getOtherLob(exisitingLobs: string) {
    const filtered = this.filterLob(this.lobList, exisitingLobs)
      .map((element) => {
        return {
          lob: element.lob,
          label: element.label,
          value: element.value,
          gtmTag: `FFQ_Auto_existcustmodal_Typeofins${element.value}_dropdown`,
        };
      });
    return filtered;
  }

  getQuoteType() {
    const quoteTypeList = DataConstants.LOB;
    if (quoteTypeList && [this.landingformGroup.get('lob').value]) {
      return this.quoteType = quoteTypeList[this.landingformGroup.get('lob').value];
    }
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.spinnerService.hideLoader();
    });
    window.history.pushState({}, '', '/');
    window.history.pushState({}, '', '/fastquote/existing');
  }

  buildFormLanding(): any {
    this.landingformGroup = this.fb.group({
      lob: ['', this.landingValidation.lob.validators],
      quoteType: ['']
    });
    this.legacyformGroup = this.fb.group({
      crossselllobs: '',
      zip: '',
      flow: '',
      quotenumber: '',
      lobsquoted: ''
    });
  }

  getlandingQuoteFormParameters() {
    return {
      lob: {
        placeholder: 'Select type of insurance',
        id: 'lob',
        formGroup: this.landingformGroup,
        formControllName: 'lob',
        values: this.getOtherLob(this.existingLob)
      },
      landingZipCode: {
        placeholder: 'Zip Code',
        id: 'landingZipCode',
        formGroup: this.landingformGroup,
        formControllName: 'landingZipCode',
        readonly: false
      }
    };
  }

  populateHqmCrossSellParams() {
    this.legacyRedirectionService.redirectToHQM(this.encryptedData, 'CS');
  }

  callEncryptCrossSellData() {
    this.appStoreService.getEncryptedCrossSellData(this.quoteNumber);
  }

  startQuote() {
    this.landingformGroup.markAllAsTouched();
    if (this.landingformGroup.valid) {
      const lob = this.titleCasePipe.transform(this.landingformGroup.get('lob').value);
      if (lob === 'Home' && this.stateRuleModel.getQuoteModuleStateRule(this.landingStateCode).isHQMEnabled) {
        this.callEncryptCrossSellData();
        this.isHQMSelected = true;
      } else {
        if ((lob === 'Home' || lob === 'Renter' || lob === 'Condo') && this.landingStateCode === 'FL') {
          this.appStoreService.updateCrossSellStatus({ crossSellStatus: true });
          this.router.navigate(['..'], { queryParams: { LOB: this.getQuoteType(), Flow: 'CS' } });
        } else {
          const parameterCollection = [];
          const params0 = {};
          params0[`name`] = 'zip';
          params0[`value`] = this.landingZipCode;
          const params1 = {};
          params1[`name`] = 'flow';
          params1[`value`] = 'CS';
          const params2 = {};
          params2[`name`] = 'quotenumber';
          params2[`value`] = this.quoteNumber;
          const params3 = {};
          params3[`name`] = 'lobsquoted';
          params3[`value`] = this.existingLob;
          const params4 = {};
          params4[`name`] = 'crossselllobs';
          params4[`value`] = this.getQuoteType();
          if (params4[`value`] === 'L') {
            params4[`value`] = 'LC';
          }

          parameterCollection.push(params0);
          parameterCollection.push(params1);
          parameterCollection.push(params2);
          parameterCollection.push(params3);
          parameterCollection.push(params4);

          this.legacyRedirectionService.redirectToLegacy(parameterCollection);
        }
      }
    }
  }

  listUnQuotedLOB(lob: any) {
    let toolkitlob;
    if (lob.search('C') >= 0) {
      this.setUpdatedLobOptions('C');
    } else if (lob.search('H') >= 0) {
      this.setUpdatedLobOptions('H');
    } else if (lob.search('R') >= 0) {
      this.setUpdatedLobOptions('R');
    } else {
      this.setUpdatedLobOptions(lob);
    }
    let listLOB = this.tempLob;
    if (this.existingLob === 'H' && lob.search('A') >= 0 && lob.search('H') >= 0) {
      listLOB = [];
    }
    const displayLOB = [];
    if (this.existingLob === 'H' && this.autoToHomeCrossSell === true && this.existingLob && this.existingLob.indexOf('H') === -1) {
      lob = 'A';
    } else if (this.existingLob === 'H' && this.autoToHomeCrossSell === true && this.existingLob &&
      this.existingLob.indexOf('H') !== -1) {
      lob = 'HA';
    }
    if (listLOB && listLOB.length > 0) {
      for (const i of listLOB) {
        // const c = i.lob;
        let n = lob.search(i.lob);
        if (n >= 0) {
          if (i.lob === 'L' || i.lob === 'G' || i.lob === 'S'
            || i.lob === 'T' || i.lob === 'P') {
            n = this.checkLifeLob(lob, i.lob);
          }
        }
        if (n < 0) {
          displayLOB.push(i);
        }
      }
      if (this.noLifeLOBStates) {
        for (let displayLOBIndex = 0; displayLOBIndex < displayLOB.length; displayLOBIndex++) {
          if (displayLOB[displayLOBIndex].lob === 'L') {
            displayLOB.splice(displayLOBIndex, 1);
          }
        }
      }
      if (this.projectCodes !== null && this.projectCodes !== undefined && this.projectCodes.ttkCrossSellDisabledLobs !== null
        && this.projectCodes.ttkCrossSellDisabledLobs !== undefined) {
        toolkitlob = this.projectCodes.ttkCrossSellDisabledLobs.split('');
      }
      if (toolkitlob && toolkitlob.length > 0) {
        for (const i of toolkitlob) {
          for (let displayLOBIndex = 0; displayLOBIndex < displayLOB.length; displayLOBIndex++) {
            if (displayLOB[displayLOBIndex].lob === i) {
              displayLOB.splice(displayLOBIndex, 1);
            }
          }
        }
      }
      this.lobList = displayLOB;
    }
  }

  setUpdatedLobOptions(lobQuoted) {
    this.tempLob = DataConstants.ExistingCustomerCrossSellOptions;
    if (this.existingLob === 'H') {
      if (this.autoToHomeCrossSell === true) {
        this.tempLob = DataConstants.ExistingCustomerCrossSellOptions;
      } else {
        this.tempLob = this.tempLob.splice(0, 1);
      }
    }
    switch (lobQuoted) {
      case 'H':
      case 'C':
      case 'R':
        this.tempLob.forEach((value) => {
          if (value.value === 'home' ||
            value.value === 'condo' || value.value === 'renter') {
            value.lob = lobQuoted;
          }
        });
        break;
      default:
        this.tempLob = DataConstants.ExistingCustomerCrossSellOptions;
        break;
    }
  }

  checkLifeLob(quotedLob, lifeCode) {
    let n;
    let searchResult;
    const lifeLobs = ['L', 'G', 'S', 'T', 'P'];
    for (const i of lifeLobs) {
      n = quotedLob.search(lifeLobs[i]);
      if (n >= 0) {
        searchResult = 1;
      } else {
        searchResult = -1;
        break;
      }
    }
    return searchResult;
  }

  // US285181 Changed to single button for login or register
  custLoginOrReg(): void {
    // US295693 Segment call and changes for test case
    this.segmentService.trackCtaClicked('Login or create account button clicked');
    this.callLoginPage(ServiceConstants.LOGIN_REGISTER_URL.replace('&amp;', '&'));
  }

  callLoginPage(loginUrl: string): void {
    window.location.href = loginUrl;
  }

  onFocus(): void {
    this.focusToAutoStartQuoteButton = true;
  }

  onBlur(): void {
    this.focusToAutoStartQuoteButton = false;
  }

  findAnAgent(): void {
    window.location.href = ServiceConstants.FIND_AN_AGENT;
  }

  /**
   * on destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}

export interface ExistingCustomer {
  agentId: string;
  quoteType: string;
  landingZipCode: string;
  encryptedData: string;
  src: string;
  isEncrypted: boolean;
  quoteNumber: string;
  lastName: string;
  dateOfBirth: string;
  quoteWindowId: string;
}
