import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { DateValidatorService } from '@ffq-app-core/services/date-validator.service';
import { DataConstants } from '@ffq-app-shared/constants';
import { DateService } from '@ffq-lib/src/lib/core/services/date.service';

export class ScheduleAgentValidtion {

    firstName = {
        placeholder: 'First name*',
        id: 'firstName',
        formControllName: 'firstName',
        directiveValidators: 'stringHyphenField',
        maxLength: '15',
        type: 'text',
        ariaLabel: 'First name',
        validators: [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(15)
        ],
        messages: {
            required: DataConstants.AGENT_APPOINTMENT_ERROR_MESSAGES.FirstNameReq
        },
        gtmTag: 'FFQ_Auto_Agent_Appt_firstname_text'
    };

    lastName = {
        placeholder: 'Last name*',
        id: 'lastName',
        formControllName: 'lastName',
        type: 'text',
        directiveValidators: 'stringHyphenField',
        maxLength: '20',
        ariaLabel: 'Last name',
        validators: [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(15)
        ],
        messages: {
            required: DataConstants.AGENT_APPOINTMENT_ERROR_MESSAGES.LastNameReq
        },
        gtmTag: 'FFQ_Auto_Agent_Appt_lastname_text'
    };

    emailId = {
        placeholder: 'Email address*',
        id: 'emailId',
        formControllName: 'emailId',
        maxLength: '50',
        type: 'text',
        validators: [
            Validators.required,
            Validators.pattern(/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i),
        ],
        messages: {
            required: DataConstants.AGENT_APPOINTMENT_ERROR_MESSAGES.EmailReq,
            pattern: DataConstants.AGENT_APPOINTMENT_ERROR_MESSAGES.EmailPattern
        },
        ariaLabel: 'Email ID',
        gtmTag: 'FFQ_Auto_Agent_Appt_email_id_text'
    };

    phoneNumber = {
        placeholder: 'Phone number',
        id: 'phoneNumber',
        formControllName: 'phoneNumber',
        maxLength: '13',
        type: 'tel',
        validators: [
            Validators.pattern(/^\(+[^0-1$]\d\d\)+[^0-1$]\d\d\-?\d{4}?$/)

        ],
        messages: {
            pattern: DataConstants.AGENT_APPOINTMENT_ERROR_MESSAGES.PhoneNumPattern
        },
        ariaLabel: 'Phone number',
        gtmTag: 'FFQ_Auto_Agent_Appt_phonenumber_text'
    };

    date = {
        placeholder: 'Choose a date*',
        id: 'schedule-agent-date',
        formControllName: 'date',
        startDate: this.dateService.getDateFromYMDForSchdlAgent(null, null, (new Date().getDate() + 1)),
        ariaLabel: 'Appointment date in the format of mmddyyyy',
        ValidateFutureDate: true,
        openCalendarLabel: 'Calendar graphical icon to select your date of appointment in the format of mmddyyyy',
        minDate: '',
        maxDate: '',
        type: 'tel',
        gtmTag: 'FFQ_Auto_Agent_Appt_date',
        isDisabled: false,
        validators: [
            Validators.required,
            this.dateLimitsValidator()
        ],
        messages: {
            required: DataConstants.AGENT_APPOINTMENT_ERROR_MESSAGES.DateReq,
            matDatepickerMin: DataConstants.AGENT_APPOINTMENT_ERROR_MESSAGES.DateMin,
            matDatepickerMax: DataConstants.AGENT_APPOINTMENT_ERROR_MESSAGES.DateMax,
            invalidDate: DataConstants.AGENT_APPOINTMENT_ERROR_MESSAGES.DateMin
        },
    };

    time = {
        placeholder: 'Time*',
        id: 'time',
        classes: ['row-items-1', 'schedule-agent'],
        formControllName: 'time',
        values: [
            {label: 'Morning (9:00 AM - 12:00 PM)', value: 'Morning (9:00 AM - 12:00 PM)'},
            {label: 'Afternoon (12:00 PM - 4:00 PM)', value: 'Afternoon (12:00 PM - 4:00 PM)'},
            {label: 'Evening (4:00 PM - 7:00 PM)', value: 'Evening (4:00 PM - 7:00 PM)'}
        ],
        validators: [
            Validators.required,
        ],
        messages: {
            required: DataConstants.AGENT_APPOINTMENT_ERROR_MESSAGES.TimeReq
        },
        ariaLabel: 'Time of appointment',
        gtmTag: 'FFQ_Auto_Agent_Appt_time'
    };

    existingFarmCust = {
        id: 'schedAgentExistingFarmCust',
        formControllName: 'existingFarmCust',
        focus: true,
        classes: ['schedule-agent-radio'],
        values: [
            { label: 'Yes', value: 'Yes', gtmTag: 'FFQ_Auto_Schedule_Agent_Appt_existing_cust_yes_radio' },
            { label: 'No', value: 'No', gtmTag: 'FFQ_Auto_Schedule_Agent_Appt_existing_cust_no_radio' }
        ],
        validators: [
        ],
        messages: {
        },
        ariaLabel: 'Existing customer question',
        gtmTag: 'FFQ_Auto_Agent_Appt_existing_cust_button'
    };

    reason = {
        placeholder: 'Please select',
        id: 'reason',
        formControllName: 'reason',
        values: [
            { label: 'New policy', value: 'New policy' },
            { label: 'Existing policy', value: 'Existing policy' },
            { label: 'Claims', value: 'Claims' },
            { label: 'Payment', value: 'Payment' },
            { label: 'General question', value: 'General question' }
        ],
        validators: [
            Validators.required,
        ],
        messages: {
            required: DataConstants.AGENT_APPOINTMENT_ERROR_MESSAGES.ReasonReq
        },
        ariaLabel: 'Reason for appointment',
        gtmTag: 'FFQ_Auto_Agent_Appt_reason'
    };

    quoteNumber = {
        placeholder: 'Quote number',
        id: 'quoteNumber',
        formControllName: 'quoteNumber',
        validators: [
            Validators.required,
        ],
        messages: {
            required: DataConstants.AGENT_APPOINTMENT_ERROR_MESSAGES.QuoteReq
        },
        ariaLabel: 'Quote Number',
        gtmTag: 'FFQ_Auto_Agent_Appt_quotenumber_text'
    };

    constructor(private dateValidatorService: DateValidatorService,
                private dateService: DateService) { }

    dateLimitsValidator(): ValidatorFn {
        const minDate = this.dateService.getDateFromYMDForSchdlAgent(null, null, (new Date().getDate() + 1));
        const month = new Date().getMonth();
        const maxDate = new Date(new Date().setMonth(month + 1));
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            return this.dateValidatorService.validateDateUsingMinMaxValues(control.value, minDate, maxDate);
        };
    }

}


