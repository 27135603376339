<div role="dialog"  aria-describedby="infoDialog"  >
    <h2 *ngIf="dialogTitle" mat-dialog-title class="dialog_info-dialog-title">{{dialogTitle}}</h2>
    
    <div *ngIf="color; else infoDialog" id="infoDialog"  class="infoDialogContent" class="dialog_info-dialog-content" [innerHTML]="dialogContent"></div>
    <ng-template #infoDialog>
    <div  id="infoDialog"  class="infoDialogContent" class="dialog_info-dialog-content" >{{dialogContent}}</div>
    </ng-template>
    
    <mat-dialog-actions align="end" >
        <button mat-button (click)="dialogActionSelected(cancelDialogAction)" class="dialog__info-dialog-button-close" (keypress)="dialogActionSelected(cancelDialogAction)" attr.data-GTM="{{cancelGTM}}">{{cancelButtonText}}</button>
        <button mat-button (click)="dialogActionSelected(acceptDialogAction)" class="dialog__info-dialog-button-close" (keypress)="dialogActionSelected(acceptDialogAction)" attr.data-GTM="{{confirmGTM}}">{{confirmButtonText}}</button>
    </mat-dialog-actions>
    </div>