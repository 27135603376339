import { Component, OnInit } from '@angular/core';
import { SpinnerService } from '@ffq-lib/src/lib/spinner/spinner.service';

@Component({
  selector: 'app-subscription-agreement',
  templateUrl: './subscription-agreement.component.html',
  styleUrls: ['./subscription-agreement.component.scss']
})
export class SubscriptionAgreementComponent implements OnInit {
  disclaimer1: string;
  disclaimer2: string;

  constructor(
    private spinnerService: SpinnerService,
  ) { }

  ngOnInit(): void {
    this.spinnerService.hideLoader();
    // US197716: EE MVP (NC) - Auto product Subscription agreement requirements for the "Please tell us..." screen
    this.setStaticContent();
  }

  setStaticContent() {
    this.disclaimer1 = 'Truck Insurance Exchange (\"Exchange\") is an inter-insurance exchange or reciprocal insurer. ' +
    'The Exchange is owned by its members (also called subscribers), and the members appoint a third party, called the' +
     ' attorney-in-fact, to conduct certain administrative services for and on behalf of the Exchange.\n\n Please sign the Subscription' +
     ' Agreement printed below to become a member of the Exchange, which is necessary to maintain coverage. Under the Subscription' +
      ' Agreement, you will be appointing Truck Underwriters Association ("Association") to serve and act as the attorney-in-fact. The ' +
      'Association has acted in this capacity since 1935 . The Subscription Agreement provides for payment of compensation to ' +
      'the Association for its becoming and acting as attorney-in-fact. This compensation consists of a membership fee and a percentage ' +
    ' of premiums on all policies of insurance or reinsurance issued or effected by the Exchange. These fees are included in your policy ' +
      'payment and are not an additional fee.\n\n If our records do not show that you have provided us with a signed copy of the ' +
      'Subscription Agreement, we reserve the right to terminate your coverage.';
    this.disclaimer2 = 'For and in consideration of the benefits to be derived therefrom the subscriber covenants ' +
    ' and agrees with Truck Insurance Exchange and other subscribers thereto through their and each ' +
    ' of their attorney-in-fact, Truck Underwriters Association, to exchange with all other subscribers\' policies ' +
    ' of insurance or reinsurance containing such terms and conditions therein as may be specified by said attorney-in-fact ' +
    ' and approved by the Board of Governors or its Executive Committee for any loss insured against, and ' +
    ' subscriber hereby designates, constitutes and appoints Truck Underwriters Association to be attorney-in-fact ' +
    ' for subscriber, granting to it power to substitute another in its place, and in subscriber\'s name, place ' +
    ' and stead to do all things which the subscriber or subscribers might or could do severally or jointly with reference ' +
    ' to all policies issued, including cancellation thereof, collection and receipt of all monies due the Exchange from ' +
    ' whatever source and disbursement of all loss and expense payments, effect reinsurance and all ' +
    ' other acts incidental to the management of the Exchange and the business of interinsurance; subscriber further ' +
    ' agrees that there shall be paid to said Association, as compensation for its becoming and acting as attorney-in-fact, ' +
    ' the membership fees and twenty per centum of the Premium Deposit for the insurance provided and twenty per centum' +
    ' of the premiums required for continuance thereof.\n\n The remaining portion of the Premium Deposit and of additional term ' +
    ' payments made by or on behalf of the subscriber shall be applied to the payment of losses and expenses ' +
    ' and to the establishment of reserves and general surplus. Such reserves and surplus may be invested and reinvested by a Board ' +
    ' of Governors duly elected by and from subscribers in accordance with provisions of policies issued, ' +
    ' which Board or its Executive Committee or an agent or agency appointed by written authority of said Executive ' +
    ' Committee shall have full powers to negotiate purchases, sales, trades, exchanges, and transfers of investments, ' +
    ' properties, titles and securities, together with full powers to execute all necessary instruments. The ' +
    ' expenses above referred to shall include all taxes, license fees, attorneys\' fees and adjustment expenses and ' +
    ' charges, expenses of members\' and governors\' meetings, agents\' commissions, and such other ' +
    ' specified fees, dues and expenses as may be authorized by the Board of Governors. All other expenses incurred in connection ' +
    ' with the conduct of the Exchange and such of the above expenses as shall from time to time be agreed ' +
    ' upon by and between the Association and the Board of Governors or its Executive Committee shall be ' +
    ' borne by the Association.\n\n The principal office of the Exchange and its attorney-in-fact shall be maintained ' +
    ' in the City of Los Angeles, County of Los Angeles, State of California.\n\n This agreement can be signed upon any number ' +
    ' of counterparts with the same effect as if the signatures of all subscribers were upon one and the same instrument, and shall be ' +
    ' binding upon the parties thereto, severally and ratably as provided in policies issued. Wherever the word ' +
    ' \"subscriber\" is used the same shall mean members of the Exchange, the subscriber hereto, and all other subscribers to this ' +
    ' or any other like agreement. Any policy issued hereon shall be non-assessable.\n\n I have read the Subscription Agreement. ' +
    ' I agree to be bound to all of the terms and conditions of the Subscription Agreement.';
  }
}
