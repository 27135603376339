<div class="multi-quote-container">
  <h1 id="auto-module_header-title" title = 'Retrieve a saved Quote'>Retrieve a Saved Quote</h1>
  <p class="auto-module__multi-quote-text">Select a saved quote below to continue</p>
  <p class="auto-module__multi-quote-text-hidden" id="ffq-auto-module__multi-quote-text-hidden" tabindex="0" role="dialog">
    Select a saved quote from the table below to continue</p>
  <div class="multi-quote-table">
    <mat-table  [dataSource]="dataSource" aria-label="Saved Quotes">
      <ng-container matColumnDef="quoteNumber">
        <mat-header-cell *matHeaderCellDef>QUOTE NUMBER </mat-header-cell>
        <mat-cell *matCellDef="let element" class = "quote-number-text"> {{element.quoteNumber}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef>POLICY TYPE </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{getLob(element.lob,element.lobDesc) || '-'}}  </mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef> QUOTE DATE </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.quoteUpdateTmstmp}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef>  </mat-header-cell>
        <mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
          <button tabindex="0" mat-button class="flex_align_next_button ffq-card-button-blue multi-quote-button-blue"
          (click)="callReviewRetrieveQuote(element)"  [title]="'Review quote with quote number ' + element.quoteNumber">
            Review Quote </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="callReviewRetrieveQuote(row)" ></mat-row>
    </mat-table>
  </div>
  <div class="ffq-landing-disclaimer">
    <p> <span>These quotes will be available for up to 120 days. Quote prices are subject to change.</span></p>
    <p><span>The preliminary rate quote is an estimate based upon the information you entered, the coverages you
        selected and certain assumptions. A quote is not a contract, binder, or agreement to extend insurance coverage.
        The coverage descriptions provided are general descriptions of available coverage and are not a statement of
        contract. Rates quoted reflect the rates in effect as of the date of this quote and are subject to revision.
        Your actual premium may be higher or lower based upon additional information you provide or we obtain, the
        coverages and deductibles that you select, any additional underwriting criteria, and removal of any assumptions.
        We reserve the right to accept, reject, or modify this quote after review of the application and other
        underwriting information. All applications are subject to underwriting approval. Coverages and availability may
        vary by state. </span></p>
    <p><span>If you would like to speak to a Farmers® representative immediately, call 1-800-FARMERS (<a href="tel:1-800-327-6377">1-800-327-6377</a>).
        If you’re an employee or member of a group with access to the Farmers GroupSelect<sup>&#8480;</sup> program, please call
        <a href="tel:1-844-296-5747">1-844-296-5747</a>.</span></p>

  </div>

  <div class="ffq-lading-footer-block">
    <div class="ffq-landing-footer-headers">
        <li>
            <a class = "ffq-landing-footer-links" href="http://www.farmers.com/disclaimer/privacy-policy/" target="_blank" aria-label="Privacy Policy opens in new window" role="link" data-GTM="FFQ_Auto_sidepanel_privacypolicy_link" title="Privacy policy">Privacy policy |</a>
        </li>
        <li>
            <a class = "ffq-landing-footer-links" href="https://www.farmers.com/disclaimer/terms-of-use/" target="_blank" aria-label="Terms of Use opens in new window" role ="link" data-GTM="FFQ_Auto_sidepanel_termsofuse_link" title="Terms of use">Terms of use |</a>
        </li>
        <li>
            <a class = "ffq-landing-footer-links" href="http://www.farmers.com/contact-us/" target="_blank" aria-label="Contact us opens in new window" role="link" data-GTM="FFQ_Auto_sidepanel_contactus_link" title="Contact us">Contact us</a>
    </div>
</div>

</div>