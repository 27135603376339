import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SaveAndFinishValidation } from '@ffq-app-shared/model/save-and-finish.validation';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppStoreService } from '@ffq-app-store/app.store.service';
import { Subscription } from 'rxjs';
import { DataConstants } from '@ffq-app-shared/constants/data.constants';
import { SaveAndFinishLaterObject } from '@ffq-app-auto/drivers';
import { NavigationDrawerService } from '../navigation-drawer/navigation-drawer.service';
import { DialogData } from '@ffq-lib/src/lib/dialog';
@Component({
  selector: 'app-save-and-finish-on-timeout',
  templateUrl: './save-and-finish-on-timeout.component.html',
  styleUrls: ['./save-and-finish-on-timeout.component.scss']
})
export class SaveAndFinishOnTimeoutComponent implements OnInit {
  /**
   * Save and finish form group of save and finish component
   */
  saveAndFinishOnTimeOutFormGroup: FormGroup;
  /**
   * Save and finish params of save and finish component
   */
  saveAndFinishOnTimeOutParams = new SaveAndFinishValidation();
  /**
   * Quote number of save and finish component
   */
  public quoteNumber: any;
  /**
   * App data of save and finish component
   */
  public appData: any;
  /**
   * App data of save and finish component
   */
  public lastVisitedPage: any;
  /**
   * Email entered of save and finish component
   */
  emailEntered: any;
  /**
   * Determines whether email view required is
   */
  isEmailViewRequired = true;
  /**
   * Save and finish later of save and finish component
   */
  saveAndFinishLater: any;
  /**
   * Button text1 of save and finish component
   */
  buttonText1: any;
  /**
   * Button text2 of save and finish component
   */
  buttonText2: any;
  /**
   * Subscriptions  of save and finish component
   */
  private subscriptions: Subscription[] = [];
  /**
   * Save and finish dialog title of save and finish component
   */
  saveAndFinishDialogTitle: string;
  /**
   * Save and finish dialog content of save and finish component
   */
  saveAndFinishDialogContent: string;
  /**
   * Save and finish popup data of save and finish component
   */
  saveAndFinishPopupData: any;
  /**
   * Save and finish later object of save and finish component
   */
  saveAndFinishLaterObject = {} as SaveAndFinishLaterObject;
  isTimeOut = false;
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialog,
    private appStoreService: AppStoreService,
    private navigationDrawerService: NavigationDrawerService,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData
  ) {
    this.subscriptions.push(this.appStoreService.appData$.subscribe((data) => {
      this.appData = data;
    }));
    this.appStoreService.appControlData$.subscribe((data) => {
      this.lastVisitedPage = data.lastVisitedPage;
    });
  }

  ngOnInit() {
    this.buildForm();
    this.quoteNumber = this.appData.quoteNumber;
    if (this.appData.customerData) {
      if (!this.appData.customerData.customer.communication.emailAddress) {
        this.isEmailViewRequired = true;
      } else {
        this.isEmailViewRequired = false;
      }
    }
    if (this.dialogData.dialogTitle === 'TimeOut Expired') {
      this.isTimeOut = true;
    }
    this.saveAndFinishPopupData = DataConstants.IdleTimeoutContent;
    this.saveAndFinishDialogTitle = this.saveAndFinishPopupData.maintitle;
    this.saveAndFinishDialogContent = this.saveAndFinishPopupData.subContent;
    this.buttonText1 = this.saveAndFinishPopupData.buttonText1;
    this.buttonText2 = this.saveAndFinishPopupData.buttonText2;
  }
  /**
   * Builds form
   * @returns form
   */
  buildForm(): any {
    this.saveAndFinishOnTimeOutFormGroup = this.fb.group({
      emailAddress: ['', this.saveAndFinishOnTimeOutParams.emailAddress.validators]
    });

    for (const field of Object.keys(this.saveAndFinishOnTimeOutFormGroup.controls)) {
      this.saveAndFinishOnTimeOutParams[field][`formGroup`] = this.saveAndFinishOnTimeOutFormGroup;
    }
  }

  /**
   * Saves and exit
   */
  saveAndExit() {
    if (this.lastVisitedPage !== 'YourInfo' && !this.isTimeOut) {
      this.emailEntered = this.saveAndFinishOnTimeOutFormGroup.get('emailAddress').value;
      this.saveAndFinishLaterObject = this.navigationDrawerService.createRequestPayload(this.emailEntered);
      this.appStoreService.saveAndFinishLater(this.saveAndFinishLaterObject);
      /* Subscription for Save And Finish */
      this.subscriptions.push(this.appStoreService.saveAndFinishLaterCtrlData$.subscribe(saveAndFinishLater => {
        this.saveAndFinishLater = saveAndFinishLater;
        if (this.saveAndFinishLater === 'success' || this.saveAndFinishLater === 'fail') {
          window.location.replace('/');
        }
      }));
    } else {
      window.location.replace('/');
    }
  }
}
