import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromQuoteState from './quote.state';
import * as fromAutoSelectors from '@ffq-app-auto/store/auto.selectors';
import * as fromAppSelectors from '@ffq-app-store/app.selectors';

const getQuoteSelector = createFeatureSelector<fromQuoteState.QuoteState>(
    'quote'
  );

// To fetch drop down list values
export const getfetchDropdownList = createSelector(
  getQuoteSelector,
  (state) => {
    return state && state.data && state.data.fetchCoverageDropDownList ? state.data.fetchCoverageDropDownList.dropDownResponseBeans : null;
  });

/* selector for redirection flow for triggering perform rate in quote */
export const getFlowControlData = createSelector(
  fromAppSelectors.getFlowDataForQuote,
  fromAppSelectors.getDefaultRateQuoteCalled,
  (flowDataForQuote, defaultRateQuoteCalled) => {
      return {
        flow: flowDataForQuote,
        defaultRateQuoteCalled
      };
  },
);

/* selector for identifying the fetchDropDownList service status */
export const getFetchDropDownServiceStatus = createSelector(
  getQuoteSelector,
  state => state && state.controlData &&  state.controlData.fetchDropDownList ?
  state.controlData.fetchDropDownList : null
);

/* selector for identifying the coverage update status */
export const getCoverageUpdateStatus = createSelector(
  getQuoteSelector,
  state => state && state.controlData &&  state.controlData.coverageUpdated ?
  state.controlData.coverageUpdated : null
);

/* selector for identifying the change in uninsured motorist */
export const getUninsuredMotoristValue = createSelector(
  getQuoteSelector,
  state => state && state.controlData &&  state.controlData.uninsuredMotorist ?
  state.controlData.uninsuredMotorist : null
);

/* selector for identifying the change in underinsured motorist */
export const getUnderinsuredMotoristValue = createSelector(
  getQuoteSelector,
  state => state && state.controlData &&  state.controlData.underinsuredMotorist ?
  state.controlData.underinsuredMotorist : null
);

/* selector for identifying the change in PIP coverage */
export const getPIPCoverageValue = createSelector(
  getQuoteSelector,
  state => state && state.controlData &&  state.controlData.PIPCoverage ?
  state.controlData.PIPCoverage : null
);

/* selector for identifying the change in propertyDamage */
export const getPropertyDamageValue = createSelector(
  getQuoteSelector,
  state => state && state.controlData &&  state.controlData.propertyDamage ?
  state.controlData.propertyDamage : null
);

/* selector for identifying the change in propertyDamage */
export const getBodilyInjury = createSelector(
  getQuoteSelector,
  state => state && state.controlData &&  state.controlData.bodilyInjury ?
  state.controlData.bodilyInjury : null
);

/** selector for fetching errors from quote store */
export const getQuoteErrors = createSelector(
  getQuoteSelector,
  state => state && state.error ?  state.error : null
);

/** selector for customRateCoverages from quote store */
export const getCustomRateCoverages = createSelector(
  getQuoteSelector,
  state => state && state.data && state.data.customRateCoverages ?  state.data.customRateCoverages : null
);

/** selector for hasErrorInLiability from quote control data */
export const getHasErrorInLiability = createSelector(
  getQuoteSelector,
  state => state && state.controlData && state.controlData.hasErrorInLiability ?  state.controlData.hasErrorInLiability : null
);

/* */
export const getQuoteButtonText = createSelector(
  getQuoteSelector,
  state => state && state.controlData && state.controlData.quoteButtonText ?  state.controlData.quoteButtonText : 'CONTINUE'
);

/** selector for hasErrorInVehicle from quote control data */
export const getHasErrorInVehicle = createSelector(
  getQuoteSelector,
  state => state && state.controlData && state.controlData.hasErrorInVehicle ?  state.controlData.hasErrorInVehicle : null
);

/** selector for controlling the custom rate service */
export const getCovErrorStatusForCustomRate = createSelector(
  getHasErrorInLiability,
  getHasErrorInVehicle,
  (hasErrorInLiability, hasErrorInVehicle) => {
    return {
      hasErrorInLiability,
      hasErrorInVehicle
    };
  }
);

/** selector for changed coverage code/label in liablilty from quote control data */
export const getCurrentChangedLiabilityCvg = createSelector(
  getQuoteSelector,
  state => state && state.controlData && state.controlData.currentChangedLiabilityCvg ?  state.controlData.currentChangedLiabilityCvg : null
);


/* selector for BW info messages in custom quote */
export const getCustomRateInfoMsgs = createSelector(
  fromAppSelectors.getQuotePackageSelected,
  fromAutoSelectors.getCustomRateInfoMsgs,
  (packageSelected, infoMessages) => {
    if (infoMessages && infoMessages.length !== 0) {
      const bwInfoMessages = [];
      infoMessages.forEach( infoMsg => {
        bwInfoMessages.push(infoMsg.description);
      });
      if (packageSelected && packageSelected === 'C' && bwInfoMessages && bwInfoMessages.length > 0) {
        return {
          bWRateAdjustMsgEnable: true,
          bwInfoMessages,
        };
      } else {
        return {
          bWRateAdjustMsgEnable: false,
          bwInfoMessages: [],
        };
      }
    }
  },
);

/* selector for liability coverage and vehicle coverage for print */
export const getCoverageForPrint = createSelector(
  getQuoteSelector,
  state => {
    if (state && state.controlData) {
      return {liability : state.controlData.liabForPrint , vehicleCoverage : state.controlData.vehCoverageForPrint};
    }
  }
);

// GW3 changes - US105406 UMBI, UMPD and Guest PIP coverage limits when  Rideshare coverage is selected for MD state - start
/* selector for identifying the change in vehilce coverage */
export const getCurrentChangedVehCvg = createSelector(
  getQuoteSelector,
  state => state && state.controlData &&  state.controlData.currentChangedVehCvg ?
  state.controlData.currentChangedVehCvg : null
);

/** selector for custom vehicle coverage from quote store */
export const getVehCustomRateCoverages = createSelector(
  getQuoteSelector,
  state => state && state.data && state.data.customRateCoverages && state.data.customRateCoverages.vehicle ?
  state.data.customRateCoverages.vehicle : null
);
 // GW3 changes - US105406 UMBI, UMPD and Guest PIP coverage limits when  Rideshare coverage is selected for MD state - end

/** selector for custom liability coverage from quote store */
export const getLiabilityCustomRateCoverages = createSelector(
  getQuoteSelector,
  state => state && state.data && state.data.customRateCoverages && state.data.customRateCoverages.liabilityCoverage ?
  state.data.customRateCoverages.liabilityCoverage : null
);


