import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'lib-card-rideshare-assignment',
  templateUrl: './card-rideshare-assignment.component.html',
  styleUrls: ['./card-rideshare-assignment.component.scss']
})
export class CardRideshareAssignmentComponent implements OnInit {
  @Input() params: any;
  driverList;
  vehcilesList;

  constructor() {}

  ngOnInit() {
    this.driverList = this.params.driverlist;
    this.vehcilesList = this.params.vehiclesList;
  }
}
