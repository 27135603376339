<ng-container [formGroup]="params.formGroup">
    <section [ngClass]="params.classes ? params.classes : null" class="ffq-lib__checkbox-conatiner">
        <ng-container *ngFor="let option of params.values">
            <div class="ffq-lib-checkbox" *ngIf="!option.isHidden" [ngClass]="option.isGreyedOut ? 'option_greyed' : ''">
                <mat-checkbox  #search  aria-label="{{!params.nolabel ? option.label : option.ariaLabel}}" [value]="option.value" (change)="onCheckChange(option,$event)" 
                    color="primary"
                    [disabled]="option.isDisabled"
                    [attr.data-GTM]="option.gtmTag"
                    [checked]="params.formGroup.get(this.params.formControllName).value.indexOf(option.value)>=0"  (focusout)="blurHandle(option,params)">
                    <img *ngIf="option.showIcon" class="ffq-lib-checkbox-icon" [src]="option.iconPath" alt=""/>
                    <span [ngClass]="option.showIcon ? 'ffq-lib-checkbox-label' :''" aria-hidden="true">  {{ option.label }} </span> <a  tabindex="0" (keydown.enter)="showDialogInfo(option.info, $event)" style="pointer-events: all;" *ngIf="option.info" attr.aria-label="{{option.info.title}} info icon opens a popup/ modal" class="info-icon" (click)="showDialogInfo(option.info, $event)" role="button"></a>
                </mat-checkbox>
            </div>
        </ng-container>
    </section>
    <mat-error class="error-text">
        <ng-container
            *ngIf="((params.formGroup).get(params.formControllName)).touched || ((params.formGroup).get(params.formControllName)).dirty">
            <ng-container *ngIf="params.messages">
                <ng-container *ngFor="let key of Object.keys(params.messages)">
                    <ng-container *ngIf="((params.formGroup).get(params.formControllName)).hasError(key)">
                        {{ params.messages[key] }}</ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
    </mat-error>
</ng-container>