
    <div [ngClass]="(quoteType==='H' || quoteType==='R' || quoteType==='C') ? 'agent-details-container-home':'agent-details-container'">

        <div [ngClass]="(!agentAssigned) && (showAsStrip==false) ? 'bg_PoliticalAgent':''">
            <div *ngIf=!agentAssigned class="agent-detail-row">
                <div *ngIf="showAsStrip==false" class="agent-detail-row">
                    <div *ngIf="!isBwQuote" aria-label="Need help? A Farmers Insurance® Representative is available if you have any questions.">
                    <div class="agent-detail-content">
                        <div aria-hidden = "true" class="need-help-caption"> Need help?</div>
                        <p aria-hidden="true" class="caption">
                            A Farmers Insurance® Representative is available if you have any questions.</p>
                    </div>
                    </div>
                    <div *ngIf="isBwQuote" aria-label="Need help? A Bristol West® Representative is available if you have any questions.">
                        <div class="agent-detail-content">
                            <div aria-hidden = "true" class="need-help-caption"> Need help?</div>
                            <p aria-hidden="true" class="caption">
                                A Bristol West® Representative is available if you have any questions.</p>
                        </div>
                    </div>
                </div>
                <span [attr.aria-hidden]="hideDesktopLabel">
                    <a href={{teleDesktop}}  tabindex="0"  [attr.aria-label]=" 'Call to farmers representative @ '+ ariaLabelPhoneNumber " class= "agent_phone">
                        <div>
                            <div class="agent-detail-row desktop">
                                <div class="no-agent-detail-icon"  >
                                    <img src="../../../../assets/images/local-phone.png" alt = ""/>
                                </div>
                                <div *ngIf="showAsStrip==false" class="agent-detail-content">
                                    <div class="agent-detail-phone">{{desktopNo}}</div>
                                </div>
                            </div>
                        </div>
                    </a>
                </span>
    
               
                    <div class="agent-detail-row mobile" tabindex="0" [attr.aria-label]=" 'Call to farmers representative @ '+ mobileNo ">
                        <a href={{teleMobile}}  class= "agent_phone" tabindex="-1">
                        <div class="no-agent-detail-icon">
                            <img src="../../../../assets/images/local-phone.png"  alt = ""/>
                        </div>
                        <div *ngIf="showAsStrip==false" class="agent-detail-content">
                            <div class="agent-detail-phone">{{mobileNo}}</div>
                        </div>
                    </a>
                    </div>
               
            </div>
            <div *ngIf=agentAssigned>
                <div  class="agent-info-row">
                <ng-container *ngIf="agentDetails?.agentImageUrl ; else default" class="agent-detail-row">
                    <div class="agent-detail-icon" >
                        <img class="agent-image" src="{{agentDetails.agentImageUrl}}" alt="" onerror="this.src='../../../../assets/images/agent-default-img.png';"/>
                    </div>
                </ng-container>
                <ng-template #default>
                    <div class="agent-detail-icon">
                        <img class="agent-image" src="../../../../assets/images/agent-default-img.png" alt="" />
                    </div>
                </ng-template>
                <div *ngIf="agentDetails?.firstName" class="agent-detail-row">
                    <div *ngIf="showAsStrip==false" class="agent-detail-content">
                        <span *ngIf="!isBwQuote" attr.aria-label = "{{agentName}} Your local Farmers Insurance® Agent is available if you have any questions.">
                        <div class="agent-detail-name" aria-hidden="true">{{agentName}}</div>
                         <div class="agent-detail-text" aria-hidden="true">Your local Farmers Insurance® Agent is
                            available if you have any questions. </div> 
                         </span>
                         <span *ngIf="isBwQuote" attr.aria-label = "{{agentName}} Your agent is available if you have any questions.">
                            <div class="agent-detail-name" aria-hidden="true">{{agentName}}</div>
                             <div class="agent-detail-text" aria-hidden="true">Your agent is available if you have any<br role="presentation"/>questions. </div> 
                        </span>
                    </div>
                </div>
            </div>
    
            <div class="agent-detail-email-row" >
                <a href="mailto:{{agentDetails.communication.emailId.emailAddress}}" tabindex="0" role="link"> 
                <div *ngIf="agentDetails?.communication?.emailId?.emailAddress" class="agent-detail-row" id ="email-align">
                     <div class="agent-detail-icon">
                        <img src="../../../../assets/images/email.png" alt="" />
                    </div>
                    <div *ngIf="showAsStrip==false" class="agent-detail-content">
                        <div class="agent-detail-email"  >{{agentDetails.communication.emailId.emailAddress}}</div>
                    </div>
                </div></a>
            </div>
               <div class="agent-detail-phonenumber-row" >
                <a role="link" tabindex="0" href="tel:{{agentPhoneNumberCC}}" [attr.aria-label]=" 'Call to farmers representative @ '+ agentPhoneNumber "  class= "agent_phone">
                <div *ngIf="agentDetails?.communication?.phoneNumber?.phoneNumber && agentPhoneNumber; else noAgentPhoneNumberDesktop"
                    class="agent-detail-row desktop" >
                    
                        <div class="agent-detail-icon">
                            <img src="../../../../assets/images/local-phone.png" alt = "" />
                        </div>
                        <div *ngIf="showAsStrip==false" class="agent-detail-content">
                            <div class="agent-detail-phone">{{agentPhoneNumber}}</div>
                        </div>
                   
                </div>
                 </a>
                </div>
    
                <ng-template #noAgentPhoneNumberDesktop>
                    <a role="link" href={{teleDesktop}}   attr.aria-label=
                    "Call to farmers representative @ {{desktopNo}}" tabindex="0"
                    class= "agent-detail-row desktop agent_phone">
                        <div class="no-agent-detail-icon">
                            <img src="../../../../assets/images/local-phone.png" alt = "" />
                        </div>
                        <div *ngIf="showAsStrip==false" class="agent-detail-content">
                            <div class="agent-detail-phone">{{desktopNo}}</div>
                        </div>
                    </a>
                </ng-template>
                
                <div *ngIf="agentDetails?.communication?.phoneNumber?.phoneNumber && agentPhoneNumber; else noAgentPhoneNumberMobile"
                    class="agent-detail-row mobile"  aria-label =" 'Call to farmers representative @ '+ agentPhoneNumber " >
                    <a role="link"  href="tel:{{agentPhoneNumberCC}}"  class= "agent_phone" tabindex="0" >                
                        <div class="agent-detail-icon">
                            <img src="../../../../assets/images/local-phone.png" alt="" />
                        </div>
                        <div *ngIf="showAsStrip==false" class="agent-detail-content">
                            <div class="agent-detail-phone">                        
                                    {{agentPhoneNumber}}
                            </div>
                        </div>                
                    </a>
                </div>
    
                <ng-template #noAgentPhoneNumberMobile>
                    <a role="link" href={{teleMobile}} tabindex="-1"
                        class= "agent-detail-row mobile agent_phone">                
                        <div class="no-agent-detail-icon">
                            <img src="../../../../assets/images/local-phone.png" alt="" />
                        </div>
                        <div *ngIf="showAsStrip==false" class="agent-detail-content">
                            <div class="agent-detail-phone">                    
                                {{mobileNo}}
                            </div>
                        </div>                
                    </a>
                </ng-template>
    <div class="agent-row-location">
        <a tabindex="0" class="agent-location-link" [href]=googleMaplocation target="_blank" attr.aria-label="{{ariaLabelStreet}} {{agentLocation}} opens in a new tab">
                <div *ngIf="agentDetails?.address?.city" class="agent-detail-row" >
                    <div class="agent-detail-icon" aria-hidden="true">                    
                      <img src="../../../../assets/images/room.png" alt="" />
                    </div>
                    <div *ngIf="showAsStrip==false" class="agent-detail-content" role="link">
                            <div class="agent-detail-address-1">{{agentDetails.address.street}}
                                </div>
                        <div class="agent-detail-address">{{agentLocation}}
                        </div>
                    </div>
                </div>
            </a>
            </div>
            </div>
        </div>
    </div>